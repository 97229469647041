import axios from "axios";
import {
  fetchUploadedFilesFailure,
  fetchUploadedFilesRequest,
  fetchUploadedFilesSuccess,
  toggleAlert,
} from "../../ActionCreators/learningContentAdminActionCreators";

const GET_LEARNING_CONTENT_URL =
  process.env.REACT_APP_API_URL +
  "/learningcontent/admin/content_homepage/?page=";

export const getLearningContent =
  (filterDetails, pageNumber = 1) =>
  (dispatch) => {
    dispatch(fetchUploadedFilesRequest());
    axios
      .post(GET_LEARNING_CONTENT_URL + pageNumber, filterDetails)
      .then((response) => {
        if (response.status === 200)
          dispatch(fetchUploadedFilesSuccess(response.data));
        else {
          dispatch(fetchUploadedFilesFailure("Something went wrong !"));
          dispatch(toggleAlert("error", "Something went wrong !", true));
        }
      })
      .catch((error) => {
        dispatch(fetchUploadedFilesFailure(error.message));
        dispatch(toggleAlert("error", error.message, true));
      });
  };
