import {
    START_LOADING,
    STOP_LOADING, 
    SET_LANDING_DATA,
    SET_DATA_FILTER, 
    SET_VET_COUNT_FILTER,
    SET_GRAPH_DATA,
    SET_TOP5_RATED_FILTER,
    SET_NOTIFICATION_DATA,
    SET_NOTIFICATION_SEEN
} from "../ActionTypes/landingPageActionTypes";

const initialState = {
    // isPageLoading: false, //page level loader
    // isContentLoading: false, //content level loader
    regionFilterData:[],
    selectedRegionFilterData:{value:"India"},
    graphData:{},
    vetCountFilterData:[],
    selectedVetCountFilterData:"",
    top5RatedFilterData:[],
    selectedTop5RatedFilterData:"",
    notificationsData:{},
    loaderObj:{
        isPageLoading: false,
        isContentLoading: false
    }
}


const adminLandingPageReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case START_LOADING:
            
            return{
                ...state,
                loaderObj:{
                    ...state.loaderObj,
                    [payload.type] : true
                }
                
            }
        
        case STOP_LOADING:
            
            return{
                ...state,
                // isPageLoading: false
                loaderObj:{
                    ...state.loaderObj,
                    [payload.type] : false
                }
            }

        case SET_LANDING_DATA:            
            return{
                ...state,
                regionFilterData: payload['show_data_from']
                // set landing data coming from action payload
            }

        case SET_DATA_FILTER:
            return{
                ...state,
                selectedRegionFilterData: {value: payload}
            }
        
        case SET_GRAPH_DATA:
            const { vet_count, top_5_rated_material} = payload;

            const vetCountDropdownData = Object.keys(vet_count);
            const top5RatedDropdownData = Object.keys(top_5_rated_material);

            return{
                ...state,
                graphData: payload,
                vetCountFilterData: vetCountDropdownData,
                selectedVetCountFilterData: vetCountDropdownData[0],
                top5RatedFilterData: top5RatedDropdownData,
                selectedTop5RatedFilterData: top5RatedDropdownData[0]
            }

        case SET_VET_COUNT_FILTER:
            return{
                ...state,
                selectedVetCountFilterData: payload

            }

        case SET_TOP5_RATED_FILTER:
            return{
                ...state,
                selectedTop5RatedFilterData: payload

            }

        case SET_NOTIFICATION_DATA:
            return{
                ...state,
                notificationsData: payload
            }

        default:
            return state;

    }
}

export default adminLandingPageReducer;