import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Snackbar, Alert } from "@mui/material";
import FileDetails from "../FileDetails/FileDetails";
import FileSelection from "../FileSelection/FileSelection";
import UploadedContent from "../UploadedContent/UploadedContent";
import UploadedFile from "../UploadedFile/UploadedFile";
import { classes } from "./styles";
import { toggleAlert } from "../../../Redux/ActionCreators/learningContentAdminActionCreators";

const LearningContent = ({
  toggleAlert,
  isAlertOpen,
  alertType,
  alertMessage,
}) => {
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [isArticleClicked, setIsArticleClicked] = useState(false);

  return (
    <div style={classes.learningContentDiv}>
      {!isFileSelected && !isArticleClicked && (
        <>
          <FileSelection
            setIsFileSelected={setIsFileSelected}
            isOnFileDetailsPage={isFileSelected}
          />
          <UploadedContent
            setIsArticleClicked={setIsArticleClicked}
            setIsFileSelected={setIsFileSelected}
          />
        </>
      )}
      {isFileSelected && (
        <FileDetails
          setIsFileSelected={setIsFileSelected}
          isOnFileDetailsPage={isFileSelected}
        />
      )}
      {isArticleClicked && (
        <UploadedFile setIsArticleClicked={setIsArticleClicked} />
      )}
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={5000}
        onClose={() => toggleAlert("success", "", false)}
      >
        <Alert
          onClose={() => toggleAlert("success", "", false)}
          severity={alertType}
          sx={classes.alert}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAlertOpen: state.LearningContentAdminReducer.isAlertOpen,
  alertType: state.LearningContentAdminReducer.alertType,
  alertMessage: state.LearningContentAdminReducer.alertMessage,
});

const mapDispatchToProps = (dispatch) => ({
  toggleAlert: (type, message, value) =>
    dispatch(toggleAlert(type, message, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LearningContent);
