import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Dialog, DialogContent, DialogContentText, DialogActions, Modal
} from "@mui/material";
import { Delete, InsertDriveFile, UploadFile, Save } from "@mui/icons-material";
import { serialize } from "object-to-formdata";
import { createCourse } from "../../Redux/Middlewares/CoursesAdmin/createCourseMiddleware";
import { editCourse } from "../../Redux/Middlewares/CoursesAdmin/editCourseMiddleware";
import { classes } from "./styles";
import CourseDetails from "../CourseDetails/CourseDetails";
import { changeCourseStatus } from "../../Redux/Middlewares/CoursesAdmin/changeCourseStatusMiddleware"
import LinearProgress from '@mui/material/LinearProgress';
import { Prompt } from "react-router-dom";
import { Box } from "@mui/system";
import { fetchPreviewData } from "../../Redux/Middlewares/CoursesAdmin/fetchDataForEditMiddleware";
import CoursePreview from "./CoursePreview";

const CourseProgress = ({ createCourse, courseDetails, isLoading, curriculumDetails, activeCourse, changeCourseStatus,
  editedData, editCourse, fetchPreviewData }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [dialogOpen, setOpenDialog] = useState(false);
  let coursePublication = false

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (curriculumDetails && curriculumDetails.chapters && curriculumDetails.chapters.length > 0) {
    let dataArray = curriculumDetails.chapters
    let flag = 1
    for (let i = 0; i < dataArray.length; i++) {
      if (!(dataArray[i].lessons && dataArray[i].lessons.length > 0)) {
        flag = 0
        break;
      }
    }
    if (flag === 1) {
      coursePublication = true
    }

  }

  let is_image_updated = true
  let is_video_updated = true
  let is_video_deleted = false
  let enableEdit = false
  let arr1 = []
  let arr2 = []
  let arr3 = []
  if (editedData && editedData.data && editedData.data.course_country) {

    let editedCounties = editedData.data.course_country.split(",").map(item => item)
    let currentCountries = courseDetails.data.course_country.map(item => item.value)


    let is_same = (editedCounties.length == currentCountries.length) && editedCounties.every(function (element, index) {
      return element === currentCountries[index];
    });

    if (editedData.data.course_title !== courseDetails.data.course_title ||
      editedData.data.course_category !== courseDetails.data.course_category ||
      editedData.data.course_language !== courseDetails.data.course_language ||
      // editedData.data.course_country !== courseDetails.data.course_country ||
      editedData.data.course_description !== courseDetails.data.course_description ||
      editedData.data.species !== courseDetails.data.species ||
      !is_same) {
      enableEdit = true
    }
    else {
      enableEdit = false
    }
    if (editedData && editedData.is_image_updated) {
      enableEdit = true
    }
    if (editedData && editedData.is_video_updated) {
      enableEdit = true
    }


    /////////////////////////// comparing speakers list for editing   ////////////////
    arr1 = editedData.data.speaker.filter(item => {
      if (!courseDetails.data.speaker.find(obj => obj.speaker_id === item.speaker_id)) {
        return true
      }
    }).map(item => ({ ...item, is_delete: true, is_edit: false, is_add: false }))

    arr2 = courseDetails.data.speaker.filter(item => {
      if (!editedData.data.speaker.find(obj => obj.speaker_id === item.speaker_id)) {
        return true
      }
    }).map(item => ({ ...item, is_delete: false, is_edit: false, is_add: true, speaker_id: null }))

    arr3 = courseDetails.data.speaker.filter(item => {
      if (editedData.data.speaker.find(obj =>
      (obj.speaker_id === item.speaker_id &&
        (obj.speaker_name !== item.speaker_name ||
          obj.speaker_qualification !== item.speaker_qualification ||
          obj.speaker_description !== item.speaker_description))

      )) {
        return true
      }
    }).map(item => ({ ...item, is_delete: false, is_edit: true, is_add: false }))

    if (arr1.length > 0 || arr2.length > 0 || arr3.length > 0) {
      enableEdit = true
    }




  }
  let modSpeakerArray = []

  // if(editedData &&editedData.data && editedData.data.speaker &&editedData.data.speaker.length>0){

  //   for(let i=0;i<editedData.data.speaker.length;i++){
  //     let spObj=editedData.data.speaker[i]
  //     for (let )
  //   }

  // }

  useEffect(() => {

    if (
      !courseDetails["course_image_path"] ||
      !courseDetails.data["course_title"] ||
      !courseDetails.data["course_category"] ||
      !courseDetails.data["course_language"] ||
      !courseDetails.data["course_country"].length > 0 ||
      !courseDetails.data["course_description"] ||
      !courseDetails.data["species"] ||
      courseDetails.data.speaker.find(
        (obj) =>
          !obj.speaker_name

      )
    )
      setIsDisabled(true);
    else setIsDisabled(false);
  }, [courseDetails]);

  const handlePublishCourse = (is_published) => {

    let modCountries = courseDetails.data.course_country.map(item => item.value).join(",")
    const tempObject = {
      ...courseDetails,
      data: {
        ...courseDetails.data, course_country: modCountries,
        is_published: is_published,
      },
    };
    // tempObject.is_published = is_published;
    const courseDetailsObject = new FormData();
    // const formData = serialize(courseDetails);
    courseDetailsObject.append(`data`, JSON.stringify(tempObject.data));
    // courseDetailsObject.append(
    //   `data[${`course_subtitle`}]`,
    //   "Full Course By professionals"
    // );
    courseDetailsObject.append(
      "course_image_path",
      tempObject.course_image_path
    );
    courseDetailsObject.append(
      "course_prevvideo_path",
      tempObject.course_prevvideo_path
    );

    createCourse(courseDetailsObject);
  };

  const handleEditCourse = () => {
    let modCountries = courseDetails.data.course_country.map(item => item.value).join(",")
    let editableSpeakerArray = arr1.concat(arr2).concat(arr3)
    const editObject = {
      data: { ...courseDetails.data, course_country: modCountries, speaker: editableSpeakerArray, course_id: activeCourse }

    };
    const dataObject = {
      ...courseDetails,

    };

    const editReqObj = new FormData();


    editReqObj.append("data", JSON.stringify(editObject.data));

    editReqObj.append(
      "course_image_path",
      dataObject.course_image_path
    );
    editReqObj.append(
      "course_prevvideo_path",
      dataObject.course_prevvideo_path
    );

    ///////////////////////////////
    editReqObj.append(
      "is_image_updated",
      editedData.is_image_updated
    );
    editReqObj.append(
      "is_video_updated",
      editedData.is_video_updated
    );
    editReqObj.append(
      "is_video_deleted",
      editedData.is_video_deleted
    );


    editCourse(editReqObj)
  }


  return (
    <>
      <Prompt
        when={enableEdit && activeCourse}
        message='Press OK to go back discarding any unsaved changes'
      />
      <Card sx={classes.card}>
        <CardContent>
          <Typography sx={classes.heading} variant="subtitle1">
            Course Summary
          </Typography>
          <hr style={classes.divider} />
          <Typography sx={classes.subheading} variant="subtitle1">
            Course Details
          </Typography>
          <Typography sx={classes.details} variant="subtitle1">
            Basic Details{" "}
            <span style={classes.progress}>
              {(courseDetails.data.course_title ? 20 : 0) +
                (courseDetails.data.course_category ? 20 : 0) +
                (courseDetails.data.course_language ? 20 : 0) +
                (courseDetails.data.course_country ? 20 : 0) +
                (courseDetails.data.species ? 20 : 0) +
                "%"}
            </span>
          </Typography>
          <Typography sx={classes.details} variant="subtitle1">
            Course Info{" "}
            <span style={classes.progress}>
              {(courseDetails.data.course_description ? 25 : 0) +
                (courseDetails.data.speaker[0].speaker_name ? 25 : 0) +
                (courseDetails.data.speaker[0].speaker_qualification ? 25 : 0) +
                (courseDetails.data.speaker[0].speaker_description ? 25 : 0) +
                "%"}
            </span>
          </Typography>
          <Typography sx={classes.details} variant="subtitle1">
            Creatives for course{" "}
            <span style={classes.progress}>
              {(courseDetails.course_image_path ? 100 : 0) + "%"}
            </span>
          </Typography>
          <Typography sx={classes.subheading} variant="subtitle1">
            Curriculum
          </Typography>
          <Typography sx={classes.details} variant="subtitle1">
            Chapter count <span style={classes.progress}>{curriculumDetails && curriculumDetails.chapters
              ? curriculumDetails.chapters.length : 0}</span>
          </Typography>
          {/* <Typography sx={classes.details} variant="subtitle1">
            Course length <span style={classes.progress}>0%</span>
          </Typography> */}
        </CardContent>
        {/* <CardActions> */}
        {/* <Button
          variant="contained"
          color="primary"
          sx={classes.button}
          onClick={() => handlePublishCourse("PUBLISHED")}
          disabled={isDisabled}
        >
          Save & publish course
        </Button>
        <Button
          color="primary"
          sx={classes.button}
          onClick={() => handlePublishCourse("UNPUBLISHED")}
          disabled={isDisabled}
        >
          Save for now
        </Button> */}

        {activeCourse ? <><LoadingButton
          variant="contained"
          color="primary"
          sx={classes.button}
          onClick={() => { setOpenDialog(true) }}
          disabled={!coursePublication || !(courseDetails && courseDetails.data && courseDetails.data.is_published === "UNPUBLISHED")}
          endIcon={<Save />}
          loading={isLoading}
          loadingPosition="end"
        >
          Publish course
        </LoadingButton>

          <LoadingButton
            variant="contained"
            color="primary"
            sx={classes.button}
            onClick={() => {
              handleEditCourse()
            }}
            disabled={isDisabled || !enableEdit}
            endIcon={<Save />}
            loading={isLoading}
            loadingPosition="end"
          >
            Save edited data
          </LoadingButton>
          <Button variant="contained"
            color="primary"
            sx={classes.button}
            onClick={() => {
              handleOpen();
              fetchPreviewData(activeCourse)
            }}
          >
            Preview
          </Button>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
           
          >
            <CoursePreview />
          </Modal>

          {/* <LoadingButton
            color="primary"
            sx={classes.button}
            onClick={() => handlePublishCourse("UNPUBLISHED")}
            disabled={!coursePublication}
            //   endIcon={<Edit />}
            loading={isLoading}
            loadingPosition="end"
          >
            Save for now
          </LoadingButton> */}
        </> : (<><LoadingButton
          color="primary"
          variant="contained"
          sx={classes.button}
          onClick={() => handlePublishCourse("UNPUBLISHED")}
          disabled={isDisabled}
          //   endIcon={<Edit />}
          loading={isLoading}
          loadingPosition="end"
        >
          Proceed to curriculum
        </LoadingButton>{(isLoading && !activeCourse) && <><Typography sx={{ fontSize: 14 }}>Uploading content.Please wait...</Typography>
          <LinearProgress color="secondary" /></>} </>)
        }

        <Dialog
          open={dialogOpen}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert_dialog_title"
          aria-describedby="alert_dialog_description"
          sx={{}}
        >
          <DialogContent sx={{}}>
            <DialogContentText id="alert_dialog_description">
              Do you want to publish the course?
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => {
              changeCourseStatus({
                is_published: "PUBLISHED",
                course_id: activeCourse,
              })
            }
            }>Yes</Button>
            <Button onClick={() => setOpenDialog(false)}>No</Button>
          </DialogActions>
        </Dialog>

        {/* </CardActions> */}
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  courseDetails: state.CoursesAdminReducer.courseDetails,
  isLoading: state.CoursesAdminReducer.isLoading,
  curriculumDetails: state.CoursesAdminReducer.curriculumDetails,
  activeCourse: state.CoursesAdminReducer.activeCourse,
  editedData: state.CoursesAdminReducer.editedData
});

const mapDispatchToProps = (dispatch) => ({
  createCourse: (courseDetailsObject) =>
    dispatch(createCourse(courseDetailsObject)),

  editCourse: (courseDetailsObject) =>
    dispatch(editCourse(courseDetailsObject)),

  changeCourseStatus: (courseDetailsObject) => dispatch(changeCourseStatus(courseDetailsObject, true)),
  fetchPreviewData: (obj) => dispatch(fetchPreviewData(obj))
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseProgress);
