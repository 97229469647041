import React, { useState, useEffect } from 'react';
import { InputLabel, TextField, Select, MenuItem, Button, Grid, Box, Typography } from '@mui/material';
import { useFormik } from "formik"
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { Link as MUIlink } from '@mui/material';


const validationSchema = yup.object({
    otp: yup.string("").min(4, 'OTP should be of minimum 4 characters length')
        .required('OTP is required'),

});

const EnterOTPForm = ({emailMain,authIdOTP,verifyOTP}) => {

    const formik = useFormik({
        initialValues: {
            otp: '',
        
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
           
            // alert(JSON.stringify(values, null, 2));
            verifyOTP(values.otp,authIdOTP)
        },
        
    });

    const handleOTP=(e)=>{   // to restric OPT as only number

        
        if(!isNaN(e.target.value))
        formik.handleChange(e)

    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ marginTop: 2, fontWeight: 600, color: "#434343" }} variant="h3" align="left">
                            Please enter the OTP sent to
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{  marginBottom: 2,  color: "#434343" }} variant="h3" align="left">
                            {emailMain}
                        </Typography>

                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            inputProps={{ maxLength: 10}}
                            InputProps={{letterSpacing:6}}
                            id="otp"
                            name="otp"
                            type="text"
                            value={formik.values.otp}
                            placeholder
                            onChange={handleOTP}
                            error={formik.touched.otp && Boolean(formik.errors.otp)}
                            helperText={formik.touched.otp && formik.errors.otp}
                        />
                    </Grid>


                    <Grid item xs={12} sm={12}>
                        <Button  style={{textTransform: 'none'}} color="primary" variant="contained" fullWidth type="submit">
                            Submit OTP
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12}>

                    </Grid>
                </Grid>


            </form>
        </>
    )
}

export default EnterOTPForm