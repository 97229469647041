import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, Button, InputAdornment, Grid, IconButton, OutlinedInput } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Search } from "@mui/icons-material";

const SearchWithExport = ({ searchText, setSearchText, handleApplyFiltersClick }) => {
  const history = useHistory();


  const handleSearch = (e) => {
    
  };

  const searchStyle =  { width: "100%", margin: "1% 0", marginTop: 0 };
  return (
    <div style={{ margin: "10px" }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 12, md: 12 }}
        direction="row"
        alignItems="center"
        // justifyContent="flex-end"
        // spacing={{ xs: 2, md: 3 }}
        // columns={{ xs: 2, md: 12 }}
      >
        <Grid item xs={2} md={2}>
          {/* <TextField
            placeholder="Search"
            value={searchText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton >
                    <SearchIcon onClick={() => handleApplyFiltersClick()} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
            onChange={(e) => setSearchText(e.target.value)}
          /> */}
          <OutlinedInput
            id="search_textfield"
            type="text"
            placeholder="Search"
            size="small"
            variant="outlined"
            className="search_field"
            // sx={classes.search}
            style={searchStyle}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="search button"
                  onClick={() => handleApplyFiltersClick()}
                  edge="end"
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={2} md={6}></Grid>
        <Grid item xs={2} md={2}>
          {/* <Button variant="text" disableElevation>
            Export
          </Button> */}
          <Button
            variant="contained"
            disableElevation
            // style={{ marginLeft: "20px", width:"100%" }}
            onClick={() => history.push("/createWebinar")}
            style={{textTransform:"none", width:"100%"}}
          >
            New Webinar
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SearchWithExport;
