

import { SET_QUERY_ADDED_ALERT, SET_BOOKMARK_ADDED_ALERT, SET_GENERIC_ERROR_ALERT } from "../../ActionTypes/alertsActionTypes"
import { BOOKMARK_UPDATE_COURSES,} from "../../ActionTypes/coursesVetActionTypes"
import axios from "axios"

//Login 
export const addBookmarkCourses = (moduleId, flag,courseList) => async dispatch => {


    try {
        let reqObj = {

            "course_id": moduleId,
            "bookmark_flag": flag

        }

        // dispatch({ type: FETCH_ARTICLE_USER_DATA })
        let url = process.env.REACT_APP_API_URL + "/vetcourses/addbookmark/"



        let result = await axios.post(url, reqObj)

        if (result.data) {

            let updatedCourseList=courseList.map(item=>{
                if(item.course_id===moduleId){
                    item.bookmark=flag
                    return item
                }
                return item
            })

            dispatch({type:BOOKMARK_UPDATE_COURSES,payload:updatedCourseList})



            if(flag==="Y"){
                dispatch({ type: SET_BOOKMARK_ADDED_ALERT })
            }

           


        }
        else {
            dispatch({ type: SET_GENERIC_ERROR_ALERT })
        }



    }
    catch (err) {
        dispatch({ type: SET_GENERIC_ERROR_ALERT })
    }
}

