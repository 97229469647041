import React, { useState, useEffect } from 'react';
import { InputLabel, TextField, Select, MenuItem, Button, Grid, Box, Typography } from '@mui/material';
import { useFormik } from "formik"
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { Link as MUIlink } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

//field validations
const validationSchema = yup.object({
    oldPassword: yup
        .string('Enter your old password')
        .min(9, 'Password should be of minimum 9 characters length')
        .required('Password is required'),
    password: yup
        .string('Enter your new password')
        .min(9, 'Password should be of minimum 9 characters length')
        .required('Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{9,}$/,
            "Password must include atleast one uppercase, one lowercase, one numeric and one special character [@$!%*#?&]"
          ),

    confirmedPassword: yup
        .string('Confirm your password')
        .min(9, 'Password should be of minimum 9 characters length')
        .required('Password is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match')
});

//function executed on submit form
const ChangePasswordForm = ({ changeResetPassword, userEmail,processLoader }) => {

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirmedPassword: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            
            changeResetPassword(userEmail, values.oldPassword, values.password)
            

        },
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ marginTop: 2 }} variant="h4" align="center">
                            Change Password
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel><Typography variant="h6"> Old Password</Typography></InputLabel>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                id="oldPassword"
                                name="oldPassword"
                                type="password"
                                inputProps={{ maxLength: 255}}
                                value={formik.values.oldPassword}
                                placeholder
                                onChange={formik.handleChange}
                                error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                                helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel><Typography variant="h6">New Password</Typography></InputLabel>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                id="password"
                                name="password"
                                inputProps={{ maxLength: 255 }}
                                type="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel><Typography variant="h6"> Confirm Password</Typography></InputLabel>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                id="confirmedPassword"
                                name="confirmedPassword"
                                inputProps={{ maxLength: 255 }}
                                type="password"
                                value={formik.values.confirmedPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.confirmedPassword && Boolean(formik.errors.confirmedPassword)}
                                helperText={formik.touched.confirmedPassword && formik.errors.confirmedPassword}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <LoadingButton style={{textTransform: 'none'}}  loading={processLoader} loadingPosition="center" color="primary" variant="contained" fullWidth type="submit">
                            Submit
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={12}>


                    </Grid>
                </Grid>


            </form>
        </>
    )
}

export default ChangePasswordForm