import React, { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
    Autocomplete,
    Button,
    Box,
    Card,
    Checkbox,
    CircularProgress,
    Container,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Drawer,
    FormHelperText,
    Grid,
    Modal,
    TextField,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Tooltip,
    Typography,
    Pagination
} from "@mui/material";
import Chip from '@mui/material/Chip';
import { LoadingButton } from "@mui/lab";
import { FilterAlt, ClearAll, Search } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Stack from '@mui/material/Stack';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import QueryCardNew from "./QueryCard/QueryCardNew";
import { connect, useDispatch } from "react-redux";


import {
    fetchVetQueriesDropdowns,
    fetchVetQueryDetails,
    createVetQuery
} from "../../Redux/Middlewares/queriesVetMiddleware";
import { selectFilter } from "../../Redux/ActionCreators/queriesVetActionCreators";
import "./queriesAdmin.css";
import { classes } from "./styles";
// import { catchError } from "../../Redux/ActionCreators/queriesAdminActionCreators";
import SpinnerLG from "../../SpinnerLG";
import FilterAltOutlined from "@mui/icons-material/FilterAltOutlined";

function Queries({
    selectFilter,
    fetchVetQueriesDropdowns,
    fetchVetQueryDetails,
    createVetQuery,
    queriesVet
}) {
    const [open, setOpen] = useState(false);
    const [dialogText, setDialogText] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [drawerState, toggleDrawer] = useState(false);

    const handleClose = () => setOpen(false);
    // const handleOpen = () => setOpen(true);

    const [tab, setTab] = useState("all_queries");
    const handleTabChange = (event, newValue) => {
        setTab(newValue);
        setPageNumber(1)
        // fetchAdminQueryDetails(filtersStateLocal);
    };

    const [modalFilters, setModalFilters] = useState({ contentType: { value: "" }, categoryValue: { value: "" } });

    //function for updating filteres
    const setFilters = (currType, currValue) => {

        selectFilter(currType, currValue);
        setPageNumber(1)
    }

    //function for updating query modal filters
    const handleModalFiltersChange = (currType, currValue) => {

        let tempObj = JSON.parse(JSON.stringify(modalFilters));
        tempObj[currType] = { value: currValue }
        setModalFilters(tempObj)
    }

    //function for submitting new query
    const handleAddQuery = () => {
        const addQueryObj = {
            module_id: "",
            content_type: modalFilters.contentType.value,
            query_category: modalFilters.categoryValue.value,
            query_text: queryText
        }
    }

    //functin for clearing filters
    const handleClearFilter = (currDrawerState = false) => {
        setFilters("duration", { value: "All time" });
        setFilters("type", []);
        setFilters("category", []);
        setFilters("search", "");
        const defaultFilterObj = {
            query_category: [],
            content_type: [],
            search_value: "",
            query_type: tab,
            show_content_from: {
                fixed: "All time",
                start_date: null,
                end_date: null,
            }
        }
        //setting local state of filter object
        setFiltersState(defaultFilterObj);
        fetchVetQueryDetails(defaultFilterObj, 1);

        toggleDrawer(currDrawerState)
    };



    //state for maintaining selected checkboxes
    const [selectedCheckboxCount, setCheckboxCount] = useState(0);
    //local state for maintaining selected filters object
    const [filtersStateLocal, setFiltersState] = useState({});
    const [checkArray, setCheckArray] = useState([]);
    //state for storing modal state
    const [queryModal, setQueryModal] = React.useState(false);
    const [queryText, setQueryText] = React.useState("");
    const [queryError, setQueryError] = React.useState("");

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: "100%", md: 400 },
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: 1,
        boxShadow: 24,
        p: 2,
    };

    const handleCloseModal = () => {
        setQueryError("");
        setQueryText("");
        setQueryModal(false)
    };

    const toggleModal = () => {
        setQueryError("");
        setQueryText("");
        setQueryModal(!queryModal)
    }

    const handleQuery = () => {
        if (queryText
            // && modalFilters.categoryValue.value!="" 
            // && modalFilters.contentType.value!=""
        ) {
            const addQueryObj = {
                module_id: "",
                content_type: modalFilters.contentType.value,
                query_category: modalFilters.categoryValue.value,
                query_text: queryText
            }

            createVetQuery(addQueryObj, filtersStateLocal, pageNumber)
            //   addWebinarQuery(currentWebinar.webinar_id, currentWebinar.webinar_category, queryText)
            setModalFilters({ contentType: { value: "" }, categoryValue: { value: "" } })
            setQueryError("")
            setQueryText("")
            setQueryModal(false)
        }
        else {
            setQueryError("Please fill all the details")
        }

    }

    const handleCheck = (tempId) => {
        if (checkArray.includes(tempId)) {
            let temp = checkArray.filter((item) => item !== tempId);
            setCheckArray(temp);

            setCheckboxCount(selectedCheckboxCount - 1);
        } else {

            let temp = [...checkArray, tempId]
            setCheckArray(temp);

            setCheckboxCount(selectedCheckboxCount + 1);
        }
    }


    const {
        dropdowns,
        selectedFilters,
        isLoading,
        queryDetails: { results },
    } = queriesVet;


    //handling page change
    const handlePageChange = (event, value) => {
        setPageNumber(value);
        fetchQueries(value);
    };

    useEffect(() => {
        // fetchAdminQueriesDropdowns();
        selectFilter("duration", { value: "All time" });
        selectFilter("type", []);
        selectFilter("category", []);
        selectFilter("search", "");
        fetchVetQueriesDropdowns();
        // fetchQueries(1);
        // fetchVetQueryDetails(1)
    }, []);


    useEffect(() => fetchQueries(1), [tab]);
    const handleApplyFiltersClick = (currDrawerState = false) => {
        setPageNumber(1)
        fetchQueries(1);
        toggleDrawer(currDrawerState);
    }

    const fetchQueries = (currentPage) => {
        const getDateObject = () => {
            if (
                selectedFilters.duration === "" ||
                selectedFilters.duration === null ||
                selectedFilters.duration.value === "All time"
            )
                return {
                    fixed: "All time",
                    start_date: null,
                    end_date: null,
                };
            else if (selectedFilters.duration.value === "1 Month")
                return {
                    fixed: "1 Month",
                    start_date: null,
                    end_date: null,
                };
            else if (selectedFilters.duration.value === "3 Months")
                return {
                    fixed: "3 Months",
                    start_date: null,
                    end_date: null,
                };
            else if (selectedFilters.duration.value === "6 Months")
                return {
                    fixed: "6 Months",
                    start_date: null,
                    // start_date: handleDateOutput(6),
                    end_date: null,
                };
        };


        const filterObject = {
            query_category: selectedFilters.category,
            content_type: selectedFilters.type,
            search_value: selectedFilters.search
                ? [selectedFilters.search]
                : [],
            query_type: tab,
            show_content_from: getDateObject(),
        };
        //setting local state of filter object
        setFiltersState(filterObject);

        fetchVetQueryDetails(filterObject, currentPage)
        // fetchAdminQueryDetails(filterObject, currentPage);
    };

    // const { queryDetails } = queriesAdmin;
    const { queryDetails } = queriesVet;

    const filterHtml = (currScreen) => {
        return (
            Object.keys(dropdowns).length !== 0 && (
                <Box sx={{ width: "auto", height: "100vh", padding: 2 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Typography sx={{ color: "#0f01a0", fontSize: 16, fontWeight: 700 }}>Filters</Typography>
                        </Grid>
                        <Grid item container xs={6} justifyContent="right">
                            <Typography onClick={() => handleClearFilter(false)} sx={{ color: "#4d4d4d" }}>Reset filters</Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <FormHelperText id="show_queries_from_filter">
                                Show queries from
                            </FormHelperText>
                            <Autocomplete
                                size="small"
                                disablePortal
                                id="show_queries_from_filter"
                                options={dropdowns.duration}
                                // sx={classes.filter}
                                loading={isLoading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {isLoading ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    ) : null}
                                                    {
                                                        params.InputProps
                                                            .endAdornment
                                                    }
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                                getOptionLabel={(option) => option.value || ""}
                                value={selectedFilters.duration}
                                onChange={(e, value) => {
                                    setFilters(
                                        "duration",
                                        value === null ? "" : value
                                    );
                                }}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
            <FormHelperText id="show_type_from_filter">
                Content type
            </FormHelperText>
            <Autocomplete
                limitTags={1}
                multiple
                size="small"
                disablePortal
                id="show_type_from_filter"
                options={dropdowns.type.map(({ value }) => value)}
                // sx={classes.filter}
                loading={isLoading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isLoading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {
                                        params.InputProps
                                            .endAdornment
                                    }
                                </>
                            ),
                        }}
                    />
                )}
                getOptionLabel={(option) => option || ""}
                value={selectedFilters.type}
                onChange={(e, value) =>
                    setFilters(
                        "type",
                        value === null ? [] : value
                    )
                }
            />
        </Grid>
        <Grid item xs={12}>
            <FormHelperText id="show_category_from_filter">
                Query Category
            </FormHelperText>
            <Autocomplete
                limitTags={1}
                multiple
                size="small"
                disablePortal
                id="show_category_from_filter"
                options={dropdowns.category.map(
                    ({ category_name }) => category_name
                )}
                // sx={classes.filter}
                loading={isLoading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isLoading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {
                                        params.InputProps
                                            .endAdornment
                                    }
                                </>
                            ),
                        }}
                    />
                )}
                getOptionLabel={(option) => option || ""}
                value={selectedFilters.category}
                onChange={(e, value) =>
                    setFilters(
                        "category",
                        value === null ? [] : value
                    )
                }
            />
        </Grid> */}
                        <Grid item xs={12}>
                            <FormHelperText id="show_search_from_filter">
                                Search
                            </FormHelperText>
                            {/* <OutlinedInput
                                id="search_textfield"
                                type="text"
                                size="small"
                                variant="outlined"
                                className="search_field"
                                placeholder="Search Queries"
                                sx={{ width: "100%" }}
                                value={selectedFilters.search}
                                onChange={(e) =>
                                    setFilters("search", e.target.value)
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="search button"
                                            onClick={() => handleApplyFiltersClick(false)}
                                            edge="end"
                                        >
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            /> */}
                            <TextField
                                sx={{ marginTop: 4, marginBottom: 2 }}
                                fullWidth
                                value={selectedFilters.search}
                                id="search_textfield"
                                placeholder="Search queries"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon  onClick={() => handleApplyFiltersClick(false)}/>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                size="large"
                                onChange={(e) =>
                                    setFilters("search", e.target.value)
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid container sx={{ marginTop: 6, }} justifyContent="flex-end" alignItems="flex-end">
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <Button style={{ textTransform: 'none' }} fullWidth variant="outlined" onClick={() => { toggleDrawer(false) }}> Close</Button>
                                <Button style={{ textTransform: 'none' }} fullWidth variant="contained" onClick={() => handleApplyFiltersClick(false)}> Apply</Button>
                            </Stack>
                        </Grid>

                    </Grid>
                </Box>
            ))
    }

    return (
        <>
        
            <Box style={{ marginTop: "2%" }}>
            <Typography color="primary" sx={{ paddingLeft: 4, paddingTop: 2, fontSize: 24, fontWeight: 600 }}>Queries</Typography>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <strong>{dialogText}</strong>
                        </DialogContentText>
                    </DialogContent>
                    {/* {!status && (
                        <DialogActions>
                            <LoadingButton
                                variant="contained"
                                size="small"
                                onClick={handleArchiveQuery}
                                loading={isLoading}
                            >
                                Retry
                            </LoadingButton>
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={handleClose}
                                autoFocus
                            >
                                Go to Queries page
                            </Button>
                        </DialogActions>
                    )} */}
                </Dialog>
                <TabContext value={tab}>
                    <TabList
                        onChange={handleTabChange}
                        aria-label="lab API tabs example"
                        sx={{ paddingLeft: 4, paddingRight: 4}}
                    >
                        <Tab label="All queries" value="all_queries"  style={{ textTransform: "none" }} />
                        <Tab label="My queries" value="my_queries"  style={{ textTransform: "none" }}/>
                        {/* <Tab label="Archived" value="archived" /> */}
                    </TabList>
                    <Box style={{ margin: "2%" }} >
                        <Grid container md={12} spacing={2} direction="row" alignItems="center" sx={{ paddingLeft: 1, paddingRight: 1, display: { xs: "none", md: "flex" } }} >
                            <Grid item xs={12} sm={2} md={2}>
                                <FormHelperText id="show_queries_from_filter">
                                    Show queries from
                                </FormHelperText>
                                <Autocomplete
                                    size="small"
                                    disablePortal
                                    id="show_queries_from_filter"
                                    options={dropdowns.duration}
                                    // sx={classes.filter}
                                    loading={isLoading}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {isLoading ? (
                                                            <CircularProgress
                                                                color="inherit"
                                                                size={20}
                                                            />
                                                        ) : null}
                                                        {
                                                            params.InputProps
                                                                .endAdornment
                                                        }
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                    getOptionLabel={(option) => option.value || ""}
                                    value={selectedFilters.duration}
                                    onChange={(e, value) => {
                                        setFilters(
                                            "duration",
                                            value === null ? "" : value
                                        );
                                    }}
                                />
                            </Grid>
                            {/* <Grid item xs={12} sm={2} md={2}>
                        <FormHelperText id="show_type_from_filter">
                            Content type
                        </FormHelperText>
                        <Autocomplete
                            limitTags={1}
                            multiple
                            size="small"
                            disablePortal
                            id="show_type_from_filter"
                            options={dropdowns.type.map(({ value }) => value)}
                            // sx={classes.filter}
                            loading={isLoading}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {isLoading ? (
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={20}
                                                    />
                                                ) : null}
                                                {
                                                    params.InputProps
                                                        .endAdornment
                                                }
                                            </>
                                        ),
                                    }}
                                />
                            )}
                            getOptionLabel={(option) => option || ""}
                            value={selectedFilters.type}
                            onChange={(e, value) =>
                                setFilters(
                                    "type",
                                    value === null ? [] : value
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                        <FormHelperText id="show_category_from_filter">
                            Query Category
                        </FormHelperText>
                        <Autocomplete
                            limitTags={1}
                            multiple
                            size="small"
                            disablePortal
                            id="show_category_from_filter"
                            options={dropdowns.category.map(
                                ({ category_name }) => category_name
                            )}
                            // sx={classes.filter}
                            loading={isLoading}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {isLoading ? (
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={20}
                                                    />
                                                ) : null}
                                                {
                                                    params.InputProps
                                                        .endAdornment
                                                }
                                            </>
                                        ),
                                    }}
                                />
                            )}
                            getOptionLabel={(option) => option || ""}
                            value={selectedFilters.category}
                            onChange={(e, value) =>
                                setFilters(
                                    "category",
                                    value === null ? [] : value
                                )
                            }
                        />
                    </Grid> */}
                           
                            <Grid item xs={12} sm={2} md={2}>
                                <LoadingButton
                                    variant="contained"
                                    sx={classes.filterButton}
                                    onClick={() => handleApplyFiltersClick(false)}
                                    endIcon={<FilterAlt />}
                                    loading={isLoading}
                                    loadingPosition="end"
                                    style={{ textTransform: "none" }}
                                >
                                    Apply
                                </LoadingButton>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2}>
                                <Tooltip title="Clear Filters" arrow>
                                    <IconButton
                                        color="primary"
                                        aria-label="clear filter"
                                        component="span"
                                        sx={classes.clearAllButton}
                                        onClick={() => handleClearFilter(false)}
                                    >
                                        <ClearAll />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}></Grid>
                            <Grid item xs={12} sm={2} md={2}>
                                {/* <FormHelperText id="show_search_from_filter">
                                    Search
                                </FormHelperText> */}
                                <OutlinedInput
                                    id="search_textfield"
                                    type="text"
                                    size="small"
                                    variant="outlined"
                                    className="search_field"
                                    placeholder="Search queries..."
                                    // sx={classes.search}
                                    value={selectedFilters.search}
                                    onChange={(e) =>
                                        setFilters("search", e.target.value)
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="search button"
                                                onClick={() => handleApplyFiltersClick(false)}
                                                edge="end"
                                            >
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    
                        <Grid container spacing={2} sx={{marginTop:0,  paddingLeft: 1, paddingRight: 4}} >
                            <Grid item  xs={8} sm={4} md={12} direction="row" alignItems="center" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <LoadingButton variant="contained"
                                    onClick={toggleModal}
                                    loading={isLoading}
                                    sx={{ fontSize: { sx: 10, sm: 12, md: 16 }, textTransform: "none" }}>
                                    <AddCircleOutlineOutlinedIcon sx={{ fontSize: { xs: 10, sm: 12, md: 16 }, marginTop:"-4px", marginRight:"4px" }} />
                                    Add a new query
                                </LoadingButton>
                            </Grid>
                            <Grid item xs={4} sm={8} md={9} sx={{ display: { xs: "flex", md: "none" } }}>
                                <IconButton onClick={() => toggleDrawer("bottom", true)}><FilterAltOutlined />
                                    {/* <Chip
                                        label={selectedFilters.category.length + selectedFilters.type.length} size="small" /> */}
                                    <Typography>Filters</Typography>
                                </IconButton>
                            </Grid>
                        </Grid>
                   

                    <Box sx={{ paddingLeft: 4, paddingRight: 4}}>
                        <div className="query-card-wrapper">
                            {isLoading ?  (<SpinnerLG />) : 
                            (
                                (results !== undefined && results.length) ? results.map((query, index) => {
                                    return (
                                        <QueryCardNew
                                            key={index}
                                            query={query}
                                            tab={tab}
                                            uniqKey={query['query_id']}
                                            checkboxFunc={handleCheck}
                                            currCheckBoxState={checkArray.includes(query['query_id'])}
                                            currentPageNumber={pageNumber}
                                        />
                                    )
                                }) : <p style={classes.noCourseDisclaimer}>No queries found !</p>
                            )
                              
                                }

                            {
                                !isLoading && 
                                <Grid container sx={{ paddingBottom: 9 }} spacing={0} justifyContent="center" alignItems="center"  >
                                    {results !== undefined && results.length > 0 && (queryDetails.count && Math.ceil(Number(queryDetails.count) / 10) > 1) &&
                                        <Grid item xs={12}>
                                            <Pagination
                                                count={queryDetails.count ? Math.ceil(Number(queryDetails.count) / 10) : 0}
                                                page={pageNumber}
                                                onChange={handlePageChange}
                                                sx={classes.pagination}
                                                color="secondary"
                                                hidePrevButton
                                                hideNextButton
                                            />
                                        </Grid>}
                                </Grid>
                            }
                        </div>
                        <Modal
                            open={queryModal}
                            onClose={toggleModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Grid container >
                                    <Grid container item xs={12} sm={12}>
                                        <Grid container justifyContent="right" item xs={12} sm={12}>
                                            <IconButton color="primary" onClick={toggleModal}>
                                                <CloseIcon sx={{ fontSize: 35 }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Typography sx={{ marginBottom: 1, color: "#0f01a0", fontSize: 26, fontWeight: 600 }} id="modal-modal-title"  >
                                            Add  a new query
                                        </Typography>
                                        {queryError && <Typography sx={{ marginBottom: 1, color: "red" }} id="modal-modal-title"  >
                                            {queryError}
                                        </Typography>}
                                    </Grid>

                                    <Grid item xs={12} sm={12} sx={{ marginTop: 2 }}>
                                        <TextField
                                            id="outlined-multiline-static"
                                            inputProps={{ maxLength: 300 }}
                                            fullWidth
                                            onChange={(e) => setQueryText(e.target.value)}
                                            multiline
                                            rows={4}
                                            placeholder="Type your query here"
                                        />
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={9} md={9}></Grid>
                                        <Grid item xs={12} sm={3} md={3}>
                                            <Button style={{ textTransform: 'none' }} fullWidth onClick={handleQuery} sx={{ marginTop: 1 }} variant="contained">Submit</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Modal>
                    </Box>
                    {/* <TabPanel value="1" style={{ paddingLeft: 0, paddingRight: 0 }}>
                    
                </TabPanel> */}
                    {/* <TabPanel value="2">Item Two</TabPanel>
                <TabPanel value="3">Item Three</TabPanel> */}
                </TabContext>
            </Box>
            {/* box for filters on smaller screens*/}
            {/* <Box onClick={() => toggleDrawer("bottom", true)}
                sx={{
                    width: "100%", height: 50, color: "white", backgroundColor: "#0f01a0",
                    display: { xs: "flex", md: "none" }, bottom: "0%", position: "fixed"
                }}>
                <Typography sx={{ color: "white", zIndex: 2, bottom: "2vh", left: { xs: "28%", sm: "40%" }, position: "fixed", fontWeight: 600, fontSize: 18 }} >Add Filters</Typography>
                <FilterAltOutlinedIcon sx={{ zIndex: 2, bottom: "2vh", left: { xs: "54%", sm: "52%" }, position: "fixed" }} />
                <Chip sx={{ zIndex: 2, bottom: "2vh", left: { xs: "62%", sm: "55%" }, position: "fixed", color: "#000", backgroundColor: "white" }}
                    label={selectedFilters.category.length + selectedFilters.type.length} size="small" />
            </Box> */}
            <Drawer
                anchor={"bottom"}
                open={drawerState}
                onClose={() => toggleDrawer("bottom", false)}
            >
                {filterHtml("mobile")}
            </Drawer>
        </>
    );
}

const mapStateToProps = (state) => ({
    queriesAdmin: state.QueriesAdminReducer,
    queriesVet: state.QueriesVetReducer
});

const mapDispatchToProps = (dispatch) => ({
    selectFilter: (filterType, filterValue) => dispatch(selectFilter(filterType, filterValue)),
    fetchVetQueriesDropdowns: () => dispatch(fetchVetQueriesDropdowns()),
    fetchVetQueryDetails: (filterDetails, currentPage) => dispatch(fetchVetQueryDetails(filterDetails, currentPage)),
    createVetQuery: (queryObj, filterObj, currentPage) => dispatch(createVetQuery(queryObj, filterObj, currentPage))
});

export default connect(mapStateToProps, mapDispatchToProps)(Queries);
