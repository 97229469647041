import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import FormHelperText from "@mui/material/FormHelperText";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "8px",
  },
}));

const TextLayout = (props) => {
  const { textInputs, title, saveTextInputs, error,fullEdit } = props;
  const handleInputValues = (event) => {
    saveTextInputs(event);
  };

 

  return (
    <FormControl
      variant="standard"
      style={{ marginTop: "10px", width: "100%" }}
    >
      <InputLabel shrink>{title}</InputLabel>
      <BootstrapInput
        name={title}
        disabled={!fullEdit}
        inputProps={{ maxLength: 60, }}
        value={textInputs && textInputs[title]}
        onChange={handleInputValues}
      />
      {error && <span style={{ color: "red", fontSize: 13 }}>Compulsory!</span>}
    </FormControl>
  );
};

const FileLayout = (props) => {
  const { title, filesUpload, id, files, error } = props;
  const handleFileUpload = (event) => {
    filesUpload([
      ...files,
      {
        id,
        fileName: event.target.files[0].name,
        fileDetails: event.target.files[0],
      },
    ]);
  };

  let fileData = (files || []).find((file) => file.id == id);

  return (
    <FormControl
      variant="standard"
      style={{ marginTop: "10px", width: "100%" }}
    >
      <InputLabel shrink htmlFor="bootstrap-input">
        {title}
      </InputLabel>
      <TextField
        style={{ marginTop: "24px" }}
        size="small"
        disabled
        multiline
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <label htmlFor={`${id}`}>
                <input
                  accept="image/*"
                  id={`${id}`}
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                <Button
                  component="span"
                  disableRipple
                  style={{ backgroundColor: "transparent" }}
                >
                  Upload file
                </Button>
              </label>
            </InputAdornment>
          ),
        }}
        value={fileData ? fileData.fileName : "No File Selected"}
      />
      {error && <span style={{ color: "red", fontSize: 13 }}>Compulsory!</span>}
    </FormControl>
  );
};

const MultiLineTextLayout = (props) => {
  const {
    multiLineTextInputs,
    title,
    saveMultiLineTextInputs,
    error,
    characterLimit,
    fullEdit
  } = props;

  const handleMultiLineInputValues = (event) => {
    saveMultiLineTextInputs(event);
  };

  return (
    <FormControl
      variant="standard"
      style={{ margin: "20px 0px", width: "100%" }}
    >
      <InputLabel shrink htmlFor="bootstrap-input">
        {title}
      </InputLabel>
      <TextField
        style={{ marginTop: "30px" }}
        input={<BootstrapInput />}
        name={title}
        multiline
        maxRows={5}
        disabled={!fullEdit}
        rows={5}
        inputProps={{ maxLength: 300, }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {
                (multiLineTextInputs && multiLineTextInputs[`${title}`]) ? multiLineTextInputs[`${title}`].length : 0
              }{" "}
              / 300
            </InputAdornment>
          )
        }}
        value={multiLineTextInputs && multiLineTextInputs[`${title}`]}
        // helperText={
        //   multiLineTextInputs &&
        //   multiLineTextInputs[`${title}`] &&
        //   `${multiLineTextInputs[`${title}`].length}/${characterLimit}`
        // }
        onChange={handleMultiLineInputValues}
      />
      {error && <span style={{ color: "red", fontSize: 13 }}>Compulsory!</span>}
    </FormControl>
  );
};

const SelectLayout = (props) => {
  const { dropDownInputs, title, saveDropDownInputs, error, dropDowns, match,fullEdit } = props;
  const handleDropDownInputValues = (value) => {
    saveDropDownInputs(value, title);
  };

  return (
    <FormControl
      variant="standard"
      style={{ marginTop: "10px", width: "100%" }}
    >
      <FormHelperText style={{ marginBottom: "5px" }}>{title}</FormHelperText>
      <Autocomplete
        size="small"
        id="combo-box"
        disablePortal
        name={title}
        disabled={!fullEdit}
        options={dropDowns}

        // || dropDownInputs[title].category_name || dropDownInputs[title].speaker_name
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={(dropDownInputs && dropDownInputs[title]) ? dropDownInputs[title].category_name ?
              dropDownInputs[title].category_name : dropDownInputs[title].value:""}
            InputProps={{
              ...params.InputProps,
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
        getOptionLabel={(option) =>
          option.value || option.category_name || option.speaker_name || ""
        }
        onChange={(e, value) => handleDropDownInputValues(value)}
      />
      {error && <span style={{ color: "red", fontSize: 13 }}>Compulsory!</span>}
    </FormControl>
  );
};

export { TextLayout, FileLayout, SelectLayout, MultiLineTextLayout };
