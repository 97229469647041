import {
    FETCH_MANAGEMENT_DATA, SET_MANAGEMENT_DATA, MANAGEMENT_DATA_FAIL,
    FETCH_MANAGEMENT_FILTERS,
    SET_MANAGEMENT_FILTERS,
    MANAGEMENT_FILTERS_FAIL,
    SET_SELECTED_FILTERS
} from "../ActionTypes/managementActionTypes";


export const selectFilterManagement = (filterType, filterValue) => ({
    type: SET_SELECTED_FILTERS,
    payload: {
      type: filterType,
      value: filterValue,
    },
  });

  