import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import authenticationReducer from "./Redux/Reducers/AuthenticationReducer";
import NavigationReducer from "./Redux/Reducers/NavigationReducer";
import AlertsReducer from "./Redux/Reducers/AlertsReducer";
import setAuthToken from "./setAuthToken";
import LearningContentReducer from "./Redux/Reducers/LearningContentReducer";
import LearningContentAdminReducer from "./Redux/Reducers/LearningContentAdminReducer";
import CoursesVetReducer from "./Redux/Reducers/CoursesVetReducer";
import CoursesAdminReducer from "./Redux/Reducers/CoursesAdminReducer";
import WebinarsAdminReducer from "./Redux/Reducers/WebinarsAdminReducer";
import WebinarsVetReducer from "./Redux/Reducers/WebinarsVetReducer";
import HomepageVetReducer from "./Redux/Reducers/HomepageVetReducer";
import QueriesAdminReducer from "./Redux/Reducers/QueriesAdminReducer";
import QueriesVetReducer from "./Redux/Reducers/QueriesVetReducer";
import ManagementReducer from "./Redux/Reducers/ManagementReducer";
import adminLandingPageReducer from "./Redux/Reducers/adminLandingPageReducer";

const rootReducer = combineReducers({
    authenticationReducer,
    NavigationReducer,
    AlertsReducer,
    LearningContentReducer,
    LearningContentAdminReducer,
    CoursesVetReducer,
    CoursesAdminReducer,
    WebinarsAdminReducer,
    WebinarsVetReducer,
    HomepageVetReducer,
    QueriesAdminReducer,
    QueriesVetReducer,
    ManagementReducer,
    adminLandingPageReducer
});

const initialState = {};

const middleware = [thunk];

const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

// set up a store subscription listener
// to store the users token in localStorage

// initialize current state from redux store for subscription comparison
// preventing undefined error
let currentState = store.getState();

store.subscribe(() => {
    let previousState = currentState;
    currentState = store.getState();
    // if the token changes set the value in localStorage and axios headers
    if (
        previousState.authenticationReducer.token !==
        currentState.authenticationReducer.token
    ) {
        const token = currentState.authenticationReducer.token;
        setAuthToken(token);
        //   if (token){
        //     localStorage.setItem("token",token)
        // }
        // else{
        //     localStorage.removeItem("token")
        // }
    }
});

export default store;
