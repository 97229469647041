const MappingObj = {
    vet_count:{
        series_name:"Vet Count",
        y_axis_title:"No.of vets",
        x_axis_title:"City Name"
    },

    weekly_new_registation:{
        series_name:"Registration Count",
        y_axis_title:"No.of vets",
        x_axis_title:"Date"
    },

    occupation_distribution:{
        series_name:"Distribution %",
        y_axis_title:"",
        x_axis_title:""
    },

    vet_adoption_analysis:{
        series_name:"Vet Adoption Rate",
        y_axis_title:"No.of logins",
        x_axis_title:"Date"
    },

    webinar_adoption_analysis:{
        series_name:"Adaption Analysis",
        y_axis_title:"No. of vets",
        x_axis_title:"Webinar name"
    },

    top_5_rated_material:{
        series_name:"Dropdown Value ",
        y_axis_title:"Rating",
        x_axis_title:"Dropdown Value"
    }
}

export default MappingObj;