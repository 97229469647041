import React, { useState } from "react";
import {
  Grid,
  TextField,
  Autocomplete,
  Button,
  Card,
  CardContent,
  Typography,
  Pagination,
  IconButton,
  MenuItem,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Rating,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { MoreVert, Delete } from "@mui/icons-material";
import { classes } from "./styles";

const ArticleCard = ({
  handleCardClick,
  article,
  index,
  setIsFileSelected,
  selectArticle,
  isLoading,
  fileDelete,
  fetchLearningContent,
  pageNumber,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleAgreeDelete = () => {
    fileDelete(article.learning_content_id);
    setTimeout(() => fetchLearningContent(pageNumber), 3000);
    setOpenDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = Boolean(anchorEl);

  const handleCloseMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleEditClick = (e) => {
    handleCloseMenu(e);
    selectArticle(article);
    setIsFileSelected(true);
  };

  const handleDeleteClick = (e) => {
    handleCloseMenu(e);
    setOpenDialog(true);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  return (
    <Grid item xs={3} key={index}>
      <Card sx={classes.card} onClick={() => handleCardClick(article)}>
        <IconButton
          color="default"
          aria-label="card actions"
          component="span"
          sx={classes.iconButton}
          onClick={handleMenuClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id={`basic_menu_${index}`}
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleEditClick}>Edit</MenuItem>
          <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
        </Menu>
        <CardContent>
          <Typography sx={classes.cardContent} gutterBottom>
            {article.file_title}
          </Typography>
          <Typography component="div">
            {/* {article.last_updated_date} . */}
            {article.file_type}
          </Typography>
          <Rating
            name="read-only"
            value={article.avg_rating}
            readOnly
            sx={classes.rating}
          />
        </CardContent>
      </Card>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert_dialog_title"
        aria-describedby="alert_dialog_description"
      >
        <DialogContent>
          <DialogContentText
            id="alert_dialog_description"
            sx={classes.dialogContent}
          >
            Do you want to delete this content ?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={classes.dialogActions}>
          {/* <Button onClick={handleCloseDialog} autoFocus variant="contained">
            Yes, delete this content
          </Button> */}
          <LoadingButton
            variant="contained"
            // sx={classes.dialogButton}
            onClick={handleAgreeDelete}
            // endIcon={<Delete />}
            // loading={true}
            // loadingPosition="end"
            loadingIndicator="Deleting ..."
          >
            Yes, delete this content
          </LoadingButton>
          <Button onClick={handleCancelDelete}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ArticleCard;
