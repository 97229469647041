import axios from "axios";
import {
  fetchDropDownValuesFailure,
  fetchDropDownValuesRequest,
  fetchDropDownValuesSuccess,
  toggleAlert,
} from "../../ActionCreators/webinarsAdminActionCreators";

const GET_DROPDOWN_VALUES_URL =
  process.env.REACT_APP_API_URL + "/webinars/admin/dropdown/";

export const getDropDownValues = () => (dispatch) => {
  dispatch(fetchDropDownValuesRequest());
  axios
    .get(GET_DROPDOWN_VALUES_URL)
    .then((response) => {
      
      if (response.status === 200)
        dispatch(fetchDropDownValuesSuccess(response.data));
      else {
       
        dispatch(fetchDropDownValuesFailure("Something went wrong !"));
        // dispatch(toggleAlert("error", "Something went wrong !", true));
      }
    })
    .catch((error) => {
     
      dispatch(fetchDropDownValuesFailure(error.message));
      // dispatch(toggleAlert("error", error.message, true));
    });
};

// {
//   country: [
//     { value: "Russia" },
//     { value: "Cameroon" },
//     { value: "China" },
//     { value: "Indonesia" },
//     { value: "Brazil" },
//     { value: "Australia" },
//     { value: "India" },
//     { value: "Argentina" },
//     { value: "Kazakhstan" },
//     { value: "Algeria" },
//   ],
//   category: [
//     { value: "The Shawshank Redemption" },
//     { value: "The Godfather" },
//     { value: "The Godfather: Part II" },
//     { value: "The Dark Knight" },
//     { value: "12 Angry Men" },
//     { value: "Schindler's List" },
//     { value: "Pulp Fiction" },
//   ],
//   languages: [
//     { value: "Russian" },
//     { value: "French" },
//     { value: "Mandarin" },
//     { value: "Indonesian" },
//     { value: "Portuguese" },
//     { value: "English" },
//     { value: "Hindi" },
//     { value: "Spanish" },
//     { value: "Kazakh" },
//     { value: "Arabic" },
//   ],
//   species: [
//     { value: "Amur leopard" },
//     { value: "Black rhino" },
//     { value: "Bornean orangutan" },
//     { value: "Cross river gorilla" },
//     { value: "Eastern lowland gorilla" },
//     { value: "Hawksbill turtles" },
//     { value: "Javan rhino" },
//     { value: "Malayan tiger" },
//     { value: "Vaquita" },
//     { value: "Yangtze finless porpoise" },
//   ],
// }
