import { signupRequest } from "../ActionCreators/authenticationActionCreators";
import axios from "axios";
import {
    SET_SIGNUP_EXISTS_ALERT,
    SET_FORGOT_PASS_ALERT,
    TOGGLE_PROCESS_LOADER,
} from "../ActionTypes/alertsActionTypes";
import { toggleGlobalAlert } from "../ActionCreators/alertsActionCreators";
import { loginRequest } from "../ActionCreators/authenticationActionCreators";

//Login
export const signupVerifyOTP = (otp, authId, email, password) => async (dispatch) => {
    try {
        dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: true } });
        let url = process.env.REACT_APP_API_URL + "/login/verify_email_otp/";
        let url2 = process.env.REACT_APP_API_URL + "/login/user_login/";


        var bodyFormData = new FormData();
        bodyFormData.append("otp", otp);
        bodyFormData.append("authId", authId);
        bodyFormData.append("email", email);

        let result = await axios.post(url, bodyFormData, {
            headers: { "Content-Type": "multipart/form-data" },
        });

        if (result.data.message) {
            dispatch(toggleGlobalAlert("success", "Account created.Please create your profile.", true))

            var bodyFormData2 = new FormData();
            bodyFormData2.append("username", email);
            bodyFormData2.append("password", password);

            let result2 = await axios.post(url2, bodyFormData2, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            if (result2.data.data) {
                dispatch(loginRequest({
                    token: result2.data.id_token, user: result2.data.data.email,
                    userStatus: result2.data.data.registration_status, profile: result2.data.data,
                    userType: result2.data.data.user_type
                }))

            }
            else {
                dispatch(loginRequest({ token: result2.data.id_token, user: result2.data.email, userStatus: result2.data.user_status }))
            }
            dispatch({
                type: TOGGLE_PROCESS_LOADER,
                payload: { processLoader: false },
            });

        } else {
            //   dispatch({ type: SET_FORGOT_PASS_ALERT });
            dispatch({
                type: TOGGLE_PROCESS_LOADER,
                payload: { processLoader: false },
            });
            dispatch(toggleGlobalAlert("warning", "Invalid OTP", true))
        }
    } catch (err) {
        dispatch({
            type: TOGGLE_PROCESS_LOADER,
            payload: { processLoader: false },
        });
        dispatch(toggleGlobalAlert("warning", "something went wrong", true))
        console.log("err");
    }
};
