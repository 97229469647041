import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  FormHelperText,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  IconButton,
  Checkbox,
  Input,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  Add,
  Save,
  ExpandMore,
  MoreVert,
  AddPhotoAlternate,
  Delete,
  Cancel,
} from "@mui/icons-material";
import { classes } from "./styles";
import "./styles.css";

const AddQuizLesson = ({
  isAddQuizLessonOpen,
  isLoading,
  activeChapter,
  activeCourse,
  handleAddQuizLessonDialogClose,
  createQuiz,
  quizEditing,
  selection,
  curriculumDetails,
  addOption,
  deleteOption,
  addQuestion,
  deleteQuestion,
  changeQuestion,
  changeQuizName
}) => {

//updating quiz object on updates
  let editedQuizObj = {}
  if (quizEditing && selection && selection.course_level2_id && curriculumDetails && curriculumDetails.chapters) {

    for (let i = 0; i < curriculumDetails.chapters.length; i++) {
      let flag = 0
      if (curriculumDetails.chapters[i].course_level1_id == activeChapter) {
        for (let j = 0; j < curriculumDetails.chapters[i].lessons.length; j++) {
          if (curriculumDetails.chapters[i].lessons[j].course_level2_id == selection.course_level2_id) {
            editedQuizObj = curriculumDetails.chapters[i].lessons[j]
            flag = 1
            break
          }
        }
        if (flag === 1) {
          break
        }
      }
    }

  }
//creating quiz object to get user inputs
  useEffect(() => {

    setQuizObject({
      data: {
        course_id: activeCourse,
        course_level1_id: activeChapter,
        course_level2_id: quizEditing ? editedQuizObj.course_level2_id : null,
        content_name: quizEditing ? editedQuizObj.content_name : "",
        file_type: "Quiz",
        duration: "600 sec",
        questions: quizEditing ? editedQuizObj.questions : [
          {
            content_id: null,
            question: "",
            options: [
              {
                option_text: "",
                media: null,
                correct_option: false,
              },
              {
                option_text: "",
                media: null,
                correct_option: false,
              },
            ],
          },
        ],
      }
    })

  }, [curriculumDetails])


  const [lessonTitle, setLessonTitle] = useState(quizEditing ? editedQuizObj.content_name : "");
  const [isQuestionExpanded, setIsQuestionExpanded] = useState(false);

  const [quizObject, setQuizObject] = useState(quizEditing ?

    {
      data: {
        course_id: activeCourse,
        course_level1_id: activeChapter,
        course_level2_id: editedQuizObj.course_level2_id,
        content_name: editedQuizObj.content_name,
        file_type: "Quiz",
        duration: "600 sec",
        questions: editedQuizObj.questions
      }
    }
    : {
      data: {
        course_id: activeCourse,
        course_level1_id: activeChapter,
        course_level2_id: null,
        content_name: "",
        file_type: "Quiz",
        duration: "600 sec",
        questions: [
          {
            content_id: null,
            question: "",
            options: [
              {
                option_text: "",
                media: null,
                correct_option: false,
              },
              {
                option_text: "",
                media: null,
                correct_option: false,
              },
            ],
          },
        ],
      },
    });

  let correctOptionSelection = false

  for (let i = 0; i < quizObject.data.questions.length; i++) {
    let tempQuestion = quizObject.data.questions[i]
    let flag = 0

    for (let j = 0; j < tempQuestion.options.length; j++) {

      if (tempQuestion.options[j].correct_option === true) {
        flag = 1
        break;
      }
    }
    if (flag === 1) {
      correctOptionSelection = true
    }
    else {
      correctOptionSelection = false
      break
    }
  }


  const handleDialogClose = () => {
    setLessonTitle("");
    // setFileToBeUploaded(null);
    setQuizObject({
      data: {
        course_id: activeCourse,
        course_level1_id: activeChapter,
        course_level2_id: null,
        content_name: "",
        file_type: "Quiz",
        duration: "600 sec",
        questions: [
          {
            content_id: null,
            question: "",
            options: [
              {
                option_text: "",
                media: null,
                correct_option: false,
              },
              {
                option_text: "",
                media: null,
                correct_option: false,
              },
            ],
          },
        ],
      },
    });
    handleAddQuizLessonDialogClose();
  };
//expanding options
  const handleQuestionClick = (question) => (event, isExpanded) => {
    setIsQuestionExpanded(isExpanded ? question : false);
  };
//adding option to questions
  const handleAddOption = (index) => {
    let objString = JSON.stringify(quizObject)
    let { data, ...rest } = quizObject
    let quizObjNew = JSON.parse(objString)
    const tempState = {
      ...quizObjNew, ...rest
    };

    if (quizEditing) {
      tempState.data.questions[index].options.push({
        option_text: "",
        media: null,
        correct_option: false,
      });
    }
    else {
      tempState.data.questions[index].options.push({
        option_text: "",
        media: null,
        correct_option: false,
      });
    }
    setQuizObject(tempState);
  };
//deleting options of questions
  const handleDeleteOption = (index, key, saved) => {
    if (quizObject.data.questions[index].options.length <= 2) return;
    if (saved) {
      deleteOption(quizObject.data.questions[index].options[key].quiz_details_id)
    }
    else {
      let objString = JSON.stringify(quizObject)
      let quizObjNew = JSON.parse(objString)
      let { data, ...rest } = quizObject
      const tempState = {
        ...quizObjNew, ...rest
      };
      tempState.data.questions[index].options.splice(key, 1);
      setQuizObject(tempState);
    }
  };
//updating question text
  const handleQuestionText = (index, value) => {
    let objString = JSON.stringify(quizObject)
    let quizObjNew = JSON.parse(objString)
    let { data, ...rest } = quizObject
    const tempState = {
      ...quizObjNew, ...rest
    };
    tempState.data.questions[index].question = value;
    setQuizObject(tempState);
  };
  //updating quiz title
  const handleQuizTitle = (value) => {
    let objString = JSON.stringify(quizObject)
    let quizObjNew = JSON.parse(objString)
    let { data, ...rest } = quizObject
    const tempState = {
      ...quizObjNew, ...rest
    };
    tempState.data.content_name = value;
    setLessonTitle(value);
    setQuizObject(tempState);
  };

  const handleOptionText = (index, key, value) => {
    let objString = JSON.stringify(quizObject)
    let quizObjNew = JSON.parse(objString)
    let { data, ...rest } = quizObject
    const tempState = {
      ...quizObjNew, ...rest
    };
    tempState.data.questions[index].options[key].option_text = value;
    setQuizObject(tempState);
  };

  const handleOptionCheckbox = (index, key) => {
    let objString = JSON.stringify(quizObject)
    let quizObjNew = JSON.parse(objString)
    let { data, ...rest } = quizObject
    const tempState = {
      ...quizObjNew, ...rest
    };
    tempState.data.questions[index].options[key].correct_option =
      !tempState.data.questions[index].options[key].correct_option;
    setQuizObject(tempState);
  };

  const handleAddQuestion = () => {
    let objString = JSON.stringify(quizObject)
    let quizObjNew = JSON.parse(objString)
    let { data, ...rest } = quizObject
    const tempState = {
      ...quizObjNew, ...rest
    };
    tempState.data.questions.push({
      content_id: null,
      question: "",
      options: [
        {
          option_text: "",
          media: null,
          correct_option: false,
        },
        {
          option_text: "",
          media: null,
          correct_option: false,
        },
      ],
    });
    setQuizObject(tempState);
  };

  //deleting questions
  const handleDeleteQuestion = (e, index, saved) => {
    e.stopPropagation();
    if (quizObject.data.questions.length <= 1) return;

    if (saved) {
      deleteQuestion(saved)
    }
    else {
      let objString = JSON.stringify(quizObject)
      let quizObjNew = JSON.parse(objString)
      let { data, ...rest } = quizObject
      const tempState = {
        ...quizObjNew, ...rest
      };
      tempState.data.questions.splice(index, 1);
      setQuizObject(tempState);
    }
  };

  //handling image upload for questions 
  const handleQuestionImageUpload = (e, index) => {

    e.stopPropagation();

    if (e.target.files[0]) {

      if (!(["jpg", "jpeg", "png"].includes(e.target.files[0].name.split(".").pop()))) {
        alert("Please upload image of accepted formats")
        return false;
      }

      else if (e.target.files[0].size / 1024 / 1024 > 5) {
        alert("File size limit exceeded.")
        return false;
      }
    }

    let objString = JSON.stringify(quizObject)
    let quizObjNew = JSON.parse(objString)
    let { data, ...rest } = quizObject
    const tempState = {
      ...quizObjNew, ...rest
    };
    // if (tempState.data.questions[index].content_id) {
    //   e.preventDefault();
    //   tempState.data.questions[index].content_id = null;
    //   delete tempState[String(index)];
    // }
    // else {
    tempState.data.questions[index].content_id = Math.random()
      .toString(36)
      .substr(2, 5);
    tempState[tempState.data.questions[index].content_id] = e.target.files[0];
    // }

    setQuizObject(tempState);
  };

  //removing image from questions 
  const handleRemoveQuestionImageClick = (e, index) => {
    e.stopPropagation();
    let objString = JSON.stringify(quizObject)
    let quizObjNew = JSON.parse(objString)
    let { data, ...rest } = quizObject
    const tempState = {
      ...quizObjNew, ...rest
    };
    if (tempState.data.questions[index].content_id) {
      tempState.data.questions[index].content_id = null;
      delete tempState[tempState.data.questions[index].content_id];
    }
    setQuizObject(tempState);
  };

  //handling image upload for options 
  const handleOptionImageUpload = (e, index, key) => {
    let objString = JSON.stringify(quizObject)
    let quizObjNew = JSON.parse(objString)
    let { data, ...rest } = quizObject
    const tempState = {
      ...quizObjNew, ...rest
    };
    tempState.data.questions[index].options[key].media = Math.random()
      .toString(36)
      .substr(2, 5);
    tempState[tempState.data.questions[index].options[key].media] =
      e.target.files[0];

    setQuizObject(tempState);
  };

  //removing image from options 
  const handleRemoveOptionImageClick = (index, key) => {
    let objString = JSON.stringify(quizObject)
    let quizObjNew = JSON.parse(objString)
    let { data, ...rest } = quizObject
    const tempState = {
      ...quizObjNew, ...rest
    };
    if (tempState.data.questions[index].options[key].media) {
      tempState.data.questions[index].options[key].media = null;
      delete tempState[tempState.data.questions[index].options[key].media];
    }
    setQuizObject(tempState);
  };

  const handleAddQuizLesson = () => {
    // const tempObject = {
    //   course_id: activeCourse,
    //   course_level1_id: activeChapter,
    //   course_level2_id: null,
    //   content_name: lessonTitle,
    //   file_type: type === ".pdf" ? "Reading Material" : type,
    //   duration: type === ".pdf" ? "600 sec" : "600 sec",
    // };
    const tempObject = new FormData();
    Object.keys(quizObject).map((key) =>
      tempObject.append(
        key,
        key === "data" ? JSON.stringify(quizObject[key]) : quizObject[key]
      )
    );

    createQuiz(tempObject);
    setTimeout(() => handleDialogClose(), 3000);

  };

  const addEditedOption = (option, question) => {
    const tempObject = new FormData();

    const tempData = {
      course_id: activeCourse,
      course_level1_id: activeChapter,
      course_level2_id: selection.course_level2_id,
      quiz_id: question.quiz_id,
      options: [option]
    }

    // {
    //   "data": {
    //     "course_id": 49,
    //       "course_level1_id": 24,
    //         "course_level2_id": 71,
    //           "quiz_id": 58,
    //             "options": [
    //               {
    //                 "option_text": "New option added with single api  image",
    //                 "media": "b",
    //                 "correct_option": false
    //               }
    //             ]
    //   },
    //   "b": ans_image.jpg
    // }
    Object.keys(quizObject).map((key) =>
      tempObject.append(
        key,
        key === "data" ? JSON.stringify(tempData) : quizObject[key]
      )
    );


    // tempObject.append(`data`, JSON.stringify(tempObject.data));
    // tempObject.append("file_url", fileToBeUploaded);
    // tempObject.append("is_file_updated", true);
    addOption(tempObject);
    // setTimeout(() => handleDialogClose(), 3000);
  }

  const changeEditQuestion = (question) => {

    const tempData = {
      course_id: activeCourse,
      course_level1_id: activeChapter,
      course_level2_id: selection.course_level2_id,
      quiz_id: question.quiz_id,
      question: question.question,


    }

    const tempObject = new FormData();

    tempObject.append("data", JSON.stringify(tempData))
    tempObject.append("is_file_updated", false)
    tempObject.append("is_file_deleted", false)
    tempObject.append("file_url", "")

    changeQuestion(tempObject)
  }


  const changeQuizNameEdit = () => {

    const tempData = {
      course_id: activeCourse,
      course_level1_id: activeChapter,
      course_level2_id: selection.course_level2_id,
      content_name: lessonTitle,
      file_type: "Quiz",
      duration: "600 sec"


    }

    const tempObject = new FormData();
    tempObject.append("data", JSON.stringify(tempData))

    changeQuizName(tempObject)
  }

  const addEditedQuestion = (question) => {

    const tempObject = new FormData();

    const tempData = {
      course_id: activeCourse,
      course_level1_id: activeChapter,
      course_level2_id: selection.course_level2_id,
      questions: [question]
    }

    Object.keys(quizObject).map((key) =>
      tempObject.append(
        key,
        key === "data" ? JSON.stringify(tempData) : quizObject[key]
      )
    );

    addQuestion(tempObject, activeCourse)

  }

  const questionArray = quizObject.data.questions
    ? quizObject.data.questions.map((question, index) => (
      <section key={index}>
        {/* {index + 1} */}
        <Accordion
          expanded={isQuestionExpanded === index}
          onChange={handleQuestionClick(index)}
          sx={classes.questionAccordian}
          className="questionAccordian"
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={`${index}_content`}
            id={`${index}_header`}
          >
            <TextField
              size="small"
              id="question_title"
              inputProps={{ maxLength: 300, }}
              placeholder="Enter question name"
              variant="outlined"
              className="question_title"
              sx={classes.questionTitle}
              value={question.question}
              onChange={(e) => handleQuestionText(index, e.target.value)}
            />
            {quizEditing && question.quiz_id && <Button sx={{ fontSize: 10 }} onClick={() => changeEditQuestion(question)} size="small" variant="outlined"> change name</Button>}
            {!quizEditing ? (!question.content_id ? (
              <Tooltip title={`Upload image`} arrow>
                <label htmlFor={`upload_question_image_${index}`}>
                  <input
                    accept=".png, .jpg, .jpeg"
                    id={`upload_question_image_${index}`}
                    //   multiple
                    type="file"
                    style={classes.input}
                    onChange={(e) => {
                      handleQuestionImageUpload(e, index);
                    }}
                  />
                  <IconButton
                    color="default"
                    aria-label="file upload"
                    component="span"
                    sx={
                      question.content_id
                        ? classes.addQuestionImageUploaded
                        : classes.addQuestionImage
                    }
                  // onClick={handleMenuClick}
                  >
                    <AddPhotoAlternate />
                  </IconButton>
                </label>
              </Tooltip>
            ) : (
              <Tooltip
                title={`Remove ${!quizObject[question.content_id]
                  ? ""
                  : quizObject[question.content_id].name
                  }`}
                arrow
              >
                <IconButton
                  color="default"
                  aria-label="remove image"
                  component="span"
                  sx={classes.addQuestionRemoveImage}
                  onClick={(e) => handleRemoveQuestionImageClick(e, index)}
                >
                  <Cancel />
                </IconButton>
              </Tooltip>
            )) : (
              <></>
            )}

            <IconButton
              color="default"
              aria-label="delete question"
              component="span"
              sx={classes.deleteQuestionBtn}
              onClick={(e) => handleDeleteQuestion(e, index, question.quiz_id)}
            >
              <Delete />
            </IconButton>
          </AccordionSummary>
          <AccordionDetails>
            {question.options.map((option, key) => (
              <Typography key={key} sx={classes.option}>
                <Checkbox
                  disabled={option.quiz_details_id}
                  checked={!quizEditing ? option.correct_option : (option.correct_option === "N" || !option.correct_option) ? false : true}
                  onChange={() => handleOptionCheckbox(index, key)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <em style={{ marginRight: 5 }}>Mark as correct</em>
                <TextField
                  size="small"
                  id="option_title"
                  placeholder="Enter option value"
                  variant="outlined"
                  className="option_value"
                  inputProps={{ maxLength: 300, }}
                  sx={classes.optionValue}
                  disabled={option.quiz_details_id}
                  value={option.option_text ? option.option_text : option.answer_option}
                  onChange={(e) =>
                    handleOptionText(index, key, e.target.value)
                  }
                />
                {!quizEditing || !option.quiz_details_id ? (!option.media ? (
                  <Tooltip title={`Upload image`} arrow>
                    <label htmlFor={`upload_option_image_${index}_${key}`}>
                      <input
                        accept="image/*"
                        id={`upload_option_image_${index}_${key}`}
                        //   multiple
                        type="file"
                        style={classes.input}
                        onChange={(e) =>
                          handleOptionImageUpload(e, index, key)
                        }
                      />
                      <IconButton
                        color="default"
                        aria-label="file upload"
                        component="span"
                        sx={
                          option.media
                            ? classes.addOptionImageUploaded
                            : classes.addOptionImage
                        }
                      // onClick={handleMenuClick}
                      >
                        <AddPhotoAlternate />
                      </IconButton>
                    </label>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={`Remove ${!quizObject[option.media]
                      ? ""
                      : quizObject[option.media].name
                      }`}
                    arrow
                  >
                    <IconButton
                      color="default"
                      aria-label="remove image"
                      component="span"
                      sx={classes.addOptionRemoveImage}
                      onClick={() => handleRemoveOptionImageClick(index, key)}
                    >
                      <Cancel />
                    </IconButton>
                  </Tooltip>
                )) : <></>}
                {(!option.quiz_details_id && quizEditing && question.quiz_id) && (<Button onClick={() => { addEditedOption(option, question) }} variant="contained">Save</Button>)}
                {/* <IconButton
                    color="default"
                    aria-label="file upload"
                    component="span"
                    sx={classes.optionFileUpload}
                    // onClick={handleMenuClick}
                  >
                    <AddPhotoAlternate />
                  </IconButton> */}
                <IconButton
                  color="default"
                  aria-label="file upload"
                  component="span"
                  sx={classes.deleteOptionBtn}
                  onClick={() => handleDeleteOption(index, key, option.quiz_details_id)}
                >
                  <Delete />
                </IconButton>
              </Typography>
            ))}
            <Typography sx={classes.addOption}>
              <Button
                variant="outlined"
                onClick={() => handleAddOption(index)}
                sx={classes.addOptionBtn}
                disabled={quizEditing ? !question.quiz_id ? false : quizObject.data.questions.find((question) => {

                  if (question.question === "") return true;

                  else if (
                    question.options.find((option) => !option.quiz_details_id)
                  )
                    return true;
                  return false;
                }) : false}
              >
                <Add /> Option
              </Button>

            </Typography>
            {!question.quiz_id && quizEditing && <Button onClick={() => addEditedQuestion(question)} variant="contained">Save question</Button>}
          </AccordionDetails>
        </Accordion>
      </section>
    ))
    : [];

  return (
    <>
      <Dialog
        open={isAddQuizLessonOpen}
        onClose={handleAddQuizLessonDialogClose}
        aria-labelledby="alert_dialog_title"
        aria-describedby="alert_dialog_description"
        sx={classes.dialog}
      >
        {quizEditing ? <Typography color="primary" sx={{ fontSize: 20, fontWeight: 600 }}>Edit quiz</Typography> :
          <Typography color="primary" sx={{ fontSize: 20, fontWeight: 600 }}>Create quiz</Typography>}
        <DialogContent sx={classes.dialogContent}>
          <DialogContentText id="alert_dialog_description">
            <TextField
              size="small"
              id="lesson_title"
              placeholder="Enter quiz name"
              variant="outlined"
              className="lesson_title"
              inputProps={{ maxLength: 300, }}
              sx={classes.lessonTitle}
              value={lessonTitle}
              onChange={(e) => handleQuizTitle(e.target.value)}
            />
            {quizEditing && <Button variant="outlined" size="small" onClick={() => changeQuizNameEdit()} sx={{ fontSize: 10, marginLeft: 1 }}>change name</Button>}
          </DialogContentText>
        </DialogContent>
        {questionArray}
        <Button
          variant="outlined"
          onClick={handleAddQuestion}
          sx={classes.addQuestionBtn}
          disabled={quizEditing ? quizObject.data.questions.find((question) => {
            if (!question.quiz_id) return true;
            return false
          }) : false}
        >
          <Add /> Question
        </Button>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          {!quizEditing && <LoadingButton
            onClick={handleAddQuizLesson}
            autoFocus
            variant="contained"
            sx={classes.uploadBtn}
            disabled={
              !lessonTitle || !correctOptionSelection ||
              quizObject.data.questions.find((question) => {
                if (question.question === "") return true;
                else if (
                  question.options.find((option) => option.option_text === "")
                )
                  return true;
                return false;
              })
            }
            endIcon={<Save />}
            loading={isLoading}
            loadingPosition="end"
          >
            Save
          </LoadingButton>}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddQuizLesson;
