import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { useDropzone } from "react-dropzone";
import { selectFile } from "../../../Redux/ActionCreators/learningContentAdminActionCreators";
import { selectArticle } from "../../../Redux/ActionCreators/learningContentAdminActionCreators";
import { classes } from "./styles";

const FileSelection = ({
  setIsFileSelected,
  selectFile,
  selectArticle,
  isOnFileDetailsPage,
  selectedFile,
}) => {
  const onDropAccepted = useCallback((files) => {
    if (!isOnFileDetailsPage) {
      setIsFileSelected(true);
      selectFile(files[0]);
      selectArticle({});
    } else selectFile(files[0]);
  });

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      maxSize: 26214400,
      accept: ".pdf",
      maxFiles: 1,
      onDropRejected: () => {
        alert("File size limit exceeded.")
        return false
      },
      onDropAccepted,
    });

  return (
    <>
      <p
        className="file_selection_heading"
        style={classes.fileSelectionHeading}
      >
        Upload content here
      </p>
      <section className="container">
        <div
          {...getRootProps({ className: "dropzone" })}
          style={
            selectedFile
              ? classes.fileSelectionDivWithFileSelected
              : classes.fileSelectionDiv
          }
        >
          <input {...getInputProps()} />
          <p>Drop your file here</p>
          <p>
            or{" "}
            <span
              style={
                selectedFile
                  ? classes.browseSpanWithFileSelected
                  : classes.browseSpan
              }
            >
              Browse
            </span>
          </p>
          <em>(Only .pdf files are accepted)</em>
        </div>
      </section>
      <hr style={classes.divider} />
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedFile: state.LearningContentAdminReducer.selectedFile,
});

const mapDispatchToProps = (dispatch) => ({
  selectFile: (selectedFile) => dispatch(selectFile(selectedFile)),
  selectArticle: (article) => dispatch(selectArticle(article)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileSelection);
