import {
    FETCH_MANAGEMENT_DATA, SET_MANAGEMENT_DATA, MANAGEMENT_DATA_FAIL,
    FETCH_MANAGEMENT_FILTERS,
    SET_MANAGEMENT_FILTERS,
    MANAGEMENT_FILTERS_FAIL,
    SET_SELECTED_FILTERS,
    CLEAR_MANAGEMENT_FILTER_DATA,
    CLEAR_MANAGEMENT_DATA,
    SET_USER_MANAGEMENT_NAVIGATION,
    SET_USER_MANAGEMENT_LOADING_FALSE,
    SET_CATEGORIES_DATA,
    SET_USER_MANAGEMENT_CATEGORIES_NAVIGATION,
    SET_TAB_VALUE, SET_EXPORT_DATA
} from "../ActionTypes/managementActionTypes";

const initialState = {
    isLoading: false,
    error: null,
    message: null,
    filtersLoading: false,
    current: 1,
    total: null,
    filters: {
        // category: [],
        // type: [],
        // country: [],
        // language: [],
        // duration: [],
        // Visibility: [],
    },
    selectedFilters: {
        category: [],
        type: [],
        country: [],
        language: [],
        show_user_from: { value: "All time" },
        Visibility: [],
        search: "",
    },
    dataSet: [],
    categoriesData: [],
    pendingUsers: null,
    tabValue: 0,
    exportData: null

};

const ManagementReducer = (state = initialState, action) => {
    const { type, payload } = action;


    switch (type) {
        case FETCH_MANAGEMENT_DATA:
            return {
                ...state,

                isLoading: true,

            };
        case SET_EXPORT_DATA:
            return {
                ...state,

                exportData: payload,

            };
        case SET_USER_MANAGEMENT_LOADING_FALSE:
            return {
                ...state,

                isLoading: false,

            };
        case SET_MANAGEMENT_DATA:
            return {
                ...state,
                dataSet: payload,
                isLoading: false
            }
        case SET_CATEGORIES_DATA:
            return {
                ...state,
                categoriesData: payload,
                isLoading: false
            }
        case MANAGEMENT_DATA_FAIL:
            return {
                ...state,
                dataSet: [],
                isLoading: false,
                total: null,
                current: 1
            }
        case FETCH_MANAGEMENT_FILTERS:
            return {
                ...state,
                filtersLoading: true
            }
        case SET_MANAGEMENT_FILTERS:
            return {
                ...state,
                filtersLoading: false,
                filters: payload
            }
        case SET_USER_MANAGEMENT_NAVIGATION:
            return {
                ...state, current: payload.current,
                total: payload.total, pendingUsers: payload.pendingUsers
            }
        case SET_USER_MANAGEMENT_CATEGORIES_NAVIGATION:
            return {
                ...state, current: payload.current,
                total: payload.total,
            }
        case MANAGEMENT_FILTERS_FAIL:
            return {
                ...state,
                filtersLoading: false,

            }
        case SET_SELECTED_FILTERS:

            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    [payload.type]: payload.value,
                },
            };
        case CLEAR_MANAGEMENT_FILTER_DATA:
            return {
                ...state, filters: {}
            }
        case CLEAR_MANAGEMENT_DATA:
            return {
                ...state, dataSet: [],
                isLoading: false,
                filtersLoading: false,
                total: null,
                pendingUsers: null
            }

        case SET_TAB_VALUE:
            return {
                ...state,
                tabValue: payload
            }

        default:
            return state;
    }
};

export default ManagementReducer;
