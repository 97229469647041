import {
    FETCH_ARTICLE_LIST, SET_ARTICLE_LIST, FETCH_ARTICLE_USER_DATA, SET_ARTICLE_USER_DATA, CLEAR_ARTICLE_USER_DATA,
    SET_BOOKMARK_LC, SET_RATING_LC, SET_AVG_RATING_LC, SET_LEARNING_CONTENT_PAGINATION, SET_FILTERS_DATA_LEARNING_CONTENT,
    SET_USER_FILTER_LEARNING_CONTENT, CLEAR_USER_FILTER_LEARNING_CONTENT, SET_LC_ARTICLE_USER_DATA_FAIL, BOOKMARK_UPDATE_LC
} from "../ActionTypes/learningContentActionTypes"

const initialState = {
    loading: false,
    contents: [],
    isBookmarked: null,
    rating: 0,
    avgRating: 0,
    articlesList: [],
    current: 1,
    next: null,
    prev: null,
    totalCount: null,
    filterData: {
        file_category: [],
        file_content_type: [],
        author: [],
        content_country: [],
        file_species: [],
        show_content_from: [],

    },


    timeFilter: ["All time"],
    categoryFilter: [],
    authorFilter: [],
    speciesFilter: [],
    contentFilter: []

};

function LearningContentReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case FETCH_ARTICLE_USER_DATA:
        case FETCH_ARTICLE_LIST:
            return {
                ...state,
                loading: true

            };
        case SET_ARTICLE_LIST:
            return {
                ...state,
                articlesList: payload,
                loading: false

            };
        case SET_ARTICLE_USER_DATA:
            return {
                ...state,
                contents: payload,
                loading: false

            };
        case SET_BOOKMARK_LC:
            return {
                ...state,
                ...payload,
                loading: false

            };
        case SET_RATING_LC:
            return {
                ...state,
                ...payload,
                loading: false

            };
        case SET_AVG_RATING_LC:
            return {
                ...state,
                ...payload,
                loading: false

            };
        case BOOKMARK_UPDATE_LC:
            return {
                ...state, articlesList: payload
            }
        case CLEAR_ARTICLE_USER_DATA:
            return {
                ...state,
                contents: null,
                articlesList: null,
                isBookmarked: null,
                rating: null,
                next: null,
                prev: null,
                current: null,
                totalCount: null,
                // timeFilter: null,
                // categoryFilter: [],
                // authorFilter: [],
                // speciesFilter: [],
                // contentFilter: []

            };
        case SET_LEARNING_CONTENT_PAGINATION:
            return {
                ...state,
                ...payload
            }
        case SET_FILTERS_DATA_LEARNING_CONTENT:
            return {
                ...state,
                filterData: payload
            }
        case SET_USER_FILTER_LEARNING_CONTENT:
            return {
                ...state,
                ...payload
            }
        case CLEAR_USER_FILTER_LEARNING_CONTENT:
            return {
                ...state,
                ...payload
            }
        case SET_LC_ARTICLE_USER_DATA_FAIL:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }
}

export default LearningContentReducer;
