import { loginRequest, loginFailureRequest, loadUserRequest, authErrorRequest,SetForgotPasswordScreenFlow,
    setAuthIdOTP} from "../ActionCreators/authenticationActionCreators"
import { setLoginFailAlert } from "../ActionCreators/alertsActionCreators"
import { LOGIN_INIT } from "../ActionTypes/authenticationActionTypes"
import { SET_FORGOT_PASS_ALERT } from "../ActionTypes/alertsActionTypes"
import axios from "axios"
import { TOGGLE_PROCESS_LOADER } from "../ActionTypes/alertsActionTypes"

//Login 
export const otpAndPassword = (otp,new_password,authId,email) => async dispatch => {


    try {
        dispatch({type:TOGGLE_PROCESS_LOADER,payload:{processLoader:true}})
        let url = process.env.REACT_APP_API_URL+"/login/verify_change_password/"
        var bodyFormData = new FormData();
        bodyFormData.append("new_password", new_password)
        bodyFormData.append("authId", authId)
        bodyFormData.append("otp", otp)
        bodyFormData.append("email", email)
        


        let result = await axios.post(url, bodyFormData, {
            headers: { "Content-Type": "multipart/form-data" },
        })

        if (result.data && result.data.tokenId) {

            dispatch(SetForgotPasswordScreenFlow({"forgetPasswordflow":2}))
            dispatch(setAuthIdOTP({"authIdOTP":result.data.authId}))
            dispatch({type:TOGGLE_PROCESS_LOADER,payload:{processLoader:false}})
        }
        else {
            dispatch({type:SET_FORGOT_PASS_ALERT})
            // dispatch(SetForgotPasswordScreenFlow({"forgetPasswordflow":0}))
            dispatch(setAuthIdOTP({"authIdOTP":null}))
            
            dispatch({type:TOGGLE_PROCESS_LOADER,payload:{processLoader:false}})
        }


    }
    catch (err) {
        dispatch({type:SET_FORGOT_PASS_ALERT})
        // dispatch(SetForgotPasswordScreenFlow({"forgetPasswordflow":0}))
            dispatch(setAuthIdOTP({"authIdOTP":null}))
        
        dispatch({type:TOGGLE_PROCESS_LOADER,payload:{processLoader:false}})

        
    }
}

