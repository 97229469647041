import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Stack from '@mui/material/Stack';
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import { connect } from "react-redux";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Drawer from "@mui/material/Drawer";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Link from "@mui/material/Link";
import logo from "./logo.png";
import CssBaseline from "@mui/material/CssBaseline";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import { Redirect, useLocation } from "react-router-dom";
import { Link as LinkReact } from "react-router-dom";
import { LOGOUT_SUCCESS } from "../../../Redux/ActionTypes/authenticationActionTypes";
import {
  clearLoginFailAlert,
  clearSignupExistsAlert,
} from "../../../Redux/ActionCreators/alertsActionCreators";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { clearForgotPasswordError } from "../../../Redux/ActionCreators/alertsActionCreators";
import {
  LoginOpenRequest,
  profileFormOpenRequest,
  signupOpenRequest,
} from "../../../Redux/ActionCreators/NavbarActionCreators";
import {
  CLEAR_GENERIC_ERROR_ALERT,
  CLEAR_QUERY_ADDED_ALERT,
  CLEAR_BOOKMARK_ADDED_ALERT,
} from "../../../Redux/ActionTypes/alertsActionTypes";
import { Alert } from "@mui/material";
import { toggleGlobalAlert } from "../../../Redux/ActionCreators/alertsActionCreators";
import logoIn from "./logoIn.png";

const Search = styled("Box")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const SearchIconWrapperMob = styled("div")(({ theme }) => ({
  // padding: theme.spacing(0, 2),
  height: "100%",

  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "inherit",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const HeaderButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 700,
  fontSize: 14,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#8080cf",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "grey",
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const NavbarV2 = ({
  isAuthenticated,
  userStatus,
  logoutInit,
  clearLoginAlert,
  loginAlert,
  clearSignupExistsAlert,
  signupExistAlert,
  clearForgotPasswordError,
  forgotPasswordAlert,
  displayName,
  queryAddedLCAlert,
  clearQueryAddedLCAlert,
  clearGnericErrorAlert,
  genericAlert,
  clearBookmarkAddedMsg,
  bookmarkAddedAlert,
  globalAlertOpen,
  globalAlertType,
  globalAlertMessage,
  toggleGlobalAlert,
  userType
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loginError, setLoginError] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const firstName = displayName.split(" ")[0];

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const location = useLocation()
  let currentNavPath = location && location.pathname ? location.pathname : ""

  const handleMenuCloseLogout = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    logoutInit();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
      <MenuItem onClick={handleMenuCloseLogout}>Logout</MenuItem>
    </Menu>
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    clearLoginAlert();
  };

  const handleCloseSignupExist = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    clearSignupExistsAlert();
  };
  const handleCloseForgotPasswordError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    clearForgotPasswordError();
  };
  const handleCloseQueryAddedError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    clearQueryAddedLCAlert();
  };
  const handleCloseGenericError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    clearGnericErrorAlert();
  };

  const handleCloseBookmarkAddedAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    clearBookmarkAddedMsg();
  };

  const handleSideMenuClose = () => {
    setAnchorEl(null);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}></Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const action2 = (
    <React.Fragment>
      <Button
        color="secondary"
        size="small"
        onClick={handleCloseSignupExist}
      ></Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSignupExist}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const action3 = (
    <React.Fragment>
      <Button
        color="secondary"
        size="small"
        onClick={handleCloseForgotPasswordError}
      ></Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseForgotPasswordError}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const action4 = (
    <React.Fragment>
      <Button
        color="secondary"
        size="small"
        onClick={handleCloseQueryAddedError}
      ></Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseQueryAddedError}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const action5 = (
    <React.Fragment>
      <Button
        color="secondary"
        size="small"
        onClick={handleCloseGenericError}
      ></Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseGenericError}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const action6 = (
    <React.Fragment>
      <Button
        color="secondary"
        size="small"
        onClick={handleCloseBookmarkAddedAlert}
      ></Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseBookmarkAddedAlert}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}
      {/* <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={0} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>

          <Box component={LinkReact} to="/">
            <img style={{

              height: "100%",
              width: 200,

              borderRadius: 10,
            }} src={window.location.href.includes(".ph")?logo:logoIn} alt="logo" />
          </Box>

        </ListItem>
        {[
          `Welcome ${displayName}`,
          "Login",
          "Sign Up",
          "Courses",
          "Learning Content",
          "Webinars",
          "Queries",
          "Feedback",
          "Management"
        ].map((text, index) => {
          if (userType === "Vet" && index === 8 || index === 7) {
            return null
          }
          else if (
            (!isAuthenticated && (index === 1 || index === 2)) ||
            (isAuthenticated &&
              userStatus === "registered" &&
              index !== 1 &&
              index !== 2) ||
            (isAuthenticated && userStatus !== "registered" && index === 0)
          ) {
            return (
              <ListItem
                button
                key={text}
                onClick={
                  index === 1
                    ? () => {

                    }
                    : index === 2
                      ? () => {

                      }
                      : index === 3
                        ? () => {

                        }
                        : index === 4
                          ? () => {

                          }
                          : index === 5
                            ? () => {

                            }
                            : index === 6
                              ? () => {

                              }
                              : () => { }
                }
                component={LinkReact}
                to={
                  index === 1
                    ? "/login"
                    : index === 2
                      ? "/signup"
                      : index === 3
                        ? "/courses"
                        : index === 4
                          ? "/learningContent"
                          :
                          index === 5 ?
                            "/webinar"
                            : index === 6 ?
                              "/queries"
                              : index === 8 ?
                                "/management"

                                : "/"
                }
              >
                {
                  // <InboxIcon />
                  index === 0 && isAuthenticated && (
                    <ListItemIcon sx={{ color: "#1976d2" }}>
                      <AccountCircleIcon />
                    </ListItemIcon>
                  )
                }
                {
                  //adding redirections
                }
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: "bold",
                    fontFamily: "default",
                    letterSpacing: 0,
                  }}
                />
              </ListItem>
            );
          }
        })}
      </List>
      <Divider />
      <List>
        <ListItem button onClick={() => { }}>
          <ListItemIcon>
            <MailIcon />
          </ListItemIcon>
          <ListItemText primary={"Help"} />
        </ListItem>
        {isAuthenticated && (
          <>
            {((userStatus === "registered" || userStatus === "Unregistered") && userType === "Vet") && (
              <ListItem button component={LinkReact} to="/changePassword">
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={"Change Password"} />
              </ListItem>
            )}
            <ListItem
              button
              component={LinkReact}
              to="/"
              onClick={() => {

                if (userType === "Admin") {
                  localStorage.removeItem("token");
                  localStorage.removeItem("adminType")
                  window.location = "/api/login/logout"
                }

                else {
                  logoutInit()
                }

              }
              }


            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={"Sign out"} />
            </ListItem>
          </>
        )}
      </List>
    </Box >
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CssBaseline />
      <Snackbar
        open={loginAlert}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Invalid credentials"
        action={action}
      />
      <Snackbar
        open={signupExistAlert}
        autoHideDuration={2000}
        onClose={handleCloseSignupExist}
        message="Account already exists.Please login"
        action={action2}
      />
      <Snackbar
        open={forgotPasswordAlert}
        autoHideDuration={2000}
        onClose={handleCloseForgotPasswordError}
        message="Incorrect OTP"
        action={action3}
      />
      <Snackbar
        open={queryAddedLCAlert}
        autoHideDuration={2000}
        onClose={handleCloseQueryAddedError}
        message="Query Added"
        action={action4}
      />
      <Snackbar
        open={genericAlert}
        autoHideDuration={3000}
        onClose={handleCloseGenericError}
        message="Some error occured!"
        action={action5}
      />
      <Snackbar
        open={bookmarkAddedAlert}
        autoHideDuration={2000}
        onClose={handleCloseBookmarkAddedAlert}
        message="Bookmark Added!"
        action={action6}
      />

      <Snackbar
        open={globalAlertOpen}
        autoHideDuration={3000}
        onClose={() => toggleGlobalAlert("success", "", false)}
      >
        <Alert
          onClose={() => toggleGlobalAlert("success", "", false)}
          severity={globalAlertType}

        >
          {globalAlertMessage}
        </Alert>
      </Snackbar>

      <Menu
        sx={{ width: 230 }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleSideMenuClose}
        onClick={handleSideMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem sx={{ justifyContent: "center" }}>
          <Avatar />
        </MenuItem>
        <MenuItem>
          <Typography sx={{ fontSize: 15, color: "#0000A0", marginLeft: 2.5}}variant="inherit" noWrap>
            {displayName}
          </Typography>
        </MenuItem>

        <Divider />

        {/* <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}
        {((userStatus === "registered" || userStatus === "Unregistered") && userType === "Vet") && (
          <MenuItem button component={LinkReact} to="/changePassword">
            <ListItemIcon>
              <BorderColorIcon fontSize="small" />
            </ListItemIcon>
            <Typography noWrap sx={{ fontSize: 15 }} >Change Password</Typography>
          </MenuItem>
        )}

        <MenuItem
          button
          component={LinkReact}
          to="/"
          onClick={() => {

            if (userType === "Admin") {
              localStorage.removeItem("token");
              localStorage.removeItem("adminType")
              window.location = "/api/login/logout"
            }

            else {
              logoutInit()
            }

          }
          }






        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <Typography noWrap sx={{ fontSize: 15 }} >Logout</Typography>
        </MenuItem>
      </Menu>

      <AppBar
        sx={{ margin: 0 }}
        style={{ backgroundColor: "#ffffff" }}
        position="fixed"
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            aria-label="open drawer"
            sx={{ mr: 2, display: { xs: "flex", sm: "flex", md: "none" } }}
            onClick={toggleDrawer("left", true)}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{display:"flex",alignItems:"center"}} component={LinkReact} to="/">
            <img style={{
              height: "100%",
              width: 180,

              borderRadius: 10,
            }} src={window.location.href.includes(".ph")?logo:logoIn} alt="logo" />
          </Box>
          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'flex', sm: 'block' } }}
          >
            Vet 2 Vet Connect
          </Typography> */}
          {isAuthenticated && userStatus === "registered" && (
            <Box
              className="mainButtons"
              ml={2}
              sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
            >
              {userType === "Admin" && <Link
                component={LinkReact}
                to="/management"
                underline="none"
                variant="body2"
                sx={{ color: currentNavPath.toLowerCase().includes("management") ? "#0000a0" : "#383838", marginLeft: 5 }}
              >
                Management
              </Link>}
              <Link
                component={LinkReact}
                to="/courses"
                underline="none"
                variant="body2"
                sx={{ color: currentNavPath.toLowerCase().includes("course") ? "#0000a0" : "#383838", marginLeft: 5 }}
              >
                Courses
              </Link>
              <Link
                component={LinkReact}
                to="/learningContent"
                underline="none"
                variant="body2"
                sx={{
                  color: currentNavPath.toLowerCase().includes("learning")
                    || currentNavPath.toLowerCase().includes("article")
                    ? "#0000a0" : "#383838", marginLeft: 5
                }}
              >
                <Typography  > Learning Content</Typography>
              </Link>{" "}
              <Link
                component={LinkReact}
                to="/webinar"
                underline="none"
                variant="body2"
                sx={{ color: currentNavPath.toLowerCase().includes("webinar") ? "#0000a0" : "#383838", marginLeft: 5 }}
              >
                Webinars
              </Link>
              <Link
                component={LinkReact}
                to="/queries"
                underline="none"
                variant="body2"
                sx={{ color: currentNavPath.toLowerCase().includes("queries") ? "#0000a0" : "#383838", marginLeft: 5 }}
              >
                Queries
              </Link>
              {/* <Link
                component={LinkReact}
                to="/feedback"
                underline="none"
                variant="body2"
                sx={{ color: "#383838", marginLeft: 5 }}
              >
                Feedback
              </Link> */}
            </Box>
          )}
          <Box className="emptySpace" sx={{ flexGrow: 1 }} />
          
          <div>
            <Drawer
              anchor={"left"}
              open={state["left"]}
              onClose={toggleDrawer("left", false)}
            >
              {list("left")}
            </Drawer>
          </div>

          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>

            <StyledInputBase
              isMobileMenuOpen={isMobileMenuOpen}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search> */}

          {isAuthenticated && (
            <>
              <SearchIconWrapperMob>
                {/* <IconButton size="medium">
                  <BookmarkIcon sx={{ color: "#8080cf" }} />
                </IconButton> */}
              </SearchIconWrapperMob>

              <SearchIconWrapperMob>
                <IconButton size="medium">
                  <Badge badgeContent={0} color="error">
                    {/* <NotificationsIcon sx={{ color: "#8080cf" }} /> */}
                  </Badge>
                </IconButton>
              </SearchIconWrapperMob>
            </>
          )}

          {isAuthenticated ? (
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                {/* <BookmarkIcon
                  size="large"
                  sx={{ color: "#8080cf" }}
                ></BookmarkIcon> */}
              </IconButton>
              <div style={{display: "flex", alignItems: "center"}}>
                <Typography style={{color: "#6ad7b9"}}>Welcome</Typography> &nbsp;   <Typography style={{color: "#383838", alignItems: "center"}}>{firstName}</Typography>
              {/* <Typography style={{color: "#383838", alignItems: "center", fontSize: 16}}>Welcome {displayName}</Typography> */}
              </div>
              
              {/* <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={0} color="error">
                  <NotificationsIcon sx={{ color: "#8080cf" }} />
                </Badge>
              </IconButton> */}
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                sx={{ color: "#8080cf" }}
              >
                
                <AccountCircle />
              </IconButton>
            </Box>
          ) : (
            <Box sx={{ display: "flex"}}>
              <Stack spacing={2} direction="row">
                <HeaderButton variant="text" component={LinkReact} to="/login" onClick={() => { }}>Login</HeaderButton>
                <HeaderButton style={{width: 140}} variant="contained" component={LinkReact} to="/signup" onClick={() => { }}>Sign Up</HeaderButton>
              </Stack>
              {/* <ColorButton
                component={LinkReact}
                to="/login"
                onClick={() => { }}
                variant="contained"
                style={{marginRight: 5}}
              >
                Login
              </ColorButton>
              <ColorButton
                variant="contained"
                component={LinkReact}
                to="/signup"
                onClick={() => { }}
              >
                Sign Up
              </ColorButton> */}
            </Box>
          )}
          {isAuthenticated && (
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              {/* <IconButton
              size="large"
              aria-label="show more"
              // aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton> */}
              {/* <Badge aria-controls={mobileMenuId} badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge> */}
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {/* {renderMobileMenu} */}
    </Box >
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authenticationReducer.isAuthenticated,
  userStatus: state.authenticationReducer.userStatus,
  userType: state.authenticationReducer.userType,
  loginModal: state.NavigationReducer.loginModalOpen,
  signUpModal: state.NavigationReducer.signupModalOpen,
  createProfileModal: state.NavigationReducer.profileFormModalOpen,
  loginAlert: state.AlertsReducer.loginErrorAlert,
  signupExistAlert: state.AlertsReducer.signupExistAlert,
  forgotPasswordAlert: state.AlertsReducer.forgotPasswordAlert,
  queryAddedLCAlert: state.AlertsReducer.queryAddedLCAlert,
  genericAlert: state.AlertsReducer.genericAlert,
  displayName: state.authenticationReducer.profile
    ? state.authenticationReducer.profile.full_name
    : "User",
  bookmarkAddedAlert: state.AlertsReducer.bookmarkAddedAlert,
  globalAlertOpen: state.AlertsReducer.globalAlertOpen,
  globalAlertType: state.AlertsReducer.globalAlertType,
  globalAlertMessage: state.AlertsReducer.globalAlertMessage,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logoutInit: () => dispatch({ type: LOGOUT_SUCCESS }),
    clearLoginAlert: () => dispatch(clearLoginFailAlert()),
    clearSignupExistsAlert: () => dispatch(clearSignupExistsAlert()),
    clearForgotPasswordError: () => dispatch(clearForgotPasswordError()),
    clearQueryAddedLCAlert: () => dispatch({ type: CLEAR_QUERY_ADDED_ALERT }),
    clearGnericErrorAlert: () => dispatch({ type: CLEAR_GENERIC_ERROR_ALERT }),
    clearBookmarkAddedMsg: () => dispatch({ type: CLEAR_BOOKMARK_ADDED_ALERT }),
    toggleGlobalAlert: (type, message, value) => dispatch(toggleGlobalAlert(type, message, value)),

    // openLogin: () => dispatch(LoginOpenRequest()),
    // openProfile: () => dispatch(profileFormOpenRequest()),
    // openSignup: () => dispatch(signupOpenRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarV2);
