export const classes = {
  heading: { fontWeight: "bold" },
  search: { width: "25%", margin: "1% 0", marginTop: 0 },
  grid: { marginBottom: "2%" },
  filter: { width: "100%", margin: "5% 0", marginTop: 0 },
  filterButton: { marginTop: "16px", width: "75%", fontSize: "80%", textTransform:"none" },
  pagination: { margin: "3% 0", justifyContent: "center", display: "flex" },
  noArticleDisclaimer: { textAlign: "center", margin: "3%", width: "100%" },
  clearAllButton: { marginLeft: "3%", marginTop:"16px", width: "20%" },
  biggerSkeleton: { margin: "2%", height: "100px", borderRadius: "5px" },
  skeleton: { margin: "2%", borderRadius: "5px" },
  smallerSkeleton: { margin: "2%", width: "70%", borderRadius: "5px" },
  menuItem: { whiteSpace: "normal" },
  menu: {
    maxHeight: 48 * 4.5 + 8,
    width: "20%",
  },
};
