import { FETCH_COURSES_DATA, SET_COURSES_DATA, SET_COURSES_USER_DATA, CLEAR_COURSES_DATA, SET_COURSES_FAILURE_DATA,
    SET_ACTIVE_COURSE_DATA, CLEAR_ACTIVE_COURSE_DATA,
    SET_COURSES_DATA_PAGINATION} from "../../ActionTypes/coursesVetActionTypes"

import { SET_QUERY_ADDED_ALERT,CLEAR_QUERY_ADDED_ALERT,SET_GENERIC_ERROR_ALERT } from "../../ActionTypes/alertsActionTypes"

import axios from "axios"

//Login 
export const lessonCompletion = (course,chapter,lesson) => async dispatch => {


    try {
        
        let reqObj={
            "type" : "lesson",
            "chapter_id" : chapter,
            "lesson_id" : lesson,
            "course_id" :course
            
            }

        let url = process.env.REACT_APP_API_URL+`/vetcourses/completion_status/`
        



        let result = await axios.post(url, reqObj)

        if (result.data) {
            let url2 = process.env.REACT_APP_API_URL+`/vetcourses/sideview/`
        



            let result = await axios.post(url2, reqObj)

            
            dispatch({ type: SET_ACTIVE_COURSE_DATA, payload: {isEnrolled: true, data:result.data } })


          


        }
       

        

    }
    catch (err) {
       
        dispatch({ type: SET_GENERIC_ERROR_ALERT })
    }
}

