
import { SET_QUERY_ADDED_ALERT,CLEAR_QUERY_ADDED_ALERT,SET_GENERIC_ERROR_ALERT } from "../../ActionTypes/alertsActionTypes"

import axios from "axios"

//Login 
export const addQueryCourses = (moduleId,queryCategory,queryText) => async dispatch => {


    try {
        let reqObj = {
            "module_id": moduleId,
            "content_type": "Courses",  
            "query_category": queryCategory, //Medical Paper   <-- sample input
            "query_text": queryText
        }

        // dispatch({ type: FETCH_ARTICLE_USER_DATA })
        let url = process.env.REACT_APP_API_URL+"/vetcourses/addquery/"



        let result = await axios.post(url, reqObj)

        if (result.data) {

            


            dispatch({ type: SET_QUERY_ADDED_ALERT })


        }
        else {
            dispatch({ type: SET_GENERIC_ERROR_ALERT })
        }

        

    }
    catch (err) {
        dispatch({ type: SET_GENERIC_ERROR_ALERT })
    }
}

