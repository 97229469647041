import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  TextField,
  Autocomplete,
  Button,
  Card,
  CardContent,
  Typography,
  Pagination,
  IconButton,
  CircularProgress,
  Tooltip,
  Skeleton,
  InputAdornment,
  OutlinedInput,
  FormHelperText,
  FormControl,
  // OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  Select,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FilterAlt, ClearAll, Search } from "@mui/icons-material";
import { getFilterValues } from "../../../Redux/Middlewares/LearningContentAdmin/getFilterValuesMIddleware";
import {
  changePage,
  selectArticle,
  selectFilter,
} from "../../../Redux/ActionCreators/learningContentAdminActionCreators";
import { getLearningContent } from "../../../Redux/Middlewares/LearningContentAdmin/getLearningContentMiddleware";
import ArticleCard from "../ArticleCard/ArticleCard";
import { fileDelete } from "../../../Redux/Middlewares/LearningContentAdmin/deleteLearningContentMiddleware";
import { classes } from "./styles";

const UploadedContent = ({
  getFilterValues,
  filters,
  selectFilter,
  selectedFilters,
  getLearningContent,
  learningContent,
  setIsArticleClicked,
  changePage,
  selectArticle,
  pageNumber,
  setIsFileSelected,
  isLoading,
  fileDelete,
}) => {
  const options = [
    { title: "Alpha", year: 1 },
    { title: "Beta", year: 2 },
    { title: "Gamma", year: 3 },
    { title: "Delta", year: 4 },
  ];

  

  useEffect(() => {
    // if (Object.keys(filters).length) return;
    getFilterValues();
  }, []);

  useEffect(() => {
    // if (Object.keys(learningContent).length) return;
    fetchLearningContent(pageNumber);
  }, []);

  const handleCardClick = (article) => {
    selectArticle(article);
    setIsArticleClicked(true);
  };

  const handleDateOutput = (monthDifference = 0) => {
    const date = new Date();
    date.setMonth(date.getMonth() - monthDifference);
    const dateArray = date.toLocaleDateString().split("/");
    dateArray.splice(1, 1);
    const newDate = dateArray.join("/");
    return newDate;
  };

  const fetchLearningContent = (currentPage) => {
    
    const getDateObject = () => {
      if (
        selectedFilters.show_content_from === "" ||
        selectedFilters.show_content_from === null ||
        selectedFilters.show_content_from.value === "All time"
      )
        return {
          fixed: "All time",
          start_date: null,
          end_date: null,
        };
      else if (selectedFilters.show_content_from.value === "1 Month")
        return {
          fixed: "1 Month",
          start_date: null,
          end_date: null,
        };
      else if (selectedFilters.show_content_from.value === "3 Months")
        return {
          fixed: "3 Months",
          start_date: null,
          end_date: null,
        };
      else if (selectedFilters.show_content_from.value === "6 Months")
        return {
          fixed: "6 Months",
          start_date: null,
          end_date: null,
        };
    };
    const filterObject = {
      // file_type: selectedFilters.file_content_type
      //   ? [selectedFilters.file_content_type.value]
      //   : [],
      // author: selectedFilters.author ? [selectedFilters.author.author] : [],
      // file_category: selectedFilters.file_category
      //   ? [selectedFilters.file_category.category_name]
      //   : [],
      // file_species: selectedFilters.file_species
      //   ? [selectedFilters.file_species.value]
      //   : [],
      file_type: selectedFilters.file_content_type,
      author: selectedFilters.author,
      file_category: selectedFilters.file_category,
      file_species: selectedFilters.file_species,
      show_content_from: getDateObject(),
      search: selectedFilters.search ? [selectedFilters.search] : [],
    };
    getLearningContent(filterObject, currentPage);
  };

  const handleApplyFiltersClick = () => {
    changePage(1);
    fetchLearningContent(1);
  };

  const handlePageChange = (event, value) => {
    changePage(value);
    fetchLearningContent(value);
  };

  const cardArray = isLoading ? (
    <>
      <Grid item xs={4}>
        <Skeleton variant="rectangular" sx={classes.biggerSkeleton} />
        <Skeleton variant="rectangular" sx={classes.skeleton} />
        <Skeleton variant="rectangular" sx={classes.smallerSkeleton} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant="rectangular" sx={classes.biggerSkeleton} />
        <Skeleton variant="rectangular" sx={classes.skeleton} />
        <Skeleton variant="rectangular" sx={classes.smallerSkeleton} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant="rectangular" sx={classes.biggerSkeleton} />
        <Skeleton variant="rectangular" sx={classes.skeleton} />
        <Skeleton variant="rectangular" sx={classes.smallerSkeleton} />
      </Grid>
    </>
  ) : learningContent !== undefined &&
    Object.keys(learningContent).length &&
    learningContent.results.length ? (
    learningContent.results.map((article, key) => (
      <ArticleCard
        handleCardClick={handleCardClick}
        article={article}
        index={key}
        setIsFileSelected={setIsFileSelected}
        selectArticle={selectArticle}
        isLoading={isLoading}
        fileDelete={fileDelete}
        fetchLearningContent={fetchLearningContent}
        pageNumber={pageNumber}
      />
    ))
  ) : (
    <p style={classes.noArticleDisclaimer}>No Articles found !</p>
  );

  const [clearFilterClicked, setClearFilterClicked] = useState(0);

  useEffect(() => {
    if (!clearFilterClicked) return;
    changePage(1);
    fetchLearningContent(1);
  }, [clearFilterClicked]);

  const handleClearFilter = () => {
    
    selectFilter("show_content_from", { value: "All time" });
    selectFilter("file_species", []);
    selectFilter("author", []);
    selectFilter("file_content_type", []);
    selectFilter("file_category", []);
    selectFilter("search", "");
    setClearFilterClicked(clearFilterClicked + 1);
    // setTimeout(() => {
    //   changePage(1);
    //   fetchLearningContent(1);
    // }, 3000);
  };

  const MenuProps = {
    PaperProps: {
      style: classes.menu,
    },
  };

  return (
    <>
      <p className="uploaded_content_heading" style={classes.heading}>
        Uploaded Content
      </p>
      {/* <FormHelperText id="outlined-weight-helper-text">Weight</FormHelperText>
        </FormControl> */}
      {/* <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
      {/* <FormHelperText id="show_content_from_filter">Search</FormHelperText> */}
      <OutlinedInput
        id="search_textfield"
        type="text"
        placeholder="Search"
        size="small"
        variant="outlined"
        className="search_field"
        sx={classes.search}
        value={selectedFilters["search"]}
        placeholder="Search,author name..."
        onChange={(e) => selectFilter("search", e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="search button"
              onClick={handleApplyFiltersClick}
              edge="end"
            >
              <Search />
            </IconButton>
          </InputAdornment>
        }
      />
      {/* </FormControl> */}
      {/* <TextField
        id="search_textfield"
        placeholder="Search"
        variant="outlined"
        className="search_field"
        sx={classes.search}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        value={selectedFilters["search"]}
        onChange={(e) => selectFilter("search", e.target.value)}
      /> */}
      <Grid container spacing={2} className="filter_section" sx={classes.grid} direction="row"
        alignItems="center">
        <Grid item xs={2}>
          <FormHelperText id="show_content_from_filter">
            Show content from
          </FormHelperText>
          <Autocomplete
            size="small"
            disablePortal
            id="show_content_from_filter"
            options={
              filters.show_content_from && Object.keys(filters).length
                ? filters.show_content_from
                : []
            }
            sx={classes.filter}
            loading={isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                // label="Show content from"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            getOptionLabel={(option) => option.value || ""}
            value={selectedFilters["show_content_from"]}
            onChange={(e, value) =>
              selectFilter("show_content_from", value === null ? "" : value)
            }
          />
        </Grid>
        <Grid item xs={2}>
          {/* <FormControl sx={classes.filter}>
            <FormHelperText id="category_filter">Category</FormHelperText>
            <Select
              labelId="category_filter_label"
              id="category_filter"
              multiple
              value={selectedFilters["file_category"]}
              onChange={(e) => selectFilter("file_category", e.target.value)}
              input={<OutlinedInput />}
              renderValue={(selected) => selected.join(", ")}
              size="small"
              MenuProps={MenuProps}
            >
              {filters.file_category &&
                filters.file_category.map((obj) => (
                  <MenuItem
                    key={obj.category_name}
                    value={obj.category_name}
                    sx={classes.menuItem}
                  >
                    <Checkbox
                      checked={
                        selectedFilters.file_category.indexOf(
                          obj.category_name
                        ) > -1
                      }
                    />
                    <ListItemText primary={obj.category_name} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl> */}
          <FormHelperText id="category_filter">Category</FormHelperText>
          <Autocomplete
            limitTags={1}
            multiple
            size="small"
            disablePortal
            id="category_filter"
            options={
              filters.file_category && Object.keys(filters).length
                ? filters.file_category.map((obj) => obj.category_name)
                : []
            }
            sx={classes.filter}
            loading={isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                // label="Category"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            getOptionLabel={(option) => option || ""}
            value={selectedFilters["file_category"]}
            onChange={(e, value) =>
              selectFilter("file_category", value === null ? [] : value)
            }
          />
        </Grid>
        <Grid item xs={2}>
          {/* <FormControl sx={classes.filter}>
            <FormHelperText id="author_filter">Author</FormHelperText>
            <Select
              labelId="author_filter_label"
              id="author_filter"
              multiple
              value={selectedFilters["author"]}
              onChange={(e) => selectFilter("author", e.target.value)}
              input={<OutlinedInput />}
              renderValue={(selected) => selected.join(", ")}
              size="small"
              // loading={isLoading}
              MenuProps={MenuProps}
            >
              {filters.author &&
                filters.author.map((obj) => (
                  <MenuItem
                    key={obj.author}
                    value={obj.author}
                    sx={classes.menuItem}
                  >
                    <Checkbox
                      checked={selectedFilters.author.indexOf(obj.author) > -1}
                    />
                    <ListItemText primary={obj.author} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl> */}
          <FormHelperText id="author_filter">Author</FormHelperText>
          <Autocomplete
            limitTags={1}
            multiple
            size="small"
            disablePortal
            id="author_filter"
            options={
              filters.author && Object.keys(filters).length
                ? filters.author.map((obj) => obj.author)
                : []
            }
            sx={classes.filter}
            loading={isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                // label="Author"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            getOptionLabel={(option) => option || ""}
            value={selectedFilters["author"]}
            onChange={(e, value) =>
              selectFilter("author", value === null ? [] : value)
            }
          />
        </Grid>
        <Grid item xs={2}>
          {/* <FormControl sx={classes.filter}>
            <FormHelperText id="species_filter">Species</FormHelperText>
            <Select
              labelId="species_filter_label"
              id="species_filter"
              multiple
              value={selectedFilters["file_species"]}
              onChange={(e) => selectFilter("file_species", e.target.value)}
              input={<OutlinedInput />}
              renderValue={(selected) => selected.join(", ")}
              size="small"
              MenuProps={MenuProps}
              // loading={isLoading}
            >
              {filters.file_species &&
                filters.file_species.map((obj) => (
                  <MenuItem
                    key={obj.value}
                    value={obj.value}
                    sx={classes.menuItem}
                  >
                    <Checkbox
                      checked={
                        selectedFilters.file_species.indexOf(obj.value) > -1
                      }
                    />
                    <ListItemText primary={obj.value} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl> */}
          <FormHelperText id="species_filter">Species</FormHelperText>
          <Autocomplete
            limitTags={1}
            multiple
            size="small"
            disablePortal
            id="species_filter"
            options={
              filters.file_species && Object.keys(filters).length
                ? filters.file_species.map((obj) => obj.value)
                : []
            }
            sx={classes.filter}
            loading={isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                // label="Species"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            getOptionLabel={(option) => option || ""}
            value={selectedFilters["file_species"]}
            onChange={(e, value) =>
              selectFilter("file_species", value === null ? [] : value)
            }
          />
        </Grid>
        <Grid item xs={2}>
          {/* <FormControl sx={classes.filter}>
            <FormHelperText id="type_of_content_filter">
              Type of content
            </FormHelperText>
            <Select
              labelId="type_of_content_filter_label"
              id="type_of_content_filter"
              multiple
              value={selectedFilters["file_content_type"]}
              onChange={(e) =>
                selectFilter("file_content_type", e.target.value)
              }
              input={<OutlinedInput />}
              renderValue={(selected) => selected.join(", ")}
              size="small"
              MenuProps={MenuProps}
              // loading={isLoading}
            >
              {filters.file_content_type &&
                filters.file_content_type.map((obj) => (
                  <MenuItem
                    key={obj.value}
                    value={obj.value}
                    sx={classes.menuItem}
                  >
                    <Checkbox
                      checked={
                        selectedFilters.file_content_type.indexOf(obj.value) >
                        -1
                      }
                    />
                    <ListItemText primary={obj.value} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl> */}
          <FormHelperText id="type_of_content_filter">
            Type of content
          </FormHelperText>
          <Autocomplete
            limitTags={1}
            multiple
            size="small"
            disablePortal
            id="type_of_content_filter"
            options={
              filters.file_content_type && Object.keys(filters).length
                ? filters.file_content_type.map((obj) => obj.value)
                : []
            }
            sx={classes.filter}
            loading={isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                // label="Type of content"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            getOptionLabel={(option) => option || ""}
            value={selectedFilters["file_content_type"]}
            onChange={(e, value) =>
              selectFilter("file_content_type", value === null ? [] : value)
            }
          />
        </Grid>
        <Grid item xs={2}>
          {/* <Button
            variant="outlined"
            sx={classes.filterButton}
            onClick={handleApplyFiltersClick}
          >
            Apply
          </Button> */}
          <div style={{display:"flex", flexWrap:"nowrap", width:"100%", marginTop:"-2%" }}>
            <LoadingButton
              variant="contained"
              sx={classes.filterButton}
              onClick={handleApplyFiltersClick}
              endIcon={<FilterAlt />}
              loading={isLoading}
              loadingPosition="end"
            >
              Apply
            </LoadingButton>
            <Tooltip title="Clear Filters" arrow>
              <IconButton
                color="primary"
                aria-label="clear filter"
                component="span"
                sx={classes.clearAllButton}
                onClick={handleClearFilter}
              >
                <ClearAll />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="content_section">
        {cardArray}
      </Grid>
      {learningContent !== undefined &&
        Object.keys(learningContent).length > 0 &&
        learningContent.results.length > 0 && (
          learningContent.count
          && Math.ceil(Number(learningContent.count) / 10) > 1 &&
          <Pagination
            count={
              learningContent.count
                ? Math.ceil(Number(learningContent.count) / 10)
                : 10
            }
            page={pageNumber}
            onChange={handlePageChange}
            sx={classes.pagination}
            color="primary"
            hidePrevButton
            hideNextButton
          />
        )}
    </>
  );
};

const mapStateToProps = (state) => ({
  filters: state.LearningContentAdminReducer.filters,
  selectedFilters: state.LearningContentAdminReducer.selectedFilters,
  learningContent: state.LearningContentAdminReducer.learningContent,
  pageNumber: state.LearningContentAdminReducer.pageNumber,
  isLoading: state.LearningContentAdminReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getFilterValues: () => dispatch(getFilterValues()),
  selectFilter: (filterType, filterValue) =>
    dispatch(selectFilter(filterType, filterValue)),
  getLearningContent: (filterDetails, pageNumber) =>
    dispatch(getLearningContent(filterDetails, pageNumber)),
  changePage: (pageNumber) => dispatch(changePage(pageNumber)),
  selectArticle: (article) => dispatch(selectArticle(article)),
  fileDelete: (id) => dispatch(fileDelete(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadedContent);
