import React, { useState, useEffect } from "react";
// import "./styles.css";
import { useHistory, withRouter } from "react-router-dom";
import {
    Box, Modal, Drawer, Typography, Grid, TextField, Button, CssBaseline, Select, OutlinedInput,
    ListItemText, Checkbox, Tooltip
} from '@mui/material';
import { LoadingButton } from "@mui/lab";
import { connect } from "react-redux"
import { login } from "../Redux/Middlewares/loginMiddleware";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { styled, alpha } from "@mui/material/styles";
import { LoginCloseRequest } from "../Redux/ActionCreators/NavbarActionCreators"
import { useFormik } from "formik"
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { Redirect } from "react-router-dom";
import Chip from '@mui/material/Chip';
import Rating from '@mui/material/Rating';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Document, Page, pdfjs } from 'react-pdf';
import test1 from "./test1.pdf"
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import ScrollToTopButton from "./ScrollToTopButton"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import StyledPDFContainer from "./StyledPDFContainer";
import { getLearningContentList } from "../Redux/Middlewares/getLearningContentListMiddleware";
import SpinnerLG from "../SpinnerLG";
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Divider from '@mui/material/Divider';
import MUILink from '@mui/material/Link';
import { getFiltersLC } from "../Redux/Middlewares/getFiltersLCMiddleware";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { rateNBookmarkLC } from "../Redux/Middlewares/rateNBookmarkLearningContentMiddleware"
import { addQueryLC } from "../Redux/Middlewares/addQueryLCMiddleware"
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';


import { SET_USER_FILTER_LEARNING_CONTENT, CLEAR_USER_FILTER_LEARNING_CONTENT } from "../Redux/ActionTypes/learningContentActionTypes"
import { ClearAll } from "@mui/icons-material";



const applyButtonStyle = { marginTop: "0px", width: "100%", fontSize: "80%",textTransform:"none" }

// const Login = ({ setLoggedInUser }) => {
const LearningContentContainer = ({ isLoading, currentPage, isAuthenticated, getLearningContentList,
    userStatus, articlesList, totalCount, getFiltersLC, rateNBookmarkLC, addQueryLC, filterData,
    timeFilter, categoryFilter, authorFilter, speciesFilter, contentFilter, setFilter
}) => {
    const theme = useTheme();
    const [reqObj, setReqObj] = useState({
        "file_type": contentFilter,
        "author": authorFilter,
        "file_category": categoryFilter,
        "search": [],
        "file_species": speciesFilter,
        "show_content_from": {
            "fixed": timeFilter[0],
            "start_date": null,
            "end_date": null
        }
    });

    const [currentSelection, setCurrentSelection] = useState({})
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(4);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [queryText, setQueryText] = useState("");
    const [isModalOpen, setModal] = React.useState(false);
    const [state, setState] = React.useState(false);
    const [category, setCategory] = React.useState(categoryFilter);
    const [author, setAuthor] = React.useState(authorFilter);
    const [species, setSpecies] = React.useState(speciesFilter);
    const [dateFilter, setDateFilter] = React.useState(timeFilter);
    const [type, setType] = React.useState(contentFilter);
    const [queryError, setQueryError] = React.useState("");
    const [searchText, setSearchText] = React.useState("");

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    // timeFilter, categoryFilter, authorFilter, speciesFilter, contentFilter,setFilter
    const saveFilter = (item) => {
        setFilter({ categoryFilter: category, authorFilter: author, speciesFilter: species, timeFilter: dateFilter, contentFilter: type })
        let temp = {
            "file_type": type.length > 0 ? type.map(item => item.value) : [],
            "author": author.length > 0 ? author.map(item => item.author) : [],
            "file_category": category.length > 0 ? category.map(item => item.category_name) : [],
            "file_species": species.length > 0 ? species.map(item => item.value) : [],
            "search": [searchText],
            "show_content_from": {
                "fixed": dateFilter[0],
                "start_date": null,
                "end_date": null
            }
        }
        getLearningContentList(temp)

        toggleDrawer(item)
    }
    const clearFilter = () => {
        setFilter({ categoryFilter: [], authorFilter: [], speciesFilter: [], timeFilter: "All time", contentFilter: [] })

        setCategory([])
        setAuthor([])
        setSpecies([])
        setDateFilter(["All time"])
        setType([])
        setSearchText("")

        let temp = {
            "file_type": [],
            "author": [],
            "file_category": [],
            "search": [],
            "file_species": [],
            "show_content_from": {
                "fixed": null,
                "start_date": null,
                "end_date": null
            }
        }
        getLearningContentList(temp)

    }

    const clearFilterOnUnmount = () => {
        setFilter({ categoryFilter: [], authorFilter: [], speciesFilter: [], timeFilter: "All time", contentFilter: [] })

        setCategory([])
        setAuthor([])
        setSpecies([])
        setDateFilter(["All time"])
        setType([])

        let temp = {
            "file_type": [],
            "author": [],
            "file_category": [],
            "search": [],
            "file_species": [],
            "show_content_from": {
                "fixed": null,
                "start_date": null,
                "end_date": null
            }
        }


    }
    const handleCategory = (event, v) => {

        const {
            target: { value },
        } = event;
        setCategory(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        // setFilter({ categoryFilter: typeof value === 'string' ? value.split(',') : value })
    };
    const handleAuthor = (event) => {
        const {
            target: { value },
        } = event;
        setAuthor(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleSpecies = (event) => {
        const {
            target: { value },
        } = event;
        setSpecies(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleDate = (event) => {
        const {
            target: { value },
        } = event;
        setDateFilter(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleType = (event) => {
        const {
            target: { value },
        } = event;
        setType(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };





    const toggleDrawer = (val) => {



        setState(val);
    };
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }


    const list = (anchor) => (filterData.length !== 0 && (
        <Box
            sx={{ width: "auto", height: "100vh", padding: 2 }}
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
        >
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <Typography sx={{ color: "#0f01a0", fontSize: 16, fontWeight: 700 }}>Filters</Typography>
                </Grid>
                <Grid item container xs={6} justifyContent="right">
                    <Typography onClick={() => clearFilter()} sx={{ color: "#4d4d4d" }}>Reset filters</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography sx={{ color: "#4d4d4d", marginBottom: 1 }}>Show content from</Typography>
                    <TextField
                        fullWidth
                        size="small"
                        id="contentTime"
                        select
                        defaultValue="All time"
                        value={dateFilter}
                        onChange={handleDate}
                    >
                        {filterData.show_content_from.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.value}
                            </MenuItem>
                        ))}
                    </TextField>


                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{ color: "#4d4d4d", marginBottom: 1 }}>Category</Typography>
                    <Autocomplete

                        multiple
                        disableCloseOnSelect
                        fullWidth
                        size="small"
                        // value={category}
                        options={filterData.file_category}
                        getOptionLabel={(option) => option.category_name}
                        limitTags={1}
                        value={category}
                        onChange={(event, newValue) => {

                            setCategory([

                                ...newValue
                            ]);
                        }}


                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.category_name}
                                    {...getTagProps({ index })}

                                />
                            ))
                        }
                        // style={{ width: 500 }}
                        renderInput={(params) => (
                            <TextField {...params} placeholder="category" />
                        )}
                    />


                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{ color: "#4d4d4d", marginBottom: 1 }}>Author</Typography>


                    <Autocomplete

                        multiple
                        disableCloseOnSelect
                        fullWidth
                        size="small"
                        // value={category}
                        options={filterData.author}
                        getOptionLabel={(option) => option.author}
                        limitTags={1}
                        value={author}
                        onChange={(event, newValue) => {

                            setAuthor([

                                ...newValue
                            ]);
                        }}


                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.author}
                                    {...getTagProps({ index })}

                                />
                            ))
                        }
                        // style={{ width: 500 }}
                        renderInput={(params) => (
                            <TextField {...params} placeholder="author" />
                        )}
                    />

                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{ color: "#4d4d4d", marginBottom: 1 }}>Species</Typography>



                    <Autocomplete

                        multiple
                        disableCloseOnSelect
                        fullWidth
                        size="small"
                        // value={category}
                        options={filterData.file_species}
                        getOptionLabel={(option) => option.value}
                        limitTags={1}
                        value={species}
                        onChange={(event, newValue) => {

                            setSpecies([

                                ...newValue
                            ]);
                        }}


                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.value}
                                    {...getTagProps({ index })}

                                />
                            ))
                        }
                        // style={{ width: 500 }}
                        renderInput={(params) => (
                            <TextField {...params} placeholder="species" />
                        )}
                    />

                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{ color: "#4d4d4d", marginBottom: 1 }}>Type of content</Typography>


                    <Autocomplete

                        multiple
                        disableCloseOnSelect
                        fullWidth
                        size="small"
                        // value={category}
                        options={filterData.file_content_type}
                        getOptionLabel={(option) => option.value}
                        limitTags={1}
                        value={type}
                        onChange={(event, newValue) => {

                            setType([

                                ...newValue
                            ]);
                        }}


                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.value}
                                    {...getTagProps({ index })}

                                />
                            ))
                        }
                        // style={{ width: 500 }}
                        renderInput={(params) => (
                            <TextField {...params} placeholder="type" />
                        )}
                    />
                </Grid>

            </Grid>


            <Grid container sx={{ marginTop: 6 }} justifyContent="flex-end" alignItems="flex-end">
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                        <Button style={{ textTransform: 'none' }} fullWidth variant="outlined" onClick={() => { toggleDrawer(false) }}> Close</Button>
                        <Button style={{ textTransform: 'none' }} fullWidth variant="contained" onClick={() => saveFilter(false)}> Apply</Button>
                    </Stack>
                </Grid>

            </Grid>

        </Box>
    ));


    useEffect(() => {

        let temp = {
            "file_type": type.length > 0 ? type.map(item => item.value) : [],
            "author": author.length > 0 ? author.map(item => item.author) : [],
            "file_category": category.length > 0 ? category.map(item => item.category_name) : [],
            "file_species": species.length > 0 ? species.map(item => item.value) : [],
            "search": [],
            "show_content_from": {
                "fixed": dateFilter[0],
                "start_date": null,
                "end_date": null
            }
        }
        getLearningContentList(temp)
        getFiltersLC()
        return () => {
            clearFilterOnUnmount()
        }
    }, [])

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }


    const open = Boolean(anchorEl);
    const handleClick = (event, item) => {
        setCurrentSelection(item)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCloseQuery = () => {
        setAnchorEl(null);
        setModal(true)
    };
    const handleCloseModal = () => { setQueryError(""); setQueryText(""); setModal(false) };
    const handleCloseBookmark = () => {
        setAnchorEl(null);
        rateNBookmarkLC("bookmark", currentSelection.bookmark === "Y" ? "N" : "Y", currentSelection.learning_content_id, articlesList)
    };
    const handleQuery = () => {
        if (queryText) {
            addQueryLC(currentSelection.learning_content_id, "Medical Paper", queryText)
            setQueryError("")
            setQueryText("")
            handleCloseModal()
        }
        else {
            setQueryError("Please enter a query")
        }


    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: "100%", md: 400 },
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: 1,
        boxShadow: 24,
        p: 2,
    };





    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: "green",
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    }));

    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'right',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'inherit',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
    }));

    const handleSearch = (e) => {
        let temp = {
            "file_type": type.length > 0 ? type.map(item => item.value) : [],
            "author": author.length > 0 ? author.map(item => item.author) : [],
            "file_category": category.length > 0 ? category.map(item => item.category_name) : [],
            "file_species": species.length > 0 ? species.map(item => item.value) : [],
            "search": [searchText],
            "show_content_from": {
                "fixed": dateFilter[0],
                "start_date": null,
                "end_date": null
            }
        }
        getLearningContentList(temp)

    }
    const handlePagination = (e, v) => {

        let temp = {
            "file_type": type.length > 0 ? type.map(item => item.value) : [],
            "author": author.length > 0 ? author.map(item => item.author) : [],
            "file_category": category.length > 0 ? category.map(item => item.category_name) : [],
            "file_species": species.length > 0 ? species.map(item => item.value) : [],
            "search": [searchText],
            "show_content_from": {
                "fixed": dateFilter[0],
                "start_date": null,
                "end_date": null
            }
        }
        getLearningContentList(temp, v)
    }


    return (
        <>
            <CssBaseline />

            <Box sx={{ margin: { xs: 2, sm: 2, md: 4 }, marginTop: { xs: 1, sm: 1 } }}>

                <Grid container spacing={0} >
                    <Grid container justifyContent="space-between" xs={12} sm={12}>

                        <Typography sx={{ marginTop: 1, fontSize: 24, fontWeight: 700, color: "#0f01a0" }}> Learning Content</Typography>


                        <TextField
                            sx={{ display: { xs: "none", md: "flex" } }}
                            value={searchText}
                            id="input-with-icon-textfield"
                            placeholder="Search learning content,author."
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon onClick={() => handleSearch()} />
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            size="small"
                            onChange={(e) => setSearchText(e.target.value)}
                        />



                    </Grid>
                    <Grid container md={12} spacing={2} sx={{ marginTop: 4, marginBottom: 4, display: { xs: "none", md: "flex" } }}  direction="row" alignItems="center" >
                        <Grid item md={2}>
                            <Typography sx={{ color: "#4d4d4d", marginBottom: 1, fontSize: 14 }}>Show content from</Typography>
                            <TextField
                                fullWidth
                                size="small"
                                id="contentTime"
                                select
                                defaultValue="All time"
                                value={dateFilter}
                                onChange={handleDate}
                            >
                                {filterData.show_content_from.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.value}
                                    </MenuItem>
                                ))}
                            </TextField>


                        </Grid>
                        <Grid item md={2}>
                            <Typography sx={{ color: "#4d4d4d", marginBottom: 1, fontSize: 14 }}>Category</Typography>
                            <Autocomplete

                                multiple
                                disableCloseOnSelect
                                fullWidth
                                size="small"
                                // value={category}
                                options={filterData.file_category}
                                getOptionLabel={(option) => option.category_name}
                                limitTags={1}
                                value={category}
                                onChange={(event, newValue) => {

                                    setCategory([

                                        ...newValue
                                    ]);
                                }}


                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.category_name}
                                            {...getTagProps({ index })}

                                        />
                                    ))
                                }
                                // style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="category" />
                                )}
                            />


                        </Grid>
                        <Grid item md={2}>
                            <Typography sx={{ color: "#4d4d4d", marginBottom: 1, fontSize: 14 }}>Author</Typography>


                            <Autocomplete

                                multiple
                                disableCloseOnSelect
                                fullWidth
                                size="small"
                                // value={category}
                                options={filterData.author}
                                getOptionLabel={(option) => option.author}
                                limitTags={1}
                                value={author}
                                onChange={(event, newValue) => {

                                    setAuthor([

                                        ...newValue
                                    ]);
                                }}


                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.author}
                                            {...getTagProps({ index })}

                                        />
                                    ))
                                }
                                // style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="author" />
                                )}
                            />

                        </Grid>
                        <Grid item md={2}>
                            <Typography sx={{ color: "#4d4d4d", marginBottom: 1, fontSize: 14 }}>Species</Typography>



                            <Autocomplete

                                multiple
                                disableCloseOnSelect
                                fullWidth
                                size="small"
                                // value={category}
                                options={filterData.file_species}
                                getOptionLabel={(option) => option.value}
                                limitTags={1}
                                value={species}
                                onChange={(event, newValue) => {

                                    setSpecies([

                                        ...newValue
                                    ]);
                                }}


                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.value}
                                            {...getTagProps({ index })}

                                        />
                                    ))
                                }
                                // style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="species" />
                                )}
                            />

                        </Grid>
                        <Grid item md={2}>
                            <Typography sx={{ color: "#4d4d4d", marginBottom: 1, fontSize: 14 }}>Type of content</Typography>


                            <Autocomplete

                                multiple
                                disableCloseOnSelect
                                fullWidth
                                size="small"
                                // value={category}
                                options={filterData.file_content_type}
                                getOptionLabel={(option) => option.value}
                                limitTags={1}
                                value={type}
                                onChange={(event, newValue) => {

                                    setType([

                                        ...newValue
                                    ]);
                                }}


                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.value}
                                            {...getTagProps({ index })}

                                        />
                                    ))
                                }
                                // style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="type" />
                                )}
                            />
                        </Grid>
                        <Grid container md={2} alignItems="start" sx={{ paddingLeft: 1, paddingRight: 1, paddingTop: 6 }}>
                            {/* <Button sx={{ marginLeft: 1, marginRight: 1 }} variant="outlined" style={{ textTransform: "none" }} onClick={() => saveFilter(false)}>
                                Apply
                            </Button> */}
                            <div style={{display:"flex", flexWrap:"nowrap", width:"100%", marginTop:"-2%" }}>
                            <LoadingButton
                                variant="contained"
                                style={applyButtonStyle}
                                onClick={() => saveFilter(false)}
                                endIcon={<FilterAltIcon />}
                                loading={isLoading}
                                loadingPosition="end"
                            >
                                Apply
                            </LoadingButton>
                            <Tooltip title="Clear Filters" arrow>
                                <IconButton
                                    color="primary"
                                    aria-label="clear filter"
                                    component="span"
                                    onClick={() => clearFilter()}
                                >
                                    <ClearAll />
                                </IconButton>
                            </Tooltip>
                            </div>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} sx={{ display: { xs: "flex", md: "none" } }}>
                        <Grid container justifyContent="end" xs={8} sx={{ display: { xs: "flex", md: "none" }}}>
                            <TextField
                                sx={{ marginTop: 4, marginBottom: 2 }}
                                fullWidth
                                value={searchText}
                                id="input-with-icon-textfield"
                                placeholder="Search learning content,author."
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon onClick={() => handleSearch()} />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                size="large"
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </Grid>
                        <Grid container justifyContent="end" xs={4} sx={{ display: { xs: "flex", md: "none" }, marginTop: 2 }}>
                            <IconButton onClick={() => toggleDrawer("bottom", true)}><FilterAltIcon />  <Chip label={categoryFilter.length + authorFilter.length + speciesFilter.length + contentFilter.length} size="small" /></IconButton>
                        </Grid>



                    </Grid>


                    {isLoading ? (<SpinnerLG />) : (!articlesList && !isLoading) ? (<div>No content yet</div>) :

                        (<Grid container> {articlesList.length !== 0 ? articlesList.map(item => (
                            <Grid container md={3} sx={{ padding: { xs: "none", md: 2 } }}>
                                <Grid container sx={{ boxShadow: { xs: "none", md: 2 }, backgroundColor: { xs: "none", md: "#f3f3f3" }, borderRadius: { xs: "none", md: 2 }, padding: { xs: "none", md: 2 } }}>
                                    <Grid container xs={10}>
                                        <Grid item  sx={{ overflow: "hidden" }}>
                                            <MUILink underline="none" component={Link} to={`/article/${item.learning_content_id}`} sx={{ color: "#000000", marginTop: 0, fontSize: { xs: 18, md: 16 }, fontWeight: 600 }} align="left">
                                                {item.file_title.substring(0, 55) + (item.file_title.length > 55 ? "..." : "")}
                                            </MUILink>
                                        </Grid>


                                    </Grid>

                                    <Grid container xs={2} justifyContent="right" >
                                        <Grid item sx={12}>
                                            <IconButton>
                                                {/* <MoreVertIcon onClick={e => { handleClick(e, item) }} /> */}
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>

                                        <Typography noWrap sx={{
                                            marginTop: 0, marginRight: 1, fontSize: { xs: 12, md: 12 }, fontWeight: 600, color: "#4d4d4d"
                                        }} >
                                            {item.author}
                                        </Typography>

                                    </Grid>
                                    <Grid item container xs={12} sx={{ marginTop: .5, marginBottom: 1 }}>
                                        <Grid item xs="auto" sm={3} md="auto" >

                                            <Typography sx={{ marginTop: 0, marginBottom: 0, fontSize: { xs: 12, md: 12 }, color: "#4e4e4e", }}  > {item.file_type} </Typography>

                                        </Grid>
                                        {/* <Grid item xs="auto" sm={3} md="auto" >
                                        <Typography noWrap sx={{ marginTop: 0, fontSize: { xs: 12, md: 15 }, marginLeft: 2, color: "#4e4e4e" }} align="left">
                                            {item.last_updated_date}
                                        </Typography>
                                    </Grid> */}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Rating sx={{ fontSize: { xs: 20, md: 25 }, color: "#6ad7b9", marginLeft: 0 }} name="read-only" value={item.avg_rating} readOnly size="small" />

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={{ display: { xs: "flex", sm: "none" } }} />
                                    </Grid>
                                </Grid>
                            </Grid>


                        )) : <div>No data found </div>}
                            <Grid container sx={{ paddingBottom: 9 }} spacing={0} justifyContent="center" alignItems="center"  >
                                {Math.ceil(totalCount / 10) > 1 && <Grid item xs={12}>
                                    <Pagination hideNextButton hidePrevButton sx={{ marginTop: 2, marginLeft: "40%" }} page={currentPage} onChange={handlePagination} count={Math.ceil(totalCount / 10)} color="secondary" />
                                </Grid>}
                            </Grid>
                        </Grid >
                        )


                    }
                    {/* <Grid item xs={12}>
                        <Pagination sx={{ marginTop: 2 }} page={currentPage} onChange={handlePagination} count={Math.ceil(totalCount / 5)} variant="outlined" color="primary" />
                    </Grid> */}


                    <Grid sx={{ backgroundColor: { sm: "none", md: "#b3b3b3" } }} container justifyContent="center" xs={12} sm={12} md={12} spacing={0}>

                        <ScrollToTopButton showBelow={300} />
                    </Grid>


                </Grid>





            </Box>

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container >
                        <Grid container item xs={12} sm={12}>
                            <Grid container justifyContent="right" item xs={12} sm={12}>
                                <IconButton color="primary" onClick={handleCloseModal}>
                                    <CloseIcon sx={{ fontSize: 35 }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography sx={{ marginBottom: 1, color: "#0f01a0", fontSize: 26, fontWeight: 600 }} id="modal-modal-title"  >
                                Add New Query
                            </Typography>
                            {queryError && <Typography sx={{ marginBottom: 1, color: "red" }} id="modal-modal-title"  >
                                {queryError}
                            </Typography>}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                id="outlined-multiline-static"
                                inputProps={{ maxLength: 300 }}
                                fullWidth
                                onChange={(e) => setQueryText(e.target.value)}
                                multiline
                                rows={4}
                                placeholder="Type your query here"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Button style={{ textTransform: 'none' }} fullWidth onClick={handleQuery} sx={{ marginTop: 1 }} variant="contained">Submit</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>


            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                // open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleCloseBookmark}>{currentSelection.bookmark === "Y" ? <BookmarkIcon sx={{ color: "#0f01a0" }} /> : <BookmarkBorderIcon sx={{ color: "#0f01a0" }} />}<Typography sx={{ color: "#0f01a0" }}>{currentSelection.bookmark === "N" ? "Add to bookmarks" : "Bookmarked"}</Typography></MenuItem>

                {/* <MenuItem onClick={handleCloseQuery}>Ask a query</MenuItem> */}
                {/* <MenuItem onClick={handleClose}>Add feedback</MenuItem> */}
            </Menu>

            {/* <Box onClick={() => toggleDrawer("bottom", true)} sx={{ width: "100%", height: 50, color: "white", backgroundColor: "#0f01a0", display: { xs: "flex", md: "none" }, bottom: "0%", position: "fixed" }}>
                <Typography sx={{ color: "white", zIndex: 2, bottom: "2vh", left: { xs: "28%", sm: "40%" }, position: "fixed", fontWeight: 600, fontSize: 18 }} >Add Filters</Typography>
                <FilterAltOutlinedIcon sx={{ zIndex: 2, bottom: "2vh", left: { xs: "54%", sm: "52%" }, position: "fixed" }} />
                <Chip sx={{ zIndex: 2, bottom: "2vh", left: { xs: "62%", sm: "55%" }, position: "fixed", color: "#000", backgroundColor: "white" }}
                    label={categoryFilter.length + authorFilter.length + speciesFilter.length + contentFilter.length} size="small" />
            </Box> */}
            <Drawer
                anchor={"bottom"}
                open={state}
                onClose={() => toggleDrawer("bottom", false)}
            >
                {list("bottom")}
            </Drawer>

        </>
    );
};



const mapStateToProps = (state) => ({
    isAuthenticated: state.authenticationReducer.isAuthenticated,
    // createProfileScreen:state.authenticationReducer.createProfileScreen,
    userStatus: state.authenticationReducer.userStatus,
    articlesList: state.LearningContentReducer.articlesList,
    isLoading: state.LearningContentReducer.loading,
    totalCount: state.LearningContentReducer.totalCount,
    currentPage: state.LearningContentReducer.current,
    filterData: state.LearningContentReducer.filterData,
    timeFilter: state.LearningContentReducer.timeFilter,
    categoryFilter: state.LearningContentReducer.categoryFilter,
    authorFilter: state.LearningContentReducer.authorFilter,
    speciesFilter: state.LearningContentReducer.speciesFilter,
    contentFilter: state.LearningContentReducer.contentFilter

})

const mapDispatchToProps = dispatch => {
    return {

        getLearningContentList: (reqObj, pageNumber) => dispatch(getLearningContentList(reqObj, pageNumber)),
        getFiltersLC: () => dispatch(getFiltersLC()),
        rateNBookmarkLC: (flag, value, id, obj) => dispatch(rateNBookmarkLC(flag, value, id, obj)),
        addQueryLC: (moduleId, queryCategory, queryText) => dispatch(addQueryLC(moduleId, queryCategory, queryText)),
        setFilter: (data) => dispatch({ type: SET_USER_FILTER_LEARNING_CONTENT, payload: data })


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LearningContentContainer);