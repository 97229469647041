export const classes = {
  dialog: {
    // width: "70% !important",
  },
  dialogContent: {
    width: "100%",
  },
  lessonTitle: {
    // width: "75% !important",
  },
  questionAccordian: {
    margin: "1%",
    boxShadow: "none",
    border: "1px solid lightgrey",
    borderRadius: "3px",
    // width: "90%",
  },
  questionTitle: {
    color: "#0f01a0",
    fontWeight: "bold",
    marginRight: "2%",
    width: "75%",
  },
  // questionInfo: {
  //   fontSize: "90%",
  //   fontStyle: "italic",
  //   marginTop: "2px",
  //   width: "20%",
  // },
  deleteQuestionBtn: {
    position: "absolute",
    top: "18%",
    right: "5%",
  },
  optionFileUpload: {
    marginLeft: "1%",
  },
  lessonTitle: {
    marginLeft: "-1.5%",
    fontWeight: "bold",
    position: "relative",
  },
  option: {
    margin: "1%",
  },
  addOptionBtn: {
    margin: "1%",
    marginLeft: "0%",
  },
  deleteOptionBtn: {
    float: "right",
  },
  addQuestionBtn: {
    width: "15%",
    margin: "1%",
  },
  input: { display: "none" },
  addQuestionImageUploaded: {
    color: "green",
  },
};
