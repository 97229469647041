import {
  // CHANGE_COURSE_STATUS_FAILURE,
  // CHANGE_COURSE_STATUS_REQUEST,
  // CHANGE_COURSE_STATUS_SUCCESS,
  CHANGE_PAGE_WEBINAR_ADMIN,
  // DELETE_COURSE_FAILURE,
  // DELETE_COURSE_REQUEST,
  // DELETE_COURSE_SUCCESS,
  // EDIT_COURSE_FAILURE,
  // EDIT_COURSE_REQUEST,
  // EDIT_COURSE_SUCCESS,
  // FETCH_COURSES_FAILURE,
  // FETCH_COURSES_REQUEST,
  FETCH_ADMIN_WEBINARS_SUCCESS,
  FETCH_FILTER_VALUES_FAILURE_WEBINAR_ADMIN,
  FETCH_FILTER_VALUES_REQUEST_WEBINAR_ADMIN,
  FETCH_FILTER_VALUES_SUCCESS_WEBINAR_ADMIN,
  FETCH_DROPDOWN_VALUES_FAILURE_WEBINAR_ADMIN,
  FETCH_DROPDOWN_VALUES_REQUEST_WEBINAR_ADMIN,
  FETCH_DROPDOWN_VALUES_SUCCESS_WEBINAR_ADMIN,
  SELECT_FILTER_WEBINAR_ADMIN,
  TOGGLE_ALERT,
} from "../ActionTypes/webinarsAdminActionTypes";

export const fetchFilterValuesRequest = () => ({
  type: FETCH_FILTER_VALUES_REQUEST_WEBINAR_ADMIN,
});

export const fetchDropDownValuesRequest = () => ({
  type: FETCH_DROPDOWN_VALUES_REQUEST_WEBINAR_ADMIN,
});

export const fetchFilterValuesSuccess = (filterValues) => ({
  type: FETCH_FILTER_VALUES_SUCCESS_WEBINAR_ADMIN,
  payload: filterValues,
});

export const fetchDropDownValuesSuccess = (dropDownValues) => ({
  type: FETCH_DROPDOWN_VALUES_SUCCESS_WEBINAR_ADMIN,
  payload: dropDownValues,
});

export const fetchFilterValuesFailure = (error) => ({
  type: FETCH_FILTER_VALUES_FAILURE_WEBINAR_ADMIN,
  payload: error,
});

export const fetchDropDownValuesFailure = (error) => ({
  type: FETCH_DROPDOWN_VALUES_FAILURE_WEBINAR_ADMIN,
  payload: error,
});

export const selectFilter = (filterType, filterValue) => ({
  type: SELECT_FILTER_WEBINAR_ADMIN,
  payload: {
    type: filterType,
    value: filterValue,
  },
});

//   export const fetchCoursesRequest = () => ({
//     type: FETCH_COURSES_REQUEST,
//   });

export const fetchWebinarsSuccess = (webinars) => ({
  type: FETCH_ADMIN_WEBINARS_SUCCESS,
  payload: webinars,
});

//   export const fetchCoursesFailure = (error) => ({
//     type: FETCH_COURSES_FAILURE,
//     payload: error,
//   });

export const changePage = (pageNumber) => ({
  type: CHANGE_PAGE_WEBINAR_ADMIN,
  payload: pageNumber,
});

//   export const editCourseRequest = () => ({
//     type: EDIT_COURSE_REQUEST,
//   });

//   export const editCourseSuccess = () => ({
//     type: EDIT_COURSE_SUCCESS,
//   });

//   export const editCourseFailure = (error) => ({
//     type: EDIT_COURSE_FAILURE,
//     payload: error,
//   });

//   export const deleteCourseRequest = () => ({
//     type: DELETE_COURSE_REQUEST,
//   });

//   export const deleteCourseSuccess = () => ({
//     type: DELETE_COURSE_SUCCESS,
//   });

//   export const deleteCourseFailure = (error) => ({
//     type: DELETE_COURSE_FAILURE,
//     payload: error,
//   });

//   export const changeCourseStatusRequest = () => ({
//     type: CHANGE_COURSE_STATUS_REQUEST,
//   });

//   export const changeCourseStatusSuccess = () => ({
//     type: CHANGE_COURSE_STATUS_SUCCESS,
//   });

//   export const changeCourseStatusFailure = (error) => ({
//     type: CHANGE_COURSE_STATUS_FAILURE,
//     payload: error,
//   });

export const toggleAlert = (type, message, value) => ({
  type: TOGGLE_ALERT,
  payload: { type, message, value },
});
