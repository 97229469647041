import {
  FETCH_FILTER_VALUES_REQUEST,
  FETCH_FILTER_VALUES_FAILURE,
  FETCH_FILTER_VALUES_SUCCESS,
  SELECT_FILTER,
  FETCH_COURSES_REQUEST,
  FETCH_COURSES_FAILURE,
  FETCH_COURSES_SUCCESS,
  CHANGE_PAGE,
  CHANGE_COURSE_STATUS_REQUEST,
  CHANGE_COURSE_STATUS_SUCCESS,
  CHANGE_COURSE_STATUS_FAILURE,
  DELETE_COURSE_FAILURE,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_RESET,
  TOGGLE_ALERT,
  CHANGE_COURSE_DETAILS,
  CREATE_COURSE_REQUEST,
  CREATE_COURSE_FAILURE,
  CREATE_COURSE_SUCCESS,
  GET_COURSE_DETAILS_FOR_EDIT,
  SET_ACTIVE_COURSE,
  CREATE_EDIT_CHAPTER_REQUEST,
  CREATE_EDIT_CHAPTER_SUCCESS,
  CREATE_EDIT_CHAPTER_FAILURE,
  SET_CURRICULUM_DETAILS,
  CREATE_QUIZ_REQUEST,
  CREATE_QUIZ_SUCCESS,
  CREATE_QUIZ_FAILURE,
  NAVIGATE_ADMIN_ON_COURSE_SAVE,
  CREATE_COURSE_EDIT_DETAILS,
  SET_SPEAKER_IDS_FOR_EDIT,
  CLEAR_COURSE_DATA,
  CREATE_EDIT_LESSON_SUCCESS,
  CREATE_EDIT_LESSON_REQUEST,
  CREATE_EDIT_LESSON_FAILURE,
  TOGGLE_EDIT_FLOW,
  SET_PREVIEW_DATA,
  SET_PREVIEW_SEQUENCE
} from "../ActionTypes/coursesAdminActionTypes";

const initialState = {
  isLoading: false,
  error: "",
  filters: {},
  isEditFlow: false,
  adminNavigation: 0, //0 for new course page, 1 will navigate back to admin courses home
  selectedFilters: {
    category: [],
    country: [],
    creator: [],
    language: [],
    species: [],
    show_content_from: { value: "All time" },
    search: "",
  },
  editedData: {
    data: {
      course_id: null,
      course_title: "",
      course_category: "",
      course_language: "",
      course_country: "",
      course_description: "",
      is_published: "UNPUBLISHED",
      species: "Canine",
      speaker: [
        {
          is_delete: null,
          is_edit: null,
          is_add: null,
          speaker_id: null,
          speaker_name: "",
          speaker_qualification: "",
          speaker_description: "",
        },
      ],
    },
    is_image_updated: false,
    is_video_updated: false,
    is_video_deleted: false,
    course_image_path: null,
    course_prevvideo_path: null,
  },

  courseDetails: {
    data: {
      course_title: "",
      // course_subtitle: "",
      course_category: "",
      course_language: "",
      course_country: [],
      species: "",
      course_description: "",
      is_published: "UNPUBLISHED",
      speaker: [
        {
          speaker_name: "",
          speaker_qualification: "",
          speaker_description: "",
        },
      ],
    },
    course_image_path: "",
    course_prevvideo_path: "",
  },
  previewData: {},
  curriculumDetails: {},
  // curriculumDetails: {
  //   course_id: 44,
  //   is_published: "UNPUBLISHED",
  //   chapters: [
  //     {
  //       course_level1_id: 15,
  //       sequence_id: 1,
  //       content_name: "18th Dec Intro",
  //       lessons: [
  //         {
  //           course_level2_id: 27,
  //           sequence_id: 1.1,
  //           content_name: "First Lesson",
  //           duration: "0.19 min",
  //           file_type: "Video",
  //           sas_file_path:
  //             "https://globalpetsensoriumdevsa.blob.core.windows.net/output/V2V/COURSES/COURSE_ID44/CURRICULUM/LEVEL1/LEVEL1_ID15/LEVEL2_ID27/Time Lapse Video Of Sky.mp4?sp=r&st=2021-11-11T13:39:29Z&se=2025-12-30T21:39:29Z&spr=https&sv=2020-08-04&sr=c&sig=AZ9b615qX2zmrSqBQNW0GdbdNFXqekzgYRHB3gHTrjM%3D",
  //           questions: [],
  //         },
  //       ],
  //     },
  //   ],
  // },
  activeCourse: null,
  deleteCourseStatus: false,
  courses: {},
  pageNumber: 1,
  isAlertOpen: false,
  alertType: "success",
  alertMessage: "",
  isUploading: false,
  currentSelection: null,
  previewSequence: null
};

const CoursesAdminReducer = (state = initialState, action) => {
  const { type, payload } = action;


  switch (type) {
    case DELETE_COURSE_SUCCESS:
      return {
        ...state,
        deleteCourseStatus: payload,
      };
    case DELETE_COURSE_RESET:
      return {
        ...state,
        deleteCourseStatus: false,
      };
    case FETCH_FILTER_VALUES_REQUEST:
    case FETCH_COURSES_REQUEST:
    case CREATE_COURSE_REQUEST:
    case CREATE_EDIT_CHAPTER_REQUEST:
    case CREATE_QUIZ_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case SET_PREVIEW_DATA:
      return {
        ...state, previewData: payload
      }
    case TOGGLE_EDIT_FLOW:
      return {
        ...state,
        isEditFlow: payload
      }
    case SET_PREVIEW_SEQUENCE:
      return {
        ...state, previewSequence: payload.data,
        currentSelection: payload.current
      }
    case CREATE_COURSE_EDIT_DETAILS:
      let editableSpeakerArray = payload.speaker.map((item) => ({
        ...item,
        is_delete: null,
        is_edit: null,
        is_add: null,
      }));
      payload.speaker = editableSpeakerArray;
      let { sas_image_path, sas_video_path, ...rest } = payload;
      return {
        ...state,
        editedData: {
          data: { ...rest },
          is_image_updated: false,
          is_video_updated: false,
          is_video_deleted: false,
        },
      };
    case SET_SPEAKER_IDS_FOR_EDIT:
      let editableSpeakerArray2 = payload.speaker.map((item) => ({
        ...item,
        is_delete: null,
        is_edit: null,
        is_add: null,
      }));
      return {
        ...state,
        courseDetails: {
          ...state.courseDetails,
          data: { ...state.courseDetails.data, speaker: editableSpeakerArray2 },
        },
      };
    case CREATE_EDIT_LESSON_REQUEST:
      return {
        ...state, isUploading: true
      }

    case CREATE_EDIT_LESSON_FAILURE:
    case CREATE_EDIT_LESSON_SUCCESS:
      return {
        ...state, isUploading: false
      }
    case CHANGE_COURSE_STATUS_SUCCESS:
    case CREATE_COURSE_SUCCESS:
    case CREATE_EDIT_CHAPTER_SUCCESS:
    case CREATE_QUIZ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };

    case CHANGE_COURSE_STATUS_FAILURE:
    case CREATE_COURSE_FAILURE:
    case DELETE_COURSE_FAILURE:
    case CREATE_EDIT_CHAPTER_FAILURE:
    case CREATE_QUIZ_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case SET_CURRICULUM_DETAILS:
      return {
        ...state,
        isLoading: false,
        curriculumDetails: payload,
      };
    case NAVIGATE_ADMIN_ON_COURSE_SAVE:
      return {
        ...state,
        adminNavigation: payload,
        activeCourse: null,
      };

    case GET_COURSE_DETAILS_FOR_EDIT:
      return {
        ...state,
        courseDetails: payload,
      };

    case FETCH_FILTER_VALUES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        filters: payload,
        error: "",
      };

    case FETCH_FILTER_VALUES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        filters: {},
      };

    case SELECT_FILTER:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [payload.type]: payload.value,
        },
      };

    case CHANGE_COURSE_DETAILS:

      if (payload.isData && payload.isSpeaker) {
        const tempArray = [...state.courseDetails.data.speaker];
        if (payload.speakerActionType === "edit") {
          const speakerObject = tempArray[payload.speakerNumber];
          speakerObject[payload.type] = payload.value;
          tempArray.splice(payload.speakerNumber, 1, speakerObject);
        } else if (payload.speakerActionType === "delete") {
          tempArray.splice(payload.speakerNumber, 1);
        } else if (payload.speakerActionType === "add") {
          const speakerObject = {
            speaker_name: "",
            speaker_qualification: "",
            speaker_description: "",
          };

          tempArray.push(speakerObject);
        }

        return {
          ...state,
          courseDetails: {
            ...state.courseDetails,
            data: {
              ...state.courseDetails.data,
              speaker: tempArray,
            },
          },
        };
      } else if (
        payload.type === "course_image_path" &&
        state.editedData &&
        state.editedData.data &&
        state.editedData.data.course_id
      ) {
        return {
          ...state,
          courseDetails: {
            ...state.courseDetails,
            [payload.type]: payload.value,
          },
          editedData: { ...state.editedData, is_image_updated: true },
        };
      }
      else if (payload.type === "course_prevvideo_path" &&
        state.editedData &&
        state.editedData.data &&
        state.editedData.data.course_id) {
        return {
          ...state,
          courseDetails: {
            ...state.courseDetails,
            [payload.type]: payload.value,
          },
          editedData: { ...state.editedData, is_video_updated: true },
        };

      }
      else if (payload.isData && !payload.isSpeaker)
        return {
          ...state,
          courseDetails: {
            ...state.courseDetails,
            data: {
              ...state.courseDetails.data,
              [payload.type]: payload.value,
            },
          },
        };
      else if (!payload.isData)
        return {
          ...state,
          courseDetails: {
            ...state.courseDetails,
            [payload.type]: payload.value,
          },
        };

    case FETCH_COURSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
        courses: payload,
        isFailure: false,
      };

    case FETCH_COURSES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        courses: "",
        isFailure: true,
      };

    case CHANGE_PAGE:
      return {
        ...state,
        pageNumber: payload,
      };

    case SET_ACTIVE_COURSE:
      return {
        ...state,
        activeCourse: payload,
      };

    case TOGGLE_ALERT:
      return {
        ...state,
        isAlertOpen: payload.value,
        alertType: payload.type,
        alertMessage: payload.message,
      };
    case CLEAR_COURSE_DATA:
      return {
        ...state,
        previewData: {},
        courseDetails: {
          data: {
            course_title: "",
            // course_subtitle: "",
            course_category: "",
            course_language: "",
            course_country: [],
            species: "",
            course_description: "",
            is_published: "UNPUBLISHED",
            speaker: [
              {
                speaker_name: "",
                speaker_qualification: "",
                speaker_description: "",
              },
            ],
          },
          course_image_path: "",
          course_prevvideo_path: "",
        },
        curriculumDetails: {}
      }

    default:
      return state;
  }
};

export default CoursesAdminReducer;
