import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import "./icon.css"
import { Autocomplete, Button, CircularProgress, FormHelperText, Grid, TextField, Pagination } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from "@mui/icons-material/Search";
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import * as XLSX from "xlsx";





const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const AutoComplete = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 10px;
  }
`;

export default function TableComponent({ isLoading, dataSet, selectFilterManagement, filters, selectedFilters,
    current, total, reqObj, getManagementData, setSelected, selected, searchText, setSearchText, action,
    setAction, setOpenHomeDialog, value,exportData }) {


    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");

    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    
    //selecting all rows
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = dataSet.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    // downloading user data in excel format

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "UserData.xlsx");
      };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataSet.length) : 0;


    const handlePagination = (e, v) => {


        setSelected([])
        getManagementData(reqObj, v)

    }


    return (
        <Grid container md={12} sx={{ width: "100%", padding: 2 }}>
            <Grid item md={3} sx={{ marginBottom: 3, marginTop: 1 }}>
                <FormHelperText id="show_users_from_filter">
                    Show users from
                </FormHelperText>
                <AutoComplete
                    // size="small"
                    disablePortal
                    disableClearable
                    id="show_users_from_filter"
                    options={
                        filters && filters.show_user_from && Object.keys(filters).length > 0
                            ? filters.show_user_from
                            : []
                    }

                    loading={isLoading}
                    renderInput={(params) => (
                        <TextField
                            size="small"

                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {isLoading ? (
                                            <CircularProgress color="inherit" size={20} />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                    getOptionLabel={(option) => option.value || ""}
                    value={selectedFilters["show_user_from"]}
                    onChange={(e, value) => {

                        // if (value && value.value == "Custom") {
                        //     setOpenCustomDialog(true);
                        // }
                        // else {
                        let tempObj = {
                            ...reqObj, show_user_from: {
                                "fixed": !value || !value.value ? "All time" : value.value,
                                "start_date": null,
                                "end_date": null
                            }
                        }
                        selectFilterManagement("show_user_from", value === null ? "" : { ...value, value: value.value + "" })
                        setSelected([])
                        getManagementData(tempObj)
                        // }


                    }
                    }
                />
            </Grid>
            <Grid item md={4}>
            </Grid>
            <Grid container alignItems="center" justifyContent="right" xs={5}>
                <TextField
                    placeholder="search"
                    id="outlined-start-adornment"
                    size="small"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    InputProps={{
                        endAdornment: <InputAdornment position="start"><IconButton onClick={() => {
                            getManagementData(reqObj); setSelected([])
                        }}>
                            <SearchIcon />
                        </IconButton>
                        </InputAdornment>,
                    }}
                />

                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                    sx={{ paddingLeft: 1 }}
                >
                    {value === 0 && <Button disabled={selected.length < 1} size="small" onClick={() => { setAction("delete"); setOpenHomeDialog(true); }}
                        style={{ textTransform: "none", color: "#666666" }}>
                        Delete</Button>}

                    {value===0 && <Button onClick={downloadExcel} size="small" style={{ textTransform: "none", color: "#666666" }}>Export</Button>}
                </Stack>
            </Grid>

            {selected.length > 0 && value !== 1 && value !== 0 &&
                <>
                    <Grid item md={12}>
                        <Divider sx={{ marginBottom: 1 }} />
                    </Grid>
                    <Grid container alignItems="center" justifyContent="space-between" md={12} sx={{ marginBottom: 1 }}>
                        <Grid item md={3}>
                            <Typography>Selected users:<span style={{ color: "blue", fontWeight: 600 }}>{selected.length}</span> </Typography>
                        </Grid>
                        <Grid item md={3}>

                        </Grid>
                        <Grid container justifyContent="right" md={5}>
                            <Stack
                                direction="row"
                                // divider={<Divider orientation="vertical" flexItem />}
                                spacing={1}
                                sx={{ paddingLeft: 1 }}
                            >
                                <Button variant="outlined" sx={{ color: "black" }} size="small" onClick={() => {
                                    setAction("Approve")
                                    setOpenHomeDialog(true)
                                }}
                                    style={{ textTransform: "none", }}>Approve Selection</Button>

                                <Button variant="outlined" sx={{ color: "black" }} size="small" onClick={() => setSelected([])}
                                    style={{ textTransform: "none", }}>Clear Selection</Button>

                                <Button variant="outlined" sx={{ color: "black" }} size="small" onClick={() => {
                                    setAction("Reject")
                                    setOpenHomeDialog(true)
                                }}
                                    style={{ textTransform: "none", }}>Reject Users</Button>

                            </Stack>
                        </Grid>
                    </Grid>
                </>
            }



            {isLoading ? <>  <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '50vh' }}
            >

                <Grid item xs={3}>
                    <CircularProgress size={100} />
                </Grid>

            </Grid></>
                : dataSet.length < 1 ? <>No data</>
                    : <> <Grid item md={12} sx={{ height: 400, overflow: "scroll" }}>
                        <Paper sx={{ width: "100%", mb: 0 }}>
                            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750, }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? "small" : "medium"}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={dataSet.length}
                                    />
                                    <TableBody>
                                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                                        {stableSort(dataSet, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <StyledTableRow
                                                        hover
                                                        onClick={(event) => handleClick(event, row.id)}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        selected={isItemSelected}
                                                    >
                                                        <StyledTableCell padding="checkbox">
                                                            <Checkbox
                                                                color="secondary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    "aria-labelledby": labelId
                                                                }}
                                                            />
                                                        </StyledTableCell>
                                                        {/* <StyledTableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.name}
                                            </StyledTableCell> */}
                                                        <StyledTableCell align="left">
                                                            <Tooltip title={row.full_name}>

                                                                <Typography sx={{ fontSize: 14 }}>
                                                                    {row.full_name && row.full_name.substring(0, 20) +
                                                                        (row.full_name && row.full_name.length > 20 ? "..." : "")}
                                                                </Typography>
                                                            </Tooltip>
                                                        </StyledTableCell>


                                                        <StyledTableCell align="left">
                                                            <Tooltip title={row.occupation}>
                                                                <span>
                                                                    {row.occupation}
                                                                </span>

                                                            </Tooltip>
                                                        </StyledTableCell>

                                                        <StyledTableCell align="right">
                                                            <Tooltip title={row.phonewithcountrycode}>
                                                                <span>
                                                                    {row.phonewithcountrycode}
                                                                </span>
                                                            </Tooltip>
                                                        </StyledTableCell>

                                                        <StyledTableCell align="left">
                                                            <Tooltip title={row.email}>
                                                                <span>
                                                                    {row.email}
                                                                </span>
                                                            </Tooltip>
                                                        </StyledTableCell>

                                                        <StyledTableCell align="left">
                                                            <Tooltip title={row.clinic_name}>

                                                                <Typography sx={{ fontSize: 14 }}>
                                                                    {row.clinic_name && row.clinic_name.substring(0, 15) +
                                                                        (row.clinic_name && row.clinic_name.length > 15 ? "..." : "")}
                                                                </Typography>
                                                            </Tooltip>
                                                        </StyledTableCell>

                                                        <StyledTableCell align="left">
                                                            <Tooltip title={row.clinic_city}>
                                                                <span>
                                                                    {row.clinic_city}
                                                                </span>
                                                            </Tooltip>
                                                        </StyledTableCell>

                                                        <StyledTableCell align="left">
                                                            <Tooltip title={row.clinic_state}>
                                                                <span>
                                                                    {row.clinic_state}
                                                                </span>
                                                            </Tooltip>
                                                        </StyledTableCell>

                                                        <StyledTableCell align="right">
                                                            <Tooltip title={row.clinic_pincode}>
                                                                <span>
                                                                    {row.clinic_pincode}
                                                                </span>
                                                            </Tooltip>
                                                        </StyledTableCell>

                                                        <StyledTableCell align="left">
                                                            <Tooltip title={row.clinic_address}>
                                                                <Typography sx={{ fontSize: 14 }}>
                                                                    {row.clinic_address && row.clinic_address.substring(0, 25) +
                                                                        (row.clinic_address && row.clinic_address.length > 25 ? "..." : "")}
                                                                </Typography>
                                                            </Tooltip>
                                                        </StyledTableCell>

                                                        <StyledTableCell align="left">
                                                            <Tooltip title={row.country}>
                                                                <span>
                                                                    {row.country}
                                                                </span>
                                                            </Tooltip>
                                                        </StyledTableCell>



                                                    </StyledTableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: (dense ? 33 : 53) * emptyRows
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
                        </Paper>
                    </Grid>
                        <> {(total !== 0 && Math.ceil(Number(total) / 10) > 1) && <Grid item md={12}>
                            <Pagination
                                size="small"
                                count={Math.ceil(Number(total) / 10)}
                                page={current}
                                onChange={handlePagination}
                                sx={{ marginTop: 1, justifyContent: "center", display: "flex" }}
                                color="primary"
                                hidePrevButton
                                hideNextButton
                            />
                        </Grid>}
                        </>
                    </>
            }
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
        </Grid >
    );
}

function createData(name, calories, fat, carbs, protein) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein
    };
}

const rows = [
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Donut", 452, 25.0, 51, 4.9),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
    createData("Honeycomb", 408, 3.2, 87, 6.5),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Jelly Bean", 375, 0.0, 94, 0.0),
    createData("KitKat", 518, 26.0, 65, 7.0),
    createData("Lollipop", 392, 0.2, 98, 0.0),
    createData("Marshmallow", 318, 0, 81, 2.0),
    createData("Nougat", 360, 19.0, 9, 37.0),
    createData("Oreo", 437, 18.0, 63, 4.0)
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "full_name",
        numeric: false,
        disablePadding: false,
        label: "Name"
    },
    {
        id: "occupation",
        numeric: false,
        disablePadding: false,
        label: "Occupation"
    },
    {
        id: "phonewithcountrycode",
        numeric: true,
        disablePadding: false,
        label: "Phone number"
    },
    {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: "Email"
    },
    {
        id: "clinic_name",
        numeric: false,
        disablePadding: false,
        label: "Clinic name"
    },
    {
        id: "clinic_city",
        numeric: false,
        disablePadding: false,
        label: "City"
    },
    {
        id: "clinic_state",
        numeric: false,
        disablePadding: false,
        label: "State"
    },
    {
        id: "clinic_pincode",
        numeric: true,
        disablePadding: false,
        label: "Pin code"
    },
    {
        id: "clinic_address",
        numeric: false,
        disablePadding: false,
        label: "Clinic address"
    },
    {
        id: "country",
        numeric: false,
        disablePadding: false,
        label: "Country"
    },

];

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow sx={{ backgroundColor: "#0000a0" }}>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="secondary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all desserts"
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={"center"}
                        padding={headCell.disablePadding ? "none" : "none"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            sx={{ color: "green" }}
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <span style={{ color: "white", fontSize: 14, paddingLeft: 0 }}>{headCell.label}</span>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.secondary.main,
                            theme.palette.action.activatedOpacity
                        )
                })
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: "1 1 100%" }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: "1 1 100%" }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Nutrition
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
};
