import React, { useState, useEffect } from "react";
// import "./styles.css";
import { useHistory, withRouter,Link,Redirect } from "react-router-dom";
import { Box, Paper, Typography, Grid, TextField, Button, CssBaseline } from '@mui/material';
import { connect } from "react-redux"
import { login as loginInit } from "../Redux/Middlewares/loginMiddleware";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { styled, alpha } from "@mui/material/styles";
import {LoginCloseRequest,ForgetPasswordOpenRequest} from "../Redux/ActionCreators/NavbarActionCreators"
import LoginFormRes from "../Login/LoginFormRes";



// const Login = ({ setLoggedInUser }) => {
const LoginPage = ({ login, isAuthenticated,closeLogin,loginInit,processLoader }) => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showError, setShowError] = useState(false);

  const history = useHistory();

  const [confirmedPassword, setConfirmedPassword] = useState("");


  const handleEmail = (e) => {
    if (e.target.value.length < 30)
      setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    if (e.target.value.length < 30)
      setPassword(e.target.value);
  };

  const StyledMain = styled('div')(({ theme }) => ({
    marginTop: '5%',
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  
  }));

  const PaperWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 60,
    },
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  
  }));
  

 


  if(isAuthenticated){

    return(<Redirect to="/"/>)
  }

  return (
    <>
     <CssBaseline/>
     <StyledMain>
       <PaperWrapper>
         <Paper sx={{padding:2,paddingLeft:{xs:2,sm:6},paddingRight:{xs:2,sm:6}}}>
         {/* <Box sx={{display:"flex",justifyContent:"center"}}>
          <img src={Logo} alt="logo.png"/>
          </Box> */}
          
           
           <LoginFormRes processLoader={processLoader} loginInit={loginInit}/>
         </Paper>
       </PaperWrapper>

     </StyledMain>

    </>
  );
};



const mapStateToProps = (state) => ({
  isAuthenticated: state.authenticationReducer.isAuthenticated,
  forgotPass:state.NavigationReducer.forgotPasswordModal,
  processLoader:state.AlertsReducer.processLoader

})

const mapDispatchToProps = dispatch => {
  return {
    closeLogin: () => dispatch(LoginCloseRequest()),
    loginInit:(email,password)=>dispatch(loginInit(email,password))
    

    
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(LoginPage);