import {LOGIN_OPEN,LOGIN_CLOSE,SIGNUP_OPEN,SIGNUP_CLOSE,
    PROFILEFORM_OPEN,PROFILEFORM_CLOSE,FORGETPASSWORD_OPEN,FORGETPASSWORD_CLOSE,OTPSCREEN_CLOSE,OTPSCREEN_OPEN} from "../ActionTypes/NavbarActionTypes"
    
    export const LoginOpenRequest=()=>({
        type:LOGIN_OPEN
    })
    export const LoginCloseRequest=()=>({
        type:LOGIN_CLOSE
    })
    export const signupOpenRequest=()=>({
        type:SIGNUP_OPEN
    })
    export const signUpCloseRequest=()=>({
        type:SIGNUP_CLOSE
    })
    export const profileFormOpenRequest=()=>({
        type:PROFILEFORM_OPEN
    })
    export const profileFormcloseRequest=()=>({
        type:PROFILEFORM_CLOSE
    })
    
    export const ForgetPasswordOpenRequest=()=>({
        type:FORGETPASSWORD_OPEN
    })
    
    export const ForgetPasswordCloseRequest=()=>({
        type:FORGETPASSWORD_CLOSE
    })

    export const EnterOTPOpenRequest=()=>({
        type:OTPSCREEN_OPEN
    })
    
    export const EnterOTPCloseRequest=()=>({
        type:OTPSCREEN_CLOSE
    })