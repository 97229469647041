import React, { useState, useEffect } from 'react';
import { InputLabel, TextField, Select, MenuItem, Button, Grid, Box, Typography, Divider, InputAdornment, IconButton } from '@mui/material';
import { useFormik } from "formik"
import { Link, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Link as MUIlink } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import MarsLogo from "./MarsLogo.png"
import { Visibility, VisibilityOff } from '@mui/icons-material';

// details validation logic
const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Invalid password')
    .required('Password is required'),
});

const LoginForm = ({ loginInit, processLoader }) => {

  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));

      loginInit(values.email, values.password)
    },
  });
  const history = useHistory()

  const handleClickShowPassword = () => {
    let input = document.getElementById("password");
    input.type === "password" ? input.type = "text" : input.type = "password";
    setShowPassword(!showPassword);
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ marginTop: 2 }} variant="h4" align="center">
              Login
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputLabel><Typography variant="h6"> Email address</Typography></InputLabel>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                id="email"
                name="email"
                value={formik.values.email}
                placeholder
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputLabel><Typography variant="h6"> Password</Typography></InputLabel>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                id="password"
                name="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <LoadingButton style={{ textTransform: 'none' }} loading={processLoader} loadingPosition="center" color="primary" variant="contained" fullWidth type="submit">
              Login
            </LoadingButton>
          </Grid>
          <Grid item justifyContent="center" xs={12} sm={12} sx={{ marginTop: 1, marginBottom: 1 }}>
            <Divider><span style={{ fontWeight: 600 }}>OR</span></Divider>
          </Grid>
          <Grid item justifyContent="center" xs={12} sm={12}>
            <Button fullWidth startIcon={<img style={{ height: 30 }} src={MarsLogo} />} variant="contained" style={{ textTransform: "none", backgroundColor: '#D6D6D6', color: "black", fontWeight: 600 }} onClick={() => { window.location = "/api/login/loginpage" }}>
              Login as Admin
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>

            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                typography: 'body1',
                '& > :not(style) + :not(style)': {
                  ml: 2,
                },
              }}

            >

              <Button style={{ textTransform: 'none', color: "black" }} component={Link} to={'/recoverpass'}>Forgot Password?</Button>


            </Box>



          </Grid>
          <Grid container justifyContent="center" xs={12} sm={12}>
            <Button onClick={() => { history.push("/signup") }} sx={{ fontSize: 17, color: "black", textTransform: "none" }}> Not an existing user?<span style={{ color: "#0000A0", fontWeight: 600 }}>Sign up</span>  </Button>
          </Grid>
        </Grid>


      </form>
    </>
  )
}

export default LoginForm