import {
    SET_LOGIN_FAILURE_ALERT, CLEAR_LOGIN_FAILURE_ALERT, CLEAR_SIGNUP_EXISTS_ALERT,
    SET_FORGOT_PASS_ALERT, CLEAR_FORGOT_PASS_ALERT, TOGGLE_GLOBAL_ALERT
} from "../ActionTypes/alertsActionTypes"


export const setLoginFailAlert = () => (
    {
        type: SET_LOGIN_FAILURE_ALERT,

    }
)

export const clearLoginFailAlert = () => (
    {
        type: CLEAR_LOGIN_FAILURE_ALERT,

    }
)

export const toggleGlobalAlert = (type, message, value) => ({
    type: TOGGLE_GLOBAL_ALERT,
    payload: { type, message, value },
});

export const clearSignupExistsAlert = () => (
    {
        type: CLEAR_SIGNUP_EXISTS_ALERT,

    }
)
export const clearForgotPasswordError = () => (
    {
        type: CLEAR_FORGOT_PASS_ALERT,

    }
)