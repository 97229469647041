import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  MenuItem,
  CardActionArea,
  CardMedia,
  Menu,
  Rating,
  Button,
  CardActions,
  Divider,

} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { classes } from "./styles";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFCertificateWebinar from "../WebinarVet/PDFCertificateWebinar";
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';


const WebinarAdminCard = ({ webinar, index, deleteWebinar, requestObject, toggleEdit, disableEdit, getWebinarForEdit,
  setUploadModal, setCurrentSelection, setOpenDialog, setDeleteId }) => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = Boolean(anchorEl);

  const handleCloseMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleEditClick = (e) => {
    handleCloseMenu(e);
  };

  const handleDeleteClick = (e, id) => {
    setDeleteId(id)
    setOpenDialog(true)
    handleCloseMenu(e);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    if (disableEdit) {
      setCurrentSelection(webinar)
    }
  };

  return (
    <Grid item xs={3} key={index}>
      <Card
        sx={classes.card}
      // onClick={() => history.push("/webinarAdminDetail")}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            sx={classes.image}
            image={webinar.sas_webinarimage_path}
            alt="webinar image"
          />
          <IconButton
            color="default"
            aria-label="card actions"
            component="span"
            sx={classes.iconButton}
            onClick={handleMenuClick}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id={`basic_menu_${index}`}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {/* <MenuItem onClick={handleEditClick}>Edit</MenuItem> */}
            {!disableEdit && <MenuItem onClick={(e) => { getWebinarForEdit(webinar.webinar_id); handleCloseMenu(e); }}>Edit</MenuItem>}
            {disableEdit && <MenuItem onClick={(e) => { getWebinarForEdit(webinar.webinar_id, true); handleCloseMenu(e); }}>Upload webinar recording</MenuItem>}
            <MenuItem onClick={(e) => handleDeleteClick(e, webinar.webinar_id)}>Delete</MenuItem>

          </Menu>
          <CardContent>
            <Typography
              gutterBottom
              variant="subtitle1"
              component="div"
              noWrap
              sx={classes.title}
            >
              {webinar.webinar_title}
            </Typography>
            <Typography noWrap variant="subtitle1" sx={classes.subtitle}>
              {webinar.speaker.map((obj) => obj.speaker_name).join(", ")}
            </Typography>
            <Divider sx={{ marginBottom: 1 }} />
            <Typography noWrap variant="subtitle1" sx={classes.subtitle}>Enrollment ratio {<span style={{ color: "blue" }}>{webinar.enrollment_ratio}</span>}</Typography>
            {/* <Rating
              name="read-only"
              value={webinar.avgRating}
              readOnly
              sx={classes.rating}
            /> */}
            <hr style={classes.divider} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <AccessTimeIcon fontSize="small" sx={{ color: "#00D7B9" }} />
              <Typography style={{ marginLeft: "5px", fontSize: 16 }}>

                {moment(webinar.webinar_date, "DD-MM-YYYY").format("DD-MMMM YYYY")} ,{webinar.webinar_start_time} UTC
              </Typography>
            </div>
          </CardContent>
          {/* <CardActions>
            <Button
              size="small"
              // onClick={() => history.push("/webinarAdminDetail")}
            >
              Learn More
            </Button>
          </CardActions> */}
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const WebinarAdminCardUnpublished = ({ webinar, index }) => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = Boolean(anchorEl);

  const handleCloseMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleEditClick = (e) => {
    handleCloseMenu(e);
  };

  const handleDeleteClick = (e) => {
    handleCloseMenu(e);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  return (
    <Grid item xs={3} key={index}>
      <Card
        sx={classes.card}
      // onClick={() => history.push("/webinarAdminDetail")}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            sx={classes.image}
            image={webinar.sas_webinarimage_path}
            alt="webinar image"
          />
          <IconButton
            color="default"
            aria-label="card actions"
            component="span"
            sx={classes.iconButton}
            onClick={handleMenuClick}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id={`basic_menu_${index}`}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleEditClick}>Edit</MenuItem>
            <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
          </Menu>
          <CardContent>
            <Typography
              gutterBottom
              variant="subtitle1"
              component="div"
              sx={classes.title}
            >
              {webinar.webinar_title}
            </Typography>
            <Typography variant="subtitle1" sx={classes.subtitle}>
              {webinar.speaker.map((obj) => obj.speaker_name).join(", ")}
            </Typography>
            {/* <Rating
              name="read-only"
              value={webinar.avgRating}
              readOnly
              sx={classes.rating}
            /> */}
            <hr style={classes.divider} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <AccessTimeIcon fontSize="small" sx={{ color: "#00D7B9" }} />
              <Typography style={{ marginLeft: "5px" }}>
                6-December 2021, 14:00
              </Typography>
            </div>
          </CardContent>
          {/* <CardActions>
            <Button
              size="small"
              // onClick={() => history.push("/webinarAdminDetail")}
            >
              Learn More
            </Button>
          </CardActions> */}
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const WebinarVetCard = ({
  webinar,
  showButton,
  rating,
  onPostQueryButtonClick,
  setVetCurrentWebinar,
  setQueryModal,
  webinarAttendance, country, full_name
}) => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = Boolean(anchorEl);

  const handleCloseMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const generatePdfDocument = async (webinar, country) => {
    const blob = await pdf((
      <PDFCertificateWebinar
        webinar={webinar} country={country} full_name={full_name}

      />
    )).toBlob();
    saveAs(blob, "certificate");
  };

  const handleClick = (e) => {
    handleCloseMenu(e);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  return (
    <div>
      <Grid container xs={12} sx={{ display: { xs: "flex", md: "none" }, marginBottom: 2 }} >
        <Grid item xs={4} style={{ height: "100px", }}>
          <img
            src={webinar.sas_webinarimage_path}
            alt="webinar image"
            style={{ height: "100px", width: 100, }}

          // objectFit="cover"
          />
        </Grid>
        <Grid container xs={8} sx={{ paddingLeft: 1 }}>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              variant="subtitle2"
              component="div"
              sx={classes.title}
            >
              {webinar.webinar_title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" noWrap>
              {webinar.speaker?.map((obj) => obj.speaker_name).join(", ")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              {moment(webinar.webinar_date, "DD-MM-YYYY").format("DD-MMMM YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              {webinar.webinar_start_time} UTC
            </Typography>
          </Grid>
          <Grid container justifyContent="end" xs={12}>
            {showButton === "Join" && <Button size="small" variant="outlined" onClick={() => { setVetCurrentWebinar(webinar); setQueryModal(true) }}
              style={{ position: "relative", float: "right", textTransform: "none", marginRight:"4px" }}>Post a query </Button>}


            {showButton !== "Join" ? (
              <Grid container justifyContent="left">
                <Button
                  variant="outlined"
                  size="small"
                  style={{ position: "relative", float: "right", textTransform: "none" }}
                  onClick={() => {
                    let modWebinar = { ...webinar, pastWebinar: true }
                    if (showButton === "View") {
                      setVetCurrentWebinar(modWebinar); history.push("/webinarVetDetail");
                    }
                    else {
                      setVetCurrentWebinar(webinar); history.push("/webinarVetDetail");
                    }

                  }}
                >
                  {showButton}
                </Button>
                {webinar.attended && showButton !== "Enrolled" && showButton !== "Enroll" && <Button style={{ textTransform: "none" }} variant="contained" sx={{ fontSize: 10,marginLeft:1 }} onClick={() => generatePdfDocument(webinar, country)}>
                  Download certificate
                </Button>}
                {/* <PDFDownloadLink document={<PDFCertificateWebinar webinar={webinar} country={country} />} fileName="certificate.pdf">
                  <Button style={{ textTransform: "none" }} variant="contained" sx={{ fontSize: 10, }}>
                    Download certificate
                  </Button>
                </PDFDownloadLink> */}

              </Grid>
            ) : (
              <Button
                size="small"

                style={{ position: "relative", float: "right", textTransform: "none", }}
                variant="outlined">
                <a onClick={() => webinarAttendance(webinar.webinar_id)} style={{ color: "white", textDecoration: "none" }} href={`${webinar.webinar_link}`} target="_blank" rel="noopener noreferrer" >Join</a>
              </Button>
            )}
          </Grid>

        </Grid>

      </Grid>

      <Grid container xs={12} sx={{ display: { xs: "none", md: "flex" }, padding: 1 }}>
        <Card
          sx={classes.card}
        // onClick={() => history.push("/webinarAdminDetail")}
        >
          <CardActionArea>
            <CardMedia
              component="img"
              sx={classes.image}
              image={webinar.sas_webinarimage_path}
              alt="webinar image"
            />
            {/* <IconButton
              color="default"
              aria-label="card actions"
              component="span"
              sx={classes.iconButton}
              onClick={handleMenuClick}
            >
              <MoreVert />
            </IconButton> */}

            <CardContent>
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                noWrap
                sx={classes.title}
              >
                {webinar.webinar_title}
              </Typography>
              <Typography noWrap variant="subtitle1" sx={classes.subtitle}>
                {webinar.speaker.map((obj) => obj.speaker_name).join(", ")}
              </Typography>
              <Divider sx={{ marginBottom: 1 }} />
              {/* <Typography noWrap variant="subtitle1" sx={classes.subtitle}>Enrollment ratio {<span style={{ color: "blue" }}>{webinar.enrollment_ratio}</span>}</Typography> */}


              <div style={{ display: "flex", alignItems: "center" }}>
                <AccessTimeIcon fontSize="small" sx={{ color: "#00D7B9" }} />
                <Typography style={{ marginLeft: "5px", fontSize: 15 }}>

                  {moment(webinar.webinar_date, "DD-MM-YYYY").format("DD-MMMM YYYY")} ,{webinar.webinar_start_time} UTC
                </Typography>
              </div>
              <Grid container justifyContent="end" xs={12}>

                {showButton === "Join" && <Button size="medium" variant="outlined" onClick={() => { setVetCurrentWebinar(webinar); setQueryModal(true) }}
                  style={{ position: "relative", float: "right", textTransform: "none", marginRight:"4px" }}>Post a query </Button>}

                {showButton !== "Join" ? (
                  <Grid container justifyContent="left" sx={{ marginTop: 1 }}>
                    <Button
                      variant="outlined"
                      size="medium"
                      style={{ position: "relative", float: "right", textTransform: "none" }}
                      onClick={() => {
                        let modWebinar = { ...webinar, pastWebinar: true }
                        if (showButton === "View") {
                          setVetCurrentWebinar(modWebinar); history.push("/webinarVetDetail");
                        }
                        else {
                          setVetCurrentWebinar(webinar); history.push("/webinarVetDetail");
                        }

                      }}
                    >
                      {showButton}
                    </Button>

                    {webinar.attended && showButton !== "Enrolled" && showButton !== "Enroll" && <Button style={{ textTransform: "none" }} variant="contained" sx={{ marginLeft:1 }} onClick={() => generatePdfDocument(webinar, country)}>
                      Download certificate
                    </Button>}
                    {/* <PDFDownloadLink document={<PDFCertificateWebinar webinar={webinar} country={country} />} fileName="certificate.pdf">
                      <Button style={{ textTransform: "none" }} variant="contained" sx={{ fontSize: 10, }}>
                        Download certificate
                      </Button>
                    </PDFDownloadLink> */}
                  </Grid>
                ) : (
                  <Button
                    size="medium"


                    style={{ position: "relative", float: "right", textTransform: "none", }}
                    variant="outlined">
                    <a onClick={() => webinarAttendance(webinar.webinar_id)} style={{ color:"inherit", textDecoration: "none" }} href={`${webinar.webinar_link}`} target="_blank" rel="noopener noreferrer" >Join</a>
                  </Button>
                )}

              </Grid>

            </CardContent>
            {/* <CardActions>
            <Button
              size="small"
              // onClick={() => history.push("/webinarAdminDetail")}
            >
              Learn More
            </Button>
          </CardActions> */}
          </CardActionArea>
        </Card>
      </Grid>
      {/* <hr style={{ marginLeft: 0, width: "98%" }} /> */}
    </div >
  );
};

export { WebinarAdminCard, WebinarAdminCardUnpublished, WebinarVetCard };
