import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useHistory, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import CoursesView from "./CoursesView";
import { connect } from "react-redux"
import { makeStyles } from "@mui/styles";
import {
    Grid, IconButton, TextField, InputAdornment, Chip, Drawer, MenuItem, Select, OutlinedInput,
    Stack, Button, Paper, Divider, Modal
} from "@mui/material"
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ScrollToTopButton from "../VetLearningContent/ScrollToTopButton"
import { getVetCourses } from ".././Redux/Middlewares/VetCourses/getCoursesVetMiddleware"
import SpinnerLG from "../SpinnerLG";
import { Autocomplete } from "@mui/material"
import { getFiltersVetCourses } from "../Redux/Middlewares/VetCourses/getFiltersVetCoursesMiddleware";
import { SET_USER_COURSE_FILTER_DATA, CLEAR_USER_COURSE_FILTER_DATA } from "../Redux/ActionTypes/coursesVetActionTypes"
import { getActiveVetCourses } from "../Redux/Middlewares/VetCourses/getActiveCourseMiddleware"
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import LinearProgress from '@mui/material/LinearProgress';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import LessonActive from "./LessonActive"
import ArticleIcon from '@mui/icons-material/Article';
import QuizIcon from '@mui/icons-material/Quiz';
import FileIcon from "../Assets/images/FileIcon.svg"
import QuizIconNew from "../Assets/images/QuizIconNew.svg"
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';





const CourseOverView = ({ contents, setCourseSequence, courseSequence, currentSelection, lessonCompletion,
    toggleQuizActivation, quizActive, getQuizDetails, quizLoader, quizData, clearQuizData, quizValidation, quizGrade, open, setOpen,
    setRoot, quizWrongAnswers , quizInput ,setTab}) => {

    const { id } = useParams()

    const [selectionState, setSelection] = React.useState({});
    const [state, setState] = React.useState(false);
    const [quizConfirmation, setQuizConfirmation] = React.useState(false);

    //creating the data set in redux to create states for selection highlight in chapters and lessons list.
    useEffect(() => {

        let selectionMap = {}

        for (let i = 0; i < contents.length; i++) {

            if (i === 0 || (contents[i - 1].chapter_completion)) {

                for (let j = 0; j < contents[i].lessons.length; j++) {
                    if (contents[i].lessons[j].course_level2_id.toString() + "x" === currentSelection) {
                        selectionMap[[contents[i].lessons[j].course_level2_id.toString() + "x"]] = true
                    }
                    else {
                        selectionMap[[contents[i].lessons[j].course_level2_id.toString() + "x"]] = false
                    }

                }
            }

        }

        setCourseSequence(selectionMap, currentSelection)

        return () => {

        }
    }, [])

    const handleClick = (index) => {
        open[index] = !open[index];
        let freshState = [...open]
        setOpen(freshState);
    };
    const selectionStyle = {
        borderRight: 4, backgroundColor: "#f3f3fb",
        borderColor: "#0f01a0",
        padding:1,
        borderRadius:"5px 0 0 5px"
    }

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',

        boxShadow: 24,
        p: 4,
    };

    // function to handle selection highlight on chapters and lessons
    const handleSelection = (nodeId) => {
        let tempObj = {}
        for (let key of Object.keys(courseSequence)) {

            if (key == nodeId) {
                tempObj[[key]] = true
            }
            else {
                tempObj[[key]] = false
            }

        }

        let chapterId = null

        let newSelection = nodeId.substring(0, nodeId.length - 1);
        let lessonType
        for (let i = 0; i < contents.length; i++) {

            if (i === 0 || (contents[i - 1].chapter_completion)) {

                for (let j = 0; j < contents[i].lessons.length; j++) {
                    if (contents[i].lessons[j].course_level2_id.toString() + "x" == nodeId) {
                        chapterId = contents[i].course_level1_id
                        lessonType = contents[i].lessons[j].file_type
                        break
                    }
                }
            }

        }
        
        if (lessonType !== "Quiz") {
            lessonCompletion(id, chapterId, newSelection)
        }
        setState(true);


        setCourseSequence(tempObj, nodeId)
    }
    const nextSelection = (nodeId) => {
        let tempObj = {}
        let change = false
        let nextKey = null
        let dict = Object.keys(courseSequence)
        for (let key of Object.keys(courseSequence)) {

           
            if (change === true) {
                tempObj[[key]] = true
                nextKey = key
                change = false
            }
            else if (key == nodeId) {
                if (dict[dict.length - 1] === key) {
                    tempObj[[key]] = true
                    nextKey = key
                    
                }
                else {
                    tempObj[[key]] = false
                    change = true
                }
            }
            else {
                tempObj[[key]] = false
            }


        }


        setCourseSequence(tempObj, nextKey)
    }


    const toggleDrawer = (val) => {
        if (quizActive !== 1) {
            setState(val);
        }
        if (quizActive === 1) {
            setQuizConfirmation(true)
        }

    };

    const completeOnSelection = (val, nodeId) => {

        let chapterId = null

        let newSelection = nodeId.substring(0, nodeId.length - 1);
        let lessonType
        for (let i = 0; i < contents.length; i++) {

            if (i === 0 || (contents[i - 1].chapter_completion)) {

                for (let j = 0; j < contents[i].lessons.length; j++) {
                    if (contents[i].lessons[j].course_level2_id.toString() + "x" == nodeId) {
                        chapterId = contents[i].course_level1_id
                        lessonType = contents[i].lessons[j].file_type
                        break
                    }
                }
            }

        }


        if (lessonType !== "Quiz") {
            lessonCompletion(id, chapterId, newSelection)
        }
        setState(val);
    }

    let completionPercentage = {}

    for (let i = 0; i < contents.length; i++) {
        let completed = 0
        let total = 0

        for (let j = 0; j < contents[i].lessons.length; j++) {
            if (contents[i].lessons[j].completion_status) {
                completed += 1
                total += 1
            }
            else {
                total += 1
            }
        }

        completionPercentage[contents[i].course_level1_id] = [completed, total]

    }


    let strDuration = (contents && contents[0]) ? contents[0].course_duration.split(":")[0] : ""
    let courseDuration = Number(strDuration) === 0 ? 1 : Number(strDuration)
    let durationFormated = isNaN(courseDuration) ? courseDuration : courseDuration === 1 ? "1 hour" : (`${courseDuration} hours`)

    return (<Grid container xs={12} xs={12} >
        <Modal
            open={quizConfirmation}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Do you want to cancel this quiz attempt?
                </Typography>
                <Stack direction="row" sx={{ mt: 2 }} spacing={1} >
                    <Button onClick={() => { setQuizConfirmation(false); setState(false); }} style={{ textTransform: "none" }} variant="contained">Yes</Button>
                    <Button onClick={() => setQuizConfirmation(false)} style={{ textTransform: "none" }} variant="contained">No</Button>
                </Stack>
            </Box>

        </Modal>
        <Grid item xs={12}>
            {/* <Button onClick={() => nextSelection(currentSelection)}>next</Button> */}
            <Typography sx={{ color: "#0f01a0", fontWeight: 800, fontSize: 18, marginTop: 3 }}>{contents && contents[0] ?
                contents[0].course_name : "No data is present for this course"}</Typography>
        </Grid>

        {strDuration !== "" &&
            <Grid item xs={12}>
                <Chip sx={{ color: "white", backgroundColor: "#8080cf", marginTop: .5 }} size="small" label={durationFormated} />
            </Grid>}

        <Grid item xs={12}>
            <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"


            >
                {
                    contents.map((item, index) => {
                        if (item.lessons && index === 0 || (item.lessons && contents[index - 1].chapter_completion)) {
                            return (
                                < >
                                    <ListItemButton disableGutters onClick={() => handleClick(index)}>
                                        <Grid container xs={12}>
                                            <Grid item xs={1}>
                                                <PlayCircleFilledWhiteIcon sx={{ color: "#6ad7b9" }} />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                                                    {`Chapter ${item.sequence_id}`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography sx={{ fontSize: 14 }}>
                                                    { }
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={1}>

                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography sx={{ fontSize: 12 }}>
                                                    {completionPercentage[item.course_level1_id][0]} out of {completionPercentage[item.course_level1_id][1]} items completed
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>

                                            </Grid>
                                            <Grid item xs={11}>
                                                <LinearProgress color="secondary" variant="determinate"
                                                    value={(completionPercentage[item.course_level1_id][0] / completionPercentage[item.course_level1_id][1]) * 100} />

                                            </Grid>

                                        </Grid>
                                        {open[index] ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Collapse in={open[index]} timeout="auto" unmountOnExit>

                                        <List component="div" disablePadding>
                                            {
                                                item.lessons.map(
                                                    subItem => {
                                                        return (
                                                            <ListItemButton onClick={() => {
                                                                handleSelection(subItem.course_level2_id.toString() + "x");
                                                            }} disableGutters sx={{padding:1}}>
                                                                <Grid container xs={12}
                                                                    sx={courseSequence && courseSequence[subItem.course_level2_id.toString() + "x"] ? selectionStyle : {padding:1,boxShadow:1}} >
                                                                    <Grid container justifyContent="center" alignItems="center" xs={4} sx={{ padding: .2, backgroundColor: "#f2f2f2", borderRadius: 2, }}>
                                                                        {subItem.file_type === "Video" ? (<div
                                                                        // style={{
                                                                        //     height: 70,
                                                                        //     width: "100%",
                                                                        //     borderRadius: 10,
                                                                        //     // backgroundImage: `url(${contents.sas_image_path})`,
                                                                        //     backgroundImage: `url("https://picsum.photos/250/150")`,

                                                                        //     display: "flex",
                                                                        //     justifyContent: "flex-end",
                                                                        //     backgroundRepeat: "space",
                                                                        //     backgroundSize: "cover"
                                                                        // }}

                                                                        >
                                                                            <OndemandVideoIcon sx={{ color: "#8080cf", height: 40, width: "100%" }} />
                                                                        </div>) : subItem.file_type === "Audio" ?


                                                                            <AudiotrackIcon sx={{ color: "#8080cf", height: 40, width: "100%" }} /> : subItem.file_type === "Reading Material" ?
                                                                                // (<ArticleIcon sx={{
                                                                                //     height: 70,
                                                                                //     width: "100%",
                                                                                //     borderRadius: 10, color: "#8080cf"
                                                                                // }} />)
                                                                                (<img src={FileIcon} style={{
                                                                                    height: 40,
                                                                                    width: "100%",
                                                                                    borderRadius: 10
                                                                                }} alt="quiz" />)
                                                                                :
                                                                                // (<QuizIcon sx={{
                                                                                //     height: 40,
                                                                                //     width: "100%",
                                                                                //     borderRadius: 10, color: "#8080cf"
                                                                                // }} />)
                                                                                (<img src={QuizIconNew} style={{
                                                                                    height: 40,
                                                                                    width: "100%",
                                                                                    borderRadius: 10
                                                                                }} alt="quiz" />)
                                                                        }
                                                                    </Grid>
                                                                    <Grid container xs={8} sx={{ overflow: "hidden", paddingLeft: 1, marginBottom: 1 }}>
                                                                        <Grid container xs={12}>
                                                                            <Typography noWrap sx={{ fontSize: 16, fontWeight: 600, color: "#0f01a0", }}>
                                                                                {subItem.file_type === "Video" ? subItem.content_name :
                                                                                    subItem.file_type === "Reading Material" ? "Reading Material" :
                                                                                        subItem.file_type === "Audio" ? "Audio" : "Quiz"}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid container xs={10}>
                                                                            <Typography sx={{ fontSize: 14, fontWeight: 600, }}>
                                                                                {subItem.file_type === "Video" || subItem.file_type === "Audio" ? `${(Number(subItem.duration.split(" ")[0]) / 3600).toPrecision(1)} hours` :
                                                                                    subItem.file_type === "Quiz" ? subItem.quiz_count + (subItem.quiz_count == 1 ? " question" : "  questions") : ""}
                                                                            </Typography>
                                                                        </Grid>



                                                                        {subItem.completion_status ? <Grid container xs={2}>
                                                                            <CheckCircleRoundedIcon color="secondary" />
                                                                        </Grid> : <Grid container xs={2}></Grid>}

                                                                        <Grid item xs="auto" >
                                                                            {!subItem.completion_status && <Typography noWrap sx={{
                                                                                border: 1.5, borderColor: "#0f01a0",
                                                                                borderRadius: 1, fontWeight: 600, paddingLeft: 1, paddingRight: 1, paddingTop: .2,
                                                                                paddingBottom: .1, fontSize: 14
                                                                            }}>{subItem.file_type === "Quiz" ? "Take Quiz" : "Start Learning"}</Typography>}
                                                                        </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                            </ListItemButton>
                                                        )
                                                    }
                                                )
                                            }
                                        </List>

                                    </Collapse>
                                </>
                            )
                        }

                        else if (item.lessons && item.chapter_completion === false) {
                            return (<>
                                <ListItemButton disableGutters >
                                    <Grid container xs={12}>
                                        <Grid item xs={1}>
                                            <LockIcon sx={{ color: "#c4c4c4" }} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography sx={{ fontWeight: 600, fontSize: 14, color: "#acacac" }}>
                                                {`Chapter ${item.sequence_id}`} 
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography sx={{ fontSize: 14, color: "#acacac" }}>
                                                {item.content_name} : Please complete previous chapters to unlock
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                                <Divider sx={{ marginBottom: 2 }} />
                            </>
                            )

                        }

                    })
                }





            </List>
        </Grid >
        <Drawer
            anchor={"bottom"}
            open={state}
            onClose={() => toggleDrawer(false)}
        >
            <LessonActive id={id} toggleDrawer={toggleDrawer} setCourseSequence={setCourseSequence}
                courseSequence={courseSequence} currentSelection={currentSelection}
                contents={contents} open={open} handleClick={handleClick} nextSelection={nextSelection}
                lessonCompletion={lessonCompletion} completionPercentage={completionPercentage}
                toggleQuizActivation={toggleQuizActivation} quizActive={quizActive}
                getQuizDetails={getQuizDetails} quizLoader={quizLoader} quizData={quizData}
                clearQuizData={clearQuizData} quizValidation={quizValidation} quizGrade={quizGrade} setRoot={setRoot}
                quizWrongAnswers={quizWrongAnswers} quizInput={quizInput} setTab={setTab}/>
        </Drawer>
    </Grid>)
}

export default CourseOverView