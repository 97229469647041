import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useHistory } from "react-router-dom";
import { Box, IconButton } from "@mui/material";

const BackButton = () => {
  const history = useHistory();
  return (
    <Box
      sx={{
        zIndex: 1,
        width: "100%",
        height: 45,
        color: "white",
        backgroundColor: "white",
        position: "fixed",
      }}
    >
      <IconButton
        sx={{}}
        onClick={() => {
          history.goBack();
        }}
      >
        {" "}
        <KeyboardBackspaceIcon sx={{ fontWeight: 600, fontSize: 30 }} />
      </IconButton>
    </Box>
  );
};

export default BackButton;
