import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useHistory, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import CoursesView from "./CoursesView";
import { connect } from "react-redux"
import { makeStyles } from "@mui/styles";
import {
    Grid, IconButton, TextField, InputAdornment, Chip, Drawer, MenuItem, Select, OutlinedInput,
    Stack, Button, Paper, Divider, Slider
} from "@mui/material"
import { styled, alpha } from "@mui/material/styles";
import { Document, Page, pdfjs } from 'react-pdf';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ScrollToTopButton from "../VetLearningContent/ScrollToTopButton"
import { getVetCourses } from ".././Redux/Middlewares/VetCourses/getCoursesVetMiddleware"
import SpinnerLG from "../SpinnerLG";
import { Autocomplete } from "@mui/material"
import { getFiltersVetCourses } from "../Redux/Middlewares/VetCourses/getFiltersVetCoursesMiddleware";
import { SET_USER_COURSE_FILTER_DATA, CLEAR_USER_COURSE_FILTER_DATA } from "../Redux/ActionTypes/coursesVetActionTypes"
import { getActiveVetCourses } from "../Redux/Middlewares/VetCourses/getActiveCourseMiddleware"
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import LinearProgress from '@mui/material/LinearProgress';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ReactPlayer from 'react-player/lazy'
import CloseIcon from '@mui/icons-material/Close';
import StyledPDFContainer from "../VetLearningContent/StyledPDFContainer";
import FileIcon from "../Assets/images/FileIcon.svg"
import QuizIconNew from "../Assets/images/QuizIconNew.svg"
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import ZoomOut from "@mui/icons-material/ZoomOut";
import ZoomIn from "@mui/icons-material/ZoomIn";

// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ReadingMaterialView = ({ toggleDrawer, setCourseSequence, courseSequence,
    currentSelection, contents, open, handleClick, nextSelection, completionPercentage,
    id, lessonCompletion, setRoot }) => {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(4);
    const [zoom, setZoom] = useState(30);
    const [zoomDesktop, setZoomDesktop] = useState(60);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const handleZoom = (event, newValue) => {
        setZoom(newValue);
    };
    const handleZoomDesktop = (event, newValue) => {
        setZoomDesktop(newValue);
    };


    // function to handle selection highlight on chapters and lessons
    const handleSelection = (nodeId) => {

        let tempObj = {}
        for (let key of Object.keys(courseSequence)) {

            if (key == nodeId) {
                tempObj[[key]] = true
            }
            else {
                tempObj[[key]] = false
            }

        }

        let chapterId = null
        let lessonType

        let newSelection = nodeId.substring(0, nodeId.length - 1);
        for (let i = 0; i < contents.length; i++) {

            if (i === 0 || (contents[i - 1].chapter_completion)) {

                for (let j = 0; j < contents[i].lessons.length; j++) {
                    if (contents[i].lessons[j].course_level2_id.toString() + "x" == nodeId) {
                        chapterId = contents[i].course_level1_id
                        lessonType = contents[i].lessons[j].file_type

                        break
                    }
                }
            }

        }
        console.log(id, chapterId, newSelection)
        if (lessonType !== "Quiz") {
            lessonCompletion(id, chapterId, newSelection)
        }





        setCourseSequence(tempObj, nodeId)
    }

    const selectionStyle = {
        borderRight: 4, backgroundColor: "#f3f3fb",
        borderColor: "#0f01a0",
        padding: 1,
        borderRadius: "5px 0 0 5px"
    }

    let contentType = null
    let contentLink = null


    for (let i = 0; i < contents.length; i++) {
        for (let j = 0; j < contents[i].lessons.length; j++) {
            if (contents[i].lessons[j].course_level2_id.toString() + "x" === currentSelection) {
                contentType = contents[i].lessons[j].file_type
                contentLink = contents[i].lessons[j].sas_file_path
            }
        }

    }


    return (<Grid container md={12} spacing={2} direction="row-reverse" sx={{ marginTop: 4, marginBottom: 4 }}>

        <Grid item xs={12} sm={8} md={8}>
            {/* display={{ sm: "none" }} */}

            <Stack spacing={2} direction="row" sx={{ mb: 1, display: { xs: "flex", md: "none" } }} alignItems="center">
                <ZoomOut />
                <Slider aria-label="Volume" value={zoom} onChange={handleZoom} />
                <ZoomIn />
            </Stack>

            <Stack spacing={2} direction="row" sx={{ mb: 1, display: { xs: "none", md: "flex" } }} alignItems="center">
                <ZoomOut />
                <Slider aria-label="Volume" value={zoomDesktop} onChange={handleZoomDesktop} />
                <ZoomIn />
            </Stack>

            <Grid sx={{
                backgroundColor: { sm: "none", md: "#b3b3b3" }, height: "70vh",
                overflow: "scroll", display: "flex", width: "100%", border: 1, borderRadius: 1.5
            }} container justifyContent="center" xs={12} sm={12} md={12} spacing={0}>


                <Box sx={{ justifyContent: "center", overflow: 'auto', bgcolor: 'background.paper', display: { xs: "flex", md: "none" } }}>

                    <Document
                        // contents.article_info.sas_file_path
                        file={contentLink}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {numPages && ([...Array(numPages).keys()].map(item => (
                            <Page key={item} width={zoom * 13}
                                renderTextLayer={false} pageNumber={item + 1} />)))}
                    </Document>

                </Box>

                <Box sx={{ justifyContent: "center", overflow: 'auto', bgcolor: 'background.paper', display: { xs: "none", md: "flex" } }}>

                    <Document
                        // contents.article_info.sas_file_path
                        file={contentLink}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {numPages && ([...Array(numPages).keys()].map(item => (
                            <Page key={item} width={zoomDesktop * 13}
                                renderTextLayer={false} pageNumber={item + 1} />)))}
                    </Document>

                </Box>
                {/* <p>Page {pageNumber} of {numPages}</p> */}
                {/* </div> */}

                {/* } */}
                <ScrollToTopButton showBelow={300} />
            </Grid>
            {/* <Grid item xs={12}>
                <Button fullWidth onClick={() => nextSelection(currentSelection)} style={{ textTransform: "none", }} sx={{
                    border: 2,
                    marginTop: 2, fontSize: 15, color: "black", borderColor: "#0f01a0", fontWeight: 600
                }}
                    variant="outlined">Go to next lesson</Button>
            </Grid> */}
        </Grid>



        <Grid item xs={12} sm={4} md={4}>{
            contents.map((item, index) => {
                if (item.lessons && index === 0 || (item.lessons && contents[index - 1].chapter_completion)) {
                    return (
                        < >
                            <ListItemButton disableGutters onClick={() => handleClick(index)}>
                                <Grid container xs={12}>
                                    <Grid item xs={1}>
                                        <PlayCircleFilledWhiteIcon sx={{ color: "#6ad7b9" }} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                                            {`Chapter ${item.sequence_id}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography sx={{ fontSize: 14 }}>
                                            { }
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={1}>

                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography sx={{ fontSize: 12 }}>
                                            {completionPercentage[item.course_level1_id][0]} out of {completionPercentage[item.course_level1_id][1]} items completed
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>

                                    </Grid>
                                    <Grid item xs={11}>
                                        <LinearProgress color="secondary" variant="determinate"
                                            value={(completionPercentage[item.course_level1_id][0] / completionPercentage[item.course_level1_id][1]) * 100} />


                                    </Grid>

                                </Grid>
                                {open[index] ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={open[index]} timeout="auto" unmountOnExit>

                                <List component="div" disablePadding>
                                    {
                                        item.lessons.map(
                                            subItem => {
                                                return (
                                                    <ListItemButton onClick={() => {
                                                        handleSelection(subItem.course_level2_id.toString() + "x");

                                                    }}
                                                        disableGutters sx={{ padding: 1 }}>
                                                        <Grid container xs={12}
                                                            sx={courseSequence && courseSequence[subItem.course_level2_id.toString() + "x"] ? selectionStyle : { padding: 1, boxShadow: 1 }} >
                                                            <Grid item xs={4} sx={{ padding: .5, backgroundColor: "#f2f2f2", borderRadius: 2, }}>
                                                                {subItem.file_type === "Video" ? (<div


                                                                >
                                                                    <OndemandVideoIcon sx={{ color: "#8080cf", height: 40, width: "100%" }} />
                                                                </div>) : subItem.file_type === "Audio" ?


                                                                    <AudiotrackIcon sx={{ color: "#8080cf", height: 40, width: "100%" }} /> : subItem.file_type === "Reading Material" ?
                                                                        // (<ArticleIcon sx={{
                                                                        //     height: 55,
                                                                        //     width: "100%",
                                                                        //     borderRadius: 10, color: "#8080cf"
                                                                        // }} />)
                                                                        (<img src={FileIcon} style={{
                                                                            height: 40,
                                                                            width: "100%",
                                                                            borderRadius: 10
                                                                        }} alt="quiz" />)
                                                                        :
                                                                        // (<QuizIcon sx={{
                                                                        //     height: 40,
                                                                        //     width: "100%",
                                                                        //     borderRadius: 10, color: "#8080cf"
                                                                        // }} />)
                                                                        (<img src={QuizIconNew} style={{
                                                                            height: 40,
                                                                            width: "100%",
                                                                            borderRadius: 10
                                                                        }} alt="quiz" />)
                                                                }
                                                            </Grid>
                                                            <Grid container xs={8} sx={{ overflow: "hidden", paddingLeft: 1 }}>
                                                                <Grid container xs={12}>
                                                                    <Typography noWrap sx={{ fontSize: 16, fontWeight: 600, color: "#0f01a0", }}>
                                                                        {subItem.file_type === "Video" ? subItem.content_name :
                                                                            subItem.file_type === "Reading Material" ? "Reading Material" :
                                                                                subItem.file_type === "Audio" ? "Audio" : "Quiz"}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid container xs={10}>
                                                                    <Typography sx={{ fontSize: 14, fontWeight: 600, }}>
                                                                        {subItem.file_type === "Video" || subItem.file_type === "Audio" ? `${(Number(subItem.duration.split(" ")[0]) / 3600).toPrecision(1)} hours` :
                                                                            subItem.file_type === "Quiz" ? subItem.quiz_count + (subItem.quiz_count == 1 ? " question" : "  questions") : ""}
                                                                    </Typography>
                                                                </Grid>



                                                                {subItem.completion_status ? <Grid container xs={2}>
                                                                    <CheckCircleRoundedIcon color="secondary" />
                                                                </Grid> : <Grid container xs={2}></Grid>}

                                                                {/* <Grid item xs="auto" onClick={() => toggleDrawer(true)}>
                                                                                    <Typography noWrap sx={{
                                                                                        border: 1.5, borderColor: "#0f01a0",
                                                                                        borderRadius: 1, fontWeight: 600, paddingLeft: 1, paddingRight: 1, paddingTop: .2,
                                                                                        paddingBottom: .1, fontSize: 14
                                                                                    }}></Typography>
                                                                                </Grid> */}

                                                            </Grid>

                                                        </Grid>
                                                    </ListItemButton>
                                                )
                                            }
                                        )
                                    }
                                </List>

                            </Collapse>
                        </>
                    )
                }

                else if (item.lessons && item.chapter_completion === false) {
                    return (<>
                        <ListItemButton disableGutters >
                            <Grid container xs={12}>
                                <Grid item xs={1}>
                                    <LockIcon sx={{ color: "#c4c4c4" }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography sx={{ fontWeight: 600, fontSize: 14, color: "#acacac" }}>
                                        {`Chapter ${item.sequence_id}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography sx={{ fontSize: 14, color: "#acacac" }}>
                                        {item.content_name} : Please complete previous chapters to unlock
                                    </Typography>

                                </Grid>
                            </Grid>
                        </ListItemButton>
                        <Divider sx={{ marginBottom: 2 }} />
                    </>
                    )

                }

            })
        }</Grid>
    </Grid>)
}

export default ReadingMaterialView