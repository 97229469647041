import React, { useState, useEffect } from "react";
// import "./styles.css";
import { useHistory, withRouter } from "react-router-dom";
import { Box, Paper, Typography, Grid, TextField, Button, CssBaseline } from '@mui/material';
import { connect } from "react-redux"
import { login } from "../Redux/Middlewares/loginMiddleware";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { styled, alpha } from "@mui/material/styles";
import {LoginCloseRequest} from "../Redux/ActionCreators/NavbarActionCreators"
import SignupFormRes from "./SignupFormRes";
import { useFormik } from "formik"
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import SignupOTPform from "./SignupOTPform"
import SignupCreateProfileForm from "./SignupCreateProfileForm"
import { signupInit } from "../Redux/Middlewares/signUpInitMiddleware";
import { createProfile } from "../Redux/Middlewares/createProfileMiddleware";
import { Redirect} from "react-router-dom";
import { DISABLE_CREATE_PROFILE_SCREEN } from "../Redux/ActionTypes/authenticationActionTypes";

  

const SignupCreateProfileContainer = ({ createProfile,isAuthenticated,userStatus,emailSaved,processLoader }) => {


  const [emailMain, setEmailMain] = useState(0);

  const StyledMain = styled('div')(({ theme }) => ({
    marginTop: '1%',
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  
  }));

  const PaperWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 60,
    },
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  
  }));
  

 if(!isAuthenticated ||  userStatus !== "incompleteProfile" ){
    return(<Redirect to="/" />)
  }


  return (
    <>
     <CssBaseline/>
     <StyledMain>
       <PaperWrapper>
         <Paper sx={{padding:2,paddingLeft:{xs:2,sm:6},paddingRight:{xs:2,sm:6}}}>
         {/* <Box sx={{display:"flex",justifyContent:"center"}}>
          <img src={Logo} alt="logo.png"/>
          </Box> */}
          
           <SignupCreateProfileForm processLoader={processLoader} emailSaved={emailSaved} createProfile={createProfile} />
 
   
           
         </Paper>
       </PaperWrapper>

     </StyledMain>

    </>
  );
};



const mapStateToProps = (state) => ({
    isAuthenticated: state.authenticationReducer.isAuthenticated,
    // createProfileScreen:state.authenticationReducer.createProfileScreen,
    userStatus:state.authenticationReducer.userStatus,
    emailSaved:state.authenticationReducer.user,
    processLoader:state.AlertsReducer.processLoader
  })
  
  const mapDispatchToProps = dispatch => {
    return {
  
      createProfile:(reqObj)=>dispatch(createProfile(reqObj)),
      
    }
  }
  
  export default connect(mapStateToProps,mapDispatchToProps)(SignupCreateProfileContainer);