import axios from "axios";
import {
  deleteFileFailure,
  deleteFileRequest,
  deleteFileSuccess,
  toggleAlert,
} from "../../ActionCreators/learningContentAdminActionCreators";

const FILE_DELETE_URL =
  process.env.REACT_APP_API_URL + "/learningcontent/admin/content/";

export const fileDelete = (fileId) => (dispatch) => {
  dispatch(deleteFileRequest());
  axios
    .delete(FILE_DELETE_URL + `${fileId}/`)
    .then((response) => {
      if (response.status === 204) {
        dispatch(deleteFileSuccess());
        dispatch(toggleAlert("success", "File deleted successfully !", true));
      } else {
        dispatch(deleteFileFailure("Something went wrong !"));
        dispatch(toggleAlert("error", "Error while deletion !", true));
      }
    })
    .catch((error) => {
      dispatch(deleteFileFailure(error.message));
      dispatch(toggleAlert("error", error.message, true));
    });
};
