import axios from "axios";
import {
    //   fetchWEBINARSFailure,
    //   fetchWEBINARSRequest,
    fetchWebinarsSuccess,

    //   toggleAlert,
} from "../../ActionCreators/webinarsAdminActionCreators";
import {
    TOGGLE_VET_WEBINAR_ENROLLMENT_STATUS
} from "../../ActionTypes/webinarsVetActionTypes"

import { toggleGlobalAlert } from "../../ActionCreators/alertsActionCreators";

// const GET_WEBINARS_URL =
//   process.env.REACT_APP_API_URL + "/WEBINARS/admin/course_homepage/?page=";


export const registerWebinar = (id, flag) => async dispatch => {

    try {

        let url = process.env.REACT_APP_API_URL + `/vetwebinars/register/`

        let reqObj = {
            "webinar_id": id,
            "type": flag
        }


        let result = await axios.post(url, reqObj)



        if (result.data) {



            if (flag === "register") {
                dispatch(toggleGlobalAlert("success", "Registered successfully", true))
                dispatch({ type: TOGGLE_VET_WEBINAR_ENROLLMENT_STATUS, payload: true })
            }
            else {
                dispatch(toggleGlobalAlert("success", "Unregistered successfully", true))
            }




        }
        else {

            dispatch(toggleGlobalAlert("success", "Something went wrong", true))
        }



    }
    catch (err) {
        if (err.response.status === 403) {
            dispatch(toggleGlobalAlert("warning", "Enrollment window closed", true))
        }
        else {
            dispatch(toggleGlobalAlert("success", "Something went wrong", true))

        }
    }
}


