import axios from "axios";
import {
    createCourseFailure,
    createCourseRequest,
    createCourseSuccess,
    createEditChapterFailure,
    createEditChapterRequest,
    createEditChapterSuccess,
    createEditLessonFailure,
    createEditLessonRequest,
    createEditLessonSuccess,
    setActiveCourse,
    setCurriculumDetails,
    toggleAlert,
} from "../../ActionCreators/coursesAdminActionCreators";



export const deleteLesson = (course_level2_id) => (dispatch) => {
    const CREATE_DELETE_LESSON_URL =
        process.env.REACT_APP_API_URL + `/curriculum/admin/delete_lesson/${course_level2_id}/`;

    // dispatch(createEditLessonRequest());
    axios
        .delete(CREATE_DELETE_LESSON_URL)
        .then((response) => {
            if (response.status === 200) {
                dispatch(createEditLessonSuccess());

                dispatch(toggleAlert("success", "lesson deleted successfully !", true));


                dispatch(setCurriculumDetails(response.data));
            } else {
                dispatch(createEditLessonFailure("Something went wrong !"));
                dispatch(toggleAlert("error", "Something went wrong !", true));
            }
        })
        .catch((error) => {
            dispatch(createEditLessonFailure(error.message));
            dispatch(toggleAlert("error", error.message, true));
        });
};