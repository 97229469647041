import {
  // CHANGE_COURSE_STATUS_FAILURE,
  // CHANGE_COURSE_STATUS_REQUEST,
  // CHANGE_COURSE_STATUS_SUCCESS,
  CHANGE_PAGE_WEBINAR_VET,
  // DELETE_COURSE_FAILURE,
  // DELETE_COURSE_REQUEST,
  // DELETE_COURSE_SUCCESS,
  // EDIT_COURSE_FAILURE,
  // EDIT_COURSE_REQUEST,
  // EDIT_COURSE_SUCCESS,
  // FETCH_COURSES_FAILURE,
  // FETCH_COURSES_REQUEST,
  FETCH_WEBINARS_VET_SUCCESS,
  FETCH_FILTER_VALUES_FAILURE_WEBINAR_VET,
  FETCH_FILTER_VALUES_REQUEST_WEBINAR_VET,
  FETCH_FILTER_VALUES_SUCCESS_WEBINAR_VET,
  SELECT_FILTER_WEBINAR_VET,
  SET_CURRENT_VET_WEBINAR
  // TOGGLE_ALERT,
} from "../ActionTypes/webinarsVetActionTypes";

export const fetchFilterValuesRequest = () => ({
  type: FETCH_FILTER_VALUES_REQUEST_WEBINAR_VET,
});

export const fetchFilterValuesSuccess = (filterValues) => ({
  type: FETCH_FILTER_VALUES_SUCCESS_WEBINAR_VET,
  payload: filterValues,
});

export const fetchFilterValuesFailure = (error) => ({
  type: FETCH_FILTER_VALUES_FAILURE_WEBINAR_VET,
  payload: error,
});

export const selectFilter = (filterType, filterValue) => ({
  type: SELECT_FILTER_WEBINAR_VET,
  payload: {
    type: filterType,
    value: filterValue,
  },
});

//   export const fetchCoursesRequest = () => ({
//     type: FETCH_COURSES_REQUEST,
//   });

export const fetchWebinarsSuccess = (webinars) => ({
  type: FETCH_WEBINARS_VET_SUCCESS,
  payload: webinars,
});

//   export const fetchCoursesFailure = (error) => ({
//     type: FETCH_COURSES_FAILURE,
//     payload: error,
//   });

export const changePage = (pageNumber) => ({
  type: CHANGE_PAGE_WEBINAR_VET,
  payload: pageNumber,
});

export const setVetCurrentWebinar = (webinarObj) => ({
  type: SET_CURRENT_VET_WEBINAR,
  payload: webinarObj,
});


//   export const editCourseRequest = () => ({
//     type: EDIT_COURSE_REQUEST,
//   });

//   export const editCourseSuccess = () => ({
//     type: EDIT_COURSE_SUCCESS,
//   });

//   export const editCourseFailure = (error) => ({
//     type: EDIT_COURSE_FAILURE,
//     payload: error,
//   });

//   export const deleteCourseRequest = () => ({
//     type: DELETE_COURSE_REQUEST,
//   });

//   export const deleteCourseSuccess = () => ({
//     type: DELETE_COURSE_SUCCESS,
//   });

//   export const deleteCourseFailure = (error) => ({
//     type: DELETE_COURSE_FAILURE,
//     payload: error,
//   });

//   export const changeCourseStatusRequest = () => ({
//     type: CHANGE_COURSE_STATUS_REQUEST,
//   });

//   export const changeCourseStatusSuccess = () => ({
//     type: CHANGE_COURSE_STATUS_SUCCESS,
//   });

//   export const changeCourseStatusFailure = (error) => ({
//     type: CHANGE_COURSE_STATUS_FAILURE,
//     payload: error,
//   });

//   export const toggleAlert = (type, message, value) => ({
//     type: TOGGLE_ALERT,
//     payload: { type, message, value },
//   });
