import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
    Autocomplete,
    Button,
    Card,
    Checkbox,
    CircularProgress,
    Container,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormHelperText,
    Grid,
    TextField,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Tooltip,
    Pagination
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FilterAlt, ClearAll, Search } from "@mui/icons-material";
import QueryCard from "../QueryCard/QueryCard";
import QueryCardNew from "../QueryCard/QueryCardNew"
import { connect, useDispatch } from "react-redux";
import {
    fetchAdminQueriesDropdowns,
    fetchAdminQueryDetails,
    updateAdminQueryArchive,
    deleteArchivedQueryAdmin,
    setCheckboxes
} from "../../Redux/Middlewares/queriesAdminMiddleware";
import { selectFilter } from "../../Redux/ActionCreators/queriesAdminActionCreators";
import "./queriesAdmin.css";
import { classes } from "./styles";
import { catchError } from "../../Redux/ActionCreators/queriesAdminActionCreators";
import QueryCardAdmin from "../QueriesVet/QueryCard/QueryCardAdmin";
import SpinnerLG from "../../SpinnerLG";
function Queries({
    queriesAdmin,
    fetchAdminQueriesDropdowns,
    fetchAdminQueryDetails,
    selectFilter,
    updateAdminQueryArchive,
    deleteArchivedQueryAdmin,
    setCheckboxes
}) {
    const [open, setOpen] = useState(false);
    const [dialogText, setDialogText] = useState("");
    const [pageNumber, setPageNumber] = useState(1);

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const [tab, setTab] = useState("unanswered");
    const handleTabChange = (event, newValue) => {
        setTab(newValue);
        setPageNumber(1)
        // fetchAdminQueryDetails(filtersStateLocal);
    };

    //function for updating filteres
    const setFilters = (currType, currValue) => {
        selectFilter(currType, currValue);
        // setPageNumber(1)
    }

    //functin for clearing filters
    const handleClearFilter = () => {
        selectFilter("duration", { value: "All time" });
        selectFilter("type", []);
        selectFilter("category", []);
        selectFilter("Visibility", []);
        selectFilter("search", "");

        const defaultFilterObj = {
            query_category: [],
            content_type: [],
            search_value: "",
            query_type: tab,
            query_visibilty: [],
            show_content_from: {
                fixed: "All time",
                start_date: null,
                end_date: null,
            }
        }

        //setting local state of filter object
        setFiltersState(defaultFilterObj);
        fetchAdminQueryDetails(defaultFilterObj, 1);

        // setClearFilterClicked(clearFilterClicked + 1);
        setPageNumber(1)
        // fetchQueries(1)
    };

    //state for maintaining selected checkboxed
    const [checkboxObj, setCheckboxState] = useState({});

    //state for maintaining selected checkboxes
    const [selectedCheckboxCount, setCheckboxCount] = useState(0);
    //local state for maintaining selected filters object
    const [filtersStateLocal, setFiltersState] = useState({});
    const [checkArray, setCheckArray] = useState([])

    const handleCheck = (tempId) => {
        if (checkArray.includes(tempId)) {
            let temp = checkArray.filter((item) => item !== tempId);
            setCheckArray(temp);
        } else {

            let temp = [...checkArray, tempId]
            setCheckArray(temp);
        }
    }

    // const handleCheck = (tempId) => {
    //     if (checkArray.includes(tempId)) {
    //         let temp = checkArray.filter((item) => item !== tempId);
    //         setCheckArray(temp);

    //         setCheckboxCount(selectedCheckboxCount - 1);
    //     } else {

    //         let temp = [...checkArray, tempId]
    //         setCheckArray(temp);

    //         setCheckboxCount(selectedCheckboxCount + 1);
    //     }
    // }

    //function for updating the checkbox state
    // const updateCheckboxState = (currCheckBoxId) => {
    //     let tempObj = JSON.parse(JSON.stringify(checkboxObj));
    //     tempObj[currCheckBoxId] = !tempObj[currCheckBoxId];
    //     setCheckboxState(tempObj);


    //     //updating selected checkbox count
    //     if (tempObj[currCheckBoxId] == true) {
    //         setCheckboxCount(selectedCheckboxCount + 1);
    //     }
    //     else {
    //         setCheckboxCount(selectedCheckboxCount - 1);
    //     }
    // }

    //function for calling archive query api or delete query api
    const updateQueryDetails = () => {
        // let tempObj = JSON.parse(JSON.stringify(checkboxObj));
        // const objKeys = Object.keys(tempObj);
        // let archivedQueries = [];
        // for (let i = 0; i < objKeys.length; i++) {
        //     if (tempObj[objKeys[i]] == true) {
        //         archivedQueries.push(parseInt(objKeys[i].split("_")[1]))
        //     }
        // }

        const selectedQueriesObj = {
            "query_id": checkArray
        }

        if (tab != "archived") {
            //calling archive action
            updateAdminQueryArchive(selectedQueriesObj, filtersStateLocal);
            setDialogText("Query Archived Successfully");
            handleOpen();
            setTimeout(() => handleClose(), 1000);

            //resetting checkbox
            // checkboxState();
            setCheckArray([])
        }

        if (tab == "archived") {
            deleteArchivedQueryAdmin(selectedQueriesObj, filtersStateLocal);
            setDialogText("Query Deleted Successfully");
            handleOpen();
            setTimeout(() => handleClose(), 1000);

            //resetting checkbox
            // checkboxState();
            setCheckArray([])
        }
        setCheckArray([])
    }

    const {
        filters,
        selectedFilters,
        isLoading,
        queryDetails: { results },
    } = queriesAdmin;

    //function for storing checkboxes defaukt state as per current active tab
    const checkboxState = () => {

        const { queryDetails } = queriesAdmin;

        let checkboxStateData = {};
        queryDetails['results'].map(e => {
            checkboxStateData['query_id'] = false;
        })

        setCheckboxState(checkboxStateData);

        //resetting checkbox count
        setCheckboxCount(0)
    }

    //clear selection
    const clearSelection = () => {

        setCheckArray([])
    }


    //handling page change
    const handlePageChange = (event, value) => {
        setPageNumber(value);
        fetchQueries(value);
    };

    useEffect(() => {
        fetchAdminQueriesDropdowns();


    }, []);

    useEffect(() => {
        if (queriesAdmin['queryDetails']['results'].length > 0) {
            // checkboxState();
            setCheckArray([])
        }
    }, [queriesAdmin])

    useEffect(() => fetchQueries(1), [tab]);
    const handleApplyFiltersClick = () => {
        setPageNumber(1)
        fetchQueries(1)
    }

    const fetchQueries = (currentPage) => {
        const getDateObject = () => {
            if (
                selectedFilters.duration === "" ||
                selectedFilters.duration === null ||
                selectedFilters.duration.value === "All time"
            )
                return {
                    fixed: "All time",
                    start_date: null,
                    end_date: null,
                };
            else if (selectedFilters.duration.value === "1 Month")
                return {
                    fixed: "1 Month",
                    start_date: null,
                    end_date: null,
                };
            else if (selectedFilters.duration.value === "3 Months")
                return {
                    fixed: "3 Months",
                    start_date: null,
                    end_date: null,
                };
            else if (selectedFilters.duration.value === "6 Months")
                return {
                    fixed: "6 Months",
                    start_date: null,
                    // start_date: handleDateOutput(6),
                    end_date: null,
                };
        };

        const filterObject = {
            query_category: selectedFilters.category,
            content_type: selectedFilters.type,
            query_visibilty: selectedFilters.Visibility,
            search_value: selectedFilters.search
                ? [selectedFilters.search]
                : [],
            query_type: tab,
            show_content_from: getDateObject(),
        };
        //setting local state of filter object
        setFiltersState(filterObject);
        fetchAdminQueryDetails(filterObject, currentPage);
    };

    const { queryDetails } = queriesAdmin;
    return (
        <Box style={{ marginTop: "2%" }}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <strong>{dialogText}</strong>
                    </DialogContentText>
                </DialogContent>
                {/* {!status && (
                        <DialogActions>
                            <LoadingButton
                                variant="contained"
                                size="small"
                                onClick={handleArchiveQuery}
                                loading={isLoading}
                            >
                                Retry
                            </LoadingButton>
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={handleClose}
                                autoFocus
                            >
                                Go to Queries page
                            </Button>
                        </DialogActions>
                    )} */}
            </Dialog>
            <TabContext value={tab}>
                <TabList
                    onChange={handleTabChange}
                    aria-label="lab API tabs example"
                    sx={{ paddingLeft: 4, paddingRight: 4}}
                >
                    <Tab label="Unanswered" value="unanswered" style={{textTransform:"none"}} />
                    <Tab label="Answered" value="answered" style={{textTransform:"none"}}/>
                    <Tab label="Archived" value="archived" style={{textTransform:"none"}}/>
                </TabList>
                <Box style={{ margin: "2%" }}>
                {/* <Grid container spacing={2}>
                    <Grid item xs={12} sm={9} md={9}></Grid>
                </Grid> */}
                    <Grid container spacing={2} direction="row" alignItems="center" sx={{paddingLeft: 1, paddingRight: 1,}}>
                        <Grid item xs={12} sm={12} md={2}>
                            <FormHelperText id="show_queries_from_filter">
                                Show queries from
                            </FormHelperText>
                            <Autocomplete
                                size="small"
                                disablePortal
                                id="show_queries_from_filter"
                                options={filters.duration}
                                // sx={classes.filter}
                                loading={isLoading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {isLoading ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    ) : null}
                                                    {
                                                        params.InputProps
                                                            .endAdornment
                                                    }
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                                getOptionLabel={(option) => option.value || ""}
                                value={selectedFilters.duration}
                                onChange={(e, value) => {
                                    setFilters(
                                        "duration",
                                        value === null ? "" : value
                                    );
                                }}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={2}>
                            <FormHelperText id="show_type_from_filter">
                                Content type
                            </FormHelperText>
                            <Autocomplete
                                limitTags={1}
                                multiple
                                size="small"
                                disablePortal
                                id="show_type_from_filter"
                                options={filters.type.map(({ value }) => value)}
                                // sx={classes.filter}
                                loading={isLoading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {isLoading ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    ) : null}
                                                    {
                                                        params.InputProps
                                                            .endAdornment
                                                    }
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                                getOptionLabel={(option) => option || ""}
                                value={selectedFilters.type}
                                onChange={(e, value) =>
                                    setFilters(
                                        "type",
                                        value === null ? [] : value
                                    )
                                }
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={12} md={2}>
                            <FormHelperText id="show_category_from_filter">
                                Query Category
                            </FormHelperText>
                            <Autocomplete
                                limitTags={1}
                                multiple
                                size="small"
                                disablePortal
                                id="show_category_from_filter"
                                options={filters.category.map(
                                    ({ category_name }) => category_name
                                )}
                                // sx={classes.filter}
                                loading={isLoading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {isLoading ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    ) : null}
                                                    {
                                                        params.InputProps
                                                            .endAdornment
                                                    }
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                                getOptionLabel={(option) => option || ""}
                                value={selectedFilters.category}
                                onChange={(e, value) =>
                                    setFilters(
                                        "category",
                                        value === null ? [] : value
                                    )
                                }
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={2}>
                            <FormHelperText id="show_visibility_from_filter">
                                Query visibility
                            </FormHelperText>
                            <Autocomplete
                                limitTags={1}
                                multiple
                                size="small"
                                disablePortal
                                id="show_visibility_from_filter"
                                options={filters.Visibility.map(
                                    ({ value }) => value
                                )}
                                // sx={classes.filter}
                                loading={isLoading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {isLoading ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    ) : null}
                                                    {
                                                        params.InputProps
                                                            .endAdornment
                                                    }
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                                getOptionLabel={(option) => option || ""}
                                value={selectedFilters.Visibility}
                                onChange={(e, value) =>
                                    setFilters(
                                        "Visibility",
                                        value === null ? [] : value
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} md={2}>
                            <LoadingButton
                                variant="contained"
                                sx={classes.filterButton}
                                onClick={() => handleApplyFiltersClick(false)}
                                style={{ textTransform: "none" }}
                                endIcon={<FilterAlt />}
                                loading={isLoading}
                                loadingPosition="end"
                            >
                                Apply
                            </LoadingButton>
                        </Grid>

                        <Grid item xs={12} sm={12} md={2}>
                            <Tooltip title="Clear Filters" arrow>
                                <IconButton
                                    color="primary"
                                    aria-label="clear filter"
                                    component="span"
                                    sx={classes.clearAllButton}
                                    onClick={handleClearFilter}
                                >
                                    <ClearAll />
                                </IconButton>
                            </Tooltip>
                        </Grid>

                        <Grid item xs={12} sm={12} md={3}>
                            {/* <FormHelperText id="show_search_from_filter">
                                Search
                            </FormHelperText> */}
                            <OutlinedInput
                                id="search_textfield"
                                type="text"
                                size="small"
                                variant="outlined"
                                className="search_field"
                                placeholder="Search queries..."
                                // sx={classes.search}
                                value={selectedFilters.search}
                                onChange={(e) =>
                                    setFilters("search", e.target.value)
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="search button"
                                            onClick={handleApplyFiltersClick}
                                            edge="end"
                                        >
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ paddingLeft: 4, paddingRight: 4}}>
                    {checkArray.length > 0 && <div className="selection-div">
                        <div className="left-side-content">
                            Selected queries - {checkArray.length}
                        </div>
                        <div className="right-side-content">
                            <Button onClick={() => clearSelection()} sx={{ textTransform: "none" }}>Clear selection</Button>
                            {/* <div className="selection-div-element" onClick={() => clearSelection()}>clear selection</div> */}
                            {tab != "archived" && <Button className="selection-div-element" onClick={() => updateQueryDetails()} sx={{ textTransform: "none" }}>Add to archive</Button>}
                            {tab == "archived" && <Button className="selection-div-element" onClick={() => updateQueryDetails()} sx={{ textTransform: "none" }}>Delete</Button>}
                        </div>
                    </div>}
                    <div className="query-card-wrapper">
                        { isLoading ?  (<SpinnerLG />) : 
                        (results !== undefined && results.length) ? results.map((query, index) => {
                            return (
                                // <QueryCard
                                //     key={index}
                                //     query={query}
                                //     tab={tab}
                                //     uniqKey={query['query_id']}
                                //     checkboxFunc={handleCheck}
                                //     currCheckBoxState={checkArray.includes(query['query_id'])}
                                //     currentPageNumber={pageNumber}
                                // />

                                <QueryCardNew
                                    key={index}
                                    query={query}
                                    tab={tab}
                                    uniqKey={query['query_id']}
                                    checkboxFunc={handleCheck}
                                    currCheckBoxState={checkArray.includes(query['query_id'])}
                                    currentPageNumber={pageNumber}
                                />
                            )
                        }) : <p style={classes.noCourseDisclaimer}>No queries found !</p>}

                        {
                        !isLoading && 
                        <Grid container sx={{ paddingBottom: 9 }} spacing={0} justifyContent="center" alignItems="center" >
                        {results !== undefined && results.length > 0 && (queryDetails.count && Math.ceil(Number(queryDetails.count) / 10) > 1) &&
                            <Grid item xs={12}>
                                <Pagination
                                    count={queryDetails.count ? Math.ceil(Number(queryDetails.count) / 10) : 0}
                                    page={pageNumber}
                                    onChange={handlePageChange}
                                    sx={classes.pagination}
                                    color="primary"
                                    hidePrevButton
                                    hideNextButton
                                />
                            </Grid>}
                         </Grid>
                        }
                    </div>
                </Box>
                {/* <TabPanel value="1" style={{ paddingLeft: 0, paddingRight: 0 }}>
                    
                </TabPanel> */}
                {/* <TabPanel value="2">Item Two</TabPanel>
                <TabPanel value="3">Item Three</TabPanel> */}
            </TabContext>
        </Box>
    );
}

const mapStateToProps = (state) => ({
    queriesAdmin: state.QueriesAdminReducer,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAdminQueriesDropdowns: () => dispatch(fetchAdminQueriesDropdowns()),
    selectFilter: (filterType, filterValue) => dispatch(selectFilter(filterType, filterValue)),
    fetchAdminQueryDetails: (filterDetails, currentPage) => dispatch(fetchAdminQueryDetails(filterDetails, currentPage)),
    updateAdminQueryArchive: (request, currFilterObject) => dispatch(updateAdminQueryArchive(request, currFilterObject)),
    deleteArchivedQueryAdmin: (request, currFilterObject) => dispatch(deleteArchivedQueryAdmin(request, currFilterObject)),
    setCheckboxes: (checkboxObj) => dispatch(setCheckboxes(checkboxObj))
});

export default connect(mapStateToProps, mapDispatchToProps)(Queries);
