export const classes = {
  card: {
    width: "100%",
    background: "#FAFAFA",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: '12px',
    cursor: "pointer",
  },
  cardContent: {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "bold",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: "#0000A0",
    margin: "12px 0 18px"
  },
  cardText: {
    fontSize: "14px",
    lineHeight: "18px",
    color: "#000000",
    opacity: "0.6",
    margin: "0 0 8px"
  },
  iconButton: { float: "right", marginRight: "2%", marginTop: "2%" },
  dialogContent: {
    fontSize: "125%",
    color: "black",
    fontWeight: "normal",
    marginTop: "5%",
    paddingBottom: "1%",
  },
  dialogActions: {
    margin: "3%",
  },
  rating: {
    fontSize: '18px',
    color: "#6ad7b9"
  },
};
