import {
    FETCH_ARTICLE_LIST, SET_ARTICLE_LIST, FETCH_ARTICLE_USER_DATA, SET_ARTICLE_USER_DATA, CLEAR_ARTICLE_USER_DATA,
    SET_BOOKMARK_LC, SET_RATING_LC, SET_AVG_RATING_LC, BOOKMARK_UPDATE_LC
} from "../ActionTypes/learningContentActionTypes"

import { SET_BOOKMARK_ADDED_ALERT } from "../ActionTypes/alertsActionTypes"
import axios from "axios"


export const rateNBookmarkLC = (flag, value, id, contentList) => async dispatch => {

    //flag values are "rating" and "bookmark"  

    try {
        let reqObj = {
            "type": null,
            "learning_content_id": `${id}`,
            "rating": 2,
            "bookmark_flag": "N"
        };

        if (flag === "rating") {

            reqObj.type = "rating"
            reqObj.rating = value
        }
        else {
            reqObj.type = "bookmark_flag"
            reqObj.bookmark_flag = value
        }

        // dispatch({ type: FETCH_ARTICLE_USER_DATA })
        let url = process.env.REACT_APP_API_URL + "/learningcontent/vet/ratebookmark_content/"



        let result = await axios.post(url, reqObj)

        if (result.data && result.data.bookmark_status) {

            if (contentList && contentList.length > 0) {
                let updatedCourseList = contentList.map(item => {
                    if (item.learning_content_id === id) {
                        item.bookmark = value
                        return item
                    }
                    return item
                })

                dispatch({ type: BOOKMARK_UPDATE_LC, payload: updatedCourseList })
            }



            if (result.data.bookmark_status.bookmark_flag == "Y") {
                dispatch({ type: SET_BOOKMARK_ADDED_ALERT })
                dispatch({ type: SET_BOOKMARK_LC, payload: { isBookmarked: true } })

            }
            else if (result.data.bookmark_status.bookmark_flag == "N") {
                dispatch({ type: SET_BOOKMARK_LC, payload: { isBookmarked: false } })

            }


        }
        else if (result.data && result.data.rating_status) {

            dispatch({ type: SET_RATING_LC, payload: { rating: result.data.rating_status.rating } })
            dispatch({ type: SET_AVG_RATING_LC, payload: { avgRating: result.data.article_info.avg_rating } })



        }

        //dispatch(loadUser())

    }
    catch (err) {
        // dispatch({ type: CLEAR_ARTICLE_USER_DATA })
        
    }
}

