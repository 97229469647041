export const SET_LOGIN_FAILURE_ALERT = "SET_LOGIN_FAILURE_ALERT"
export const CLEAR_LOGIN_FAILURE_ALERT = "CLEAR_LOGIN_FAILURE_ALERT"
export const SET_SIGNUP_EXISTS_ALERT = "SET_SIGNUP_EXISTS_ALERT"
export const CLEAR_SIGNUP_EXISTS_ALERT = "CLEAR_SIGNUP_EXISTS_ALERT"
export const SET_FORGOT_PASS_ALERT = "SET_FORGOT_PASS_ALERT"
export const CLEAR_FORGOT_PASS_ALERT = "CLEAR_FORGOT_PASS_ALERT"
export const SET_QUERY_ADDED_ALERT = "SET_QUERY_ADDED_ALERT"
export const CLEAR_QUERY_ADDED_ALERT = "CLEAR_QUERY_ADDED_ALERT"
export const SET_BOOKMARK_ADDED_ALERT = "SET_BOOKMARK_ADDED_ALERT"
export const CLEAR_BOOKMARK_ADDED_ALERT = "CLEAR_BOOKMARK_ADDED_ALERT"
export const TOGGLE_PROCESS_LOADER = "TOGGLE_PROCESS_LOADER"
export const TOGGLE_GLOBAL_ALERT = "TOGGLE_GLOBAL_ALERT"



export const SET_GENERIC_ERROR_ALERT = "SET_GENERIC_ERROR_ALERT"
export const CLEAR_GENERIC_ERROR_ALERT = "CLEAR_GENERIC_ERROR_ALERT"