import {
  CHANGE_PAGE,
  EDIT_FILE_FAILURE,
  EDIT_FILE_REQUEST,
  EDIT_FILE_SUCCESS,
  FETCH_FILTER_VALUES_FAILURE,
  FETCH_FILTER_VALUES_REQUEST,
  FETCH_FILTER_VALUES_SUCCESS,
  FETCH_UPLOADED_FILES_FAILURE,
  FETCH_UPLOADED_FILES_REQUEST,
  FETCH_UPLOADED_FILES_SUCCESS,
  FILE_UPLOAD_FAILURE,
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_SUCCESS,
  SELECT_ARTICLE,
  SELECT_FILE,
  SELECT_FILTER,
  TOGGLE_ALERT,
} from "../ActionTypes/learningContentAdminActionTypes";

const initialState = {
  isLoading: false,
  error: "",
  selectedFile: null,
  filters: {},
  selectedFilters: {
    file_category: [],
    file_content_type: [],
    content_country: "",
    author: [],
    file_species: [],
    show_content_from: { value: "All time" },
    search: "",
  },
  learningContent: {},
  pageNumber: 1,
  articleSelected: {},
  isAlertOpen: false,
  alertType: "success",
  alertMessage: "",
};

const LearningContentAdminReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FILE_UPLOAD_REQUEST:
    case FETCH_FILTER_VALUES_REQUEST:
    case FETCH_UPLOADED_FILES_REQUEST:
    case EDIT_FILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };

    case FILE_UPLOAD_SUCCESS:
    case EDIT_FILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };

    case FILE_UPLOAD_FAILURE:
    case EDIT_FILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case SELECT_FILE:
      return {
        ...state,
        selectedFile: payload,
      };

    case FETCH_FILTER_VALUES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        filters: payload,
        error: "",
      };

    case FETCH_FILTER_VALUES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        filters: {},
      };

    case SELECT_FILTER:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [payload.type]: payload.value,
        },
      };

    case FETCH_UPLOADED_FILES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
        learningContent: payload,
        isFailure: false,
      };

    case FETCH_UPLOADED_FILES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        learningContent: "",
        isFailure: true,
      };

    case CHANGE_PAGE:
      return {
        ...state,
        pageNumber: payload,
      };

    case SELECT_ARTICLE:
      return {
        ...state,
        articleSelected: payload,
      };

    case TOGGLE_ALERT:
      return {
        ...state,
        isAlertOpen: payload.value,
        alertType: payload.type,
        alertMessage: payload.message,
      };

    default:
      return state;
  }
};

export default LearningContentAdminReducer;
