import {
    CALL_COMPLETED,
    CATCH_ERROR,
    START_CALL,
} from "../ActionTypes/homepageVetActionTypes";

const createAction = (type, payload = null) => ({ type, payload });

export const startCall = () => createAction(START_CALL);
export const callCompleted = (payload) => createAction(CALL_COMPLETED, payload);
export const catchError = (error) => createAction(CATCH_ERROR, { error });
