import {
  CHANGE_COURSE_STATUS_FAILURE,
  CHANGE_COURSE_STATUS_REQUEST,
  CHANGE_COURSE_STATUS_SUCCESS,
  CHANGE_PAGE,
  DELETE_COURSE_FAILURE,
  DELETE_COURSE_RESET,
  DELETE_COURSE_SUCCESS,
  EDIT_COURSE_FAILURE,
  EDIT_COURSE_REQUEST,
  EDIT_COURSE_SUCCESS,
  FETCH_COURSES_FAILURE,
  FETCH_COURSES_REQUEST,
  FETCH_COURSES_SUCCESS,
  FETCH_FILTER_VALUES_FAILURE,
  FETCH_FILTER_VALUES_REQUEST,
  FETCH_FILTER_VALUES_SUCCESS,
  SELECT_FILTER,
  CHANGE_COURSE_DETAILS,
  TOGGLE_ALERT,
  GET_COURSE_DETAILS_FOR_EDIT,
  CREATE_COURSE_REQUEST,
  CREATE_COURSE_SUCCESS,
  CREATE_COURSE_FAILURE,
  SET_ACTIVE_COURSE,
  CREATE_EDIT_CHAPTER_REQUEST,
  CREATE_EDIT_CHAPTER_SUCCESS,
  CREATE_EDIT_CHAPTER_FAILURE,
  SET_CURRICULUM_DETAILS,
  CREATE_EDIT_LESSON_REQUEST,
  CREATE_EDIT_LESSON_SUCCESS,
  CREATE_EDIT_LESSON_FAILURE,
  CREATE_QUIZ_REQUEST,
  CREATE_QUIZ_SUCCESS,
  CREATE_QUIZ_FAILURE,
  SET_PREVIEW_DATA
} from "../ActionTypes/coursesAdminActionTypes";

export const fetchFilterValuesRequest = () => ({
  type: FETCH_FILTER_VALUES_REQUEST,
});

export const fetchFilterValuesSuccess = (filterValues) => ({
  type: FETCH_FILTER_VALUES_SUCCESS,
  payload: filterValues,
});

export const fetchFilterValuesFailure = (error) => ({
  type: FETCH_FILTER_VALUES_FAILURE,
  payload: error,
});

export const selectFilter = (filterType, filterValue) => ({
  type: SELECT_FILTER,
  payload: {
    type: filterType,
    value: filterValue,
  },
});

export const changeCourseDetails = (
  type,
  value,
  isData,
  isSpeaker,
  speakerActionType,
  speakerNumber
) => ({
  type: CHANGE_COURSE_DETAILS,
  payload: {
    type: type,
    value: value,
    isData: isData,
    isSpeaker: isSpeaker,
    speakerActionType: speakerActionType,
    speakerNumber: speakerNumber,
  },
});

export const fetchCoursesRequest = () => ({
  type: FETCH_COURSES_REQUEST,
});

export const fetchCoursesSuccess = (courses) => ({
  type: FETCH_COURSES_SUCCESS,
  payload: courses,
});

export const fetchCoursesFailure = (error) => ({
  type: FETCH_COURSES_FAILURE,
  payload: error,
});

export const changePage = (pageNumber) => ({
  type: CHANGE_PAGE,
  payload: pageNumber,
});

export const editCourseRequest = () => ({
  type: EDIT_COURSE_REQUEST,
});

export const editCourseSuccess = () => ({
  type: EDIT_COURSE_SUCCESS,
});

export const editCourseFailure = (error) => ({
  type: EDIT_COURSE_FAILURE,
  payload: error,
});

export const deleteCourseReset = () => ({
  type: DELETE_COURSE_RESET,
});

export const deleteCourseSuccess = () => ({
  type: DELETE_COURSE_SUCCESS,
  payload: true,
});

export const deleteCourseFailure = (error) => ({
  type: DELETE_COURSE_FAILURE,
  payload: error,
});

export const changeCourseStatusRequest = () => ({
  type: CHANGE_COURSE_STATUS_REQUEST,
});

export const changeCourseStatusSuccess = () => ({
  type: CHANGE_COURSE_STATUS_SUCCESS,
});

export const changeCourseStatusFailure = (error) => ({
  type: CHANGE_COURSE_STATUS_FAILURE,
  payload: error,
});

export const toggleAlert = (type, message, value) => ({
  type: TOGGLE_ALERT,
  payload: { type, message, value },
});

export const createCourseRequest = () => ({
  type: CREATE_COURSE_REQUEST,
});

export const createCourseSuccess = () => ({
  type: CREATE_COURSE_SUCCESS,
});

export const createCourseFailure = (error) => ({
  type: CREATE_COURSE_FAILURE,
  payload: error,
});

export const getCourseDetailsForEdit = (courseDetails) => ({
  type: GET_COURSE_DETAILS_FOR_EDIT,
  payload: courseDetails,
});

export const setActiveCourse = (courseId) => ({
  type: SET_ACTIVE_COURSE,
  payload: courseId,
});

export const createEditChapterRequest = () => ({
  type: CREATE_EDIT_CHAPTER_REQUEST,
});

export const createEditChapterSuccess = () => ({
  type: CREATE_EDIT_CHAPTER_SUCCESS,
});

export const createEditChapterFailure = (error) => ({
  type: CREATE_EDIT_CHAPTER_FAILURE,
  payload: error,
});

export const createEditLessonRequest = () => ({
  type: CREATE_EDIT_LESSON_REQUEST,
});

export const createEditLessonSuccess = () => ({
  type: CREATE_EDIT_LESSON_SUCCESS,
});

export const createEditLessonFailure = (error) => ({
  type: CREATE_EDIT_LESSON_FAILURE,
  payload: error,
});

export const setCurriculumDetails = (curriculumDetails) => ({
  type: SET_CURRICULUM_DETAILS,
  payload: curriculumDetails,
});
export const setPreviewDetails = (previewDetails) => ({
  type: SET_PREVIEW_DATA,
  payload: previewDetails,
});

export const createQuizRequest = () => ({
  type: CREATE_QUIZ_REQUEST,
});

export const createQuizSuccess = () => ({
  type: CREATE_QUIZ_SUCCESS,
});

export const createQuizFailure = (error) => ({
  type: CREATE_QUIZ_FAILURE,
  payload: error,
});
