import axios from "axios";
import {
    callCompleted,
    catchError,
    startCall,
} from "../ActionCreators/homepageVetActionCreators";

const baseUrl = process.env.REACT_APP_API_URL;

export const fetchHomepageVetLogout = () => (dispatch) => {
    dispatch(startCall());
    axios
        .get(`${baseUrl}/vetQueries/logout/homepage/`)
        .then((response) => dispatch(callCompleted({
            logout: response.data,
            login: {
                course_details: { results: [] },
                webinar_details: { results: [] },
                learning_content_details: { results: [] },
                popular_courses: { results: [] },
            },
        }
        )))
        .catch((error) => {
            error.clientMessage = "Failed to fetch vet homepage logout";
            dispatch(catchError(error));
        });
};

export const fetchHomepageVetLogin = () => (dispatch) => {
    dispatch(startCall());
    axios
        .get(`${baseUrl}/vetQueries/login/homepage/`)
        .then((response) => dispatch(callCompleted({ login: response.data })))
        .catch((error) => {
            error.clientMessage = "Failed to fetch vet homepage login";
            dispatch(catchError(error));
        });
};
