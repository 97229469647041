export const FETCH_FILTER_VALUES_FAILURE_WEBINAR_ADMIN =
  "FETCH_FILTER_VALUES_FAILURE_WEBINAR_ADMIN";
export const FETCH_FILTER_VALUES_REQUEST_WEBINAR_ADMIN =
  "FETCH_FILTER_VALUES_REQUEST_WEBINAR_ADMIN";
export const FETCH_FILTER_VALUES_SUCCESS_WEBINAR_ADMIN =
  "FETCH_FILTER_VALUES_SUCCESS_WEBINAR_ADMIN";
export const FETCH_DROPDOWN_VALUES_FAILURE_WEBINAR_ADMIN =
  "FETCH_DROPDOWN_VALUES_FAILURE_WEBINAR_ADMIN";
export const FETCH_DROPDOWN_VALUES_REQUEST_WEBINAR_ADMIN =
  "FETCH_DROPDOWN_VALUES_REQUEST_WEBINAR_ADMIN";
export const FETCH_DROPDOWN_VALUES_SUCCESS_WEBINAR_ADMIN =
  "FETCH_DROPDOWN_VALUES_SUCCESS_WEBINAR_ADMIN";
export const SELECT_FILTER_WEBINAR_ADMIN = "SELECT_FILTER_WEBINAR_ADMIN";
// export const FETCH_COURSES_FAILURE = "FETCH_COURSES_FAILURE";
// export const FETCH_COURSES_REQUEST = "FETCH_COURSES_REQUEST";
export const FETCH_ADMIN_WEBINARS_SUCCESS = "FETCH_ADMIN_WEBINARS_SUCCESS";
export const CHANGE_PAGE_WEBINAR_ADMIN = "CHANGE_PAGE_WEBINAR_ADMIN";
// export const EDIT_COURSE_FAILURE = "EDIT_COURSE_FAILURE";
// export const EDIT_COURSE_REQUEST = "EDIT_COURSE_REQUEST";
// export const EDIT_COURSE_SUCCESS = "EDIT_COURSE_SUCCESS";
// export const DELETE_COURSE_FAILURE = "DELETE_COURSE_FAILURE";
// export const DELETE_COURSE_REQUEST = "DELETE_COURSE_REQUEST";
// export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
// export const CHANGE_COURSE_STATUS_REQUEST = "CHANGE_COURSE_STATUS_REQUEST";
// export const CHANGE_COURSE_STATUS_SUCCESS = "CHANGE_COURSE_STATUS_SUCCESS";
// export const CHANGE_COURSE_STATUS_FAILURE = "CHANGE_COURSE_STATUS_FAILURE";
export const CLEAR_WEBINARS_DATA = "CLEAR_WEBINARS_DATA";
export const FETCH_WEBINARS_DATA = "FETCH_WEBINARS_DATA";
export const SET_WEBINARS_DATA = "SET_WEBINARS_DATA";
export const SET_WEBINARS_DATA_PAGINATION = "SET_WEBINARS_DATA_PAGINATION";
export const TOGGLE_ALERT = "TOGGLE_ALERT";
export const WEBINAR_NAVIGATION = "WEBINAR_NAVIGATION"
export const SET_CUSTOM_DATE = "SET_CUSTOM_DATE"
export const SET_WEB_EDIT = "SET_WEB_EDIT"
export const SET_WEBINARS_EDITING_DATA = "SET_WEBINARS_EDITING_DATA";
export const SET_CURRENT_WEBINAR = "SET_CURRENT_WEBINAR"
export const SET_UPLOAD_WEBINAR = "SET_UPLOAD_WEBINAR"
