import React, { useState, useEffect } from "react";
import { Route, Redirect, Switch, BrowserRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {Box,  CircularProgress, Grid, Card, CardContent, Paper, TextField, Select, MenuItem, Tabs, Tab, Typography, Pagination} from "@mui/material";
import {getLandingDetails, 
    setFilterValue, 
    getGraphData,
     setRegionWiseDropdownValue, 
     setTop5RatedDropdownValue,
     setNoficationsData,
     setNoficationsSeen } from "../../Redux/Middlewares/AdminLandingPage/adminLandingPageMiddleware";
import { setTabValue } from "../../Redux/Middlewares/UserManagement/getManagementFilters";
import "./style.css";
import SpinnerLG from "../../SpinnerLG";
import { format } from "date-fns";

const NotificationComponent = ({getLandingDetails,setNoficationsData,setNoficationsSeen,setFilterValue, getGraphData,landingData, 
    setRegionWiseDropdownValue, setTop5RatedDropdownValue, setTabValue}) => {
    const {loaderObj, notificationsData} = landingData;
    const {isContentLoading} = loaderObj;
    const {pending_users, unanswered_queries} = notificationsData;

    useEffect(() => {
        
    },[]);

    

    const [tab, setTab] = useState("queries"); //state for tab  
    const [pageNumber, setPageNumber]  = useState(1);

    const handleTabChange = (event, tabValue) => {
        setTab(tabValue);
        setPageNumber(1);
    }

    const handlePageChange = (event,value) => {
        setPageNumber(value);
        setNoficationsData(value);
    }

    //function for seen notifications and seeting user management tab
    const redirectToManagement = () => {
        setNoficationsSeen("Users")
        setTabValue(3)
    }

    const formatDate = (date) => format(new Date(date), "dd-MMM-yyyy");
    const formatTime = (date) => format(new Date(date), "hh:mm");

    const getNotficationData = () => {
        const queries_results = unanswered_queries['results'];
        const users_results = pending_users['results'];
        let cardElement = "";
        switch(tab){
            case "queries":
                cardElement = queries_results ? queries_results.map(e => {
                    return(
                        <Card  component={Link} to="/queries" className="card-div"
                        onClick={() => setNoficationsSeen("Queries")}>
                            <CardContent className="card-div">
                               <div className="card-text">{ e.query_text}</div>
                               <div className="card-time">
                                    {formatDate(e.created_date)}{" "}
                                    <span style={{color:"grey"}}>
                                        • {formatTime(e.created_date)}
                                    </span>
                                </div>
                            </CardContent>
                        </Card>
                    )
                }) : ""
                break;

            case "users":
                cardElement = users_results ? users_results.map(e => {
                    return(
                        <Card component={Link} to="/management" className="card-div"
                        onClick={() => redirectToManagement()}>
                            <CardContent className="card-div">
                               <div className="card-text">{ e.full_name} wants to join</div>
                               <div className="card-time">
                                    {formatDate(e.date_added)}{" "}
                                    <span style={{color:"grey"}}>
                                        • {formatTime(e.date_added)}
                                    </span>
                                </div>
                            </CardContent>
                        </Card>
                    )
                }) : ""
                break;

            default:
                break
        }

        return cardElement;
         
    }

    return(
        <>{(notificationsData && notificationsData!=undefined && notificationsData!=null) ? 
            <Grid container md={12} spacing={2}>
                <Grid item md={12} sm={12} sx={{}}>
                    <Tabs
                        value={tab}
                        onChange={handleTabChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 0, borderColor: 'divider'}}
                    >
                        <Tab label="Queries" value="queries" sx={{textTransform:"none"}} />
                        <Tab label="Users" value="users" sx={{textTransform:"none"}}/>
                    </Tabs>
                </Grid>
                <Grid item md={12} sm={12}>
                    {tab=="queries" && <div>
                   
                        {isContentLoading ? 
                        <Box sx={{ display: 'flex', justifyContent:"center" }}>
                            <CircularProgress />
                        </Box> :  
                          <div className="noticiation-content"
                          
                           >
                            {unanswered_queries!=undefined ?  getNotficationData() : <div>No data to be displayed</div>}
                        </div>}
                        <Pagination
                            size="small" 
                            className="pagintation-div"
                            count={unanswered_queries!=undefined ? Math.ceil(Number(unanswered_queries.count) / 10) : 0}
                            page={pageNumber}
                            onChange={handlePageChange}
                            color="primary"
                            hidePrevButton
                            hideNextButton
                            />
                    </div>}
                      
                    {tab=="users" && <div>{
                        isContentLoading ? 
                        <Box sx={{ display: 'flex', justifyContent:"center" }}>
                            <CircularProgress />
                        </Box> :  
                        <div className="noticiation-content">
                            {pending_users!=undefined ? getNotficationData() : <div>No data to be displayed</div>}
                        </div>}
                        <Pagination
                                size="small" 
                                className="pagintation-div"
                                count={pending_users ? Math.ceil(Number(pending_users.count) / 10) : 0}
                                page={pageNumber}
                                onChange={handlePageChange}
                                color="primary"
                                hidePrevButton
                                hideNextButton
                            />
                    </div>}
                    
                </Grid>
            </Grid> : <div>No Data to display</div>}
        </>
    )
}

const mapStateToProps = (state) => ({
    landingData: state.adminLandingPageReducer
})

const mapDispatchToProps = (dispatch) => ({
    setNoficationsData: (currPage) => dispatch(setNoficationsData(currPage)),
    setNoficationsSeen: (notificationType) => dispatch(setNoficationsSeen(notificationType)),
    setTabValue: (currTab) => dispatch(setTabValue(currTab))
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationComponent);