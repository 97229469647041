import React, { useState, useEffect } from "react";

import {
    Button, IconButton, Box, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Grid, Paper, Typography, Pagination, Menu, MenuItem, TextField
} from "@mui/material";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from '@mui/material/InputAdornment';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: '60px',
}));

const lightTheme = createTheme({ palette: { mode: 'light' } });

const CategoriesLayout = ({ categoriesData, isLoading, total, current, categoriesObj, getCategories,
    editCategory, createCategory, deleteCategory, searchText, setSearchText }) => {

    const [selected, setSelected] = useState(null)
    const [openHomeDialog, setOpenHomeDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [action, setAction] = useState("create");  //create or edit
    const [categoryText, setCategoryText] = useState("");

    const openMenu = Boolean(anchorEl);

    //getting data on component mount
    useEffect(() => {

        getCategories(categoriesObj)

    }, [])

    const handlePagination = (e, v) => {

        getCategories(categoriesObj, v)

    }
//to select menu options from kebab menu
    const handleMenuClick = (e, val) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
        setSelected(val)
        setCategoryText(val.category_name)
    };
    const handleCloseMenu = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    let createCategoryObj = {
        "category_name": categoryText
    }

    let editCategoryObj = {
        "category_id": selected?.category_id,
        "updated_category": categoryText
    }


    return (<>
        <Grid container md={12}>
            <Grid container justifyContent="space-between" md={12} sx={{ marginBottom: 2, marginRight: 4 }}>

                <TextField
                    placeholder="search"
                    id="outlined-start-adornment"
                    size="small"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    InputProps={{
                        endAdornment: <InputAdornment position="start"><IconButton onClick={() => {
                            getCategories(categoriesObj);
                        }}>
                            <SearchIcon />
                        </IconButton>
                        </InputAdornment>,
                    }}
                />

                <Button onClick={() => { setAction("create"); setOpenHomeDialog(true); setCategoryText(""); }}
                    sx={{ borderRadius: 2 }} style={{ textTransform: "none" }} variant="contained">
                    New Category </Button>
            </Grid>
            {isLoading ? <>  <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '50vh' }}
            >

                <Grid item xs={3}>
                    <CircularProgress size={100} />
                </Grid>

            </Grid></>
                : categoriesData.length < 1 ? <>No data</>
                    : <>
                        {categoriesData && categoriesData.length > 0 && categoriesData.map(item => {
                            return (
                                <Grid key={item.category_id} container md={3} sx={{ marginBottom: 4 }}>
                                    <Paper elevation={2} sx={{ width: "90%", height: 60, backgroundColor: "#FAFAFA", borderRadius: "12px" }}>


                                        <Grid container justifyContent="space-between" sx={{ paddingLeft: 2, paddingTop: 1 }} alignItems="center" md={12}>
                                            <Typography>{item.category_name}</Typography>
                                            <IconButton onClick={(e) => handleMenuClick(e, item)}>
                                                <MoreVertIcon />
                                            </IconButton>
                                        </Grid>





                                    </Paper>
                                </Grid>
                            )
                        })}

                        {total !== 0 && Math.ceil(Number(total) / 10) > 1 && <Grid item md={12}>
                            <Pagination
                                size="small"
                                count={Math.ceil(Number(total) / 10)}
                                page={current}
                                onChange={handlePagination}
                                sx={{ marginTop: 1, justifyContent: "center", display: "flex" }}
                                color="primary"
                                hidePrevButton
                                hideNextButton
                            />
                        </Grid>}
                    </>}
        </Grid>


        <Dialog
            open={openHomeDialog}
            // onClose={() => setOpenHomeDialog(false)}
            maxWidth={"lg"}
        >
            <DialogTitle
                style={{
                    padding: "10px 15px",
                    justifyContent: "flex-end",
                    display: "flex",
                    backgroundColor: "#CCCCFF",
                }}
            >
                <CloseIcon
                    fontSize="small"
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpenHomeDialog(false)}
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert_dialog_description"
                    style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                    {action === "create" ? "Create new category" : "Edit selected category"}

                </DialogContentText>
                <TextField value={categoryText} onChange={e => setCategoryText(e.target.value)} />
            </DialogContent>
            <DialogActions>
                {
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOpenHomeDialog(false);
                            if (action === "edit") {
                                editCategory(editCategoryObj, categoriesObj)
                            }
                            else {
                                createCategory(createCategoryObj, categoriesObj)
                            }

                        }}
                        style={{ textTransform: "none", height: 30 }}
                    >
                        Yes
                    </Button>
                }
                <Button
                    variant="outlined"
                    style={{ textTransform: "none", height: 30 }}
                    onClick={() => setOpenHomeDialog(false)}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>



        <Dialog
            open={openDeleteDialog}
            // onClose={() => setOpenHomeDialog(false)}
            maxWidth={"lg"}
        >
            <DialogTitle
                style={{
                    padding: "10px 15px",
                    justifyContent: "flex-end",
                    display: "flex",
                    backgroundColor: "#CCCCFF",
                }}
            >
                <CloseIcon
                    fontSize="small"
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpenDeleteDialog(false)}
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert_dialog_description"
                    style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                    {`Do you want to delete the category ${categoryText} ?`}

                </DialogContentText>

            </DialogContent>
            <DialogActions>
                {
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOpenDeleteDialog(false);
                            deleteCategory(selected?.category_id, categoriesObj)

                        }}
                        style={{ textTransform: "none", height: 30 }}
                    >
                        Yes
                    </Button>
                }
                <Button
                    variant="outlined"
                    style={{ textTransform: "none", height: 30 }}
                    onClick={() => setOpenDeleteDialog(false)}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>


        <Menu
            id={`basic_menu`}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            {/* <MenuItem onClick={handleEditClick}>Edit</MenuItem> */}
            <MenuItem onClick={(e) => { handleCloseMenu(e); setAction("edit"); setOpenHomeDialog(true); }} >Edit</MenuItem>
            <MenuItem onClick={(e) => { handleCloseMenu(e); setOpenDeleteDialog(true) }} >Delete</MenuItem>



        </Menu>
    </>)
}

export default CategoriesLayout