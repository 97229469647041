import { FETCH_COURSES_DATA, SET_COURSES_DATA, SET_COURSES_USER_DATA, CLEAR_COURSES_DATA, SET_COURSES_FAILURE_DATA,
    SET_COURSES_FILTER_DATA, SET_USER_COURSE_FILTER_DATA, CLEAR_USER_COURSE_FILTER_DATA,
    SET_COURSES_DATA_PAGINATION} from "../../ActionTypes/coursesVetActionTypes"

import { SET_QUERY_ADDED_ALERT,CLEAR_QUERY_ADDED_ALERT,SET_GENERIC_ERROR_ALERT } from "../../ActionTypes/alertsActionTypes"

import axios from "axios"

//Login 
export const getVetCourses = (reqObj,page=1) => async dispatch => {


    try {
        dispatch({ type: CLEAR_COURSES_DATA })
        dispatch({ type: FETCH_COURSES_DATA })

        let url = process.env.REACT_APP_API_URL+`/vetcourses/explore_course_details/?page=${page}`
        



        let result = await axios.post(url, reqObj)

        if (result.data) {
            dispatch({ type: SET_COURSES_DATA, payload: result.data.results })
            dispatch({ type: SET_COURSES_DATA_PAGINATION, payload: {totalCount:result.data.count,
                prev:result.data.previous?page-1:null,
                next:result.data.next?page+1:null,
                current:page
            
            } })


          


        }
        else {
            dispatch({ type: CLEAR_COURSES_DATA })
            dispatch({ type: SET_GENERIC_ERROR_ALERT })
        }

        

    }
    catch (err) {
        dispatch({ type: CLEAR_COURSES_DATA })
        dispatch({ type: SET_GENERIC_ERROR_ALERT })
    }
}

