import React, { useState, useEffect } from 'react';
import { InputLabel, TextField, Select, MenuItem, Button, Grid, Box, Typography } from '@mui/material';
import { useFormik } from "formik"
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { Link as MUIlink } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

//fields validation schema
const validationSchema = yup.object({

    otp: yup.string("").min(4, 'OTP should be of minimum 4 characters length')
        .required('OTP is required'),

    password: yup
        .string('Enter your new password')
        .min(9, 'Password should be of minimum 9 characters length')
        .required('Password is required')
        .oneOf([yup.ref('changepassword'), null], 'Passwords must match'),
    changepassword: yup
        .string('Enter your new password')
        .min(9, 'Password should be of minimum 9 characters length')
        .required('Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{9,}$/,
            "Password must include atleast one uppercase, one lowercase, one numeric and one special character [@$!%*#?&]"
          ),





});

const NewPassEntryForm = ({ otpAndPassword, authIdOTP, emailMain, forgotPassOTPSend,processLoader }) => {

    const formik = useFormik({
        initialValues: {
            changepassword: '',
            password: '',
            otp: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
           
            otpAndPassword(values.otp, values.password, authIdOTP,emailMain)
        },
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>

                        <InputLabel><Typography sx={{ marginTop: 4 }} variant="h6"> Enter OTP sent to </Typography></InputLabel>
                        <Typography sx={{ marginBottom: 1 }} variant="h6"> {emailMain}</Typography>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                inputProps={{ maxLength: 10 }}
                                id="otp"
                                name="otp"
                                type="otp"
                                value={formik.values.otp}
                                placeholder
                                onChange={formik.handleChange}
                                error={formik.touched.otp && Boolean(formik.errors.otp)}
                                helperText={formik.touched.otp && formik.errors.otp}
                            />
                        </Grid>
                       
                    </Grid>
                    <Grid container item xs={12} sm={12}>
                        <Grid item xs={5} sm={5}>
                            <Typography sx={{ marginTop: {xs:0.4,sm:0.2},padding:0 }} variant="h6"> Didn't get OTP?</Typography>
                        </Grid>

                        <Grid item xs="auto" sm="auto" >
                            <Button onClick={() => forgotPassOTPSend(emailMain)} size="small">Resend OTP</Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ marginTop: 2, marginBottom: 2, fontWeight: 600, color: "#434343" }} variant="h3" align="left">
                            Please Enter your new password
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel><Typography variant="h6"> Create password</Typography></InputLabel>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                inputProps={{ maxLength: 30 }}
                                id="changepassword"
                                name="changepassword"
                                type="password"
                                value={formik.values.changepassword}
                                placeholder
                                onChange={formik.handleChange}
                                error={formik.touched.changepassword && Boolean(formik.errors.changepassword)}
                                helperText={formik.touched.changepassword && formik.errors.changepassword}
                            />
                        </Grid>
                    </Grid>


                    <Grid item xs={12} sm={12}>
                        <InputLabel><Typography variant="h6"> Confirm password</Typography></InputLabel>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                inputProps={{ maxLength: 30 }}
                                id="password"
                                name="password"
                                type="password"
                                value={formik.values.confirmedPassword}
                                placeholder
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <LoadingButton  style={{textTransform: 'none'}} loading={processLoader} loadingPosition="center" sx={{ marginBottom: 5 }} color="primary" variant="contained" fullWidth type="submit">
                            Submit
                        </LoadingButton>
                    </Grid>

                </Grid>


            </form>
        </>
    )
}

export default NewPassEntryForm