

import { SET_QUERY_ADDED_ALERT, SET_BOOKMARK_ADDED_ALERT, SET_GENERIC_ERROR_ALERT } from "../../ActionTypes/alertsActionTypes"
import { BOOKMARK_UPDATE_COURSES,RATING_UPDATE_COURSES} from "../../ActionTypes/coursesVetActionTypes"
import axios from "axios"

//Login 
export const rateCourse = (moduleId, val,) => async dispatch => {


    try {
        let reqObj = {
            "course_id": moduleId,
            "course_rating": val
            }

        // dispatch({ type: FETCH_ARTICLE_USER_DATA })
        let url = process.env.REACT_APP_API_URL + "/vetcourses/addrating/"



        let result = await axios.post(url, reqObj)

        if (result.data) {
            dispatch({type:RATING_UPDATE_COURSES,payload:val})


        }
        else {
            dispatch({ type: SET_GENERIC_ERROR_ALERT })
        }



    }
    catch (err) {
        dispatch({ type: SET_GENERIC_ERROR_ALERT })
    }
}

