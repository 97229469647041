import React, { useEffect } from "react";
import { WebinarVetCard } from "../Utils/WebinarCard";
import { Box, Chip, Grid, IconButton, InputAdornment, Pagination, TextField } from "@mui/material";
import { connect } from "react-redux";
import { changePage } from "../../Redux/ActionCreators/webinarsAdminActionCreators";
import { getWebinars } from "../../Redux/Middlewares/WebinarsVet/getWebinarsMiddleware";
import CircularProgress from '@mui/material/CircularProgress';
import { setVetCurrentWebinar } from "../../Redux/ActionCreators/webinarsVetActionCreators";
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlined from "@mui/icons-material/FilterAltOutlined";

const PastWebinar = ({ current, getWebinars, webinarList, changePage, selectedFilters, isLoading, setVetCurrentWebinar,
  searchText, totalCount,
  setSearchText, country, full_name, handleClickOpen }) => {
  const handlePageChange = (event, value) => {
    // changePage(value);
    getWebinars(requestObj, value);
  };

  const [currentSelection, setCurrentSelection] = React.useState(null);

  const handleApplyFiltersClick = () => {
    getWebinars(requestObj);
  };

  let requestObj = {
    "webinar_speaker": selectedFilters && selectedFilters.creator && selectedFilters.creator.length > 0 ? selectedFilters.creator.map(item => item.speaker_name) : [],
    "webinar_category": selectedFilters && selectedFilters.category && selectedFilters.category.length > 0 ? selectedFilters.category.map(item => item.category_name) : [],
    "webinar_species": selectedFilters && selectedFilters.species && selectedFilters.species.length > 0 ? selectedFilters.species.map(item => item.value) : [],
    "webinar_language": [],
    "search_value": searchText ? [searchText] : [],
    "webinar_type": "past",
    "show_content_from": {
      "fixed": selectedFilters && selectedFilters.show_content_from.value ? selectedFilters.show_content_from.value : "All time",
      "start_date": null,
      "end_date": null
    }
  }

//getting the webinar data on component load
  useEffect(() => {
    requestObj.search_value = ""
    getWebinars(requestObj);
    return () => {
      setSearchText("")
    }
  }, []);

  if (isLoading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '50vh' }}
      >

        <Grid item xs={3}>
          <CircularProgress size={100} />
        </Grid>

      </Grid>
    )


  }


  return (
    <>
      <Grid container xs={12} sx={{ marginBottom: 4, display: { md: "none", xs: "flex" } }}>
        <Grid container xs={8}>
          <TextField
            sx={{ marginTop: 0, }}
            fullWidth
            id="input-with-icon-textfield"
            value={searchText}
            placeholder="Search webinars,authors..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon
                    onClick={() => handleApplyFiltersClick()}
                  />
                </InputAdornment>
              ),
            }}
            variant="standard"
            size="large"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Grid>
        <Grid container justifyContent="end" xs={4} sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton onClick={handleClickOpen}><FilterAltOutlined />  <Chip label={selectedFilters.category.length + selectedFilters.creator.length + selectedFilters.species.length} size="small" /></IconButton>
        </Grid>
      </Grid>

      <Grid container xs={12}>
        {webinarList &&
          webinarList.length > 0 ?
          webinarList.map((element, index) => (
            <Grid item xs={12} md={3}>
              <WebinarVetCard
                webinar={element}
                index={index}
                showButton="View"
                setVetCurrentWebinar={setVetCurrentWebinar}
                country={country}
                full_name={full_name}
              />
            </Grid>
          )) : <div style={{marginLeft: 20}}>No past webinars found!</div>
        }
      </Grid>

      <Box sx={{ marginTop: 2, marginBottom: 6 }}>{webinarList && webinarList.length > 0 && (
        (Math.ceil(Number(totalCount) / 10) > 1 && <Pagination
          count={Math.ceil(Number(totalCount) / 10)}
          page={current}
          onChange={handlePageChange}
          sx={{ margin: "3% 0", justifyContent: "center", display: "flex", }}
          color="secondary"
          hidePrevButton
          hideNextButton
        />)
      )}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  country: state.authenticationReducer.profile && state.authenticationReducer.profile.country,
  full_name: state.authenticationReducer.profile && state.authenticationReducer.profile.full_name,
  webinarList: state.WebinarsVetReducer.webinars,
  current: state.WebinarsVetReducer.current,
  filters: state.WebinarsVetReducer.filters,
  selectedFilters: state.WebinarsVetReducer.selectedFilters,
  courses: state.WebinarsVetReducer.courses,
  isLoading: state.WebinarsVetReducer.isLoading,
  totalCount: state.WebinarsVetReducer.totalCount
});

const mapDispatchToProps = (dispatch) => ({
  getWebinars: (obj, pageNumber) => dispatch(getWebinars(obj, pageNumber)),
  changePage: (pageNumber) => dispatch(changePage(pageNumber)),
  setVetCurrentWebinar: (obj) => dispatch(setVetCurrentWebinar(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PastWebinar);
