import React, { useState, useEffect } from "react";
import { Route, Redirect, Switch, BrowserRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {Button, CircularProgress, Grid, Paper, TextField, Select, MenuItem, Tabs, Tab, Typography} from "@mui/material";
import {getLandingDetails, 
    setFilterValue, 
    getGraphData,
     setRegionWiseDropdownValue, 
     setTop5RatedDropdownValue,
     setNoficationsData} from "../../Redux/Middlewares/AdminLandingPage/adminLandingPageMiddleware"
import NotificationComponent from "./NotificationComponet"
import "./style.css";
import ChartComponent from "./ChartComponent";
import PieChartComponent from "./PieChartComponent";
import SpinnerLG from "../../SpinnerLG";

const AnanlyticsDashboard = ({getLandingDetails,setNoficationsData,setFilterValue, getGraphData,landingData, setRegionWiseDropdownValue, setTop5RatedDropdownValue}) => {
    const {loaderObj,
        regionFilterData, 
        selectedRegionFilterData, 
        graphData, 
        vetCountFilterData,
        selectedVetCountFilterData,
        top5RatedFilterData,
        selectedTop5RatedFilterData} = landingData;

        const {isPageLoading} = loaderObj
        
    //getting graph data
    const {
            vet_count,
            weekly_new_registation, 
            occupation_distribution,
            vet_adoption_analysis, 
            webinar_adoption_analysis,
            top_5_rated_material
        } = graphData;
    
    useEffect(() => {
        getLandingDetails();
        setNoficationsData();
    },[]);

    

    const [tab, setTab] = useState("queries"); //state for tab

    const handleRegionChange = (event) => {
        getGraphData(event.target.value)
    }

    const getFilterOptions = (currFilterData) => {
        return(
            currFilterData.map((filterOption) => {
                let currOptionValue = filterOption.value ? filterOption.value : filterOption;
                return  (<MenuItem value={currOptionValue}>{currOptionValue}</MenuItem>)
                }
            )
        )
    }

    const redirectToPage = () => {
        return(<Link to ="/queries"/>)
        // <Redirect to="/queries"/>
    }

    const handleTabChange = (event, tabValue) => {
        setTab(tabValue)
    }

    return(
        <>
        <Grid container>
            <Grid container md={12} spacing={2} sx={{ marginBottom: 4 }}>
                <Grid item md={9} sm={9}>
                    <Grid container md={12} spacing={2}>
                        <Grid item md={3} sm={3}>
                            <Button style={{ textTransform: 'none' }} variant="contained" 
                            component={Link}
                            to="/learningContent"
                            onClick={() => { }}
                            > Upload Content</Button>
                        </Grid>
                        <Grid item md={3} sm={3}>
                            <Button style={{ textTransform: 'none' }} variant="contained" 
                            component={Link}
                            to="/newCourse"
                            onClick={() => { }}
                            > New Course</Button>
                        </Grid>
                        <Grid item md={3} sm={3}>
                            <Button style={{ textTransform: 'none' }}  variant="contained" 
                            component={Link}
                            to="/createWebinar"
                            > Schedule Webinar</Button>
                        </Grid>
                </Grid>
                </Grid>
                <Grid item md={3} sm={3}>
                    
                </Grid>
            </Grid>
            {regionFilterData!=undefined && regionFilterData!=null && regionFilterData.length>0 && <div className="filter-div">
                <div className="filter-text">Showing data from</div>
                       <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedRegionFilterData.value}
                            label="Select Region"
                            onChange={handleRegionChange}
                            sx={{width:"200px", height:"40px"}}
                            >
                            {getFilterOptions(regionFilterData)}
                        </Select>
                    </div>}
            {isPageLoading ? <SpinnerLG /> : 
            graphData && Object.keys(graphData).length>0 ?
            <Grid container md={12} spacing={2} align   Items="center" sx={{ marginTop: 4, marginBottom: 4 }}>
                <Grid item md={9} sm={9}>
                   

                    <Grid container md={12} spacing={2} alignItems="center" >
                        <Grid item md={6} sm={6}>
                            <Paper elevation={2}>
                                <div className="chart-header-div">
                                    <div className="chart-title">Region wise vet count (top 10 cities)</div>
                                    <div>
                                    <Select
                                        labelId="region_wise_vet_count"
                                        id="region-simple-select"
                                        value={selectedVetCountFilterData}
                                        label=""
                                        onChange={(event) => {
                                            setRegionWiseDropdownValue(event.target.value)
                                        }}
                                        sx={{width:"auto", height:"20px", fontSize:14, float:"right", marginRight:2 }}
                                        >
                                        {getFilterOptions(vetCountFilterData)}
                                    </Select>
                                    </div>
                                </div>
                                <Typography className="chart-title"></Typography>
                               
                            
                            <div style={{minHeight:"250px", width:"auto", position:"relative" }} >
                                {vet_count ? <ChartComponent 
                                    chartData={vet_count} 
                                    chartType="bar" 
                                    stacked={false} 
                                    currFilterValue={selectedVetCountFilterData}
                                    chartKey="vet_count"
                                    isLegendVisible={false}/> : "No data to display"}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item md={6} sm={6}>
                            <Paper elevation={2}>
                            <div className="chart-header-div">
                                <div className="chart-title">Weekly new registration count</div> 
                            </div>
                                <div style={{minHeight:"250px", width:"auto", position:"relative" }} >
                                    {weekly_new_registation ? <ChartComponent 
                                    chartData={weekly_new_registation} 
                                    chartType="bar" 
                                    stacked={false} 
                                    currFilterValue="test"
                                    chartKey="weekly_new_registation"
                                    isLegendVisible={false}/> : "No data to display"}
                                </div>                               
                            </Paper>
                        </Grid>
                        <Grid item md={6} sm={6}>
                            <Paper elevation={2}>
                            <div className="chart-header-div">
                                <div className="chart-title">Occupation distribution</div>
                            </div>
                                <div style={{minHeight:"250px", width:"auto", position:"relative" }} >
                                    {occupation_distribution ? <PieChartComponent 
                                    chartData={occupation_distribution} 
                                    chartType="pie" 
                                    stacked={false} 
                                    currFilterValue="test"
                                    chartKey="occupation_distribution"/> : "No data to display"}
                                    </div>
                            </Paper>
                        </Grid>
                        <Grid item md={6} sm={6}>
                            <Paper elevation={2}>
                            <div className="chart-header-div">
                                <div className="chart-title">Vet adoption rate</div>
                            </div>
                                <div style={{minHeight:"250px", width:"auto", position:"relative" }} >
                                {vet_adoption_analysis ? <ChartComponent 
                                chartData={vet_adoption_analysis} 
                                chartType="bar" 
                                stacked={false} 
                                currFilterValue="test"
                                chartKey="vet_adoption_analysis"
                                isLegendVisible={false}
                                /> : "No data to display"}
                                </div>
                            </Paper>
                        </Grid>
                        </Grid>

                        <Typography color="primary" sx={{ paddingTop: 2, fontSize: 20, fontWeight: 600 }}>Global KPIs</Typography>
                        <Grid container md={12} spacing={2} alignItems="center" >
                        <Grid item md={6} sm={6}>
                            <Paper elevation={2}>
                            <div className="chart-header-div">
                                <div className="chart-title">Webinar adaption analysis</div>
                            </div>
                                <div style={{minHeight:"250px", width:"auto", position:"relative" }} >
                                {webinar_adoption_analysis ? <ChartComponent 
                                chartData={webinar_adoption_analysis} 
                                chartType="bar" 
                                stacked={true} 
                                currFilterValue="test"
                                chartKey="webinar_adoption_analysis"
                                isLegendVisible={true}
                                /> : "No data to display"}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item md={6} sm={6}>
                            <Paper elevation={2}>
                                <div className="chart-header-div">
                                    <div className="chart-title">Top 5 rated material</div>
                                    <div>
                                    <Select
                                        labelId="top5_rated_material"
                                        id="top5-simple-select"
                                        value={selectedTop5RatedFilterData}
                                        label=""
                                        onChange={(event) => {
                                            setTop5RatedDropdownValue(event.target.value)
                                        }}
                                        sx={{width:"auto", height:"20px", fontSize:14, float:"right", marginRight:2 }}
                                        >
                                        {getFilterOptions(top5RatedFilterData)}
                                    </Select>
                                    </div>
                                </div>
                                <div style={{minHeight:"250px", width:"auto", position:"relative" }} >
                                {top_5_rated_material ? <ChartComponent 
                                chartData={top_5_rated_material} 
                                chartType="bar" 
                                stacked={false} 
                                currFilterValue={selectedTop5RatedFilterData}
                                chartKey="top_5_rated_material"
                                isLegendVisible={false}
                                /> : "No data to display"}
                                </div>
                            </Paper>
                        </Grid>                        
                    </Grid>
                
                </Grid>
                <Grid item md={3} sm={3} sx={{borderLeft:1, borderRight:1 , borderColor: 'divider'}}>
                    Recent notifications
                    <NotificationComponent/>
                </Grid>
            </Grid> : 
            <Grid container md={12} spacing={2} align   Items="center" sx={{ marginTop: 4, marginBottom: 4 }}>No data to be displayed</Grid> }

            
        </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    landingData: state.adminLandingPageReducer
})

const mapDispatchToProps = (dispatch) => ({
    getLandingDetails: () => dispatch(getLandingDetails()),
    setNoficationsData: (currPage) => dispatch(setNoficationsData(currPage)),
    setFilterValue: (filterValue) => dispatch(setFilterValue(filterValue)),
    getGraphData: (filterValue) =>  dispatch(getGraphData(filterValue)),
    setRegionWiseDropdownValue: (filterValue) =>  dispatch(setRegionWiseDropdownValue(filterValue)),
    setTop5RatedDropdownValue: (filterValue) => dispatch(setTop5RatedDropdownValue(filterValue))
})

export default connect(mapStateToProps, mapDispatchToProps)(AnanlyticsDashboard);