import { loginRequest, loginFailureRequest, loadUserRequest, authErrorRequest,SetForgotPasswordScreenFlow,
    setAuthIdOTP} from "../ActionCreators/authenticationActionCreators"
import { setLoginFailAlert } from "../ActionCreators/alertsActionCreators"
import { LOGIN_INIT } from "../ActionTypes/authenticationActionTypes"
import { SET_FORGOT_PASS_ALERT } from "../ActionTypes/alertsActionTypes"
import axios from "axios"

//Login 
export const enterNewPass = (new_password,authId) => async dispatch => {


    try {
        let url = process.env.REACT_APP_API_URL+"/login/change_password/"
        var bodyFormData = new FormData();
        bodyFormData.append("new_password", new_password)
        bodyFormData.append("authId", authId)
        


        let result = await axios.post(url, bodyFormData, {
            headers: { "Content-Type": "multipart/form-data" },
        })

        if (result.data && result.data.tokenId) {

            dispatch(SetForgotPasswordScreenFlow({"forgetPasswordflow":3}))
            dispatch(setAuthIdOTP({"authIdOTP":result.data.authId}))
        }
        else {
            dispatch({type:SET_FORGOT_PASS_ALERT})
            dispatch(SetForgotPasswordScreenFlow({"forgetPasswordflow":0}))
            dispatch(setAuthIdOTP({"authIdOTP":null}))
            
        }


    }
    catch (err) {
        dispatch({type:SET_FORGOT_PASS_ALERT})
        dispatch(SetForgotPasswordScreenFlow({"forgetPasswordflow":0}))
            dispatch(setAuthIdOTP({"authIdOTP":null}))
        

        
    }
}

