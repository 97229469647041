import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getSSOUser } from "../Redux/Middlewares/SSO/SSOMiddleware"
import { loginRequest, loginFailureRequest, loadUserRequest, authErrorRequest } from "../Redux/ActionCreators/authenticationActionCreators"
import { setLoginFailAlert } from "../Redux/ActionCreators/alertsActionCreators"
import { LOGIN_INIT } from "../Redux/ActionTypes/authenticationActionTypes"
import axios from "axios"
import { Redirect } from 'react-router-dom';
import { TOGGLE_PROCESS_LOADER } from "../Redux/ActionTypes/alertsActionTypes"
import { toggleGlobalAlert } from "../Redux/ActionCreators/alertsActionCreators";

const SSOlogin = ({ getSSOUser, isAuthenticated }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: true } })

        // if the userInfo api fails,the user is redirected to the login page
        const SSOValidate = async () => {

            try {

                // dispatch({ type: LOGIN_INIT })
                let url = process.env.REACT_APP_API_URL + "/login/userinfo"


                let result = await axios.get(url)

                if (result.data && result.data[0]) {


                    dispatch(loginRequest({
                        token: result.data[0].token, user: result.data[0].email,
                        userStatus: "registered",
                        profile: result.data[0],
                        userType: result.data[0].user_type
                    }))
                    localStorage.setItem("adminType", "admin")
                    dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: false } })


                }
                else {
                    // dispatch(setLoginFailAlert())
                    dispatch(loginFailureRequest())
                    dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: false } })
                }

                //dispatch(loadUser())

            }
            catch (error) {

                if (
                    error.response
                ) {
                    window.location = "/api/login/loginpage";
                    return;
                }


                // dispatch(toggleGlobalAlert("warning", err.response && err.response.data
                //     && err.response.data.message ? err.response.data.message : "Something went wrong", true));
                // dispatch(loginFailureRequest())
                // dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: false } })
            }

        }

        SSOValidate()


    }, [])

    return (<>
        {isAuthenticated ? (<Redirect to='/' />) : "loading..........."}
    </>)
}


const mapStateToProps = (state) => ({
    isAuthenticated: state.authenticationReducer.isAuthenticated,
})


const mapDispatchToProps = dispatch => {
    return {
        getSSOUser: () => dispatch(getSSOUser()),
        //   createProfile: (item) => dispatch(createProfile(item))


    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SSOlogin)