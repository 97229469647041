import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useHistory, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { connect } from "react-redux"
import { makeStyles } from "@mui/styles";
import {
    Grid, IconButton, TextField, InputAdornment, Chip, Drawer, MenuItem, Select, OutlinedInput,
    Stack, Button, Paper, Divider
} from "@mui/material"
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import { Autocomplete } from "@mui/material"
import { getFiltersVetCourses } from "../../Redux/Middlewares/VetCourses/getFiltersVetCoursesMiddleware";
import { getActiveVetCourses } from "../../Redux/Middlewares/VetCourses/getActiveCourseMiddleware"
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import LinearProgress from '@mui/material/LinearProgress';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ReactPlayer from 'react-player/lazy'
import CloseIcon from '@mui/icons-material/Close';
import ReadingMaterialView from "../../VetCourses/ReadingMaterialView";
import QuizActiveScreen from "../../VetCourses/QuizActiveScreen";
import FileIcon from "../../Assets/images/FileIcon.svg"
import QuizIconNew from "../../Assets/images/QuizIconNew.svg"
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import { fetchPreviewData } from "../../Redux/Middlewares/CoursesAdmin/fetchDataForEditMiddleware";
import { SET_PREVIEW_SEQUENCE } from "../../Redux/ActionTypes/coursesAdminActionTypes";
import ReadingMaterialPreview from "./ReadingMaterialPreview"
import CoursePreviewQuiz from "./CoursePreviewQuiz";
import { getQuizDetailsAdmin } from "../../Redux/Middlewares/VetCourses/getQuizDetailsMiddleware";


const CoursePreview = ({ toggleDrawer, setCourseSequence, courseSequence,
    contents, open, handleClick, nextSelection, id, lessonCompletion,
    completionPercentage, toggleQuizActivation, quizActive, getQuizDetails, quizLoader, quizData, clearQuizData,
    quizValidation, quizGrade, setRoot, quizWrongAnswers, quizInput, setTab, setPreviewSequence,
    previewSequence, currentSelection }) => {

    courseSequence = previewSequence

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        height: 550,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        overflow: "scroll"
    };
    const [state, setState] = React.useState(false);
    const [quizConfirmation, setQuizConfirmation] = React.useState(false);

    useEffect(() => {

        if (contents) {
            let selectionMap = {}

            for (let i = 0; i < contents.length; i++) {



                for (let j = 0; j < contents[i].lessons.length; j++) {
                    if (contents[i].lessons[j].course_level2_id.toString() + "x" === currentSelection) {
                        selectionMap[[contents[i].lessons[j].course_level2_id.toString() + "x"]] = true
                    }
                    else {
                        selectionMap[[contents[i].lessons[j].course_level2_id.toString() + "x"]] = false
                    }

                }


            }

            setPreviewSequence(selectionMap, currentSelection)
        }


    }, [contents])

    const handleSelection = (nodeId) => {
        let tempObj = {}
        for (let key of Object.keys(courseSequence)) {

            if (key == nodeId) {
                tempObj[[key]] = true
            }
            else {
                tempObj[[key]] = false
            }

        }

        let chapterId = null

        let newSelection = nodeId.substring(0, nodeId.length - 1);
        let lessonType
        for (let i = 0; i < contents.length; i++) {

            if (i === 0 || (contents[i - 1].chapter_completion)) {

                for (let j = 0; j < contents[i].lessons.length; j++) {
                    if (contents[i].lessons[j].course_level2_id.toString() + "x" == nodeId) {
                        chapterId = contents[i].course_level1_id
                        lessonType = contents[i].lessons[j].file_type
                        break
                    }
                }
            }

        }

        // if (lessonType !== "Quiz") {
        //     lessonCompletion(id, chapterId, newSelection)
        // }


        setPreviewSequence(tempObj, nodeId)
    }

    const selectionStyle = {
        borderRight: 4, backgroundColor: "#f3f3fb",
        borderColor: "#0f01a0",
        padding: 1,
        borderRadius: "5px 0 0 5px"
    }

    let contentType = null
    let contentLink = null
    let currentLessonName = null
    let currentLessonId = null
    let currentChapter = null
    let quizLesson = null
    let lessonTypeGeneric = null
    let quizQuestions = 0


    if (contents) {
        for (let i = 0; i < contents.length; i++) {
            for (let j = 0; j < contents[i].lessons.length; j++) {
                if (contents[i].lessons[j].course_level2_id.toString() + "x" === currentSelection) {
                    contentType = contents[i].lessons[j].file_type
                    contentLink = contents[i].lessons[j].sas_file_path
                    currentLessonName = contents[i].lessons[j].content_name
                    currentChapter = contents[i].course_level1_id
                    currentLessonId = contents[i].lessons[j].course_level2_id
                    quizLesson = contents[i].lessons[j]
                    lessonTypeGeneric = contents[i].lessons[j].file_type
                    quizQuestions = contents[i].lessons[j].questions && contents[i].lessons[j].questions.length
                }
            }

        }
    }

    return (<Box sx={style}>

        <Grid container sx={{ padding: 1.5, }}>
            <Grid container justifyContent="space-between" xs={12} >

                {/* <CloseIcon size="small" onClick={() => toggleDrawer(false)} /> */}
                {/* {(lessonTypeGeneric !== "Quiz" || quizActive === 0) && <Button onClick={() => nextSelection(currentSelection)}>Next</Button>} */}
            </Grid>
            {lessonTypeGeneric !== "Quiz" && <Grid container justifyContent="center" sx={12}>
                <Typography sx={{ color: "#0f01a0", fontWeight: 800, fontSize: 18, marginTop: 0 }}>{currentLessonName}</Typography>
            </Grid>}

            {contentType === "Video" || contentType === "Audio" ? (
                <Grid container md={12} spacing={2} direction="row-reverse" sx={{ marginTop: 4, marginBottom: 4 }}>
                    <Grid item xs={12} sm={12} md={12} sx={{ border: 0, marginBottom: 1 }}>
                        <ReactPlayer config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                            onContextMenu={e => e.preventDefault()}
                            width="100%" height="100%" controls={true} url={contentLink} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{ height: "55vh", overflowY: "auto", display: "flex", width: "100%" }}>
                            <Grid item xs={12} sm={12}>
                                <List
                                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"


                                >
                                    {
                                        contents && contents.map((item, index) => {

                                            return (
                                                < >
                                                    <ListItemButton disableGutters onClick={{}
                                                        // () => handleClick(index)
                                                    }>
                                                        <Grid container xs={12} sm={12}>
                                                            <Grid item xs={1}>
                                                                <PlayCircleFilledWhiteIcon sx={{ color: "#6ad7b9", paddingRight: 1, paddingBottom: 1 }} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography sx={{ fontWeight: 600, fontSize: 14, }}>
                                                                    {`Chapter ${item.sequence_id}`}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <Typography sx={{ fontSize: 14 }}>
                                                                    { }
                                                                </Typography>

                                                            </Grid>
                                                            <Grid item xs={1}>

                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                {/* <Typography sx={{ fontSize: 12 }}>
                                                                        {completionPercentage[item.course_level1_id][0]} out of {completionPercentage[item.course_level1_id][1]} items completed
                                                                    </Typography> */}
                                                            </Grid>
                                                            <Grid item xs={1}>

                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                {/* <LinearProgress color="secondary" variant="determinate"
                                                                        value={(completionPercentage[item.course_level1_id][0] / completionPercentage[item.course_level1_id][1]) * 100} /> */}

                                                            </Grid>

                                                        </Grid>
                                                        {true ? <ExpandLess /> : <ExpandMore />}
                                                    </ListItemButton>
                                                    <Collapse in={true} timeout="auto" unmountOnExit>

                                                        <List component="div" disablePadding>
                                                            {
                                                                item.lessons.map(
                                                                    subItem => {
                                                                        return (
                                                                            <ListItemButton disableGutters onClick={() => {
                                                                                handleSelection(subItem.course_level2_id.toString() + "x");

                                                                            }} sx={{ padding: 1 }}>
                                                                                <Grid container xs={12}
                                                                                    sx={courseSequence && courseSequence[subItem.course_level2_id.toString() + "x"] ? selectionStyle : { padding: 1, boxShadow: 1 }} >
                                                                                    <Grid container justifyContent="center" alignItems="center" xs={4} sx={{ padding: .5, backgroundColor: "#f2f2f2", borderRadius: 2, }}>
                                                                                        {subItem.file_type === "Video" ? (<div


                                                                                        >
                                                                                            <OndemandVideoIcon sx={{ color: "#8080cf", height: 40, width: "100%" }} />
                                                                                        </div>) : subItem.file_type === "Audio" ?


                                                                                            <AudiotrackIcon sx={{ color: "#8080cf", height: 40, width: "100%" }} /> : subItem.file_type === "Reading Material" ?
                                                                                                // (<ArticleIcon sx={{
                                                                                                //     height: 55,
                                                                                                //     width: "100%",
                                                                                                //     borderRadius: 10, color: "#8080cf"
                                                                                                // }} />)
                                                                                                (<img src={FileIcon} style={{
                                                                                                    height: 40,
                                                                                                    width: "100%",
                                                                                                    borderRadius: 10
                                                                                                }} alt="quiz" />)
                                                                                                :
                                                                                                // (<QuizIcon sx={{
                                                                                                //     height: 40,
                                                                                                //     width: "100%",
                                                                                                //     borderRadius: 10, color: "#8080cf"
                                                                                                // }} />)
                                                                                                (<img src={QuizIconNew} style={{
                                                                                                    height: 40,
                                                                                                    width: "100%",
                                                                                                    borderRadius: 10
                                                                                                }} alt="quiz" />)
                                                                                        }
                                                                                    </Grid>
                                                                                    <Grid container xs={8} sx={{ overflow: "hidden", paddingLeft: 1, }}>
                                                                                        <Grid container xs={12}>
                                                                                            <Typography noWrap sx={{ fontSize: 16, fontWeight: 600, color: "#0f01a0", }}>
                                                                                                {subItem.file_type === "Video" ? subItem.content_name :
                                                                                                    subItem.file_type === "Reading Material" ? "Reading Material" :
                                                                                                        subItem.file_type === "Audio" ? "Audio" : "Quiz"}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid container xs={10}>
                                                                                            <Typography sx={{ fontSize: 14, fontWeight: 600, }}>
                                                                                                {subItem.file_type === "Video" || subItem.file_type === "Audio" ? `${(Number(subItem.duration.split(" ")[0]) / 3600).toPrecision(1)} hours` :
                                                                                                    subItem.file_type === "Quiz" ? subItem.questions?.length + (subItem.questions?.length == 1 ? " question" : "  questions") : ""}
                                                                                            </Typography>
                                                                                        </Grid>



                                                                                        {subItem.completion_status ? <Grid container xs={2}>
                                                                                            <CheckCircleRoundedIcon color="secondary" />
                                                                                        </Grid> : <Grid container xs={2}></Grid>}

                                                                                        {/* <Grid item xs="auto" onClick={() => toggleDrawer(true)}>
                                                                                    <Typography noWrap sx={{
                                                                                        border: 1.5, borderColor: "#0f01a0",
                                                                                        borderRadius: 1, fontWeight: 600, paddingLeft: 1, paddingRight: 1, paddingTop: .2,
                                                                                        paddingBottom: .1, fontSize: 14
                                                                                    }}></Typography>
                                                                                </Grid> */}

                                                                                    </Grid>

                                                                                </Grid>
                                                                            </ListItemButton>
                                                                        )
                                                                    }
                                                                )
                                                            }
                                                        </List>

                                                    </Collapse>
                                                </>
                                            )




                                        })
                                    }





                                </List>
                            </Grid >
                        </Box>
                    </Grid>
                </Grid>) : contentType === "Reading Material" ? (<ReadingMaterialPreview
                    toggleDrawer={toggleDrawer} setPreviewSequence={setPreviewSequence} courseSequence={courseSequence}
                    currentSelection={currentSelection} contents={contents} open={open} handleClick={handleClick}
                    nextSelection={nextSelection} completionPercentage={completionPercentage}
                    id={id} lessonCompletion={lessonCompletion} setRoot={setRoot} quizQuestions={quizQuestions} />) :

                contentType === "Quiz" ? (<CoursePreviewQuiz toggleQuizActivation={toggleQuizActivation}
                    quizActive={quizActive} getQuizDetails={getQuizDetails} currentSelection={currentSelection}
                    quizLoader={quizLoader} quizData={quizData} currentLessonId={currentLessonId} id={id}
                    currentChapter={currentChapter} nextSelection={nextSelection}
                    clearQuizData={clearQuizData} quizValidation={quizValidation} currentLessonName={currentLessonName}
                    quizLesson={quizLesson} quizQuestions={quizQuestions} quizGrade={quizGrade} contents={contents}
                    setRoot={setRoot} quizWrongAnswers={quizWrongAnswers} quizInput={quizInput} setTab={setTab}
                    previewSequence={previewSequence} setPreviewSequence={setPreviewSequence}
                />) : (<>
                    <Grid item xs={12} sm={12}>
                        <List
                            sx={{ width: '100%', bgcolor: 'background.paper' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"


                        >
                            {
                                contents && contents.map((item, index) => {

                                    return (
                                        < >
                                            <ListItemButton disableGutters onClick={{}
                                                // () => handleClick(index)
                                            }>
                                                <Grid container xs={12} sm={12}>
                                                    <Grid item xs={1}>
                                                        <PlayCircleFilledWhiteIcon sx={{ color: "#6ad7b9", paddingRight: 1, paddingBottom: 1 }} />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                                                            {`Chapter ${item.sequence_id}`}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Typography sx={{ fontSize: 14 }}>
                                                            { }
                                                        </Typography>

                                                    </Grid>
                                                    <Grid item xs={1}>

                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        {/* <Typography sx={{ fontSize: 12 }}>
                                                                        {completionPercentage[item.course_level1_id][0]} out of {completionPercentage[item.course_level1_id][1]} items completed
                                                                    </Typography> */}
                                                    </Grid>
                                                    <Grid item xs={1}>

                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        {/* <LinearProgress color="secondary" variant="determinate"
                                                                        value={(completionPercentage[item.course_level1_id][0] / completionPercentage[item.course_level1_id][1]) * 100} /> */}

                                                    </Grid>

                                                </Grid>
                                                {true ? <ExpandLess /> : <ExpandMore />}
                                            </ListItemButton>
                                            <Collapse in={true} timeout="auto" unmountOnExit>

                                                <List component="div" disablePadding>
                                                    {
                                                        item.lessons.map(
                                                            subItem => {
                                                                return (
                                                                    <ListItemButton disableGutters onClick={() => {
                                                                        handleSelection(subItem.course_level2_id.toString() + "x");

                                                                    }} sx={{ padding: 1 }}>
                                                                        <Grid container xs={12}
                                                                            sx={courseSequence && courseSequence[subItem.course_level2_id.toString() + "x"] ? selectionStyle : { padding: 1, boxShadow: 1 }} >
                                                                            <Grid container justifyContent="center" alignItems="center" xs={4} sx={{ padding: .5, backgroundColor: "#f2f2f2", borderRadius: 2, }}>
                                                                                {subItem.file_type === "Video" ? (<div


                                                                                >
                                                                                    <OndemandVideoIcon sx={{ color: "#8080cf", height: 40, width: "100%" }} />
                                                                                </div>) : subItem.file_type === "Audio" ?


                                                                                    <AudiotrackIcon sx={{ color: "#8080cf", height: 40, width: "100%" }} /> : subItem.file_type === "Reading Material" ?
                                                                                        // (<ArticleIcon sx={{
                                                                                        //     height: 55,
                                                                                        //     width: "100%",
                                                                                        //     borderRadius: 10, color: "#8080cf"
                                                                                        // }} />)
                                                                                        (<img src={FileIcon} style={{
                                                                                            height: 40,
                                                                                            width: "100%",
                                                                                            borderRadius: 10
                                                                                        }} alt="quiz" />)
                                                                                        :
                                                                                        // (<QuizIcon sx={{
                                                                                        //     height: 40,
                                                                                        //     width: "100%",
                                                                                        //     borderRadius: 10, color: "#8080cf"
                                                                                        // }} />)
                                                                                        (<img src={QuizIconNew} style={{
                                                                                            height: 40,
                                                                                            width: "100%",
                                                                                            borderRadius: 10
                                                                                        }} alt="quiz" />)
                                                                                }
                                                                            </Grid>
                                                                            <Grid container xs={8} sx={{ overflow: "hidden", paddingLeft: 1, }}>
                                                                                <Grid container xs={12}>
                                                                                    <Typography noWrap sx={{ fontSize: 16, fontWeight: 600, color: "#0f01a0", }}>
                                                                                        {subItem.file_type === "Video" ? subItem.content_name :
                                                                                            subItem.file_type === "Reading Material" ? "Reading Material" :
                                                                                                subItem.file_type === "Audio" ? "Audio" : "Quiz"}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid container xs={10}>
                                                                                    <Typography sx={{ fontSize: 14, fontWeight: 600, }}>
                                                                                        {subItem.file_type === "Video" || subItem.file_type === "Audio" ? `${(Number(subItem.duration.split(" ")[0]) / 3600).toPrecision(1)} hours` :
                                                                                            subItem.file_type === "Quiz" ? subItem.questions?.length + (subItem.questions?.length == 1 ? " question" : "  questions") : ""}
                                                                                    </Typography>
                                                                                </Grid>



                                                                                {subItem.completion_status ? <Grid container xs={2}>
                                                                                    <CheckCircleRoundedIcon color="secondary" />
                                                                                </Grid> : <Grid container xs={2}></Grid>}

                                                                                {/* <Grid item xs="auto" onClick={() => toggleDrawer(true)}>
                                                                                    <Typography noWrap sx={{
                                                                                        border: 1.5, borderColor: "#0f01a0",
                                                                                        borderRadius: 1, fontWeight: 600, paddingLeft: 1, paddingRight: 1, paddingTop: .2,
                                                                                        paddingBottom: .1, fontSize: 14
                                                                                    }}></Typography>
                                                                                </Grid> */}

                                                                            </Grid>

                                                                        </Grid>
                                                                    </ListItemButton>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </List>

                                            </Collapse>
                                        </>
                                    )




                                })
                            }





                        </List>
                    </Grid > </>)}

        </Grid>

    </Box>)
}

const mapStateToProps = (state) => ({
    courseDetails: state.CoursesAdminReducer.courseDetails,
    isLoading: state.CoursesAdminReducer.isLoading,
    curriculumDetails: state.CoursesAdminReducer.curriculumDetails,
    activeCourse: state.CoursesAdminReducer.activeCourse,
    editedData: state.CoursesAdminReducer.editedData,
    contents: state.CoursesAdminReducer.previewData && state.CoursesAdminReducer.previewData.chapters,
    currentSelection: state.CoursesAdminReducer.currentSelection,
    previewSequence: state.CoursesAdminReducer.previewSequence,
    quizData: state.CoursesVetReducer.quizData,
});

const mapDispatchToProps = (dispatch) => ({
    setPreviewSequence: (obj, current) => dispatch({ type: SET_PREVIEW_SEQUENCE, payload: { data: obj, current: current } }),
    getQuizDetails: (val) => dispatch(getQuizDetailsAdmin(val)),

    // editCourse: (courseDetailsObject) =>
    //     dispatch(editCourse(courseDetailsObject)),

    // changeCourseStatus: (courseDetailsObject) => dispatch(changeCourseStatus(courseDetailsObject, true)),
    // fetchPreviewData: (obj) => dispatch(fetchPreviewData(obj))
});

export default connect(mapStateToProps, mapDispatchToProps)(CoursePreview);