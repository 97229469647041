import axios from "axios";
import {
    //   fetchWEBINARSFailure,
    //   fetchWEBINARSRequest,
    fetchWebinarsSuccess,

    //   toggleAlert,
} from "../../ActionCreators/webinarsAdminActionCreators";
import {
    CLEAR_WEBINARS_DATA,
    FETCH_WEBINARS_DATA,
    SET_WEBINARS_DATA,
    SET_WEBINARS_DATA_PAGINATION
} from "../../ActionTypes/webinarsAdminActionTypes"

// const GET_WEBINARS_URL =
//   process.env.REACT_APP_API_URL + "/WEBINARS/admin/course_homepage/?page=";


export const deleteWebinar = (webinar_id, reqObj) => async dispatch => {

    try {


        let url = process.env.REACT_APP_API_URL + `/webinars/admin/delete_webinar/${webinar_id}/`




        let result = await axios.delete(url)

        if (result.data || result.status === 204) {

            dispatch({ type: CLEAR_WEBINARS_DATA })
            dispatch({ type: FETCH_WEBINARS_DATA })

            let url = process.env.REACT_APP_API_URL + `/webinars/admin/webinar_homepage/?page=${1}`




            let result = await axios.post(url, reqObj)

            if (result.data) {

                dispatch({ type: SET_WEBINARS_DATA, payload: result.data.results })
                dispatch({
                    type: SET_WEBINARS_DATA_PAGINATION, payload: {
                        totalCount: result.data.count,
                        prev: null,
                        next: result.data.next ? 2 : null,
                        current: 1

                    }
                })


            }


        }
        else {
            dispatch({ type: CLEAR_WEBINARS_DATA })
            // dispatch({ type: SET_GENERIC_ERROR_ALERT })
        }



    }
    catch (err) {
        dispatch({ type: CLEAR_WEBINARS_DATA })
        
        // dispatch({ type: SET_GENERIC_ERROR_ALERT })
    }
}


