import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import RegisteredWebinar from "./RegisteredWebinar";
import UpcomingWebinar from "./UpcomingWebinar";
import PastWebinar from "./PastWebinar";
import { makeStyles } from "@mui/styles";
// import { useHistory } from "react-router";
import Dropdowns from "./Dropdowns";
import Dialog from "@mui/material/Dialog";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { connect } from "react-redux";
import { getWebinars } from "../../Redux/Middlewares/WebinarsVet/getWebinarsMiddleware";
import { selectFilter } from "../../Redux/ActionCreators/webinarsVetActionCreators";
import { Grid, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import Chip from '@mui/material/Chip';
import { getFilterValues } from "../../Redux/Middlewares/WebinarsVet/getFilterValuesMiddleware";
import { ClearAll, FilterAlt } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from "@mui/lab";


const useStyles = makeStyles((theme) => ({
  customTabRoot: {
    color: "black",
  },
  customTabIndicator: {
    backgroundColor: "black",
  },
  selected: {
    fontWeight: "bold",
  },

  applyButtonStyle: { marginTop: "0px", width: "100%", fontSize: "80%" }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Webinar = ({ selectedFilters, getWebinars, selectFilter, getFilterValues, isLoading }) => {
  // const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    getFilterValues();
    return () => {
      handleClearFilterOnUnmount()
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  let requestObj = {
    "webinar_speaker": selectedFilters && selectedFilters.creator && selectedFilters.creator.length > 0 ? selectedFilters.creator.map(item => item.speaker_name) : [],
    "webinar_category": selectedFilters && selectedFilters.category && selectedFilters.category.length > 0 ? selectedFilters.category.map(item => item.category_name) : [],
    "webinar_species": selectedFilters && selectedFilters.species && selectedFilters.species.length > 0 ? selectedFilters.species.map(item => item.value) : [],
    "webinar_language": [],
    "search_value": searchText ? [searchText] : [],
    "webinar_type": value === 0 ? "upcoming" : value === 1 ? "register" : "past",
    "show_content_from": {
      "fixed": selectedFilters && selectedFilters.show_content_from.value ? selectedFilters.show_content_from.value : "All time",
      "start_date": null,
      "end_date": null
    }
  }

  const handleApplyFiltersClick = () => {
    getWebinars(requestObj);
  };

  const handleClearFilter = () => {
    selectFilter("show_content_from", { value: "All time" })
    selectFilter("category", [])
    selectFilter("species", [])
    selectFilter("creator", [])

    let requestObject =
    {
      "webinar_type": value === 0 ? "upcoming" : value === 1 ? "register" : "past",
      "search_value": [],
      "webinar_speaker": [],
      "webinar_category": [],
      "webinar_species": [],
      "show_content_from": {
        "fixed": "All time",
        "start_date": null,
        "end_date": null
      }
    }
    setSearchText("")
    getWebinars(requestObject)

  }
  const handleClearFilterOnUnmount = () => {
    selectFilter("show_content_from", { value: "All time" })
    selectFilter("category", [])
    selectFilter("species", [])
    selectFilter("creator", [])

    let requestObject =
    {
      "webinar_type": value === 0 ? "upcoming" : value === 1 ? "register" : "past",
      "search_value": [],
      "webinar_speaker": [],
      "webinar_category": [],
      "webinar_species": [],
      "show_content_from": {
        "fixed": "All time",
        "start_date": null,
        "end_date": null
      }
    }
    setSearchText("")


  }


  return (
    <Box sx={{ width: "100%" }}>
      <Box >

        <Typography color="primary" sx={{ paddingLeft: 4, paddingTop: 2, fontSize: 24, fontWeight: 600 }}>Webinars</Typography>
        <Grid container >
          <Grid item xs={12} md={8}>
            <Tabs
              sx={{ padding: 2 }}
              value={value}
              onChange={handleChange}
              textColor="inherit"
              classes={{
                root: classes.customTabRoot,
                indicator: classes.customTabIndicator,
              }}
            >

              <Tab
                style={{ textTransform: "none" }}
                classes={{
                  selected: classes.selected,
                }}
                label="Upcoming"
              />
              <Tab
                style={{ textTransform: "none" }}
                classes={{
                  selected: classes.selected,
                }}
                label="Registered"
              />
              <Tab
                style={{ textTransform: "none" }}
                classes={{
                  selected: classes.selected,
                }}
                label="Past"
              />

            </Tabs>
          </Grid>
          <Grid container justifyContent="left" md={3} sx={{ display: { xs: "hidden", md: "flex" } }}>
            <TextField
              sx={{ display: { md: "flex", xs: "none" }, }}
              fullWidth
              id="input-with-icon-textfield"
              placeholder="Search webinars,authors..."
              value={searchText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon
                      onClick={() => handleApplyFiltersClick()}
                    />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </Grid>
        </Grid>



      </Box>
      <Grid container md={12} sx={{ padding: 2, display: { md: "flex", xs: "none" } }}>
        <Grid item md={10}>
          <Dropdowns />
        </Grid>
        <Grid container md={2} alignItems="start" sx={{ paddingLeft: 1, paddingRight: 1, paddingTop: 5 }}>

          {/* <Button sx={{ marginLeft: 1, marginRight: 1 }} variant="outlined" style={{ textTransform: "none" }} onClick={() => { getWebinars(requestObj); handleClose() }}>
            Apply
          </Button> */}
          <div style={{display:"flex", flexWrap:"nowrap", width:"100%", marginTop:"-2%" }}>
          <LoadingButton
              variant="contained"
              className={classes.applyButtonStyle}
              onClick={() => { getWebinars(requestObj); handleClose() }}
              style={{textTransform:"none"}}
              endIcon={<FilterAlt />}
              loading={isLoading}
              loadingPosition="end"
          >
              Apply
          </LoadingButton>
          <Tooltip title="Clear Filters" arrow>
            <IconButton
              color="primary"
              aria-label="clear filter"
              component="span"
              onClick={() => handleClearFilter()}
            >
              <ClearAll />
            </IconButton>
          </Tooltip>
          </div>
        </Grid>

      </Grid>
      <TabPanel value={value} index={0}>
        <UpcomingWebinar searchText={searchText} setSearchText={setSearchText} handleClickOpen={handleClickOpen} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RegisteredWebinar searchText={searchText} setSearchText={setSearchText} handleClickOpen={handleClickOpen} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PastWebinar searchText={searchText} setSearchText={setSearchText} handleClickOpen={handleClickOpen} />
      </TabPanel>
      {/* <Button
        variant="contained"
        style={{ position: "fixed", bottom: 0, zIndex: 1, width: "100%", height: 50, textTransform: "none", }}
        onClick={handleClickOpen}
        sx={{ display: { xs: "flex", md: "none" } }}
        endIcon={<><FilterAltOutlinedIcon /> <Chip sx={{ color: "#000", backgroundColor: "white" }} size="small"
          label={selectedFilters.category.length + selectedFilters.creator.length + selectedFilters.species.length} /> </>}
      >
        Filters
      </Button> */}
      <Dialog
        open={open}
        fullScreen
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div>
          <Grid container xs={12} justifyContent="space-between" sx={{ padding: 1 }}>
            <Typography color="primary" sx={{ fontSize: 18, fontWeight: 600 }} >Filters</Typography>
            <Button sx={{ color: "#7c7c7c" }} style={{ textTransform: "none" }} onClick={() => { handleClearFilter(); }}>Reset filters</Button>
          </Grid>
          <Dropdowns />
          <div style={{ display: "flex" }}>
            <Button
              variant="contained"
              style={{ width: "100%", marginRight: "10px", marginLeft: "10px", textTransform: "none" }}
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              variant="outlined"
              style={{ width: "100%", marginRight: "10px", marginLeft: "10px", textTransform: "none" }}
              onClick={() => { getWebinars(requestObj); handleClose() }}
            >
              Apply
            </Button>
          </div>
        </div>
      </Dialog>
    </Box >
  );
};


const mapStateToProps = (state) => ({
  webinarList: state.WebinarsVetReducer.webinars,
  pageNumber: state.WebinarsVetReducer.pageNumber,
  filters: state.WebinarsVetReducer.filters,
  selectedFilters: state.WebinarsVetReducer.selectedFilters,
  courses: state.WebinarsVetReducer.courses,
  isLoading: state.WebinarsVetReducer.isLoading,
  currentWebinar: state.WebinarsVetReducer.currentWebinar,
});

const mapDispatchToProps = (dispatch) => ({
  getWebinars: (obj, pageNumber) => dispatch(getWebinars(obj, pageNumber)),
  selectFilter: (filterType, filterValue) => dispatch(selectFilter(filterType, filterValue)),
  getFilterValues: () => dispatch(getFilterValues()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Webinar);

