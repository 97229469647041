import {
  FETCH_DROPDOWN_VALUES_REQUEST_WEBINAR_ADMIN,
  FETCH_DROPDOWN_VALUES_FAILURE_WEBINAR_ADMIN,
  FETCH_DROPDOWN_VALUES_SUCCESS_WEBINAR_ADMIN,
  FETCH_FILTER_VALUES_REQUEST_WEBINAR_ADMIN,
  FETCH_FILTER_VALUES_FAILURE_WEBINAR_ADMIN,
  FETCH_FILTER_VALUES_SUCCESS_WEBINAR_ADMIN,
  SELECT_FILTER_WEBINAR_ADMIN,
  //   FETCH_COURSES_REQUEST,
  //   FETCH_COURSES_FAILURE,
  FETCH_ADMIN_WEBINARS_SUCCESS,
  CHANGE_PAGE_WEBINAR_ADMIN,
  //   CHANGE_COURSE_STATUS_REQUEST,
  //   CHANGE_COURSE_STATUS_SUCCESS,
  //   CHANGE_COURSE_STATUS_FAILURE,
  TOGGLE_ALERT,
  CLEAR_WEBINARS_DATA,
  FETCH_WEBINARS_DATA,
  SET_WEBINARS_DATA,
  SET_WEBINARS_DATA_PAGINATION,
  WEBINAR_NAVIGATION,
  SET_CUSTOM_DATE,
  SET_WEB_EDIT,
  SET_WEBINARS_EDITING_DATA,
  SET_CURRENT_WEBINAR,
  SET_UPLOAD_WEBINAR
} from "../ActionTypes/webinarsAdminActionTypes";

const initialState = {
  isLoading: false,
  error: "",
  filters: {},
  dropDowns: {},
  selectedFilters: {
    category: [],
    country: [],
    creator: [],
    language: [],
    species: [],
    show_content_from: { value: "All time" },
    search: "",
  },
  webinars: {},
  pageNumber: 1,
  isAlertOpen: false,
  alertType: "success",
  alertMessage: "",
  contents: null,
  totalCount: null,
  currentWebinar: null,
  current: 1,
  next: null,
  prev: null,
  dataLoading: false,
  editFlow: false,
  editedData: null,
  webinarSaving: false,
  uploadModal: false,
  from: "",   // for custom date range filters
  to: ""      // for custom date range filters
};

const WebinarsAdminReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_FILTER_VALUES_REQUEST_WEBINAR_ADMIN:
      // case FETCH_COURSES_REQUEST:
      // case CHANGE_COURSE_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case FETCH_WEBINARS_DATA:
      return {
        ...state,
        dataLoading: true,
      };
    case SET_WEBINARS_DATA:
      return {
        ...state,
        webinars: payload,
        dataLoading: false
      };
    case SET_CUSTOM_DATE:
    case WEBINAR_NAVIGATION:
    case SET_WEBINARS_DATA_PAGINATION:
      return {
        ...state, ...payload
      };
    case SET_WEB_EDIT:
      return {
        ...state, editFlow: payload
      }
    case SET_WEBINARS_EDITING_DATA:
      return {
        ...state, editedData: payload
      }
    case SET_CURRENT_WEBINAR:
      return {
        ...state, currentWebinar: payload
      }
    case SET_UPLOAD_WEBINAR:
      return {
        ...state, uploadModal: payload
      }

    case CLEAR_WEBINARS_DATA:
      return {
        ...state,
        isLoading: false,
        dataLoading: false,
        contents: null,
        webinars: null,
        isBookmarked: null,
        // rating: null,
        next: null,
        prev: null,
        current: null,
        totalCount: null,
        editedData: null,
        currentWebinar: null

      };

    // case CHANGE_COURSE_STATUS_SUCCESS:
    //   // case EDIT_FILE_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     error: "",
    //   };

    // case CHANGE_COURSE_STATUS_FAILURE:
    //   // case EDIT_FILE_FAILURE:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     error: payload,
    //   };

    // // case SELECT_FILE:
    // //   return {
    // //     ...state,
    // //     selectedFile: payload,
    // //   };

    case FETCH_FILTER_VALUES_SUCCESS_WEBINAR_ADMIN:
      return {
        ...state,
        isLoading: false,
        filters: payload,
        error: "",
      };

    case FETCH_FILTER_VALUES_FAILURE_WEBINAR_ADMIN:
      return {
        ...state,
        isLoading: false,
        error: payload,
        filters: {},
      };

    case SELECT_FILTER_WEBINAR_ADMIN:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [payload.type]: payload.value,
        },
      };

    case FETCH_ADMIN_WEBINARS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
        webinars: payload,
        isFailure: false,
      };

    // case FETCH_COURSES_FAILURE:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     error: payload,
    //     courses: "",
    //     isFailure: true,
    //   };

    case CHANGE_PAGE_WEBINAR_ADMIN:
      return {
        ...state,
        pageNumber: payload,
      };

    // case SELECT_ARTICLE:
    //   return {
    //     ...state,
    //     articleSelected: payload,
    //   };

    case TOGGLE_ALERT:
      return {
        ...state,
        isAlertOpen: payload.value,
        alertType: payload.type,
        alertMessage: payload.message,
      };

    case FETCH_DROPDOWN_VALUES_REQUEST_WEBINAR_ADMIN:
      return {
        ...state,
        isLoading: true,
        error: "",
      };

    case FETCH_DROPDOWN_VALUES_SUCCESS_WEBINAR_ADMIN:
      return {
        ...state,
        isLoading: false,
        dropDowns: payload,
        error: "",
      };

    case FETCH_DROPDOWN_VALUES_FAILURE_WEBINAR_ADMIN:
      return {
        ...state,
        isLoading: false,
        error: payload,
        dropDowns: {},
      };
    default:
      return state;
  }
};

export default WebinarsAdminReducer;

