export const classes = {
  fileSelectionHeading: { fontWeight: "bold" },
  fileSelectionDiv: {
    textAlign: "center",
    border: "2px dashed #250996",
    borderRadius: "10px",
    fontSize: "95%",
    padding: "1%",
  },
  fileSelectionDivWithFileSelected: {
    textAlign: "center",
    border: "2px dashed green",
    borderRadius: "10px",
    fontSize: "95%",
    padding: "1%",
  },
  browseSpan: {
    textDecoration: "underline",
    color: "#250996",
    cursor: "pointer",
  },
  browseSpanWithFileSelected: {
    textDecoration: "underline",
    color: "green",
    cursor: "pointer",
  },
  divider: { margin: "4% 0", width: "100%" },
};
