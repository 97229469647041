export const FETCH_FILTER_VALUES_FAILURE_WEBINAR_VET =
  "FETCH_FILTER_VALUES_FAILURE_WEBINAR_VET";
export const FETCH_FILTER_VALUES_REQUEST_WEBINAR_VET =
  "FETCH_FILTER_VALUES_REQUEST_WEBINAR_VET";
export const FETCH_FILTER_VALUES_SUCCESS_WEBINAR_VET =
  "FETCH_FILTER_VALUES_SUCCESS_WEBINAR_VET";
export const SELECT_FILTER_WEBINAR_VET = "SELECT_FILTER_WEBINAR_VET";
// export const FETCH_COURSES_FAILURE = "FETCH_COURSES_FAILURE";
// export const FETCH_COURSES_REQUEST = "FETCH_COURSES_REQUEST";
export const FETCH_WEBINARS_VET_SUCCESS = "FETCH_WEBINARS_VET_SUCCESS";
export const CHANGE_PAGE_WEBINAR_VET = "CHANGE_PAGE_WEBINAR_VET";
// export const EDIT_COURSE_FAILURE = "EDIT_COURSE_FAILURE";
// export const EDIT_COURSE_REQUEST = "EDIT_COURSE_REQUEST";
// export const EDIT_COURSE_SUCCESS = "EDIT_COURSE_SUCCESS";
// export const DELETE_COURSE_FAILURE = "DELETE_COURSE_FAILURE";
// export const DELETE_COURSE_REQUEST = "DELETE_COURSE_REQUEST";
// export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
// export const CHANGE_COURSE_STATUS_REQUEST = "CHANGE_COURSE_STATUS_REQUEST";
// export const CHANGE_COURSE_STATUS_SUCCESS = "CHANGE_COURSE_STATUS_SUCCESS";
// export const CHANGE_COURSE_STATUS_FAILURE = "CHANGE_COURSE_STATUS_FAILURE";
// export const TOGGLE_ALERT = "TOGGLE_ALERT";
export const CLEAR_VET_WEBINARS_DATA = "CLEAR_VET_WEBINARS_DATA";
export const FETCH_VET_WEBINARS_DATA = "FETCH_VET_WEBINARS_DATA";
export const SET_VET_WEBINARS_DATA = "SET_VET_WEBINARS_DATA";
export const SET_VET_WEBINARS_DATA_PAGINATION = "SET_VET_WEBINARS_DATA_PAGINATION"
export const SET_CURRENT_VET_WEBINAR = "SET_CURRENT_VET_WEBINAR"
export const TOGGLE_VET_WEBINAR_ENROLLMENT_STATUS="TOGGLE_VET_WEBINAR_ENROLLMENT_STATUS"