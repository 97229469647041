import React, { useEffect } from "react";
import ReactPlayer from "react-player";
import { registerWebinar } from "../../Redux/Middlewares/WebinarsVet/registerWebinarMiddleware";
import { connect } from "react-redux";
import { Button, Grid } from "@mui/material";
import { Redirect } from "react-router-dom";
import { TOGGLE_VET_WEBINAR_ENROLLMENT_STATUS } from "../../Redux/ActionTypes/webinarsVetActionTypes";
import Webinar from "./Webinar";
import { Box } from "@mui/system";
import { TOGGLE_BACK_BUTTON } from "../../Redux/ActionTypes/NavbarActionTypes"

const getContentUI = (heading, data) => {
  return (
    <div style={{ marginBottom: "20px" }}>
      <span>
        <b>{heading}</b>
      </span>
      <br />
      <span>{data}</span>
      <br />
    </div>
  );
};

const WebinarDetail = ({ currentWebinar, registerWebinar, toggleVetWeinarStatus, enrollmentStatus, toggleBackButton }) => {


  useEffect(() => {
    toggleBackButton(true)
    return () => {
      toggleVetWeinarStatus(false)
      toggleBackButton(false)
    }
  }, [])

  if (!currentWebinar) {
    return (<Redirect to="/webinars" />)
  }


  return (<>
    <Box sx={{ padding: 1, marginTop: 2, marginBottom: 6 }}>
      <span style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <b>{currentWebinar.webinar_title}</b>
      </span>
      {currentWebinar.pastWebinar ? (currentWebinar.sas_recordedvideo_path ?
        <Grid container sx={{ paddingLeft: {xs:1,md:40}, paddingRight: {xs:1,md:40}, paddingBottom: 0 }}
          style={{
            // height: "350px",
            // backgroundImage: `url("https://picsum.photos/350/350")`,
            display: "flex",
            justifyContent: "center",
            marginBottom: "40px",
            marginTop: "10px"
          }}
        >
          <Grid container>
            <ReactPlayer
              config={{ file: { attributes: { controlsList: 'nodownload' } } }}
              onContextMenu={e => e.preventDefault()}
              width="100%" height="100%" controls={true}

              url={currentWebinar.sas_recordedvideo_path} />
          </Grid>
        </Grid> : <span style={{ display: "flex", justifyContent: "center", marginBottom: "40px" }}>No video present for this webinar yet</span>) :
        <Grid container justifyContent="center" sx={{ paddingLeft: 2, overflow: "hidden" }}> <img
          src={currentWebinar.sas_webinarimage_path}
          style={{
            height: "350px",
            width: 600,
            display: "flex",
            justifyContent: "center",
            marginBottom: "40px",
            marginTop: "10px",
            objectFit: "fill"
          }}
        >
          {/* <img src={currentWebinar.sas_webinarimage_path} /> */}
        </img></Grid>}
      <Grid container justifyContent="center">
        <Grid item>
          <Grid container justifyContent="left">
            {getContentUI("Speaker Name", currentWebinar.speaker && currentWebinar.speaker.length > 0 ?
              currentWebinar.speaker.map(item => item.speaker_name).join(",") : "Anonymous")}
          </Grid>
          <Grid container justifyContent="left">
            {getContentUI("Organised By", currentWebinar.veterinary_association ? currentWebinar.veterinary_association : "MARS")}
          </Grid>
          <Grid container justifyContent="left">
            {getContentUI("Date and Time", <span>{currentWebinar.webinar_date} <span style={{ fontWeight: 600 }}>
              {currentWebinar.pastWebinar ? "Started at" : "Starts at:"}</span> {currentWebinar.webinar_start_time} UTC</span>)}
          </Grid>
          <Grid container justifyContent="left">
            {getContentUI(
              "Webinar Description",
              currentWebinar.webinar_description
            )}
          </Grid>
        </Grid>
      </Grid>

    </Box>
    {!currentWebinar.pastWebinar && <> {enrollmentStatus || currentWebinar.register === "Y" ?
      <><Button
        variant="contained"
        sx={{ position: "fixed", bottom: 0, zIndex: 1, width: "100%", height: 50, textTransform: "none", display: { md: "none", xs: "flex" } }}


      >
        Enrolled
      </Button>
        <Grid container justifyContent="center"><Button
          variant="contained"
          sx={{ position: "fixed", bottom: 0, zIndex: 1, width: "30%", height: 50, textTransform: "none", display: { md: "flex", xs: "none" } }}


        >
          Enrolled
        </Button>
        </Grid>

      </> :
      <>
        <Button
          variant="contained"
          sx={{ position: "fixed", bottom: 0, zIndex: 1, width: "100%", height: 50, textTransform: "none", display: { md: "none", xs: "flex" } }}
          onClick={() => registerWebinar(currentWebinar.webinar_id, "register")}

        >
          Enroll
        </Button>
        <Grid container justifyContent="center">
          <Button
            variant="contained"
            sx={{ position: "fixed", bottom: 0, zIndex: 1, width: "30%", height: 50, textTransform: "none", display: { md: "flex", xs: "none" } }}
            onClick={() => registerWebinar(currentWebinar.webinar_id, "register")}

          >
            Enroll
          </Button>
        </Grid>
      </>}
    </>}
  </>
  );


};

const mapStateToProps = (state) => ({
  // webinarList: state.WebinarsVetReducer.webinars,
  // pageNumber: state.WebinarsVetReducer.pageNumber,
  // filters: state.WebinarsVetReducer.filters,
  // selectedFilters: state.WebinarsVetReducer.selectedFilters,
  currentWebinar: state.WebinarsVetReducer.currentWebinar,
  enrollmentStatus: state.WebinarsVetReducer.enrollmentStatus,
  // isLoading: state.WebinarsVetReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  registerWebinar: (id, flag) => dispatch(registerWebinar(id, flag)),
  toggleVetWeinarStatus: (val) => dispatch({ type: TOGGLE_VET_WEBINAR_ENROLLMENT_STATUS, payload: val }),
  toggleBackButton: (val) => dispatch({ type: TOGGLE_BACK_BUTTON, payload: { backButton: val } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebinarDetail);


