import React, { useEffect } from "react";
import { WebinarVetCard } from "../Utils/WebinarCard";
import { Box, CircularProgress, Grid, Pagination, Typography, Modal, IconButton, TextField, Button, InputAdornment, Chip } from "@mui/material";
import { connect } from "react-redux";
import { changePage } from "../../Redux/ActionCreators/webinarsAdminActionCreators";
import { getWebinars } from "../../Redux/Middlewares/WebinarsVet/getWebinarsMiddleware";
import { useHistory } from "react-router";
import { addWebinarQuery } from "../../Redux/Middlewares/WebinarsVet/addWebinarQueryMiddleware";
import { setVetCurrentWebinar } from "../../Redux/ActionCreators/webinarsVetActionCreators";
import { webinarAttendance } from "../../Redux/Middlewares/WebinarsVet/webinarAttendance";
import SearchIcon from '@mui/icons-material/Search';

import CloseIcon from '@mui/icons-material/Close';
import FilterAltOutlined from "@mui/icons-material/FilterAltOutlined";

const RegisteredWebinar = ({
  pageNumber,
  getWebinars,
  webinarList,
  changePage,
  selectedFilters,
  isLoading,
  addWebinarQuery,
  setVetCurrentWebinar,
  currentWebinar,
  searchText,
  setSearchText,
  webinarAttendance,
  handleClickOpen
}) => {
  const history = useHistory();


  const [currentSelection, setCurrentSelection] = React.useState(null);

  const [queryModal, setQueryModal] = React.useState(false);
  const [queryText, setQueryText] = React.useState("");
  const [queryError, setQueryError] = React.useState("");

  const handlePageChange = (event, value) => {
    // changePage(value);
    getWebinars(requestObj, value);
  };

  const handleApplyFiltersClick = () => {
    getWebinars(requestObj);
  };

  const onPostQueryButtonClick = () => {
    history.push("/postQuery");
  };
  let requestObj = {
    "webinar_speaker": selectedFilters && selectedFilters.creator && selectedFilters.creator.length > 0 ? selectedFilters.creator.map(item => item.speaker_name) : [],
    "webinar_category": selectedFilters && selectedFilters.category && selectedFilters.category.length > 0 ? selectedFilters.category.map(item => item.category_name) : [],
    "webinar_species": selectedFilters && selectedFilters.species && selectedFilters.species.length > 0 ? selectedFilters.species.map(item => item.value) : [],
    "webinar_language": [],
    "search_value": searchText ? [searchText] : [],
    "webinar_type": "register",
    "show_content_from": {
      "fixed": selectedFilters && selectedFilters.show_content_from.value ? selectedFilters.show_content_from.value : "All time",
      "start_date": null,
      "end_date": null
    }
  }
  useEffect(() => {
    requestObj.search_value = ""
    getWebinars(requestObj);
    return () => {
      setSearchText("")
    }
  }, []);

  if (isLoading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '50vh' }}
      >

        <Grid item xs={3}>
          <CircularProgress size={100} />
        </Grid>

      </Grid>
    )


  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: "100%", md: 400 },
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: 1,
    boxShadow: 24,
    p: 2,
  };
  const handleCloseModal = () => { setQueryError(""); setQueryText(""); setQueryModal(false) };
  const handleQuery = () => {
    if (queryText) {
      addWebinarQuery(currentWebinar.webinar_id, currentWebinar.webinar_category, queryText)
      setQueryError("")
      setQueryText("")
      setQueryModal(false)
    }
    else {
      setQueryError("Please enter a query")
    }

  }


  return (
    <>
      <Grid container xs={12} sx={{ marginBottom: 4, display: { md: "none", xs: "flex" } }}>
        <Grid container xs={8}>
          <TextField
            sx={{ marginTop: 0, }}
            fullWidth
            id="input-with-icon-textfield"
            value={searchText}
            placeholder="Search webinars,authors..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon
                    onClick={() => handleApplyFiltersClick()}
                  />
                </InputAdornment>
              ),
            }}
            variant="standard"
            size="large"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Grid>
        <Grid container justifyContent="end" xs={4} sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton onClick={handleClickOpen}><FilterAltOutlined />  <Chip label={selectedFilters.category.length + selectedFilters.creator.length + selectedFilters.species.length} size="small" /></IconButton>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        {webinarList &&
          webinarList.length > 0 ?
          webinarList.map((element, index) => (
            <Grid item xs={12} md={3}>
              <WebinarVetCard
                setQueryModal={setQueryModal}
                setVetCurrentWebinar={setVetCurrentWebinar}
                webinar={element}
                index={index}
                showButton="Join"
                webinarAttendance={webinarAttendance}
              />
            </Grid>
          ))
          : <div style={{marginLeft: 20}}>No registered webinars found!</div>
        }
      </Grid>

      <Box sx={{ marginTop: 2, marginBottom: 6 }}>{webinarList && webinarList.length > 0 && (
        (Math.ceil(Number(webinarList.length) / 10) > 1 && <Pagination
          count={Math.ceil(Number(webinarList.length) / 10)}
          page={pageNumber}
          onChange={handlePageChange}
          sx={{ margin: "3% 0", justifyContent: "center", display: "flex", }}
          color="secondary"
          hidePrevButton
          hideNextButton
        />)
      )}
      </Box>

      <Modal
        open={queryModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container >
            <Grid container item xs={12} sm={12}>
              <Grid container justifyContent="right" item xs={12} sm={12}>
                <IconButton color="primary" onClick={handleCloseModal}>
                  <CloseIcon sx={{ fontSize: 35 }} />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography sx={{ marginBottom: 1, color: "#0f01a0", fontSize: 26, fontWeight: 600 }} id="modal-modal-title"  >
                Add a new query
              </Typography>
              {queryError && <Typography sx={{ marginBottom: 1, color: "red" }} id="modal-modal-title"  >
                {queryError}
              </Typography>}
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="outlined-multiline-static"
                inputProps={{ maxLength: 300 }}
                fullWidth
                onChange={(e) => setQueryText(e.target.value)}
                multiline
                rows={4}
                placeholder="Type your query here"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button style={{ textTransform: 'none' }} fullWidth onClick={handleQuery} sx={{ marginTop: 1 }} variant="contained">Submit</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  webinarList: state.WebinarsVetReducer.webinars,
  pageNumber: state.WebinarsVetReducer.pageNumber,
  filters: state.WebinarsVetReducer.filters,
  selectedFilters: state.WebinarsVetReducer.selectedFilters,
  courses: state.WebinarsVetReducer.courses,
  isLoading: state.WebinarsVetReducer.isLoading,
  currentWebinar: state.WebinarsVetReducer.currentWebinar,
});

const mapDispatchToProps = (dispatch) => ({
  getWebinars: (obj, pageNumber) => dispatch(getWebinars(obj, pageNumber)),
  changePage: (pageNumber) => dispatch(changePage(pageNumber)),
  setVetCurrentWebinar: (obj) => dispatch(setVetCurrentWebinar(obj)),
  addWebinarQuery: (id, category, query) => dispatch(addWebinarQuery(id, category, query)),
  webinarAttendance: (id) => dispatch(webinarAttendance(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisteredWebinar);
