import React, { useState, useEffect } from "react";
import { connect } from "react-redux"
import { useHistory, withRouter } from "react-router-dom";
import { Box, Paper, Typography, Divider, Grid, TextField, Button, Select, MenuItem } from '@mui/material';
import { createProfile } from "../Redux/Middlewares/createProfileMiddleware"
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { profileFormcloseRequest } from "../Redux/ActionCreators/NavbarActionCreators";
import { styled } from '@mui/material/styles';




const SignupForm = ({ createProfile, closeProfile }) => {

  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [occupation, setOccupation] = useState("");
  const [clinicCodeMars, setClinicCodeMars] = useState("");
  const [regNo, setRegNo] = useState("");
  const [clinicPinCode, setClinicPinCode] = useState("");
  const [clinicAddress, setClinicAddress] = useState("");
  const [clinicCity, setClinicCity] = useState("");
  const [clinicState, setClinicState] = useState("");
  const [clinicName, setClinicName] = useState("");
  const [file, setFile] = useState(undefined);
  const [fileName, setFileName] = useState("");
  const [countryCode, setCountryCode] = useState("");


  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showError, setShowError] = useState(false);

  const history = useHistory();


  const handleChange = (e) => {

    if (e.target.id === "fullName") {
      if (e.target.value.length < 30)
        setFullName(e.target.value)
    }
    if (e.target.id === "phoneNumber") {
      if ((!isNaN(e.target.value)) && e.target.value.length <= 10)
        setPhoneNumber(e.target.value)
    }

    if (e.target.id === "clinicCodeMars") {
      if ((!isNaN(e.target.value)) && e.target.value.length < 15)
        setClinicCodeMars(e.target.value)
    }
    if (e.target.id === "regNo") {
      if ((!isNaN(e.target.value)) && e.target.value.length < 15)
        setRegNo(e.target.value)
    }
    if (e.target.id === "clinicPinCode") {
      if ((!isNaN(e.target.value)) && e.target.value.length < 15)
        setClinicPinCode(e.target.value)
    }
    if (e.target.id === "clinicAddress") {
      if (e.target.value.length < 30)
        setClinicAddress(e.target.value)
    }
    if (e.target.id === "clinicCity") {
      if (e.target.value.length < 30)
        setClinicCity(e.target.value)
    }
    if (e.target.id === "clinicName") {
      if (e.target.value.length < 30)
        setClinicName(e.target.value)
    }

  };

  const handleFile = (item) => {
    let selectedFiles = item.target.files
   
    if (selectedFiles.length > 0)
      setFile(selectedFiles[0])
    let nameArr = selectedFiles[0].name.split(".")
    let modFileName = nameArr[0].slice(0, 6) + "." + nameArr[1]
    setFileName(modFileName)
  }

  const handleClinicState = (e) => {
    setClinicState(e.target.value)
  }

  const handleOccupation = (e) => {
    setOccupation(e.target.value)
  }
  const handleCountryCode = (e) => {
    setCountryCode(e.target.value)
  }

  const handleSubmit = () => {

    let profileObj = {
      email: fullName,
      full_name: "test1",
      phone_number: countryCode + phoneNumber,
      occupation: occupation,
      clinic_code: clinicCodeMars,
      registration_number: regNo,
      clinic_pincode: clinicPinCode,
      clinic_address: clinicAddress,
      clinic_city: clinicCity,
      clinic_name: clinicName,
      business_card_url: file


    }
    if (fullName && phoneNumber && occupation && clinicCodeMars && regNo
      && clinicPinCode && clinicAddress && clinicCity && clinicName && file) {

      
      createProfile(profileObj)
    }



  }

  const paperLayout2 = {
    width: 720,
    height: 950,
    // margin: "10px auto"
  }
  // const paperLayout1 = {
  //   padding: "12px 12px",
  //   width: 750,
  //   height: 870,
  //   margin: "0px auto",


  // }
  const buttonStyle = { width: 270, height: 50 }
  const base = {
    position: 'absolute',
    top: '80%',
    left: '40%',
    transform: 'translate(-50%, -50%)',
    width: 400,
  }

  const Input = styled('input')({
    display: 'none',
  });

  const commonStyles = {



    border: 1,
    width: "2rem",
    height: "2rem"
  };



  return (
    <Grid sx={base}>
      {/* <Paper style={paperLayout1} variant="outlined" square > */}
      <Paper elevation={5} style={paperLayout2} >
        <Box ml={85}><IconButton onClick={() => closeProfile()} >
          <CloseIcon />
        </IconButton>
        </Box>

        <Grid align="center">



          <Box ><Typography variant="h5" >Vet 2 Vet</Typography></Box>
          <Box mt={2}><Typography variant="body2" >Sign up</Typography></Box>

          <Box

          >
            <Divider textAlign="left"><Typography align="left" variant="h1" >Personal details </Typography></Divider>

            <Box mb={8}>
              <Box sx={{ display: "flex" }}>
                <Box mt={2} ml={10}><Typography align="left" variant="h6" >Fullname </Typography></Box>
                <Box mt={2} ml={30}><Typography align="left" variant="h6" >Phone no. (Preferable WhatsApp) </Typography></Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box>
                  <TextField sx={{ width: "30ch" }} InputProps={{ disableUnderline: true }} style={{ marginLeft: 80 }}
                    size="small"
                    placeholder="Enter your full name"
                    id="fullName"
                    type="name"
                    value={fullName}
                    onChange={handleChange}
                    variant="filled"


                  />
                </Box>

                <Box sx={{ width: 72, marginTop: 0, marginLeft: 4, maxWidth: '100%', }}>
                  <Select disableUnderline
                    variant="filled"
                    fullWidth
                    displayEmpty
                    size="small"

                    id="occupation"
                    value={countryCode}
                    renderValue={(countryCode) => {
                      if (!countryCode) {
                        return <Typography align="left" variant="body2" >+91</Typography>
                      }

                      return countryCode;
                    }}
                    onChange={handleCountryCode}
                  >

                    <MenuItem value={"+63"}>+63</MenuItem>
                    <MenuItem value={"+91"}>+91</MenuItem>



                  </Select>
                </Box>

                <TextField placeholder="Enter mobile" sx={{ marginLeft: 2, width: "20ch" }} onChange={handleChange} value={phoneNumber} inputProps={{ style: { textAlign: "right" } }} InputProps={{ disableUnderline: true }} size="small" variant="filled" id="phoneNumber" />


              </Box>

              <Box sx={{ display: "flex" }}>
                <Box mt={2} ml={10}><Typography align="left" variant="h6" >Occupation </Typography></Box>
                <Box mt={2} ml={28}><Typography align="left" variant="h6" >Clinic code with Mars(optional) </Typography></Box>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Box ml={10} sx={{ width: 260, maxWidth: '100%', }}>

                  <Select disableUnderline
                    variant="filled"
                    fullWidth
                    displayEmpty
                    size="small"

                    id="occupation"
                    value={occupation}
                    renderValue={(occupation) => {
                      if (!occupation) {
                        return <Typography align="left" variant="body2" >Select Occupation</Typography>
                      }

                      return occupation;
                    }}
                    onChange={handleOccupation}
                  >

                    <MenuItem value={"Government Vet"}>Government Vet</MenuItem>
                    <MenuItem value={"Private Practitioner"}>Private Practitioner</MenuItem>
                    <MenuItem value={"Student"}>Student</MenuItem>
                    <MenuItem value={"Faculty Member"}>Faculty Member</MenuItem>

                  </Select>

                </Box>
                <Box ml={4} mb={0}>
                  <TextField
                    sx={{ width: "30ch" }}
                    inputProps={{ style: { textAlign: "right" } }}
                    InputProps={{ disableUnderline: true }}
                    size="small"
                    id="clinicCodeMars"
                    placeholder="Enter your clinic code"
                    value={clinicCodeMars}
                    onChange={handleChange}
                    variant="filled"

                  />
                </Box>

              </Box>


              <Box sx={{ display: "flex" }}>
                <Box mt={2} ml={10}><Typography align="left" variant="h6" >Registration Number(optional) </Typography></Box>

              </Box>
              <TextField InputProps={{ disableUnderline: true }} style={{ marginRight: 300 }}
                sx={{ width: "30ch" }}
                inputProps={{ style: { textAlign: "right" } }}
                size="small"
                id="regNo"
                value={regNo}
                type="email"
                placeholder="Enter registration number"
                onChange={handleChange}
                variant="filled"

              />
            </Box>




            <Divider textAlign="left"><Typography align="left" variant="h1" >Clinic/Hospital Details </Typography></Divider>


            <Box sx={{ display: "flex" }}>
              <Box mt={2} ml={10}><Typography align="left" variant="h6" >Clinic pincode</Typography></Box>
              <Box mt={2} ml={23}><Typography align="left" variant="h6" >Clinic Business Card(optional) </Typography></Box>
            </Box>

            <TextField InputProps={{ disableUnderline: true }} style={{ marginRight: 30 }}
              size="small"
              id="clinicPinCode"
              placeholder="Enter your clinic pincode"
              sx={{ width: "30ch" }}
              inputProps={{ style: { textAlign: "right" } }}
              value={clinicPinCode}
              type="email"
              onChange={handleChange}
              variant="filled"

            />

            <label >
              <Input onChange={handleFile} accept="image/*" id="contained-button-file" type="file" />
              <Button component="span" variant="contained" size="large" style={{ marginTop: 8, marginRight: 50, width: 250, color: "black", backgroundColor: "#F0F0F0" }}>
                Upload File
              </Button>
            </label>

            <Box ml={15}>
              <Typography variant="body2" >{fileName}</Typography>
            </Box>


            {/* <TextField
                InputProps={{ disableUnderline: true }}
                size="small"
                id="clinicAddress"
                type="phoneNumber"
                value={clinicAddress}
                onChange={handleChange}
                variant="filled"

              /> */}
            <Box sx={{ display: "flex" }}>
              <Box mt={2} ml={10}><Typography align="left" variant="h6" >Clinic Name </Typography></Box>
              <Box mt={2} ml={25}><Typography align="left" variant="h6" >Clinic Address</Typography></Box>
            </Box>
            <Box sx={{ display: "flex" }}>

              <Box ml={8} mb={0}>
                <TextField
                  sx={{ width: "30ch" }}
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  id="clinicName"
                  value={clinicName}
                  placeholder="Enter your clinic name"

                  onChange={handleChange}
                  variant="filled"

                />
              </Box>
              <Box ml={3} mb={0}>
                <TextField
                  sx={{ width: "30ch" }}
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  id="clinicAddress"
                  value={clinicAddress}
                  placeholder="Enter building name,street..."

                  onChange={handleChange}
                  variant="filled"

                />
              </Box>

            </Box>
            <Box mt={2} sx={{ display: "flex" }}>
              <Box mt={2} ml={10}><Typography align="left" variant="h6" >City </Typography></Box>
              {countryCode !== "+63" && (<Box mt={2} ml={32}><Typography align="left" variant="h6" >State</Typography></Box>)}
            </Box>

            <Box sx={{ display: "flex" }}>

              <Box ml={8} mb={0}>
                <TextField
                  sx={{ width: "30ch" }}
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  id="clinicCity"
                  placeholder="Enter City"
                  value={clinicCity}
                  onChange={handleChange}
                  variant="filled"

                />
              </Box>
              {/* STATE UI BLOCK */}
              {
                countryCode !== "+63" && (<Box ml={3} mt={0} sx={{ width: 260, maxWidth: '100%', }}>

                  <Select disableUnderline
                    variant="filled"
                    fullWidth
                    displayEmpty
                    size="small"

                    id="clinicState"
                    value={clinicState}
                    renderValue={(clinicState) => {
                      if (!clinicState) {
                        return <Typography align="left" variant="body2" >Enter State</Typography>
                      }

                      return clinicState;
                    }}
                    onChange={handleClinicState}
                  >
                    {
                      ["Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh",
                        "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jammu & Kashmir", "Jharkhand",
                        "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya",
                        "Mizoram", "Nagaland", "Orissa", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu",
                        "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal"].map(
                          (item) => (<MenuItem value={item}>{item}</MenuItem>)
                        )
                    }



                  </Select>

                </Box>)
              }


            </Box>


            <Box mt={4}>
              <Button style={buttonStyle} onClick={handleSubmit} fullWidth variant="contained">Sign up</Button>
            </Box>
            <Typography sx={{marginTop:5,color:"red"}} variant="h3" >* For your reference please note that the registration details will change based on the country selected  </Typography>
          </Box>
        </Grid>
      </Paper>

      {/* </Paper> */}
    </Grid>


  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.authenticationReducer.isAuthenticated
})


const mapDispatchToProps = dispatch => {
  return {
    closeProfile: () => dispatch(profileFormcloseRequest()),
    createProfile: (item) => dispatch(createProfile(item))


  }
}


export default connect(mapStateToProps, mapDispatchToProps)(SignupForm)