import axios from "axios";

import {
    FETCH_MANAGEMENT_DATA, SET_MANAGEMENT_DATA, MANAGEMENT_DATA_FAIL,
    FETCH_MANAGEMENT_FILTERS,
    SET_MANAGEMENT_FILTERS,
    MANAGEMENT_FILTERS_FAIL,
    SET_SELECTED_FILTERS,
    CLEAR_MANAGEMENT_FILTER_DATA,
    CLEAR_MANAGEMENT_DATA,
    SET_USER_MANAGEMENT_NAVIGATION,
    SET_USER_MANAGEMENT_LOADING_FALSE,
    SET_CATEGORIES_DATA,
    SET_USER_MANAGEMENT_CATEGORIES_NAVIGATION,
    SET_TAB_VALUE,
    SET_EXPORT_DATA
} from "../../ActionTypes/managementActionTypes";

import { toggleGlobalAlert } from "../../ActionCreators/alertsActionCreators";






export const getManagementFilters = () => (dispatch) => {

    let GET_FILTER_VALUES_URL =
        process.env.REACT_APP_API_URL + "/usermanagement/admin/homepagedropdown/";

    dispatch({ type: FETCH_MANAGEMENT_FILTERS });
    axios
        .get(GET_FILTER_VALUES_URL)
        .then((response) => {
            if (response.status === 200) {


                dispatch({ type: SET_MANAGEMENT_FILTERS, payload: response.data });
            }

            else {
                
                dispatch(toggleGlobalAlert("warning", "Failed to fetch filters", true));

            }
        })
        .catch((error) => {
            dispatch(toggleGlobalAlert("warning", "Failed to fetch filters", true));
        });
};

export const getManagementData = (reqObj, page = 1) => (dispatch) => {

    let GET_FILTER_VALUES_URL =
        process.env.REACT_APP_API_URL + `/usermanagement/admin/user_homepage/?page=${page}`;

    dispatch({ type: FETCH_MANAGEMENT_DATA });
    axios
        .post(GET_FILTER_VALUES_URL, reqObj)
        .then((response) => {
            if (response.status === 200) {


                dispatch({ type: SET_MANAGEMENT_DATA, payload: response.data.results });
                dispatch({
                    type: SET_USER_MANAGEMENT_NAVIGATION, payload: {
                        current: page, total: response.data.count,
                        pendingUsers: response.data.pending_users
                    }
                })
            }

            else {
                
                dispatch({ type: MANAGEMENT_DATA_FAIL })
                dispatch(toggleGlobalAlert("warning", "Failed to fetch users' data", true));

            }
        })
        .catch((error) => {
            dispatch({ type: MANAGEMENT_DATA_FAIL })
            dispatch(toggleGlobalAlert("warning", "Failed to fetch users' data", true));
        });
};

export const getExportData = () => (dispatch) => {

    let GET_EXPORT_DATA_URL =
        process.env.REACT_APP_API_URL + `/usermanagement/admin/vetdata/`;

    dispatch({ type: FETCH_MANAGEMENT_DATA });
    axios
        .get(GET_EXPORT_DATA_URL)
        .then((response) => {
            if (response.status === 200) {


                dispatch({ type: SET_EXPORT_DATA, payload: response.data });
                
                dispatch({ type: MANAGEMENT_DATA_FAIL })
            }

            else {
                
                dispatch({ type: MANAGEMENT_DATA_FAIL })
                dispatch(toggleGlobalAlert("warning", "Failed to exportable data", true));

            }
        })
        .catch((error) => {
            dispatch({ type: MANAGEMENT_DATA_FAIL })
            dispatch(toggleGlobalAlert("warning", "Failed to fetch exportable data", true));
        });
};


export const vetValidation = (userListObj, reqobj) => async dispatch => {


    try {


        let url = process.env.REACT_APP_API_URL + `/usermanagement/admin/registrationstatus/`;



        let result = await axios.post(url, userListObj)

        if (userListObj.status === "Approve") {
            dispatch(toggleGlobalAlert("success", "Selected users have been approved", true));
        }
        else if (userListObj.status === "Reject") {
            dispatch(toggleGlobalAlert("success", "Selected users have been rejected", true));
        }


        let GET_FILTER_VALUES_URL =
            process.env.REACT_APP_API_URL + `/usermanagement/admin/user_homepage/?page=1`;

        let response = await axios.post(GET_FILTER_VALUES_URL, reqobj)

        dispatch({ type: SET_MANAGEMENT_DATA, payload: response.data.results });
        dispatch({
            type: SET_USER_MANAGEMENT_NAVIGATION, payload: {
                current: 1, total: response.data.count,
                pendingUsers: response.data.pending_users
            }
        })


    }
    catch (err) {
        dispatch({ type: SET_USER_MANAGEMENT_LOADING_FALSE })
        dispatch(toggleGlobalAlert("warning", err.response && err.response.data ? err.response.data : "some error occurred", true));
    }
}

export const deleteVet = (userListObj, reqObj) => async dispatch => {


    try {


        let url = process.env.REACT_APP_API_URL + `/usermanagement/admin/delete_vet/`;



        let result = await axios.post(url, userListObj)

        dispatch(toggleGlobalAlert("success", "Selected users have been deleted", true));



        let GET_FILTER_VALUES_URL =
            process.env.REACT_APP_API_URL + `/usermanagement/admin/user_homepage/?page=1`;

        let response = await axios.post(GET_FILTER_VALUES_URL, reqObj)

        dispatch({ type: SET_MANAGEMENT_DATA, payload: response.data.results });
        dispatch({
            type: SET_USER_MANAGEMENT_NAVIGATION, payload: {
                current: 1, total: response.data.count,
                pendingUsers: response.data.pending_users
            }
        })


    }
    catch (err) {
        dispatch({ type: SET_USER_MANAGEMENT_LOADING_FALSE })
        dispatch(toggleGlobalAlert("warning", err.response && err.response.data ? err.response.data : "some error occurred", true));
    }
}


export const getCategories = (reqobj, page = 1) => async dispatch => {


    try {

        dispatch({ type: FETCH_MANAGEMENT_DATA });
        let url = process.env.REACT_APP_API_URL + `/category/category_homepage/?page=${page}`;



        let result = await axios.post(url, reqobj)

        dispatch({ type: SET_CATEGORIES_DATA, payload: result.data.results });
        dispatch({
            type: SET_USER_MANAGEMENT_CATEGORIES_NAVIGATION, payload: {
                current: page, total: result.data.count,
                // pendingUsers: result.data.pending_users
            }
        })
        // dispatch(toggleGlobalAlert("success", "Category successfully added", true));

    }
    catch (err) {
        dispatch({ type: SET_USER_MANAGEMENT_LOADING_FALSE })
        // dispatch(toggleGlobalAlert("warning", err.response && err.response.data ? err.response.data : "some error occurred", true));
    }
}

// export const getCategories = (reqobj, page = 1) => async dispatch => {


//     try {


//         let url = process.env.REACT_APP_API_URL + `/category/category_homepage/?page=${page}`;



//         let result = await axios.post(url, reqobj)

//         dispatch({ type: SET_CATEGORIES_DATA, payload: result.data.results });
//         dispatch({
//             type: SET_USER_MANAGEMENT_CATEGORIES_NAVIGATION, payload: {
//                 current: page, total: result.data.count,
//                 // pendingUsers: result.data.pending_users
//             }
//         })
//         // dispatch(toggleGlobalAlert("success", "Category successfully added", true));

//     }
//     catch (err) {
//         dispatch({ type: SET_USER_MANAGEMENT_LOADING_FALSE })
//         dispatch(toggleGlobalAlert("warning", err.response && err.response.data ? err.response.data : "some error occurred", true));
//     }
// }



export const editCategory = (reqobj, categoryObj) => async dispatch => {


    try {


        let url = process.env.REACT_APP_API_URL + `/category/edit_category/`;



        let result = await axios.post(url, reqobj)

        dispatch(toggleGlobalAlert("success", "Category successfully edited", true));

        url = process.env.REACT_APP_API_URL + `/category/category_homepage/?page=1`;
        result = await axios.post(url, categoryObj)

        dispatch({ type: SET_CATEGORIES_DATA, payload: result.data.results });

        dispatch({
            type: SET_USER_MANAGEMENT_CATEGORIES_NAVIGATION, payload: {
                current: 1, total: result.data.count,
                // pendingUsers: result.data.pending_users
            }
        })


    }
    catch (err) {
        dispatch({ type: SET_USER_MANAGEMENT_LOADING_FALSE })
        dispatch(toggleGlobalAlert("warning", err.response && err.response.data ? err.response.data : "some error occurred", true));
    }
}

export const createCategory = (reqobj, categoryObj) => async dispatch => {


    try {


        let url = process.env.REACT_APP_API_URL + `/category/create_category/`;



        let result = await axios.post(url, reqobj)

        dispatch(toggleGlobalAlert("success", "Category successfully created", true));

        url = process.env.REACT_APP_API_URL + `/category/category_homepage/?page=1`;
        result = await axios.post(url, categoryObj)

        dispatch({ type: SET_CATEGORIES_DATA, payload: result.data.results });

        dispatch({
            type: SET_USER_MANAGEMENT_CATEGORIES_NAVIGATION, payload: {
                current: 1, total: result.data.count,
                // pendingUsers: result.data.pending_users
            }
        })


    }
    catch (err) {
        dispatch({ type: SET_USER_MANAGEMENT_LOADING_FALSE })
        dispatch(toggleGlobalAlert("warning", "Category already exist", true));
    }
}

export const deleteCategory = (id, categoryObj) => async dispatch => {


    try {


        let url = process.env.REACT_APP_API_URL + `/category/delete_category/${id}/`;



        let result = await axios.delete(url)

        dispatch(toggleGlobalAlert("success", "Category successfully deleted", true));

        url = process.env.REACT_APP_API_URL + `/category/category_homepage/?page=1`;
        result = await axios.post(url, categoryObj)

        dispatch({ type: SET_CATEGORIES_DATA, payload: result.data.results });

        dispatch({
            type: SET_USER_MANAGEMENT_CATEGORIES_NAVIGATION, payload: {
                current: 1, total: result.data.count,
                // pendingUsers: result.data.pending_users
            }
        })


    }
    catch (err) {
        dispatch({ type: SET_USER_MANAGEMENT_LOADING_FALSE })
        dispatch(toggleGlobalAlert("warning", err.response && err.response.data ? err.response.data : "some error occurred", true));
    }
}


export const setTabValue = (currTabValue) =>  dispatch => {
    dispatch({ 
            type: SET_TAB_VALUE,
            payload: currTabValue
         });
}







