import {
  CHANGE_PAGE,
  DELETE_FILE_FAILURE,
  DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
  EDIT_FILE_FAILURE,
  EDIT_FILE_REQUEST,
  EDIT_FILE_SUCCESS,
  FETCH_FILTER_VALUES_FAILURE,
  FETCH_FILTER_VALUES_REQUEST,
  FETCH_FILTER_VALUES_SUCCESS,
  FETCH_SEARCH_RESULT_FAILURE,
  FETCH_SEARCH_RESULT_REQUEST,
  FETCH_SEARCH_RESULT_SUCCESS,
  FETCH_UPLOADED_FILES_FAILURE,
  FETCH_UPLOADED_FILES_REQUEST,
  FETCH_UPLOADED_FILES_SUCCESS,
  FILE_UPLOAD_FAILURE,
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_SUCCESS,
  SELECT_ARTICLE,
  SELECT_FILE,
  SELECT_FILTER,
  TOGGLE_ALERT,
} from "../ActionTypes/learningContentAdminActionTypes";

export const fileUploadRequest = () => ({
  type: FILE_UPLOAD_REQUEST,
});

export const fileUploadSuccess = () => ({
  type: FILE_UPLOAD_SUCCESS,
});

export const fileUploadFailure = (error) => ({
  type: FILE_UPLOAD_FAILURE,
  payload: error,
});

export const selectFile = (selectedFile) => ({
  type: SELECT_FILE,
  payload: selectedFile,
});

export const fetchFilterValuesRequest = () => ({
  type: FETCH_FILTER_VALUES_REQUEST,
});

export const fetchFilterValuesSuccess = (filterValues) => ({
  type: FETCH_FILTER_VALUES_SUCCESS,
  payload: filterValues,
});

export const fetchFilterValuesFailure = (error) => ({
  type: FETCH_FILTER_VALUES_FAILURE,
  payload: error,
});

export const selectFilter = (filterType, filterValue) => ({
  type: SELECT_FILTER,
  payload: {
    type: filterType,
    value: filterValue,
  },
});

export const fetchUploadedFilesRequest = () => ({
  type: FETCH_UPLOADED_FILES_REQUEST,
});

export const fetchUploadedFilesSuccess = (uploadedFiles) => ({
  type: FETCH_UPLOADED_FILES_SUCCESS,
  payload: uploadedFiles,
});

export const fetchUploadedFilesFailure = (error) => ({
  type: FETCH_UPLOADED_FILES_FAILURE,
  payload: error,
});

export const changePage = (pageNumber) => ({
  type: CHANGE_PAGE,
  payload: pageNumber,
});

export const selectArticle = (article) => ({
  type: SELECT_ARTICLE,
  payload: article,
});

export const fetchSearchResultRequest = () => ({
  type: FETCH_SEARCH_RESULT_REQUEST,
});

export const fetchSearchResultSuccess = (searchResult) => ({
  type: FETCH_SEARCH_RESULT_SUCCESS,
  payload: searchResult,
});

export const fetchSearchResultFailure = (error) => ({
  type: FETCH_SEARCH_RESULT_FAILURE,
  payload: error,
});

export const editFileRequest = () => ({
  type: EDIT_FILE_REQUEST,
});

export const editFileSuccess = () => ({
  type: EDIT_FILE_SUCCESS,
});

export const editFileFailure = (error) => ({
  type: EDIT_FILE_FAILURE,
  payload: error,
});

export const deleteFileRequest = () => ({
  type: DELETE_FILE_REQUEST,
});

export const deleteFileSuccess = () => ({
  type: DELETE_FILE_SUCCESS,
});

export const deleteFileFailure = (error) => ({
  type: DELETE_FILE_FAILURE,
  payload: error,
});

export const toggleAlert = (type, message, value) => ({
  type: TOGGLE_ALERT,
  payload: { type, message, value },
});
