import axios from "axios";
import {
  fileUploadFailure,
  fileUploadRequest,
  fileUploadSuccess,
  toggleAlert,
} from "../../ActionCreators/learningContentAdminActionCreators";

const FILE_UPLOAD_URL =
  process.env.REACT_APP_API_URL + "/learningcontent/create_content/";

export const fileUpload = (fileUploadOptions) => (dispatch) => {
  dispatch(fileUploadRequest());
  axios
    .post(FILE_UPLOAD_URL, fileUploadOptions, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 201) {
        dispatch(fileUploadSuccess());
        dispatch(toggleAlert("success", "File uploaded successfully !", true));
      } else {
        dispatch(fileUploadFailure("Something went wrong !"));
        dispatch(toggleAlert("error", "Error while upload !", true));
      }
    })
    .catch((error) => {
      dispatch(fileUploadFailure(error.message));
      dispatch(toggleAlert("error", error.message, true));
    });
};
