import {
    FETCH_ARTICLE_LIST, SET_ARTICLE_LIST, FETCH_ARTICLE_USER_DATA, SET_ARTICLE_USER_DATA, CLEAR_ARTICLE_USER_DATA,
    SET_BOOKMARK_LC, SET_RATING_LC, SET_AVG_RATING_LC
} from "../ActionTypes/learningContentActionTypes"

import { SET_QUERY_ADDED_ALERT,CLEAR_QUERY_ADDED_ALERT,SET_GENERIC_ERROR_ALERT } from "../ActionTypes/alertsActionTypes"

import axios from "axios"

//Login 
export const addQueryLC = (moduleId,queryCategory,queryText) => async dispatch => {


    try {
        let reqObj = {
            "module_id": moduleId,
            "content_type": "Learning Content",  
            "query_category": queryCategory, //Medical Paper   <-- sample input
            "query_text": queryText
        }

        // dispatch({ type: FETCH_ARTICLE_USER_DATA })
        let url = process.env.REACT_APP_API_URL+"/learningcontent/vet/add_query/"



        let result = await axios.post(url, reqObj)

        if (result.data) {

            


            dispatch({ type: SET_QUERY_ADDED_ALERT })


        }
        else {
            dispatch({ type: SET_GENERIC_ERROR_ALERT })
        }

        //dispatch(loadUser())

    }
    catch (err) {
        dispatch({ type: SET_GENERIC_ERROR_ALERT })
    }
}

