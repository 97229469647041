import {
    FETCH_ARTICLE_LIST, SET_ARTICLE_LIST, FETCH_ARTICLE_USER_DATA, SET_ARTICLE_USER_DATA, CLEAR_ARTICLE_USER_DATA,
    SET_LEARNING_CONTENT_PAGINATION,SET_LC_ARTICLE_USER_DATA_FAIL
} from "../ActionTypes/learningContentActionTypes"

import { SET_GENERIC_ERROR_ALERT } from "../ActionTypes/alertsActionTypes"
import axios from "axios"

//Login 
export const getLearningContentList = (reqObj,page=1) => async dispatch => {

    
    try {
        dispatch({ type: CLEAR_ARTICLE_USER_DATA })
        dispatch({ type: FETCH_ARTICLE_LIST })
        let url = process.env.REACT_APP_API_URL+`/learningcontent/vet/newsearch/?page=${page}`



        let result = await axios.post(url, reqObj)

        if (result.data) {
            dispatch({ type: SET_ARTICLE_LIST, payload: result.data.results })

           
            dispatch({ type: SET_LEARNING_CONTENT_PAGINATION, payload: {totalCount:result.data.count,
                prev:result.data.previous?page-1:null,
                next:result.data.next?page+1:null,
                current:page
            
            } })


        }
        else {
            dispatch({type:SET_LC_ARTICLE_USER_DATA_FAIL})
            dispatch({ type: SET_GENERIC_ERROR_ALERT })
            dispatch({ type: CLEAR_ARTICLE_USER_DATA })
        }

        //dispatch(loadUser())

    }
    catch (err) {
        dispatch({type:SET_LC_ARTICLE_USER_DATA_FAIL})
        dispatch({ type: SET_GENERIC_ERROR_ALERT })
        dispatch({ type: CLEAR_ARTICLE_USER_DATA })
    }
}

