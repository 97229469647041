import axios from "axios";
import {
    createCourseFailure,
    createCourseRequest,
    createCourseSuccess,
    createEditChapterFailure,
    createEditChapterRequest,
    createEditChapterSuccess,
    createEditLessonFailure,
    createEditLessonRequest,
    createEditLessonSuccess,
    setActiveCourse,
    setCurriculumDetails,
    toggleAlert,
    setPreviewDetails
} from "../../ActionCreators/coursesAdminActionCreators";



export const fetchCurriculumData = (courseId) => (dispatch) => {
    const CREATE_EDIT_COURSE =
        process.env.REACT_APP_API_URL + `/curriculum/admin/getcontent/${courseId}/`;

    dispatch(createEditLessonRequest());
    axios
        .get(CREATE_EDIT_COURSE)
        .then((response) => {
            if (response.status === 200) {
                dispatch(createEditLessonSuccess());

                // dispatch(toggleAlert("success", "option added successfully !", true));


                dispatch(setCurriculumDetails(response.data));
            } else {
                dispatch(createEditLessonFailure("Something went wrong !"));
                dispatch(toggleAlert("error", "Something went wrong !", true));
            }
        })
        .catch((error) => {
            dispatch(createEditLessonFailure(error.message));
            dispatch(toggleAlert("error", error.message, true));
        });
};

export const fetchPreviewData = (courseId) => (dispatch) => {
    const CREATE_EDIT_COURSE =
        process.env.REACT_APP_API_URL + `/curriculum/admin/getcontent/${courseId}/`;


    axios
        .get(CREATE_EDIT_COURSE)
        .then((response) => {
            if (response.status === 200) {


                // dispatch(toggleAlert("success", "option added successfully !", true));


                dispatch(setPreviewDetails(response.data));
            } else {
                dispatch(createEditLessonFailure("Something went wrong !"));
                dispatch(toggleAlert("error", "Something went wrong !", true));
            }
        })
        .catch((error) => {

            dispatch(toggleAlert("error", error.message, true));
        });
};