import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  TextField,
  // Autocomplete,
  InputAdornment,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { LoadingButton } from "@mui/lab";
import { Delete, InsertDriveFile, UploadFile, Edit } from "@mui/icons-material";
import { fileUpload } from "../../../Redux/Middlewares/LearningContentAdmin/fileUploadMiddleware";
import { selectFile } from "../../../Redux/ActionCreators/learningContentAdminActionCreators";
import { selectArticle } from "../../../Redux/ActionCreators/learningContentAdminActionCreators";
import { fileEdit } from "../../../Redux/Middlewares/LearningContentAdmin/editLearningContentMIddleware";
import FileSelection from "../FileSelection/FileSelection";
import { classes } from "./styles";

const filter = createFilterOptions();

const FileDetails = ({
  setIsFileSelected,
  selectedFile,
  initiateFileUpload,
  filters,
  articleSelected,
  selectFile,
  selectArticle,
  isLoading,
  initiateFileEdit,
  isOnFileDetailsPage,
}) => {
  const [isEditFlow, setIsEditFlow] = useState(false);
  // const [isOnFileDetailsPage, setIsOnFileDetailsPage] = useState(false);

  useEffect(() => {
    

    if (
      articleSelected !== undefined &&
      Object.keys(articleSelected).length > 0
    ) {
      setIsEditFlow(true);
      setSelectedFileDetails({
        name: articleSelected.file_title,
        author: articleSelected.author,
        contentType: articleSelected.file_type,
        description: articleSelected.file_description,
        category: articleSelected.file_category,
        country: articleSelected.file_country.split(",").map(item => ({ value: item })),
        species: articleSelected.file_species,
      });
    }
  }, []);

  useEffect(() => {
    
    if (selectedFile !== null)
      if (selectedFile.name !== undefined)
        handleSelectedFileDetailsChange(
          selectedFile.name.replace(".pdf", ""),
          "name"
        );
  }, [selectedFile]);

  const [selectedFileDetails, setSelectedFileDetails] = useState({
    name: "",
    author: "",
    contentType: "",
    description: "",
    category: "",
    country: [],
    species: "",
  });

  const [error, setError] = useState("");

  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const options = [
    { title: "Alpha", year: 1 },
    { title: "Beta", year: 2 },
    { title: "Gamma", year: 3 },
    { title: "Delta", year: 4 },
  ];

  const handleSelectedFileDetailsChange = (value, type) => {
    if (
      (type === "description" && value.length > 150) ||
      (type === "name" && value.length > 150) ||
      (type === "author" && value.length > 100)
    )
      return;
    setSelectedFileDetails({ ...selectedFileDetails, [type]: value });
  };

  const handleSelectedFileDetailsFilterChange = (e, type, value) =>
    setSelectedFileDetails({
      ...selectedFileDetails,
      [type]:
        value === null
          ? ""
          : type === "country" ? value : type === "category"
            ? value.category_name
            : type === "author"
              ? value.author
              : value.value
    });

  const uploadFile = () => {
    if (selectedFileDetails.author === "") return setError("Author");
    else if (selectedFileDetails.contentType === "")
      return setError("'Content Type' field");
    else if (selectedFileDetails.description === "")
      return setError("Description");
    else if (selectedFileDetails.category === "") return setError("Category");
    else if (selectedFileDetails.country === "" || selectedFileDetails.country.length < 1) return setError("Country");
    else if (selectedFileDetails.species === "") return setError("Species");
    else setError("");

    var fileToBeUploaded = new FormData();
    fileToBeUploaded.append(
      "file_title",
      selectedFileDetails.name
      // ? selectedFileDetails.name
      // : selectedFile.name
      // ? selectedFile.name.replace(".pdf", "")
      // : articleSelected.file_title
    );
    fileToBeUploaded.append("author", selectedFileDetails.author);
    fileToBeUploaded.append("file_type", selectedFileDetails.contentType);
    fileToBeUploaded.append(
      "file_description",
      selectedFileDetails.description
    );
    fileToBeUploaded.append("file_category", selectedFileDetails.category);
    fileToBeUploaded.append("file_species", selectedFileDetails.species);
    let countryString = selectedFileDetails.country.map(item => item.value).join(",")
    fileToBeUploaded.append("file_country", countryString);
    fileToBeUploaded.append("file_url", selectedFile);
    if (
      // articleSelected !== undefined &&
      // Object.keys(articleSelected).length > 0
      isEditFlow
    ) {
      // fileToBeUploaded.append("file_url", articleSelected.sas_file_path);
      fileToBeUploaded.append("is_file_updated", selectedFile ? true : false);
      initiateFileEdit(fileToBeUploaded, articleSelected.learning_content_id);
    } else {
      // fileToBeUploaded.append("file_url", selectedFile);
      initiateFileUpload(fileToBeUploaded);
    }
    setTimeout(() => handleDiscardFile(), 3000);
  };

  const handleDeleteClick = () => {
    setOpenDialog(true);
  };

  const handleDiscardFile = () => {
    selectFile(null);
    selectArticle({});
    setIsFileSelected(false);
    setSelectedFileDetails({
      name: "",
      author: "",
      contentType: "",
      description: "",
      category: "",
      country: "",
      species: "",
    });
    setError("");
    setIsEditFlow(false);
  };

  const handleHomeClick = () => {
    handleDiscardFile();
  };

  return (
    <>
      <p>
        <span style={classes.inactiveCrumb} onClick={handleHomeClick}>
          {"Home > "}
        </span>
        <span style={classes.activeCrumb}>File Details</span>
      </p>
      <FileSelection isOnFileDetailsPage={isOnFileDetailsPage} />
      <p className="heading" style={classes.heading}>
        Fill in the details for the uploaded file
      </p>
      <div className="file_details" style={classes.fileDetails}>
        {/* <IconButton
          color="default"
          aria-label="close file selection"
          component="span"
          sx={classes.iconButton}
          onClick={handleDeleteClick}
        >
          <Delete />
        </IconButton> */}
        {/* <p className="title" style={classes.title}>
          {articleSelected !== undefined &&
          Object.keys(articleSelected).length > 0
            ? articleSelected.file_title
            : selectedFile === null || selectedFile.name === undefined
            ? ""
            : selectedFile.name.replace(".pdf", "")}
        </p> */}
        <TextField
          size="small"
          id="file_name_textfield"
          label="Article name"
          // placeholder="Enter article name here"
          variant="outlined"
          className="textfield"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <InsertDriveFile />
              </InputAdornment>
            ),
          }}
          sx={classes.fileName}
          value={selectedFileDetails.name}
          onChange={(e) =>
            handleSelectedFileDetailsChange(e.target.value, "name")
          }
        />
        <div
          className="file_details_input_containers"
          style={classes.fileDetailsInputContainers}
        >
          <TextField
            size="small"
            id="author_name_textfield"
            placeholder="Use commas for multiple authors"
            variant="outlined"
            className="textfield"
            label="Author name"
            sx={classes.textFieldOne}
            value={selectedFileDetails.author}
            onChange={(e) =>
              handleSelectedFileDetailsChange(e.target.value, "author")
            }
          />
          {/* <Autocomplete
            value={selectedFileDetails.author}
            onChange={(e, newValue) => {
              if (typeof newValue === "string") {
                handleSelectedFileDetailsChange(e, "author", newValue);
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                handleSelectedFileDetailsChange(
                  e,
                  "author",
                  newValue.inputValue
                );
              } else {
                handleSelectedFileDetailsChange(e, "author", newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push(inputValue);
              }
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="author_name_filter"
            options={
              Object.keys(filters).length
                ? filters.author.map((obj) => obj.author)
                : []
            }
            getOptionLabel={(option) => option}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            sx={classes.filterOne}
            freeSolo
            renderInput={(params) => <TextField {...params} />}
          /> */}
          <Autocomplete
            size="small"
            disablePortal
            id="file_content_type_filter"
            options={
              Object.keys(filters).length ? filters.file_content_type : []
            }
            renderInput={(params) => (
              <TextField {...params} label="Content type" />
            )}
            getOptionLabel={(option) => option.value || ""}
            sx={classes.filterOne}
            value={{ value: selectedFileDetails.contentType }}
            onChange={(e, value) =>
              handleSelectedFileDetailsFilterChange(e, "contentType", value)
            }
          />
        </div>
        <TextField
          size="small"
          id="description_textfield"
          variant="outlined"
          className="textfield"
          label="Description"
          sx={classes.textFieldTwo}
          value={selectedFileDetails.description}
          onChange={(e) =>
            handleSelectedFileDetailsChange(e.target.value, "description")
          }
        />
        <div
          className="file_details_input_containers"
          style={classes.fileDetailsInputContainers}
        >
          <Autocomplete
            size="small"
            disablePortal
            id="category_filter"
            options={Object.keys(filters).length ? filters.file_category : []}
            renderInput={(params) => (
              <TextField {...params} label="Select category" />
            )}
            getOptionLabel={(option) => option.category_name || ""}
            sx={classes.filterTwo}
            value={{ category_name: selectedFileDetails.category }}
            onChange={(e, value) =>
              handleSelectedFileDetailsFilterChange(e, "category", value)
            }
          />
          <Autocomplete
            size="small"
            disablePortal
            multiple
            id="country_filter"
            options={filters.content_country && Object.keys(filters).length > 0 ? filters.content_country : []}
            renderInput={(params) => (
              <TextField {...params} label="Select country" />
            )}
            getOptionLabel={(option) => option.value || ""}
            sx={classes.filterOne}
            value={selectedFileDetails.country}
            onChange={(e, value) =>
              handleSelectedFileDetailsFilterChange(e, "country", value)
            }
          />
        </div>
        <Autocomplete
          size="small"
          disablePortal
          id="species_filter"
          options={Object.keys(filters).length ? filters.file_species : []}
          renderInput={(params) => (
            <TextField {...params} label="Select species" />
          )}
          getOptionLabel={(option) => option.value || ""}
          sx={classes.filterTwo}
          value={{ value: selectedFileDetails.species }}
          onChange={(e, value) =>
            handleSelectedFileDetailsFilterChange(e, "species", value)
          }
        />
      </div>
      {articleSelected !== undefined &&
        Object.keys(articleSelected).length > 0 ? (
        <LoadingButton
          variant="contained"
          sx={classes.uploadButton}
          onClick={uploadFile}
          endIcon={<Edit />}
          loading={isLoading}
          loadingPosition="end"
        >
          Save Changes
        </LoadingButton>
      ) : (
        <LoadingButton
          variant="contained"
          sx={classes.uploadButton}
          onClick={uploadFile}
          endIcon={<UploadFile />}
          loading={isLoading}
          loadingPosition="end"
        >
          Upload File
        </LoadingButton>
      )}

      {error && <i style={classes.error}>{error} cannot be empty !</i>}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert_dialog_title"
        aria-describedby="alert_dialog_description"
      >
        <DialogContent>
          <DialogContentText
            id="alert_dialog_description"
            sx={classes.dialogContent}
          >
            Do you want to discard this file ?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={classes.dialogActions}>
          <Button onClick={handleDiscardFile} autoFocus variant="contained">
            Yes, discard this file
          </Button>
          <Button onClick={handleCloseDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedFile: state.LearningContentAdminReducer.selectedFile,
  filters: state.LearningContentAdminReducer.filters,
  articleSelected: state.LearningContentAdminReducer.articleSelected,
  isLoading: state.LearningContentAdminReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  initiateFileUpload: (selectedFile) => dispatch(fileUpload(selectedFile)),
  selectFile: (selectedFile) => dispatch(selectFile(selectedFile)),
  selectArticle: (article) => dispatch(selectArticle(article)),
  initiateFileEdit: (selectedFile, fileId) =>
    dispatch(fileEdit(selectedFile, fileId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileDetails);
