import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useHistory, useParams } from "react-router-dom";
import Box from "@mui/material/Box";

import {
    Grid, IconButton, TextField, InputAdornment, Chip, Drawer, MenuItem, Select, OutlinedInput,
    Stack, Button, Paper, Divider,
    FormControlLabel, Radio, Checkbox,

} from "@mui/material"
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { Prompt } from 'react-router';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, Field, Form, useField, FieldArray, useFormik, useFormikContext, ErrorMessage } from "formik";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';


const CoursePreviewQuiz = ({ toggleQuizActivation, quizActive, getQuizDetails, currentSelection, quizLoader, quizData,
    id, currentLessonId, currentChapter, nextSelection, clearQuizData, quizValidation, currentLessonName,
    quizLesson, quizQuestions, quizGrade, contents, setRoot, quizWrongAnswers, quizInput, setTab, previewSequence,
    setPreviewSequence }) => {



    // The state for our timer
    // const [timer, setTimer] = useState('00m:01s');
    const CustomRadio = ({ label, ...props }) => {
        const [field] = useField(props);
        return (
            <FormControlLabel {...field} {...props} control={<Radio />} label={label} />
        );
    };
    const initialValuesArray = {}

    const Ref = useRef(null);

    // const AutoSubmitComponent = ({ timer }) => {
    //     const { values, submitForm } = useFormikContext();
    //     React.useEffect(() => {
    //         // Submit the form imperatively as an effect as soon as form values.token are 6 digits long
    //         if (timer === "00m 00s") {
    //             submitForm();
    //         }
    //     }, [timer, submitForm]);
    //     return null;
    // }




    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 * 60 * 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }


    const startTimer = (e) => {
        let { total, hours, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {

            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the begining of the variable
            // setTimer(
            //     // (hours > 9 ? hours+"h" : '0' + hours +"h") + ' ' +
            //     (minutes > 9 ? minutes + "m" : '0' + minutes + "m") + ' '
            //     + (seconds > 9 ? seconds + "s" : '0' + seconds + "s")
            // )
        }
    }


    // const clearTimer = (e) => {

    //     // If you adjust it you should also need to
    //     // adjust the Endtime formula we are about
    //     // to code next    
    //     setTimer('00m:00s');

    //     // If you try to remove this line the 
    //     // updating of timer Variable will be
    //     // after 1000ms or 1sec
    //     if (Ref.current) clearInterval(Ref.current);
    //     const id = setInterval(() => {
    //         startTimer(e);
    //     }, 1000)
    //     Ref.current = id;
    // }


    const getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if 
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + (quizQuestions * 60));
        return deadline;
    }

    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible

    // We put empty array to act as componentDid
    // mount only
    // useEffect(() => {
    //     if (quizActive === 1) {
    //         clearTimer(getDeadTime());
    //     }

    // }, [quizActive]);

    useEffect(() => {
        getQuizDetails(currentSelection && currentSelection.substring(0, currentSelection.length - 1));

        return () => {

            // clearQuizData()

        }
    }, []);

    // Another way to call the clearTimer() to start
    // the countdown is via action event from the
    // button first we create function to be called
    // by the button
    // const onClickReset = () => {
    //     clearTimer(getDeadTime());
    // }





    if (quizData && quizData.length > 0) {
        for (let i = 0; i < quizData.length; i++) {
            if (!quizData[i].single_select) {
                for (let j = 0; j < quizData[i].quiz_options.length; j++) {
                    initialValuesArray[quizData[i].quiz_options[j].quiz_details_id] = false
                }
            }

        }
    }




    return (<>
        <Grid container xs={12}>
            <Grid item xs={10} md={10}>
                <Typography sx={{ fontSize: 20, fontWeight: 600, marginBottom: 2 }}>{currentLessonName}</Typography>
            </Grid>
            <Grid item xs={10} md={2}>
                <IconButton onClick={() => {
                    setPreviewSequence(previewSequence, null)
                }} ><CancelIcon /></IconButton>
            </Grid>
            <div>
                <Formik initialValues={{}}

                    // validationSchema={Yup.object().shape({})}

                    validate={values => {
                        let errors = {}
                        // if (timer === "00m 00s") {
                        //     return errors
                        // }
                        if (Object.keys(values).filter(item => {
                            if (Array.isArray(values[item])) {
                                if (values[item].length > 0) {
                                    return true
                                }
                                return false
                            }
                            return true
                        }).length != quizQuestions) {
                            errors = { "selectionError": "Please answer all questions" }
                        }


                        // Check the values object, and add custom validation 

                        return errors
                    }}

                    onSubmit={(data, { setSubmitting }) => {


                        clearInterval(Ref.current)
                        let dataArray = []
                        for (let i = 0; i < quizData.length; i++) {
                            let tempArray = []
                            if (quizData[i].single_select) {
                                dataArray.push({ question_id: quizData[i].quiz_id, answer_id: [data[quizData[i].quiz_id + "x"] && Number(data[quizData[i].quiz_id + "x"])] })
                            }
                            else {
                                tempArray = data[quizData[i].quiz_id] ? data[quizData[i].quiz_id].map(item => (Number(item))) : []

                                dataArray.push({ question_id: quizData[i].quiz_id, answer_id: tempArray })
                            }
                        }


                        // let tempSelection = currentSelection.substring(0, currentSelection.length - 1);
                        let reqObj = {
                            "course_id": id,
                            "chapter_id": currentChapter,
                            "lesson_id": currentLessonId,
                            "data": dataArray
                        }

                        quizValidation(reqObj)

                    }}
                >
                    {({ values, errors, isSubmitting, handleSubmit, touched }) => (
                        <Form autoComplete="off">
                            <Box sx={{ overflow: "scroll", width: "100%", height: { xs: "80vh", sm: "60vh" } }}> {
                                quizData && quizData.map((item, index) => {

                                    if (item.single_select) {
                                        return (<Grid container sx={{ borderRadius: 2, border: .5, marginBottom: 4 }} xs={12}>
                                            <Grid item xs={12} sx={{ paddingLeft: 2, marginTop: 1, backgroundColor: "#efeeec" }}>
                                                <Typography sx={{ color: "#0000a0", marginTop: 1, marginBottom: 1, fontSize: 20, fontWeight: 800 }}>{item.question}</Typography>
                                            </Grid>
                                            {
                                                item.sas_ques_image_path &&
                                                <Grid container justifyContent="center" xs={12} sx={{ marginTop: 1 }}><img
                                                    style={{
                                                        height: 200,
                                                        width: 200,

                                                        borderRadius: 10,

                                                    }}
                                                    src={item.sas_ques_image_path}
                                                /></Grid>
                                            }
                                            {item.quiz_options.map((subItem, i) => (
                                                <Grid item xs={12} sx={{ paddingLeft: 2, paddingRight: 2 }}>
                                                    <Field
                                                        as={CustomRadio}
                                                        type="radio"
                                                        name={`${item.quiz_id}x`}
                                                        value={`${subItem.quiz_details_id}`}
                                                        label={<span style={{ color: "black" }}>{subItem.answer_option}</span>}
                                                        key={i}
                                                    />

                                                    {subItem.sas_ans_image_path &&
                                                        <Grid container justifyContent="center" xs={12}><img
                                                            style={{
                                                                height: 200,
                                                                width: 200,

                                                                borderRadius: 10,

                                                            }}
                                                            src={subItem.sas_ans_image_path}
                                                        /></Grid>
                                                    }
                                                    <Divider sx={{ marginBottom: 1, marginTop: 1 }} />

                                                </Grid>
                                            ))}

                                        </Grid>)
                                    }
                                    else {
                                        return (<Grid container sx={{ borderRadius: 2, border: .5, marginBottom: 4 }} xs={12}>
                                            <Grid item xs={12} sx={{ paddingLeft: 2, marginTop: 1, backgroundColor: "#efeeec" }}>
                                                <Typography sx={{ color: "#0000a0", marginTop: 1, marginBottom: 1, fontSize: 20, fontWeight: 800 }}>{item.question}</Typography>
                                            </Grid>
                                            {
                                                item.sas_ques_image_path &&
                                                <Grid container justifyContent="center" xs={12} sx={{ marginTop: 1 }}><img
                                                    style={{
                                                        height: 200,
                                                        width: 200,

                                                        borderRadius: 10,

                                                    }}
                                                    src={item.sas_ques_image_path}
                                                /></Grid>
                                            }
                                            {item.quiz_options.map((subItem, i) => {
                                                return (<Grid item xs={12} sx={{ paddingLeft: 2, paddingRight: 2, }}>
                                                    <FormControlLabel
                                                        control={<Field as={Checkbox} type="checkbox" name={item.quiz_id.toString()} />}
                                                        label={<span style={{ color: "black" }}>{subItem.answer_option}</span>}
                                                        value={subItem.quiz_details_id.toString()}
                                                    />

                                                    {/* <label>
                                                        <Field type="checkbox" name={item.quiz_id} value={subItem.quiz_details_id} />
                                                        {<span style={{ color: "black" }}>{subItem.answer_option}</span>}
                                                    </label> */}
                                                    {subItem.sas_ans_image_path && <Grid container justifyContent="center" xs={12}><img
                                                        style={{
                                                            height: 200,
                                                            width: 200,

                                                            borderRadius: 10,

                                                        }}
                                                        src={subItem.sas_ans_image_path}
                                                    /></Grid>
                                                    }

                                                    <Divider sx={{ marginBottom: 1, marginTop: 1 }} />
                                                </Grid>
                                                )
                                            })}

                                        </Grid>)

                                    }

                                })
                            }</Box>
                            {/* {(errors.selectionError && touched.selectionError ) && <Grid item xs={12}>
                                <Typography sx={{ color: "red" }}>{errors.selectionError}</Typography>
                            </Grid>} */}
                            <Grid container justifyContent="space-between" xs={12} sx={{ paddingLeft: 0, paddingRight: 0, marginTop: 1 }} >
                                <Grid container xs={6} justifyContent="left" alignContent="center" >
                                    {/* <Grid container xs={12}>
                                        <Typography sx={{ color: "#8080cf", fontSize: 13, fontWeight: 500 }}>{`Time left -`} </Typography>
                                        <Typography sx={{ color: "#0f01a0", fontSize: 14, fontWeight: 600, marginLeft: .5 }}>{timer} </Typography>
                                    </Grid> */}
                                    <Grid container xs={12}>
                                        <Typography sx={{ color: "#8080cf", fontSize: 13, fontWeight: 500 }}>{`Questions answered -`} </Typography>
                                        <Typography sx={{ color: "#0f01a0", fontSize: 14, fontWeight: 600, marginLeft: .5 }}>{`
                                        ${Object.keys(values).filter(item => {
                                            if (Array.isArray(values[item])) {
                                                if (values[item].length > 0) {
                                                    return true
                                                }
                                                return false
                                            }
                                            return true
                                        }).length}
                                        
                                        
                                        /${quizQuestions}`} </Typography>
                                    </Grid>
                                    {/* <button onClick={onClickReset}>Reset</button> */}
                                </Grid>
                                <Grid container xs={6} justifyContent="right" >
                                    <Button disabled fullWidth
                                        style={{ textTransform: "none" }}
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        type="none">
                                        Submit the quiz
                                    </Button>
                                </Grid>
                            </Grid>

                            {/* <div>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    type="submit"
                                // disabled={isSubmitting}
                                >
                                    Submit
                                </Button>
                            </div> */}
                            {/* <pre>{JSON.stringify(touched, null, 2)}</pre>
                            <pre style={{ color: "red" }}>
                                {JSON.stringify(errors, null, 2)}
                            </pre>
                            {() => handleSubmit()}
                            <Button type="button" onClick={() => handleSubmit()}> submit</Button> */}
                            {/* <AutoSubmitComponent timer={timer} setTimer={setTimer} /> */}
                        </Form>

                    )}


                </Formik>
            </div>

        </Grid>

    </>)


}

export default CoursePreviewQuiz