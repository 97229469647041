import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Provider } from "react-redux";
// import { store } from "./store";
import Routes from "./routes";
import axios from "axios"
import setAuthToken from "./setAuthToken"
// import logo from './logo.svg';
// import "./App.css";
import {
  createTheme,
  // makeStyles,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import store from "./store";
import { BrowserRouter as Router } from "react-router-dom";
import { loginRefresh } from "./Redux/Middlewares/loginRefreshMiddleware";
import { getSSOUser } from "./Redux/Middlewares/SSO/SSOMiddleware";
import MarsCentraBookEot from './Assets/fonts/MarsCentra-Book.eot';
import MarsCentraBookSvg from './Assets/fonts/MarsCentra-Book.svg';
import MarsCentraBook from './Assets/fonts/MarsCentra-Book.ttf';
import MarsCentraBookWoff from './Assets/fonts/MarsCentra-Book.woff';
import MarsCentraBookWoff2 from './Assets/fonts/MarsCentra-Book.woff2';

import MarsCentraBoldEot from './Assets/fonts/MarsCentra-Bold.eot';
import MarsCentraBoldSvg from './Assets/fonts/MarsCentra-Bold.svg';
import MarsCentraBold from './Assets/fonts/MarsCentra-Bold.ttf';
import MarsCentraBoldWoff from './Assets/fonts/MarsCentra-Bold.woff';
import MarsCentraBoldWoff2 from './Assets/fonts/MarsCentra-Bold.woff2';

import { CssBaseline } from "@mui/material";
import { Box } from "@mui/system";

//theme for complete app consisting of Mars font
const theme = createTheme({

  palette: {
    primary: {
      main: "#0000A0",
    },
    secondary: {
      main: "#6ad7b9",
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1081,
      lg: 1200,
      xl: 1536,
    },
  },

  typography: {
    // htmlFontSize: 10,
    fontFamily: `"Mars Centra", Arial`,
    fontSize: 16,
    h5: {
      fontSize: '1rem',
      fontWeight: 700
    },
    body2: {
      fontSize: 18,
      opacity: ".8"
    },
    h6: {
      fontSize: 14,
      opacity: ".7",
      fontWeight: 600
    },
    h4: {
      fontSize: 22,
      fontWeight: 600
    },
    h2: {
      fontSize: 14,
      opacity: ".6",
      fontWeight: 20
    },
    h3: {
      fontSize: 14,
      opacity: "1",
      fontWeight: 20
    },
    h1: {
      fontSize: 20,
      opacity: ".8",
      fontWeight: 600
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Mars Centra';
          src: url(${MarsCentraBookEot});
          src: url(${MarsCentraBookEot}?#iefix) format('embedded-opentype'),
              url(${MarsCentraBookSvg}#MarsCentra-Book) format('svg'),
              url(${MarsCentraBook}) format('truetype'),
              url(${MarsCentraBookWoff}) format('woff'),
              url(${MarsCentraBookWoff2}) format('woff2');
          font-weight: 400;
          font-style: normal;
        }
        @font-face {
          font-family: 'Mars Centra';
          src: url(${MarsCentraBoldEot});
          src: url(${MarsCentraBoldEot}?#iefix) format('embedded-opentype'),
               url(${MarsCentraBoldSvg}#MarsCentra-Bold') format('svg'),
               url(${MarsCentraBold}) format('truetype'),
               url(${MarsCentraBoldWoff}) format('woff'),
               url(${MarsCentraBoldWoff2}) format('woff2');
          font-Weight: 700;
          font-style: normal;
        }
      `
    },


  }

});


const App = () => {
  const [loggedInUser, setLoggedInUser] = useState(null);


  const history = useHistory();






  useEffect(() => {

    if (localStorage.token) {
      setAuthToken(localStorage.token)

    }


    if (localStorage.userType) {
      store.dispatch(loginRefresh())
    }

    if (localStorage.adminType) {
      // store.dispatch(getSSOUser())
    }
    store.dispatch(getSSOUser())

    // if (!localStorage.getItem("token"))
    // history.push("/");


    // const token = localStorage.getItem("token");
    // if (token) {
    //   axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
    //   setLoggedInUser(JSON.parse(localStorage.getItem("user")));
    // }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Router>
          <Routes loggedInUser={loggedInUser} setLoggedInUser={setLoggedInUser} />
        </Router>


      </ThemeProvider>
    </Provider>

  );
};

export default App;
