export const classes = {
  subHeading: {
    color: "#0f01a0",
  },
  subHeadingGrid: {
    color: "#0f01a0",
    paddingLeft: "0% !important",
  },
  divider: { width: "100%", margin: "4%" },
  input: { display: "none" },
  //   creativesGrid: { gridAutoRows: "1fr" },
  previewDiv: {
    background: "#f2f2f2",
    padding: "2%",
    height: "100%",
    borderRadius: "5px",
  },
  courseTitleField: {
    width: "100%",
  },
  courseDescriptionField: {
    width: "100%",
  },
  addCreatorButton: {
    marginTop: "3%",
  },
};
