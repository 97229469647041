import React, { useState, useEffect } from "react";

import { useHistory, withRouter } from "react-router-dom";
import { Box, Paper, Typography, Grid, TextField, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { connect } from "react-redux";
import { signUpCloseRequest } from "../Redux/ActionCreators/NavbarActionCreators";




// const Login = ({ setLoggedInUser }) => {
const SignUp = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showError, setShowError] = useState(false);

  const history = useHistory();

  const handleEmail = (e) => {
    if (e.target.value.length < 30)
      setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    if (e.target.value.length < 30)
      setPassword(e.target.value);
  };

  const handleConfirmedPassword = (e) => {
    if (e.target.value.length < 30)
      setConfirmedPassword(e.target.value);
  };

  const handlePageVisibility = () => {
    if (sessionStorage.getItem("isLoggedIn")) {
      history.push("/courses");
    }
  };

  useEffect(() => {
    handlePageVisibility();
  }, []);

  // const handleLogin = () => {
  //   if (userName === "" || password === "") return;
  //   if (userName === "admin" && password === "pass@admin") {
  //     setShowError(false);
  //     sessionStorage.setItem("isLoggedIn", true);
  //     // setLoggedInUser(true);
  //     history.push("/courses");
  //   } else setShowError(true);
  // };


  const paperLayout2 = {
    padding: "10px 10px",
    width: 360,
    height: 500,
    // margin: "10px auto"
  }
  // const paperLayout1={
  //   padding:"12px 12px",
  //   width:420,
  //   height:570,
  //   margin: "0px auto",
  // }
  const buttonStyle = { width: 270, height: 50 }
  const base = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
  }


  const handleSignup = () => {  // to initiate signup process

  }

  return (


    <Grid sx={base}>

      {/* <Paper  style={paperLayout1} variant="outlined" square > */}
      <Paper elevation={2} style={paperLayout2} >


        <Grid align="center">
          <Box ml={40}><IconButton onClick={()=>props.closeSignup()} >
            <CloseIcon />
          </IconButton>
          </Box>

          <Box mt={0} ml={2}><Typography variant="h5" >Vet 2 Vet</Typography></Box>





          <Box mt={2}><Typography variant="body2" >Sign up</Typography></Box>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '30ch' },
            }}
            noValidate
            autoComplete="off"
          >

            <Box mt={2} ml={9}><Typography align="left" variant="h6" >Email </Typography></Box>
            <TextField InputProps={{ disableUnderline: true }}
              id="outlined-multiline-flexible"
              size="small"

              maxRows={4}
              type="email"
              onChange={handleEmail}
              variant="filled"
              value={email}
            />
            <Box mt={2} ml={9}><Typography align="left" variant="h6" >Create password </Typography></Box>
            <TextField
              InputProps={{ disableUnderline: true }}
              size="small"
              id="outlined-textarea"
              placeholder="Enter your password"
              onChange={handlePassword}
              variant="filled"
              type="password"
              value={password}
            />
            <Box mt={2} ml={9}><Typography align="left" variant="h6" >Confirm Password </Typography></Box>
            <TextField
              InputProps={{ disableUnderline: true }}
              size="small"
              type="password"
              value={confirmedPassword}
              id="outlined-textarea"
              placeholder="Confirm password"
              onChange={handleConfirmedPassword}
              variant="filled"
            />





            <Box mt={2}>
              <Button id="signUpButton" onClick={handleSignup} style={buttonStyle} fullWidth variant="contained">Sign up</Button>
            </Box>
          </Box>
        </Grid>
      </Paper>

      {/* </Paper> */}
    </Grid>


  );
};


const mapDispatchToProps = dispatch => {
  return {
  
    closeSignup: () => dispatch(signUpCloseRequest()),

    
  }
}


export default connect(null,mapDispatchToProps)(SignUp);