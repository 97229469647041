import {
    FETCH_COURSES_DATA, SET_COURSES_DATA, SET_ACTIVE_COURSE_DATA, CLEAR_ACTIVE_COURSE_DATA, CLEAR_COURSES_DATA, SET_COURSES_FAILURE_DATA,
    SET_COURSES_FILTER_DATA, SET_USER_COURSE_FILTER_DATA, CLEAR_USER_COURSE_FILTER_DATA, SET_COURSES_DATA_PAGINATION,
    SET_COURSES_SEQUENCE, TOGGLE_QUIZ_ACTIVATION, SET_QUIZ_DATA, FETCH_QUIZ_DATA, SET_QUIZ_RESULT, CLEAR_QUIZ_DATA, SET_GRADE_DATA,
    BOOKMARK_UPDATE_COURSES, RATING_UPDATE_COURSES,SET_COURSE_CERTIFICATE_PDF
} from "../ActionTypes/coursesVetActionTypes";



const initialState = {
    loading: false,
    contents: null,
    isBookmarked: null,
    rating: 0,
    avgRating: 0,
    coursesList: [],
    current: 1,
    next: null,
    prev: null,
    totalCount: null,
    filterData: [],
    quizActive: 0, //0 for quiz begin screen, 1 for questions screen,2 for passing screen and 3 for fail screen
    quizResult: null,
    quizGrade: null,
    quizWrongAnswers: [],
    quizInput: {},
    gradeData: null,
    currentRating: null,

    // timeFilter: "All time",
    categoryFilter: [],
    quizLoader: false,
    quizData: null,
    authorFilter: [],
    speciesFilter: [],
    countryFilter: [],
    timeFilter: ["All time"],

    courseSequence: null,
    currentSelection: null, // current lesson selection
    certificate: null,
};

function CoursesVetReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case FETCH_COURSES_DATA:
            return {
                ...state,
                loading: true,
            };
        case SET_COURSES_DATA:
            return {
                ...state,
                coursesList: payload,
                loading: false
            };
        case CLEAR_COURSES_DATA:
            return {
                ...state,
                loading: false,
                contents: null,
                coursesList: null,
                isBookmarked: null,
                rating: null,
                next: null,
                prev: null,
                current: null,
                totalCount: null,
                certificate: null

            };
        case SET_COURSES_FAILURE_DATA:
            return {
                ...state,
                loading: false
            };
        case BOOKMARK_UPDATE_COURSES:
            return {
                ...state,
                coursesList: payload
            }


        case SET_COURSES_FILTER_DATA:
        case CLEAR_USER_COURSE_FILTER_DATA:
            return {
                ...state, filterData: payload
            };
        case SET_USER_COURSE_FILTER_DATA:
        case SET_COURSES_DATA_PAGINATION:
            return {
                ...state, ...payload
            };

        case SET_ACTIVE_COURSE_DATA:
            return {
                ...state, contents: payload, loading: false
            }
        case CLEAR_ACTIVE_COURSE_DATA:
            return {
                ...state, contents: null
            }
        case SET_COURSES_SEQUENCE:
            return {
                ...state, courseSequence: payload.data,
                currentSelection: payload.current
            }
        case RATING_UPDATE_COURSES:
            return {
                ...state, currentRating: payload
            }
        case TOGGLE_QUIZ_ACTIVATION:
            return {
                ...state, quizActive: payload.quizActive
            }
        case SET_QUIZ_DATA:
            return {
                ...state, quizData: payload, quizLoader: false
            }
        case FETCH_QUIZ_DATA:
            return {
                ...state, quizLoader: payload
            }
        case SET_QUIZ_RESULT:
            return {
                ...state, ...payload
            }
        case SET_COURSE_CERTIFICATE_PDF:
            return {
                ...state, certificate: payload
            }
        case CLEAR_QUIZ_DATA:
            return {
                ...state,
                quizGrade: null, quizData: null, quizLoader: false, quizResult: null, gradeData: null,
                quizActive: 0, quizWrongAnswers: [], quizInput: {}
            }
        case SET_GRADE_DATA:
            return {
                ...state, gradeData: payload
            }
        default:
            return state;

    }
}

export default CoursesVetReducer;