import React from 'react';
import {
    Document,
    Page,
    View,
    Text,
    Link,
    Font,
    StyleSheet,
    Image
} from '@react-pdf/renderer';
import CERT_IN_CERTIFICATE from "../../Assets/certificates/IN-webinar certificate.png"
import CERT_PH_CERTIFICATE from "../../Assets/certificates/PH-webinar certificate.png"

const styles = StyleSheet.create({
    title: {
        margin: 20,
        fontSize: 20,
        textAlign: 'center',
        backgroundColor: '#e4e4e4',
        textTransform: 'uppercase',
    },
    body: {

    },
    row: {

        flexDirection: 'row',
    },
    block: {
        flexGrow: 1,
    },
    text: {
        top: "150%",
        left: "40%",
        margin: 10,

        fontSize: 20
    },
    text2: {
        top: "198%",
        left: "40%",
        margin: 10,

        fontSize: 20
    },
    text3: {
        top: "150%",
        left: "15%",
        margin: 10,

        fontSize: 20
    },
    text4: {
        top: "170%",
        left: "20%",
        margin: 10,

        fontSize: 20
    },

});

const styles1 = StyleSheet.create({
    pageBackground: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',
    },
});

export default ({ webinar, country, full_name }) => (
    <Document>
        <Page size="LETTER" orientation="landscape">
            <Image src={country === "India" ? CERT_IN_CERTIFICATE : CERT_PH_CERTIFICATE} style={styles1.pageBackground} />
            <View >
                <Text style={styles.text}>
                    {full_name}
                </Text>
                <Text style={styles.text2}>
                    {webinar && webinar.webinar_date && webinar.webinar_date.split(" ")[0].toString().replaceAll("-", "/")}
                </Text>
                <Text style={styles.text3}>
                    {webinar && webinar.webinar_title}
                </Text>
                <Text style={styles.text4}>
                    {webinar && webinar.speaker && webinar.speaker[0].speaker_name}
                </Text>

            </View>
        </Page>
    </Document>
);