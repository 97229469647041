import React, { useState, useEffect } from "react";
import { Redirect, useHistory, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { TabList, TabContext, TabPanel } from "@mui/lab";
import { Tab, Snackbar, Alert, Typography, Grid } from "@mui/material";
import CurriculumDetails from "../CurriculumDetails/CurriculumDetails";
import CourseDetails from "../CourseDetails/CourseDetails";
import { getFilterValues } from "../../Redux/Middlewares/CoursesAdmin/getFilterValuesMIddleware";
import CourseProgress from "../CourseProgress/CourseProgress";
import { toggleAlert } from "../../Redux/ActionCreators/coursesAdminActionCreators";
import { classes } from "./styles";
import { NAVIGATE_ADMIN_ON_COURSE_SAVE, CLEAR_COURSE_DATA } from "../../Redux/ActionTypes/coursesAdminActionTypes";
import { TOGGLE_EDIT_FLOW } from "../../Redux/ActionTypes/coursesAdminActionTypes";
import "./styles.css";

const NewCourse = ({
  toggleAlert,
  isAlertOpen,
  alertType,
  alertMessage,
  getFilterValues,
  filters,
  activeCourse,
  adminNavigation, courseCompleteNavigationReset, resetCourseData, toggleEditFlow, isEditFlow
}) => {
  useEffect(() => {
  
    if (!Object.keys(filters).length) getFilterValues();

    return () => {
      courseCompleteNavigationReset()
      resetCourseData()
      toggleEditFlow(false)
    }

  }, []);

  useEffect(() => {
    if (activeCourse && !isEditFlow) {
      setValue("2")
    }

  }, [activeCourse])

  const history = useHistory();

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleHomeClick = () => {
    history.push("/courses");
  };
  if (adminNavigation == 1) {
    return (<Redirect to="/courses" />)
  }

  return (
    <div style={classes.container}>
      
      <p>
        <span style={classes.inactiveCrumb} onClick={handleHomeClick}>
          {"Home > "}
        </span>
        {isEditFlow ? <span style={classes.activeCrumb}>Edit course</span> : <span style={classes.activeCrumb}>New Course</span>}
      </p>
      {isEditFlow ? <Typography align="left" variant="h4" noWrap>
        Edit course
      </Typography> :
        <Typography align="left" variant="h4" noWrap>
          Create a new Course
        </Typography>}

      <Grid
        container
        spacing={2}
        className="new_course_section"
        sx={classes.grid}
      >
        <Grid item xs={9}>
          <TabContext value={value}>
            <TabList
              onChange={handleChange}
              aria-label="new course tabs"
              sx={classes.tabContainer}
            >
              <Tab label="Course Details" value="1" />
              <Tab label="Curriculum" value="2" disabled={!activeCourse} />
            </TabList>
            <TabPanel value="1">
              <CourseDetails />
            </TabPanel>
            <TabPanel value="2">
              <CurriculumDetails />
            </TabPanel>
          </TabContext>
        </Grid>
        <Grid item xs={3}>
          <CourseProgress setValue={setValue} />
        </Grid>
      </Grid>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={5000}
        onClose={() => toggleAlert("success", "", false)}
      >
        <Alert
          onClose={() => toggleAlert("success", "", false)}
          severity={alertType}
          sx={classes.alert}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  filters: state.CoursesAdminReducer.filters,
  isAlertOpen: state.CoursesAdminReducer.isAlertOpen,
  alertType: state.CoursesAdminReducer.alertType,
  alertMessage: state.CoursesAdminReducer.alertMessage,
  activeCourse: state.CoursesAdminReducer.activeCourse,
  adminNavigation: state.CoursesAdminReducer.adminNavigation,
  isEditFlow: state.CoursesAdminReducer.isEditFlow,
});

const mapDispatchToProps = (dispatch) => ({
  getFilterValues: () => dispatch(getFilterValues()),
  toggleAlert: () => dispatch(toggleAlert()),
  courseCompleteNavigationReset: () => dispatch({ type: NAVIGATE_ADMIN_ON_COURSE_SAVE, payload: 0 }),
  resetCourseData: () => dispatch({ type: CLEAR_COURSE_DATA }),
  toggleEditFlow: (val) => dispatch({ type: TOGGLE_EDIT_FLOW, payload: val })
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCourse);
