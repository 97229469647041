import { loginRequest, loginFailureRequest, loadUserRequest, authErrorRequest } from "../../ActionCreators/authenticationActionCreators"
import { setLoginFailAlert } from "../../ActionCreators/alertsActionCreators"
import { LOGIN_INIT } from "../../ActionTypes/authenticationActionTypes"
import axios from "axios"
import { TOGGLE_PROCESS_LOADER } from "../../ActionTypes/alertsActionTypes"
import { toggleGlobalAlert } from "../../ActionCreators/alertsActionCreators";
import { INITIAL_LOADING_STOP } from "../../ActionTypes/authenticationActionTypes"

//Login 
export const getSSOUser = () => async dispatch => {


    try {
        dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: true } })
        // dispatch({ type: LOGIN_INIT })
        let url = process.env.REACT_APP_API_URL + "/login/userinfo"


        let result = await axios.get(url)

        if (result.data && result.data[0]) {


            dispatch(loginRequest({
                token: result.data[0].token, user: result.data[0].email,
                userStatus: "registered",
                profile: result.data[0],
                userType: result.data[0].user_type
            }))
            localStorage.setItem("adminType", "admin")
            dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: false } })


        }
        else {
            // dispatch(setLoginFailAlert())
            // dispatch(loginFailureRequest())
            dispatch({ type: INITIAL_LOADING_STOP })
            dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: false } })
        }

        //dispatch(loadUser())

    }
    catch (err) {
        // dispatch(toggleGlobalAlert("warning",err.response&& err.response.data
        //      && err.response.data.message?err.response.data.message:"Something went wrong" , true));
        // dispatch(loginFailureRequest())
        dispatch({ type: INITIAL_LOADING_STOP })
        dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: false } })
    }
}

//Login
// export const loadUser=(email,password)=>async dispatch=>{

//     try{
//         const result=await new Promise( //   emulating api call
//          (res,rej)=>{
//              setTimeout(()=>{
//                     res({userName:"John Doe"})
//              },4000)
//          }
//         )
//         dispatch(loadUserRequest(result))

//     }
//     catch(err){
//         dispatch(authErrorRequest())
//     }
// }