import axios from "axios";
import {
  //   fetchWEBINARSFailure,
  //   fetchWEBINARSRequest,
  fetchWebinarsSuccess,

  //   toggleAlert,
} from "../../ActionCreators/webinarsAdminActionCreators";
import {
  CLEAR_WEBINARS_DATA,
  FETCH_WEBINARS_DATA,
  SET_WEBINARS_DATA,
  SET_WEBINARS_DATA_PAGINATION
} from "../../ActionTypes/webinarsAdminActionTypes"

// const GET_WEBINARS_URL =
//   process.env.REACT_APP_API_URL + "/WEBINARS/admin/course_homepage/?page=";


export const getWebinars = (reqObj, page = 1) => async dispatch => {

  try {
    dispatch({ type: CLEAR_WEBINARS_DATA })
    dispatch({ type: FETCH_WEBINARS_DATA })

    let url = process.env.REACT_APP_API_URL + `/webinars/admin/webinar_homepage/?page=${page}`




    let result = await axios.post(url, reqObj)

    if (result.data) {

      // dispatch(
      //   fetchWebinarsSuccess({
      //     count: webinarList.count,
      //     results: paginate(webinarList.results, 4, pageNumber),
      //   })
      // )
      dispatch({ type: SET_WEBINARS_DATA, payload: result.data.results })
      dispatch({
        type: SET_WEBINARS_DATA_PAGINATION, payload: {
          totalCount: result.data.count,
          prev: result.data.previous ? page - 1 : null,
          next: result.data.next ? page + 1 : null,
          current: page

        }
      })





    }
    else {
      dispatch({ type: CLEAR_WEBINARS_DATA })
      // dispatch({ type: SET_GENERIC_ERROR_ALERT })
    }



  }
  catch (err) {
    dispatch({ type: CLEAR_WEBINARS_DATA })
    // dispatch({ type: SET_GENERIC_ERROR_ALERT })
  }
}


