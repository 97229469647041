export const FETCH_ARTICLE_LIST="FETCH_ARTICLE_LIST"
export const SET_ARTICLE_LIST="SET_ARTICLE_LIST"
export const FETCH_ARTICLE_USER_DATA="FETCH_ARTICLE_USER_DATA"
export const SET_ARTICLE_USER_DATA="SET_ARTICLE_USER_DATA"
export const SET_LC_ARTICLE_USER_DATA_FAIL="SET_LC_ARTICLE_USER_DATA_FAIL"
export const CLEAR_ARTICLE_USER_DATA="CLEAR_ARTICLE_USER_DATA"
export const SET_BOOKMARK_LC="SET_BOOKMARK_RATING_LC"    //setting bookmark for learning content
export const SET_RATING_LC="SET_RATING_LC"    //setting rating for learning content
export const SET_AVG_RATING_LC="SET_AVG_RATING_LC"    //setting average rating for learning content
export const SET_LEARNING_CONTENT_PAGINATION="SET_LEARNING_CONTENT_PAGINATION"  //set learning content pagination data
export const SET_FILTERS_DATA_LEARNING_CONTENT="SET_FILTERS_DATA_LEARNING_CONTENT"
export const SET_USER_FILTER_LEARNING_CONTENT="SET_USER_FILTER_LEARNING_CONTENT"
export const CLEAR_USER_FILTER_LEARNING_CONTENT="CLEAR_USER_FILTER_LEARNING_CONTENT"
export const BOOKMARK_UPDATE_LC="BOOKMARK_UPDATE_LC"

