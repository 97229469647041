import {CLEAR_COURSES_DATA,SET_COURSES_USER_DATA,SET_COURSES_FILTER_DATA} from "../../ActionTypes/coursesVetActionTypes"

import { SET_QUERY_ADDED_ALERT,CLEAR_QUERY_ADDED_ALERT,SET_GENERIC_ERROR_ALERT } from "../../ActionTypes/alertsActionTypes"

import axios from "axios"

export const getFiltersVetCourses = (moduleId,queryCategory,queryText) => async dispatch => {


   try {
       

       
       let url = process.env.REACT_APP_API_URL+"/vetcourses/dropdowndetails/"



       let result = await axios.get(url)

       if (result.data &&result.data.course_author) {

           


        dispatch({ type: SET_COURSES_FILTER_DATA,payload:result.data })


       }
       else {
           dispatch({ type: SET_GENERIC_ERROR_ALERT })
       }

       //dispatch(loadUser())

   }
   catch (err) {
       dispatch({ type: SET_GENERIC_ERROR_ALERT })
   }
}

