


import axios from "axios";
import {
    //   fetchWEBINARSFailure,
    //   fetchWEBINARSRequest,
    fetchWebinarsSuccess,

    //   toggleAlert,
} from "../../ActionCreators/webinarsAdminActionCreators";
import {
    CLEAR_WEBINARS_DATA,
    FETCH_WEBINARS_DATA,
    SET_WEBINARS_DATA,
    SET_WEBINARS_DATA_PAGINATION,
    SET_WEB_EDIT,
    SET_WEBINARS_EDITING_DATA,
    SET_CURRENT_WEBINAR,
    SET_UPLOAD_WEBINAR
} from "../../ActionTypes/webinarsAdminActionTypes"

// const GET_WEBINARS_URL =
//   process.env.REACT_APP_API_URL + "/WEBINARS/admin/course_homepage/?page=";


export const getWebinarForEdit = (webinar_id, uploadedFile) => async dispatch => {

    try {
        // dispatch({ type: CLEAR_WEBINARS_DATA })
        // dispatch({ type: FETCH_WEBINARS_DATA })

        let url = process.env.REACT_APP_API_URL + `/webinars/admin/getwebinar/${webinar_id}/`




        let result = await axios.get(url)

        if (result.data) {

            // dispatch(
            //   fetchWebinarsSuccess({
            //     count: webinarList.count,
            //     results: paginate(webinarList.results, 4, pageNumber),
            //   })
            // )
            if (uploadedFile) {
                dispatch({ type: SET_WEBINARS_EDITING_DATA, payload: result.data })
                dispatch({ type: SET_CURRENT_WEBINAR, payload: webinar_id })
                dispatch({ type: SET_UPLOAD_WEBINAR, payload: true })
            }
            else {
                dispatch({ type: SET_WEBINARS_EDITING_DATA, payload: result.data })
                dispatch({ type: SET_WEB_EDIT, payload: 1 })
                dispatch({ type: SET_CURRENT_WEBINAR, payload: webinar_id })
            }







        }
        else {
            //   dispatch({ type: CLEAR_WEBINARS_DATA })
            // dispatch({ type: SET_GENERIC_ERROR_ALERT })
        }



    }
    catch (err) {
        // dispatch({ type: CLEAR_WEBINARS_DATA })
        // dispatch({ type: SET_GENERIC_ERROR_ALERT })
    }
}


