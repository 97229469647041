import React from 'react';
import {
    Document,
    Page,
    View,
    Text,
    Link,
    Font,
    StyleSheet,
    Image
} from '@react-pdf/renderer';
import Cert from "./cert.png"
import CERT_IN_COURSE from "../Assets/certificates/IN-course certificate.png"
import CERT_PH_COURSE from "../Assets/certificates/PH-course certificate.png"

const styles = StyleSheet.create({
    title: {
        margin: 20,
        fontSize: 20,
        textAlign: 'center',
        backgroundColor: '#e4e4e4',
        textTransform: 'uppercase',

    },
    body: {

    },
    row: {

        flexDirection: 'row',
    },
    block: {
        flexGrow: 1,
    },
    text: {
        top: "250px",
        left: "40%",
        margin: 10,

        fontSize: 30
    },
    text2: {
        top: "300px",
        width: "100%",
        textAlign:"center",
        margin: 10,

        fontSize: 20
    },
    text3: {
        top: "44%",
        left: "500%",
        margin: 10,


    },
    text4: {
        top: "310px",
        left: "40%",
        margin: 10,

        fontSize: 20
    },

});


const styles1 = StyleSheet.create({
    pageBackground: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',
    },
});

export default ({ certificate, country }) => (
    <Document>
        <Page size="LETTER" orientation="landscape">
            <Image src={country === "India" ? CERT_IN_COURSE : CERT_PH_COURSE} style={styles1.pageBackground} />
            <View >
                <Text style={styles.text}>
                    {certificate && certificate.user_name}
                </Text>
                <Text style={styles.text2}>
                    {certificate && certificate.course_name}
                </Text>
                {/* <Text style={styles.text3}>
                {"Mars INC"}
                </Text> */}
                <Text style={styles.text4}>
                    {certificate && certificate.complete_date && certificate.complete_date.split(" ")[0].toString().replaceAll("-", "/")}
                </Text>

            </View>
        </Page>
    </Document>
);