import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import MappingObj from "./LandingPageConstants";

const PieChartComponent = ({chartData, chartType, stacked, currFilterValue, chartKey}) => {
    console.log("stacked",stacked)
    ChartJS.register(ArcElement, Tooltip, Legend);

      const colorArray = [
        'rgba(255, 99, 132,0.5)',
        'rgba(54, 162, 235,0.5)',
        'rgba(255, 206, 86,0.5)',
        'rgba(75, 192, 192,0.5)',
        'rgba(153, 102, 255,0.5)',
        'rgba(255, 159, 64,0.5)',
      ]

      const getGraphData = (currGraphData, currChartType, isStacked, dropdownValue, currChartKey) => {
        //check for object or array
        let labelArray = [];
        let dataArray = [];
        // let colorArray = [];
        let datasetsArray = [];
        if(Object.keys(currGraphData).length>0 || currGraphData.length>0){
        if((!!currGraphData) && (currGraphData.constructor === Object)){
            //filtering data as per dropdown value
            let filteredGraphData = currGraphData[dropdownValue];
            filteredGraphData.map(e=> {
                // labelArray.push((e.values).length>12 ? e.values.substring(0,10)+"..." : e.values);
                labelArray.push(e.occupation);
                dataArray.push(e.percentage_val);                
            })

            datasetsArray = [
                {
                    label: MappingObj[currChartKey]['series_name'],
                    data: dataArray,
                    backgroundColor:colorArray
                }
            ]
    
        }
        if((!!currGraphData) && (currGraphData.constructor === Array)){
           
                currGraphData.map(e => {
                    labelArray.push(e.occupation);
                    dataArray.push(e.percentage_val)
                })
    
                datasetsArray = [
                    {
                        label: MappingObj[currChartKey]['series_name'],
                        data: dataArray,
                        backgroundColor:colorArray
                    }
                ]

        }
    }
        return {
            labelArray, datasetsArray
        }
    }

    useEffect(() => {

    },[])

    const finalGraphData = getGraphData(chartData,chartType,stacked,currFilterValue, chartKey);

    const {labelArray, datasetsArray} = finalGraphData;

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: false,
            text: ''
          },
          tooltip: {
            callbacks: {
              title: function (tooltipItem) {
                  return "Distribution";
                // return "my tittle";
              },
              label: function(tooltipItem, data){
                  console.log("tooltip",tooltipItem, data)
                    return tooltipItem['label']+ ": "+ tooltipItem['formattedValue']+"%"
              }
            },
          }
        }
      }
      const labels = labelArray;
      const dataObj = {
        labels,
        datasets: datasetsArray
      }

    return(
        <>
            {datasetsArray.length>0 ? <Doughnut options={options} data={dataObj}/> :  <div className="no-data-message">No Data to display</div>}
        </>
    )


  }

  const mapStateToProps = (state) => ({
   
})

const mapDispatchToProps = (dispatch) => ({
    
})

export default connect(mapStateToProps, mapDispatchToProps)(PieChartComponent);