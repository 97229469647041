import React, { useState, useEffect } from "react";
// import "./styles.css";
import { useHistory, withRouter } from "react-router-dom";
import { Box, Paper, Typography, Grid, TextField, Button, CssBaseline } from '@mui/material';
import { connect } from "react-redux"
import { login } from "../Redux/Middlewares/loginMiddleware";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { styled, alpha } from "@mui/material/styles";
import {LoginCloseRequest,ForgetPasswordOpenRequest,EnterOTPOpenRequest} from "../Redux/ActionCreators/NavbarActionCreators"
import LoginFormRes from "../Login/LoginFormRes";
import ForgotPassInitForm from "./ForgotPassInitForm";
import NewPassEntryForm from "./NewPassEntryForm";
import EnterOTPForm from "./EnterOTPform";
import { CLEAR_AUTH_ID_OTP,CLEAR_FORGOTPASS_SCREENS_FLOW } from "../Redux/ActionTypes/authenticationActionTypes";
import { forgotPassOTPSend } from "../Redux/Middlewares/forgotPassOTPSendMiddleware";
import { verifyOTP } from "../Redux/Middlewares/verifyOTPMiddleware";
import { enterNewPass } from "../Redux/Middlewares/enterNewPasswordMiddleware";
import {otpAndPassword} from "../Redux/Middlewares/otpAndPasswordMiddleware"




// const Login = ({ setLoggedInUser }) => {
const ForgotPassInit = ({ login, isAuthenticated,forgetPasswordflow,forgotPassOTPSend,verifyOTP,
  enterNewPass,authIdOTP,clearAuthIdOTP,clearForgotPasswordScreenFlow,otpAndPassword,processLoader }) => {

  const [emailMain, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [activeStep, setActiveStep] = useState(0);  // step is 0 for password change init screen, 1 for enter otp and 2 for enter new password screen
  const [showError, setShowError] = useState(false);

  const history = useHistory();

  useEffect(()=>{
    return ()=>{
      clearAuthIdOTP()
      clearForgotPasswordScreenFlow()

    }
  },[])




  const StyledMain = styled('div')(({ theme }) => ({
    marginTop: '5%',
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  
  }));

  const PaperWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 60,
    },
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  
  }));
  




  return (
    <>
     <CssBaseline/>
     <StyledMain>
       <PaperWrapper>
         <Paper sx={{padding:2,paddingLeft:{xs:2,sm:8},paddingRight:{xs:2,sm:8}}}>
         {/* <Box sx={{display:"flex",justifyContent:"center"}}>
          <img src={Logo} alt="logo.png"/>
          </Box> */}
          {forgetPasswordflow===0?( <ForgotPassInitForm  setEmail={setEmail} 
          forgotPassOTPSend={forgotPassOTPSend} processLoader={processLoader}   />):

          // forgetPasswordflow===1?(<EnterOTPForm verifyOTP={verifyOTP}  emailMain={emailMain} authIdOTP={authIdOTP} />):
          forgetPasswordflow===1?(  <NewPassEntryForm processLoader={processLoader} forgotPassOTPSend={forgotPassOTPSend}  emailMain={emailMain} otpAndPassword={otpAndPassword} authIdOTP={authIdOTP} />):(<div>Password Changed Sucessfully.Please login</div>) }
         </Paper>
       </PaperWrapper>

     </StyledMain>

    </>
  );
};



const mapStateToProps = (state) => ({
  isAuthenticated: state.authenticationReducer.isAuthenticated,
  // forgotPass:state.NavigationReducer.forgotPasswordModal,
  forgetPasswordflow: state.authenticationReducer.forgetPasswordflow,
  authIdOTP:state.authenticationReducer.authIdOTP,
  processLoader:state.AlertsReducer.processLoader

})

const mapDispatchToProps = dispatch => {
  return {
    ForgetPasswordOpenRequest: () => dispatch(EnterOTPOpenRequest()),
    forgotPassOTPSend:(email)=>dispatch(forgotPassOTPSend(email)),
    verifyOTP:(otp,authId)=>dispatch(verifyOTP(otp,authId)), 
    enterNewPass:(password,authId)=>dispatch(enterNewPass(password,authId)),
    clearForgotPasswordScreenFlow:()=>dispatch({type:CLEAR_FORGOTPASS_SCREENS_FLOW}),
    clearAuthIdOTP:()=>dispatch({type:CLEAR_AUTH_ID_OTP}),
    otpAndPassword:(otp,password,authId,email)=>dispatch(otpAndPassword(otp,password,authId,email))
    

    
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ForgotPassInit);