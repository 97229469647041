import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Grid,
  TextField,
  Autocomplete,
  Pagination,
  IconButton,
  CircularProgress,
  Tooltip,
  Skeleton,
  InputAdornment,
  OutlinedInput,
  FormHelperText,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FilterAlt, ClearAll, Search } from "@mui/icons-material";
import { getFilterValues } from "../../Redux/Middlewares/CoursesAdmin/getFilterValuesMIddleware";
import {
  changeCourseDetails,
  changePage,
  getCourseDetailsForEdit,
  selectFilter,
  setActiveCourse,
} from "../../Redux/ActionCreators/coursesAdminActionCreators";
import { getCourses } from "../../Redux/Middlewares/CoursesAdmin/getCoursesMiddleware";
import { changeCourseStatus } from "../../Redux/Middlewares/CoursesAdmin/changeCourseStatusMiddleware";
import CourseCard from "../CourseCard/CourseCard";
import { classes } from "./styles";
import { deleteCourse } from "../../Redux/Middlewares/CoursesAdmin/deleteCourseMiddleware";
import { fetchCurriculumData } from "../../Redux/Middlewares/CoursesAdmin/fetchDataForEditMiddleware";
import { TOGGLE_EDIT_FLOW } from "../../Redux/ActionTypes/coursesAdminActionTypes";
import { CREATE_COURSE_EDIT_DETAILS } from "../../Redux/ActionTypes/coursesAdminActionTypes";
const CoursesLayout = ({
  filters,
  getFilterValues,
  selectedFilters,
  selectFilter,
  courses,
  getCourses,
  pageNumber,
  changePage,
  isLoading,
  type,
  changeCourseStatus,
  getCourseDetailsForEdit,
  setActiveCourse,
  deleteCourse,
  deleteCourseStatus,
  fetchCurriculumData,
  toggleEditFlow,
  createEditData
}) => {
  useEffect(() => {
    getFilterValues();
  }, []);

  useEffect(() => {
    fetchCourses(1);
  }, [deleteCourseStatus]);

  const history = useHistory();

  const options = [
    { title: "Alpha", year: 1 },
    { title: "Beta", year: 2 },
    { title: "Gamma", year: 3 },
    { title: "Delta", year: 4 },
  ];

  const handleDateOutput = (monthDifference = 0) => {
    const date = new Date();
    date.setMonth(date.getMonth() - monthDifference);
    const dateArray = date.toLocaleDateString().split("/");
    dateArray.splice(1, 1);
    const newDate = dateArray.join("/");
    return newDate;
  };

  const fetchCourses = (currentPage) => {
    const getDateObject = () => {
      if (
        selectedFilters.show_content_from === "" ||
        selectedFilters.show_content_from === null ||
        selectedFilters.show_content_from.value === "All time"
      )
        return {
          fixed: "All time",
          start_date: null,
          end_date: null,
        };
      else if (selectedFilters.show_content_from.value === "1 Month")
        return {
          fixed: "1 Month",
          start_date: null,
          end_date: null,
        };
      else if (selectedFilters.show_content_from.value === "3 Months")
        return {
          fixed: "3 Months",
          start_date: null,
          end_date: null,
        };
      else if (selectedFilters.show_content_from.value === "6 Months")
        return {
          fixed: "6 Months",
          start_date: null,
          // start_date: handleDateOutput(6),
          end_date: null,
        };
    };
    const filterObject = {
      file_type: selectedFilters.file_content_type,
      creator: selectedFilters.creator,
      category: selectedFilters.category,
      species: selectedFilters.species,
      show_course_from: getDateObject(),
      search: selectedFilters.search ? [selectedFilters.search] : [],
      is_published: type.toUpperCase(),
    };
    getCourses(filterObject, currentPage);
  };

  const cardArray = isLoading ? (
    <>
      <Grid item xs={4}>
        <Skeleton variant="rectangular" sx={classes.biggerSkeleton} />
        <Skeleton variant="rectangular" sx={classes.skeleton} />
        <Skeleton variant="rectangular" sx={classes.smallerSkeleton} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant="rectangular" sx={classes.biggerSkeleton} />
        <Skeleton variant="rectangular" sx={classes.skeleton} />
        <Skeleton variant="rectangular" sx={classes.smallerSkeleton} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant="rectangular" sx={classes.biggerSkeleton} />
        <Skeleton variant="rectangular" sx={classes.skeleton} />
        <Skeleton variant="rectangular" sx={classes.smallerSkeleton} />
      </Grid>
    </>
  ) : courses !== undefined &&
    Object.keys(courses).length &&
    courses.results.length ? (
    courses.results.map((course, key) => (
      <CourseCard
        course={course}
        index={key}
        type={type}
        changeCourseStatus={changeCourseStatus}
        fetchCourses={fetchCourses}
        pageNumber={pageNumber}
        history={history}
        getCourseDetailsForEdit={getCourseDetailsForEdit}
        fetchCurriculumData={fetchCurriculumData}
        setActiveCourse={setActiveCourse}
        deleteCourse={deleteCourse}
        toggleEditFlow={toggleEditFlow}
        createEditData={createEditData}
      />
    ))
  ) : (
    <p style={classes.noCourseDisclaimer}>No courses found !</p>
  );

  const handleApplyFiltersClick = () => {
    changePage(1);
    fetchCourses(1);
  };

  const handlePageChange = (event, value) => {
    changePage(value);
    fetchCourses(value);
  };

  const [clearFilterClicked, setClearFilterClicked] = useState(0);

  useEffect(() => {
    if (!clearFilterClicked) return;
    changePage(1);
    fetchCourses(1);
  }, [clearFilterClicked]);

  const handleClearFilter = () => {
    selectFilter("show_content_from", { value: "All time" });
    selectFilter("species", []);
    selectFilter("creator", []);
    selectFilter("category", []);
    selectFilter("search", "");
    setClearFilterClicked(clearFilterClicked + 1);
  };

  const handleNewCourseClick = () => {
    setActiveCourse(null);
    history.push("/newCourse");
  };

  return (
    <div style={classes.courseLayout}>
      {/* <FormHelperText id="show_content_from_filter">Search</FormHelperText> */}
      <Grid container spacing={2} className="filter_section" sx={classes.grid} direction="row" alignItems="center">
        <Grid item md={2}>
          <OutlinedInput
          id="search_textfield"
          type="text"
          size="small"
          variant="outlined"
          className="search_field"
          placeholder="Search courses..."
          sx={classes.search}
          value={selectedFilters["search"]}
          onChange={(e) => selectFilter("search", e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="search button"
                onClick={handleApplyFiltersClick}
                edge="end"
              >
                <Search />
              </IconButton>
            </InputAdornment>
          }
        />
        </Grid>
        <Grid item md={6}></Grid>
        <Grid item md={2}>
          <Button
          sx={classes.newCourseButton}
          variant="contained"
          color="primary"
          onClick={handleNewCourseClick}
          style={{ textTransform: "none", width:"100%" }}
        >
          New Course
        </Button>
        </Grid>
      </Grid>
      
     
      <Grid container spacing={2} className="filter_section" sx={classes.grid} direction="row" alignItems="center">
        <Grid item xs={2}>
          <FormHelperText id="show_courses_from_filter">
            Show courses from
          </FormHelperText>
          <Autocomplete
            size="small"
            disablePortal
            id="show_courses_from_filter"
            options={
              filters.show_content_from && Object.keys(filters).length > 0
                ? filters.show_content_from
                : []
            }
            sx={classes.filter}
            loading={isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            getOptionLabel={(option) => option.value || ""}
            value={selectedFilters["show_content_from"]}
            onChange={(e, value) =>
              selectFilter("show_content_from", value === null ? "" : value)
            }
          />
        </Grid>
        <Grid item xs={2}>
          <FormHelperText id="category_filter">Category</FormHelperText>
          <Autocomplete
            limitTags={1}
            multiple
            size="small"
            disablePortal
            id="category_filter"
            options={
              filters.category && Object.keys(filters).length > 0
                ? filters.category.map((obj) => obj.category_name)
                : []
            }
            sx={classes.filter}
            loading={isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            getOptionLabel={(option) => option || ""}
            value={selectedFilters["category"]}
            onChange={(e, value) =>
              selectFilter("category", value === null ? [] : value)
            }
          />
        </Grid>
        <Grid item xs={2}>
          <FormHelperText id="creator_filter">Creator</FormHelperText>
          <Autocomplete
            limitTags={1}
            multiple
            size="small"
            disablePortal
            id="creator_filter"
            options={
              filters.creator && Object.keys(filters).length > 0
                ? filters.creator.map((obj) => obj.speaker_name)
                : []
            }
            sx={classes.filter}
            loading={isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            getOptionLabel={(option) => option || ""}
            value={selectedFilters["creator"]}
            onChange={(e, value) =>
              selectFilter("creator", value === null ? [] : value)
            }
          />
        </Grid>
        <Grid item xs={2}>
          <FormHelperText id="species_filter">Species</FormHelperText>
          <Autocomplete
            limitTags={1}
            multiple
            size="small"
            disablePortal
            id="species_filter"
            options={
              filters.species && Object.keys(filters).length > 0
                ? filters.species.map((obj) => obj.value)
                : []
            }
            sx={classes.filter}
            loading={isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            getOptionLabel={(option) => option || ""}
            value={selectedFilters["species"]}
            onChange={(e, value) =>
              selectFilter("species", value === null ? [] : value)
            }
          />
        </Grid>
        <Grid item xs={2} >
          <LoadingButton
              variant="contained"
              sx={classes.filterButton}
              onClick={handleApplyFiltersClick}
              endIcon={<FilterAlt />}
              loading={isLoading}
              loadingPosition="end"
              style={{ textTransform: "none" }}
          >
              Apply
          </LoadingButton>
        </Grid>
        <Grid item xs={2}>
          <Tooltip title="Clear Filters" arrow>
            <IconButton
              color="primary"
              aria-label="clear filter"
              component="span"
              sx={classes.clearAllButton}
              onClick={handleClearFilter}
            >
              <ClearAll />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="content_section" style={{marginLeft: 15}}>
        {cardArray}
      </Grid>
      {courses !== undefined &&
        Object.keys(courses).length > 0 &&
        courses.results.length > 0 && (courses.count && Math.ceil(Number(courses.count) / 10) > 1 &&
          <Pagination
            count={courses.count ? Math.ceil(Number(courses.count) / 10) : 0}
            page={pageNumber}
            onChange={handlePageChange}
            sx={classes.pagination}
            color="primary"
            hidePrevButton
            hideNextButton
          />
        )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  filters: state.CoursesAdminReducer.filters,
  selectedFilters: state.CoursesAdminReducer.selectedFilters,
  courses: state.CoursesAdminReducer.courses,
  pageNumber: state.CoursesAdminReducer.pageNumber,
  isLoading: state.CoursesAdminReducer.isLoading,
  deleteCourseStatus: state.CoursesAdminReducer.deleteCourseStatus,
});

const mapDispatchToProps = (dispatch) => ({
  getFilterValues: () => dispatch(getFilterValues()),
  selectFilter: (filterType, filterValue) =>
    dispatch(selectFilter(filterType, filterValue)),
  getCourses: (filterDetails, pageNumber) =>
    dispatch(getCourses(filterDetails, pageNumber)),
  changePage: (pageNumber) => dispatch(changePage(pageNumber)),
  changeCourseStatus: (courseDetails) =>
    dispatch(changeCourseStatus(courseDetails)),
  deleteCourse: (courseId) => dispatch(deleteCourse(courseId)),
  getCourseDetailsForEdit: (courseDetails) =>
    dispatch(getCourseDetailsForEdit(courseDetails)),
  setActiveCourse: (id) => dispatch(setActiveCourse(id)),
  fetchCurriculumData: (id) => dispatch(fetchCurriculumData(id)),
  toggleEditFlow: (val) => dispatch({ type: TOGGLE_EDIT_FLOW, payload: val }),
  createEditData: (obj) => dispatch({ type: CREATE_COURSE_EDIT_DETAILS, payload: obj })
});

export default connect(mapStateToProps, mapDispatchToProps)(CoursesLayout);
