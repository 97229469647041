import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  FormHelperText,
  Typography,
  Grid,
  Box,
  Modal
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
import { classes } from "./styles";
import "./styles.css";

const AddLesson = ({
  handleAddLessonDialogClose,
  isAddLessonOpen,
  isLoading,
  type,
  createEditLesson,
  activeCourse,
  activeChapter,
  setEditModal,
  editModal,
  tempEdit,
  setTempEdit,
  selection,
  createEditChapter
}) => {
  const [lessonTitle, setLessonTitle] = useState("");
  const [fileToBeUploaded, setFileToBeUploaded] = useState(null);

  const handleDialogClose = () => {
    setLessonTitle("");
    setFileToBeUploaded(null);
    handleAddLessonDialogClose();
    setEditModal(false)
  };

  const handleUpload = () => {
    const tempObject = {
      course_id: activeCourse,
      course_level1_id: activeChapter,
      course_level2_id: null,
      content_name: lessonTitle,
      file_type: type === "audio" ? "Audio" : type === "video" ? "Video" : type === "quiz" ? "Quiz" : "Reading Material",
      duration: type === "audio" ? "100 sec" : type === "video" ? "200 sec" : type === "quiz" ? "300 sec" : "600 sec",
    };
    const lessonObject = new FormData();
    lessonObject.append(`data`, JSON.stringify(tempObject));
    lessonObject.append("file_url", fileToBeUploaded);
    lessonObject.append("is_file_updated", true);
    createEditLesson(lessonObject, false);
    // setTimeout(() => handleDialogClose(), 3000);
    handleDialogClose();
  };

  const handleEditUpload = () => {

    if (selection.course_level1_id) {
      const tempObject = {
        "course_id": activeCourse,
        "course_level1_id": selection.course_level1_id,
        "content_name": tempEdit
      }
      // const chapterObject = new FormData();
      // chapterObject.append(`data`, JSON.stringify(tempObject));
      createEditChapter(tempObject, true);
      handleDialogClose();
    }
    else {
      const tempObject = {
        course_id: activeCourse,
        course_level1_id: activeChapter,
        course_level2_id: selection.course_level2_id,
        content_name: tempEdit,
        file_type: type === "audio" ? "Audio" : type === "video" ? "Video" : type === "quiz" ? "Quiz" : "Reading Material",
        duration: type === "audio" ? "100 sec" : type === "video" ? "200 sec" : type === "quiz" ? "300 sec" : "600 sec",
      };
      const lessonObject = new FormData();
      lessonObject.append(`data`, JSON.stringify(tempObject));
      lessonObject.append("file_url", fileToBeUploaded);
      lessonObject.append("is_file_updated", fileToBeUploaded ? true : false);
      createEditLesson(lessonObject, true);
      // setTimeout(() => handleDialogClose(), 3000);
      handleDialogClose();
    }
  };

  const onDropAccepted = useCallback((files) => {
    setFileToBeUploaded(files[0]);
    // if (!isOnFileDetailsPage) {
    //   setIsFileSelected(true);
    //   selectFile(files[0]);
    //   selectArticle({});
    // } else selectFile(files[0]);
  });
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
  };
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({

      maxSize: type === "pdf" ? 26214400 : type === "audio" ? 52428800 : 524288000,
      accept: type === "pdf" ? ".pdf" : `${type}/*`,
      maxFiles: 1,
      onDropRejected: () => {
        alert("File size limit exceeded.")
        return false
      },
      onDropAccepted,
    });
  if (selection && selection.course_level1_id && editModal) {
    return (
      <Box sx={modalStyle}>
        <Grid container xs={12}>
          <Grid item sm={12}>
            <Typography color="primary" sx={{ fontSize: 20, fontWeight: 600, marginBottom: 2 }}>
              Change chapter name</Typography>
          </Grid>
          <Grid item sm={12} sx={{ marginBottom: 4 }}>
            <TextField
              size="small"
              id="lesson_title"
              placeholder="Enter lesson name"
              variant="outlined"
              className="lesson_title"
              sx={classes.lessonTitle}
              inputProps={{ maxLength: 300, }}
              value={tempEdit}
              onChange={(e) => setTempEdit(e.target.value)}
            />
          </Grid>
          <Grid container sm={12} justifyContent="right" spacing={2}>
            <Button onClick={handleDialogClose}>Cancel</Button>
            {<LoadingButton
              onClick={handleEditUpload}
              autoFocus
              variant="contained"
              sx={classes.uploadBtn}
              disabled={!tempEdit}
              endIcon={<Save />}
              loading={isLoading}
              loadingPosition="end"
            >
              Save changes
            </LoadingButton>
            }
          </Grid>

        </Grid>
      </Box>
    )
  }
  return (
    <>
      <Dialog
        open={isAddLessonOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert_dialog_title"
        aria-describedby="alert_dialog_description"
        sx={classes.dialog}
      >
        <DialogContent sx={classes.dialogContent}>
          <DialogContentText id="alert_dialog_description">
            {/* <FormHelperText id="lesson_title">Category</FormHelperText> */}
            <Typography color="primary" sx={{ fontSize: 26, fontWeight: 600, marginBottom: 2 }}>
              {editModal ? "Edit lesson" : "Create lesson"}</Typography>

            {editModal ? <TextField
              size="small"
              id="lesson_title"
              placeholder="Enter lesson name"
              variant="outlined"
              className="lesson_title"
              sx={classes.lessonTitle}
              inputProps={{ maxLength: 300, }}
              value={tempEdit}
              onChange={(e) => setTempEdit(e.target.value)}
            /> :
              <TextField
                size="small"
                id="lesson_title"
                placeholder="Enter lesson name"
                variant="outlined"
                inputProps={{ maxLength: 300, }}
                className="lesson_title"
                sx={classes.lessonTitle}
                value={lessonTitle}
                onChange={(e) => setLessonTitle(e.target.value)}
              />}
            <p
              className="file_selection_heading"
              style={classes.fileSelectionHeading}
            >
              {editModal ? "Change media" : "Upload content here"}
            </p>
            <section className="container">
              <div
                {...getRootProps({ className: "dropzone" })}
                style={
                  // selectedFile
                  //   ? classes.fileSelectionDivWithFileSelected
                  //   :
                  classes.fileSelectionDiv
                }
              >
                <input {...getInputProps()} />
                {editModal ? <p>Drop your new file here</p> : <p>Drop your file here</p>}
                <p>
                  or{" "}
                  <span
                    style={
                      // selectedFile
                      //   ? classes.browseSpanWithFileSelected
                      //   :
                      classes.browseSpan
                    }
                  >
                    Browse
                  </span>
                </p>
                <em>
                  Only {type === "pdf" ? ".pdf" : `${type}`} files are accepted
                </em>
              </div>
            </section>
            {/* <hr style={classes.divider} /> */}
          </DialogContentText>
          {
            editModal ?
              <Typography>
                {
                  !fileToBeUploaded
                    ? (selection.sas_file_path &&
                      selection.sas_file_path.split("?")[0].split("/")
                      [selection.sas_file_path.split("?")[0].split("/").length - 1])
                    : fileToBeUploaded.name
                }
              </Typography>
              :
              <Typography>
                {
                  !fileToBeUploaded
                    ? "No file selected"
                    : fileToBeUploaded.name
                }
              </Typography>
          }
        </DialogContent>
        <Grid container md={12}>
          <Grid item sm={6} sx={{ paddingLeft: 2 }}>
            <Grid container sm={12}>
              <Grid item sm="auto">
                <Typography sx={{ color: "#a4a4a4", fontSize: 14 }}>. Supported formats-</Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography sx={{ color: "black", fontSize: 14, marginLeft: 1 }}>
                  {type === "video" ? "mp4,mov" :
                    type === "audio" ? "mp3,wav" :
                      "pdf"}

                </Typography>
              </Grid>
            </Grid>
            <Grid container sm={12}>
              <Grid item sm="auto">
                <Typography sx={{ color: "#a4a4a4", fontSize: 14 }}>. Maximum size-</Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography sx={{ color: "black", fontSize: 14, marginLeft: 1 }}>
                  {type === "video" ? "500 MB" :
                    type === "audio" ? "50 MB" :
                      "25 MB"}
                </Typography>
              </Grid>
            </Grid>


          </Grid>
          <Grid container sm={6} justifyContent="right">
            <Button onClick={handleDialogClose}>Cancel</Button>
            {editModal ? <LoadingButton
              onClick={handleEditUpload}
              autoFocus
              variant="contained"
              sx={classes.uploadBtn}
              disabled={!tempEdit}
              endIcon={<Save />}
              loading={isLoading}
              loadingPosition="end"
            >
              Save changes
            </LoadingButton>
              :
              <LoadingButton
                onClick={handleUpload}
                autoFocus
                variant="contained"
                sx={classes.uploadBtn}
                disabled={!lessonTitle || !fileToBeUploaded}
                endIcon={<Save />}
                loading={isLoading}
                loadingPosition="end"
              >
                Save
              </LoadingButton>}
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default AddLesson;
