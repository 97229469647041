import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ReactPlayer from "react-player";

const getContentUI = (heading, data) => {
  return (
    <div
      style={{
        marginTop: "20px",
      }}
    >
      <span>
        <b>{heading}</b>
      </span>
      <br />
      <span>{data}</span>
      <br />
    </div>
  );
};

const WebinarDetail = () => {
  return (
    <div style={{ margin: "10px" }}>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <b>Webinar Name</b>
      </span>
      <div
        // src="https://picsum.photos/350/350"
        // alt="Paris"
        style={{
          display: "block",
          marginTop: "20px",
          marginLeft: "auto",
          marginRight: "auto",
          height: "450px",
          width: "50%",
        }}
      >
        <ReactPlayer url="https://www.youtube.com/watch?v=ysz5S6PUM-U" />
      </div>

      {getContentUI("Speaker Name", "Lorem ipsum dolor sit amet")}
      {getContentUI("Organised By", "Lorem ipsum dolor sit amet")}
      {getContentUI("Date and Time", "Lorem ipsum dolor sit amet")}
      {getContentUI(
        "Webinar Description",
        <>
          <li>Example 1</li>
          <li>Example 2</li>
          <li>Example 3</li>
        </>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "0.75rem",
        }}
      >
        <Typography>Have any doubts?</Typography>
        <Typography>
          <b>&nbsp;&nbsp;&nbsp;&nbsp;Post your doubts</b>
        </Typography>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button variant="contained" disableElevation>
          RSVP
        </Button>
      </div>
    </div>
  );
};

export default WebinarDetail;
