import {
    FETCH_COURSES_DATA, SET_COURSES_DATA, SET_COURSES_USER_DATA, CLEAR_COURSES_DATA, SET_COURSES_FAILURE_DATA,
    SET_ACTIVE_COURSE_DATA, CLEAR_ACTIVE_COURSE_DATA, SET_QUIZ_DATA, FETCH_QUIZ_DATA, TOGGLE_QUIZ_ACTIVATION
} from "../../ActionTypes/coursesVetActionTypes"

import { SET_QUERY_ADDED_ALERT, CLEAR_QUERY_ADDED_ALERT, SET_GENERIC_ERROR_ALERT } from "../../ActionTypes/alertsActionTypes"
import axios from "axios"

//Login 
export const getQuizDetails = (id) => async dispatch => {


    try {
        dispatch({ type: SET_QUIZ_DATA, payload: null })
        dispatch({ type: FETCH_QUIZ_DATA, payload: true })
        let reqObj = {
            "course_level2_id": id
        }

        let url = process.env.REACT_APP_API_URL + `/vetcourses/quiz_details/`




        let result = await axios.post(url, reqObj)

        if (result.data && result.data.length > 0) {


            dispatch({ type: SET_QUIZ_DATA, payload: result.data })
            dispatch({ type: FETCH_QUIZ_DATA, payload: false })
            dispatch({ type: TOGGLE_QUIZ_ACTIVATION, payload: { quizActive: 1 } })





        }
        else {
            dispatch({ type: SET_GENERIC_ERROR_ALERT })

            dispatch({ type: FETCH_QUIZ_DATA, payload: false })

        }



    }
    catch (err) {
        dispatch({ type: FETCH_QUIZ_DATA, payload: false })
        dispatch({ type: SET_GENERIC_ERROR_ALERT })
    }
}



export const getQuizDetailsAdmin = (id) => async dispatch => {


    try {
        dispatch({ type: SET_QUIZ_DATA, payload: null })
        dispatch({ type: FETCH_QUIZ_DATA, payload: true })
        let reqObj = {
            "course_level2_id": id
        }

        let url = process.env.REACT_APP_API_URL + `/courses/admin/quiz_details/`




        let result = await axios.post(url, reqObj)

        if (result.data && result.data.length > 0) {


            dispatch({ type: SET_QUIZ_DATA, payload: result.data })
            dispatch({ type: FETCH_QUIZ_DATA, payload: false })
            dispatch({ type: TOGGLE_QUIZ_ACTIVATION, payload: { quizActive: 1 } })





        }
        else {
            dispatch({ type: SET_GENERIC_ERROR_ALERT })

            dispatch({ type: FETCH_QUIZ_DATA, payload: false })

        }



    }
    catch (err) {
        dispatch({ type: FETCH_QUIZ_DATA, payload: false })
        dispatch({ type: SET_GENERIC_ERROR_ALERT })
    }
}




