import {
    CALL_COMPLETED,
    CATCH_ERROR,
    START_CALL,
} from "../ActionTypes/homepageVetActionTypes";

const initialState = {
    isLoading: false,
    error: null,
    message: null,
    logout: {
        learning_content_details: { results: [] },
        webinar_details: { results: [] },
        popular_courses: { results: [] },
    },
    login: {
        course_details: { results: [] },
        webinar_details: { results: [] },
        learning_content_details: { results: [] },
        popular_courses: { results: [] },
    },
};

const HomepageVetReducer = (state = initialState, action) => {
    const { type, payload } = action;
    

    switch (type) {
        case START_CALL:
            return {
                ...state,
                message: null,
                isLoading: true,
                error: null,
            };
        case CALL_COMPLETED:
            return {
                ...state,
                ...payload,
                isLoading: false,
                error: false,
            };
        case CATCH_ERROR:
            return {
                ...state,
                isLoading: false,
                message: payload.error.clientMessage,
                error: payload.error,
            };

        default:
            return state;
    }
};

export default HomepageVetReducer;
