export const classes = {
  iconButton: { float: "right", marginRight: "2%" },
  heading: {
    display: "block",
    margin: "0 1%",
    textAlign: "center",
    color: "#250996",
  },
  chip: { margin: "0 1%", backgroundColor: "#917de2", color: "white" },
  contentDetails: { display: "inline-block", margin: "0 1%" },
  contentDescription: { color: "grey", margin: "1%" },
  document: { width: "100%", textAlign: "center" },
  page: { width: "100%", margin: "10%", border: "1px solid black" },
  rating: { verticalAlign: "middle" },
  contentDetailsSection: { margin: "1% 0" },
  breadcrumbs: { margin: "1%" },
  activeCrumb: { color: "#250996" },
  inactiveCrumb: { cursor: "pointer" },
};
