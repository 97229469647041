import React, { useState } from "react";
import {
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  MenuItem,
  CardActionArea,
  CardMedia,
  Menu,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Rating,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { classes } from "./styles";

const CourseCard = ({
  course,
  index,
  type,
  changeCourseStatus,
  fetchCourses,
  pageNumber,
  history,
  getCourseDetailsForEdit,
  fetchCurriculumData,
  setActiveCourse,
  deleteCourse,
  toggleEditFlow,
  createEditData
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  //   const handleAgreeDelete = () => {
  //     fileDelete(article.learning_content_id);
  //     setTimeout(() => fetchLearningContent(pageNumber), 3000);
  //     setOpenDialog(false);
  //   };

  //   const handleCancelDelete = () => {
  //     setOpenDialog(false);
  //   };

  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = Boolean(anchorEl);

  const handleCloseMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleEditClick = (e) => {
    setActiveCourse(course.course_id);

    let countriesArray = course.course_country.split(",").map(item => ({ value: item }))
    const objectToBeEdited = {
      data: {
        course_title: course.course_title,
        course_subtitle: course.course_subtitle,
        course_category: course.course_category,
        course_language: course.course_language,
        course_country: countriesArray,
        species: course.species,
        course_description: course.course_description,
        is_published: course.is_published,
        speaker: course.speaker,
      },
      course_image_path: course.sas_image_path,
      course_prevvideo_path: course.sas_video_path,
    };
    toggleEditFlow(true)
    createEditData(course)
    fetchCurriculumData(course.course_id)
    getCourseDetailsForEdit(objectToBeEdited);

    history.push("/newCourse");
    handleCloseMenu(e);
  };

  const handleDeleteCourseClick = () => {
    deleteCourse(course.course_id);
    setOpenDialog(false);
  };

  const handleDeleteClick = (e) => {
    handleCloseMenu(e);
    setOpenDialog(true);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleSwitchStatus = (e, course) => {
    changeCourseStatus({
      is_published: type && type.toLowerCase() === "published" ? "UNPUBLISHED" : "PUBLISHED",
      course_id: course.course_id,
    });
    handleCloseMenu(e);
    setTimeout(() => fetchCourses(pageNumber), 3000);
  };

  return (
    <>
      <Grid item xs={3} key={index}>
        <Card onClick={handleEditClick} sx={classes.card}>
          <CardActionArea>
            <CardMedia
              component="img"
              sx={classes.image}
              image={course.sas_image_path}
              alt="course image"
            />
            <IconButton
              color="default"
              aria-label="card actions"
              component="span"
              sx={classes.iconButton}
              onClick={handleMenuClick}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id={`basic_menu_${index}`}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleEditClick}>Edit</MenuItem>
              {type && type.toLowerCase() === "published" && <MenuItem
                onClick={(e) => handleSwitchStatus(e, course)}
                sx={classes.actionItem}
              >
                Unpublish course
              </MenuItem>}
              <MenuItem onClick={handleDeleteClick}>Delete course</MenuItem>
            </Menu>
            <CardContent>
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                sx={classes.title}
              >
                {course.course_title}
              </Typography>
              <Typography variant="subtitle1" sx={classes.subtitle}>
                {course.course_category}
              </Typography>
              <Typography variant="subtitle1" sx={classes.subtitle}>
                {course.speaker.map((obj) => obj.speaker_name).join(", ")}
              </Typography>
              <Rating
                name="read-only"
                value={course.avg_rating}
                readOnly
                sx={classes.rating}
              />
              <hr style={classes.divider} />
              <Typography variant="subtitle1" sx={classes.subtitle}>
                Completion Ratio
              </Typography>
              <Typography variant="subtitle1" sx={classes.ratio}>
                {course.completion_ratio}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent sx={classes.dialogContent}>
          Do you want to delete the course?
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleDeleteCourseClick}>
            Yes
          </Button>
          <Button variant="outlined" onClick={() => setOpenDialog(false)}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CourseCard;
