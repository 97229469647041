import axios from "axios";


//setting the token in local storage if it is generated from the backend via login api
const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `bearer ${token}`;
    localStorage.setItem("token", token);
  } else {
    delete axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("token");
    localStorage.removeItem("userType") 
    localStorage.removeItem("adminType")
  }
};

export default setAuthToken;
