import axios from "axios";
import {
    createCourseFailure,
    createCourseRequest,
    createCourseSuccess,
    createEditChapterFailure,
    createEditChapterRequest,
    createEditChapterSuccess,
    createEditLessonFailure,
    createEditLessonRequest,
    createEditLessonSuccess,
    setActiveCourse,
    setCurriculumDetails,
    toggleAlert,
} from "../../ActionCreators/coursesAdminActionCreators";



export const addOption = (lessonDetails) => (dispatch) => {
    const CREATE_EDIT_LESSON_URL =
        process.env.REACT_APP_API_URL + "/curriculum/admin/edit_quizaddoption/";

    dispatch(createEditLessonRequest());
    axios
        .post(CREATE_EDIT_LESSON_URL, lessonDetails)
        .then((response) => {
            if (response.status === 201) {
                dispatch(createEditLessonSuccess());

                dispatch(toggleAlert("success", "option added successfully !", true));


                dispatch(setCurriculumDetails(response.data));
            } else {
                dispatch(createEditLessonFailure("Something went wrong !"));
                dispatch(toggleAlert("error", "Something went wrong !", true));
            }
        })
        .catch((error) => {
            dispatch(createEditLessonFailure(error.message));
            dispatch(toggleAlert("error", error.message, true));
        });
};

export const deleteOption = (id) => (dispatch) => {

    const CREATE_DELETE_OPTION_URL =
        process.env.REACT_APP_API_URL + `/curriculum/admin/delete_option/${id}/`;


    dispatch(createEditLessonRequest());
    axios
        .delete(CREATE_DELETE_OPTION_URL)
        .then((response) => {
            if (response.status === 200) {
                dispatch(createEditLessonSuccess());

                dispatch(toggleAlert("success", "option deleted successfully !", true));


                dispatch(setCurriculumDetails(response.data));
            } else {
                dispatch(createEditLessonFailure("Something went wrong !"));
                dispatch(toggleAlert("error", "Something went wrong !", true));
            }
        })
        .catch((error) => {
            dispatch(createEditLessonFailure(error.message));
            dispatch(toggleAlert("error", error.message, true));
        });
};

export const deleteQuestion = (quiz_id) => (dispatch) => {

    const CREATE_DELETE_QUESTION_URL =
        process.env.REACT_APP_API_URL + `/curriculum/admin/delete_question/${quiz_id}/`;


    dispatch(createEditLessonRequest());
    axios
        .delete(CREATE_DELETE_QUESTION_URL)
        .then((response) => {
            if (response.status === 200) {
                dispatch(createEditLessonSuccess());

                dispatch(toggleAlert("success", "Question deleted successfully !", true));


                dispatch(setCurriculumDetails(response.data));
            } else {
                dispatch(createEditLessonFailure("Something went wrong !"));
                dispatch(toggleAlert("error", "Something went wrong !", true));
            }
        })
        .catch((error) => {
            dispatch(createEditLessonFailure(error.message));
            dispatch(toggleAlert("error", error.message, true));
        });
};



export const addQuestion = (lessonDetails) => (dispatch) => {
    const CREATE_EDIT_LESSON_URL =
        process.env.REACT_APP_API_URL + "/curriculum/admin/edit_quizaddques/";

    dispatch(createEditLessonRequest());
    axios
        .post(CREATE_EDIT_LESSON_URL, lessonDetails)
        .then((response) => {
            if (response.status === 201) {
                dispatch(createEditLessonSuccess());

                dispatch(toggleAlert("success", "question added successfully !", true));


                dispatch(setCurriculumDetails(response.data));
            } else {
                dispatch(createEditLessonFailure("Something went wrong !"));
                dispatch(toggleAlert("error", "Something went wrong !", true));
            }
        })
        .catch((error) => {
            dispatch(createEditLessonFailure(error.message));
            dispatch(toggleAlert("error", error.message, true));
        });
};

export const changeQuestion = (lessonDetails) => (dispatch) => {
    const CREATE_EDIT_LESSON_URL =
        process.env.REACT_APP_API_URL + "/curriculum/admin/edit_question/";

    dispatch(createEditLessonRequest());
    axios
        .post(CREATE_EDIT_LESSON_URL, lessonDetails)
        .then((response) => {
            if (response.status === 200) {
                dispatch(createEditLessonSuccess());

                dispatch(toggleAlert("success", "question name changed successfully !", true));


                dispatch(setCurriculumDetails(response.data));
            } else {
                dispatch(createEditLessonFailure("Something went wrong !"));
                dispatch(toggleAlert("error", "Something went wrong !", true));
            }
        })
        .catch((error) => {
            dispatch(createEditLessonFailure(error.message));
            dispatch(toggleAlert("error", error.message, true));
        });
};

export const changeQuizName = (lessonDetails) => (dispatch) => {
    const CREATE_EDIT_LESSON_URL =
        process.env.REACT_APP_API_URL + "/curriculum/admin/edit_quizname/";

    dispatch(createEditLessonRequest());
    axios
        .post(CREATE_EDIT_LESSON_URL, lessonDetails)
        .then((response) => {
            if (response.status === 200) {
                dispatch(createEditLessonSuccess());

                dispatch(toggleAlert("success", "quiz name changed successfully !", true));


                dispatch(setCurriculumDetails(response.data));
            } else {
                dispatch(createEditLessonFailure("Something went wrong !"));
                dispatch(toggleAlert("error", "Something went wrong !", true));
            }
        })
        .catch((error) => {
            dispatch(createEditLessonFailure(error.message));
            dispatch(toggleAlert("error", error.message, true));
        });
};