import axios from "axios";
import {
  createCourseFailure,
  createCourseRequest,
  createCourseSuccess,
  setActiveCourse,
  toggleAlert,
} from "../../ActionCreators/coursesAdminActionCreators";
import { NAVIGATE_ADMIN_ON_COURSE_SAVE,CREATE_COURSE_EDIT_DETAILS,SET_SPEAKER_IDS_FOR_EDIT } from "../../ActionTypes/coursesAdminActionTypes";

const CREATE_COURSE_URL =
  process.env.REACT_APP_API_URL + "/courses/admin/edit_course/";

export const editCourse = (courseDetails) => (dispatch) => {
  dispatch(createCourseRequest());
  axios
    .post(CREATE_COURSE_URL, courseDetails, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(createCourseSuccess());
        dispatch(toggleAlert("success", "Course edited successfully !", true));
        dispatch({ type: CREATE_COURSE_EDIT_DETAILS, payload: response.data })
        dispatch({ type: SET_SPEAKER_IDS_FOR_EDIT, payload: response.data })
      } else {
        dispatch(createCourseFailure("Something went wrong !"));
        dispatch(toggleAlert("error", "Error while editing !", true));
      }
    })
    .catch((error) => {
      dispatch(createCourseFailure(error.message));
      dispatch(toggleAlert("error", error.message, true));
    });
};
