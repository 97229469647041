import React from "react";
import {
    Grid,
    TextField,
    InputAdornment,
    Box,
    Typography,
    Modal,
    CardActionArea,
} from "@mui/material";
import Stack from '@mui/material/Stack';
import { borderRadius } from "@mui/system";
import Pagination from "@mui/material/Pagination";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Rating from "@mui/material/Rating";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import BookmarkIcon from "@mui/icons-material/Bookmark";

const CourseBoard = ({ courseData, isProgress }) => {
    const history = useHistory();
    const styles = {
        media: {
            height: 0,
            paddingTop: "56.25%", // 16:9
        },
        card: {
            position: "relative",
            borderRadius: 12,
            lineHeight: '1',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)'
        },
        overlay: {
            color: "white",
            filter: "drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5))",
        },
        textOverlay: {
            color: "white",
            filter: "drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5))",
        },
        cardcontent: {
            backgroundColor: "#FAFAFA",
            padding: 16,
            "&:last-child": {
                paddingBottom: 0,
            },
        },
        cardText: {
            fontSize: "14px",
            lineHeight: "18px",
            color: "#363636",
            margin: "0 0 4px"
        },
        button3: {
            filter: "drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5))",

            margin: "5px",
            color: "white",
        },
    };

    const formatDuration = (val) => {
        let strDuration = val ? val.split(":")[0] : "";
        let courseDuration =
            Number(strDuration) === 0 ? 1 : Number(strDuration);
        let durationFormated = isNaN(courseDuration)
            ? courseDuration
            : courseDuration === 1
                ? "1 hour"
                : `${courseDuration} hours`;
        return durationFormated;
    };

    return (




        <Card sx={{ maxWidth: "100%" }} style={styles.card}>
            {/* <CardActionArea
            // style={{
            //   height: 140,
            //   width: "100%",
            //   backgroundImage: `url(${courseData.sas_image_path})`,
            //   display: "flex",
            //   justifyContent: "flex-end",
            //   backgroundRepeat: "space",
            //   backgroundSize: "cover"
            // }}
            > */}
                <CardMedia
                    component="img"
                    sx={{ height: 140, }}
                    image={courseData.sas_image_path}
                    alt="course image"
                    onClick={() => history.push(`/course/${courseData.course_id}`)}
                />

               



                {/* <MoreVertIcon
                onClick={e => { handleClick(e, courseData) }}
                style={styles.button3}
              /> */}
              <Stack spacing={0} direction="row" sx={{alignItems: 'center', position: 'absolute', top: '100px'}}>
                <IconButton onClick={() => {  history.push(`/course/${courseData.course_id}`) }} style={styles.overlay} aria-label="play/pause">
                        <PlayCircleFilledWhiteIcon sx={{ height: 20, width: 20 }} />
                </IconButton>
                <Typography noWrap sx={{ fontSize: 14, fontWeight: 400, margin:'3px 0 0 0' }} style={styles.textOverlay}>{formatDuration(courseData.course_duration)}</Typography>
              </Stack>





                <CardContent onClick={() => {  history.push(`/course/${courseData.course_id}`) }} style={styles.cardcontent}>
                    <Typography noWrap sx={{ color: "#0f01a0", fontWeight: 700, fontSize: '16px', lineHeight: '22px', marginBottom: '4px' }}>{courseData.course_title}</Typography>
                    <Typography noWrap sx={styles.cardText}>{courseData.course_category}</Typography>
                    <Typography noWrap sx={styles.cardText}>{courseData.speaker.length > 0 ? (courseData.speaker.map(item => (item.speaker_name)).join(",")) : "No data"}</Typography>
                    <Typography noWrap sx={styles.cardText}>{courseData.course_language}</Typography>
                    <Rating sx={{ fontSize: 20, color: "#6ad7b9", }}
                        name="read-only" value={courseData.avg_rating} readOnly size="small" />

                </CardContent>
            {/* </CardActionArea> */}
        </Card>

    );
};

export default CourseBoard;

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress
                    color="secondary"
                    variant="determinate"
                    {...props}
                />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography
                    variant="body2"
                    color="text.secondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}
