export const classes = {
  progress: {
    float: "right",
  },
  button: {
    width: "90%",
    margin: "2% 5%",
  },
  card: {
    padding: "5%",
    margin: "5%",
    background: "#f2f2f2",
  },
  heading: {
    color: "#0f01a0",
    fontSize: "105%",
  },
  subheading: {
    color: "#0f01a0",
    margin: "4% 0",
  },
  details: {
    fontSize: "90%",
  },
};
