import axios from "axios";
import {
  fetchFilterValuesFailure,
  fetchFilterValuesRequest,
  fetchFilterValuesSuccess,
  toggleAlert,
} from "../../ActionCreators/learningContentAdminActionCreators";

const GET_FILTER_VALUES_URL =
  process.env.REACT_APP_API_URL + "/learningcontent/admin/dropdown/";

export const getFilterValues = () => (dispatch) => {
  dispatch(fetchFilterValuesRequest());
  axios
    .get(GET_FILTER_VALUES_URL)
    .then((response) => {
      if (response.status === 200)
        dispatch(fetchFilterValuesSuccess(response.data));
      else {
        dispatch(fetchFilterValuesFailure("Something went wrong !"));
        dispatch(toggleAlert("error", "Something went wrong !", true));
      }
    })
    .catch((error) => {
      dispatch(fetchFilterValuesFailure(error.message));
      dispatch(toggleAlert("error", error.message, true));
    });
};
