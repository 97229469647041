export const LOGIN_OPEN="LOGIN_OPEN"
export const LOGIN_CLOSE="LOGIN_CLOSE"
export const SIGNUP_OPEN="SIGNUP_OPEN"
export const SIGNUP_CLOSE="SIGNUP_CLOSE"
export const PROFILEFORM_OPEN="PROFILEFORM_OPEN"
export const PROFILEFORM_CLOSE="PROFILEFORM_CLOSE"
export const FORGETPASSWORD_OPEN="FORGETPASSWORD_OPEN"
export const FORGETPASSWORD_CLOSE="FORGETPASSWORD_CLOSE"
export const OTPSCREEN_OPEN="OTPSCREEN_OPEN"
export const OTPSCREEN_CLOSE="OTPSCREEN_CLOSE"
export const NEWPASSWORDSCREEN_OPEN="NEWPASSWORDSCREEN_OPEN"
export const NEWPASSWORDSCREEN_CLOSE="NEWPASSWORDSCREEN_CLOSE"
export const TOGGLE_BACK_BUTTON="TOGGLE_BACK_BUTTON"
