import {
    FETCH_COURSES_DATA, SET_COURSES_DATA, SET_COURSES_USER_DATA, CLEAR_COURSES_DATA, SET_COURSES_FAILURE_DATA,
    SET_ACTIVE_COURSE_DATA, CLEAR_ACTIVE_COURSE_DATA,
    SET_COURSES_DATA_PAGINATION, RATING_UPDATE_COURSES
} from "../../ActionTypes/coursesVetActionTypes"

import { SET_QUERY_ADDED_ALERT, CLEAR_QUERY_ADDED_ALERT, SET_GENERIC_ERROR_ALERT } from "../../ActionTypes/alertsActionTypes"

import axios from "axios"

//Login 
export const getActiveVetCourses = (id) => async dispatch => {


    try {
        dispatch({ type: CLEAR_COURSES_DATA })
        dispatch({ type: RATING_UPDATE_COURSES, payload: null })
        dispatch({ type: FETCH_COURSES_DATA })
        let reqObj = { "course_id": id }

        let url = process.env.REACT_APP_API_URL + `/vetcourses/sideview/`




        let result = await axios.post(url, reqObj)

        if (result.data.sas_image_path) {


            dispatch({ type: SET_ACTIVE_COURSE_DATA, payload: { isEnrolled: false, data: result.data } })






        }
        else {
            dispatch({ type: RATING_UPDATE_COURSES, payload: result && result.data && result.data[0] && result.data[0].user_rating })
            dispatch({ type: SET_ACTIVE_COURSE_DATA, payload: { isEnrolled: true, data: result.data } })

        }



    }
    catch (err) {
        dispatch({ type: CLEAR_COURSES_DATA })
        dispatch({ type: SET_GENERIC_ERROR_ALERT })
    }
}

