import {
    CALL_COMPLETED,
    CATCH_ERROR,
    START_CALL,
    SELECT_FILTER,
    CLEAR_QUERY_LIST
} from "../ActionTypes/queriesVetActionTypes";

const initialState = {
    isLoading: false,
    error: null,
    message: null,
    dropdowns: {
        category: [],
        type: [],
        duration:{ value: "All time" },
    },
    selectedFilters: {
        category: [],
        type: [],
        duration:{ value: "All time" },
        search: "",
    },
    queryDetails: { results: [] },
    addedQuery: {}, //for storing new query
    repliedQuery: {}, //for storing query reply/answer
};

const QueriesVetReducer = (state = initialState, action) => {
    const { type, payload } = action;
    

    switch (type) {
        case START_CALL:
            return {
                ...state,
                message: null,
                isLoading: true,
                error: null,
            };
        case CALL_COMPLETED:
            return {
                ...state,
                ...payload,
                isLoading: false,
                error: false,
            };
        case CATCH_ERROR:
            return {
                ...state,
                isLoading: false,
                message: payload.error.clientMessage,
                error: payload.error,
            };

        case SELECT_FILTER:
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    [payload.type]: payload.value,
                },
            };

        case CLEAR_QUERY_LIST:
            return{
                ...state,
                queryDetails: { results: [] }
            }

        default:
            return state;
    }
};

export default QueriesVetReducer;
