import React from "react";
import { connect } from "react-redux";
import {Route,Redirect} from "react-router-dom"
import SpinnerLG from "./SpinnerLG";

const PrivateRoute =({component:Component,isAuthenticated,loading,...rest})=>(
  <Route {...rest} render={props=>(loading)?(<SpinnerLG/>):isAuthenticated? (<Component {...props} />):(<Redirect to="/login" />)
 } />
)

//Component to display the content only if the user is authenticated else redirect him to the login page



const mapStateToProps = (state) => ({
    isAuthenticated: state.authenticationReducer.isAuthenticated,
    loading: state.authenticationReducer.loading
  })
  
  
  
  
  
  export default connect(mapStateToProps)(PrivateRoute)