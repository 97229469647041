import axios from "axios";
import {
    //   fetchWEBINARSFailure,
    //   fetchWEBINARSRequest,
    fetchWebinarsSuccess,

    //   toggleAlert,
} from "../../ActionCreators/webinarsAdminActionCreators";
import {
    TOGGLE_VET_WEBINAR_ENROLLMENT_STATUS
} from "../../ActionTypes/webinarsVetActionTypes"

import { toggleGlobalAlert } from "../../ActionCreators/alertsActionCreators";

// const GET_WEBINARS_URL =
//   process.env.REACT_APP_API_URL + "/WEBINARS/admin/course_homepage/?page=";


export const webinarAttendance = (id) => async dispatch => {

    try {

        let url = process.env.REACT_APP_API_URL + `/vetwebinars/attendance/`

        let reqObj = {
            "webinar_id" : id
           
        }


        let result = await axios.post(url, reqObj)

        if (result.data) {



            if (result.data.success) {
                // dispatch(toggleGlobalAlert("success", "Registered successfully", true))
            }
            else {
                // dispatch(toggleGlobalAlert("warning", result.data.message, true))
            }




        }
        else {

            dispatch(toggleGlobalAlert("warning", "Something went wrong", true))
        }



    }
    catch (err) {
        dispatch(toggleGlobalAlert("warning", "Something went wrong", true))
    }
}


