import { getTouchRippleUtilityClass } from "@mui/material";
import {
  CLEAR_LOGIN_FAILURE_ALERT,
  SET_LOGIN_FAILURE_ALERT, SET_SIGNUP_EXISTS_ALERT,
  CLEAR_SIGNUP_EXISTS_ALERT, CLEAR_FORGOT_PASS_ALERT, SET_FORGOT_PASS_ALERT,
  SET_QUERY_ADDED_ALERT, CLEAR_QUERY_ADDED_ALERT, SET_GENERIC_ERROR_ALERT, CLEAR_GENERIC_ERROR_ALERT,
  SET_BOOKMARK_ADDED_ALERT, CLEAR_BOOKMARK_ADDED_ALERT, TOGGLE_PROCESS_LOADER,TOGGLE_GLOBAL_ALERT
} from "../ActionTypes/alertsActionTypes"



const initialState = {
  loginErrorAlert: false,
  signupExistAlert: false,
  forgotPasswordAlert: false,
  queryAddedLCAlert: false,
  genericAlert: false,
  bookmarkAddedAlert: false,
  processLoader: false,
  globalAlertOpen: false,
  globalAlertType: false,
  globalAlertMessage: false,

};

function AlertsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOGIN_FAILURE_ALERT:
      return {
        ...state,
        loginErrorAlert: true

      };
    case CLEAR_LOGIN_FAILURE_ALERT:
      return {
        ...state,
        loginErrorAlert: false

      };
    case TOGGLE_GLOBAL_ALERT:
      return {
        ...state,
        globalAlertOpen: payload.value,
        globalAlertType: payload.type,
        globalAlertMessage: payload.message,
      };
    case SET_SIGNUP_EXISTS_ALERT:
      return {
        ...state,
        signupExistAlert: true


      }
    case CLEAR_SIGNUP_EXISTS_ALERT:
      return {
        ...state,
        signupExistAlert: false

      }
    case CLEAR_FORGOT_PASS_ALERT:
      return {
        ...state,
        forgotPasswordAlert: false

      }
    case SET_FORGOT_PASS_ALERT:
      return {
        ...state,
        forgotPasswordAlert: true

      }
    case SET_QUERY_ADDED_ALERT:
      return {
        ...state,
        queryAddedLCAlert: true

      }
    case SET_GENERIC_ERROR_ALERT:
      return {
        ...state,
        genericAlert: true

      }
    case CLEAR_QUERY_ADDED_ALERT:
      return {
        ...state,
        queryAddedLCAlert: false

      }
    case CLEAR_GENERIC_ERROR_ALERT:
      return {
        ...state,
        genericAlert: false

      }
    case SET_BOOKMARK_ADDED_ALERT:
      return {
        ...state,
        bookmarkAddedAlert: true

      }
    case CLEAR_BOOKMARK_ADDED_ALERT:
      return {
        ...state,
        bookmarkAddedAlert: false

      }
    case TOGGLE_PROCESS_LOADER:
      return {
        ...state, ...payload

      }
    default:
      return state;
  }
}

export default AlertsReducer;
