import React, { useState, useEffect } from 'react'
import { styled, alpha } from "@mui/material/styles";
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ScrollToTopButton = ({ showBelow }) => {



    const [show, setShow] = useState(showBelow ? false : true)

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true)
        } else {
            if (show) setShow(false)
        }
    }

    const handleClick = () => {
        window[`scrollTo`]({ top: 0, behavior: `smooth` })
    }
    // adding event lister on component mount and removing it on unmount
    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })

    return (
        <div>
            {show && <Fab aria-label="to top" component="span" onClick={handleClick} style={{ position: "fixed", zIndex: 2, bottom: "8vh", right: "2%" }} size="medium" color="#fff" aria-label="add">
                <KeyboardArrowUpIcon sx={{ color: "green" }} />
            </Fab>

            }
        </div>
    )
}
export default ScrollToTopButton