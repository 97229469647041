import React from "react";
import { Grid, TextField, InputAdornment, Box, Typography, Modal, CardActionArea } from "@mui/material"
import { borderRadius } from "@mui/system";
import Pagination from '@mui/material/Pagination';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Rating from '@mui/material/Rating';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import BookmarkIcon from '@mui/icons-material/Bookmark';




const CoursesView = ({ coursesList, currentPage, handlePagination, totalCount, addBookmarkCourses, addQueryCourses }) => {

  const [isModalOpen, setModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [queryError, setQueryError] = React.useState("");
  const [queryText, setQueryText] = React.useState("");
  const [currentSelection, setCurrentSelection] = React.useState({})

  const history = useHistory()

  const handleCloseQuery = () => {
    setAnchorEl(null);
    setModal(true)
  };
  const handleCloseModal = () => { setQueryError(""); setQueryText(""); setModal(false) };
  const handleCloseBookmark = () => {
    setAnchorEl(null);
    addBookmarkCourses(currentSelection.course_id, currentSelection.bookmark === "N" ? "Y" : "N", coursesList)
  };
  const handleQuery = () => {
    if (queryText) {
      addQueryCourses(currentSelection.course_id, currentSelection.course_category, queryText)
      setQueryError("")
      setQueryText("")
      handleCloseModal()
    }
    else {
      setQueryError("Please enter a query")
    }

  }


  const open = Boolean(anchorEl);
  const handleClick = (event, item) => {
    setCurrentSelection(item)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const styles = {
    media: {
      height: 0,
      paddingTop: '56.25%' // 16:9
    },
    card: {
      position: 'relative',
      borderRadius: 12
    },
    overlay: {
      position: 'absolute',
      top: '90px',
      left: '10px',
      color: 'white',
      filter: "drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5))",

    },
    textOverlay: {
      position: 'absolute',
      top: '110px',
      left: '60px',
      color: 'white',
      filter: "drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5))",

    },
    cardcontent: {
      backgroundColor: "#f2f2f2",
      padding: 8,
      "&:last-child": {
        paddingBottom: 0
      }
    },
    button3: {


      filter: "drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5))",

      margin: "5px", color: "white"
    }
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: "100%", md: 400 },
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: 1,
    boxShadow: 24,
    p: 2,
  };

  //formatting date to display on course card
  const formatDuration = (val) => {
    let strDuration = val ? val.split(":")[0] : ""
    let courseDuration = Number(strDuration) === 0 ? 1 : Number(strDuration)
    let durationFormated = isNaN(courseDuration) ? courseDuration : courseDuration === 1 ? "1 hour" : (`${courseDuration} hours`)
    return (durationFormated)
  }



  return (






    <Grid container >

      {coursesList && coursesList.length > 0 ? (<> {coursesList.map(courseData => (
        <Grid item xs={12} sm={3} sx={{ marginBottom: 2 }} >

          <Card sx={{ maxWidth: "100%", margin: { xs: .5, sm: 1 } }} style={styles.card}>
            <CardActionArea
            // style={{
            //   height: 140,
            //   width: "100%",
            //   backgroundImage: `url(${courseData.sas_image_path})`,
            //   display: "flex",
            //   justifyContent: "flex-end",
            //   backgroundRepeat: "space",
            //   backgroundSize: "cover"
            // }}
            >
              <CardMedia
                component="img"
                sx={{ height: 140, }}
                image={courseData.sas_image_path}
                alt="course image"
              />

              {/* <IconButton
                color="default"
                aria-label="card actions"
                component="span"
                style={styles.button3}
                sx={{
                  float: "right",
                  marginRight: "2%",
                  marginTop: "2%",
                  position: "absolute",
                  top: "0%",
                  right: "0%",
                  color: "white",
                }}
                onClick={e => { handleClick(e, courseData) }}
              >
                <MoreVertIcon />
              </IconButton> */}



              {/* <MoreVertIcon
                onClick={e => { handleClick(e, courseData) }}
                style={styles.button3}
              /> */}
              <IconButton onClick={() => { history.push(`/course/${courseData.course_id}`) }} style={styles.overlay} aria-label="play/pause">
                <PlayCircleFilledWhiteIcon sx={{ height: 38, width: 38 }} />
              </IconButton>
              <Typography sx={{ fontSize: 16, fontWeight: 600 }} style={styles.textOverlay}>{formatDuration(courseData.course_duration)}</Typography>





              <CardContent onClick={() => {  history.push(`/course/${courseData.course_id}`) }} style={styles.cardcontent}>
                <Typography noWrap sx={{ color: "#0f01a0", fontWeight: 600, fontSize: 16, marginBottom: .2 }}>{courseData.course_title}</Typography>
                <Typography sx={{ color: "#363636", marginBottom: .2, fontSize: 14 }}>{courseData.course_category}</Typography>
                <Typography sx={{ color: "#363636", marginBottom: .2, fontSize: 14 }}>{courseData.speaker.length > 0 ? (courseData.speaker.map(item => (item.speaker_name)).join(",")) : "No data"}</Typography>
                <Typography sx={{ color: "#363636", marginBottom: .2, fontSize: 14 }}>{courseData.course_language}</Typography>
                <Rating sx={{ fontSize: { xs: 20, md: 25 }, color: "#6ad7b9", }}
                  name="read-only" value={courseData.avg_rating} readOnly size="small" />

              </CardContent>

            </CardActionArea>
          </Card>


        </Grid>
      ))}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          // open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleCloseBookmark}>{currentSelection.bookmark === "Y" ? <BookmarkIcon sx={{ color: "#0f01a0" }} /> : <BookmarkBorderIcon sx={{ color: "#0f01a0" }} />}<Typography sx={{ color: "#0f01a0" }}>{currentSelection.bookmark === "N" ? "Add to bookmarks" : "Bookmarked"}</Typography></MenuItem>
          {/* <MenuItem onClick={handleCloseQuery}>Ask a query</MenuItem> */}
          {/* <MenuItem onClick={handleClose}>Add feedback</MenuItem> */}
        </Menu>
        <Grid container sx={{ paddingBottom: 9 }} spacing={0} justifyContent="center" alignItems="center"  >
          {Math.ceil(totalCount / 10) > 1 && <Grid item xs={12}>
            <Pagination hideNextButton hidePrevButton sx={{ marginTop: 2, marginLeft: "40%" }} page={currentPage} onChange={handlePagination} count={Math.ceil(totalCount / 10)} color="secondary" />
          </Grid>}
        </Grid> </>)
        :
        (<div style={{marginLeft: 40}}>No courses enrolled!</div>)}

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container >
            <Grid container item xs={12} sm={12}>
              <Grid container justifyContent="right" item xs={12} sm={12}>
                <IconButton color="primary" onClick={handleCloseModal}>
                  <CloseIcon sx={{ fontSize: 35 }} />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography sx={{ marginBottom: 1, color: "#0f01a0", fontSize: 26, fontWeight: 600 }} id="modal-modal-title">
                
              </Typography>
              {queryError && <Typography sx={{ marginBottom: 1, color: "red" }} id="modal-modal-title"  >
                {queryError}
              </Typography>}
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="outlined-multiline-static"
                inputProps={{ maxLength: 300 }}
                fullWidth
                onChange={(e) => setQueryText(e.target.value)}
                multiline
                rows={4}
                placeholder="Type your query here"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button fullWidth onClick={handleQuery} sx={{ marginTop: 1 }} variant="contained">Submit</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

    </Grid>


  );
};

export default CoursesView;
