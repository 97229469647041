import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TableComponent from "./TableComponent";


import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";

import { getManagementFilters, getManagementData, getExportData } from "../Redux/Middlewares/UserManagement/getManagementFilters";
import { selectFilterManagement } from "../Redux/ActionCreators/managementActionCreators";
import Chip from '@mui/material/Chip';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { vetValidation } from "../Redux/Middlewares/UserManagement/getManagementFilters";
import { deleteVet } from "../Redux/Middlewares/UserManagement/getManagementFilters";
import CategoriesLayout from "./CategoriesLayout";
import { getCategories, editCategory, createCategory, deleteCategory } from "../Redux/Middlewares/UserManagement/getManagementFilters";


const useStyles = makeStyles((theme) => ({
  customTabRoot: {
    color: "black",
  },
  customTabIndicator: {
    backgroundColor: "black",
  },
  selected: {
    fontWeight: "bold",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const UserManagement = ({ getManagementFilters, getManagementData, isLoading, dataSet, selectFilterManagement,
  filters, selectedFilters, current, total, vetValidation, deleteVet, pendingUsers, categoriesData, getCategories,
  editCategory, createCategory, deleteCategory, currTabValue,getExportData,exportData }) => {

  const classes = useStyles();
  const [value, setValue] = useState(0)
  const [selected, setSelected] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [openHomeDialog, setOpenHomeDialog] = useState(false);
  const [action, setAction] = useState("Reject");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let reqObj = {
    "status": value === 0 ? "Vet" : value === 1 ? "Admin" : value === 3 ? "Pending" : "",
    "search": [searchText],
    "show_user_from": {
      "fixed": selectedFilters.show_user_from.value,
      "start_date": null,
      "end_date": null
    }
  }

  let categoriesObj = {
    "status": "Category",
    "search": [searchText],
    "show_user_from": {
      "fixed": "All time",
      "start_date": null,
      "end_date": null
    }

  }

  let userObj = {
    "user_id": selected,
    "status": action
  }

  //performing user related actions
  const handleUsers = () => {
    if (action === "delete") {
      let delObj = {
        "user_id": selected
      }
      deleteVet(delObj, reqObj)
    }
    else {
      vetValidation(userObj, reqObj)
    }


    setSelected([])


  }






  useEffect(() => {
    getManagementFilters()
    // getManagementData(reqObj)

    if (value === 0 || value === 1 || value === 3) {
      getExportData()
      getManagementData(reqObj)
      setSelected([])
    }

  }, [value])

  useEffect(() => {
    if (currTabValue == 3) {
      setValue(currTabValue)
    }
  }, [currTabValue])


  return (
    <Box sx={{ width: "100%", paddingLeft: 2, paddingRight: 2 }}>
      <Box >
        <Tabs
          sx={{ paddingTop: 2 }}
          value={value}
          onChange={handleChange}
          // textColor="inherit"
          classes={{
            root: classes.customTabRoot,
            indicator: classes.customTabIndicator,
          }}
        >
          <Tab
            style={{ textTransform: "none" }}
            classes={{
              selected: classes.selected,
            }}
            label="Vets"
          />
          <Tab
            style={{ textTransform: "none" }}
            classes={{
              selected: classes.selected,
            }}
            label="Admin"
          />
          <Tab
            style={{ textTransform: "none" }}
            classes={{
              selected: classes.selected,
            }}
            label="Master Admin"
          />
          <Tab
            sx={{ height: 2, paddingRight: 0 }}
            style={{ textTransform: "none" }}
            classes={{
              selected: classes.selected,
            }}
            label="Pending Users"
          // icon={<Chip  sx={{backgroundColor:"#9999d9",color:"white"}} label={10} />} iconPosition="end" 
          />
          <Chip size="small" sx={{ backgroundColor: "#9999d9", color: "white", marginTop: 1 }} label={pendingUsers} />
          <Tab
            style={{ textTransform: "none" }}
            classes={{
              selected: classes.selected,
            }}
            label="Categories"
          />
        </Tabs>
      </Box>
      {/* <TabPanel value={value} index={0}> */}

      {(value === 5 || value === 2) ? (value === 2 && <>No data</>) : <TableComponent isLoading={isLoading} dataSet={dataSet} selectFilterManagement={selectFilterManagement}
        filters={filters} selectedFilters={selectedFilters} current={current} total={total} getManagementData={getManagementData}
        reqObj={reqObj} selected={selected} setSelected={setSelected} searchText={searchText} setSearchText={setSearchText}
        action={action} setAction={setAction} setOpenHomeDialog={setOpenHomeDialog} value={value}
        pendingUsers={pendingUsers} exportData={exportData} />}


      {/* </TabPanel> */}
      <TabPanel value={value} index={5}>
        <CategoriesLayout categoriesData={categoriesData} isLoading={isLoading} total={total}
          current={current} categoriesObj={categoriesObj} getCategories={getCategories}
          editCategory={editCategory} createCategory={createCategory} deleteCategory={deleteCategory}
          searchText={searchText} setSearchText={setSearchText} />
      </TabPanel>



      <Dialog
        open={openHomeDialog}
        // onClose={() => setOpenHomeDialog(false)}
        maxWidth={"lg"}
      >
        <DialogTitle
          style={{
            padding: "10px 15px",
            justifyContent: "flex-end",
            display: "flex",
            backgroundColor: "#CCCCFF",
          }}
        >
          <CloseIcon
            fontSize="small"
            style={{ cursor: "pointer" }}
            onClick={() => setOpenHomeDialog(false)}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert_dialog_description"
            style={{ fontWeight: "bold", marginTop: "10px" }}
          >
            Do you want to  {action && action.toLowerCase()}  {selected.length > 1 ? "these" + " " : "this" + " "}
            {selected.length} {selected.length > 1 ? "users" : "user"}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {
            <Button
              variant="contained"
              onClick={() => {
                setOpenHomeDialog(false);
                handleUsers()

              }}
              style={{ textTransform: "none", height: 30 }}
            >
              Yes,{" " + action}
            </Button>
          }
          <Button
            variant="outlined"
            style={{ textTransform: "none", height: 30 }}
            onClick={() => setOpenHomeDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};


const mapStateToProps = (state) => ({
  filters: state.ManagementReducer.filters,
  selectedFilters: state.ManagementReducer.selectedFilters,
  current: state.ManagementReducer.current,
  total: state.ManagementReducer.total,
  isLoading: state.ManagementReducer.isLoading,
  dataSet: state.ManagementReducer.dataSet,
  pendingUsers: state.ManagementReducer.pendingUsers,
  categoriesData: state.ManagementReducer.categoriesData,
  currTabValue: state.ManagementReducer.tabValue,
  exportData:state.ManagementReducer.exportData
});

const mapDispatchToProps = (dispatch) => ({
  getManagementFilters: () => dispatch(getManagementFilters()),
  getManagementData: (obj, page) => dispatch(getManagementData(obj, page)),
  selectFilterManagement: (type, value) => dispatch(selectFilterManagement(type, value)),
  vetValidation: (userList, reqObj) => dispatch(vetValidation(userList, reqObj)),
  deleteVet: (userList, reqObj) => dispatch(deleteVet(userList, reqObj)),
  getCategories: (obj, page) => dispatch(getCategories(obj, page)),
  editCategory: (userList, reqObj) => dispatch(editCategory(userList, reqObj)),
  createCategory: (userList, reqObj) => dispatch(createCategory(userList, reqObj)),
  deleteCategory: (userList, reqObj) => dispatch(deleteCategory(userList, reqObj)),
  getExportData: () => dispatch(getExportData())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);