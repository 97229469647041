import axios from "axios";
import {
  fetchCoursesFailure,
  fetchCoursesRequest,
  fetchCoursesSuccess,
  toggleAlert,
} from "../../ActionCreators/coursesAdminActionCreators";

const GET_COURSES_URL =
  process.env.REACT_APP_API_URL + "/courses/admin/course_homepage/?page=";

export const getCourses =
  (filterDetails, pageNumber = 1) =>
  (dispatch) => {
    dispatch(fetchCoursesRequest());
    axios
      .post(GET_COURSES_URL + pageNumber, filterDetails)
      .then((response) => {
        if (response.status === 200)
          dispatch(fetchCoursesSuccess(response.data));
        else {
          dispatch(fetchCoursesFailure("Something went wrong !"));
          dispatch(toggleAlert("error", "Something went wrong !", true));
        }
      })
      .catch((error) => {
        dispatch(fetchCoursesFailure(error.message));
        dispatch(toggleAlert("error", error.message, true));
      });
  };
