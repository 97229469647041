import {
    CALL_COMPLETED,
    CATCH_ERROR,
    SELECT_FILTER,
    SET_CHECKBOX_STATE,
    UPDATE_CHECKBOX_STATE,
    START_CALL,
} from "../ActionTypes/queriesAdminActionTypes";

const initialState = {
    isLoading: false,
    error: null,
    message: null,
    filters: {
        category: [],
        type: [],
        country: [],
        language: [],
        duration: [],
        Visibility: [],
    },
    selectedFilters: {
        category: [],
        type: [],
        country: [],
        language: [],
        duration: { value: "All time" },
        Visibility: [],
        search: "",
    },
    queryDetails: { results: [] },
    queryAnswer: {},
    currCheckboxObj:{}
};

const QueriesAdminReducer = (state = initialState, action) => {
    const { type, payload } = action;
    

    switch (type) {
        case START_CALL:
            return {
                ...state,
                message: null,
                isLoading: true,
                error: null,
            };
        case CALL_COMPLETED:
            return {
                ...state,
                ...payload,
                isLoading: false,
                error: false,
            };
        case CATCH_ERROR:
            return {
                ...state,
                isLoading: false,
                message: payload.error.clientMessage,
                error: payload.error,
            };

        case SELECT_FILTER:
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    [payload.type]: payload.value,
                },
            };

        case SET_CHECKBOX_STATE:
            
            const {tempObj} = payload;
           
            return {
                ...state,
                currCheckboxObj: tempObj
            }
            break;

        case UPDATE_CHECKBOX_STATE:
            return{
                ...state,
                currCheckboxObj:{
                    ...state.currCheckboxObj,
                    ["checkbox_"+payload]: !state.currCheckboxObj["checkbox_"+payload]
                }
                
            }
            break;

        default:
            return state;
    }
};

export default QueriesAdminReducer;
