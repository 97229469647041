import axios from "axios";
import {
    callCompleted,
    catchError,
    startCall,
} from "../ActionCreators/queriesAdminActionCreators";
import { SET_CHECKBOX_STATE, UPDATE_CHECKBOX_STATE } from "../ActionTypes/queriesAdminActionTypes";

const baseUrl = process.env.REACT_APP_API_URL;

export const fetchAdminQueriesDropdowns = () => (dispatch) => {
    dispatch(startCall());
    axios
        .get(`${baseUrl}/queries/dropdown/`)
        .then((response) => dispatch(callCompleted({ filters: response.data })))
        .catch((error) => {
            error.clientMessage = "Failed to fetch admin queries dropdowns";
            dispatch(catchError(error));
        });
};

export const fetchAdminQueryDetails = (request,pageNumber=1) => async dispatch => {
    dispatch(startCall());
    // axios
    //     .post(`${baseUrl}/queries/querydetails/?page=`+pageNumber, request)
    //     .then((response) =>
    //         dispatch(callCompleted({ queryDetails: response.data }))           
    //     )
    //     .catch((error) => {
    //         error.clientMessage = "Failed to fetch admin query details";
    //         dispatch(catchError(error));
    //     });

        try {
            dispatch(startCall())
            let url = baseUrl+`/queries/querydetails/?page=`+pageNumber;
            let response = await axios.post(url, request);
            dispatch(callCompleted({
                queryDetails: response.data
            }));
            if (response.data) {
                let tempObj = {};
                response.data.results.map(e => {
                    tempObj["checkbox_"+e['query_id']] =  false;
                })
                dispatch({ 
                    type: SET_CHECKBOX_STATE, 
                    payload: {tempObj}
                })
            }
        }
        catch (err) {
                err.clientMessage = "Failed to get query details";
                dispatch(catchError(err));
        }
};

export const updateAdminQueryAnswer = (request, currFilterObject, pageNumber=1) => (dispatch) => {
    dispatch(startCall());
    return axios
        .post(`${baseUrl}/queries/answer_query/`, request)
        .then((response) => {
            dispatch(callCompleted({ queryAnswer: response.data }))
            return axios.post(`${baseUrl}/queries/querydetails/?page=`+pageNumber, currFilterObject)
                .then((response) =>
                    
                    dispatch(callCompleted({ queryDetails: response.data }))
            )
        });
};

export const updateAdminQueryArchive = (request,currFilterObject) => async dispatch => {
    try {
        dispatch(startCall())
        let url = baseUrl+`/queries/archived_query/`;
        let result = await axios.post(url, request);
        dispatch(callCompleted());
        if (result.data) {
            let url2 = baseUrl + `/queries/querydetails/`;
            let result2 = await axios.post(url2, currFilterObject)
            dispatch(callCompleted({ queryDetails: result2.data }))
        }
    }
    catch (err) {
            err.clientMessage = "Failed to update admin query archive";
            dispatch(catchError(err));
    }
}

export const deleteArchivedQueryAdmin = (request,currFilterObject) => async dispatch => {
        try {
            dispatch(startCall())
            let url = baseUrl+`/queries/delete_query/`;
            let result = await axios.post(url, request);
           
            dispatch(callCompleted());
            if (result) {
                let url2 = baseUrl + `/queries/querydetails/`;
                let result2 = await axios.post(url2, currFilterObject)
                dispatch(callCompleted({ queryDetails: result2.data }))
            }
        }
        catch (err) {
                err.clientMessage = "Failed to update admin query archive";
                dispatch(catchError(err));
        }
};

//function for setting default state of checkboxes
export const setCheckboxes = (tempObj) =>  async dispatch => {
    
    dispatch({ 
        type: SET_CHECKBOX_STATE, 
        payload: {tempObj} 
    })
}

//function for updating the checkboxes state
export const updateCheckboxState = (currCheckBoxID) => async dispatch => {
    dispatch({ 
        type: UPDATE_CHECKBOX_STATE, 
        payload: currCheckBoxID 
    })
}

