import react from "react"
import CircularProgress from '@mui/material/CircularProgress';

const SpinnerLG=()=>{

    return(<div>
        <CircularProgress size={100} sx={{ color: "#6ad7b9", zIndex: 2, top: "50%", left: "35%", position: "fixed"}} 
          color="primary" />
    </div>)
}

export default SpinnerLG