

import {
    FETCH_COURSES_DATA, SET_COURSES_DATA, SET_COURSES_USER_DATA, CLEAR_COURSES_DATA, SET_COURSES_FAILURE_DATA,
    SET_ACTIVE_COURSE_DATA, CLEAR_ACTIVE_COURSE_DATA,
    SET_COURSE_CERTIFICATE_PDF
} from "../../ActionTypes/coursesVetActionTypes"

import { SET_QUERY_ADDED_ALERT, CLEAR_QUERY_ADDED_ALERT, SET_GENERIC_ERROR_ALERT } from "../../ActionTypes/alertsActionTypes"


import axios from "axios"

//Login 
export const getCourseCertificate = (id) => async dispatch => {


    try {

        let reqObj = { "course_id": id }

        let url = process.env.REACT_APP_API_URL + `/vetcourses/certificate/?course_id=${id}`




        let result = await axios.get(url)
        // const url1 = window.URL.createObjectURL(new Blob([result.data]));
        // const link = document.createElement('a');
        // link.href = url1;
        // link.setAttribute('download', 'file.pdf'); //or any other extension
        // document.body.appendChild(link);
        // link.click();

        dispatch({ type: SET_COURSE_CERTIFICATE_PDF, payload: result.data })



    }
    catch (err) {

        dispatch({ type: SET_GENERIC_ERROR_ALERT })
    }
}

