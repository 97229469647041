import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useHistory, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import CoursesView from "./CoursesView";
import { connect } from "react-redux"
import { makeStyles } from "@mui/styles";
import {
    Grid, IconButton, TextField, InputAdornment, Chip, Drawer, MenuItem, Select, OutlinedInput,
    Stack, Button
} from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ScrollToTopButton from "../VetLearningContent/ScrollToTopButton"
import { getVetCourses } from ".././Redux/Middlewares/VetCourses/getCoursesVetMiddleware"
import SpinnerLG from "../SpinnerLG";
import { Autocomplete } from "@mui/material"
import { getFiltersVetCourses } from "../Redux/Middlewares/VetCourses/getFiltersVetCoursesMiddleware";
import { SET_USER_COURSE_FILTER_DATA, CLEAR_USER_COURSE_FILTER_DATA } from "../Redux/ActionTypes/coursesVetActionTypes"
import { getActiveVetCourses } from "../Redux/Middlewares/VetCourses/getActiveCourseMiddleware"
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import ReactPlayer from 'react-player/lazy'

const styles = {
    media: {
        height: 0,
        paddingTop: '56.25%' // 16:9
    },
    card: {
        position: 'relative',
    },
    overlay: {
        position: 'absolute',
        top: "35%",
        left: "46%",
        color: 'white',

    },
    textOverlay: {
        position: 'absolute',
        top: '110px',
        left: '60px',
        color: 'white',

    },
    cardcontent: {
        padding: 8,
        "&:last-child": {
            paddingBottom: 0
        }
    }
}


const UnenrolledView = ({ contents, enrollToCourse }) => {

    let strDuration = (contents && contents.course_duration) ? contents.course_duration.split(":")[0] : ""
    let courseDuration = Number(strDuration) === 0 ? 1 : Number(strDuration)
    let durationFormatted = isNaN(courseDuration) ? courseDuration : courseDuration === 1 ? "1 hour" : (`${courseDuration} hours`)

    return (<>
        <Box sx={{ padding: 1.5, marginTop: 4 }}>
            <Grid container>
                <Grid justifyContent="center" container xs={12} sm={12}>
                    <Typography sx={{ marginTop: 1, marginBottom: 0.5, fontSize: 19, fontWeight: 600, color: "black" }}> {contents.course_title}</Typography>
                </Grid>
                {(contents.sas_video_path !== "Null" && contents.sas_video_path) ?
                    <Grid container justifyContent="center" xs={12} sm={12} sx={{ paddingLeft: { xs: 0, sm: 30 }, paddingRight: { xs: 0, sm: 30 } }}>
                        <ReactPlayer config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                            onContextMenu={e => e.preventDefault()}
                            width="100%" height="100%" controls={true} url={contents.sas_video_path} />
                    </Grid>

                    :

                    <Grid item justifyContent="center" xs={12} sm={12} style={styles.card}
                        sx={{ paddingLeft: { xs: 1, md: 20 }, paddingRight: { xs: 1, md: 20 } }}>
                        <img src={contents.sas_image_path}
                            style={{
                                height: "auto",
                                width: "80%",
                                margin: "0 auto",
                                borderRadius: 10,
                                // backgroundImage: `url(${contents.sas_image_path})`,
                                display: "flex",
                                justifyContent: "flex-end",
                                backgroundRepeat: "space",
                                backgroundSize: "cover"
                            }}
                        />

                        <div>

                            <IconButton style={styles.overlay} aria-label="play/pause">
                                <PlayCircleFilledWhiteIcon size="large" sx={{ height: 60, width: 60 }} />
                            </IconButton>


                        </div>


                    </Grid>}

                <Grid justifyContent="center" container xs={12} sm={12}>
                    <Typography sx={{ marginTop: 3, fontSize: 18, fontWeight: 600, color: "#8080cf" }}> Course Creator</Typography>
                </Grid>
                <Grid justifyContent="center" container xs={12} sm={12}>
                    <Typography sx={{ marginBottom: 0, fontSize: 16, fontWeight: 100, color: "#525252" }}> {contents.speaker.map(
                        item => item.speaker_name
                    ).join(",")}</Typography>
                </Grid>


                <Grid justifyContent="center" container xs={12} sm={12}>
                    <Typography sx={{ marginTop: 3, fontSize: 18, fontWeight: 600, color: "#8080cf" }}> Course Language</Typography>
                </Grid>
                <Grid justifyContent="center" container xs={12} sm={12}>
                    <Typography sx={{ marginBottom: 0, fontSize: 16, fontWeight: 100, color: "#525252" }}> {contents.course_language}</Typography>
                </Grid>


                <Grid justifyContent="center" container xs={12} sm={12}>
                    <Typography sx={{ marginTop: 3, fontSize: 18, fontWeight: 600, color: "#8080cf" }}> Course Duration</Typography>
                </Grid>
                <Grid justifyContent="center" container xs={12} sm={12}>
                    <Typography sx={{ marginBottom: 0, fontSize: 16, fontWeight: 100, color: "#525252" }}> {durationFormatted}</Typography>
                </Grid>



                <Grid justifyContent="center" container xs={12} sm={12}>
                    <Typography sx={{ marginTop: 3, fontSize: 18, fontWeight: 600, color: "#8080cf" }}> Course Description</Typography>
                </Grid>
                <Grid justifyContent="center" container xs={12} sm={12} sx={{ paddingLeft: 10, paddingRight: 10 }}  >
                    <Typography sx={{ marginBottom: 20, fontSize: 16, fontWeight: 100, color: "#525252", }}> {contents.course_description}</Typography>
                </Grid>

            </Grid>

        </Box>
        <Box sx={{ justifyContent: "center", display: "flex",cursor: "pointer"  }} onClick={() => { enrollToCourse(contents.course_id) }}>
            <Box  sx={{ width: {sm:200,xs:"100%"}, height: 50, color: "white", backgroundColor: "#0f01a0",  bottom: "0%", position: "fixed", }}>
                

            </Box>
            <Typography sx={{ color: "white", zIndex: 2, bottom: "2vh", position: "fixed", fontWeight: 600, fontSize: 18 }} >Enroll</Typography>
        </Box></>)


}

export default UnenrolledView