import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Checkbox from '@mui/material/Checkbox';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Tooltip,
  TextField,
  FormHelperText,
  InputAdornment
} from "@mui/material";
import { Prompt, useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Save } from "@mui/icons-material";
import {
  SelectLayout,
  TextLayout,
  MultiLineTextLayout,
  FileLayout,
} from "./InputTypes";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./cssStyles.css"
import { getDropDownValues } from "../../Redux/Middlewares/WebinarsAdmin/getCreateWebinarDropDownValuesMiddleware";
import { connect } from "react-redux";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { TOGGLE_BACK_BUTTON } from "../../Redux/ActionTypes/NavbarActionTypes";
import { Delete } from "@mui/icons-material";
import moment from "moment";
import { setMinutes, setHours } from "date-fns";
import { createNewWebinar } from "../../Redux/Middlewares/WebinarsAdmin/createWebinarMiddleware";
import { WEBINAR_NAVIGATION } from "../../Redux/ActionTypes/webinarsAdminActionTypes";
import LoadingButton from '@mui/lab/LoadingButton';
import { Redirect } from "react-router-dom";
import { SET_WEB_EDIT } from "../../Redux/ActionTypes/webinarsAdminActionTypes";
import { editWebinar } from "../../Redux/Middlewares/WebinarsAdmin/editWebinarMiddleware";
import { Box } from "@mui/system";


const CustomInput = ({ value, onClick, placeholderText }) => (
  <div
    placeholder={placeholderText}
    style={{
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      height: 40,
      border: "1px solid #D3D3D3",
      cursor: "pointer",
      borderRadius: "5px",
    }}
    onClick={onClick}
  >
    {value ? (
      <span>{value}</span>
    ) : (
      <span style={{ color: "#999", fontSize: 12 }}>{placeholderText}</span>
    )}

    <ArrowDropDown sx={{ color: "#0000008a" }} />
  </div>
);

const years = new Date().getFullYear();
let yearArray = new Array();
for (let i = years; i < years + 5; i++) {
  yearArray[i] = i;
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];


const CreateWebinar = ({ getDropDownValues, dropDowns, toggleBackButton, createNewWebinar, webinarNavigation,
  webinarNav, webinarSaving, toggleEdit, editFlow, editedData, editWebinar, currentWebinar }) => {
  const [speakerCount, addSpeakerCount] = useState(editFlow && editedData ?

    editedData.speaker.map(item => ({ ...item }))

    : [{ speaker_name: "", speaker_description: "" }]);
  const [teaserCount, addTeaserCount] = useState(editFlow && editedData ?
    editedData.teaser.map(item => {
      let tObj = {
        teaser_description: item.teaser_description,
        teaser_release_date: moment(item.teaser_release_date, "DD-MM-YYYY").toDate(),
        media: item.sas_teaser_path,
        teaser_id: item.teaser_id
      }
      return tObj
    })
    :
    [{ teaser_description: "", teaser_release_date: "", media: "" }]);

  const [startDate, setStartDate] = useState(editFlow && editedData ? moment(editedData.webinar_date, "DD-MM-YYYY").toDate() : null);
  const [teaserMedia, setTeaserMedia] = useState({});
  //image states////
  const [collaboratorLogo, setCollaboratorLogo] = useState(editFlow && editedData ? editedData.sas_logo_path : null);
  const [webinarImage, setWebinarImage] = useState(editFlow && editedData ? editedData.sas_webinarimage_path : null);
  const [webinarInvitation, setWebinarInvitation] = useState(editFlow && editedData ? editedData.sas_invitation_path : null);
  const [checked, setChecked] = useState(editFlow && editedData && editedData.disable_enrollment === "Y" ? true : false);

  // const [endTime, setEndTime] = useState()
  // const [startDate, setStartDate] = useState()
  // const [startTime, setStartTime] = useState()

  const [startTime, setStartTime] = useState(editFlow && editedData ? moment(editedData.webinar_start_time, "HH:mm").toDate() : null);
  const [endTime, setEndTime] = useState(editFlow && editedData ? moment(editedData.webinar_end_time, "HH:mm").toDate() : null);
  const [files, setFiles] = useState([]);
  const [webinarTeasersFiles, setWebinarTeasersFiles] = useState([]);
  const [textInputs, setTextInputs] = useState(editFlow && editedData ?
    {
      ["Webinar Title"]: editedData.webinar_title, ["Webinar URL"]: editedData.webinar_link,
      ["Veterinary association/Collaborator (Optional)"]: editedData.veterinary_association
    }


    : []);
  const [multiLineTextInputs, setMultiLineTextInputs] = useState(editFlow && editedData ? { ["Webinar Description"]: editedData.webinar_description } : []);
  const [
    webinarTeasersMultiLineTextInputs,
    setWebinarTeasersMultiLineTextInputs,
  ] = useState([]);
  const [dropDownInputs, setDropDownInputs] = useState(editFlow && editedData ?
    {
      "Species": { value: editedData.webinar_species },
      "Language of Webinar": { value: editedData.webinar_language },
      "Webinar Category": { category_name: editedData.webinar_category },
      "Country for this webinar": { value: editedData.webinar_country }
    }

    : []);
  const [error, setError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openHomeDialog, setOpenHomeDialog] = useState(false);
  const [toPublish, setPublish] = useState("UNPUBLISHED");

  const [logoUpdate, setLogoUpdate] = useState(false);
  const [webinarImageUpdate, setWebinarImageUpdate] = useState(false);
  const [invitationImageUpdate, setInvitationImageUpdate] = useState(false);


  let fullEdit = editedData && editedData.is_published && editedData.is_published.toUpperCase() === "PUBLISHED" ? false : true

  let showAlert = true;
  if (
    !textInputs["Webinar Title"] && !textInputs["Webinar URL"] &&
    !textInputs["Veterinary association/Collaborator (Optional)"] &&
    (Object.keys(dropDownInputs).length === 0) &&
    !multiLineTextInputs["Webinar Description"] &&
    !startDate &&
    !startTime &&
    !endTime &&
    speakerCount.find(item => {
      if (!item.speaker_description && !item.speaker_name) {
        return true
      }

    }) &&
    teaserCount.find(item => {
      if (!item.teaser_description && !item.teaser_release_date && !item.media) {
        return true
      }

    })
    &&
    !webinarImage &&
    !webinarInvitation &&
    !collaboratorLogo




    // || !files.length
  ) {

    showAlert = false
  }

  const history = useHistory();
  useEffect(() => {
    // toggleBackButton(true);
    getDropDownValues();
    if (editFlow) {
      // history.goBack()
    }

    return () => {
      toggleBackButton(false);
      webinarNav(0)
      toggleEdit(0)

    };
  }, []);



  if (webinarNavigation === 1) {
    return (<Redirect to="/webinar" />)
  }

  const createWebinar = () => {
    let reqObj = {
      data: {

        "webinar_title": textInputs["Webinar Title"],
        "veterinary_association": textInputs["Veterinary association/Collaborator (Optional)"],
        "webinar_category": dropDownInputs["Webinar Category"]["category_name"],
        "webinar_language": dropDownInputs["Language of Webinar"]["value"],
        "webinar_link": textInputs["Webinar URL"],
        "webinar_country": dropDownInputs["Country for this webinar"]["value"],
        "webinar_species": dropDownInputs["Species"]["value"],
        "is_published": toPublish,
        "webinar_description": multiLineTextInputs["Webinar Description"],
        "webinar_date": moment(startDate).format("DD-MM-YYYY"),
        "webinar_start_time": moment(startTime).format("HH:mm"),
        "webinar_end_time": moment(endTime).format("HH:mm"),
        "speaker": speakerCount,
        "disable_enrollment": checked ? "Y" : "N",
        "teaser": teaserCount && teaserCount.length > 0 && teaserCount.map(item =>
          ({ ...item, "teaser_release_date": moment(item.teaser_release_date).format("DD-MM-YYYY") })
        )

      },
      "webinar_image_path": webinarImage,
      "webinar_logo": collaboratorLogo,
      "webinar_invitation_path": webinarInvitation,
      ...teaserMedia
    }

    const tempObject = new FormData();

    Object.keys(reqObj).map((key) =>
      tempObject.append(
        key,
        key === "data" ? JSON.stringify(reqObj[key]) : reqObj[key]
      )
    );
    createNewWebinar(tempObject)

  };

  const createEditData = () => {

    ///////comparasion logic for teaser edits ///////////////

    let arr1 = editedData.teaser.filter(item => {
      if (!teaserCount.find(obj => obj.teaser_id === item.teaser_id)) {
        return true
      }
    }).map(item => ({ ...item, is_delete: true, is_edit: false, is_add: false, is_teaser_updated: false, }))

    let arr2 = teaserCount.filter(item => {
      if (!editedData.teaser.find(obj => obj.teaser_id === item.teaser_id)) {
        return true
      }
    }).map(item => ({
      ...item, is_delete: false, is_edit: false, is_add: true, teaser_id: null, is_teaser_updated: false,
      teaser_release_date: moment(item.teaser_release_date).format("DD-MM-YYYY")
    }))

    let arr3 = teaserCount.filter(item => {
      if (editedData.teaser.find(obj =>
      (obj.teaser_id === item.teaser_id &&
        (obj.teaser_description !== item.teaser_description ||
          obj.media !== item.media ||
          obj.teaser_release_date !== item.teaser_release_date))

      )) {
        return true
      }
    }).map(newItem => {

      let mod;
      if (editedData.teaser.find(i => (newItem.media !== i.media && newItem.teaser_id === i.teaser_id))) {
        if (newItem.media && newItem.media.includes("http")) {
          mod = { ...newItem, is_delete: false, is_edit: false, is_teaser_updated: false, is_add: false }
        }
        else {
          mod = { ...newItem, is_delete: false, is_edit: false, is_teaser_updated: true, is_add: false }
        }

      }
      if (editedData.teaser.find(i => (newItem.teaser_description !== i.teaser_description && newItem.teaser_id === i.teaser_id))) {
        mod = { ...mod, is_edit: true, }
      }
      if (editedData.teaser.find(i => (newItem.teaser_release_date !== i.teaser_release_date && newItem.teaser_id === i.teaser_id))) {
        mod = { ...mod, is_edit: true, teaser_release_date: moment(newItem.teaser_release_date).format("DD-MM-YYYY") }
      }
      return mod
    })
    let editableTeaserArray = arr1.concat(arr2).concat(arr3)




    ////////////////comparasion logic for speaker edits /////////////////////

    let speakerArr1 = editedData.speaker.filter(item => {
      if (!speakerCount.find(obj => obj.speaker_id === item.speaker_id)) {
        return true
      }
    }).map(item => ({ ...item, is_delete: true, is_edit: false, is_add: false }))

    let speakerArr2 = speakerCount.filter(item => {
      if (!editedData.speaker.find(obj => obj.speaker_id === item.speaker_id)) {
        return true
      }
    }).map(item => ({ ...item, is_delete: false, is_edit: false, is_add: true, speaker_id: null }))

    let speakerArr3 = speakerCount.filter(item => {
      if (editedData.speaker.find(obj =>
      (obj.speaker_id == item.speaker_id &&
        (obj.speaker_name !== item.speaker_name ||
          obj.speaker_description !== item.speaker_description))

      )) {
        return true
      }
    }).map(item => ({ ...item, is_delete: false, is_edit: true, is_add: false }))

    let editableSpeakerArray = speakerArr1.concat(speakerArr2).concat(speakerArr3)

    let reqObj = {

      "data": {
        "webinar_id": currentWebinar,
        "webinar_title": textInputs["Webinar Title"],
        "veterinary_association": textInputs["Veterinary association/Collaborator (Optional)"],
        "webinar_category": dropDownInputs["Webinar Category"]["category_name"],
        "webinar_language": dropDownInputs["Language of Webinar"]["value"],
        "webinar_link": textInputs["Webinar URL"],
        "webinar_country": dropDownInputs["Country for this webinar"]["value"],
        "webinar_species": dropDownInputs["Species"]["value"],
        "is_published": toPublish,
        "webinar_description": multiLineTextInputs["Webinar Description"],
        "webinar_date": moment(startDate).format("DD-MM-YYYY"),
        "webinar_start_time": moment(startTime).format("HH:mm"),
        "webinar_end_time": moment(endTime).format("HH:mm"),
        "speaker": editableSpeakerArray,
        "teaser": editableTeaserArray,
        "disable_enrollment": checked ? "Y" : "N",
      },
      "is_image_updated": webinarImageUpdate,
      "is_invitation_updated": invitationImageUpdate,
      "is_logo_updated": logoUpdate,
      "is_logo_deleted": false,
      "webinar_image_path": webinarImage,
      "webinar_logo": collaboratorLogo,
      "webinar_invitation_path": webinarInvitation,
      ...teaserMedia


    }
    const tempObject = new FormData();

    Object.keys(reqObj).map((key) =>
      tempObject.append(
        key,
        key === "data" ? JSON.stringify(reqObj[key]) : reqObj[key]
      )
    );

    editWebinar(tempObject)

  }


  const handleTextInputsChange = (event) => {
    setTextInputs({ ...textInputs, [event.target.name]: event.target.value });
  };

  const handleDropDownsChange = (value, name) => {
    setDropDownInputs({
      ...dropDownInputs,
      [name]: value,
    });
  };

  const handleMultiLineTextInputsChange = (event) => {
    setMultiLineTextInputs({
      ...multiLineTextInputs,
      [event.target.name]: event.target.value,
    });
  };

  const handleWebinarTesersMultiLineTextInputsChange = (event) => {
    setWebinarTeasersMultiLineTextInputs({
      ...webinarTeasersMultiLineTextInputs,
      [event.target.name]: event.target.value,
    });
  };

  const addNewSpeakerFunction = (event) => {
    let count = [...speakerCount];

    let tempSpeaker = { speaker_name: "", speaker_description: "" }
    count.push(tempSpeaker)

    addSpeakerCount(count);
  };


  const addNewTeaserFunction = (event) => {
    let count = [...teaserCount];

    let tempTeaser = { teaser_description: "", teaser_release_date: "", media: "" }
    count.push(tempTeaser);

    addTeaserCount(count);
  };

  const handleSaveWebinar = (publish = false) => {

    if (publish) {
      setPublish("PUBLISHED")
    }
    else {
      setPublish("UNPUBLISHED")
    }

    if (
      !(textInputs["Webinar Title"] && textInputs["Webinar URL"]) ||
      !(Object.keys(dropDownInputs).length === 4) ||
      !multiLineTextInputs["Webinar Description"] ||
      !startDate ||
      !startTime ||
      !endTime ||
      speakerCount.find(item => {
        if (!item.speaker_name) {
          return true
        }

      }) ||
      teaserCount.find(item => {
        if (!item.teaser_description || !item.teaser_release_date || !item.media) {
          return true
        }

      })
      ||
      !webinarImage ||
      !webinarInvitation




      // || !files.length
    ) {

      setOpenDialog(true);
      setError(true);

    } else {
      setOpenDialog(true);
      setError(false);
    }
  };

  const modifySpeaker = (e, index, desc) => {
    let tempSpeakers = speakerCount.map((item, i) => {
      if (index === i) {
        if (desc === "desc") {
          item.speaker_description = e.target.value
        }
        else {
          item.speaker_name = e.target.value
        }
        return item
      }
      return item
    })

    addSpeakerCount(tempSpeakers)
  }
  const modifyTeaser = (e, index, desc) => {

    // if (e.target.files[0]) {

    //   if (!(["jpg", "jpeg", "png"].includes(e.target.files[0].name.split(".").pop()))) {
    //     alert("Please upload image of accepted formats")
    //     return false;
    //   }

    //   else if (e.target.files[0].size / 1024 / 1024 > 5) {
    //     alert("File size limit exceeded.")
    //     return false;
    //   }
    // }

    let tempSpeakers = teaserCount.map((item, i) => {
      if (index === i) {
        if (desc === "desc") {
          item.teaser_description = e.target.value
        }
        else if (desc === "date") {
          item.teaser_release_date = e
        }
        else if (desc === "image") {
          e.stopPropagation();
          if (e.target.files[0]) {

            if (!(["jpg", "jpeg", "png"].includes(e.target.files[0].name.split(".").pop()))) {
              alert("Please upload image of accepted formats")

            }

            else if (e.target.files[0].size / 1024 / 1024 > 5) {
              alert("File size limit exceeded.")

            }
            else {
              item.media = Math.random()
                .toString(36)
                .substr(2, 5);
              setTeaserMedia({ ...teaserMedia, [item.media]: e.target.files[0] })
            }


          }


        }
        return item
      }
      return item
    })

    addTeaserCount(tempSpeakers)
  }

  const deleteSpeaker = (index) => {
    if (speakerCount && speakerCount.length > 1) {
      let tempSpeakers = speakerCount.filter((item, i) => index !== i)
      addSpeakerCount(tempSpeakers)
    }

  }

  const deleteTeaser = (index) => {
    if (teaserCount && teaserCount.length > 1) {
      let tempTeasers = teaserCount.filter((item, i) => index !== i)
      addTeaserCount(tempTeasers)
    }

  }

  const handleImageUpload = (e, fileType) => {


    if (e.target.files[0]) {

      if (!(["jpg", "jpeg", "png"].includes(e.target.files[0].name.split(".").pop()))) {
        alert("Please upload image of accepted formats")
        return false;
      }

      else if (e.target.files[0].size / 1024 / 1024 > 5) {
        alert("File size limit exceeded.")
        return false;
      }

      if (fileType === "collaboratorLogo") {
        setLogoUpdate(true)
        setCollaboratorLogo(e.target.files[0])
      }
      else if (fileType === "webinarImage") {
        setWebinarImageUpdate(true)
        setWebinarImage(e.target.files[0])
      }
      else if (fileType === "webinarInvitation") {
        setInvitationImageUpdate(true)
        setWebinarInvitation(e.target.files[0])
      }



    }
    // const reader = new FileReader();
    // const url = URL.createObjectURL(e.target.files[0]);
    // setImage(url);
  };


  const modalStyle = {
    position: 'absolute',
    top: '50%',
    // left: '50%',
    left: "30%",
    // transform: 'translate(-50%, -50%)',
    width: 500,
  };

  return (
    <div style={{ margin: "10px" }}>

      <Prompt
        when={showAlert}
        message='Press OK to go back discarding any unsaved changes'
      />

      {!fullEdit && <Box sx={{ width: "100%", height: 50, backgroundColor: "#00D7B9" }}>
        <Typography sx={{ marginBottom: "10px", marginTop: 1, lineHeight: 4, fontSize: "14px", fontWeight: 700, color: "white", textAlign: "center" }}>

          You can only edit the teasers section for upcoming webinars
        </Typography>
      </Box>}
      <Typography style={{ marginBottom: "10px", fontSize: "14px" }}>
        <span
          style={{ color: "#999", cursor: "pointer" }}
          onClick={() => history.push("/webinar")}
        >
          Home &gt;{" "}
        </span>
        <span style={{ color: "blue" }}>{editFlow ? "Edit Webinar" : "New webinar"}</span>
      </Typography>
      <Typography>
        <b>{editFlow ? "Edit webinar" : "Create a new Webinar"}</b>
      </Typography>
      <br />
      <Typography style={{ color: "#8080CF", fontWeight: "bold" }}>
        Basic Details
      </Typography>

      <Grid container spacing={{ xs: 2, md: 3 }} style={{ maxWidth: "60%" }}>
        <Grid item xs={12} sm={12} md={12}>
          <TextLayout
            title="Webinar Title"
            //error={error}
            textInputs={textInputs}
            fullEdit={fullEdit}
            saveTextInputs={handleTextInputsChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextLayout

            title="Veterinary association/Collaborator (Optional)"
            textInputs={textInputs}
            fullEdit={fullEdit}
            saveTextInputs={handleTextInputsChange}
          />
        </Grid>
        {/* <Grid item xs={12} sm={12} md={6}>
          <FileLayout
            title="Veterinary association/Collaborator logo (Optional)"
            id="vetAssocLogo"
            filesUpload={setFiles}
            files={files}
          />
        </Grid> */}
        <Grid item xs={12} sm={12} md={6}>
          <FormHelperText id="course_image_field">Veterinary association/Collaborator logo (optional)</FormHelperText>
          <TextField
            size="small"
            id="course_image_field"
            disabled
            // placeholder="No file selected"
            variant="outlined"
            className="course_image_field"
            multiline
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <label htmlFor="upload_course_image">
                    <input
                      accept=".png, .jpg, .jpeg"
                      id="upload_course_image"
                      //   multiple
                      hidden
                      disabled={!fullEdit}
                      type="file"
                      sx={{ display: "none" }}
                      onChange={(e) => handleImageUpload(e, "collaboratorLogo")}
                    />
                    <Button component="span">{false ? "Change file" : "Upload file"}</Button>
                  </label>
                </InputAdornment>
              ),
            }}
            value={
              collaboratorLogo
                ? (collaboratorLogo.name ?
                  collaboratorLogo.name :
                  collaboratorLogo.split("?")[0].split("/")
                  [collaboratorLogo.split("?")[0].split("/").length - 1])
                : "No file selected"
            }
          //   onChange={(e) => selectFilter("search", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <SelectLayout
            title="Country for this webinar"
            //error={error}
            dropDownInputs={dropDownInputs}
            saveDropDownInputs={handleDropDownsChange}
            dropDowns={dropDowns.country}
            fullEdit={fullEdit}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2} alignSelf="end">
          <span
            style={{ fontSize: "12px", color: "gray", marginBottom: "10px" }}
          >
            Date
          </span>
          <DatePicker
            disabled={!fullEdit}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              increaseMonth,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <select
                  value={date.getFullYear()}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {yearArray.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={months[date.getMonth()]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  type="button"
                  onClick={increaseMonth}
                // disabled={nextMonthButtonDisabled}
                >
                  {">"}
                </button>
              </div>
            )}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => {
              setStartDate(date);
            }}
            selected={startDate}
            minDate={moment(new Date).toDate()}
            customInput={<CustomInput placeholderText="dd-mm-yyyy" />}
          />
          {/* {error && (
            <span style={{ color: "red", fontSize: 13 }}>Compulsory!</span>
          )} */}
        </Grid>
        <Grid item xs={12} sm={12} md={2} alignSelf="end">
          <span style={{ fontSize: "12px", color: "gray" }}>Start Time (UTC)</span>
          <DatePicker
            selected={startTime}
            disabled={!fullEdit}
            onChange={(date) => setStartTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeCaption="Start Time"
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            minTime={setHours(setMinutes(new Date(), 0), 0)}
            maxTime={setHours(setMinutes(new Date(), endTime ? (
              moment(endTime).subtract(30, "minutes").minutes()

            ) : 30), endTime ?

              (moment(endTime).minutes() == 30 ? moment(endTime).hours() : moment(endTime).subtract(1, "hours").hours()


              ) : 23)

            }
            customInput={<CustomInput placeholderText="hh:mm" />}
          />
          {/* {error && (
            <span style={{ color: "red", fontSize: 13 }}>Compulsory!</span>
          )} */}
        </Grid>
        <Grid item xs={12} sm={12} md={2} alignSelf="end">
          <span style={{ fontSize: "12px", color: "gray" }}>End Time (UTC)</span>
          <DatePicker
            disabled={!fullEdit}
            selected={endTime}
            onChange={(date) => setEndTime(date)}
            showTimeSelect

            showTimeSelectOnly
            timeCaption="End Time"
            dateFormat="HH:mm"
            timeFormat="HH:mm"

            customInput={<CustomInput placeholderText="hh:mm" />}
            maxTime={setHours(setMinutes(new Date(), 30), 23)}
            minTime={setHours(setMinutes(new Date(), startTime ? (
              moment(startTime).minutes() == 0 ? moment(startTime).add(30, "minutes").minutes() : 0

            ) : 30), startTime ?

              (moment(startTime).minutes() == 30 ? moment(startTime).add(1, "hours").hours() : moment(startTime).hours()


              ) : 0)

            }
            customInput={<CustomInput placeholderText="hh:mm" />}
          />

          {/* {error && (
            <span style={{ color: "red", fontSize: 13 }}>Compulsory!</span>
          )} */}
        </Grid>
        <Grid container justifyContent="right" md={12} sx={{ paddingTop: 4 }}>
          <Grid item md={6}>

          </Grid>
          <Grid container md={6}>
            <Typography sx={{ fontSize: 16, marginTop: 2, marginLeft: 2, color: "gray" }}>* Disable enrollment before 2 hours of webinar?</Typography>
            <Checkbox
              checked={checked}
              onChange={(e) => { setChecked(e.target.checked) }}
              inputProps={{ 'aria-label': 'controlled' }}

            />
          </Grid>

        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <SelectLayout
            title="Webinar Category"
            //error={error}
            dropDownInputs={dropDownInputs}
            saveDropDownInputs={handleDropDownsChange}
            dropDowns={dropDowns.category}
            fullEdit={fullEdit}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <SelectLayout
            title="Species"
            //error={error}
            dropDownInputs={dropDownInputs}
            saveDropDownInputs={handleDropDownsChange}
            dropDowns={dropDowns.species}
            fullEdit={fullEdit}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <SelectLayout
            title="Language of Webinar"
            //error={error}
            dropDownInputs={dropDownInputs}
            saveDropDownInputs={handleDropDownsChange}
            dropDowns={dropDowns.language}
            fullEdit={fullEdit}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextLayout
            title="Webinar URL"
            fullEdit={fullEdit}
            textInputs={textInputs}
            saveTextInputs={handleTextInputsChange}
          />
        </Grid>
      </Grid>
      <hr style={{ margin: "30px 0px", width: "100%" }} />
      <Typography
        style={{ marginTop: "20px", color: "#8080CF", fontWeight: "bold" }}
      >
        Webinar Info
      </Typography>

      <div style={{ width: "80%" }}>
        <MultiLineTextLayout
          title="Webinar Description"
          characterLimit="240"
          //error={error}
          fullEdit={fullEdit}
          multiLineTextInputs={multiLineTextInputs}
          saveMultiLineTextInputs={handleMultiLineTextInputsChange}

        />


      </div>
      {speakerCount.map((item, index) => (
        <>
          <span style={{ fontSize: "small" }}>{index + 1 === 1 ? "1st " : index + 1 === 2 ? "2nd" :
            index + 1 === 3 ? "3rd" : (index + 1) + "th"} Speaker</span>

          <div style={{ width: "80%" }}>
            <Grid container sm={12}>
              <Grid item sm={8}>
                <FormHelperText id="creator_name_field">Speaker Name</FormHelperText>
                <TextField
                  disabled={!fullEdit}
                  size="small"
                  inputProps={{ maxLength: 30, }}
                  ////error={error}
                  value={item.speaker_name}
                  onChange={e => modifySpeaker(e, index, "name")}
                />
              </Grid>
              {fullEdit && speakerCount && speakerCount.length > 1 && <Grid item sm={4}>
                <Tooltip title="Delete Speaker" arrow>
                  <IconButton>
                    <Delete onClick={() => deleteSpeaker(index)} />
                  </IconButton>
                </Tooltip>
              </Grid>}
            </Grid>

            <FormHelperText id="creator_name_field">Speaker Description (Optional)</FormHelperText>
            <TextField
              disabled={!fullEdit}
              sx={{ marginBottom: 2 }}

              inputProps={{
                maxLength: 100
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      (item && item.speaker_description) ? item.speaker_description.length : 0
                    }{" "}
                    / 100
                  </InputAdornment>
                )
              }}
              fullWidth
              multiline
              rows={3}
              // error={error}
              value={item.speaker_description}
              onChange={e => modifySpeaker(e, index, "desc")}
            />
          </div>
        </>
      ))}
      {fullEdit && <span
        style={{ fontSize: "small", color: "#0000A0", cursor: "pointer" }}
        onClick={addNewSpeakerFunction}
      >
        + Add Speaker
      </span>}
      <hr style={{ margin: "30px 0px", width: "100%" }} />
      <Typography
        style={{ margin: "20px 0px", color: "#8080CF", fontWeight: "bold" }}
      >
        Creatives for Webinar
      </Typography>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={5}>
          {/* <FileLayout
            title="Webinar Image"
            error={error}
            id="webinarImage"
            filesUpload={setFiles}
            files={files}
          /> */}
          <Grid item xs={12} sm={12} md={6}>
            <FormHelperText id="course_image_field">Webinar Image</FormHelperText>
            <TextField
              size="small"
              id="course_image_field"
              disabled
              // placeholder="No file selected"
              variant="outlined"
              className="course_image_field"
              multiline
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <label htmlFor="upload_webinar_image">
                      <input
                        accept=".png, .jpg, .jpeg"
                        id="upload_webinar_image"
                        //   multiple
                        hidden
                        type="file"
                        disabled={!fullEdit}
                        sx={{ display: "none" }}
                        onChange={(e) => handleImageUpload(e, "webinarImage")}
                      />
                      <Button component="span">{false ? "Change file" : "Upload file"}</Button>
                    </label>
                  </InputAdornment>
                ),
              }}
              value={
                webinarImage
                  ? (webinarImage.name ?
                    webinarImage.name :
                    webinarImage.split("?")[0].split("/")
                    [webinarImage.split("?")[0].split("/").length - 1])
                  : "No file selected"
              }
            //   onChange={(e) => selectFilter("search", e.target.value)}
            />
          </Grid>

          <span style={{ fontSize: "x-small" }}>Supported Formats - </span>
          <span style={{ fontSize: "x-small", fontWeight: "bold" }}>
            jpg, jpeg, png, webp, bitmap
          </span>
          <br />
          <span style={{ fontSize: "x-small" }}>Dimension - </span>
          <span style={{ fontSize: "x-small", fontWeight: "bold" }}>
            300x140 pixels
          </span>
          <br />
          <span style={{ fontSize: "x-small" }}>Size - </span>
          <span style={{ fontSize: "x-small", fontWeight: "bold" }}>
            less than 3mb
          </span>
        </Grid>
        <Grid item xs={6} sx={{}}>
          {webinarImage && !editFlow ? (
            <img
              src={URL.createObjectURL(webinarImage)}
              style={{
                backgroundColor: "#F8F8F8",
                borderRadius: "10px",
                height: "250px",
              }}
            />
          ) : (
            webinarImage && typeof webinarImage === "object" ?
              (<img
                src={URL.createObjectURL(webinarImage)}
                style={{
                  backgroundColor: "#F8F8F8",
                  borderRadius: "10px",
                  height: "250px",
                }}
              />) : webinarImage && webinarImage && typeof webinarImage === "string" ?
                (<img
                  src={webinarImage}
                  style={{
                    backgroundColor: "#F8F8F8",
                    borderRadius: "10px",
                    height: "250px",
                  }}
                />) :
                (<div

                  style={{
                    backgroundColor: "#F8F8F8",
                    borderRadius: "10px",
                    height: "250px",
                  }}
                />)
          )}
        </Grid>


        <Grid item xs={12} sm={12} md={5}>
          {/* <FileLayout
            title="Webinar Invitation"
            error={error}
            id="webinarInvite"
            filesUpload={setFiles}
            files={files}
          /> */}
          <Grid item xs={12} sm={12} md={6}>
            <FormHelperText id="course_image_field">Webinar Invitation</FormHelperText>
            <TextField
              size="small"
              id="course_image_field"
              disabled
              // placeholder="No file selected"
              variant="outlined"
              className="course_image_field"
              multiline
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <label htmlFor="upload_webinarInvitation_image">
                      <input
                        accept=".png, .jpg, .jpeg"
                        id="upload_webinarInvitation_image"
                        //   multiple
                        hidden
                        type="file"
                        disabled={!fullEdit}
                        sx={{ display: "none" }}
                        onChange={(e) => handleImageUpload(e, "webinarInvitation")}
                      />
                      <Button component="span">{false ? "Change file" : "Upload file"}</Button>
                    </label>
                  </InputAdornment>
                ),
              }}
              value={
                webinarInvitation
                  ? (webinarInvitation.name ?
                    webinarInvitation.name :
                    webinarInvitation.split("?")[0].split("/")
                    [webinarInvitation.split("?")[0].split("/").length - 1])
                  : "No file selected"
              }
            //   onChange={(e) => selectFilter("search", e.target.value)}
            />
          </Grid>
          <span style={{ fontSize: "x-small" }}>Supported Formats - </span>
          <span style={{ fontSize: "x-small", fontWeight: "bold" }}>
            jpg, jpeg, png, webp, bitmap
          </span>
          <br />
          <span style={{ fontSize: "x-small" }}>Dimension - </span>
          <span style={{ fontSize: "x-small", fontWeight: "bold" }}>
            300x140 pixels
          </span>
          <br />
          <span style={{ fontSize: "x-small" }}>Size - </span>
          <span style={{ fontSize: "x-small", fontWeight: "bold" }}>
            less than 3mb
          </span>

        </Grid>
        <Grid item xs={6} sx={{}}>
          {webinarInvitation && !editFlow ? (
            <img
              src={URL.createObjectURL(webinarInvitation)}
              style={{
                backgroundColor: "#F8F8F8",
                borderRadius: "10px",
                height: "250px",
              }}
            />
          ) : (
            webinarInvitation && typeof webinarInvitation === "object" ?
              (<img
                src={URL.createObjectURL(webinarInvitation)}
                style={{
                  backgroundColor: "#F8F8F8",
                  borderRadius: "10px",
                  height: "250px",
                }}
              />) : webinarInvitation && webinarInvitation && typeof webinarInvitation === "string" ?
                (<img
                  src={webinarInvitation}
                  style={{
                    backgroundColor: "#F8F8F8",
                    borderRadius: "10px",
                    height: "250px",
                  }}
                />) :
                (<div

                  style={{
                    backgroundColor: "#F8F8F8",
                    borderRadius: "10px",
                    height: "250px",
                  }}
                />)
          )}
        </Grid>
      </Grid>
      <hr style={{ margin: "30px 0px", width: "100%" }} />
      <Typography
        style={{ margin: "20px 0px", color: "#8080CF", fontWeight: "bold" }}
      >
        Teasers for Webinar
      </Typography>
      {teaserCount.map((item, index) => {
        let titleTeaser = "Teaser " + (index + 1)

        return (
          <>
            <Grid container spacing={{ xs: 2, md: 2 }}>
              <Grid item xs={12} sm={12} md={5}>

                <Grid item xs={12} sm={12} md={6}>
                  <FormHelperText id="course_image_field">{titleTeaser}</FormHelperText>
                  <TextField
                    size="small"
                    id="course_image_field"
                    disabled
                    // placeholder="No file selected"
                    variant="outlined"
                    className="course_image_field"
                    multiline
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <label htmlFor={`upload_teaser_image_${index}`}>
                            <input
                              accept=".png, .jpg, .jpeg"
                              id={`upload_teaser_image_${index}`}
                              //   multiple
                              hidden
                              type="file"
                              sx={{ display: "none" }}
                              onChange={e => modifyTeaser(e, index, "image")}
                            />
                            <Button component="span">{false ? "Change file" : "Upload file"}</Button>
                          </label>
                        </InputAdornment>
                      ),
                    }}
                    value={
                      teaserMedia[item.media] || item.media
                        ? (teaserMedia[item.media] && teaserMedia[item.media].name ?
                          teaserMedia[item.media].name :
                          item.media.split("?")[0].split("/")
                          [item.media.split("?")[0].split("/").length - 1])
                        : "No file selected"
                    }
                  //   onChange={(e) => selectFilter("search", e.target.value)}
                  />
                </Grid>

                <span style={{ fontSize: "x-small" }}>
                  Supported Formats -{" "}
                </span>
                <span style={{ fontSize: "x-small", fontWeight: "bold" }}>
                  jpg, jpeg, png, webp, bitmap
                </span>
                <br />
                <span style={{ fontSize: "x-small" }}>Dimension - </span>
                <span style={{ fontSize: "x-small", fontWeight: "bold" }}>
                  300x140 pixels
                </span>
                <br />
                <span style={{ fontSize: "x-small" }}>Size - </span>
                <span style={{ fontSize: "x-small", fontWeight: "bold" }}>
                  less than 3mb
                </span>


                <Grid item xs={12} sm={12} md={6} sx={{ marginTop: 2 }}>
                  <span
                    style={{ fontSize: "12px", color: "black", marginBottom: "10px", }}
                  >
                    Release date
                  </span>
                  <DatePicker
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      increaseMonth,
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <select
                          value={date.getFullYear()}
                          onChange={({ target: { value } }) => changeYear(value)}
                        >
                          {yearArray.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <select
                          value={months[date.getMonth()]}

                          onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                          }
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <button
                          type="button"
                          onClick={increaseMonth}
                        // disabled={nextMonthButtonDisabled}
                        >
                          {">"}
                        </button>
                      </div>
                    )}
                    dateFormat="dd/MM/yyyy"
                    onChange={e => modifyTeaser(e, index, "date")}

                    selected={item.teaser_release_date}
                    maxDate={moment(startDate).toDate()}
                    minDate={moment(new Date).add(1, 'days').toDate()}
                    customInput={<CustomInput placeholderText="dd-mm-yyyy" />}
                  />
                </Grid>

              </Grid>


              <Grid item xs={5} md={5} sx={{}}>
                {teaserMedia[item.media] && !editFlow ? (
                  <img
                    src={URL.createObjectURL(teaserMedia[item.media])}
                    style={{
                      backgroundColor: "#F8F8F8",
                      borderRadius: "10px",
                      height: "250px",
                    }}
                  />
                ) : (
                  teaserMedia[item.media] && typeof teaserMedia[item.media] === "object" ?
                    (<img
                      src={URL.createObjectURL(teaserMedia[item.media])}
                      style={{
                        backgroundColor: "#F8F8F8",
                        borderRadius: "10px",
                        height: "250px",
                      }}
                    />) : item.media && typeof item.media === "string" ?
                      (<img
                        src={item.media}
                        style={{
                          backgroundColor: "#F8F8F8",
                          borderRadius: "10px",
                          height: "250px",
                        }}
                      />) :
                      (<div

                        style={{
                          backgroundColor: "#F8F8F8",
                          borderRadius: "10px",
                          height: "250px",
                        }}
                      />)
                )}
              </Grid>


              {teaserCount && teaserCount.length > 1 && <Grid item sm={2} md={2}>
                <Tooltip title="Delete teaser" arrow>
                  <IconButton>
                    <Delete onClick={() => deleteTeaser(index)} />
                  </IconButton>
                </Tooltip>
              </Grid>}
            </Grid>
            <FormHelperText id="teaser_description">Teaser description</FormHelperText>
            <div style={{ width: "80%" }}>
              <TextField
                sx={{ marginBottom: 2 }}

                inputProps={{
                  maxLength: 100
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {
                        item.teaser_description.length
                      }{" "}
                      / 100
                    </InputAdornment>
                  )
                }}
                fullWidth
                multiline
                rows={3}
                // error={error}
                value={item.teaser_description}
                onChange={e => modifyTeaser(e, index, "desc")}
              />
            </div>
          </>
        );
      })}

      <span
        style={{ fontSize: "small", color: "#0000A0", cursor: "pointer" }}
        onClick={addNewTeaserFunction}
      >
        + Add Teaser
      </span>
      <br />
      <LoadingButton

        loading={webinarSaving}
        variant="contained"
        sx={{ marginTop: "30px" }}

        loadingPosition="center"
        onClick={() => handleSaveWebinar(true)}
        style={{ textTransform: "none" }}
      >
        SAVE & PUBLISH WEBINAR
      </LoadingButton>
      {
        fullEdit && <LoadingButton

          loading={webinarSaving}

          loadingPosition="center"
          variant="outlined"
          sx={{ marginTop: "30px", marginLeft: "30px" }}
          onClick={() => handleSaveWebinar(false)}
          style={{ textTransform: 'none' }}
        >
          SAVE FOR NOW
        </LoadingButton>
      }

      <Dialog
        sx={modalStyle}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert_dialog_title"
        aria-describedby="alert_dialog_description"
        maxWidth={false}
      >
        <DialogContent>
          <DialogContentText
            id="alert_dialog_description"
          // sx={classes.dialogContent}
          >
            {error ? "Please fill all compulsory fields" : editedData ? "Save changes?" : "Create webinar?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions
        // sx={classes.dialogActions}
        >
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          {!error && (
            <Button
              onClick={() => {
                setOpenDialog(false);
                if (editFlow) {
                  createEditData()
                } else {
                  createWebinar();
                }

              }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* <Dialog
        open={openHomeDialog}
        // onClose={() => setOpenHomeDialog(false)}
        maxWidth={"lg"}
      >
        <DialogTitle
          style={{
            padding: "10px 15px",
            justifyContent: "flex-end",
            display: "flex",
            backgroundColor: "#CCCCFF",
          }}
        >
          <CloseIcon
            fontSize="small"
            style={{ cursor: "pointer" }}
            onClick={() => setOpenHomeDialog(false)}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert_dialog_description"
            style={{ fontWeight: "bold", marginTop: "10px" }}
          >
            Do you want to discard the changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {
            <Button
              variant="contained"
              onClick={() => {
                setOpenHomeDialog(false);
                history.push("/webinar")
              }}
              style={{ textTransform: "none", height: 30 }}
            >
              Yes Discard the changes
            </Button>
          }
          <Button
            variant="outlined"
            style={{ textTransform: "none", height: 30 }}
            onClick={() => setOpenHomeDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog> */}
    </div >
  );
};

const mapStateToProps = (state) => ({
  dropDowns: state.WebinarsAdminReducer.dropDowns,
  webinarNavigation: state.WebinarsAdminReducer.webinarNavigation,
  webinarSaving: state.WebinarsAdminReducer.webinarSaving,
  editFlow: state.WebinarsAdminReducer.editFlow,
  editedData: state.WebinarsAdminReducer.editedData,
  currentWebinar: state.WebinarsAdminReducer.currentWebinar,
});

const mapDispatchToProps = (dispatch) => ({
  getDropDownValues: () => dispatch(getDropDownValues()),
  toggleBackButton: (val) =>
    dispatch({ type: TOGGLE_BACK_BUTTON, payload: { backButton: val } }),
  createNewWebinar: (obj) => dispatch(createNewWebinar(obj)),
  webinarNav: () => dispatch({ type: WEBINAR_NAVIGATION, payload: { webinarNavigation: 0 } }),
  toggleEdit: (val) => dispatch({ type: SET_WEB_EDIT, payload: val }),
  editWebinar: (obj) => dispatch(editWebinar(obj))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateWebinar);
