import {
  REGISTER_SUCCESS,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  ACCOUNT_DELETED,
  LOGIN_INIT, DISABLE_CREATE_PROFILE_SCREEN, SET_STATUS_PROFILE_COMPLETE,
  SET_FORGOTPASS_SCREENS_FLOW, SET_AUTH_ID_OTP, CLEAR_FORGOTPASS_SCREENS_FLOW, CLEAR_AUTH_ID_OTP, LOGIN_FAIL,
  SET_CHANGE_PASSWORD_SCREEN_FLOW, CLEAR_CHANGE_PASSWORD_SCREEN_FLOW, SIGNUP_OTP_SCREEN_TOGGLE,
  SET_SIGNUP_OTP_AUTH_ID_TEMP_PASS, INITIAL_LOADING_STOP
} from '../ActionTypes/authenticationActionTypes';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  loading: true,
  user: null,
  userStatus: null,
  forgetPasswordflow: 0,   // 0 for enter mail screen, 1 for enter otp screen, 2 for enter new password screen, 3 for password changed message screen
  authIdOTP: null,
  profile: null,
  userType: null,
  changePasswordFlow: 0, // 0 for change password screen , 1 for password changed confirmation screen.
  // profile:localStorage.getItem("currentProfile")?JSON.parse(localStorage.getItem("currentProfile")):null,
  signupOTPscreen: false,
  signupAuthId: null,
  tempPass: null,

};

function AuthenticationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state, ...payload,
        isAuthenticated: true,
        loading: false

      };

    case INITIAL_LOADING_STOP:
      return {
        ...state,
        loading: false
      }

    case LOGIN_INIT:
      return {
        ...state,
        loading: true
      }
    case SIGNUP_OTP_SCREEN_TOGGLE:
      return {
        ...state,
        signupOTPscreen: payload,
      };
    case SET_SIGNUP_OTP_AUTH_ID_TEMP_PASS:
      return {
        ...state,
        signupAuthId: payload.authId,
        tempPass: payload.tempPass
      }
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        token: null,
        isAuthenticated: false,
      }

    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false
      };
    case ACCOUNT_DELETED:
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        userStatus: null,
        profile: null,
        userType: null
      };

    case DISABLE_CREATE_PROFILE_SCREEN:
      return {
        ...state, createProfileScreen: false
      }
    case SET_STATUS_PROFILE_COMPLETE:
      return {
        ...state, userStatus: "Unregistered", profile: payload
      }
    case SET_FORGOTPASS_SCREENS_FLOW:
      return {
        ...state, ...payload
      }
    case SET_AUTH_ID_OTP:
      return {
        ...state, ...payload
      }
    case CLEAR_FORGOTPASS_SCREENS_FLOW:
      return {
        ...state, forgetPasswordflow: 0
      }
    case CLEAR_AUTH_ID_OTP:
      return {
        ...state, authIdOTP: null
      }
    case SET_CHANGE_PASSWORD_SCREEN_FLOW:
      return {
        ...state, changePasswordFlow: 1
      }
    case CLEAR_CHANGE_PASSWORD_SCREEN_FLOW:
      return {
        ...state, changePasswordFlow: 0
      }
    default:
      return state;
  }
}

export default AuthenticationReducer;
