import {
  FETCH_FILTER_VALUES_REQUEST_WEBINAR_VET,
  FETCH_FILTER_VALUES_FAILURE_WEBINAR_VET,
  FETCH_FILTER_VALUES_SUCCESS_WEBINAR_VET,
  SELECT_FILTER_WEBINAR_VET,
  //   FETCH_COURSES_REQUEST,
  //   FETCH_COURSES_FAILURE,
  FETCH_WEBINARS_VET_SUCCESS,
  CHANGE_PAGE_WEBINAR_VET,
  CLEAR_VET_WEBINARS_DATA,
  FETCH_VET_WEBINARS_DATA,
  SET_VET_WEBINARS_DATA,
  SET_VET_WEBINARS_DATA_PAGINATION,
  SET_CURRENT_VET_WEBINAR,
  TOGGLE_VET_WEBINAR_ENROLLMENT_STATUS
} from "../ActionTypes/webinarsVetActionTypes";

const initialState = {
  isLoading: false,
  error: "",
  filters: null,
  selectedFilters: {
    category: [],
    country: [],
    creator: [],
    language: [],
    species: [],
    show_content_from: { value: "All time" },
    search: "",
  },
  webinars: {},
  pageNumber: 1,
  isAlertOpen: false,
  alertType: "success",
  alertMessage: "",
  current: 1,
  prev: null,
  next: null,
  totalCount: null,
  currentWebinar: null,
  contents: null,
  enrollmentStatus: false,

};

const WebinarsVetReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_FILTER_VALUES_REQUEST_WEBINAR_VET:
      // case FETCH_COURSES_REQUEST:
      // case CHANGE_COURSE_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case FETCH_VET_WEBINARS_DATA:
      return {
        ...state, isLoading: true
      }
    case SET_VET_WEBINARS_DATA:
      return {
        ...state,
        webinars: payload,
        isLoading: false
      };
    case SET_CURRENT_VET_WEBINAR:
      return {
        ...state, currentWebinar: payload
      }

    case CLEAR_VET_WEBINARS_DATA:
      return {
        ...state,
        isLoading: false,
        dataLoading: false,
        contents: null,
        webinars: null,
        // isBookmarked: null,
        // rating: null,
        next: null,
        prev: null,
        current: null,
        totalCount: null,
        // editedData: null,
        currentWebinar: null

      };
    case TOGGLE_VET_WEBINAR_ENROLLMENT_STATUS:
      return {
        ...state, enrollmentStatus: payload
      }

    case SET_VET_WEBINARS_DATA_PAGINATION:
      return {
        ...state, ...payload
      };
    case FETCH_FILTER_VALUES_SUCCESS_WEBINAR_VET:
      return {
        ...state,
        isLoading: false,
        filters: payload,
        error: "",
      };

    case FETCH_FILTER_VALUES_FAILURE_WEBINAR_VET:
      return {
        ...state,
        isLoading: false,
        error: payload,
        filters: {},
      };

    case SELECT_FILTER_WEBINAR_VET:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [payload.type]: payload.value,
        },
      };

    case FETCH_WEBINARS_VET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
        webinars: payload,
        isFailure: false,
      };

    // case FETCH_COURSES_FAILURE:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     error: payload,
    //     courses: "",
    //     isFailure: true,
    //   };

    case CHANGE_PAGE_WEBINAR_VET:
      return {
        ...state,
        pageNumber: payload,
      };

    // case SELECT_ARTICLE:
    //   return {
    //     ...state,
    //     articleSelected: payload,
    //   };

    //   case TOGGLE_ALERT:
    //     return {
    //       ...state,
    //       isAlertOpen: payload.value,
    //       alertType: payload.type,
    //       alertMessage: payload.message,
    //     };

    default:
      return state;
  }
};

export default WebinarsVetReducer;
