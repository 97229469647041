import React, { useState } from "react";
import { connect } from "react-redux";
import { Document, Page } from "react-pdf";
import { Chip, IconButton, Rating, Breadcrumbs, Link } from "@mui/material";
import Cancel from "@mui/icons-material/Cancel";
import SamplePdf from "../../../Assets/Sample.pdf";
import { selectArticle } from "../../../Redux/ActionCreators/learningContentAdminActionCreators";
import { classes } from "./styles";
import "./styles.css";

const UploadedFile = ({
  setIsArticleClicked,
  articleSelected,
  selectArticle,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleHomeClick = () => {
    selectArticle({});
    setIsArticleClicked(false);
  };

  return (
    <>
      {/* <IconButton
        color="default"
        aria-label="close article"
        component="span"
        sx={classes.iconButton}
      >
        <Cancel />
      </IconButton> */}
      <p style={classes.breadcrumbs}>
        <span style={classes.inactiveCrumb} onClick={handleHomeClick}>
          {"Home > "}
        </span>
        <span style={classes.activeCrumb}>Reading Material</span>
      </p>
      <div className="heading_section">
        <h2 className="content_heading" style={classes.heading}>
          {articleSelected.file_title}
        </h2>
        <Chip label={articleSelected.file_type} sx={classes.chip} />
        <Rating
          name="read-only"
          value={articleSelected.avg_rating}
          readOnly
          sx={classes.rating}
        />
      </div>
      <div className="content_details" style={classes.contentDetailsSection}>
        <p className="author" style={classes.contentDetails}>
          {articleSelected.author}
        </p>
        {/* <p className="date" style={classes.contentDetails}>
          {articleSelected.last_updated_date}
        </p> */}
      </div>
      {/* <p className="content_description" style={classes.contentDescription}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta
        ultricies nibh, nec aliquam augue mollis eu. Fusce risus orci, vulputate
        vitae fringilla nec, mollis a lorem. Cras eu congue neque, eu mattis
        nulla. Fusce at sodales odio. Integer tristique libero a elit luctus,
        quis aliquet tortor pulvinar. Curabitur aliquam aliquam libero in
        pellentesque. Aenean nec sagittis metus. Mauris et nibh porta, accumsan
        lacus quis, mollis nunc. Nam luctus ac turpis sit amet consectetur. Duis
        dignissim odio in purus cursus, non efficitur tellus faucibus. Morbi
        tincidunt arcu eu purus mattis hendrerit. In cursus nibh vel nunc
        feugiat, in tristique nisi placerat.
      </p> */}
      <div className="pdf">
        <Document
          file={articleSelected.sas_file_path}
          onLoadSuccess={onDocumentLoadSuccess}
          className="document"
          // fullWidth
        >
          {/* <Page pageNumber={pageNumber} className="page" width={1000} /> */}
          {Array.from(new Array(numPages), (el, index) => (
            <>
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                className="page"
                width={1000}
              />
              <p>
                Page {index + 1} of {numPages}
              </p>
            </>
          ))}
        </Document>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  articleSelected: state.LearningContentAdminReducer.articleSelected,
});

const mapDispatchToProps = (dispatch) => ({
  selectArticle: (article) => dispatch(selectArticle(article)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadedFile);
