import React, { useState, useEffect, forwardRef } from 'react';
import { InputLabel, TextField, Select, MenuItem, Button, Grid, Box, Typography, Divider, CssBaseline, Paper, Checkbox } from '@mui/material';
import { Formik, useFormik } from "formik"
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { Link as MUIlink } from '@mui/material';
import { useHistory } from "react-router-dom";
import Logo from "../Header/components/Navbar/logo.png"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import LoadingButton from '@mui/lab/LoadingButton';






const SignupCreateProfileForm = ({ createProfile, emailMain, isAuthenticated, userStatus, emailSaved, processLoader }) => {

  // const history = useHistory()

  // useEffect(() => {

  //   return () => {

  //   };
  // }, []);

  const [modFileName, setModFileName] = useState("")
  const [dob1, setDOB] = useState("");
  const [submitEnabled, setSubmitEnabled] = useState(false)
  // const [anniversary, setAnniversary] = useState("");
// validations for all the input fields
  const validationSchema = yup.object({
    full_name: yup
      .string('Please your name')
      .required('Name is required'),
    phone_number: yup
      .string('Enter your phone number')
      .min(10, 'Invalid mobile number')
      .required('Phone no. is required'),
    // clinic_code: yup
    //   .number('Enter your clinic code')
    //   // .min(8, 'Password should be of minimum 8 characters length')
    //   .required('clinic code is required'),
    // registration_number: yup
    //   .number('Enter your registration_number')
    //   // .min(8, 'Password should be of minimum 8 characters length')
    //   .required('registration_number is required'),
    // clinic_pincode: yup
    //   .string('Enter your clinic pincode')
    //   .min(6, 'Invalid pin code')
    //   .required('clinic pincode is required'),
    clinic_pincode: yup
      .string('Enter your clinic pincode')
      .when('countryCode', {
        is: "+91",
        then: yup.string()
          .min(6, 'Invalid pin code')
          .required('clinic pincode is required') 
      })
      .when('countryCode', {
        is: "+63",
        then: yup.string()
          .min(6, 'Invalid pin code')
      }),
    clinic_address: yup
      .string('Please your clinic address')
      .required('clinic address is required'),
    clinic_city: yup
      .string('Please your clinic city')
      .required('clinic city is required'),
    clinic_name: yup
      .string('Please your clinic name')
      .required('clinic name is required'),
    occupation: yup
      .string('Please select your occupation')
      .required('Occupation is required'),
    clinic_state: yup
      .string('Please select your state')
      .required('State is required'),

  });

  const formik = useFormik({
    initialValues: {

      full_name: "",
      occupation: '',
      phone_number: "",
      countryCode: "+91",
      // clinic_code: "",
      registration_number: "",
      clinic_pincode: "",
      clinic_address: "",
      clinic_city: "",
      clinic_state: "",
      clinic_name: "",
      dob: "",
      // marriage_anniversary: "",
      business_card_url: ""

    },

    validationSchema: validationSchema,

    onSubmit: (values) => {

      if(values.clinic_pincode=== ""){
        values.clinic_pincode = "000000"
      }else{
        values.clinic_pincode = values.clinic_pincode
      }



      let reqBodyObj = {
        email: emailSaved,
        full_name: values.full_name,
        occupation: values.occupation,
        phone_number: values.phone_number,
        countryCode: values.countryCode,
        // clinic_code: values.clinic_code,
        registration_number: values.registration_number,
        clinic_pincode: values.clinic_pincode,
        clinic_address: values.clinic_address,
        clinic_city: values.clinic_city,
        clinic_state: values.clinic_state,
        clinic_name: values.clinic_name,
        dob: values.dob,
        // marriage_anniversary: values.marriage_anniversary,
        business_card_url: values.business_card_url

      }



      createProfile(reqBodyObj)
      // alert(JSON.stringify(values, null, 2));
    },
  });

  const numberFilter = (e) => {   // to required values as only number


    if (!isNaN(e.target.value))
      formik.handleChange(e)

  }
  const alphaNumericFilter = (e) => {
    let pattern = /^[a-z0-9]+$/i

    if (pattern.test(e.target.value) || e.target.value === "") {
      formik.handleChange(e)
    }
  }

  const handleFile = (e) => {
    if (e.target.files && e.target.files[0] && e.target.files[0].name) {

      if (!(["jpg", "jpeg", "png"].includes(e.target.files[0].name.split(".").pop()))) {
        alert("Please upload image of accepted formats")
        return false

      }

      else if (e.target.files[0].size / 1024 / 1024 > 5) {
        alert("File size limit exceeded.")
        return false

      }

      let nameArr = e.target.files[0].name.split(".")
      let modFileName = nameArr[0].slice(0, 6) + "." + nameArr[1]
      setModFileName(modFileName)
      formik.setFieldValue("business_card_url", e.target.files[0])


    }

  }

  const Input = styled('input')({  //styled input for upload button
    display: 'none',
  });

  const handleCountryCode = (e) => {  //to set state field as none when country other that India is selected

    formik.setFieldValue("countryCode", e.target.value)
    if (e.target.value !== "+91") {
      formik.setFieldValue("clinic_state", "NA")
      formik.setFieldValue("clinic_pincode", "")
    }
    else {
      if (formik.values.clinic_state === "NA") {
        formik.setFieldValue("clinic_state", "")
      }
    }
  }

  const StyledMain = styled('div')(({ theme }) => ({
    marginTop: '1%',
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },

  }));

  const PaperWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 60,
    },
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },

  }));

  const CustomInput = ({ value, onClick }) => (
    <Button fullWidth sx={{ height: 56, borderColor: "#949494", color: "black" }} size="large" variant="outlined" onClick={onClick}>{value}</Button>
  );

  // if(!isAuthenticated || userStatus!=="Incomplete_Profile"){
  //   return(<Redirect to="/" />)
  // }

  const years = new Date().getFullYear() + 1
  let yearArray = new Array();
  for (let i = 1900; i < years; i++) {
    yearArray[i] = i;
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>

          <Grid item xs={12} sm={12}>
            <Typography sx={{ marginTop: 2 }} variant="h4" align="center">
              Sign Up
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Divider textAlign="left"><Typography align="left" sx={{ fontWeight: 600, color: "#525252" }} variant="body1" >Personal details </Typography></Divider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel><Typography variant="h6"> Full Name</Typography></InputLabel>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                inputProps={{ maxLength: 30 }}
                id="full_name"
                name="full_name"
                value={formik.values.full_name}
                placeholder
                onChange={formik.handleChange}
                error={formik.touched.full_name && Boolean(formik.errors.full_name)}
                helperText={formik.touched.full_name && formik.errors.full_name}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={6}>

            {/* formik.values.countryCode !== "+63" ?
              <InputLabel><Typography variant="h6">	Preferably WhatsApp Number </Typography></InputLabel> */}

            <InputLabel><Typography variant="h6">	Phone Number</Typography></InputLabel>

            <Grid container item>
              <Grid item xs={3} sm={4}>
                <TextField
                  fullWidth
                  select
                  id="countryCode"
                  name="countryCode"
                  inputProps={{ maxLength: 30 }}

                  value={formik.values.countryCode}
                  onChange={handleCountryCode}
                  error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
                  helperText={formik.touched.countryCode && formik.errors.countryCode}
                >
                  {
                    ["+91", "+63"].map(
                      (item) => (<MenuItem value={item}>{item}</MenuItem>)
                    )
                  }
                </TextField>
              </Grid>
              <Grid item xs={9} sm={8}>
                <TextField
                  fullWidth
                  id="phone_number"
                  name="phone_number"
                  inputProps={{ maxLength: 10, style: { textAlign: "right" } }}

                  value={formik.values.phone_number}
                  onChange={numberFilter}
                  error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                  helperText={formik.touched.phone_number && formik.errors.phone_number}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel><Typography variant="h6"> Date of birth(optional)</Typography></InputLabel>
            <Grid item xs={12} sm={12}>

              {/* <DatePicker
                
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {formik.setFieldValue("dob", moment(date).format("DD/MM/yyyy"));
                 setDOB(date)}}
                selected={dob1}
                maxDate={anniversary}
                customInput={<CustomInput />}
                
                
                
              /> */}

              <DatePicker
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                      {"<"}
                    </button>
                    <select
                      value={(date).getFullYear()}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {yearArray.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <select
                      value={months[(date).getMonth()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                      {">"}
                    </button>
                  </div>
                )}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  formik.setFieldValue("dob", moment(date).format("DD/MM/YYYY"));
                  setDOB(date)
                }}
                selected={dob1}
                maxDate={moment(new Date).subtract(1, 'days').toDate()}
                customInput={<CustomInput />}
              />

            </Grid>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <InputLabel><Typography variant="h6"> Marriage anniversary(optional)</Typography></InputLabel>
            <Grid item xs={12} sm={12}>
              
              <DatePicker
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button type="button"  onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                      {"<"}
                    </button>
                    <select
                      value={(date).getFullYear()}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {yearArray.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <select
                      value={months[(date).getMonth()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <button type="button"  onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                      {">"}
                    </button>
                  </div>
                )}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  formik.setFieldValue("marriage_anniversary", moment(date).format("DD/MM/YYYY"));
                  setAnniversary(date)
                }}
                selected={anniversary}
                minDate={moment(dob1).add(1, 'days').toDate()}
                customInput={<CustomInput />}
              />

            </Grid>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <InputLabel><Typography variant="h6"> Occupation</Typography></InputLabel>
            <Grid item xs={12} sm={12}>
              <TextField
                select
                fullWidth
                id="occupation"
                name="occupation"
                value={formik.values.occupation}
                onChange={formik.handleChange}
                error={formik.touched.occupation && Boolean(formik.errors.occupation)}
                helperText={formik.touched.occupation && formik.errors.occupation}
              >

                <MenuItem value={"Government Vet"}>Government Vet</MenuItem>
                <MenuItem value={"Private Practitioner"}>Private Practitioner</MenuItem>
                <MenuItem value={"Student"}>Student</MenuItem>
                <MenuItem value={"Faculty Member"}>Faculty Member</MenuItem>

              </TextField>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <InputLabel><Typography variant="h6"> Clinic Code with Mars(optional)</Typography></InputLabel>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                id="clinic_code"
                name="clinic_code"
                value={formik.values.clinic_code}
                inputProps={{ maxLength: 15, style: { textAlign: "right" } }}
                onChange={alphaNumericFilter}
                error={formik.touched.clinic_code && Boolean(formik.errors.clinic_code)}
                helperText={formik.touched.clinic_code && formik.errors.clinic_code}
              />
            </Grid>
          </Grid> */}
          {formik.values.countryCode !== "+91" && <Grid item xs={12} sm={12}>
            <Grid item xs={12} sm={6}>
              <InputLabel><Typography variant="h6"> Registration Number(optional)</Typography></InputLabel>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  id="registration_number"
                  name="registration_number"

                  inputProps={{ maxLength: 15, style: { textAlign: "right" } }}
                  value={formik.values.registration_number}
                  onChange={alphaNumericFilter}
                  error={formik.touched.registration_number && Boolean(formik.errors.registration_number)}
                  helperText={formik.touched.registration_number && formik.errors.registration_number}
                />
              </Grid>
            </Grid>
          </Grid>}
          <Grid item xs={12} sm={12} sx={{ display: { sm: "block", xs: "none" } }}>
            <Divider textAlign="left"><Typography align="left" sx={{ fontWeight: 600, color: "#525252" }} variant="body1" >Clinic/Hospital/College/University details</Typography></Divider>
          </Grid>
          <Grid item xs={12} sm={12} sx={{ display: { sm: "none", xs: "flex" } }}>
            <Typography align="left" sx={{ fontWeight: 600, color: "#525252" }} variant="body1" >Clinic/Hospital/College/University details</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputLabel><Typography variant="h6"> Pincode {formik.values.countryCode !== "+91" ? `${"(optional)"}` : `${""}`}</Typography></InputLabel>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                id="clinic_pincode"
                name="clinic_pincode"

                inputProps={{ maxLength: 6, style: { textAlign: "right" } }}
                value={formik.values.clinic_pincode}
                onChange={numberFilter}
                error={formik.touched.clinic_pincode && Boolean(formik.errors.clinic_pincode)}
                helperText={formik.touched.clinic_pincode && formik.errors.clinic_pincode}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel><Typography variant="h6"> Clinic Business card(Optional)</Typography></InputLabel>
            <Grid item xs={12} sm={12}>

              <label htmlFor="contained-button-file">
                <Input accept=".png, .jpg, .jpeg" id="contained-button-file" multiple type="file"

                  onChange={handleFile}
                  error={formik.touched.business_card_url && Boolean(formik.errors.business_card_url)}
                  helperText={formik.touched.business_card_url && formik.errors.business_card_url} />
                <Button style={{ textTransform: 'none' }} size="medium" variant="contained" component="span">
                  Upload business card
                </Button>
              </label>

            </Grid>{modFileName && modFileName}
          </Grid>
          <Grid item xs={12} sm={7}>
            <InputLabel><Typography noWrap variant="h6">Clinic/Hospital/College/University Name</Typography></InputLabel>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                id="clinic_name"
                name="clinic_name"
                value={formik.values.clinic_name}
                inputProps={{ maxLength: 30, style: { textAlign: "right" } }}
                onChange={formik.handleChange}
                error={formik.touched.clinic_name && Boolean(formik.errors.clinic_name)}
                helperText={formik.touched.clinic_name && formik.errors.clinic_name}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel><Typography variant="h6"> Full Address</Typography></InputLabel>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                id="clinic_address"
                name="clinic_address"
                value={formik.values.clinic_address}
                onChange={formik.handleChange}
                inputProps={{ maxLength: 80, style: { textAlign: "right" } }}
                error={formik.touched.clinic_address && Boolean(formik.errors.clinic_address)}
                helperText={formik.touched.clinic_address && formik.errors.clinic_address}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel><Typography variant="h6"> City</Typography></InputLabel>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                id="clinic_city"
                name="clinic_city"
                inputProps={{ maxLength: 15, style: { textAlign: "right" } }}
                value={formik.values.clinic_city}
                onChange={formik.handleChange}
                error={formik.touched.clinic_city && Boolean(formik.errors.clinic_city)}
                helperText={formik.touched.clinic_city && formik.errors.clinic_city}
              />
            </Grid>
          </Grid>
          {formik.values.countryCode !== "+63" && (<Grid item xs={12} sm={6}>
            <InputLabel><Typography variant="h6"> State</Typography></InputLabel>
            <Grid item xs={12} sm={12}>
              <TextField
                select
                fullWidth
                id="clinic_state"
                name="clinic_state"
                value={formik.values.clinic_state}
                onChange={formik.handleChange}
                error={formik.touched.clinic_state && Boolean(formik.errors.clinic_state)}
                helperText={formik.touched.clinic_state && formik.errors.clinic_state}
              >
                {
                  ["Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh",
                    "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jammu & Kashmir", "Jharkhand",
                    "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya",
                    "Mizoram", "Nagaland", "New Delhi", "Orissa", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu",
                    "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal"].map(
                      (item) => (<MenuItem value={item}>{item}</MenuItem>)
                    )
                }
              </TextField>
            </Grid>
          </Grid>)}
          <Grid container xs={12} sm={12} sx={{ marginTop: 2 }}>

            <Grid item xs={3} sm={3}>
              <Grid item xs={12} sm={12} sx={{ paddingLeft: 2 }}>
                <Checkbox 
                  checked={submitEnabled}
                  onChange={(e) => { setSubmitEnabled(!submitEnabled) }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Grid>
              <Grid item xs={12} sm={12} sx={{paddingLeft:2}}>
                <Typography variant="h6" sx={{fontSize:16}}>Accept</Typography>
              </Grid>

            </Grid>

            <Grid item xs={9} sm={9} sx={{ backgroundColor: "#f7f7f7", padding: 1, borderRadius: 2,}}>
              <Box >
              <span >I have read and accepted the Mars data privacy <a href='https://www.mars.com/privacy' target="_blank" style={{textDecoration:"none"}}>terms and conditions</a> </span>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <LoadingButton disabled={!submitEnabled} style={{ textTransform: 'none' }} loading={processLoader} loadingPosition="center" color="primary" variant="contained" fullWidth type="submit">
              Submit
            </LoadingButton>
          </Grid>
          <Grid item xs={12} sm={12}>


          </Grid>
        </Grid>


      </form>

    </>
  )
}




export default SignupCreateProfileForm;
