import axios from "axios";
import {
  createCourseFailure,
  createCourseRequest,
  createCourseSuccess,
  createEditChapterFailure,
  createEditChapterRequest,
  createEditChapterSuccess,
  setActiveCourse,
  setCurriculumDetails,
  toggleAlert,
} from "../../ActionCreators/coursesAdminActionCreators";

const CREATE_EDIT_CHAPTER_URL =
  process.env.REACT_APP_API_URL + "/curriculum/admin/createedit_chapter/";

export const createEditChapter = (chapterDetails,edit=false) => (dispatch) => {
  dispatch(createEditChapterRequest());
  axios
    .post(CREATE_EDIT_CHAPTER_URL, chapterDetails)
    .then((response) => {
      if (response.status === 201) {
        dispatch(createEditChapterSuccess());
        if(edit){
          dispatch(toggleAlert("success", "Chapter edited successfully !", true));
        }
        else{
          dispatch(toggleAlert("success", "Chapter created successfully !", true));
        }
        dispatch(setCurriculumDetails(response.data));
      } else {
        dispatch(createEditChapterFailure("Something went wrong !"));
        dispatch(toggleAlert("error", "Something went wrong !", true));
      }
    })
    .catch((error) => {
      dispatch(createEditChapterFailure(error.message));
      dispatch(toggleAlert("error", error.message, true));
    });
};
