export const classes = {
  card: {
    borderRadius: "5px",
    width: "100%",
    background: "#f2f2f2",
    boxShadow: "1px 1px 5px lightgrey",
  },
  image: { height: 150 },
  iconButton: {
    marginRight: "2%",
    marginTop: "2%",
    position: "absolute",
    top: "0%",
    right: "0%",
    color: "white",
  },
  actionItem: {
    color: "red",
  },
  title: {
    color: "#0f01a0",
    fontWeight: "bold",
    width: "100%",
    overflow: "hidden",
    // whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  rating: {
    color: "lightseagreen",
  },
  ratio: { color: "#0f01a0" },
};
