import {
    FETCH_ARTICLE_LIST, SET_ARTICLE_LIST, FETCH_ARTICLE_USER_DATA, SET_ARTICLE_USER_DATA, CLEAR_ARTICLE_USER_DATA,
    SET_BOOKMARK_LC, SET_RATING_LC, SET_AVG_RATING_LC
} from "../ActionTypes/learningContentActionTypes"
import axios from "axios"

//Login 
export const getSingleArticle = (reqObj) => async dispatch => {


    try {
        dispatch({ type: FETCH_ARTICLE_USER_DATA })
        let url = process.env.REACT_APP_API_URL + "/learningcontent/vet/single_object/"



        let result = await axios.post(url, reqObj)

        if (result.data) {

            if (result.data.user_status && result.data.user_status.bookmark_flag === "Y") {
                dispatch({ type: SET_BOOKMARK_LC, payload: { isBookmarked: true } })
            }
            else if (result.data.user_status && result.data.user_status.bookmark_flag === "N") {
                dispatch({ type: SET_BOOKMARK_LC, payload: { isBookmarked: false } })
            }
            if (result.data.user_status) {
                dispatch({ type: SET_RATING_LC, payload: { rating: result.data.user_status.rating } })
            }
            if (result.data.article_info) {
                dispatch({ type: SET_AVG_RATING_LC, payload: { avgRating: result.data.article_info.avg_rating } })
                dispatch({ type: SET_ARTICLE_USER_DATA, payload: result.data })
            }





        }
        else {
            dispatch({ type: CLEAR_ARTICLE_USER_DATA })
        }

        //dispatch(loadUser())

    }
    catch (err) {
        dispatch({ type: CLEAR_ARTICLE_USER_DATA })
    }
}

