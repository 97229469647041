import React, { useState, useEffect } from 'react';
import { InputLabel, TextField, Select, MenuItem, Button, Grid, Box, Typography, InputAdornment, IconButton } from '@mui/material';
import { useFormik } from "formik"
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { Link as MUIlink } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';




const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(9, 'Password should be of minimum 9 characters length')
    .required('Password is required')
    .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{9,}$/,
        "Password must include atleast one uppercase, one lowercase, one numeric and one special character [@$!%*#?&]"
      ),
  
  confirmedPassword: yup
    .string('Enter your password')
    .min(9, 'Password should be of minimum 9 characters length')
    .required('Password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match')
});

const SignupFormRes = ({signupInit,setEmailMain,processLoader}) => {

  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmedPassword: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      
      setEmailMain(values.email)
      signupInit(values.email,values.password)
     
    },
  });

  const handleClickShowPassword = () => {
    let input = document.getElementById("password");
    input.type === "password" ? input.type = "text" : input.type = "password";
    setShowPassword(!showPassword);
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ marginTop: 2 }} variant="h4" align="center">
              Sign Up
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputLabel><Typography variant="h6"> Email address</Typography></InputLabel>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                id="email"
                name="email"
                inputProps={{ maxLength: 255}}
                value={formik.values.email}
                placeholder
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputLabel><Typography variant="h6">Create Password</Typography></InputLabel>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                id="password"
                name="password"
                inputProps={{ maxLength: 255}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputLabel><Typography variant="h6"> Confirm Password</Typography></InputLabel>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                id="confirmedPassword"
                name="confirmedPassword"
                inputProps={{ maxLength: 255}}
                type="password"
                value={formik.values.confirmedPassword}
                onChange={formik.handleChange}
                error={formik.touched.confirmedPassword && Boolean(formik.errors.confirmedPassword)}
                helperText={formik.touched.confirmedPassword && formik.errors.confirmedPassword}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <LoadingButton  style={{textTransform: 'none'}} loading={processLoader} loadingPosition="center" color="primary" variant="contained" fullWidth type="submit">
              Sign Up
            </LoadingButton>
          </Grid>
          <Grid item xs={12} sm={12}>


          </Grid>
        </Grid>


      </form>
    </>
  )
}

export default SignupFormRes