import axios from "axios";
import {
  createCourseFailure,
  createCourseRequest,
  createCourseSuccess,
  createEditChapterFailure,
  createEditChapterRequest,
  createEditChapterSuccess,
  createEditLessonFailure,
  createEditLessonRequest,
  createEditLessonSuccess,
  setActiveCourse,
  setCurriculumDetails,
  toggleAlert,
} from "../../ActionCreators/coursesAdminActionCreators";

const CREATE_EDIT_LESSON_URL =
  process.env.REACT_APP_API_URL + "/curriculum/admin/createedit_lesson/";

export const createEditLesson = (lessonDetails, isEdit = false) => (dispatch) => {
  dispatch(createEditLessonRequest());
  axios
    .post(CREATE_EDIT_LESSON_URL, lessonDetails)
    .then((response) => {
      if (response.status === 201) {
        dispatch(createEditLessonSuccess());
        if (!isEdit) {
          dispatch(toggleAlert("success", "Lesson created successfully !", true));
        }
        else{
          dispatch(toggleAlert("success", "Lesson edited successfully !", true));
        }

        dispatch(setCurriculumDetails(response.data));
      } else {
        dispatch(createEditLessonFailure("Something went wrong !"));
        dispatch(toggleAlert("error", "Something went wrong !", true));
      }
    })
    .catch((error) => {
      dispatch(createEditLessonFailure(error.message));
      dispatch(toggleAlert("error", error.message, true));
    });
};
