import React, { useState } from "react";
import {
    Grid,
    Card,
    CardContent,
    Typography,
    IconButton,
    MenuItem,
    CardActionArea,
    CardMedia,
    Menu,
    Rating,
    Button,
    CardActions,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { classes } from "./styles";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useHistory } from "react-router-dom";
import moment from "moment";

const WebinarBoard = ({ webinar, setVetCurrentWebinar, isAuthenticated }) => {

    const history = useHistory()
    return (<Grid md={3} sx={{paddingRight:4}}>
        <div className="s-card">
            <Card
                sx={classes.card} onClick={() => {
                    if (isAuthenticated) {
                        setVetCurrentWebinar(webinar); history.push("/webinarVetDetail")
                    }
                    else {
                        history.push("/login")
                    }

                }}
            >
                <CardActionArea>
                    <CardMedia
                        component="img"
                        sx={classes.image}
                        image={webinar.sas_webinarimage_path}
                        alt="webinar image"
                    />
                    {/* <IconButton
                        color="default"
                        aria-label="card actions"
                        component="span"
                        sx={classes.iconButton}
                        onClick={handleMenuClick}
                    >
                        <MoreVert />
                    </IconButton> */}
                    <CardContent>
                        <Typography
                            noWrap
                            gutterBottom
                            variant="subtitle1"
                            component="div"
                            sx={classes.title}
                        >
                            {webinar.webinar_title}
                        </Typography>
                        <Typography variant="subtitle1" sx={classes.subtitle} noWrap>
                            {webinar.speaker.map((obj) => obj.speaker_name).join(", ")}
                        </Typography>
                        <hr style={classes.divider} />
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <AccessTimeIcon fontSize="small" sx={{ color: "#00D7B9" }} />
                            <Typography sx={{ fontSize: 12 }} style={{ marginLeft: "5px" }}>
                                {moment(webinar.webinar_date, "DD-MM-YYYY").format("DD-MMMM YYYY")} ,{webinar.webinar_start_time} UTC
                            </Typography>
                        </div>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
        </Grid>
    );
};

export default WebinarBoard;
