import React, { useState, useEffect } from "react";
// import "./styles.css";
import { useHistory, withRouter } from "react-router-dom";
import { Box, Paper, Typography, Grid, TextField, Button } from '@mui/material';
import { connect } from "react-redux"
import { login } from "../Redux/Middlewares/loginMiddleware";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {LoginCloseRequest} from "../Redux/ActionCreators/NavbarActionCreators"



// const Login = ({ setLoggedInUser }) => {
const Login = ({ login, isAuthenticated,closeLogin }) => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showError, setShowError] = useState(false);

  const history = useHistory();

  const [confirmedPassword, setConfirmedPassword] = useState("");


  const handleEmail = (e) => {
    if (e.target.value.length < 30)
      setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    if (e.target.value.length < 30)
      setPassword(e.target.value);
  };


  const paperLayout2 = {
    padding: "10px 10px",
    width: 360,
    height: 450,
    // margin: "10px auto"
  }
  // const paperLayout1 = {
  //   padding: "12px 12px",
  //   width: 400,
  //   height: 480,
  //   margin: "0px auto",


  // }
  const buttonStyle = { width: 270, height: 50 }
  const base = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-48%, -50%)',
    width: 400,
  }



  const handleLogin = async (a) => {
   
    if (email === "" || password === "") return;
    if (email === "admin" && password === "pass@admin") {
      login(email, password)

    };
  }


  return (
    <Grid sx={base}>
      {/* <Paper style={paperLayout1} variant="outlined" square > */}
        <Paper elevation={5} style={paperLayout2} >

        <Box ml={38}><IconButton onClick={()=>closeLogin()} >
            <CloseIcon />
          </IconButton>
          </Box>
          <Grid align="center">

            

            <Box mt={0}><Typography variant="h5" >Vet 2 Vet</Typography></Box>
            <Box mt={2}><Typography variant="body2" >Login</Typography></Box>

            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '30ch' },
              }}
              noValidate
              autoComplete="off"
            >

              <Box mt={2} ml={7}><Typography align="left" variant="h6" >Email </Typography></Box>
              <TextField InputProps={{ disableUnderline: true }}
                id="outlined-multiline-flexible"
                size="small"

                maxRows={4}
                type="email"
                onChange={handleEmail}
                variant="filled"
                value={email}

              />
              <Box mt={2} ml={7}><Typography align="left" variant="h6" >Password </Typography></Box>
              <TextField
                InputProps={{ disableUnderline: true }}
                size="small"
                id="outlined-textarea"
                placeholder="Enter your password"
                onChange={handlePassword}
                variant="filled"
                type="password"
                value={password}
              />
              

              <Box mt={2}>
                <Button id="loginButton" onClick={handleLogin} style={buttonStyle} fullWidth variant="contained">Login</Button>
              </Box>
              <Box ml={12} mt={6} sx={{display:"flex"}}>
                           
                           <Button  size="small" style={{textTransform: 'none',maxWidth: '500px', maxHeight: '15px', minWidth: '30px', minHeight: '15px',
                                  marginLeft:"6px",color:"black",fontWeight: 600}} variant="text">Forgot password?</Button>
                          </Box>
            </Box>
          </Grid>
        </Paper>

      {/* </Paper> */}
    </Grid>

  );
};



const mapStateToProps = (state) => ({
  isAuthenticated: state.authenticationReducer.isAuthenticated
})

const mapDispatchToProps = dispatch => {
  return {
    closeLogin: () => dispatch(LoginCloseRequest()),
    

    
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Login);