import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useHistory, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import CoursesView from "./CoursesView";
import { connect } from "react-redux"
import { makeStyles } from "@mui/styles";
import {
    Grid, IconButton, TextField, InputAdornment, Chip, Drawer, MenuItem, Select, OutlinedInput,
    Stack, Button
} from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ScrollToTopButton from "../VetLearningContent/ScrollToTopButton"
import { getVetCourses } from ".././Redux/Middlewares/VetCourses/getCoursesVetMiddleware"
import SpinnerLG from "../SpinnerLG";
import { Autocomplete } from "@mui/material"
import { getFiltersVetCourses } from "../Redux/Middlewares/VetCourses/getFiltersVetCoursesMiddleware";
import { SET_USER_COURSE_FILTER_DATA, CLEAR_USER_COURSE_FILTER_DATA } from "../Redux/ActionTypes/coursesVetActionTypes"
import { getActiveVetCourses } from "../Redux/Middlewares/VetCourses/getActiveCourseMiddleware"
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import UnenrolledView from "./UnenrolledView";
import EnrolledView from "./EnrolledView";
import { TOGGLE_BACK_BUTTON } from "../Redux/ActionTypes/NavbarActionTypes"
import { SET_COURSES_SEQUENCE, TOGGLE_QUIZ_ACTIVATION, CLEAR_QUIZ_DATA } from "../Redux/ActionTypes/coursesVetActionTypes"
import { enrollToCourse } from "../Redux/Middlewares/VetCourses/enrollToCourse"
import { lessonCompletion } from "../Redux/Middlewares/VetCourses/lessonCompletionMiddleware"
import { getQuizDetails } from "../Redux/Middlewares/VetCourses/getQuizDetailsMiddleware";
import { quizValidation } from "../Redux/Middlewares/VetCourses/quizValidationMiddleware";
import { gradeView } from "../Redux/Middlewares/VetCourses/gradeViewMiddleware";
import { rateCourse } from "../Redux/Middlewares/VetCourses/rateCourseMiddleware";
import { getCourseCertificate } from "../Redux/Middlewares/VetCourses/getCourseCertificateMiddleware"
import { SET_COURSE_CERTIFICATE_PDF } from "../Redux/ActionTypes/coursesVetActionTypes";

const ActiveCourseContainer = ({ getActiveVetCourses, contents, loading, toggleBackButton, setCourseSequence,
    courseSequence, currentSelection, enrollToCourse, lessonCompletion, toggleQuizActivation, quizActive, getQuizDetails,
    quizLoader, quizData, clearQuizData, quizValidation, quizGrade, gradeData, gradeView, rateCourse, currentRating,
    quizWrongAnswers, quizInput, getCourseCertificate, certificate, clearCetificate, country }) => {

    const { id } = useParams()

    useEffect(() => {

        getActiveVetCourses(id)
        toggleBackButton(true)
        return () => {
            toggleBackButton(false)
        }
    }, [])

    //creating flow between unenrolled and enrolled group
    if (loading) {
        return (<SpinnerLG />)
    }
    else if (contents && contents.isEnrolled) {
        return (<EnrolledView contents={contents.data} setCourseSequence={setCourseSequence}
            courseSequence={courseSequence} currentSelection={currentSelection}
            lessonCompletion={lessonCompletion} toggleQuizActivation={toggleQuizActivation}
            quizActive={quizActive} getQuizDetails={getQuizDetails} quizLoader={quizLoader}
            quizData={quizData} clearQuizData={clearQuizData} quizValidation={quizValidation}
            quizGrade={quizGrade} gradeData={gradeData} gradeView={gradeView}
            rateCourse={rateCourse} currentRating={currentRating} quizWrongAnswers={quizWrongAnswers} quizInput={quizInput}
            getCourseCertificate={getCourseCertificate} certificate={certificate} clearCetificate={clearCetificate} country={country} />)
    }
    else if (contents && !contents.isEnrolled && contents.data) {
        return (<UnenrolledView contents={contents.data} enrollToCourse={enrollToCourse} />)

    }
    else {
        return (<>
            Some error occured
        </>)
    }





}


const mapStateToProps = (state) => ({
    // isAuthenticated: state.authenticationReducer.isAuthenticated,
    country: state.authenticationReducer.profile && state.authenticationReducer.profile.country,
    contents: state.CoursesVetReducer.contents,
    loading: state.CoursesVetReducer.loading,
    courseSequence: state.CoursesVetReducer.courseSequence,
    currentSelection: state.CoursesVetReducer.currentSelection,
    quizActive: state.CoursesVetReducer.quizActive,
    quizLoader: state.CoursesVetReducer.quizLoader,
    quizData: state.CoursesVetReducer.quizData,
    quizGrade: state.CoursesVetReducer.quizGrade,
    gradeData: state.CoursesVetReducer.gradeData,
    currentRating: state.CoursesVetReducer.currentRating,
    quizWrongAnswers: state.CoursesVetReducer.quizWrongAnswers,
    quizInput: state.CoursesVetReducer.quizInput,
    certificate: state.CoursesVetReducer.certificate

})

const mapDispatchToProps = dispatch => {
    return {
        getActiveVetCourses: (id) => dispatch(getActiveVetCourses(id)),
        toggleBackButton: (val) => dispatch({ type: TOGGLE_BACK_BUTTON, payload: { backButton: val } }),
        setCourseSequence: (obj, current) => dispatch({ type: SET_COURSES_SEQUENCE, payload: { data: obj, current: current } }),
        enrollToCourse: (id) => dispatch(enrollToCourse(id)),
        lessonCompletion: (course, chapter, lesson) => dispatch(lessonCompletion(course, chapter, lesson)),
        toggleQuizActivation: (val) => dispatch({ type: TOGGLE_QUIZ_ACTIVATION, payload: { quizActive: val } }),
        getQuizDetails: (val) => dispatch(getQuizDetails(val)),
        clearQuizData: () => dispatch({ type: CLEAR_QUIZ_DATA }),
        quizValidation: (obj) => dispatch(quizValidation(obj)),
        gradeView: (id) => dispatch(gradeView(id)),
        rateCourse: (id, val) => dispatch(rateCourse(id, val)),
        getCourseCertificate: (id) => dispatch(getCourseCertificate(id)),
        clearCetificate: () => dispatch({ type: SET_COURSE_CERTIFICATE_PDF, payload: null }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveCourseContainer);
