import { styled, alpha } from "@mui/material/styles";

export default styled('div')(({ theme }) => ({

    // marginLeft: theme.spacing(2),
    // marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
        marginTop: '1%',
        width: "100%",
        whiteSpace: 'nowrap',

    },

    [theme.breakpoints.down("sm")]: {
        marginTop: '1%',
        width: "100%",
        whiteSpace: 'nowrap'
    },
    [theme.breakpoints.up("sm")]: {
        marginTop: '1%',
        width: "70%",
        whiteSpace: 'nowrap'
    },

}));