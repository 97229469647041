import {REGISTER_SUCCESS,USER_LOADED,AUTH_ERROR,LOGIN_SUCCESS,
    LOGOUT,ACCOUNT_DELETED,LOGIN_FAIL,SET_FORGOTPASS_SCREENS_FLOW,SET_AUTH_ID_OTP
} from "../ActionTypes/authenticationActionTypes"


export const loginRequest=(result)=>(
    {
        type:LOGIN_SUCCESS,
        payload:result
    }
)

export const loginFailureRequest=()=>(
    {
        type:LOGIN_FAIL

    }
)

export const loadUserRequest=(result)=>(
    {
        type:USER_LOADED,
        payload:result
    })
    
export const authErrorRequest=()=>(
    {
        type:AUTH_ERROR
    }

)

export const signupRequest=(result)=>(
    {
        type:REGISTER_SUCCESS,
        payload:result
    }
)

export const SetForgotPasswordScreenFlow=(result)=>(
    {
        type:SET_FORGOTPASS_SCREENS_FLOW,
        payload:result
    }
)

export const setAuthIdOTP=(result)=>(
    {
        type:SET_AUTH_ID_OTP,
        payload:result
    }
)