export const classes = {
  addChapterCard: {
    padding: "1%",
  },
  discardBtn: {
    float: "right",
    margin: "0 1%",
  },
  saveBtn: {
    float: "right",
    margin: "0 1%",
  },
  tempChapterCard: {
    padding: "1%",
    margin: "1% 0",
  },
  tempChapterTitle: {
    width: "75%",
  },
  chapterAccordian: {
    margin: "1% 0",
    boxShadow: "none",
    border: "1px solid lightgrey",
    borderRadius: "3px",
  },
  chapterTitle: {
    color: "#0f01a0",
    fontWeight: "bold",
    marginRight: "2%",
  },
  chapterInfo: {
    fontSize: "90%",
    fontStyle: "italic",
    marginTop: "2px",
    width: "20%",
  },
  threeDotMenu: {
    position: "absolute",
    top: "10%",
    right: "5%",
  },
  lessonTitle: {
    margin: "1%",
    fontWeight: "bold",
    position: "relative",
  },
  fileType: {
    float: "right",
    fontSize: "90%",
    fontWeight: "normal",
    margin: "0 1%",
    fontStyle: "italic",
  },
  duration: {
    float: "right",
    fontSize: "90%",
    fontWeight: "normal",
    margin: "0 1%",
    marginRight: "5%",
    fontStyle: "italic",
  },
  lessonThreeDotMenu: {
    position: "absolute",
    right: "0",
    top: "-40%",
  },
  lessonDuration: {
    marginLeft: "5%",
  },
  addLessonBtns: {
    margin: "1%",
  },
};
