export const classes = {
  heading: { fontSize: "125%", fontWeight: "bold", color: "#250996" },
  fileDetails: {
    background: "#f2f2f2",
    boxShadow: "1px 1px 5px lightgrey",
    padding: "3%",
  },
  iconButton: { float: "right", marginRight: "2%" },
  title: { display: "inline-block", marginRight: "1%" },
  fileName: { width: "30%" },
  fileDetailsInputContainers: { margin: "2% 0" },
  textFieldOne: { width: "30%" },
  filterOne: {
    width: "30%",
    display: "inline-block",
    marginLeft: "2%",
  },
  textFieldTwo: { width: "100%" },
  filterTwo: {
    width: "30%",
    display: "inline-block",
  },
  uploadButton: { margin: "2% 0" },
  error: { color: "crimson", marginLeft: "2%" },
  dialogContent: {
    fontSize: "125%",
    color: "black",
    fontWeight: "normal",
    marginTop: "5%",
  },
  dialogActions: {
    margin: "3%",
  },
  activeCrumb: { color: "#250996" },
  inactiveCrumb: { cursor: "pointer" },
};
