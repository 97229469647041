export const FILE_UPLOAD_FAILURE = "FILE_UPLOAD_FAILURE";
export const FILE_UPLOAD_REQUEST = "FILE_UPLOAD_REQUEST";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const SELECT_FILE = "SELECT_FILE";
export const FETCH_FILTER_VALUES_FAILURE = "FETCH_FILTER_VALUES_FAILURE";
export const FETCH_FILTER_VALUES_REQUEST = "FETCH_FILTER_VALUES_REQUEST";
export const FETCH_FILTER_VALUES_SUCCESS = "FETCH_FILTER_VALUES_SUCCESS";
export const SELECT_FILTER = "SELECT_FILTER";
export const FETCH_UPLOADED_FILES_FAILURE = "FETCH_UPLOADED_FILES_FAILURE";
export const FETCH_UPLOADED_FILES_REQUEST = "FETCH_UPLOADED_FILES_REQUEST";
export const FETCH_UPLOADED_FILES_SUCCESS = "FETCH_UPLOADED_FILES_SUCCESS";
export const CHANGE_PAGE = "CHANGE_PAGE";
export const SELECT_ARTICLE = "SELECT_ARTICLE";
export const FETCH_SEARCH_RESULT_FAILURE = "FETCH_SEARCH_RESULT_FAILURE";
export const FETCH_SEARCH_RESULT_REQUEST = "FETCH_SEARCH_RESULT_REQUEST";
export const FETCH_SEARCH_RESULT_SUCCESS = "FETCH_SEARCH_RESULT_SUCCESS";
export const EDIT_FILE_FAILURE = "EDIT_FILE_FAILURE";
export const EDIT_FILE_REQUEST = "EDIT_FILE_REQUEST";
export const EDIT_FILE_SUCCESS = "EDIT_FILE_SUCCESS";
export const DELETE_FILE_FAILURE = "DELETE_FILE_FAILURE";
export const DELETE_FILE_REQUEST = "DELETE_FILE_REQUEST";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const FILE_EDIT_FAILURE = "FILE_EDIT_FAILURE";
export const FILE_EDIT_REQUEST = "FILE_EDIT_REQUEST";
export const FILE_EDIT_SUCCESS = "FILE_EDIT_SUCCESS";
export const TOGGLE_ALERT = "TOGGLE_ALERT";
