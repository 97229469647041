export const classes = {
  subHeading: {
    color: "rgba(0, 0, 0, 0.6)",
  },
  addCreatorDiv: {
    margin: "2% 0",
    marginBottom: "0%",
  },
  noPaddingTopGrid: {
    paddingTop: "0% !important",
  },
  creatorDescriptionField: {
    width: "100%",
  },
};
