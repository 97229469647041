import React, { useState, useEffect } from "react";
import {
    Avatar,
    Button,
    Card,
    Checkbox,
    Chip,
    FormControlLabel,
    Stack,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import { classes } from "./styles";
import "./style.css";
import { TextareaAutosize } from "@mui/base";
import { format } from "date-fns";
import { connect, useDispatch } from "react-redux";
// import { updateAdminQueryAnswer, fetchAdminQueryDetails,updateCheckboxState } from "../../Redux/Middlewares/queriesAdminMiddleware";
import { updateVetQueryReply } from "../../../Redux/Middlewares/queriesVetMiddleware";

import { LoadingButton } from "@mui/lab";
import { catchError } from "../../../Redux/ActionCreators/queriesAdminActionCreators";

const QueryCardNew = ({ query, queriesAdmin, queriesVet, tab, user, uniqKey, currentPageNumber, updateVetQueryReply }) => {
    const { isLoading, selectedFilters, queryDetails: { results }, repliedQuery } = queriesVet;

    const getDateObject = () => {
        if (
            selectedFilters.duration === "" ||
            selectedFilters.duration === null ||
            selectedFilters.duration.value === "All time"
        )
            return {
                fixed: "All time",
                start_date: null,
                end_date: null,
            };
        else if (selectedFilters.duration.value === "1 Month")
            return {
                fixed: "1 Month",
                start_date: null,
                end_date: null,
            };
        else if (selectedFilters.duration.value === "3 Months")
            return {
                fixed: "3 Months",
                start_date: null,
                end_date: null,
            };
        else if (selectedFilters.duration.value === "6 Months")
            return {
                fixed: "6 Months",
                start_date: null,
                // start_date: handleDateOutput(6),
                end_date: null,
            };
    };
    //creating filters object
    const filterObject = {
        query_category: selectedFilters.category,
        content_type: selectedFilters.type,
        // query_visibilty: selectedFilters.Visibility,
        search_value: selectedFilters.search
            ? [selectedFilters.search]
            : [],
        query_type: tab,
        show_content_from: getDateObject(),
    };

    const dispatch = useDispatch();

    // const [fullQuery, setFullQuery] = useState(false);
    // const [answerDetails, setAnswerDetails] = useState([]);
    const [status, setStatus] = useState(false);
    const [open, setOpen] = useState(false);
    const [query_answer, setQueryAnswer] = useState("");
    const [replying, setReplying] = useState(false);
    const [replyObject, setReplpyObject] = useState(null);
    const [dialogText, setDialogText] = useState("");

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const handleSubmitReply = (submit) => {
        if (!query_answer) return;
        const reply = {
            query_id: query.query_id,
            query_answer,
            answered_by: user,
            submit,
        };
        setReadMore(false);

        setReplpyObject(reply);
        updateVetQueryReply(reply, filterObject, currentPageNumber)
        if (repliedQuery !== "" && repliedQuery != undefined) {
            setQueryAnswer("");
            setReplpyObject(null);
            setDialogText("Reply sent successfully.");
            handleOpen();
            setTimeout(() => handleClose(), 1000);
            setReplying(false);
            setStatus(true);
        }
        else {
            setDialogText("Some error has occurred");
            setStatus(false);
            handleOpen();
            // error.clientMessage = "Failed to reply query";
            // dispatch(catchError(error));
        }
    };

    const handleSubmitReplyRetry = () => {
        dispatch(updateVetQueryReply(replyObject, filterObject, currentPageNumber))
            .then(() => {
                setQueryAnswer("");
                setReplpyObject(null);
                setDialogText("Reply sent successfully.");
                setStatus(true);
                handleOpen();
                setTimeout(() => handleClose(), 1000);
                setReplying(false);
            })
            .catch((error) => {
                error.clientMessage = "Failed to update admin query answer";
                dispatch(catchError(error));
            });
    };

    // useEffect(() => {
    //     if (!query.answer_details) return;

    //     if (fullQuery) setAnswerDetails(query.answer_details);
    //     else if (query.answer_details.length > 0)
    //         setAnswerDetails([query.answer_details[query.answer_details.length-1]]);
    //     else setAnswerDetails([]);
    // }, [fullQuery, query]);

    useEffect(() => {
        // setFullQuery(false);
        setReadMore(false)
    }, [tab, currentPageNumber])

    const formatDate = (date) => format(new Date(date), "dd-MMM-yyyy");
    const formatTime = (date) => format(new Date(date), "hh:mm");
    // **********************************************************
    const [readMore, setReadMore] = useState(false);

    const replyCards = (fullQ) => {

        return (
            fullQ.map((e, index) => {
                return (
                    <Card
                        key={e.query_id + index} sx={classes.card} className={e.user_type!="Vet" ? "query-card-admin": ""}>
                        <div className="query-card-head">
                            <div className="query-card-head-left">
                                <h5>{e.user_type == "Vet" ? e.occupation : "Admin"}</h5>
                                {/* <h5>Replied person occupation</h5> */}
                            </div>
                            <div className="query-card-head-right">
                                {formatDate(e.end_timestamp)}{" "}
                                <span>
                                    • {formatTime(e.end_timestamp)}
                                </span>
                            </div>
                        </div>
                        <div className="query-card-body">
                            <p>{e.query_answer}</p>
                        </div>
                    </Card>
                    // <div>
                    //     {e.query_answer}
                    // </div>
                )
            })
        )

    }

    return (
        <Card sx={classes.card} className="query-card">
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <strong>{dialogText}</strong>
                        </DialogContentText>
                    </DialogContent>
                    {!status && (
                        <DialogActions>
                            <LoadingButton
                                variant="contained"
                                size="small"
                                onClick={handleSubmitReplyRetry}
                                loading={isLoading}
                            >
                                Retry
                            </LoadingButton>
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={handleClose}
                                autoFocus
                            >
                                Go to Queries page
                            </Button>
                        </DialogActions>
                    )}
                </Dialog>
            </div>
            <div className="query-card-head">
                <div className="query-card-head-left">
                    <Avatar
                        alt="Remy Sharp"
                        src="./assets/images/slide-1.png"
                        sx={{ width: 32, height: 32 }}
                    />
                    <h5>{query.creater_details ? query.creater_details.full_name : ""}</h5>
                    <Chip
                        color="secondary"
                        sx={classes.chipStyle}
                        label={`${query.content_type} Query`}
                        size="small"
                    />
                </div>
                <div className="query-card-head-right">
                    {formatDate(query.created_date)}{" "}
                    <span>• {formatTime(query.created_date)}</span>
                </div>
            </div>
            <div className="query-card-body">
                <h5>
                    {query.creater_details ? query.creater_details.occupation : ""}
                </h5>
                <div>{query.query_text}</div>
                <div>
                    {(readMore == false && query.answer_details != null && query.answer_details.length > 1) ?
                        <Button
                            sx={{
                                color: "black",
                                fontWeight: "bold",
                                textTransform: "none",
                                marginLeft: "auto",
                            }}
                            variant="text"
                            size="small"
                            onClick={() => setReadMore(true)}>
                            Read full query
                        </Button> : ""}
                </div>
                <div>
                    {readMore == false && query.answer_details != null && replyCards([query.answer_details[query.answer_details.length - 1]])}
                    {readMore == true && query.answer_details != null && replyCards(query.answer_details)}
                </div>
                {replying && (
                    <div className="reply-textarea">
                        <TextareaAutosize
                            placeholder="Type your reply here"
                            minRows={4}
                            style={{ width: "100%" }}
                            sx={classes.textarea}
                            value={query_answer}
                            onChange={(e) => {
                                if (e.target.value.length <= 1000)
                                    setQueryAnswer(e.target.value)

                            }}
                        />
                    </div>
                )}
            </div>

            <div className="query-card-footer">
                {(!replying && tab == "my_queries") ?
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setReplying(true)}>
                        Reply
                    </Button> : ""}
                {replying && (
                    <>
                        {" "}
                        <LoadingButton
                            variant="contained"
                            size="small"
                            onClick={() => handleSubmitReply("")}
                            loading={isLoading}
                        >
                            Submit Reply
                        </LoadingButton>
                        <Button
                            variant="text"
                            size="small"
                            style={{ marginLeft: "auto" }}
                            onClick={() => setReplying(false)}
                        >
                            Cancel
                        </Button>
                    </>
                )}
            </div>
        </Card>
    );
};

const mapStateToProps = (state) => ({
    queriesAdmin: state.QueriesAdminReducer,
    queriesVet: state.QueriesVetReducer,
    user: state.authenticationReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
    updateVetQueryReply: (queryObj, filterObj, currentPage) => dispatch(updateVetQueryReply(queryObj, filterObj, currentPage)),
    // fetchAdminQueryDetails: (request) => dispatch(fetchAdminQueryDetails(request)),
    // updateCheckboxState: (currCheckBoxId) => dispatch(updateCheckboxState(currCheckBoxId))
});

export default connect(mapStateToProps, mapDispatchToProps)(QueryCardNew);
