import axios from "axios";
import {
    //   fetchWEBINARSFailure,
    //   fetchWEBINARSRequest,
    fetchWebinarsSuccess,

    //   toggleAlert,
} from "../../ActionCreators/webinarsAdminActionCreators";
import {
    CLEAR_WEBINARS_DATA,
    FETCH_WEBINARS_DATA,
    SET_WEBINARS_DATA,
    SET_WEBINARS_DATA_PAGINATION,
    WEBINAR_NAVIGATION
} from "../../ActionTypes/webinarsAdminActionTypes"

import { toggleAlert } from "../../ActionCreators/coursesAdminActionCreators";

// const GET_WEBINARS_URL =
//   process.env.REACT_APP_API_URL + "/WEBINARS/admin/course_homepage/?page=";


export const createNewWebinar = (reqObj,) => async dispatch => {

    try {
        // dispatch({ type: CLEAR_WEBINARS_DATA })
        // dispatch({ type: FETCH_WEBINARS_DATA })
        dispatch({ type: WEBINAR_NAVIGATION, payload: { webinarSaving: true } })
        let url = process.env.REACT_APP_API_URL + `/webinars/admin/create_webinar/`




        let result = await axios.post(url, reqObj)

        if (result.data) {

            // toggleAlert("success", "Webinar added", true)

            dispatch({ type: WEBINAR_NAVIGATION, payload: { webinarNavigation: 1, webinarSaving: false } })




        }
        else {
            // dispatch({ type: CLEAR_WEBINARS_DATA })
            // dispatch({ type: SET_GENERIC_ERROR_ALERT })
            // toggleAlert("error", "Something went wrong !", true)
            dispatch({ type: WEBINAR_NAVIGATION, payload: { webinarSaving: false } })
        }



    }
    catch (err) {
        // dispatch({ type: CLEAR_WEBINARS_DATA })
        // toggleAlert("error", "Something went wrong !", true)
        dispatch({ type: WEBINAR_NAVIGATION, payload: { webinarSaving: false } })
        // dispatch({ type: SET_GENERIC_ERROR_ALERT })
    }
}


