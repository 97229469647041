import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import CoursesView from "./CoursesView";
import {
    Grid, IconButton, TextField, InputAdornment, Chip, Drawer, MenuItem, Select, OutlinedInput,
    Stack, Button, Paper, Typography, Tabs, Tab
} from "@mui/material"
import { styled, alpha } from "@mui/material/styles";

import CourseOverView from "./CourseOverview";
import GradesCourse from "./GradesCourse"
import Certificates from "./Certificates";
import Rating from '@mui/material/Rating';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    height: 48,

    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    color: "black",



}));


const EnrolledView = ({ contents, setCourseSequence, courseSequence, currentSelection, lessonCompletion,
    toggleQuizActivation, quizActive, getQuizDetails, quizLoader, quizData, clearQuizData, quizValidation, quizGrade, gradeData, gradeView,
    rateCourse, currentRating, quizWrongAnswers, quizInput, getCourseCertificate, certificate, clearCetificate ,country }) => {

    const { id } = useParams()

    const [rootTab, setRoot] = useState(0) //0 for overview, 1 for grades and 2 for certificates
    const [open, setOpen] = React.useState([]);
    const [tab, setTab] = useState("overview");

    const handleTabChange = (event, tabValue) => {
        setTab(tabValue)
        let val = tabValue === "overview" ? 0 : tabValue === "grades" ? 1 : 2
        setRoot(val)


    }

    useEffect(() => {
        if (rootTab === 1) {
            gradeView(id)
        }
    }, [rootTab])

    const selectedStyle = {
        fontWeight: 600,
        border: 1,
        backgroundColor: "#f3f3fb",
        borderColor: "#0f01a0",




    }
    const unSelectedStyle = {
        border: 1,
        borderColor: "#acacac"
    }

    const handleRating = (event, newValue) => {
        rateCourse(id, newValue)
    }

    const mobileView = () => {
        return (
            <Grid container sx={{ marginTop: 4, marginBottom: 4, display: { xs: "flex", sm: "none", md: "none" } }}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" spacing={1}>
                        <Item sx={rootTab === 0 ? selectedStyle : unSelectedStyle} onClick={() => { setRoot(0); setTab("overview"); }}>Overview</Item>
                        <Item sx={rootTab === 1 ? selectedStyle : unSelectedStyle} onClick={() => { setRoot(1); setTab("grades"); }}>Grades</Item>
                        <Item sx={rootTab === 2 ? selectedStyle : unSelectedStyle} onClick={() => { setRoot(2); setTab("certificate"); }}>Certificate</Item>
                    </Stack>

                </Grid>
                <Grid item xs={12} sx={{ marginBottom: 4 }}>
                    {rootTab === 0 && <CourseOverView contents={contents} setCourseSequence={setCourseSequence} courseSequence={courseSequence}
                        currentSelection={currentSelection} lessonCompletion={lessonCompletion}
                        toggleQuizActivation={toggleQuizActivation} quizActive={quizActive} getQuizDetails={getQuizDetails}
                        quizLoader={quizLoader} quizData={quizData} clearQuizData={clearQuizData}
                        quizValidation={quizValidation} quizGrade={quizGrade} open={open} setOpen={setOpen} setRoot={setRoot}
                        quizWrongAnswers={quizWrongAnswers} quizInput={quizInput} setTab={setTab}/>}

                    {rootTab === 1 && <GradesCourse gradeData={gradeData} gradeView={gradeView} quizLoader={quizLoader} />}
                    {rootTab === 2 && <Certificates contents={contents} getCourseCertificate={getCourseCertificate} 
                    certificate={certificate} clearCetificate={clearCetificate} country={country} />}
                </Grid>
                {contents && contents[0] && contents[0].course_completion === 100 && <Grid item xs={12}>
                    <Box sx={{ width: "100%", minHeight: "6vh", color: "white", backgroundColor: "white", display: "flex", bottom: "0%", position: "fixed", zIndex: 1 }}>
                        <Typography sx={{ color: "#0f01a0", zIndex: 1, bottom: "1vh", left: "5%", position: "fixed", fontWeight: 600 }} > Rate this Course</Typography>
                        <Rating onChange={handleRating} sx={{ color: "#6ad7b9", zIndex: 1, bottom: "1vh", left: "45%", position: "fixed" }} name="size-large" defaultValue={currentRating} size="large" />
                    </Box>
                </Grid>}

            </Grid>
        )
    }

    const desktopView = () => {
        return (
            <Grid container md={12} spacing={2} sx={{ marginTop: 4, marginBottom: 4, display: { xs: "none", sm: "flex", md: "flex" }, height: "100vh" }} >
                <Grid item sm={4} md={4} sx={{ backgroundColor: "rgb(242, 242, 242)" }}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={tab}
                        onChange={handleTabChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                        <Tab label="Overview" value="overview" />
                        <Tab label="Grades" value="grades" />
                        <Tab label="Certificate" value="certificate" />

                    </Tabs>
                </Grid>

                <Grid item sm={8} md={8} sx={{ marginBottom: 4 }}>
                    {tab === "overview" && <CourseOverView contents={contents} setCourseSequence={setCourseSequence} courseSequence={courseSequence}
                        currentSelection={currentSelection} lessonCompletion={lessonCompletion}
                        toggleQuizActivation={toggleQuizActivation} quizActive={quizActive} getQuizDetails={getQuizDetails}
                        quizLoader={quizLoader} quizData={quizData} clearQuizData={clearQuizData}
                        quizValidation={quizValidation} quizGrade={quizGrade} open={open} setOpen={setOpen} setRoot={setRoot}
                        quizWrongAnswers={quizWrongAnswers} quizInput={quizInput} setTab={setTab} />}

                    {tab === "grades" && <GradesCourse gradeData={gradeData} gradeView={gradeView} quizLoader={quizLoader} />}
                    {tab === "certificate" && <Certificates contents={contents} getCourseCertificate={getCourseCertificate}
                     certificate={certificate} clearCetificate={clearCetificate} country={country} />}
                </Grid>
                {contents && contents[0] && contents[0].course_completion === 100 && <Grid item xs={12} md={12}>
                    <Box sx={{ width: "100%", minHeight: "6vh", color: "white", backgroundColor: "white", display: "flex", bottom: "0%", position: "fixed", zIndex: 1 }}>
                        <Typography sx={{ color: "#0f01a0", zIndex: 1, bottom: "1vh", left: "5%", position: "fixed", fontWeight: 600 }} > Rate this Course</Typography>
                        <Rating onChange={handleRating} sx={{ color: "#6ad7b9", zIndex: 1, bottom: "1vh", left: "45%", position: "fixed" }} name="size-large" defaultValue={currentRating} size="large" />
                    </Box>
                </Grid>}
            </Grid>
        )
    }

    return (<Box sx={{ padding: 1.5, marginTop: 5 }}>
        {desktopView()}
        {mobileView()}
    </Box>)
}

export default EnrolledView