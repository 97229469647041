import {
    FETCH_COURSES_DATA, SET_COURSES_DATA, SET_COURSES_USER_DATA, CLEAR_COURSES_DATA, SET_COURSES_FAILURE_DATA,
    SET_ACTIVE_COURSE_DATA, CLEAR_ACTIVE_COURSE_DATA, SET_QUIZ_DATA, FETCH_QUIZ_DATA, TOGGLE_QUIZ_ACTIVATION,
    SET_QUIZ_RESULT
} from "../../ActionTypes/coursesVetActionTypes"

import { SET_QUERY_ADDED_ALERT, CLEAR_QUERY_ADDED_ALERT, SET_GENERIC_ERROR_ALERT } from "../../ActionTypes/alertsActionTypes"
import axios from "axios"

//Login 
export const quizValidation = (reqObj) => async dispatch => {


    try {
        dispatch({ type: FETCH_QUIZ_DATA, payload: true })


        let url = process.env.REACT_APP_API_URL + `/vetcourses/quiz_validate/`




        let result = await axios.post(url, reqObj)

        if (result.data && result.data.passed) {





            let url2 = process.env.REACT_APP_API_URL + `/vetcourses/sideview/`


            let reqObj2 = {
                "course_id": reqObj.course_id
            }

            let result2 = await axios.post(url2, reqObj2)


            dispatch({ type: SET_ACTIVE_COURSE_DATA, payload: { isEnrolled: true, data: result2.data } })
            dispatch({ type: FETCH_QUIZ_DATA, payload: false })
            dispatch({ type: TOGGLE_QUIZ_ACTIVATION, payload: { quizActive: 2 } }) //2 for nagivating to quiz completion msg screen
            dispatch({ type: SET_QUIZ_RESULT, payload: { "quizGrade": result.data.grade, "quizResult": "pass" } })



        }
        else if (result.data && result.data.question_id) {

            let modData = {};
            reqObj.data.forEach(element => {
                modData[element.question_id] = element.answer_id
            });

            dispatch({ type: FETCH_QUIZ_DATA, payload: false })
            dispatch({ type: TOGGLE_QUIZ_ACTIVATION, payload: { quizActive: 3 } }) //3 for nagivating to quiz failure msg screen
            dispatch({
                type: SET_QUIZ_RESULT, payload: {
                    "quizGrade": result.data.grade,
                    "quizResult": "fail",
                    quizWrongAnswers: result.data.question_id,
                    quizInput: modData
                }
            })

        }
        else {
            dispatch({ type: TOGGLE_QUIZ_ACTIVATION, payload: { quizActive: 0 } })
            dispatch({ type: SET_GENERIC_ERROR_ALERT })

            dispatch({ type: FETCH_QUIZ_DATA, payload: false })
        }



    }
    catch (err) {
        dispatch({ type: TOGGLE_QUIZ_ACTIVATION, payload: { quizActive: 0 } })
        dispatch({ type: SET_GENERIC_ERROR_ALERT })

        dispatch({ type: FETCH_QUIZ_DATA, payload: false })
    }
}

