import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Typography,
  Grid,
  FormHelperText,
  Autocomplete,
  TextField,
  InputAdornment,
  Button,
  CircularProgress,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import AddCreator from "../AddCreator/AddCreator";
import { changeCourseDetails } from "../../Redux/ActionCreators/coursesAdminActionCreators";
import { classes } from "./styles";
import { Prompt } from "react-router-dom";


const CourseDetails = ({
  isLoading,
  filters,
  courseDetails,
  changeCourseDetails,
  isUploading,
  isEditFlow,
  activeCourse
}) => {
  const options = [
    { title: "Alpha", year: 1 },
    { title: "Beta", year: 2 },
    { title: "Gamma", year: 3 },
    { title: "Delta", year: 4 },
  ];

  useEffect(() => {}, [courseDetails]);

  const [image, setImage] = useState("");

  const handleImageUpload = (e, fileType) => {
  
    if (e.target.files[0]) {



      if (fileType === "course_image_path") {

        if (!(["jpg", "jpeg", "png"].includes(e.target.files[0].name.split(".").pop()))) {
          alert("Please upload image of accepted formats")
          return false;
        }

        else if (e.target.files[0].size / 1024 / 1024 > 5) {
          alert("File size limit exceeded.")
          return false;
        }
      }

      if (fileType === "course_prevvideo_path") {
        if (!(["mp4", "mov"].includes(e.target.files[0].name.split(".").pop()))) {
          alert("Please upload video of accepted formats")
          return false;
        }
        if (e.target.files[0].size / 1024 / 1024 > 500) {
          alert("File size limit exceeded.")
          return false;
        }
      }

      changeCourseDetails(fileType, e.target.files[0], false, false, "", 0);
    }
    // const reader = new FileReader();
    // const url = URL.createObjectURL(e.target.files[0]);
    // setImage(url);
  };

  const checkForLength = (e, type, speakerNumber = 0) => {
    if (type === "course_description")
      if (e.target.value.length > 300) return;
      else
        changeCourseDetails(
          "course_description",
          e.target.value,
          true,
          false,
          "",
          0
        );
    else if (type === "speaker_description")
      if (e.target.value.length > 100) return;
      else
        changeCourseDetails(
          "speaker_description",
          e.target.value,
          true,
          true,
          "edit",
          speakerNumber
        );
    else if (type === "course_title")
      if (e.target.value.length > 100) return;
      else
        changeCourseDetails("course_title", e.target.value, true, false, "", 0);
  };
  const trimTextOnBlur = (e, type, speakerNumber = 0) => {
    if (type === "course_description")
      if (e.target.value.length > 300) return;
      else
        changeCourseDetails(
          "course_description",
          e.target.value.trim(),
          true,
          false,
          "",
          0
        );
    else if (type === "speaker_description")
      if (e.target.value.length > 100) return;
      else
        changeCourseDetails(
          "speaker_description",
          e.target.value.trim(),
          true,
          true,
          "edit",
          speakerNumber
        );
    else if (type === "course_title")
      if (e.target.value.length > 100) return;
      else
        changeCourseDetails("course_title", e.target.value.trim(), true, false, "", 0);
  };


  let showPrompt = true

  if (
    !courseDetails["course_image_path"] &&
    !courseDetails.data["course_title"] &&
    !courseDetails.data["course_category"] &&
    !courseDetails.data["course_language"] &&
    !courseDetails.data["course_country"].length > 0 &&
    !courseDetails.data["course_description"] &&
    !courseDetails.data["species"] &&
    courseDetails.data.speaker.find(
      (obj) =>
        !obj.speaker_name &&
        !obj.speaker_qualification &&
        !obj.speaker_description
    )

  ) {
    showPrompt = false
  }



  return (
    <Grid
      container
      spacing={2}
      className="new_course_section"
      sx={classes.grid}
    >
      <Prompt
        when={!activeCourse && showPrompt}
        message='Press OK to go back discarding any unsaved changes'
      />
      <Typography
        align="left"
        variant="subtitle2"
        noWrap
        sx={classes.subHeading}
      >
        Basic Details
      </Typography>
      <Grid item xs={12}>
        <FormHelperText id="course_title_field">Course Title</FormHelperText>
        <TextField
          size="small"
          id="course_title_field"
          //   placeholder=""
          variant="outlined"
          className="course_title_field"
          inputProps={{ maxLength: 100, }}
          sx={classes.courseTitleField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {courseDetails.data["course_title"].length} / 100
              </InputAdornment>
            ),
          }}
          value={courseDetails.data["course_title"]}
          onChange={(e) => checkForLength(e, "course_title")}
          onBlur={(e) => trimTextOnBlur(e, "course_title")}
        />
      </Grid>
      <Grid item xs={4}>
        <FormHelperText id="category_filter">Category</FormHelperText>
        <Autocomplete
          size="small"
          disablePortal
          id="category_filter"
          options={
            filters.category && Object.keys(filters).length
              ? filters.category.map((obj) => obj.category_name)
              : []
          }
          //   options={options}
          sx={classes.filter}
          loading={isLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select category"
              // label=""
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          getOptionLabel={(option) => option || ""}
          value={courseDetails.data["course_category"]}
          onChange={(e, value) =>
            changeCourseDetails(
              "course_category",
              value === null ? "" : value,
              true,
              false,
              "",
              0
            )
          }


        />
      </Grid>
      <Grid item xs={4}>
        <FormHelperText id="language_filter">Language of course</FormHelperText>
        <Autocomplete
          size="small"
          disablePortal
          id="language_filter"
          options={
            filters.language && Object.keys(filters).length
              ? filters.language.map((obj) => obj.value)
              : []
          }
          //   options={options}
          sx={classes.filter}
          loading={isLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select language"
              // label=""
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          getOptionLabel={(option) => option || ""}
          value={courseDetails.data["course_language"]}
          onChange={(e, value) =>
            changeCourseDetails(
              "course_language",
              value === null ? "" : value,
              true,
              false,
              "",
              0
            )
          }

        />
      </Grid>
      {<Grid item xs={4}>
        <FormHelperText id="country_filter">Country of course</FormHelperText>
        <Autocomplete
          size="small"
          limitTags={1}
          disablePortal
          multiple
          id="country_filter"
          options={
            filters.country && Object.keys(filters).length > 0
              ? filters.country
              : []
          }
          //   options={options}
          sx={classes.filter}
          loading={isLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select country"
              // label=""
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          getOptionLabel={(option) => option.value || ""}
          value={courseDetails.data["course_country"]}
          onChange={(e, value) =>
            changeCourseDetails(
              "course_country",
              value === null ? [] : value,
              true,
              false,
              "",
              0
            )
          }

        />
      </Grid>}
      <Grid item xs={4}>
        <FormHelperText id="species_filter">Species</FormHelperText>
        <Autocomplete
          size="small"
          disablePortal
          id="species_filter"
          options={
            filters.species && Object.keys(filters).length
              ? filters.species.map((obj) => obj.value)
              : []
          }
          //   options={options}
          sx={classes.filter}
          loading={isLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select species"
              // label=""
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          getOptionLabel={(option) => option || ""}
          value={courseDetails.data["species"]}
          onChange={(e, value) =>
            changeCourseDetails(
              "species",
              value === null ? "" : value,
              true,
              false,
              "",
              0
            )
          }
        />
      </Grid>
      <hr style={classes.divider} />
      <Typography
        align="left"
        variant="subtitle2"
        noWrap
        sx={classes.subHeading}
      >
        Course Info
      </Typography>
      <Grid item xs={12}>
        <FormHelperText id="course_description_field">
          Course Description
        </FormHelperText>
        <TextField
          size="small"
          id="course_description_field"
          //   placeholder=""
          variant="outlined"
          className="course_description_field"
          sx={classes.courseDescriptionField}
          rows={4}
          multiline
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {courseDetails.data["course_description"].length} / 300
              </InputAdornment>
            ),
          }}
          inputProps={{ maxLength: 300, }}
          value={courseDetails.data["course_description"]}
          onChange={(e) => checkForLength(e, "course_description")}
          onBlur={(e) => trimTextOnBlur(e, "course_description")}
        />
      </Grid>
      {courseDetails.data.speaker.map((object, index) => (
        <AddCreator
          key={index}
          changeCourseDetails={changeCourseDetails}
          checkForLength={checkForLength}
          trimTextOnBlur={trimTextOnBlur}
          speakerNumber={index}
          courseDetails={courseDetails}
        />
      ))}
      <Button
        sx={classes.addCreatorButton}
        startIcon={<Add />}
        onClick={() =>
          changeCourseDetails(
            "",
            "",
            true,
            true,
            "add",
            courseDetails.data.speaker.length
          )
        }
      >
        Add creator
      </Button>
      <hr style={classes.divider} />
      <Grid item xs={12} sx={classes.subHeadingGrid}>
        <Typography
          align="left"
          variant="subtitle2"
          noWrap
          sx={classes.subHeadingCreatives}
        >
          Creatives for course
        </Typography>
      </Grid>
      {/* <Grid container spacing={2} sx={classes.creativesGrid}> */}
      <Grid item xs={6}>
        <FormHelperText id="course_image_field">Course Image</FormHelperText>
        <TextField
          size="small"
          id="course_image_field"
          disabled
          // placeholder="No file selected"
          variant="outlined"
          className="course_image_field"
          sx={classes.courseImageField}
          multiline
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <label htmlFor="upload_course_image">
                  <input
                    accept=".png, .jpg, .jpeg"
                    id="upload_course_image"
                    //   multiple
                    hidden
                    type="file"
                    sx={classes.input}
                    onChange={(e) => handleImageUpload(e, "course_image_path")}
                  />
                  <Button component="span">{isEditFlow ? "Change file" : "Upload file"}</Button>
                </label>
              </InputAdornment>
            ),
          }}
          value={
            courseDetails.course_image_path
              ? (courseDetails.course_image_path.name ?
                courseDetails.course_image_path.name :
                courseDetails.course_image_path.split("?")[0].split("/")
                [courseDetails.course_image_path.split("?")[0].split("/").length - 1])
              : "No file selected"
          }
        //   onChange={(e) => selectFilter("search", e.target.value)}
        />
        <p style={classes.detailsTitle}>
          Supported formats -
          <span style={classes.detailsDescription}> .jpeg, .jpg, .png</span>
        </p>
        <p style={classes.detailsTitle}>
          Dimensions -
          <span style={classes.detailsDescription}> 600 x 300 pixels</span>
        </p>
        <p style={classes.detailsTitle}>
          Size -<span style={classes.detailsDescription}> less than 5 mb</span>
        </p>
      </Grid>
      <Grid item xs={6} sx={classes.previewDivGrid}>
        {courseDetails && courseDetails.course_image_path && !isEditFlow ? (
          <img
            src={URL.createObjectURL(courseDetails.course_image_path)}
            style={{
              backgroundColor: "#F8F8F8",
              borderRadius: "10px",
              height: "250px",
            }}
          />
        ) : (
          courseDetails && typeof courseDetails.course_image_path === "object" ?
            (<img
              src={URL.createObjectURL(courseDetails.course_image_path)}
              style={{
                backgroundColor: "#F8F8F8",
                borderRadius: "10px",
                height: "250px",
              }}
            />) : courseDetails && courseDetails.course_image_path && typeof courseDetails.course_image_path === "string" ?
              (<img
                src={courseDetails.course_image_path}
                style={{
                  backgroundColor: "#F8F8F8",
                  borderRadius: "10px",
                  height: "250px",
                }}
              />) :
              (<div

                style={{
                  backgroundColor: "#F8F8F8",
                  borderRadius: "10px",
                  height: "250px",
                }}
              />)
        )}
      </Grid>
      <Grid item xs={6}>
        <FormHelperText id="course_video_field">
          Course Preview Video (Optional)
        </FormHelperText>
        <TextField
          size="small"
          id="course_video_field"
          disabled
          // placeholder="No file selected"
          variant="outlined"
          className="course_video_field"
          sx={classes.courseVideoField}
          multiline
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <label htmlFor="upload_course_video">
                  <input
                    accept=".mp4, .mov"
                    id="upload_course_video"
                    //   multiple
                    hidden
                    type="file"
                    sx={classes.input}
                    onChange={(e) =>
                      handleImageUpload(e, "course_prevvideo_path")
                    }
                  />
                  <Button component="span">{isEditFlow ? "Change file" : "Upload file"}</Button>
                </label>
              </InputAdornment>
            ),
          }}
          value={
            courseDetails.course_prevvideo_path && courseDetails.course_prevvideo_path !== "Null"
              ? (courseDetails.course_prevvideo_path.name ?
                courseDetails.course_prevvideo_path.name :
                courseDetails.course_prevvideo_path.split("?")[0].split("/")
                [courseDetails.course_prevvideo_path.split("?")[0].split("/").length - 1])
              : "No file selected"


          }
        //   onChange={(e) => selectFilter("search", e.target.value)}
        />
        <p style={classes.detailsTitle}>
          Supported formats -
          <span style={classes.detailsDescription}> .mp4, .mov</span>
        </p>
        <p style={classes.detailsTitle}>
          Dimensions -
          <span style={classes.detailsDescription}> 1920 x 1080 pixels</span>
        </p>
        <p style={classes.detailsTitle}>
          Size -
          <span style={classes.detailsDescription}> less than 500 mb</span>
        </p>
        {/* <p style={classes.detailsTitle}>
          Duration -
          <span style={classes.detailsDescription}> less than 10 min</span>
        </p> */}
      </Grid>

      <Grid item xs={6} sx={classes.previewDivGrid}>
        {courseDetails && courseDetails.course_prevvideo_path && !isEditFlow ? (
          <video
            src={URL.createObjectURL(courseDetails.course_prevvideo_path)}
            style={{
              backgroundColor: "#F8F8F8",
              borderRadius: "10px",
              height: "250px",
            }}
          />
        ) : (
          courseDetails && courseDetails.course_prevvideo_path && typeof courseDetails.course_prevvideo_path === "object" ?
            (<video
              src={URL.createObjectURL(courseDetails.course_prevvideo_path)}
              style={{
                backgroundColor: "#F8F8F8",
                borderRadius: "10px",
                height: "250px",
              }}
            />) : courseDetails && courseDetails.course_prevvideo_path && typeof courseDetails.course_prevvideo_path === "string" ?
              (<video
                src={courseDetails.course_prevvideo_path}
                style={{
                  backgroundColor: "#F8F8F8",
                  borderRadius: "10px",
                  height: "250px",
                }}
              />) :
              (<div

                style={{
                  backgroundColor: "#F8F8F8",
                  borderRadius: "10px",
                  height: "250px",
                }}
              />)
        )}
      </Grid>
      <Grid item xs={6} sx={classes.previewDivGrid}>
        {/* <div style={classes.previewDiv}>
            <img src={image} />
          </div> */}
      </Grid>
      {/* </Grid> */}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.CoursesAdminReducer.isLoading,
  courseDetails: state.CoursesAdminReducer.courseDetails,
  filters: state.CoursesAdminReducer.filters,
  isUploading: state.CoursesAdminReducer.isUploading,
  isEditFlow: state.CoursesAdminReducer.isEditFlow,
  activeCourse: state.CoursesAdminReducer.activeCourse
});

const mapDispatchToProps = (dispatch) => ({
  changeCourseDetails: (
    type,
    value,
    isData,
    isSpeaker,
    speakerActionType,
    speakerNumber
  ) =>
    dispatch(
      changeCourseDetails(
        type,
        value,
        isData,
        isSpeaker,
        speakerActionType,
        speakerNumber
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetails);
