import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useHistory, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import CoursesView from "./CoursesView";
import { connect } from "react-redux"
import { makeStyles } from "@mui/styles";
import {
    Grid, IconButton, TextField, InputAdornment, Chip, Drawer, MenuItem, Select, OutlinedInput,
    Stack, Button, Paper, Divider, Modal
} from "@mui/material"
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ScrollToTopButton from "../VetLearningContent/ScrollToTopButton"
import { getVetCourses } from "../Redux/Middlewares/VetCourses/getCoursesVetMiddleware"
import SpinnerLG from "../SpinnerLG";
import { Autocomplete } from "@mui/material"
import { getFiltersVetCourses } from "../Redux/Middlewares/VetCourses/getFiltersVetCoursesMiddleware";
import { SET_USER_COURSE_FILTER_DATA, CLEAR_USER_COURSE_FILTER_DATA } from "../Redux/ActionTypes/coursesVetActionTypes"
import { getActiveVetCourses } from "../Redux/Middlewares/VetCourses/getActiveCourseMiddleware"
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import LinearProgress from '@mui/material/LinearProgress';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LessonActive from "./LessonActive"
import ArticleIcon from '@mui/icons-material/Article';
import QuizIcon from '@mui/icons-material/Quiz';
import FileIcon from "../Assets/images/FileIcon.svg"
import QuizIconNew from "../Assets/images/QuizIconNew.svg"
import moment from "moment";
import CancelIcon from '@mui/icons-material/Cancel';
import CertificatesImg from "./certificateTemporaty.jpg"
import DownloadIcon from "../Assets/images/Download.svg"
import ShareIcon from "../Assets/images/Share.svg"
import Cert from "./cert.png"
import * as htmlToImage from 'html-to-image';
import { Text, View, StyleSheet, PDFViewer, PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import PDFCertificate from "./PDFCertificate";
import { Document, Page, pdfjs } from 'react-pdf';
import CircularProgress from '@mui/material/CircularProgress';


const Certificates = ({ quizLoader, contents, getCourseCertificate, certificate, clearCetificate, country }) => {

    // if (quizLoader) {
    //     return (<SpinnerLG />)
    // }
    const { id } = useParams()
    useEffect(() => {
        if (contents && contents[0] && contents[0].course_completion === 100) {
            getCourseCertificate(id)
        }

        return (() => {
            clearCetificate()
        })
    }, [])

    const [certLoading, setLoadingCert] = React.useState(false)

    useEffect(() => {
        if (certificate) {
            // alert("abc")
            exportAsPicture()
        }
    }, [certificate])

    const options = {
        replace: (domNode) => {
            if (domNode.attribs && domNode.attribs.class === "remove") {
                return <></>;
            }
        }
    };



    const [time, setTime] = useState(null)

    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const saveAs = (blob, fileName) => {
        var elem = window.document.createElement('a');
        elem.href = blob
        elem.download = fileName;
        elem.style = 'display:none;';
        (document.body || document.documentElement).appendChild(elem);
        if (typeof elem.click === 'function') {
            // elem.click();
            setTime(elem.href)
        } else {
            elem.target = '_blank';
            elem.dispatchEvent(new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
            }));
        }
        URL.revokeObjectURL(elem.href);
        elem.remove()
    }

    const exportAsPicture = () => {

        var t0 = performance.now()

        var data = document.getElementsByClassName('snapshotCert')

        for (var i = 0; i < data.length; ++i) {
            htmlToImage.toPng(data[i]).then((dataUrl) => {
                saveAs(dataUrl, 'my-node.png');
            });
        }
    }


    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#E4E4E4'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        }
    });




    return (<><Grid container xs={12} sx={{ marginTop: 4 }}>
        {contents && contents[0] && contents[0].course_completion === 100 ?
            <Grid container xs={12} justifyContent="center" sx={{ padding: 2 }}>

                <Grid item sx={12}>
                    <Typography sx={{ color: "black", fontWeight: 600, fontSize: 22, marginTop: 1 }}>
                        Congratulations! &nbsp;
                    </Typography>
                </Grid>
                <Grid item sx={12}>
                    <Typography sx={{ color: "#0f01a0", fontWeight: 600, fontSize: 20, marginTop: 1 }}>
                        You have completed the course
                    </Typography>
                </Grid>
                <Grid container xs={12} justifyContent="right" sx={{ marginTop: 2 }} >
                    <Grid item xs={2} >
                        {/* <img src={ShareIcon} style={{
                            height: 25,
                            width: "100%",
                            borderRadius: 10
                        }} alt="quiz" /> */}
                    </Grid>
                    <Grid item xs={2} >
                        {/* <a href={CertificatesImg} download="CourseCetificate">
                            <img src={DownloadIcon} style={{
                                height: 25,
                                width: "100%",
                                borderRadius: 10
                            }} alt="quiz" /></a> */}

                        {certificate && <PDFDownloadLink document={<PDFCertificate certificate={certificate} country={country} />} fileName="certificate.pdf">
                            <img src={DownloadIcon} style={{
                                height: 25,
                                width: "100%",
                                borderRadius: 10
                            }} alt="quiz" />
                        </PDFDownloadLink>}

                    </Grid>

                </Grid>

                {/* <button onClick={exportAsPicture}>capture</button> */}

                <Grid item sx={{ marginTop: 1, }} >
                    {/* <div className="snapshotCert" style={{
                        position: "relative",
                    }}>

                        <img
                            src={Cert}
                            style={{
                                height: "100%",
                                width: "100%",

                                display: "flex",
                                justifyContent: "flex-end",
                                backgroundRepeat: "space",
                                backgroundSize: "cover"
                            }}
                        />

                        <Typography sx={{
                            position: "absolute",
                            top: "45%",
                            left: "45%",
                            fontSize: 24

                        }}>{certificate && certificate.user_name}</Typography>

                        <Typography sx={{
                            position: "absolute",
                            top: "55%",
                            left: "40%",
                            fontSize: 16

                        }}>{certificate && certificate.course_name}</Typography>

                        <Typography sx={{
                            position: "absolute",
                            top: "55%",
                            left: "58%",
                            fontSize: 16

                        }}>{"Mars INC"}</Typography>

                        <Typography sx={{
                            position: "absolute",
                            top: "55%",
                            left: "78%",
                            fontSize: 16

                        }}>{certificate && certificate.complete_date && certificate.complete_date.split(" ")[0].toString().replaceAll("-", "/")}</Typography>
                    </div> */}

                </Grid>
                <Grid item xs={12} sx={{ marginTop: 1 }} >
                    {/* <img
                        src={time}
                        style={{
                            height: "100%",
                            width: "100%",

                            display: "flex",
                            justifyContent: "flex-end",
                            backgroundRepeat: "space",
                            backgroundSize: "cover"
                        }}
                    /> */}
                    {/* <PDFViewer>
                        <MyDocument />
                    </PDFViewer> */}
                </Grid>
                
                <Grid container justifyContent="center" xs={12} sx={{ marginTop: 1 }} >
                    {/* {certificate && <PDFViewer height={400} width={320}>
                        <PDFCertificate certificate={certificate} />
                    </PDFViewer>} */}
                  
                    {certificate && <BlobProvider document={<PDFCertificate certificate={certificate} country={country} />}>
                        {({ blob, url, loading, error }) => {
                            console.log("loading", certLoading)
                            // Do whatever you need with blob here
                            if (loading) {
                                setLoadingCert(true)
                                return <CircularProgress size={100} disableShrink={true} /> ;
                                
                            }
                            if (!loading) {
                                setLoadingCert(false)
                            }


                            return <Document
                                // contents.article_info.sas_file_path
                                file={blob}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                {numPages && ([...Array(numPages).keys()].map(item => (
                                    <Page width={320}
                                        key={item} renderTextLayer={false} pageNumber={item + 1} />)))}


                            </Document>
                        }}
                        
                    </BlobProvider>}
                    
                </Grid>


            </Grid>
            :
            <Grid container xs={12} justifyContent="center" sx={{ padding: 2 }}>
                <Typography sx={{ color: "#0f01a0", fontWeight: 600, fontSize: 18, marginTop: 1 }}>
                    Please score 80% or above in the quizzes and complete all the lessons to obtain the certificate for this course
                </Typography>
            </Grid>

        }

        {/* {parse(sampleHTML, options)} */}
    </Grid>
    </>)
}

export default Certificates