import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useHistory, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import CoursesView from "./CoursesView";
import { connect } from "react-redux"
import { makeStyles } from "@mui/styles";
import {
    Grid, IconButton, TextField, InputAdornment, Chip, Drawer, MenuItem, Select, OutlinedInput,
    Stack, Button, Paper, Divider
} from "@mui/material"
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ScrollToTopButton from "../VetLearningContent/ScrollToTopButton"
import { getVetCourses } from ".././Redux/Middlewares/VetCourses/getCoursesVetMiddleware"
import SpinnerLG from "../SpinnerLG";
import { Autocomplete } from "@mui/material"
import { getFiltersVetCourses } from "../Redux/Middlewares/VetCourses/getFiltersVetCoursesMiddleware";
import { SET_USER_COURSE_FILTER_DATA, CLEAR_USER_COURSE_FILTER_DATA } from "../Redux/ActionTypes/coursesVetActionTypes"
import { getActiveVetCourses } from "../Redux/Middlewares/VetCourses/getActiveCourseMiddleware"
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import LinearProgress from '@mui/material/LinearProgress';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ReactPlayer from 'react-player/lazy'
import CloseIcon from '@mui/icons-material/Close';
import ReadingMaterialView from "./ReadingMaterialView";
import QuizActiveScreen from "./QuizActiveScreen";
import FileIcon from "../Assets/images/FileIcon.svg"
import QuizIconNew from "../Assets/images/QuizIconNew.svg"
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';

const LessonActive = ({ toggleDrawer, setCourseSequence, courseSequence,
    currentSelection, contents, open, handleClick, nextSelection, id, lessonCompletion,
    completionPercentage, toggleQuizActivation, quizActive, getQuizDetails, quizLoader, quizData, clearQuizData,
    quizValidation, quizGrade, setRoot, quizWrongAnswers, quizInput, setTab }) => {


    //function to handle the selection highlight on chapters and lessons list
    const handleSelection = (nodeId) => {
        let tempObj = {}
        for (let key of Object.keys(courseSequence)) {

            if (key == nodeId) {
                tempObj[[key]] = true
            }
            else {
                tempObj[[key]] = false
            }

        }

        let chapterId = null

        let newSelection = nodeId.substring(0, nodeId.length - 1);
        let lessonType
        for (let i = 0; i < contents.length; i++) {

            if (i === 0 || (contents[i - 1].chapter_completion)) {

                for (let j = 0; j < contents[i].lessons.length; j++) {
                    if (contents[i].lessons[j].course_level2_id.toString() + "x" == nodeId) {
                        chapterId = contents[i].course_level1_id
                        lessonType = contents[i].lessons[j].file_type
                        break
                    }
                }
            }

        }

        if (lessonType !== "Quiz") {
            lessonCompletion(id, chapterId, newSelection)
        }


        setCourseSequence(tempObj, nodeId)
    }

    const selectionStyle = {
        borderRight: 4, backgroundColor: "#f3f3fb",
        borderColor: "#0f01a0",
        padding: 1,
        borderRadius: "5px 0 0 5px"
    }

    let contentType = null
    let contentLink = null
    let currentLessonName = null
    let currentLessonId = null
    let currentChapter = null
    let quizLesson = null
    let lessonTypeGeneric = null
    let quizQuestions = 0


    for (let i = 0; i < contents.length; i++) {
        for (let j = 0; j < contents[i].lessons.length; j++) {
            if (contents[i].lessons[j].course_level2_id.toString() + "x" === currentSelection) {
                contentType = contents[i].lessons[j].file_type
                contentLink = contents[i].lessons[j].sas_file_path
                currentLessonName = contents[i].lessons[j].content_name
                currentChapter = contents[i].course_level1_id
                currentLessonId = contents[i].lessons[j].course_level2_id
                quizLesson = contents[i].lessons[j]
                lessonTypeGeneric = contents[i].lessons[j].file_type
                quizQuestions = contents[i].lessons[j].quiz_count
            }
        }

    }




    return (<Box sx={{ height: "400vh" }}>
        <Grid container sx={{ padding: 1.5 }}>
            <Grid container justifyContent="space-between" xs={12}>

                <CloseIcon size="small" onClick={() => toggleDrawer(false)} />
                {/* {(lessonTypeGeneric !== "Quiz" || quizActive === 0) && <Button onClick={() => nextSelection(currentSelection)}>Next</Button>} */}
            </Grid>
            {lessonTypeGeneric !== "Quiz" && <Grid container justifyContent="center" sx={12}>
                <Typography sx={{ color: "#0f01a0", fontWeight: 800, fontSize: 18, marginTop: 0 }}>{currentLessonName}</Typography>
            </Grid>}

            {contentType === "Video" || contentType === "Audio" ? (
                <Grid container md={12} spacing={2} direction="row-reverse" sx={{ marginTop: 4, marginBottom: 4 }}>
                    <Grid item xs={12} sm={8} md={8} sx={{ border: 0, marginBottom: 1 }}>
                        <ReactPlayer config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                            onContextMenu={e => e.preventDefault()}
                            width="100%" height="100%" controls={true} url={contentLink} />
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                        <Box sx={{ height: "55vh", overflowY: "auto", display: "flex", width: "100%" }}>
                            <Grid item xs={12} sm={12}>
                                <List
                                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"


                                >
                                    {
                                        contents.map((item, index) => {
                                            if (item.lessons && index === 0 || (item.lessons && contents[index - 1].chapter_completion)) {
                                                return (
                                                    < >
                                                        <ListItemButton disableGutters onClick={() => handleClick(index)}>
                                                            <Grid container xs={12} sm={12}>
                                                                <Grid item xs={1}>
                                                                    <PlayCircleFilledWhiteIcon sx={{ color: "#6ad7b9" }} />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                                                                        {`Chapter ${item.sequence_id}`}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <Typography sx={{ fontSize: 14 }}>
                                                                        { }
                                                                    </Typography>

                                                                </Grid>
                                                                <Grid item xs={1}>

                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    <Typography sx={{ fontSize: 12 }}>
                                                                        {completionPercentage[item.course_level1_id][0]} out of {completionPercentage[item.course_level1_id][1]} items completed
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={1}>

                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    <LinearProgress color="secondary" variant="determinate"
                                                                        value={(completionPercentage[item.course_level1_id][0] / completionPercentage[item.course_level1_id][1]) * 100} />

                                                                </Grid>

                                                            </Grid>
                                                            {open[index] ? <ExpandLess /> : <ExpandMore />}
                                                        </ListItemButton>
                                                        <Collapse in={open[index]} timeout="auto" unmountOnExit>

                                                            <List component="div" disablePadding>
                                                                {
                                                                    item.lessons.map(
                                                                        subItem => {
                                                                            return (
                                                                                <ListItemButton disableGutters onClick={() => {
                                                                                    handleSelection(subItem.course_level2_id.toString() + "x");

                                                                                }} sx={{ padding: 1 }}>
                                                                                    <Grid container xs={12}
                                                                                        sx={courseSequence && courseSequence[subItem.course_level2_id.toString() + "x"] ? selectionStyle : { padding: 1, boxShadow: 1 }} >
                                                                                        <Grid container justifyContent="center" alignItems="center" xs={4} sx={{ padding: .5, backgroundColor: "#f2f2f2", borderRadius: 2, }}>
                                                                                            {subItem.file_type === "Video" ? (<div


                                                                                            >
                                                                                                <OndemandVideoIcon sx={{ color: "#8080cf", height: 40, width: "100%" }} />
                                                                                            </div>) : subItem.file_type === "Audio" ?


                                                                                                <AudiotrackIcon sx={{ color: "#8080cf", height: 40, width: "100%" }} /> : subItem.file_type === "Reading Material" ?
                                                                                                    // (<ArticleIcon sx={{
                                                                                                    //     height: 55,
                                                                                                    //     width: "100%",
                                                                                                    //     borderRadius: 10, color: "#8080cf"
                                                                                                    // }} />)
                                                                                                    (<img src={FileIcon} style={{
                                                                                                        height: 40,
                                                                                                        width: "100%",
                                                                                                        borderRadius: 10
                                                                                                    }} alt="quiz" />)
                                                                                                    :
                                                                                                    // (<QuizIcon sx={{
                                                                                                    //     height: 40,
                                                                                                    //     width: "100%",
                                                                                                    //     borderRadius: 10, color: "#8080cf"
                                                                                                    // }} />)
                                                                                                    (<img src={QuizIconNew} style={{
                                                                                                        height: 40,
                                                                                                        width: "100%",
                                                                                                        borderRadius: 10
                                                                                                    }} alt="quiz" />)
                                                                                            }
                                                                                        </Grid>
                                                                                        <Grid container xs={8} sx={{ overflow: "hidden", paddingLeft: 1, }}>
                                                                                            <Grid container xs={12}>
                                                                                                <Typography noWrap sx={{ fontSize: 16, fontWeight: 600, color: "#0f01a0", }}>
                                                                                                    {subItem.file_type === "Video" ? subItem.content_name :
                                                                                                        subItem.file_type === "Reading Material" ? "Reading Material" :
                                                                                                            subItem.file_type === "Audio" ? "Audio" : "Quiz"}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid container xs={10}>
                                                                                                <Typography sx={{ fontSize: 14, fontWeight: 600, }}>
                                                                                                    {subItem.file_type === "Video" || subItem.file_type === "Audio" ? `${(Number(subItem.duration.split(" ")[0]) / 3600).toPrecision(1)} hours` :
                                                                                                        subItem.file_type === "Quiz" ? subItem.quiz_count + (subItem.quiz_count == 1 ? " question" : "  questions") : ""}
                                                                                                </Typography>
                                                                                            </Grid>



                                                                                            {subItem.completion_status ? <Grid container xs={2}>
                                                                                                <CheckCircleRoundedIcon color="secondary" />
                                                                                            </Grid> : <Grid container xs={2}></Grid>}

                                                                                            {/* <Grid item xs="auto" onClick={() => toggleDrawer(true)}>
                                                                                    <Typography noWrap sx={{
                                                                                        border: 1.5, borderColor: "#0f01a0",
                                                                                        borderRadius: 1, fontWeight: 600, paddingLeft: 1, paddingRight: 1, paddingTop: .2,
                                                                                        paddingBottom: .1, fontSize: 14
                                                                                    }}></Typography>
                                                                                </Grid> */}

                                                                                        </Grid>

                                                                                    </Grid>
                                                                                </ListItemButton>
                                                                            )
                                                                        }
                                                                    )
                                                                }
                                                            </List>

                                                        </Collapse>
                                                    </>
                                                )
                                            }

                                            else if (item.lessons && item.chapter_completion === false) {
                                                return (<>
                                                    <ListItemButton disableGutters >
                                                        <Grid container xs={12}>
                                                            <Grid item xs={1}>
                                                                <LockIcon sx={{ color: "#c4c4c4" }} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography sx={{ fontWeight: 600, fontSize: 14, color: "#acacac" }}>
                                                                    {`Chapter ${item.sequence_id}`}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <Typography sx={{ fontSize: 14, color: "#acacac" }}>
                                                                    {item.content_name} : Please complete previous chapters to unlock
                                                                </Typography>

                                                            </Grid>
                                                        </Grid>
                                                    </ListItemButton>
                                                    <Divider sx={{ marginBottom: 2 }} />
                                                </>
                                                )

                                            }

                                        })
                                    }





                                </List>
                            </Grid >
                        </Box>
                    </Grid>
                </Grid>) : contentType === "Reading Material" ? (<ReadingMaterialView
                    toggleDrawer={toggleDrawer} setCourseSequence={setCourseSequence} courseSequence={courseSequence}
                    currentSelection={currentSelection} contents={contents} open={open} handleClick={handleClick}
                    nextSelection={nextSelection} completionPercentage={completionPercentage}
                    id={id} lessonCompletion={lessonCompletion} setRoot={setRoot} />) :

                contentType === "Quiz" ? (<QuizActiveScreen toggleQuizActivation={toggleQuizActivation}
                    quizActive={quizActive} getQuizDetails={getQuizDetails} currentSelection={currentSelection}
                    quizLoader={quizLoader} quizData={quizData} currentLessonId={currentLessonId} id={id}
                    currentChapter={currentChapter} nextSelection={nextSelection}
                    clearQuizData={clearQuizData} quizValidation={quizValidation} currentLessonName={currentLessonName}
                    quizLesson={quizLesson} quizQuestions={quizQuestions} quizGrade={quizGrade} contents={contents}
                    setRoot={setRoot} quizWrongAnswers={quizWrongAnswers} quizInput={quizInput} setTab={setTab}
                    toggleDrawer={toggleDrawer}
                />) : (<> Invalid content type </>)}

        </Grid>
    </Box>)
}

export default LessonActive