import axios from "axios";
import {
  changeCourseStatusFailure,
  changeCourseStatusRequest,
  changeCourseStatusSuccess,
  toggleAlert,
} from "../../ActionCreators/coursesAdminActionCreators";
import { NAVIGATE_ADMIN_ON_COURSE_SAVE } from "../../ActionTypes/coursesAdminActionTypes";

const CHANGE_COURSE_STATUS_URL =
  process.env.REACT_APP_API_URL + "/courses/admin/publish_status/";

export const changeCourseStatus = (courseDetails, redirect = null) => (dispatch) => {
  dispatch(changeCourseStatusRequest());
  axios
    .post(CHANGE_COURSE_STATUS_URL, courseDetails)
    .then((response) => {
      if (response.status === 201) {
        dispatch(changeCourseStatusSuccess());
        dispatch(
          toggleAlert("success", "Course status successfully changed !", true)
        );
        if (redirect) {
          dispatch({ type: NAVIGATE_ADMIN_ON_COURSE_SAVE, payload: 1 })   // 1 is navigational signal for NewCourse component to 
        }                                                                 // redirect to the admin courses page
      } else {
        dispatch(changeCourseStatusFailure("Something went wrong !"));
        dispatch(toggleAlert("error", "Something went wrong !", true));
      }
    })
    .catch((error) => {
      dispatch(changeCourseStatusFailure(error.message));
      dispatch(toggleAlert("error", error.message, true));
    });
};
