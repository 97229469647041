import axios from "axios";
import {
    createCourseFailure,
    createCourseRequest,
    createCourseSuccess,
    createEditChapterFailure,
    createEditChapterRequest,
    createEditChapterSuccess,
    createEditLessonFailure,
    createEditLessonRequest,
    createEditLessonSuccess,
    setActiveCourse,
    setCurriculumDetails,
    toggleAlert,
} from "../../ActionCreators/coursesAdminActionCreators";



export const deleteChapter = (course_level1_id) => (dispatch) => {
    const DELETE_CHAPTER_URL =
        process.env.REACT_APP_API_URL + `/curriculum/admin/delete_chapter/${course_level1_id}/`;

    // dispatch(createEditLessonRequest());
    axios
        .delete(DELETE_CHAPTER_URL)
        .then((response) => {
            if (response.status === 200) {
                dispatch(createEditLessonSuccess());

                dispatch(toggleAlert("success", "chapter deleted successfully !", true));


                dispatch(setCurriculumDetails(response.data));
            } else {
                dispatch(createEditLessonFailure("Something went wrong !"));
                dispatch(toggleAlert("error", "Something went wrong !", true));
            }
        })
        .catch((error) => {
            dispatch(createEditLessonFailure(error.message));
            dispatch(toggleAlert("error", error.message, true));
        });
};