import react, { useEffect } from "react";
import "./style.css";
import Login from "../Login/Login";
import SignUp from "../SignUp/SignUp";
import SignupForm from "../SignUp/SignupForm";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { setVetCurrentWebinar } from "../Redux/ActionCreators/webinarsVetActionCreators";
import {
	Box,
	Paper,
	Typography,
	Avatar,
	Modal,
	Grid,
	TextField,
	Button,
	CssBaseline,
	Container,
} from "@mui/material";
import {
	LoginOpenRequest,
	LoginCloseRequest,
	signupOpenRequest,
	signUpCloseRequest,
	profileFormOpenRequest,
	profileFormcloseRequest,
} from "../Redux/ActionCreators/NavbarActionCreators";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { styled, alpha } from "@mui/material/styles";
import ProgressionLevel from "./progressionLevel";
import { Redirect } from "react-router";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import landingAll from "./landingLogin.PNG";
import landingLogin from "./landingAll.PNG";
import desktopLanding from "./desktopLanding.png";
import desktopLogin from "./desktopLogin.png";
import adminLanding from "./adminLanding.png";
import WebinarBoard from "../Webinar/Utils/WebinarBoard";
import ArticleBoard from "../LearningContent/Components/ArticleCard/ArticleBoard";
import CourseBoard from "../VetCourses/CourseBoard";
import {
	fetchHomepageVetLogin,
	fetchHomepageVetLogout,
} from "../Redux/Middlewares/homepageVetMiddleware";

import AnanlyticsDashboard from "./components/AnalyticsDashboard";
import PhLogo from "../Header/components/Navbar/logo.png"  
import InLogo from "../Header/components/Navbar/logoIn.png";
const style = {
	// position: 'absolute',
	// top: '50%',
	// left: '50%',
	// transform: 'translate(-50%, -50%)',
	// width: 400,
	// bgcolor: 'background.paper',
	// border: '2px solid #000',
	// boxShadow: 24,
	// p: 4,
};

const StyledMain = styled("div")(({ theme }) => ({
	width: "100",
	marginLeft: theme.spacing(1),
	marginRight: theme.spacing(1),
	[theme.breakpoints.up("md")]: {
		width: "95%",
		marginLeft: "auto",
		marginRight: "auto",
	},
}));

const PaperWrapper = styled("div")(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(3),

	[theme.breakpoints.down("xs")]: {
		width: "100",
		marginTop: 60,
	},
	[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(6),
		padding: theme.spacing(3),
	},
}));

const StyledMainAwatingApprovalPopup = styled("div")(({ theme }) => ({
	marginTop: "1%",
	width: "auto",
	marginLeft: theme.spacing(2),
	marginRight: theme.spacing(2),
	[theme.breakpoints.up("md")]: {
		width: 600,
		marginLeft: "auto",
		marginRight: "auto",
	},
}));

const PaperWrapperAwatingApprovalPopup = styled("div")(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(3),

	[theme.breakpoints.down("xs")]: {
		width: "100%",
		marginTop: 60,
	},
	[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(6),
		padding: theme.spacing(3),
	},
}));

const LandingPage = ({
	isAuthenticated,
	signUpModal,
	loginModal,
	createProfileModal,
	openLogin,
	openSignup,
	closeLogin,
	closeSignup,
	closeProfile,
	userStatus,
	userType,
	fetchHomepageVetLogout,
	fetchHomepageVetLogin,
	logout,
	login,
	setVetCurrentWebinar
}) => {
	const { webinar_details, learning_content_details, popular_courses } =
		isAuthenticated ? login : logout;

	const { course_details } = login;

	useEffect(() => {

		if (isAuthenticated === true) fetchHomepageVetLogin();
		else if (isAuthenticated === false) fetchHomepageVetLogout();
	}, [isAuthenticated]);
	const history = useHistory()
	if (
		isAuthenticated &&
		(userStatus === "Incomplete_Profile" ||
			userStatus === "incompleteProfile")
	) {
		return <Redirect to="/createProfile" />;
	}
	//view when user is unregistered and awaiting admin approval
	if (isAuthenticated && userStatus === "Unregistered") {
		return (
			<>
				<CssBaseline />
				<StyledMainAwatingApprovalPopup>
					<PaperWrapper>
						<Paper
							sx={{
								padding: 2,
								paddingLeft: { xs: 1, sm: 6 },
								paddingRight: { xs: 1, sm: 6 },
							}}
						>
							<Grid container spacing={2}>
								<Grid
									item
									style={{ textAlign: "center" }}
									xs={12}
									sm={12}
								>
									<CheckCircleOutlineIcon
										style={{ fontSize: "120px" }}
										color="secondary"
									/>
								</Grid>
								<Grid
									item
									style={{ textAlign: "center" }}
									xs={12}
									sm={12}
								>
									<Typography variant="h6">
										Registration completed successfully
									</Typography>
								</Grid>
								<Grid
									item
									style={{ textAlign: "center" }}
									xs={12}
									sm={12}
								>
									<Typography variant="h5">
										An Email approval will be sent to you in
										a few days
									</Typography>
								</Grid>
							</Grid>
						</Paper>
					</PaperWrapper>
				</StyledMainAwatingApprovalPopup>
			</>
		);
	}
	//view when user is logged in and registered
	if (isAuthenticated && userStatus === "registered") {
		return (
			<>
				<CssBaseline />
				{userType === "Vet" && (
					<>
						<Container fixed>
							<Grid container>
								<Grid item xs={12}>
									{/* {login && login.user_name && <div className="section-title-wrap">
										<h3 className="section-title greet-message">
											Welcome {login.user_name.split(" ")[0].substring(0, 20)} !
										</h3>
									</div>} */}
									{login && login.popular_courses && login.popular_courses.results?.length > 0 && <Carousel
										showThumbs={false}
										autoPlay={true}
										className="landing-carousel"
										infiniteLoop={true}
										autoFocus={true}
										interval={4000}
									>

										{
											login && login.popular_courses && login.popular_courses.results ?
												login.popular_courses.results.map((item, index) => (
													<div key={index} className="carousel-item">
														<img src={item.sas_image_path} />
														<div className="carousel-caption">
															 <p style={{ textShadow: "1px 1px 1px black" }}>
																{item.course_title} <br />
															</p>
															<a style={{ cursor: "pointer" }} onClick={() => history.push(`/course/${item.course_id}`)}>Start learning</a>
														</div>
													</div>
												)) :
												<div className="carousel-item" >
													<img src="./assets/images/slide-1.png" />
													<div className="carousel-caption">
														<p>
															Start a new learning journey,{" "}
															<br />

														</p>
														<a style={{ cursor: "pointer" }} onClick={() => history.push('/courses')} >Start learning</a>
													</div>
												</div>

										}

										{/* <div className="carousel-item">
											<img src="./assets/images/slide-1.png" />
											<div className="carousel-caption">
												<p>
													Lorem ipsum dolor sit amet,{" "}
													<br />
													consectetur adipiscing elit.
												</p>
												<a >Start learning</a>
											</div>
										</div> */}

									</Carousel>}
									{/* <div className="title-link">
                                <h3>Continue your courses</h3>
                                <a href="#">View all courses</a>
                            </div> */}
									<Container maxWidth="lg" disableGutters>
										<div className="section-title-wrap">
											<h2 className="section-title">
												Continue your courses
											</h2>
										</div>
										<div className="scrolling-wrapper three-column">
											{course_details && course_details.results && course_details.results.length >
												0 ? (
												course_details.results.map(
													(courseData, index) => (
														<div className="s-card">
															<CourseBoard
																key={index}
																courseData={
																	courseData
																}
																isProgress={
																	true
																}
															/>
														</div>
													)
												)
											) : (
												<p style={{marginLeft: 40}}>Please enroll to courses!</p>
											)}
										</div>
									</Container>
									<Container maxWidth="lg" disableGutters>
										<div className="section-title-wrap">
											<h2 className="section-title">
												Latest in Learning Content
											</h2>
											<Typography onClick={() => history.push("/learningContent")} color="primary" sx={{ fontSize: 14, paddingLeft: 6 }} style={{ cursor: "pointer" }}>
												View all learning content
											</Typography>
										</div>
										<div className="scrolling-wrapper three-column">
											{learning_content_details && learning_content_details.results && learning_content_details.results
												.length > 0 ? (
												learning_content_details.results.map(
													(article, index) => (
														<div className="s-card">
															<ArticleBoard
																key={index}
																article={
																	article
																}
															/>
														</div>
													)
												)
											) : (
												<p style={{marginLeft: 40}}>No Articles found !</p>
											)}
										</div>
									</Container>
									<Container maxWidth="lg" disableGutters>
										<div className="section-title-wrap">
											<h2 className="section-title">
												Popular Courses
											</h2>

											<Typography onClick={() => history.push("/courses")} color="primary" sx={{ fontSize: 14, paddingLeft: 6 }} style={{ cursor: "pointer" }}>
												View all courses
											</Typography>
										</div>
										<div className="scrolling-wrapper three-column">
											{popular_courses && popular_courses.results && popular_courses.results.length >
												0 ? (
												popular_courses.results.map(
													(courseData, index) => (
														<div className="s-card">
															<CourseBoard
																key={index}
																courseData={
																	courseData
																}
															/>
														</div>
													)
												)
											) : (
												<p style={{marginLeft: 40}}>No Data Found!</p>
											)}
										</div>
									</Container>
								</Grid>
								<Grid item md={12} xs={12}>
									<Container maxWidth="lg" disableGutters>
										<div className="home-sidebar">
											<div className="section-title-wrap">
												<h3 className="section-title">
													Upcoming webinars
												</h3>
											</div>
											<div className="upcoming-webinars scrolling-wrapper">
												{webinar_details && webinar_details.results && webinar_details.results.length >
													0 ? (
													webinar_details.results.map(
														(webinar, index) => (

															<WebinarBoard
																setVetCurrentWebinar={setVetCurrentWebinar}
																key={index}
																isAuthenticated={isAuthenticated}
																webinar={
																	webinar
																}
															/>
														)
													)
												) : (
													<p style={{marginLeft: 30}}>Coming soon!</p>
												)}
											</div>
										</div>
									</Container>
								</Grid>
							</Grid>
						</Container>
					</>
				)}
				{userType === "Admin" && (
					<StyledMain>
						<Grid
							item
							xs={12}
							md={12}
							sx={{
								// display: { xs: "none", md: "block" },
								padding: 6,
							}}
						>
							<Grid item xs={12} md={12}>
								{/* <img
									src={adminLanding}
									style={{
										height: "100%",
										width: "100%",

										display: "flex",
										justifyContent: "flex-end",
										backgroundRepeat: "space",
										backgroundSize: "cover",
									}}
								/> */}
								<AnanlyticsDashboard />
							</Grid>
						</Grid>
					</StyledMain>
				)}
			</>
		);
	}
	let modifileData;
	if(logout?.popular_courses?.results){
		modifileData=[{course_title:"",sas_image_path:window.location.href.includes(".ph")?PhLogo:InLogo},...logout.popular_courses.results]
	}
//view when user is not logged in
	return (
		<>
			<Container fixed>
				{/* <Grid Container sx={{padding: {md:10,xs:0},paddingTop:{md:4,xs:4},paddingBottom:{md:2,xs:2}}}> */}
				<Grid Container>					
					<Grid item xs={12}>
						{logout && logout.popular_courses && logout.popular_courses.results?.length > 0 && 
						<Carousel showThumbs={false} autoPlay={true} className="landing-carousel"
						infiniteLoop={true}
						autoFocus={true}
						interval={4000}
						showArrows={true}
						>
							{
								logout && logout.popular_courses && logout.popular_courses.results ?
									modifileData.map((item, index) => (
										<div key={index} className="carousel-item">
											<img src={item.sas_image_path} />
											<div className="carousel-caption">
												<p>{item.course_title}</p>
												<a style={{ cursor: "pointer" }} onClick={() => history.push("/login")}>Start learning</a>
											</div>
										</div>
									)) : <div className="carousel-item">
										<img src="./assets/images/abc.png" />
										<div className="carousel-caption">
											<p>
												Start a new learning journey{" "}
												<br />

											</p>
											<a style={{ cursor: "pointer" }} onClick={() => history.push('/login')} >Start learning</a>
										</div>
									</div>
							}
							{/* <div className="carousel-item">
								<img src="./assets/images/slide-1.png" />
								<div className="carousel-caption">
									<p>
										Lorem ipsum dolor sit amet, <br />
										consectetur adipiscing elit.
									</p>
									<a onClick={() => history.push("/login")}>Start learning</a>
								</div>
							</div>
							<div className="carousel-item">
								<img src="./assets/images/slide-1.png" />
								<div className="carousel-caption">
									<p>
										Lorem ipsum dolor sit amet, <br />
										consectetur adipiscing elit.
									</p>
									<a onClick={() => history.push("/login")}>Start learning</a>
								</div>
							</div> */}
						</Carousel>}
					</Grid>
				</Grid>
			</Container>

			<Container fixed>
				<div className="section-title-wrap">
					<h2 className="section-title">

						{window.location.href.includes(".ph") ? "MARS Vet Connect" : "MARS Vet Konnect"}</h2>
				</div>
				<div className="scrolling-wrapper three-column">
					{/* <div className="s-card">
						<div className="why-card">
							<div className="number">
								<span>1</span>
							</div>
							<div className="why-content">
								<h4>Product launch details</h4>
								<p>
									You will get to know about MARS newly
									launched exclusively here.
								</p>
							</div>
						</div>
					</div> */}
					<div className="s-card">
						<div className="why-card">
							<div className="number">
								<span>1</span>
							</div>
							<div className="why-content">
								<h4>Curated courses</h4>
								<p>
									Our team of experts provide courses ranging
									on various subjects.
								</p>
							</div>
						</div>
					</div>
					<div className="s-card">
						<div className="why-card">
							<div className="number">
								<span>2</span>
							</div>
							<div className="why-content">
								<h4>Webinars</h4>
								<p>
									Vets from MARS team conduct webinars on
									various topics.
								</p>
							</div>
						</div>
					</div>
					<div className="s-card">
						<div className="why-card">
							<div className="number">
								<span>3</span>
							</div>
							<div className="why-content">
								<h4>Latest research papers</h4>
								<p>
									We publish research papers, articles and
									other reading materials regularly.
								</p>
							</div>
						</div>
					</div>
				</div>
			</Container>
			{!isAuthenticated && (
				<>

					<Container fixed>
						<div className="section-title-wrap">
							<h2 className="section-title">
								Latest in Learning Content
							</h2>
						</div>
						<div className="scrolling-wrapper four-column">
							{learning_content_details && learning_content_details.results && learning_content_details.results.length > 0 ? (
								learning_content_details.results.map(
									(article, index) => (
										<div className="s-card">
											<ArticleBoard
												key={index}
												article={article}
											/>
										</div>
									)
								)
							) : (
								<p style={{marginLeft: 40}}>No Articles found !</p>
							)}
						</div>
					</Container>
					<Container fixed>
						<div className="section-title-wrap">
							<h2 className="section-title">Popular Courses</h2>
						</div>
						<div className="scrolling-wrapper four-column">
							{popular_courses && popular_courses.results && popular_courses.results.length > 0 ? (
								popular_courses.results.map(
									(courseData, index) => (
										<div className="s-card">
											<CourseBoard
												key={index}
												courseData={courseData}
											/>
										</div>
									)
								)
							) : (
								<p style={{marginLeft: 40}}>No Data Found!</p>
							)}
						</div>
					</Container>
					<Container fixed>
						<div className="section-title-wrap">
							<h2 className="section-title">Upcoming Webinars</h2>
						</div>

						<div className="scrolling-wrapper four-column">
							{webinar_details && webinar_details.results && webinar_details.results.length > 0 ? (
								webinar_details.results.map(
									(webinar, index) => (
										<WebinarBoard
											key={index}
											webinar={webinar}
										/>
									)
								)
							) : (
								<p style={{marginLeft: 40}}>Coming soon!</p>
							)}
						</div>
					</Container>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	logout: state.HomepageVetReducer.logout,
	login: state.HomepageVetReducer.login,
	loginModal: state.NavigationReducer.loginModalOpen,
	signUpModal: state.NavigationReducer.signupModalOpen,
	createProfileModal: state.NavigationReducer.profileFormModalOpen,
	userStatus: state.authenticationReducer.userStatus,
	isAuthenticated: state.authenticationReducer.isAuthenticated,
	userType: state.authenticationReducer.userType,
});

const mapDispatchToProps = (dispatch) => {
	return {
		closeLogin: () => dispatch(LoginCloseRequest()),
		closeProfile: () => dispatch(profileFormcloseRequest()),
		closeSignup: () => dispatch(signUpCloseRequest()),
		fetchHomepageVetLogout: () => dispatch(fetchHomepageVetLogout()),
		fetchHomepageVetLogin: () => dispatch(fetchHomepageVetLogin()),
		setVetCurrentWebinar: (obj) => dispatch(setVetCurrentWebinar(obj)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
