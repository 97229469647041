import { loginRequest, loginFailureRequest, loadUserRequest, authErrorRequest  } from "../ActionCreators/authenticationActionCreators"
import { setLoginFailAlert } from "../ActionCreators/alertsActionCreators"
import { LOGIN_INIT } from "../ActionTypes/authenticationActionTypes"
import axios from "axios"

//LoginRefresh
export const loginRefresh = () => async dispatch => {


    try {
       
        let url = process.env.REACT_APP_API_URL+"/login/login_refresh/"
   


        let result = await axios.get(url)

        if (result.data && result.data.registration_status) {

            dispatch(loadUserRequest({  user: result.data.email,userStatus:result.data.registration_status,
            profile:result.data, userType: result.data.user_type}))
        }
        else if(result.data && result.data.user_status){  
            dispatch(loadUserRequest({ email:result.data.email, user:result.data.email,userStatus:result.data.user_status }))
        }
        else {
            // dispatch(setLoginFailAlert())
            dispatch(loginFailureRequest())
        }

        //dispatch(loadUser())

    }
    catch (err) {
       
        dispatch(loginFailureRequest())
    }
}