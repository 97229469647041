import React, { useState, useEffect } from "react";
// import "./styles.css";
import { useHistory, withRouter } from "react-router-dom";
import { Box, Paper, Typography, Grid, TextField, Button, CssBaseline } from '@mui/material';
import { connect } from "react-redux"
import { login } from "../Redux/Middlewares/loginMiddleware";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { styled, alpha } from "@mui/material/styles";
import { LoginCloseRequest } from "../Redux/ActionCreators/NavbarActionCreators"
import SignupFormRes from "./SignupFormRes";
import { useFormik } from "formik"
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import SignupOTPform from "./SignupOTPform"
import SignupCreateProfileForm from "./SignupCreateProfileForm"
import { signupInit } from "../Redux/Middlewares/signUpInitMiddleware";
import { createProfile } from "../Redux/Middlewares/createProfileMiddleware";
import { Redirect } from "react-router-dom";
import {
  DISABLE_CREATE_PROFILE_SCREEN, SIGNUP_OTP_SCREEN_TOGGLE,
  SET_SIGNUP_OTP_AUTH_ID_TEMP_PASS
} from "../Redux/ActionTypes/authenticationActionTypes";
import { signupVerifyOTP } from "../Redux/Middlewares/signupVerifyOTP";


//validation logic for signup fields
const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  confirmedPassword: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});



// const Login = ({ setLoggedInUser }) => {
const SignupPage = ({ login, isAuthenticated, closeLogin, createProfileScreen, signupInit, createProfile, userStatus,
  processLoader, toggleSignupOTPScreen, signupOTPscreen, signupAuthId, setSignupDetails, tempPass, signupVerifyOTP }) => {
  // axios.defaults.headers.common["Authorization"]=`bearer ${"eyJ0eXAiOiJKV1QiLCJraWQiOiJ3VTNpZklJYUxPVUFSZVJCL0ZHNmVNMVAxUU09IiwiYWxnIjoiUlMyNTYifQ.eyJhdF9oYXNoIjoiZHVEdVdBZ3NHZnlfdXl4SDlxRzgxdyIsInN1YiI6IjY1Njc4ZmUxLWQ4MjEtNGJlMi1iZDVmLTMxMzc1YTc5YzIyYSIsImF1ZGl0VHJhY2tpbmdJZCI6IjNlMTE0NDA4LTRkNjEtNGZkMi04OTE3LTFkYTcwZDQ5ZTkwYS0xMjYyMDY3IiwiaXNzIjoiaHR0cDovL2NpYW0tc2IubWFycy5jb206ODAvYXV0aC9vYXV0aDIvcmVhbG1zL3Jvb3QvcmVhbG1zL0NpSCIsInRva2VuTmFtZSI6ImlkX3Rva2VuIiwiYXVkIjoibWFyc190ZXN0IiwiYXpwIjoibWFyc190ZXN0IiwiYXV0aF90aW1lIjoxNjM3OTkzNjg4LCJyZWFsbSI6Ii9DaUgiLCJleHAiOjE2Mzc5OTcyODgsInRva2VuVHlwZSI6IkpXVFRva2VuIiwiaWF0IjoxNjM3OTkzNjg4fQ.BjZ3h_hWLPPY05Y2d7Pui1G0t3L4DnJ213R6VuBNJ3MI8zeFWyb-xJjWr2mU99iL5enbCV_4XkS7cGK4PF7RCzf54S4Nf3Cm4mgxpHoaH5p4D87AtX1kZrE7DJEW4KvHMrM2jUHUthZn5B6mDgFTCD7wBoor1bQTZRSXBZKyIHyTeWXJgQO-Xc0p1EMoaspXG4Lh0pjXhbEIkQSR_jLnZm2Sn4myxKQyY4e6f9bjdD7E7II_3icn6Vqe8r6bKjRhZtEfSBRuzTpUUH5jStVGv4ykVr1It8M3eqC1aWbbmGJjQqYztVEZ0ulIzPO4f_Kf1LBeDjHyII3P8NZb8hdkvQ"}`

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmedPassword: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const [activeStep, setActiveStep] = useState(0); //0 for signup form, 1 for otp page and 2 for create profile page

  const [emailMain, setEmailMain] = useState();
  const StyledMain = styled('div')(({ theme }) => ({
    marginTop: '1%',
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },

  }));

  const PaperWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 60,
    },
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },

  }));

  useEffect(() => {
    return () => {
      toggleSignupOTPScreen(false)
      setSignupDetails(null, null)
    }
  }, [])

//redirecting to root page if user is loggedin
  if (isAuthenticated) {
    return (<Redirect to="/" />)


  }


  return (
    <>
      <CssBaseline />
      <StyledMain>
        <PaperWrapper>
          <Paper sx={{ padding: 2, paddingLeft: { xs: 2, sm: 6 }, paddingRight: { xs: 2, sm: 6 } }}>
            {/* <Box sx={{display:"flex",justifyContent:"center"}}>
          <img src={Logo} alt="logo.png"/>
          </Box> */}

            {/* <SignupCreateProfileForm/> */}

            {signupOTPscreen ? <SignupOTPform signupAuthId={signupAuthId} signupVerifyOTP={signupVerifyOTP}
              tempPass={tempPass} emailMain={emailMain} signupInit={signupInit} />
              : <SignupFormRes processLoader={processLoader}
                signupInit={signupInit} setEmailMain={setEmailMain} />}


          </Paper>
        </PaperWrapper>

      </StyledMain>

    </>
  );
};



const mapStateToProps = (state) => ({
  isAuthenticated: state.authenticationReducer.isAuthenticated,
  // createProfileScreen:state.authenticationReducer.createProfileScreen,
  userStatus: state.authenticationReducer.userStatus,
  processLoader: state.AlertsReducer.processLoader,
  signupOTPscreen: state.authenticationReducer.signupOTPscreen,
  signupAuthId: state.authenticationReducer.signupAuthId,
  tempPass: state.authenticationReducer.tempPass
})

const mapDispatchToProps = dispatch => {
  return {
    closeLogin: () => dispatch(LoginCloseRequest()),
    signupInit: (email, password) => dispatch(signupInit(email, password)),
    createProfile: (reqObj) => dispatch(createProfile(reqObj)),
    toggleSignupOTPScreen: (val) => dispatch({ type: SIGNUP_OTP_SCREEN_TOGGLE, payload: val }),
    setSignupDetails: (id, pass) => dispatch({ type: SET_SIGNUP_OTP_AUTH_ID_TEMP_PASS, payload: { authId: id, pass: pass } }),
    signupVerifyOTP: (otp, authId, email, password) => dispatch(signupVerifyOTP(otp, authId, email, password))


  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);