import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UpcomingWebinar from "./UpcomingWebinar";
import UnpublishedWebinar from "./UnpublishedWebinar";
import PastWebinar from "./PastWebinar";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  customTabRoot: {
    color: "black",
  },
  customTabIndicator: {
    backgroundColor: "black",
  },
  selected: {
    fontWeight: "bold",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Webinar = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", padding: 2 }}>
      <Box >
        <Tabs
          sx={{ padding: 2 }}
          value={value}
          onChange={handleChange}
          // textColor="inherit"
          classes={{
            root: classes.customTabRoot,
            indicator: classes.customTabIndicator,
          }}
        >
          <Tab
            style={{ textTransform: "none" }}
           
            label="Past Webinars"
          />
          <Tab
            style={{ textTransform: "none" }}
           
            label="Upcoming Webinars"
          />
          <Tab
            style={{ textTransform: "none" }}
          
            label="Unpublished Webinars"
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <PastWebinar />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UpcomingWebinar />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UnpublishedWebinar />
      </TabPanel>
    </Box>
  );
};

export default Webinar;
