import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  FormHelperText,
  Typography,
  Grid,
  Box,
  Modal,
  InputAdornment,
  IconButton
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
import { classes } from "./stylesUploadWebinar";
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import "./styles.css";

const UploadPastWebinar = ({
  handleAddLessonDialogClose,
  isAddLessonOpen,
  isLoading,
  type,
  createEditLesson,
  activeCourse,
  activeChapter,
  setEditModal,
  editModal,
  tempEdit,
  setTempEdit,
  selection,
  createEditChapter,



  currentSelection,
  uploadWebinar,
  setUploadModal,
  editedData,
  currentWebinar,
  webinarSaving
}) => {
  const [lessonTitle, setLessonTitle] = useState("");
  const [fileToBeUploaded, setFileToBeUploaded] = useState(null);

  const handleDialogClose = () => {
    setLessonTitle("");
    setFileToBeUploaded(null);
    // handleAddLessonDialogClose();
    // setEditModal(false)
    setUploadModal(false)

  };

  let fileAvilable = editedData && editedData.sas_recordedvideo_path &&
    editedData.sas_recordedvideo_path !== "Null" ? true : false

  const handleUpload = () => {
    const tempObject = {
      "webinar_id": currentWebinar,
      "video_path": fileToBeUploaded
    }

    const lessonObject = new FormData();
    // lessonObject.append(`data`, JSON.stringify(tempObject));
    lessonObject.append("video_path", fileToBeUploaded);
    lessonObject.append("webinar_id", currentWebinar);
    // createEditLesson(lessonObject, false);
    // setTimeout(() => handleDialogClose(), 3000);

    uploadWebinar(lessonObject)
    // handleDialogClose();
  };

  const handleEditUpload = () => {

    if (selection.course_level1_id) {
      const tempObject = {
        "course_id": activeCourse,
        "course_level1_id": selection.course_level1_id,
        "content_name": tempEdit
      }
      // const chapterObject = new FormData();
      // chapterObject.append(`data`, JSON.stringify(tempObject));
      createEditChapter(tempObject, true);
      handleDialogClose();
    }
    else {
      const tempObject = {
        course_id: activeCourse,
        course_level1_id: activeChapter,
        course_level2_id: selection.course_level2_id,
        content_name: tempEdit,
        file_type: type === "audio" ? "Audio" : type === "video" ? "Video" : type === "quiz" ? "Quiz" : "Reading Material",
        duration: type === "audio" ? "100 sec" : type === "video" ? "200 sec" : type === "quiz" ? "300 sec" : "600 sec",
      };
      const lessonObject = new FormData();
      lessonObject.append(`data`, JSON.stringify(tempObject));
      lessonObject.append("file_url", fileToBeUploaded);
      lessonObject.append("is_file_updated", fileToBeUploaded ? true : false);
      createEditLesson(lessonObject, true);
      // setTimeout(() => handleDialogClose(), 3000);
      handleDialogClose();
    }
  };

  const onDropAccepted = useCallback((files) => {
    setFileToBeUploaded(files[0]);
    // if (!isOnFileDetailsPage) {
    //   setIsFileSelected(true);
    //   selectFile(files[0]);
    //   selectArticle({});
    // } else selectFile(files[0]);
  });
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
  };
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      maxSize: 524288000,
      accept: [".mov", ".mp4"],
      maxFiles: 1,
      onDropRejected: () => {
        alert("File size limit exceeded.")
        return false
      },
      onDropAccepted,
    });

  return (
    <>
      <Dialog
        open={true}
        // onClose={handleDialogClose}
        aria-labelledby="alert_dialog_title"
        aria-describedby="alert_dialog_description"
        sx={classes.dialog}
      >
        <DialogContent sx={classes.dialogContent}>
          <DialogContentText id="alert_dialog_description" sx={{ marginBottom: 2 }}>
            {/* <FormHelperText id="lesson_title">Category</FormHelperText> */}
            <Grid container md={12}>
              <Grid item md={11}>
                <Typography color="primary" sx={{ fontSize: 26, fontWeight: 600, marginBottom: 2 }}>
                  {"Upload/Replace the webinar recording here"}</Typography>
              </Grid>
              <Grid container justifyContent="right" md={1}>
                <IconButton disabled={webinarSaving} onClick={handleDialogClose}>  <CloseIcon sx={{ fontSize: 30 }} /></IconButton>
              </Grid>
            </Grid>



            <Grid item xs={12} sm={12} md={12}>
              {/* <FormHelperText id="course_image_field">Veterinary association/Collaborator logo (optional)</FormHelperText> */}
              {fileAvilable ?
                <TextField
                  size="small"
                  fullWidth
                  id="course_image_field"
                  disabled
                  // placeholder="No file selected"
                  variant="outlined"
                  value={editedData &&

                    editedData.sas_recordedvideo_path.split("?")[0].split("/")
                    [editedData.sas_recordedvideo_path.split("?")[0].split("/").length - 1].split(".")
                    [0].slice(0, 40) + "." +

                    editedData.sas_recordedvideo_path.split("?")[0].split("/")
                    [editedData.sas_recordedvideo_path.split("?")[0].split("/").length - 1].split(".")
                    [editedData.sas_recordedvideo_path.split("?")[0].split("/")
                    [editedData.sas_recordedvideo_path.split("?")[0].split("/").length - 1].split(".").length - 1]
                  }
                  className="course_image_field"
                  multiline
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <label htmlFor="upload_webinar_video">
                          <input
                            disabled
                            accept="image/*"
                            id="upload_webinar_video"
                            //   multiple
                            hidden

                            type="file"
                            sx={{ display: "none" }}
                          // onChange={(e) => handleImageUpload(e, "collaboratorLogo")}
                          />
                          < >{<Typography sx={{ color: "green" }}>Webinar uploaded file</Typography>}</>
                          {/* <IconButton>
                            <DeleteIcon />
                          </IconButton> */}
                          {/* <DeleteIcon sx={{marginTop:1}}/> */}
                        </label>
                      </InputAdornment>

                    ),
                  }}


                /> :
                <Typography sx={{ color: "#FF0000", backgroundColor: "#F0F3F5", borderRadius: 1, paddingLeft: 1 }}>No webinar recording found. Please upload a recording for this webinar</Typography>

              }
            </Grid>
            <p
              className="file_selection_heading"
              style={classes.fileSelectionHeading}
            >

            </p>
            <section className="container">
              <div
                {...getRootProps({ className: "dropzone" })}
                style={
                  // selectedFile
                  //   ? classes.fileSelectionDivWithFileSelected
                  //   :
                  classes.fileSelectionDiv
                }
              >
                <input {...getInputProps()} />
                {editModal ? <p>Drop your new file here</p> : <p>Drop your file here</p>}
                <p>
                  or{" "}
                  <span
                    style={
                      // selectedFile
                      //   ? classes.browseSpanWithFileSelected
                      //   :
                      classes.browseSpan
                    }
                  >
                    Browse
                  </span>
                </p>
                <em>
                  Only video files are accepted
                </em>
              </div>
            </section>
            {/* <hr style={classes.divider} /> */}
          </DialogContentText>
          {
            editModal ?
              <Typography>
                {
                  !fileToBeUploaded
                    ? (selection.sas_file_path &&
                      selection.sas_file_path.split("?")[0].split("/")
                      [selection.sas_file_path.split("?")[0].split("/").length - 1])
                    : fileToBeUploaded.name
                }
              </Typography>
              :
              !fileToBeUploaded
                ? ""
                : (<Grid container md={12}>
                  <Grid item md={2}>
                    <Typography sx={{ marginTop: 2, fontSize: 14, marginLeft: 3 }}>
                      Selected file:
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography sx={{ marginTop: 2, fontSize: 12, marginLeft: 0 }}>
                      {fileToBeUploaded.name}

                    </Typography>
                  </Grid>
                </Grid>)


          }
        </DialogContent>
        <Grid container md={12}>
          <Grid item sm={6} sx={{ paddingLeft: 2, }}>
            <Grid container sm={12}>
              <Grid item sm="auto">
                <Typography sx={{ color: "#a4a4a4", fontSize: 14 }}>. Supported formats-</Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography sx={{ color: "black", fontSize: 14, marginLeft: 1 }}>
                  mp4,mov
                </Typography>
              </Grid>
            </Grid>
            <Grid container sm={12}>
              <Grid item sm="auto">
                <Typography sx={{ color: "#a4a4a4", fontSize: 14 }}>. Maximum size-</Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography sx={{ color: "black", fontSize: 14, marginLeft: 1 }}>
                  {type === "video" ? "500 MB" :
                    type === "audio" ? "50 MB" :
                      "500 MB"}
                </Typography>
              </Grid>
            </Grid>


          </Grid>
          <Grid container sm={6} justifyContent="right">
            {/* <Button onClick={handleDialogClose}>Cancel</Button> */}

            <LoadingButton
              onClick={handleUpload}
              autoFocus
              variant="contained"
              sx={classes.uploadBtn}
              disabled={!fileToBeUploaded || webinarSaving}
              // endIcon={<Save />}
              loading={webinarSaving}
              loadingPosition="center"
            >
              Upload
            </LoadingButton>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default UploadPastWebinar;
