import axios from "axios";
import {
  editFileFailure,
  editFileRequest,
  editFileSuccess,
  toggleAlert,
} from "../../ActionCreators/learningContentAdminActionCreators";

const FILE_EDIT_URL =
  process.env.REACT_APP_API_URL + "/learningcontent/admin/content/";

export const fileEdit = (fileEditOptions, fileId) => (dispatch) => {
  dispatch(editFileRequest());
  axios
    .put(FILE_EDIT_URL + `${fileId}/`, fileEditOptions, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(editFileSuccess());
        dispatch(toggleAlert("success", "File edited successfully !", true));
      } else {
        dispatch(editFileFailure("Something went wrong !"));
        dispatch(toggleAlert("error", "Error while editing !", true));
      }
    })
    .catch((error) => {
      dispatch(editFileFailure(error.message));
      dispatch(toggleAlert("error", error.message, true));
    });
};
