import React from "react";
import { Route, Redirect, Switch, BrowserRouter } from "react-router-dom";
import SignupPage from "./SignUp/SignupPage";
import NavbarV2 from "./Header/components/Navbar/NavbarV2";
// import CurriculumLayout from "./Courses/components/CurriculumLayout";
// import CreateCourse from "./Courses/CreateCourse";
import NewCourse from "./Courses/NewCourse/NewCourse";
import LoginPage from "./Login/LoginPage";
import WebinarPage from "./Webinar/WebinarVet/Webinar";
import AdminWebinarPage from "./Webinar/WebinarAdmin/Webinar";
import QueriesPage from "./Queries/QueriesVet/Queries";
import AdminQueriesPage from "./Queries/QueriesAdmin/Queries";
import WebinarVetDetailPage from "./Webinar/WebinarVet/WebinarVetDetail";
import WebinarAdminDetailPage from "./Webinar/WebinarAdmin/WebinarAdminDetail";
import CreateWebinarPage from "./Webinar/WebinarAdmin/CreateWebinar";
import LandingPage from "./LandingPage/LandingPage";
import { CssBaseline } from "@mui/material";
import { styled } from "@mui/material/styles";
import ForgotPassInit from "./ForgotPassword/ForgotPassInit";
import PrivateRoute from "./privateRoute";
import LearningContent from "./LearningContent/Components/LearningContent/LearningContent";
import SignupCreateProfileContainer from "./SignUp/SignupCreateProfileContainer";
import ChangePassword from "./ForgotPassword/ChangePassword";
import ArticleContainer from "./VetLearningContent/ArticleContainer";
import LearningContentContainer from "./VetLearningContent/LearningContentContainer";
import BackButton from "./backButton";
import { connect } from "react-redux";
import CoursesLayout from "./VetCourses/CoursesLayout";
import CoursesLandingPage from "./Courses/CoursesLandingPage/CoursesLandingPage";
import ActiveCourseContainer from "./VetCourses/ActiveCourseContainer";
import PostQuery from "./Webinar/Utils/PostQuery";
import UserManagement from "./Management/UserManagement";
import SSOlogin from "./SignUp/SSOlogin";

const Routes = ({ loggedInUser, backButton, userType }) => {
    const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);


    //Higher order components created to create admin and user views using same routes
     
    const LearningContentHOC = () => {
        if (userType === "Admin") {
            return <LearningContent />;
        }
        if (userType === "Vet") {
            return <LearningContentContainer />;
        } else {
            return <div>No data</div>;
        }
    };

    const CoursesHOC = () => {
        if (userType === "Admin") {
            return <CoursesLandingPage />;
        }
        if (userType === "Vet") {
            return <CoursesLayout />;
        } else {
            return <div>No data</div>;
        }
    };

    const WebinarHOC = () => {
        if (userType === "Admin") {
            return <AdminWebinarPage />;
        }
        if (userType === "Vet") {
            return <WebinarPage />;
        } else {
            return <div>No data</div>;
        }
    };

    const QueriesHOC = () => {
        if (userType === "Admin") {
            return <AdminQueriesPage />;
        }
        if (userType === "Vet") {
            return <QueriesPage />;
        } else {
            return <div>No data</div>;
        }
    };

    return (
        <BrowserRouter>
            <div>
                <CssBaseline />
                <NavbarV2 />
                <Offset />
                {backButton && <BackButton />}

                <Switch>
                    <Route exact path="/" component={LandingPage} /> 
                    <Route exact path="/admin" component={SSOlogin} /> 
                    <PrivateRoute
                        exact
                        path="/courses"
                        component={CoursesHOC}
                    />
                    {/* <Route exact path="/course/:id" component={ActiveCourseContainer} /> */}
                    <PrivateRoute
                        exact
                        path="/course/:id"
                        component={ActiveCourseContainer}
                    />
                    <PrivateRoute
                        exact
                        path="/article/:id"
                        component={ArticleContainer}
                    />
                     <PrivateRoute
                        exact
                        path="/management"
                        component={UserManagement}
                    />
                    <Route exact path="/signup" component={SignupPage} />
                    <Route
                        exact
                        path="/recoverpass"
                        component={ForgotPassInit}
                    />
                    <Route
                        exact
                        path="/createProfile"
                        component={SignupCreateProfileContainer}
                    />
                    <Route exact path="/login" component={LoginPage} />
                    <Route
                        exact
                        path="/changePassword"
                        component={ChangePassword}
                    />
                    <PrivateRoute
                        exact
                        path="/learningContent"
                        component={LearningContentHOC}
                    /> 
                    {/* <Route exact path="/home" component={CurriculumLayout} /> */}
                    <Route
                        exact
                        path="/learningContentAdmin"
                        component={LearningContent}
                    />
                    {/* <Route
                        exact
                        path="/learningContent"
                        component={LearningContent}
                    /> */}
                    <PrivateRoute exact path="/webinar" component={WebinarHOC} />
                    <PrivateRoute
                        exact
                        path="/queries"
                        component={QueriesHOC}
                    />
                    {/* <Route exact path="/adminWebinar" component={AdminWebinarPage} /> */}
                    <PrivateRoute
                        exact
                        path="/webinarVetDetail"
                        component={WebinarVetDetailPage}
                    />
                    <PrivateRoute
                        exact
                        path="/webinarAdminDetail"
                        component={WebinarAdminDetailPage}
                    />
                    <PrivateRoute
                        exact
                        path="/createWebinar"
                        component={CreateWebinarPage}
                    />
                    <Route exact path="/postQuery" component={PostQuery} />
                    <PrivateRoute exact path="/newCourse" component={NewCourse} />
                    {/* <Route exact path="/learningContent" component={LearningContent} /> */}
                    <Redirect to="/" />
                </Switch>
            </div>
        </BrowserRouter>
    );
};
const mapStateToProps = (state) => ({
    userType: state.authenticationReducer.userType,
    backButton: state.NavigationReducer.backButton,
});
export default connect(mapStateToProps, null)(Routes);
