export const FETCH_FILTER_VALUES_FAILURE = "FETCH_FILTER_VALUES_FAILURE";
export const FETCH_FILTER_VALUES_REQUEST = "FETCH_FILTER_VALUES_REQUEST";
export const FETCH_FILTER_VALUES_SUCCESS = "FETCH_FILTER_VALUES_SUCCESS";
export const SELECT_FILTER = "SELECT_FILTER";
export const FETCH_COURSES_FAILURE = "FETCH_COURSES_FAILURE";
export const FETCH_COURSES_REQUEST = "FETCH_COURSES_REQUEST";
export const FETCH_COURSES_SUCCESS = "FETCH_COURSES_SUCCESS";
export const CHANGE_PAGE = "CHANGE_PAGE";
export const EDIT_COURSE_FAILURE = "EDIT_COURSE_FAILURE";
export const EDIT_COURSE_REQUEST = "EDIT_COURSE_REQUEST";
export const EDIT_COURSE_SUCCESS = "EDIT_COURSE_SUCCESS";
export const DELETE_COURSE_FAILURE = "DELETE_COURSE_FAILURE";
export const DELETE_COURSE_RESET = "DELETE_COURSE_RESET";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const CHANGE_COURSE_STATUS_REQUEST = "CHANGE_COURSE_STATUS_REQUEST";
export const CHANGE_COURSE_STATUS_SUCCESS = "CHANGE_COURSE_STATUS_SUCCESS";
export const CHANGE_COURSE_STATUS_FAILURE = "CHANGE_COURSE_STATUS_FAILURE";
export const TOGGLE_ALERT = "TOGGLE_ALERT";
export const CHANGE_COURSE_DETAILS = "CHANGE_COURSE_DETAILS";
export const CREATE_COURSE_FAILURE = "CREATE_COURSE_FAILURE";
export const CREATE_COURSE_REQUEST = "CREATE_COURSE_REQUEST";
export const CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS";
export const GET_COURSE_DETAILS_FOR_EDIT = "GET_COURSE_DETAILS_FOR_EDIT";
export const SET_ACTIVE_COURSE = "SET_ACTIVE_COURSE";
export const CREATE_EDIT_CHAPTER_FAILURE = "CREATE_EDIT_CHAPTER_FAILURE";
export const CREATE_EDIT_CHAPTER_REQUEST = "CREATE_EDIT_CHAPTER_REQUEST";
export const CREATE_EDIT_CHAPTER_SUCCESS = "CREATE_EDIT_CHAPTER_SUCCESS";
export const CREATE_EDIT_LESSON_FAILURE = "CREATE_EDIT_LESSON_FAILURE";
export const CREATE_EDIT_LESSON_REQUEST = "CREATE_EDIT_LESSON_REQUEST";
export const CREATE_EDIT_LESSON_SUCCESS = "CREATE_EDIT_LESSON_SUCCESS";
export const SET_CURRICULUM_DETAILS = "SET_CURRICULUM_DETAILS";
export const SET_PREVIEW_DATA = "SET_PREVIEW_DATA";
export const CREATE_QUIZ_FAILURE = "CREATE_QUIZ_FAILURE";
export const CREATE_QUIZ_REQUEST = "CREATE_QUIZ_REQUEST";
export const CREATE_QUIZ_SUCCESS = "CREATE_QUIZ_SUCCESS";
export const NAVIGATE_ADMIN_ON_COURSE_SAVE = "NAVIGATE_ADMIN_ON_COURSE_SAVE";
export const CREATE_COURSE_EDIT_DETAILS = "CREATE_COURSE_EDIT_DETAILS"
export const SET_SPEAKER_IDS_FOR_EDIT = "SET_SPEAKER_IDS_FOR_EDIT"
export const CLEAR_COURSE_DATA = "CLEAR_COURSE_DATA"
export const TOGGLE_EDIT_FLOW = "TOGGLE_EDIT_FLOW"
export const SET_PREVIEW_SEQUENCE = "SET_PREVIEW_SEQUENCE"