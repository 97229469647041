import {
  LOGIN_OPEN,
  LOGIN_CLOSE,
  SIGNUP_OPEN,
  SIGNUP_CLOSE,
  PROFILEFORM_OPEN,
  PROFILEFORM_CLOSE,
  FORGETPASSWORD_OPEN,
  FORGETPASSWORD_CLOSE,
  OTPSCREEN_OPEN,
  OTPSCREEN_CLOSE,
  NEWPASSWORDSCREEN_OPEN,
  NEWPASSWORDSCREEN_CLOSE,
  TOGGLE_BACK_BUTTON
} from "../ActionTypes/NavbarActionTypes";

const initialState = {
  loginModalOpen: false,
  signupModalOpen: false,
  profileFormModalOpen: false,
  newPasswordModal: false,
  otpModal: false,
  forgotPasswordModal: false,
  backButton: false
};

function NavigationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_OPEN:
      return {
        ...state,
        loginModalOpen: true,
      };
    case LOGIN_CLOSE:
      return {
        ...state,
        loginModalOpen: false,
      };
    case SIGNUP_OPEN:
      return {
        ...state,
        signupModalOpen: true,
      };
    case SIGNUP_CLOSE:
      return {
        ...state,
        signupModalOpen: false,
      };
    case PROFILEFORM_OPEN:
      return {
        ...state,
        profileFormModalOpen: true,
      };
    case PROFILEFORM_CLOSE:
      return {
        ...state,
        profileFormModalOpen: false,
      };
    case FORGETPASSWORD_OPEN:
      return {
        ...state,
        forgotPasswordModal: true,
      };
    case FORGETPASSWORD_CLOSE:
      return {
        ...state,
        forgotPasswordModal: false,
      };
    case OTPSCREEN_OPEN:
      return {
        ...state,
        otpModal: true,
      };
    case OTPSCREEN_CLOSE:
      return {
        ...state,
        otpModal: false,
      };
    case NEWPASSWORDSCREEN_OPEN:
      return {
        ...state,
        newPasswordModal: true,
      };
    case NEWPASSWORDSCREEN_CLOSE:
      return {
        ...state,
        newPasswordModal: false,
      };
    case TOGGLE_BACK_BUTTON:
      return {
        ...state, ...payload
      }
    default:
      return state;
  }
}

export default NavigationReducer;
