import React, { useState, useEffect, useMemo } from "react";
// import "./styles.css";
import { useHistory, useParams } from "react-router-dom";
import { Box, Paper, Typography, Grid, TextField, Modal, Button, CssBaseline } from '@mui/material';
import { connect } from "react-redux"
import { login } from "../Redux/Middlewares/loginMiddleware";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { styled, alpha } from "@mui/material/styles";
import { LoginCloseRequest } from "../Redux/ActionCreators/NavbarActionCreators"
import Logo from "../Header/components/Navbar/logo.png"
import { useFormik } from "formik"
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { Redirect } from "react-router-dom";
import Chip from '@mui/material/Chip';
import Rating from '@mui/material/Rating';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Document, Page, pdfjs } from 'react-pdf';
import test1 from "./test1.pdf"
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import ScrollToTopButton from "./ScrollToTopButton"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import StyledPDFContainer from "./StyledPDFContainer";
import { getSingleArticle } from "../Redux/Middlewares/getSingleArticleMiddleware"
import { rateNBookmarkLC } from "../Redux/Middlewares/rateNBookmarkLearningContentMiddleware"
import SpinnerLG from "../SpinnerLG";
import { addQueryLC } from "../Redux/Middlewares/addQueryLCMiddleware"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { TOGGLE_BACK_BUTTON } from "../Redux/ActionTypes/NavbarActionTypes"
import Slider from '@mui/material/Slider';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Stack from '@mui/material/Stack';



pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const Login = ({ setLoggedInUser }) => {
const ArticleContainer = ({ toggleBackButton, userEmail, isAuthenticated, userStatus, getSingleArticle, isLoading, contents,
    rateNBookmarkLC, isBookmarked, userRating, avgRating, addQueryLC }) => {

    const { id } = useParams()
    useEffect(() => {
        toggleBackButton(true)

        let defaultReq = {
            "learning_content_id": id,
            "email": userEmail
        }

        getSingleArticle(defaultReq)
        return () => {
            toggleBackButton(false)
        }
    }, [])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: "100%", md: 400 },
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: 1,
        boxShadow: 24,
        p: 2,
    };



    //   if(isAuthenticated){
    //    return(<Redirect to="/" />)


    //   }


    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(4);
    const [queryText, setQueryText] = useState("");
    const [links, setLink] = useState("https://file-examples-com.github.io/uploads/2017/10/file-sample_150kB.pdf")
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isModalOpen, setModal] = React.useState(false);
    const [queryError, setQueryError] = React.useState("");
    const [zoom, setZoom] = React.useState(30);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleZoom = (event, newValue) => {
        setZoom(newValue);
    };


    const handleCloseQuery = () => {
        setAnchorEl(null);
        setModal(true)
    };

    const handleBookmark = () => {
        rateNBookmarkLC("bookmark", isBookmarked ? "N" : "Y", id, null)
    }

    const handleRating = (event, newValue) => {
        rateNBookmarkLC("rating", newValue, id, null)
    }

    const handleOpenModal = () => setModal(true);
    const handleCloseModal = () => { setQueryError(""); setQueryText(""); setModal(false) };

    const handleQuery = () => {
        if (queryText) {

            addQueryLC(id, "Medical Paper", queryText)
            setQueryError("")
            setQueryText("")
            handleCloseModal()
        }
        else {
            setQueryError("Please enter a query")
        }


    }

    let history = useHistory();

    if (!contents || contents.length < 1) {
        return (<SpinnerLG />)
    }
    return (
        <>
            <CssBaseline />
            {/* <StyledMain>
            
                <PaperWrapper> */}

            <Box sx={{ marginTop: 4, paddingTop: 2, paddingLeft: { xs: 2, sm: 2, md: 10 }, paddingRight: { xs: 2, sm: 2, md: 10 } }}>

                <Grid container spacing={0} >


                    <Grid item xs={12} sm={12}>

                        <Typography sx={{ marginTop: 0, fontSize: 18, fontWeight: 600, color: "#0f01a0" }} align="left">
                            {contents.article_info.file_title}
                        </Typography>

                    </Grid>


                    <Grid container xs={12} sm={12} md={12} spacing={0} sx={{ marginTop: 1 }}>

                        <Grid item xs="auto" sm={3} md="auto" >

                            <Chip sx={{ marginTop: 0, marginBottom: 1, color: "white", backgroundColor: "#8080cf" }} label={contents.article_info.file_type} size="small" />

                        </Grid>
                        <Grid item xs="auto" sm={3} md={5} >
                            <Rating sx={{ fontSize: { xs: 20, md: 25 }, color: "#6ad7b9", marginLeft: 1 }} name="read-only" value={avgRating} readOnly size="small" />
                        </Grid>

                    </Grid>
                    <Grid container xs={6} sm={6} md={6} spacing={0} sx={{ padding: 0, paddingTop: 0 }} justifyContent="left" >
                        <Grid item xs={12} sm={12} md="auto" >
                            <Typography noWrap sx={{
                                marginTop: 1.4, marginRight: 1, fontSize: { xs: 11, md: 15 }, fontWeight: 600, color: "#4e4e4e"
                            }} >
                                {contents.article_info.author}
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={6} sm={6} md={6} >
                            <Typography noWrap sx={{ marginTop: 1.4, fontSize: { xs: 11, md: 15 }, marginLeft: 0, color: "#4e4e4e" }} align="left">
                                {contents.article_info.last_updated_date}
                            </Typography>
                        </Grid> */}
                    </Grid>
                    <Grid container item xs={6} sm={6} md={6} justifyContent="right" alignItems="right">
                        {/* <Grid item>
                            <Typography noWrap sx={{ marginTop: 1.4, fontSize: { xs: 11, md: 15 }, marginLeft: 0, fontSize: { xs: 11, md: 15 }, fontWeight: 600, color: "#808080" }}>
                                {isBookmarked ? "Bookmarked" : "Bookmark"}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton sx={{
                                color: isBookmarked ? "#6ad7b9" : "#d4d4d4",
                                fontSize: { xs: 11, md: 15 }
                            }}
                                id="basic-button"
                                onClick={handleBookmark}
                                aria-controls="basic-menu" >
                                <BookmarkIcon />
                            </IconButton>
                        </Grid> */}
                        {/* <Grid item>
                            <IconButton id="basic-button"
                                aria-controls="basic-menu"

                                onClick={handleClick} sx={{ marginTop: .4, color: "#808080", marginLeft: 1, }} size="small">
                                <MoreVertIcon />
                            </IconButton>
                        </Grid> */}
                    </Grid>

                    <Grid container xs={12} md={12}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ display: { xs: "flex", md: "none" } }}>

                        <Grid item md={6} xs={12} >


                            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                <ZoomOutIcon />
                                <Slider aria-label="Volume" value={zoom} onChange={handleZoom} />
                                <ZoomInIcon />
                            </Stack>
                        </Grid>
                    </Grid>


                    <Grid sx={{}} container justifyContent="center" xs={12} sm={12} md={12} spacing={0}>
                        {/* {links && */}
                        {/* <StyledPDFContainer> */}
                        <Grid sx={{ justifyContent: "center", overflow: 'auto', bgcolor: 'background.paper', height: "70vh", display: { md: "none", xs: "flex" } }}>

                            <Document
                                // contents.article_info.sas_file_path
                                file={contents.article_info.sas_file_path}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                {numPages && ([...Array(numPages).keys()].map(item => (
                                    <Page width={zoom * 13}
                                        key={item} renderTextLayer={false} pageNumber={item + 1} />)))}

                                {/* <Page renderTextLayer={false} pageNumber={1} />
                                        <Page renderTextLayer={false} pageNumber={2} />
                                        <Page renderTextLayer={false} pageNumber={3} />
                                        <Page renderTextLayer={false} pageNumber={4} /> */}

                            </Document>

                        </Grid>
                        <Grid sx={{ justifyContent: "center", bgcolor: 'background.paper', display: { md: "flex", xs: "none" } }}>
                            <Document
                                // contents.article_info.sas_file_path
                                file={contents.article_info.sas_file_path}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                {numPages && ([...Array(numPages).keys()].map(item => (
                                    <Page width={1000}
                                        key={item} renderTextLayer={false} pageNumber={item + 1} />)))}

                                {/* <Page renderTextLayer={false} pageNumber={1} />
                                        <Page renderTextLayer={false} pageNumber={2} />
                                        <Page renderTextLayer={false} pageNumber={3} />
                                        <Page renderTextLayer={false} pageNumber={4} /> */}

                            </Document>
                        </Grid>
                        {/* <p>Page {pageNumber} of {numPages}</p> */}
                        {/* </div> */}
                        {/* </StyledPDFContainer> */}
                        {/* } */}
                        <ScrollToTopButton showBelow={300} />
                    </Grid>


                </Grid>




            </Box>

            {/* </Paper> */}
            {/* </PaperWrapper>

            </StyledMain> */}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >

                {/* <MenuItem onClick={handleCloseQuery}>Ask a query</MenuItem> */}
                <MenuItem onClick={handleClose}>Add feedback</MenuItem>
            </Menu>

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container >
                        <Grid container item xs={12} sm={12}>
                            <Grid container justifyContent="right" item xs={12} sm={12}>
                                <IconButton color="primary" onClick={handleCloseModal}>
                                    <CloseIcon sx={{ fontSize: 35 }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography sx={{ marginBottom: 1, color: "#0f01a0", fontSize: 26, fontWeight: 600 }} id="modal-modal-title"  >
                                Add New Query
                            </Typography>

                            {queryError && <Typography sx={{ marginBottom: 1, color: "red" }} id="modal-modal-title"  >
                                {queryError}
                            </Typography>}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                id="outlined-multiline-static"
                                inputProps={{ maxLength: 300 }}
                                fullWidth
                                onChange={(e) => setQueryText(e.target.value)}
                                multiline
                                rows={4}
                                placeholder="Type your query here"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Button style={{ textTransform: 'none' }} fullWidth onClick={handleQuery} sx={{ marginTop: 1 }} variant="contained">Submit</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>


            <Box sx={{ width: "100%", minHeight: "7vh", color: "white", backgroundColor: "white", display: "flex", bottom: "0%", position: "fixed" }}>
                <Typography sx={{ color: "#0f01a0", zIndex: 2, bottom: "1vh", left: "5%", position: "fixed", fontWeight: 600 }} > Rate this Article</Typography>
                <Rating onChange={handleRating} sx={{ color: "#6ad7b9", zIndex: 2, bottom: "1vh", left: "45%", position: "fixed" }} name="size-large" defaultValue={userRating} size="large" />
            </Box>
        </>
    );
};



const mapStateToProps = (state) => ({
    isAuthenticated: state.authenticationReducer.isAuthenticated,
    // createProfileScreen:state.authenticationReducer.createProfileScreen,
    userStatus: state.authenticationReducer.userStatus,
    userEmail: state.authenticationReducer.user,
    isLoading: state.LearningContentReducer.loading,
    contents: state.LearningContentReducer.contents,
    isBookmarked: state.LearningContentReducer.isBookmarked,
    userRating: state.LearningContentReducer.rating,
    avgRating: state.LearningContentReducer.avgRating,
})

const mapDispatchToProps = dispatch => {
    return {

        getSingleArticle: (reqObj) => dispatch(getSingleArticle(reqObj)),
        rateNBookmarkLC: (flag, value, id, obj = null) => dispatch(rateNBookmarkLC(flag, value, id, obj)),
        addQueryLC: (moduleId, queryCategory, queryText) => dispatch(addQueryLC(moduleId, queryCategory, queryText)),
        toggleBackButton: (val) => dispatch({ type: TOGGLE_BACK_BUTTON, payload: { backButton: val } })


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleContainer);