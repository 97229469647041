import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useHistory, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import CoursesView from "./CoursesView";
import { connect } from "react-redux"
import { makeStyles } from "@mui/styles";
import {
    Grid, IconButton, TextField, InputAdornment, Chip, Drawer, MenuItem, Select, OutlinedInput,
    Stack, Button, Paper, Divider, Modal
} from "@mui/material"
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ScrollToTopButton from "../VetLearningContent/ScrollToTopButton"
import { getVetCourses } from ".././Redux/Middlewares/VetCourses/getCoursesVetMiddleware"
import SpinnerLG from "../SpinnerLG";
import { Autocomplete } from "@mui/material"
import { getFiltersVetCourses } from "../Redux/Middlewares/VetCourses/getFiltersVetCoursesMiddleware";
import { SET_USER_COURSE_FILTER_DATA, CLEAR_USER_COURSE_FILTER_DATA } from "../Redux/ActionTypes/coursesVetActionTypes"
import { getActiveVetCourses } from "../Redux/Middlewares/VetCourses/getActiveCourseMiddleware"
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import LinearProgress from '@mui/material/LinearProgress';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LessonActive from "./LessonActive"
import ArticleIcon from '@mui/icons-material/Article';
import QuizIcon from '@mui/icons-material/Quiz';
import FileIcon from "../Assets/images/FileIcon.svg"
import QuizIconNew from "../Assets/images/QuizIconNew.svg"
import moment from "moment";
import CancelIcon from '@mui/icons-material/Cancel';


const GradesCourse = ({ gradeData, quizLoader }) => {

    if (quizLoader) {
        return (<SpinnerLG />)
    }

    return (<><Grid container xs={12}>
        <Grid container alignItems="center" xs={12} sx={{ marginTop: 4 }}>
            <Grid container justifyContent="left" xs={5}>
                <Grid item xs={2}>

                </Grid>
                <Grid item xs={10}>
                    <Typography sx={{ fontWeight: 600, fontColor: "#393939", fontSize: 15, }} > ITEM</Typography>

                </Grid>
            </Grid>
            <Grid container justifyContent="center" xs={2}>
                <Typography sx={{ fontWeight: 600, fontColor: "#393939", fontSize: 15, paddingRight: 2 }} > STATUS</Typography>
            </Grid>
            <Grid container justifyContent="center" xs={2}>
                <Typography sx={{ fontWeight: 600, fontColor: "#393939", fontSize: 15, }} > GRADE</Typography>
            </Grid>
            <Grid container justifyContent="center" xs={3}>
                <Typography sx={{ fontWeight: 600, fontColor: "#393939", fontSize: 15, }} > DATE</Typography>
            </Grid>

        </Grid>
        {
            (gradeData && gradeData.attempted && gradeData.attempted.length > 0) && gradeData.attempted.map(
                (item, index) => {
                    return (<Grid container alignItems="center" xs={12} sx={{ marginTop: 4 }}>

                        <Grid container justifyContent="center" xs={5}>
                            {item.status === "fail" ?
                                <Grid item xs={2}>
                                    <CancelIcon sx={{ color: "#EC3225" }} />
                                </Grid> :
                                <Grid item xs={2}>
                                    <CheckCircleIcon sx={{ color: "#00D7B9" }} />
                                </Grid>}

                            <Grid item xs={10}>
                                <Typography sx={{ fontColor: "#393939", fontSize: 12, fontWeight: 600 }} > {item.lesson_name.content_name}</Typography>

                            </Grid>


                        </Grid>
                        <Grid container justifyContent="center" xs={2}>
                            <Typography sx={{
                                fontSize: 14, fontWeight: 700,
                                color: item.status === "fail" ? "#EC3225" : "#00D7B9"
                            }} > {item.status === "fail"?"RETRY":"PASS"}</Typography>
                        </Grid>
                        <Grid container justifyContent="center" xs={2}>
                            <Typography sx={{ fontColor: "#393939", fontSize: 14, fontWeight: 700 }} > {item.grade}%</Typography>
                        </Grid>
                        <Grid container justifyContent="center" xs={3}>
                            <Typography sx={{ fontColor: "#393939", fontSize: 14, fontWeight: 700 }} > {moment(item.end_timestamp).format("DD MMM YY")}</Typography>
                        </Grid>
                        <Divider sx={{ marginTop: 2 }} />
                    </Grid>)
                }
            )
        }
        {
            (gradeData && gradeData.not_attempted && gradeData.not_attempted.length > 0) && gradeData.not_attempted.map(
                (item, index) => {
                    return (<Grid container alignItems="center" xs={12} sx={{ marginTop: 4 }}>

                        <Grid container justifyContent="center" xs={5}>
                            <Grid item xs={2}>
                                <LockIcon sx={{ color: "#8F8F8F" }} />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography sx={{ color: "#8F8F8F", fontSize: 12, fontWeight: 600 }} > {item.content_name}</Typography>

                            </Grid>


                        </Grid>
                        <Grid container justifyContent="center" xs={2}>
                            <Typography sx={{ fontSize: 12, fontWeight: 600, color: "#8F8F8F" }}> - - </Typography>
                        </Grid>
                        <Grid container justifyContent="center" xs={2}>
                            <Typography sx={{ color: "#8F8F8F", fontSize: 12, fontWeight: 600 }} > - -</Typography>
                        </Grid>
                        <Grid container justifyContent="center" xs={3}>
                            <Typography sx={{ color: "#8F8F8F", fontSize: 12, fontWeight: 600 }} >- -</Typography>
                        </Grid>
                        <Divider sx={{ marginTop: 2 }} />
                    </Grid>)
                }
            )
        }
    </Grid>
    </>)
}

export default GradesCourse