import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Card,
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Box,
  Stack
} from "@mui/material";
import { Add, Save, ExpandMore, MoreVert } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { createEditChapter } from "../../Redux/Middlewares/CoursesAdmin/createEditChapterMiddleware";
import AddLesson from "../AddLesson/AddLesson";
import { createEditLesson } from "../../Redux/Middlewares/CoursesAdmin/createEditLessonMiddleware";
import AddQuizLesson from "../../AddQuizLesson/AddQuizLesson";
import { createQuiz } from "../../Redux/Middlewares/CoursesAdmin/createQuizMiddleware";
import { classes } from "./styles";
import LinearProgress from '@mui/material/LinearProgress';
import { addOption, deleteOption, addQuestion, deleteQuestion, changeQuestion, changeQuizName } from "../../Redux/Middlewares/CoursesAdmin/quizEditingMiddleware"
import "./styles.css";
import { deleteLesson } from "../../Redux/Middlewares/CoursesAdmin/deleteLessonMiddleware";
import { deleteChapter } from "../../Redux/Middlewares/CoursesAdmin/deleteChapterMiddleware";

const CurriculumDetails = ({
  createEditChapter,
  activeCourse,
  isLoading,
  curriculumDetails,
  createEditLesson,
  createQuiz,
  isUploading,
  addOption,
  deleteOption,
  deleteLesson,
  deleteChapter,
  addQuestion,
  deleteQuestion,
  changeQuestion,
  changeQuizName
}) => {
  const [isTempChapterOpen, setIsTempChapterOpen] = useState(false);
  const [tempChapterValue, setTempChapterValue] = useState("");
  const [isChapterExpanded, setIsChapterExpanded] = useState(false);
  const [isAddLessonOpen, setIsAddLessonOpen] = useState(false);
  const [isAddQuizLessonOpen, setIsAddQuizLessonOpen] = useState(false);
  const [lessonType, setLessonType] = useState("pdf");
  const [activeChapter, setActiveChapter] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selection, setSelection] = useState(null);
  const [tempEdit, setTempEdit] = useState("");
  const [quizEditing, setQuizEditing] = useState(false);

  const openMenu = Boolean(anchorEl);
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const handleAddLessonDialogOpen = (type, chapterId) => {
    setLessonType(type);
    setActiveChapter(chapterId);
    if (type === "quiz") setTimeout(() => setIsAddQuizLessonOpen(true), 1000);
    else setTimeout(() => setIsAddLessonOpen(true), 2000);
  };

  const handleAddLessonDialogClose = () => {
    setIsAddLessonOpen(false);
  };

  const handleMenuClick = (e, val) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelection(val)
  };
  const handleCloseMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const handleEditClick = (e) => {

    if (selection.file_type === "Quiz") {
      setIsAddQuizLessonOpen(true)
      setQuizEditing(true)
    }
    else {
      if (selection.file_type === "Audio") {
        setLessonType("audio")
      }
      else if (selection.file_type === "Video") {
        setLessonType("video")
      }
      else if (selection.file_type === "Reading Material") {
        setLessonType("pdf")
      }

      setEditModal(true)
      setIsAddLessonOpen(true)
    }

    setTempEdit(selection.content_name)

  
    handleCloseMenu(e);
  };
  const handleDeleteCourseClick = (e) => {
    // deleteCourse(course.course_id);
    if (selection.course_level1_id) {
      deleteChapter(selection.course_level1_id)
    }
    if (selection.course_level2_id) {
      deleteLesson(selection.course_level2_id)

    }

    handleCloseMenu(e);
  };
  const handleAddQuizLessonDialogClose = () => {
    setIsAddQuizLessonOpen(false);
    setQuizEditing(false)
  };

  const handleAddChapterClick = () => {
    if (isTempChapterOpen) return;
    setIsTempChapterOpen(true);
  };

  const handleDiscardClick = () => {
    setTempChapterValue("");
    setIsTempChapterOpen(false);
  };

  const handleSaveClick = () => {
    if (!tempChapterValue) return;
    handleCreateEditChapterRequest();
    handleDiscardClick();
  };

  const handleCreateEditChapterRequest = () => {
    const curriculumObject = {
      course_id: activeCourse,
      course_level1_id: null,
      content_name: tempChapterValue,
    };
    createEditChapter(curriculumObject);
  };
  const handleEdit = () => {

    if (selection.course_level1_id) {
      const tempObject = {
        "course_id": activeCourse,
        "course_level1_id": selection.course_level1_id,
        "content_name": tempEdit
      }
      // const chapterObject = new FormData();
      // chapterObject.append(`data`, JSON.stringify(tempObject));
      createEditChapter(tempObject);
    }
    else if (selection.course_level2_id) {
      const tempObject = {
        course_id: activeCourse,
        course_level1_id: activeChapter,
        course_level2_id: selection.course_level2_id,
        content_name: tempEdit,
        file_type: selection.file_type,
        duration: selection.duration,
      };


      const lessonObject = new FormData();
      lessonObject.append(`data`, JSON.stringify(tempObject));
      lessonObject.append("file_url", null);
      lessonObject.append("is_file_updated", false);
      createEditLesson(lessonObject, true);

    }

    setEditModal(false)
  }

  const handleChapterClick = (chapter) => (event, isExpanded) => {
    setIsChapterExpanded(isExpanded ? chapter : false);
  };

  const chapterArray = curriculumDetails.chapters
    ? curriculumDetails.chapters.map((chapter, index) => (
      <Accordion
        expanded={isChapterExpanded === chapter.course_level1_id}
        onChange={handleChapterClick(chapter.course_level1_id)}
        key={index}
        sx={classes.chapterAccordian}
        className="chapterAccordian"
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={`${index}_content`}
          id={`${index}_header`}
        >
          <Typography sx={classes.chapterTitle}>
            {chapter.content_name}
          </Typography>
          <Typography sx={classes.chapterInfo}>
            <span style={classes.lessonCount}>
              {chapter.lessons.length} Lesson
              {chapter.lessons.length === 0 || chapter.lessons.length === 1
                ? ""
                : "s"}
            </span>
            <span style={classes.lessonDuration}>
              {Math.ceil(
                chapter.lessons.reduce(
                  (total, lesson) =>
                    total + Number(lesson.duration.split(" ")[0]),
                  0
                ) / 60
              )}{" "}
              min
            </span>
          </Typography>
          <IconButton
            color="default"
            aria-label="accordian actions"
            component="span"
            sx={classes.threeDotMenu}
            onClick={(e) => handleMenuClick(e, chapter)}
          >
            <MoreVert />
          </IconButton>
        </AccordionSummary>
        <AccordionDetails>
          {chapter.lessons.map((lesson, key) => (
            <Typography key={key} sx={classes.lessonTitle}>
              <span style={classes.lessonName}>{lesson.content_name}</span>
              <IconButton
                color="default"
                aria-label="lesson actions"
                component="span"
                sx={classes.lessonThreeDotMenu}
                onClick={(e) => { handleMenuClick(e, lesson); setActiveChapter(chapter.course_level1_id); }}
              >
                <MoreVert />
              </IconButton>
              <span style={classes.duration}>
                {Math.ceil(lesson.duration.split(" ")[0] / 60)} min
              </span>
              <span style={classes.fileType}>{lesson.file_type}</span>
            </Typography>
          ))}
          <Typography sx={classes.addLesson}>
            <Button
              disabled={isUploading}
              variant="outlined"
              onClick={() =>
                handleAddLessonDialogOpen("pdf", chapter.course_level1_id)
              }
              sx={classes.addLessonBtns}
            >
              <Add /> Reading Material
            </Button>
            <Button
              disabled={isUploading}
              variant="outlined"
              onClick={() =>
                handleAddLessonDialogOpen("video", chapter.course_level1_id)
              }
              sx={classes.addLessonBtns}
            >
              <Add /> Video
            </Button>
            <Button
              disabled={isUploading}
              variant="outlined"
              onClick={() =>
                handleAddLessonDialogOpen("audio", chapter.course_level1_id)
              }
              sx={classes.addLessonBtns}
            >
              <Add /> Audio
            </Button>
            <Button
              disabled={isUploading}
              variant="outlined"
              onClick={() =>
                handleAddLessonDialogOpen("quiz", chapter.course_level1_id)
              }
              sx={classes.addLessonBtns}
            >
              <Add /> Quiz
            </Button>
          </Typography>

          {isUploading &&
            <>
              <Typography sx={{ marginTop: 2, fontSize: 14 }}>Uploading.Please wait...</Typography>
              <LinearProgress color="secondary" />
            </>}
        </AccordionDetails>
      </Accordion>
    ))
    : [];

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      {chapterArray}
      {isTempChapterOpen && (
        <Card variant="outlined" sx={classes.tempChapterCard}>
          <TextField
            size="small"
            id="temp_chapter_title"
            placeholder="Enter chapter name"
            variant="outlined"
            className="temp_chapter_title"
            sx={classes.tempChapterTitle}
            value={tempChapterValue}
            onChange={(e) => setTempChapterValue(e.target.value)}
          />
          <Button
            variant="text"
            onClick={handleDiscardClick}
            sx={classes.discardBtn}
          >
            Discard
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleSaveClick}
            sx={classes.saveBtn}
            disabled={!tempChapterValue}
            endIcon={<Save />}
            loading={isLoading}
            loadingPosition="end"
          >
            Save
          </LoadingButton>
        </Card>
      )}
      <Card variant="outlined" sx={classes.addChapterCard}>
        <Button variant="text" onClick={handleAddChapterClick} disabled={isUploading}>
          <Add /> Add Chapter
        </Button>
      </Card>
      {isAddLessonOpen && <AddLesson
        handleAddLessonDialogClose={handleAddLessonDialogClose}
        isAddLessonOpen={isAddLessonOpen}
        isLoading={isLoading}
        type={lessonType}
        setEditModal={setEditModal}
        editModal={editModal}
        tempEdit={tempEdit}
        setTempEdit={setTempEdit}
        createEditLesson={createEditLesson}
        activeChapter={activeChapter}
        activeCourse={activeCourse}
        selection={selection}
        createEditChapter={createEditChapter}
      />}
      {isAddQuizLessonOpen && <AddQuizLesson
        isAddQuizLessonOpen={isAddQuizLessonOpen}
        quizEditing={quizEditing}
        isLoading={isLoading}
        addOption={addOption}
        curriculumDetails={curriculumDetails}
        selection={selection}
        activeChapter={activeChapter}
        activeCourse={activeCourse}
        handleAddQuizLessonDialogClose={handleAddQuizLessonDialogClose}
        createQuiz={createQuiz}
        deleteOption={deleteOption}
        addQuestion={addQuestion}
        deleteQuestion={deleteQuestion}
        changeQuestion={changeQuestion}
        changeQuizName={changeQuizName}

      />}

      <Menu
        // id={`basic_menu_${index}`}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
        {/* <MenuItem
          onClick={(e) => handleSwitchStatus(e, course)}
          sx={classes.actionItem}
        >
          {type === "Published" ? "Unpublish course" : "Publish course"}
        </MenuItem> */}
        <MenuItem onClick={(e) => { handleCloseMenu(e); setDeleteModal(true); }}>Delete</MenuItem>
      </Menu>
      <Modal
        open={deleteModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {/* <TextField value={tempEdit} onChange={e => setTempEdit(e.target.value)} /> */}
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Confirm delete?
          </Typography>
          <Stack direction="row" sx={{ mt: 2 }} spacing={1} >
            <Button onClick={(e) => { handleDeleteCourseClick(e); setDeleteModal(false); }} style={{ textTransform: "none" }} variant="contained">Yes</Button>
            <Button onClick={() => { setDeleteModal(false) }} style={{ textTransform: "none" }} variant="contained">No</Button>
          </Stack>
        </Box>

      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeCourse: state.CoursesAdminReducer.activeCourse,
  isLoading: state.CoursesAdminReducer.isLoading,
  curriculumDetails: state.CoursesAdminReducer.curriculumDetails,
  isUploading: state.CoursesAdminReducer.isUploading,
  // courses: state.CoursesAdminReducer.courses,
  // pageNumber: state.CoursesAdminReducer.pageNumber,
});

const mapDispatchToProps = (dispatch) => ({
  createEditChapter: (chapterObject,edit) =>
    dispatch(createEditChapter(chapterObject,edit)),
  createEditLesson: (lessonObject, isEdit) => dispatch(createEditLesson(lessonObject, isEdit)),
  createQuiz: (quizObject) => dispatch(createQuiz(quizObject)),
  addOption: (quizObject) => dispatch(addOption(quizObject)),
  deleteOption: (id) => dispatch(deleteOption(id)),
  deleteLesson: (id) => dispatch(deleteLesson(id)),
  deleteChapter: (id) => dispatch(deleteChapter(id)),
  addQuestion: (obj) => dispatch(addQuestion(obj)),
  deleteQuestion: (id) => dispatch(deleteQuestion(id)),
  changeQuestion: (obj) => dispatch(changeQuestion(obj)),
  changeQuizName: (obj) => dispatch(changeQuizName(obj)),
  // getCourses: (filterDetails, pageNumber) =>
  //   dispatch(getCourses(filterDetails, pageNumber)),
  // changePage: (pageNumber) => dispatch(changePage(pageNumber)),
  // changeCourseStatus: (courseDetails) =>
  //   dispatch(changeCourseStatus(courseDetails)),
  // getCourseDetailsForEdit: (courseDetails) =>
  //   dispatch(getCourseDetailsForEdit(courseDetails)),
  // setActiveCourse: (id) => dispatch(setActiveCourse(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurriculumDetails);
