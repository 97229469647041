import React, { useState } from "react";
import { connect } from "react-redux";
import { TabList, TabContext, TabPanel } from "@mui/lab";
import { Tab, Snackbar, Alert } from "@mui/material";
import CoursesLayout from "../CoursesLayout/CoursesLayout";
import { toggleAlert } from "../../Redux/ActionCreators/coursesAdminActionCreators";
import { classes } from "./styles";
import {
  changeCourseDetails,
  changePage,
  getCourseDetailsForEdit,
  selectFilter,
  setActiveCourse,
} from "../../Redux/ActionCreators/coursesAdminActionCreators";
import "./styles.css";

const CoursesLandingPage = ({
  toggleAlert,
  isAlertOpen,
  alertType,
  alertMessage,
  changePage,
}) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    changePage(1)
    setValue(newValue);
  };

  return (
    <>
      <TabContext value={value}>
        <TabList
          onChange={handleChange}
          aria-label="courses tabs"
          sx={classes.tabContainer}
        >
          <Tab label="Unpublished Course" value="1" style={{ textTransform: "none" }} />
          <Tab label="Published Course" value="2"  style={{ textTransform: "none" }}/>
        </TabList>
        <TabPanel value="1">
          <CoursesLayout type="UNPUBLISHED" />
        </TabPanel>
        <TabPanel value="2">
          <CoursesLayout type="PUBLISHED" />
        </TabPanel>
      </TabContext>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={5000}
        onClose={() => toggleAlert("success", "", false)}
      >
        <Alert
          onClose={() => toggleAlert("success", "", false)}
          severity={alertType}
          sx={classes.alert}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAlertOpen: state.CoursesAdminReducer.isAlertOpen,
  alertType: state.CoursesAdminReducer.alertType,
  alertMessage: state.CoursesAdminReducer.alertMessage,
});

const mapDispatchToProps = (dispatch) => ({
  toggleAlert: (type, message, value) =>
    dispatch(toggleAlert(type, message, value)),
  changePage: (pageNumber) => dispatch(changePage(pageNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoursesLandingPage);
