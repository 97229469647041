import axios from "axios";
import {
    //   fetchWEBINARSFailure,
    //   fetchWEBINARSRequest,
    fetchWebinarsSuccess,

    //   toggleAlert,
} from "../../ActionCreators/webinarsAdminActionCreators";
import {
    TOGGLE_VET_WEBINAR_ENROLLMENT_STATUS
} from "../../ActionTypes/webinarsVetActionTypes"

import { toggleGlobalAlert } from "../../ActionCreators/alertsActionCreators";

// const GET_WEBINARS_URL =
//   process.env.REACT_APP_API_URL + "/WEBINARS/admin/course_homepage/?page=";


export const addWebinarQuery = (id, category, query) => async dispatch => {

    try {

        let url = process.env.REACT_APP_API_URL + `/vetwebinars/webinars_query/`

        let reqObj = {
            "module_id": id,
            "content_type": "Webinars",
            "query_category": category,
            "query_text": query
        }



        let result = await axios.post(url, reqObj)

        if (result.data) {




            dispatch(toggleGlobalAlert("success", "Query posted successfully", true))





        }
        else {

            dispatch(toggleGlobalAlert("success", "Something went wrong", true))
        }



    }
    catch (err) {
        dispatch(toggleGlobalAlert("success", "Something went wrong", true))
    }
}


