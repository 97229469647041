import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';

import MappingObj from "./LandingPageConstants";

const GraphComponent = ({chartData, chartType, stacked, currFilterValue, chartKey, isLegendVisible}) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
      );

      const colorArray = ['rgb(179 243 234)','rgb(128 128 207)'];      
    
    //   function for preparing graph data as per the data type, chart type and chart properties
      const getGraphData = (currGraphData, currChartType, isStacked, dropdownValue, currChartKey) => {
        // if(currGraphData || Object.keys(currGraphData).length>0 || currGraphData!=null || currGraphData!=undefined){}
        //check for object or array
        let labelArray = []; //array for storing x-axis labels
        let dataArray = []; // array for storing series data 1
        let dataArray2 = []; // array for storing series data 1
        let datasetsArray = []; // array for storing collection of series for graph
        // checking for data availability
        if(Object.keys(currGraphData).length>0 || currGraphData.length>0){
            //checking for object data type
            if((!!currGraphData) && (currGraphData.constructor === Object)){
                //filtering data as per dropdown value
                let filteredGraphData = currGraphData[dropdownValue];
                //pushing labels and values
                filteredGraphData.map(e=> {
                    labelArray.push(e.values);
                    dataArray.push(e.counts);
                })

                //pushing series data into main array
                datasetsArray = [
                    {
                        label: MappingObj[currChartKey]['series_name'],
                        data: dataArray,
                        backgroundColor:colorArray[0]
                    }
                ]
        
            }
            //checking for array data type
            if((!!currGraphData) && (currGraphData.constructor === Array)){
                // for simple bar chart
                if(stacked==false){
                    //pushing labels and values
                    currGraphData.map(e => {
                        labelArray.push(e.values);
                        dataArray.push(e.counts)
                    })

                    //pushing series data into main array
                    datasetsArray = [
                        {
                            label: MappingObj[currChartKey]['series_name'],
                            data: dataArray,
                            backgroundColor:colorArray[0]
                        }
                    ]
                }
                // for stacked bar chart
                if(stacked){
                    //pushing labels and values
                    currGraphData.map(e => {
                        labelArray.push(e.WEBINAR_TITLE);
                        dataArray.push(e.ATTENDED);
                        dataArray2.push(e.REGISTERED);
                    })

                    //pushing series data into main array
                    datasetsArray.push({
                        label: 'Registered',
                            data: dataArray2,
                            backgroundColor:colorArray[1]
                    })

                    datasetsArray.push({
                        label: 'Attended',
                            data: dataArray,
                            backgroundColor:colorArray[0]
                    })
                    
                }
            }
    }
        return {
            labelArray, datasetsArray
        }
    }

    useEffect(() => {

    },[])
    
    const finalGraphData = getGraphData(chartData,chartType,stacked,currFilterValue, chartKey);

    const {labelArray, datasetsArray} = finalGraphData;

    const options = {
        responsive: true,
        
        scales: {
            y: {
                stacked: stacked,
              title: {
                display: true,
                text: MappingObj[chartKey]['y_axis_title']
              },
            },
            x:{
                stacked: stacked,
                title: {
                    display: true,
                    text: MappingObj[chartKey]['x_axis_title'],
                    fontSize:12,
                  },
                  ticks:{
                      
                      callback: function(value){
                          const trimmedLabel = labelArray[value] ? labelArray[value].length>12 ? (labelArray[value]).substring(0,10)+"..." : labelArray[value] : "";
                          return trimmedLabel;
                      }
                  }
            }
          },
        plugins: {
          legend: {
            position: 'bottom',
            display:isLegendVisible
          },
          title: {
            display: false,
            text: ''
          },
          tooltip:{
              intersect: true
          }
        }
      }
      const labels = labelArray;
      const dataObj = {
        labels,
        datasets: datasetsArray
      }

    return(
        <>
            {datasetsArray.length>0 ? <Bar options={options} data={dataObj}/> : <div className="no-data-message">No Data to display</div>}
            {/* {chartType=="pie" ?} */}
        </>
    )


  }

  const mapStateToProps = (state) => ({
   
})

const mapDispatchToProps = (dispatch) => ({
    
})

export default connect(mapStateToProps, mapDispatchToProps)(GraphComponent);