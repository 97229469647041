import axios from "axios";
import {
  fetchFilterValuesFailure,
  fetchFilterValuesRequest,
  fetchFilterValuesSuccess,
  // toggleAlert,
} from "../../ActionCreators/webinarsVetActionCreators";

const GET_FILTER_VALUES_URL =
  process.env.REACT_APP_API_URL + "/vetwebinars/webinars_dropdown/";


export const getFilterValues = () => (dispatch) => {
  dispatch(fetchFilterValuesRequest());
  axios
    .get(GET_FILTER_VALUES_URL)
    .then((response) => {
      if (response.status === 200) {

        let modResponse = {
          category: response.data.webinar_category, country: response.data.webinar_country,
          species: response.data.webinar_species, language: response.data.webinar_language, show_content_from: response.data.duration,
          creator: response.data.webinar_speaker
        }

        dispatch(fetchFilterValuesSuccess(modResponse));
      }

      else {
       
        dispatch(fetchFilterValuesFailure("Something went wrong !"));
        // dispatch(toggleAlert("error", "Something went wrong !", true));
      }
    })
    .catch((error) => {
      
      dispatch(fetchFilterValuesFailure(error.message));
      // dispatch(toggleAlert("error", error.message, true));
    });
};
