export const classes = {
    card: {
      background: '#FAFAFA',
      // cursor: 'pointer',
      margin: '28px 0px',
      borderRadius: '8px',
      padding: '12px 12px 12px 44px',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
    },
    chipStyle: {
      color: '#fff',
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '16px',
    },
    textarea: {
      background: '#FFFFFF',
      border: '1px solid #EBEBEB',
      boxSizing: 'border-box',
      borderRadius: '8px',
    },
    checkbox: {
      left: '-44px',
      top: '50%',
      position: 'absolute',
      transform: 'translateY(-50%)',
    }
  };
  