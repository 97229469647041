export default {
    register() {
        if ("serviceWorker" in navigator)
            navigator.serviceWorker
                .register(process.env.PUBLIC_URL + "/sw.js")
                .then(() => {})
                .catch((error) =>
                    console.log("Service worker registration failed:", error)
                );
        else console.log("Service workers are not supported.");
    },
};
