import React from "react";
import {
  Typography,
  Grid,
  FormHelperText,
  Autocomplete,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { classes } from "./styles";

const AddCreator = ({
  changeCourseDetails,
  checkForLength,
  courseDetails,
  speakerNumber,
  trimTextOnBlur
}) => {
  const handleDeleteCreator = (speakerNumber) => {
    if (courseDetails.data.speaker.length === 1) return;
    changeCourseDetails("", "", true, true, "delete", speakerNumber);
  };

  return (
    <Grid container spacing={2} sx={classes.addCreatorDiv}>
      <Grid item xs={10}>
        <Typography
          align="left"
          variant="subtitle2"
          noWrap
          sx={classes.subHeading}
        >
          Creator No. {speakerNumber + 1}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Tooltip title="Delete Creator" arrow>
          <IconButton
            color="default"
            aria-label="delete creator"
            component="span"
            sx={classes.deleteCreatorButton}
            onClick={() => handleDeleteCreator(speakerNumber)}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={4} sx={classes.noPaddingTopGrid}>
        <FormHelperText id="creator_name_field">Creator's Name</FormHelperText>
        <TextField
         inputProps={{ maxLength: 100, }}
          size="small"
          id="creator_name_field"
          //   placeholder=""
          variant="outlined"
          className="creator_name_field"
          sx={classes.creatorNameField}
          //   InputProps={{
          //     startAdornment: (
          //       <InputAdornment position="start">
          //         <Search />
          //       </InputAdornment>
          //     ),
          //   }}
          value={courseDetails.data.speaker[speakerNumber]["speaker_name"]}
          onChange={(e, value) =>
            changeCourseDetails(
              "speaker_name",
              e.target.value,
              true,
              true,
              "edit",
              speakerNumber
            )
          }
          onBlur={(e, value) =>
            changeCourseDetails(
              "speaker_name",
              e.target.value.trim(),
              true,
              true,
              "edit",
              speakerNumber
            )
          }
        />
      </Grid>
      <Grid item xs={4} sx={classes.noPaddingTopGrid}>
        <FormHelperText id="creator_qualification_field">
          Creator's Qualification (optional)
        </FormHelperText>
        <TextField
          size="small"
          id="creator_qualification_field"
          //   placeholder=""
          variant="outlined"
          className="creator_qualification_field"
          sx={classes.creatorQualificationField}
          inputProps={{ maxLength: 30, }}
          //   InputProps={{
          //     startAdornment: (
          //       <InputAdornment position="start">
          //         <Search />
          //       </InputAdornment>
          //     ),
          //   }}
          value={
            courseDetails.data.speaker[speakerNumber]["speaker_qualification"]
          }
          onChange={(e, value) =>
            changeCourseDetails(
              "speaker_qualification",
              e.target.value,
              true,
              true,
              "edit",
              speakerNumber
            )
          }
          onBlur={(e, value) =>
            changeCourseDetails(
              "speaker_qualification",
              e.target.value.trim(),
              true,
              true,
              "edit",
              speakerNumber
            )
          }


        />
      </Grid>
      <Grid item xs={12}>
        <FormHelperText id="creator_description_field">
          Creator's Description (optional)
        </FormHelperText>
        <TextField
          size="small"
          id="creator_description_field"
          //   placeholder=""
          multiline
          inputProps={{ maxLength: 100, }}
          variant="outlined"
          className="creator_description_field"
          sx={classes.creatorDescriptionField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {
                  courseDetails.data.speaker[speakerNumber][
                    "speaker_description"
                  ].length
                }{" "}
                / 100
              </InputAdornment>
            ),
          }}
          inputProps={{ maxLength: 300, }}
          value={
            courseDetails.data.speaker[speakerNumber]["speaker_description"]
          }
          onChange={(e) =>
            checkForLength(e, "speaker_description", speakerNumber)
          }
          onBlur={(e) => trimTextOnBlur(e, "speaker_description",speakerNumber)}
        />
      </Grid>
    </Grid>
  );
};

export default AddCreator;
