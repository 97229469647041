export const classes = {
  search: { width: "25%", margin: "1% 0", marginTop: 0 },
  grid: { marginBottom: "2%" },
  filter: { width: "100%", margin: "5% 0", marginTop: 0 },
  filterButton: { 
    // margin: "11% 0", 
    marginTop:"24px",
    width: "100%", fontSize: "80%", textTransform:"none" },
  pagination: { margin: "3% 0", justifyContent: "center", display: "flex" },
  noCourseDisclaimer: { textAlign: "center", margin: "3%", width: "100%" },
  clearAllButton: { marginTop: "10%" },
  biggerSkeleton: { margin: "2%", height: "100px", borderRadius: "5px" },
  skeleton: { margin: "2%", borderRadius: "5px" },
  smallerSkeleton: { margin: "2%", width: "70%", borderRadius: "5px" },
  courseLayout: { margin: "0 2%" },
  newCourseButton: { float: "right" },
};
