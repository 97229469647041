export const FETCH_MANAGEMENT_DATA="FETCH_MANAGEMENT_DATA"
export const SET_MANAGEMENT_DATA="SET_MANAGEMENT_DATA"
export const MANAGEMENT_DATA_FAIL="MANAGEMENT_DATA_FAIL"
export const FETCH_MANAGEMENT_FILTERS="FETCH_MANAGEMENT_FILTERS"
export const SET_MANAGEMENT_FILTERS="SET_MANAGEMENT_FILTERS"
export const MANAGEMENT_FILTERS_FAIL="MANAGEMENT_FILTERS_FAIL"
export const CLEAR_MANAGEMENT_DATA="CLEAR_MANAGEMENT_DATA"
export const CLEAR_MANAGEMENT_FILTER_DATA="CLEAR_MANAGEMENT_FILTER_DATA"
export const SET_SELECTED_FILTERS="SET_SELECTED_FILTERS"
export const SET_USER_MANAGEMENT_NAVIGATION="SET_USER_MANAGEMENT_NAVIGATION"
export const SET_USER_MANAGEMENT_LOADING_FALSE="SET_USER_MANAGEMENT_LOADING_FALSE"
export const SET_CATEGORIES_DATA="SET_CATEGORIES_DATA"
export const SET_USER_MANAGEMENT_CATEGORIES_NAVIGATION="SET_USER_MANAGEMENT_CATEGORIES_NAVIGATION"
export const SET_TAB_VALUE = "SET_TAB_VALUE"
export const SET_EXPORT_DATA="SET_EXPORT_DATA"

