import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Grid,
    TextField,
    Autocomplete,
    Button,
    Card,
    CardContent,
    Typography,
    Pagination,
    IconButton,
    MenuItem,
    Menu,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Rating,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { MoreVert, Delete } from "@mui/icons-material";
import { classes } from "./styles";

const ArticleBoard = ({ article, index }) => {

    const history = useHistory()
    return (
        <Card sx={classes.card} onClick={() => { history.push(`/article/${article.learning_content_id}`) }}>
            <CardContent sx={{padding: '12px !important'}}>
                <Typography sx={classes.cardContent} gutterBottom>
                    {article.file_title}
                </Typography>
                <Typography sx={classes.cardText}>
                    {article.last_updated_date}
                </Typography>
                <Typography sx={classes.cardText}>
                    {article.file_type}
                </Typography>
                <Rating
                    name="read-only"
                    value={article.avg_rating}
                    readOnly
                    sx={classes.rating}
                />
            </CardContent>
        </Card>
    );
};

export default ArticleBoard;
