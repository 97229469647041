import React, { useState } from "react";
import {
  FormControl,
  Typography,
  InputLabel,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px",
  },
}));

const PostQuery = () => {
  const CHARACTER_LIMIT = 240;
  const [values, setValues] = React.useState({
    name: "",
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };
  return (
    <div style={{ margin: "10px" }}>
      <Typography>
        <b>Add New Query</b>
      </Typography>
      <FormControl
        variant="standard"
        style={{ margin: "20px 0px", width: "100%" }}
      >
        <InputLabel shrink htmlFor="bootstrap-input">
          Query
        </InputLabel>
        <TextField
          style={{ marginTop: "30px" }}
          input={<BootstrapInput />}
          multiline
          placeholder="Type your query here"
          maxRows={4}
          value={values.name}
          helperText={`${values.name.length}/${CHARACTER_LIMIT}`}
          onChange={handleChange("name")}
        />
      </FormControl>
      <Button variant="outlined">SUBMIT</Button>
    </div>
  );
};

export default PostQuery;
