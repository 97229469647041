import React, { useEffect, useState } from "react";
import Dropdowns from "./Dropdowns";
import SearchWithExport from "./SearchWithExport";
import { WebinarAdminCard } from "../Utils/WebinarCard";
import { Grid, Pagination, Skeleton,Button,Dialog, DialogContent,DialogActions } from "@mui/material";
import { connect } from "react-redux";
import { changePage } from "../../Redux/ActionCreators/webinarsAdminActionCreators";
import { getWebinars } from "../../Redux/Middlewares/WebinarsAdmin/getWebinarsMiddleware";
import { deleteWebinar } from "../../Redux/Middlewares/WebinarsAdmin/deleteWebinarMiddleware";
import { SET_WEB_EDIT } from "../../Redux/ActionTypes/webinarsAdminActionTypes";
import { Redirect, useHistory } from "react-router-dom";
import { getWebinarForEdit } from "../../Redux/Middlewares/WebinarsAdmin/getWebinarForEditMiddleware";


const UpcomingWebinar = ({ pageNumber, getWebinars, webinarList, changePage,
  current, totalCount, isLoading, dataLoading, deleteWebinar, selectedFilters, toggleEdit, editFlow, getWebinarForEdit,
  from, to }) => {

  const [searchText, setSearchText] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);

  const handlePageChange = (event, value) => {
    changePage(value);
    getWebinars(value);
  };

  const handleWebinarFinalDelete = () => {
   deleteWebinar(deleteId,requestObject);
    setOpenDialog(false);
  };

  let requestObject =
  {
    "status": "UPCOMING",
    "search": [searchText],
    "speaker": selectedFilters && selectedFilters.creator && selectedFilters.creator.length > 0 ? selectedFilters.creator : [],
    "category": selectedFilters && selectedFilters.category && selectedFilters.category.length > 0 ? selectedFilters.category : [],
    "species": selectedFilters && selectedFilters.species && selectedFilters.species.length > 0 ? selectedFilters.species : [],
    "show_webinar_from": {
      "fixed": selectedFilters && selectedFilters.show_content_from.value === "custom" ? null :
        selectedFilters.show_content_from.value ? selectedFilters.show_content_from.value : "All time",
      "start_date": selectedFilters && selectedFilters.show_content_from.value === "custom" ? from : null,
      "end_date": selectedFilters && selectedFilters.show_content_from.value === "custom" ? to : null
    }
  }

  const handleApplyFiltersClick = () => {
    // changePage(1);


    getWebinars(requestObject);
  };



  const handlePagination = (e, v) => {


    getWebinars(requestObject, v)

  }
  const history = useHistory()

  useEffect(() => {
    if (editFlow === 1) {

      history.push("/createWebinar")
    }
    else {
      getWebinars(requestObject);
    }

  }, [editFlow]);





  const classes = {
    search: { width: "25%", margin: "1% 0", marginTop: 0 },
    grid: { marginBottom: "2%" },
    filter: { width: "100%", margin: "5% 0", marginTop: 0 },
    filterButton: { margin: "11% 0", width: "100%", fontSize: "80%" },
    pagination: { margin: "3% 0", justifyContent: "center", display: "flex" },
    noCourseDisclaimer: { textAlign: "center", margin: "3%", width: "100%" },
    clearAllButton: { marginTop: "10%" },
    biggerSkeleton: { margin: "2%", height: "100px", borderRadius: "5px" },
    skeleton: { margin: "2%", borderRadius: "5px" },
    smallerSkeleton: { margin: "2%", width: "70%", borderRadius: "5px" },
    courseLayout: { margin: "0 2%" },
    newCourseButton: { float: "right" },
  };

  let primaryContent;
  if (dataLoading) {
    primaryContent = (
      <Grid container>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" sx={classes.biggerSkeleton} />
          <Skeleton variant="rectangular" sx={classes.skeleton} />
          <Skeleton variant="rectangular" sx={classes.smallerSkeleton} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" sx={classes.biggerSkeleton} />
          <Skeleton variant="rectangular" sx={classes.skeleton} />
          <Skeleton variant="rectangular" sx={classes.smallerSkeleton} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" sx={classes.biggerSkeleton} />
          <Skeleton variant="rectangular" sx={classes.skeleton} />
          <Skeleton variant="rectangular" sx={classes.smallerSkeleton} />
        </Grid>
      </Grid>
    )
  }
  else {
    primaryContent = (<>
      <Grid
        container
        spacing={3}
        className="content_section"
        justifyContent="left"
      >
        {webinarList &&
          webinarList.length > 0 &&
          webinarList.map((element, index) => (
            <WebinarAdminCard webinar={element} index={index}
              requestObject={requestObject} deleteWebinar={deleteWebinar} toggleEdit={toggleEdit}
               getWebinarForEdit={getWebinarForEdit}  setDeleteId={setDeleteId} setOpenDialog={setOpenDialog}/>
          ))}
      </Grid>
      {webinarList && totalCount > 0 && (Math.ceil(Number(totalCount) / 10) > 1 &&
        <Pagination
          count={Math.ceil(Number(totalCount) / 10)}
          page={current}
          onChange={handlePagination}
          sx={{ margin: "3% 0", justifyContent: "center", display: "flex" }}
          color="primary"
          hidePrevButton
          hideNextButton
        />
      )}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent sx={classes.dialogContent}>
          Do you want to delete the webinar?
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleWebinarFinalDelete}>
            Yes
          </Button>
          <Button variant="outlined" onClick={() => setOpenDialog(false)}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>)
  }

  if (webinarList &&
    webinarList.length < 1) {
    primaryContent = (<>No webinars</>)
  }

  return (
    <>
      <SearchWithExport searchText={searchText} setSearchText={setSearchText} handleApplyFiltersClick={handleApplyFiltersClick} />
      <Dropdowns type="UPCOMING" handleApplyFiltersClick={handleApplyFiltersClick} getWebinars={getWebinars} setSearchText={setSearchText} />
      <div
        style={{
          display: "inline-flex",
          marginBottom: "10px",
          marginTop: "10px",
          alignItems: "center",
          width: "100%",
        }}
      >
        <span style={{ marginLeft: "10px" }}>
          {/* <b>September</b> */}
        </span>
        <hr />
        <br />
        <br />
        <br />
      </div>
      {primaryContent}
    </>
  );
};

const mapStateToProps = (state) => ({
  webinarList: state.WebinarsAdminReducer.webinars,
  pageNumber: state.WebinarsAdminReducer.pageNumber,
  current: state.WebinarsAdminReducer.current,
  totalCount: state.WebinarsAdminReducer.totalCount,
  isLoading: state.WebinarsAdminReducer.isLoading,
  dataLoading: state.WebinarsAdminReducer.dataLoading,
  selectedFilters: state.WebinarsAdminReducer.selectedFilters,
  editFlow: state.WebinarsAdminReducer.editFlow,
  from: state.WebinarsAdminReducer.from,
  to: state.WebinarsAdminReducer.to
});

const mapDispatchToProps = (dispatch) => ({
  getWebinars: (obj, pageNumber) => dispatch(getWebinars(obj, pageNumber)),
  changePage: (pageNumber) => dispatch(changePage(pageNumber)),
  deleteWebinar: (webinarId, obj) => dispatch(deleteWebinar(webinarId, obj)),
  toggleEdit: (val) => dispatch({ type: SET_WEB_EDIT, payload: val }),
  getWebinarForEdit: (webId) => dispatch(getWebinarForEdit(webId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingWebinar);
