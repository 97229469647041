import axios from "axios";
import {
    callCompleted,
    catchError,
    startCall,
} from "../ActionCreators/queriesVetActionCreators";
import { toggleGlobalAlert } from "../ActionCreators/alertsActionCreators";

const baseUrl = process.env.REACT_APP_API_URL;

export const fetchVetQueriesDropdowns = () => (dispatch) => {
    dispatch(startCall());
    axios
        .get(`${baseUrl}/vetQueries/dropdown/`)
        .then((response) =>{
            dispatch(callCompleted({ dropdowns: response.data }))
        })
        .catch((error) => {
            error.clientMessage = "Failed to fetch vet queries dropdowns";
            dispatch(catchError(error));
        });
};

export const fetchVetQueryDetails = (request, pageNumber=1) => async dispatch => {
    dispatch(startCall());
        try {
            dispatch(startCall())
            let url = baseUrl+`/vetQueries/querydetails/?page=`+pageNumber;
            let response = await axios.post(url, request);
            dispatch(callCompleted({
                queryDetails: response.data
            }));
        }
        catch (err) {
                err.clientMessage = "Failed to get query details";
                dispatch(catchError(err));
        }
};

export const createVetQuery = (queryObj, filterObj,pageNumber=1) => async dispatch => {
        dispatch(startCall());
        try {
            dispatch(startCall())
            let url = baseUrl+`/vetQueries/addquery/`;
            let response = await axios.post(url, queryObj);
                dispatch(callCompleted({
                     addedQuery: response.data 
                    }))
            if (response.data) {
                let url2 = baseUrl+`/vetQueries/querydetails/?page=`+pageNumber;
                let response2 = await axios.post(url2, filterObj);
                dispatch(callCompleted({
                    queryDetails: response2.data
                }));
                dispatch(toggleGlobalAlert("success", "Query posted successfully", true))
                
            }
        }
        catch (err) {
                err.clientMessage = "Failed to add query";
                dispatch(catchError(err));
        }
};

export const updateVetQueryReply = (replyObj, filterObj, pageNumber=1) => async dispatch => {
    dispatch(startCall());
    try {
        dispatch(startCall());
        let url = baseUrl+`/vetQueries/replyqueries/`;
        let response = await axios.post(url,replyObj);
        dispatch(callCompleted({
            repliedQuery: response.data
        }))
        if (response.data) {
                            let url2 = baseUrl+`/vetQueries/querydetails/?page=`+pageNumber;
                            let response2 = await axios.post(url2, filterObj);
                            dispatch(callCompleted({
                                queryDetails: response2.data
                            }));
                            
            }
        
    }
    catch (err){
        err.clientMessage = "Failed to add query";
        dispatch(catchError(err));
    }
}