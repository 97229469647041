import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CoursesView from "./CoursesView";
import { connect } from "react-redux"
import { makeStyles } from "@mui/styles";
import {
    Grid, TextField, InputAdornment, Chip, Drawer, MenuItem, Select, OutlinedInput,
    Stack, Button, Tooltip, IconButton,
} from "@mui/material"
import { LoadingButton } from "@mui/lab";
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ScrollToTopButton from "../VetLearningContent/ScrollToTopButton"
import { getVetCourses } from ".././Redux/Middlewares/VetCourses/getCoursesVetMiddleware"
import SpinnerLG from "../SpinnerLG";
import { Autocomplete } from "@mui/material"
import { getFiltersVetCourses } from "../Redux/Middlewares/VetCourses/getFiltersVetCoursesMiddleware";
import { SET_USER_COURSE_FILTER_DATA, CLEAR_USER_COURSE_FILTER_DATA } from "../Redux/ActionTypes/coursesVetActionTypes"
import { addQueryCourses } from "../Redux/Middlewares/VetCourses/addQueryCoursesMiddleware"
import { addBookmarkCourses } from "../Redux/Middlewares/VetCourses/addBookmarkCoursesMiddleware"
import { ClearAll } from "@mui/icons-material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';


const useStyles = makeStyles((theme) => ({
    customTabRoot: {
        color: "black",
    },
    customTabIndicator: {
        backgroundColor: theme.palette.secondary.main,
    },
    selected: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
    },
    tabLabel: {
        fontSize: 16
    },
    

}));

const applyButtonStyle = { marginTop: "24px", width: "100%", fontSize: "80%",textTransform:"none" }

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};




const CoursesLayout = ({ getVetCourses, coursesList, isLoading, getFiltersVetCourses, filterData,
    timeFilter, authorFilter, speciesFilter, countryFilter, categoryFilter, setFilter, currentPage, totalCount,
    addBookmarkCourses, addQueryCourses, }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [dateFilter, setDateFilter] = React.useState(timeFilter);
    const [category, setCategory] = React.useState(categoryFilter);
    const [author, setAuthor] = React.useState(authorFilter);
    const [species, setSpecies] = React.useState(speciesFilter);
    const [country, setCountry] = React.useState(countryFilter);
    

    const [state, setState] = React.useState(false);


    const [searchText, setSearchText] = React.useState("");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    //getting the courses data on component mount
    useEffect(() => {
        let temp = {
            "course_author": author.length > 0 ? author.map(item => item.speaker_name) : [],
            "course_category": category.length > 0 ? category.map(item => item.category_name) : [],
            "course_species": species.length > 0 ? species.map(item => item.value) : [],
            "course_language": [],
            "search_value": [searchText],
            "all_courses": value === 0 ? true : false,
            "show_content_from": {
                "fixed": dateFilter[0],
                "start_date": null,
                "end_date": null
            }
        }


        getVetCourses(temp)

        // getFiltersLC()

    }, [value])

    useEffect(() => {
        getFiltersVetCourses()
        return () => {
            clearFilterOnUnmount()
        }
    }, [])

    const handlePagination = (e, v) => {
        let temp = {
            "course_author": author.length > 0 ? author.map(item => item.speaker_name) : [],
            "course_category": category.length > 0 ? category.map(item => item.category_name) : [],
            "course_species": species.length > 0 ? species.map(item => item.value) : [],
            "course_language": [],
            "search_value": [searchText],
            "all_courses": value === 0 ? true : false,
            "show_content_from": {
                "fixed": dateFilter[0],
                "start_date": null,
                "end_date": null
            }
        }

        getVetCourses(temp, v)

    }

    const clearFilter = () => {




        let temp = {
            "course_author": [],
            "course_category": [],
            "course_species": [],
            "course_language": [],
            "search_value": [searchText],
            "all_courses": value === 0 ? true : false,
            "show_content_from": {
                "fixed": "All time",
                "start_date": null,
                "end_date": null
            }
        }

        setFilter({ categoryFilter: [], authorFilter: [], speciesFilter: [], timeFilter: "All time", countryFilter: [] })

        setDateFilter(["All time"])
        setCategory([])
        setAuthor([])
        setSpecies([])
        // setCountry([])

        getVetCourses(temp)

    }

    const clearFilterOnUnmount = () => {




        let temp = {
            "course_author": [],
            "course_category": [],
            "course_species": [],
            "course_language": [],
            "search_value": [searchText],
            "all_courses": value === 0 ? true : false,
            "show_content_from": {
                "fixed": "All time",
                "start_date": null,
                "end_date": null
            }
        }

        setFilter({ categoryFilter: [], authorFilter: [], speciesFilter: [], timeFilter: "All time", countryFilter: [] })

        setDateFilter(["All time"])
        setCategory([])
        setAuthor([])
        setSpecies([])
        // setCountry([])



    }


    const handleDate = (event) => {
        const {
            target: { value },
        } = event;
        setDateFilter(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleSearch = () => {
        let temp = {


            "course_author": author.length > 0 ? author.map(item => item.speaker_name) : [],
            "course_category": category.length > 0 ? category.map(item => item.category_name) : [],
            "course_species": species.length > 0 ? species.map(item => item.value) : [],
            "course_language": [],
            "search_value": [searchText],
            "all_courses": value === 0 ? true : false,
            "show_content_from": {
                "fixed": dateFilter[0],
                "start_date": null,
                "end_date": null
            }
        }
        getVetCourses(temp)

    }

    const saveFilter = (item) => {
        let temp = {


            "course_author": author.length > 0 ? author.map(item => item.speaker_name) : [],
            "course_category": category.length > 0 ? category.map(item => item.category_name) : [],
            "course_species": species.length > 0 ? species.map(item => item.value) : [],
            "course_language": [],
            "search_value": [searchText],
            "all_courses": value === 0 ? true : false,
            "show_content_from": {
                "fixed": dateFilter[0],
                "start_date": null,
                "end_date": null
            }
        }
        setFilter({ categoryFilter: category, authorFilter: author, speciesFilter: species, timeFilter: dateFilter, countryFilter: country })
        toggleDrawer(item)
        getVetCourses(temp)

    }



    const toggleDrawer = (val) => {
        setState(val);
    };


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const list = (anchor) => (filterData.length !== 0 && (
        <Box
            sx={{ width: "auto", height: "100vh", padding: 2 }}
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
        >
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <Typography sx={{ color: "#0f01a0", fontSize: 16, fontWeight: 700 }}>Filters</Typography>
                </Grid>
                <Grid item container xs={6} justifyContent="right">
                    <Typography onClick={() => clearFilter()} sx={{ color: "#4d4d4d" }}>Reset filters</Typography>
                </Grid>
                {/* 
                <Grid item xs={12}>
                    <Typography sx={{ color: "#4d4d4d", marginBottom: 1 }}>Show content from</Typography>
                    <TextField
                        fullWidth
                        size="small"
                        id="contentTime"
                        select
                        defaultValue="All time"
                        value={dateFilter}
                        onChange={handleDate}
                    >
                       
                    </TextField>


                </Grid> */}
                <Grid item xs={12}>
                    <Typography sx={{ color: "#4d4d4d", marginBottom: 1 }}>Show content from</Typography>

                    <TextField
                        fullWidth
                        size="small"
                        id="contentTime"
                        select
                        defaultValue="All time"
                        value={dateFilter}
                        onChange={handleDate}
                    >
                        {filterData.duration.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.value}
                            </MenuItem>
                        ))}
                    </TextField>

                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{ color: "#4d4d4d", marginBottom: 1 }}>Category</Typography>
                    <Autocomplete

                        multiple
                        disableCloseOnSelect
                        fullWidth
                        size="small"
                        // value={category}
                        options={filterData.course_category}
                        getOptionLabel={(option) => option.category_name}
                        limitTags={2}
                        value={category}
                        onChange={(event, newValue) => {

                            setCategory([

                                ...newValue
                            ]);
                        }}


                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.category_name}
                                    {...getTagProps({ index })}

                                />
                            ))
                        }
                        // style={{ width: 500 }}
                        renderInput={(params) => (
                            <TextField {...params} placeholder="category" />
                        )}
                    />



                </Grid>

                <Grid item xs={12}>
                    <Typography sx={{ color: "#4d4d4d", marginBottom: 1 }}>Course creator</Typography>


                    <Autocomplete

                        multiple
                        disableCloseOnSelect
                        fullWidth
                        size="small"
                        // value={category}
                        options={filterData.course_author}
                        getOptionLabel={(option) => option.speaker_name}
                        limitTags={2}
                        value={author}
                        onChange={(event, newValue) => {

                            setAuthor([

                                ...newValue
                            ]);
                        }}


                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.speaker_name}
                                    {...getTagProps({ index })}

                                />
                            ))
                        }
                        // style={{ width: 500 }}
                        renderInput={(params) => (
                            <TextField {...params} placeholder="creator" />
                        )}
                    />


                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{ color: "#4d4d4d", marginBottom: 1 }}>Species</Typography>

                    <Autocomplete

                        multiple
                        disableCloseOnSelect
                        fullWidth
                        size="small"
                        // value={category}
                        options={filterData.course_species}
                        getOptionLabel={(option) => option.value}
                        limitTags={2}
                        value={species}
                        onChange={(event, newValue) => {

                            setSpecies([

                                ...newValue
                            ]);
                        }}


                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.value}
                                    {...getTagProps({ index })}

                                />
                            ))
                        }
                        // style={{ width: 500 }}
                        renderInput={(params) => (
                            <TextField {...params} placeholder="species" />
                        )}
                    />


                </Grid>

            </Grid>


            <Grid container sx={{ marginTop: 6 }} justifyContent="flex-end" alignItems="flex-end">
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                        <Button style={{ textTransform: 'none' }} fullWidth variant="outlined" onClick={() => { toggleDrawer(false) }}> Close</Button>
                        <Button style={{ textTransform: 'none' }} fullWidth variant="contained" onClick={() => saveFilter(false)}> Apply</Button>
                    </Stack>
                </Grid>

            </Grid>

        </Box>
    ));



    return (
        <Box sx={{ width: "100%" }}>
            <Grid container sx={{ padding: 4 }}>
                <Grid item xs={12}>
                    <Typography sx={{ marginLeft: 1, marginBottom: 1, fontSize: 24, fontWeight: 700, color: "#0f01a0" }}>Courses</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Tabs
                        sx={{ marginBottom: 1, }}
                        value={value}
                        onChange={handleChange}
                        textColor="inherit"
                        classes={{
                            root: classes.customTabRoot,
                            indicator: classes.customTabIndicator,
                        }}
                    >
                        <Tab
                            sx={{ marginTop: 1 }}
                            style={{ textTransform: "none" }}
                            classes={{
                                selected: classes.selected,
                            }}
                            label={<span className={classes.tabLabel}>Explore courses</span>}
                        />
                        <Tab
                            sx={{ marginTop: 1 }}
                            style={{ textTransform: "none", }}
                            classes={{
                                selected: classes.selected,
                            }}
                            label={<span className={classes.tabLabel}>My courses</span>}
                        />

                    </Tabs>
                </Grid>
                <Grid container md={3} justifyContent="left" sx={{ display: { xs: "none", md: "flex" } }}>

                    <TextField
                        sx={{ marginTop: 2 }}
                        fullWidth
                        id="input-with-icon-textfield"
                        placeholder="Search courses..."
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon onClick={() => handleSearch()} />
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        size="small"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />


                </Grid>
                <Grid container md={12} sx={{ display: { xs: "none", md: "flex" }, marginTop: 4 }} >
                    <Grid container md={12} spacing={2} direction="row" alignItems="center">
                        <Grid item sm={2}>
                            <Typography sx={{ color: "#4d4d4d", marginBottom: 1, fontSize: 14 }}>Show content from</Typography>

                            <TextField
                                fullWidth
                                size="small"
                                id="contentTime"
                                select
                                defaultValue="All time"
                                value={dateFilter}
                                onChange={handleDate}
                            >
                                {filterData.duration?.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.value}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </Grid>
                        <Grid item sm={2}>
                            <Typography sx={{ color: "#4d4d4d", marginBottom: 1, fontSize: 14 }}>Category</Typography>
                            <Autocomplete

                                multiple
                                disableCloseOnSelect
                                fullWidth
                                size="small"
                                // value={category}
                                options={filterData.course_category ? filterData.course_category : []}
                                getOptionLabel={(option) => option.category_name}
                                limitTags={1}
                                value={category}
                                onChange={(event, newValue) => {

                                    setCategory([

                                        ...newValue
                                    ]);
                                }}


                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.category_name}
                                            {...getTagProps({ index })}

                                        />
                                    ))
                                }
                                // style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="category" />
                                )}
                            />



                        </Grid>

                        <Grid item sm={2}>
                            <Typography sx={{ color: "#4d4d4d", marginBottom: 1, fontSize: 14 }}>Course creator</Typography>


                            <Autocomplete

                                multiple
                                disableCloseOnSelect
                                fullWidth
                                size="small"
                                // value={category}
                                options={filterData.course_author ? filterData.course_author : []}
                                getOptionLabel={(option) => option.speaker_name}
                                limitTags={1}
                                value={author}
                                onChange={(event, newValue) => {

                                    setAuthor([

                                        ...newValue
                                    ]);
                                }}


                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.speaker_name}
                                            {...getTagProps({ index })}

                                        />
                                    ))
                                }
                                // style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="creator" />
                                )}
                            />


                        </Grid>
                        <Grid item sm={2}>
                            <Typography sx={{ color: "#4d4d4d", marginBottom: 1, fontSize: 14 }}>Species</Typography>

                            <Autocomplete

                                multiple
                                disableCloseOnSelect
                                fullWidth
                                size="small"
                                // value={category}
                                options={filterData.course_species ? filterData.course_species : []}
                                getOptionLabel={(option) => option.value}
                                limitTags={1}
                                value={species}
                                onChange={(event, newValue) => {

                                    setSpecies([

                                        ...newValue
                                    ]);
                                }}


                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.value}
                                            {...getTagProps({ index })}

                                        />
                                    ))
                                }
                                // style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="species" />
                                )}
                            />


                        </Grid>
                        <Grid item sm={2} md={2}>
                            <LoadingButton
                                variant="contained"
                                style={applyButtonStyle}
                                onClick={() => saveFilter(false)}
                                endIcon={<FilterAltIcon />}
                                loading={isLoading}
                                loadingPosition="end"
                            >
                                Apply
                            </LoadingButton>
                            
                        </Grid>
                        <Grid item sm={2}>
                            <Tooltip title="Clear Filters" arrow>
                                <IconButton
                                    color="primary"
                                    aria-label="clear filter"
                                    component="span"
                                    onClick={() => clearFilter()}
                                    style = { {marginTop: "10%" }}
                                >
                                    <ClearAll />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    {/* <Grid container md={4} spacing={2} alignItems="start">
                        <Button sx={{ marginLeft: 1, marginRight: 1 }} variant="outlined" style={{ textTransform: "none" }} onClick={() => saveFilter(false)}>
                            Apply
                        </Button>
                    </Grid> */}
                </Grid>
                <Grid item xs={8} sx={{ display: { xs: "flex", md: "none" } }}>

                    <TextField
                        sx={{ marginTop: 2 }}
                        fullWidth
                        id="input-with-icon-textfield"
                        placeholder="Search courses..."
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon onClick={() => handleSearch()} />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                        size="large"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />


                </Grid>
                <Grid container justifyContent="end" xs={4} sx={{ display: { xs: "flex", md: "none" }, marginTop: 2 }}>
                    <IconButton  onClick={() => toggleDrawer("bottom", true)}><FilterAltIcon />  <Chip label={categoryFilter.length + authorFilter.length + speciesFilter.length} size="small" /></IconButton>
                </Grid>
            </Grid >

            <TabPanel style={{ padding: '0px' }} value={value} index={0}>

                {isLoading ? (<SpinnerLG />) : (<CoursesView addBookmarkCourses={addBookmarkCourses}
                    addQueryCourses={addQueryCourses} currentPage={currentPage}
                    handlePagination={handlePagination} coursesList={coursesList}
                    totalCount={totalCount} />)}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {isLoading ? (<SpinnerLG />) : (<CoursesView
                    addBookmarkCourses={addBookmarkCourses}
                    addQueryCourses={addQueryCourses} currentPage={currentPage}
                    handlePagination={handlePagination} coursesList={coursesList}
                    totalCount={totalCount} />)}
            </TabPanel>


            {/* <Box onClick={() => toggleDrawer("bottom", true)} sx={{ width: "100%", height: 50, color: "white", backgroundColor: "#0f01a0", display: { xs: "flex", md: "none" }, bottom: "0%", position: "fixed" }}>
                <Typography sx={{ color: "white", zIndex: 2, bottom: "2vh", left: { xs: "28%", sm: "40%" }, position: "fixed", fontWeight: 600, fontSize: 18 }} >Add Filters</Typography>
                <FilterAltOutlinedIcon sx={{ zIndex: 2, bottom: "2vh", left: { xs: "54%", sm: "52%" }, position: "fixed" }} />
                <Chip sx={{ zIndex: 2, bottom: "2vh", left: { xs: "62%", sm: "55%" }, position: "fixed", color: "#000", backgroundColor: "white" }}
                    // label={categoryFilter.length + authorFilter.length + speciesFilter.length + contentFilter.length} size="small"
                    label={categoryFilter.length + authorFilter.length + speciesFilter.length} size="small" />
            </Box> */}
            <Drawer
                anchor={"bottom"}
                open={state}
                onClose={() => toggleDrawer("bottom", false)}
            >
                {list("bottom")}
            </Drawer>
            <Grid sx={{ backgroundColor: { sm: "none", md: "#b3b3b3" } }} container justifyContent="center" xs={12} sm={12} md={12} spacing={0}>

                <ScrollToTopButton showBelow={300} />
            </Grid>

        </Box>
    );
};



const mapStateToProps = (state) => ({
    // isAuthenticated: state.authenticationReducer.isAuthenticated,
    // userStatus: state.authenticationReducer.userStatus,
    coursesList: state.CoursesVetReducer.coursesList,
    isLoading: state.CoursesVetReducer.loading,
    totalCount: state.CoursesVetReducer.totalCount,
    currentPage: state.CoursesVetReducer.current,
    filterData: state.CoursesVetReducer.filterData,


    timeFilter: state.CoursesVetReducer.timeFilter,
    categoryFilter: state.CoursesVetReducer.categoryFilter,
    authorFilter: state.CoursesVetReducer.authorFilter,
    speciesFilter: state.CoursesVetReducer.speciesFilter,
    countryFilter: state.CoursesVetReducer.countryFilter


})

const mapDispatchToProps = dispatch => {
    return {

        getVetCourses: (reqObj, pageNumber) => dispatch(getVetCourses(reqObj, pageNumber)),
        getFiltersVetCourses: () => dispatch(getFiltersVetCourses()),
        addBookmarkCourses: (id, value, obj) => dispatch(addBookmarkCourses(id, value, obj)),
        addQueryCourses: (moduleId, queryCategory, queryText) => dispatch(addQueryCourses(moduleId, queryCategory, queryText)),
        setFilter: (data) => dispatch({ type: SET_USER_COURSE_FILTER_DATA, payload: data }),




    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoursesLayout);
