import { createProfileRequest } from "../ActionCreators/createProfileActionCreator"
import axios from "axios"
import { SET_STATUS_PROFILE_COMPLETE } from "../ActionTypes/authenticationActionTypes";
import { TOGGLE_PROCESS_LOADER, SET_GENERIC_ERROR_ALERT } from "../ActionTypes/alertsActionTypes";


//Create user profile 
export const createProfile = (profileForm) => dispatch => {




  const CREATE_PROFILE_URI = process.env.REACT_APP_API_URL + "/usermanagement/user_details/"

  try {
    dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: true } })


    var bodyFormData = new FormData();
    for (var key in profileForm) {
      bodyFormData.append(key, profileForm[key]);
    }
    const result = axios({
      method: "post",
      url: CREATE_PROFILE_URI,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: false } })
        dispatch({ type: SET_STATUS_PROFILE_COMPLETE, payload: response.data })
        dispatch(createProfileRequest(result))
       
        //handle success

       
      })
      .catch(function (response) {
        dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: false } })
        dispatch({ type: SET_GENERIC_ERROR_ALERT })
        //handle error
        
      });

    
    
   
    //dispatch(loadUser())

  }
  catch (err) {
   
    dispatch({ type: SET_GENERIC_ERROR_ALERT })
  }
}

