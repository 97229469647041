import React, { useEffect, useState } from "react";
import {
  Grid, TextField, Autocomplete, FormHelperText, Button, Dialog, DialogTitle, DialogActions, Typography,
  DialogContentText, DialogContent, Chip
} from "@mui/material";
import { getFilterValues } from "../../Redux/Middlewares/WebinarsVet/getFilterValuesMiddleware";
import {
  changePage,
  selectFilter,
} from "../../Redux/ActionCreators/webinarsVetActionCreators";
import { connect } from "react-redux";
import { classes } from "./styles";
import CloseIcon from "@mui/icons-material/Close";

const Dropdowns = ({
  filters,
  getFilterValues,
  selectedFilters,
  selectFilter,
  handleApplyFiltersClick,
  isLoading,
}) => {
  useEffect(() => {
    // getFilterValues();
  }, []);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleCustomDate = (type, value,) => {
    value = value.trim()
    if (type === "fromMonth") {
      setCustom({ ...custom, start_date: { ...custom.start_date, month: value } })
    }
    else if (type === "fromYear") {
      if (!isNaN(value)) {
        setCustom({ ...custom, start_date: { ...custom.start_date, year: value } })
      }

    }
    else if (type === "toMonth") {
      setCustom({ ...custom, end_date: { ...custom.end_date, month: value } })
    }
    else if (type === "toYear") {
      if (!isNaN(value)) {
        setCustom({ ...custom, end_date: { ...custom.end_date, year: value } })
      }
    }
  }

  const handleSubmit = () => {

    //LOGIC TO DETECT IF END DATE IS GREATER THAT START DATE

    if (!custom.start_date.month || !custom.end_date.month
      || !custom.start_date.year || !custom.end_date.year) {
      setError(true)
      setMsg("Enter Valid dates")
    }
    else if (months.indexOf(custom.start_date.month) + ((Number(custom.start_date.year)) * 20) <
      months.indexOf(custom.end_date.month) + ((Number(custom.end_date.year)) * 20)) {
      setError(false);

      // setCustomDate(custom.start_date.month + "/" + custom.start_date.year, custom.end_date.month + "/" + custom.end_date.year)
      selectFilter("show_content_from", { value: "custom" })

      setOpenCustomDialog(false);
    }
    else {
      setError(true)
      setMsg("From date should be less than to date")

    }





  }

  const [openCustomDialog, setOpenCustomDialog] = useState(false);
  const [custom, setCustom] = useState({ start_date: { month: "", year: "" }, end_date: { month: "", year: "" } })
  const [error, setError] = useState(false)
  const [msg, setMsg] = useState()

  return (
    <div style={{ margin: "10px" }}>
      <Grid
        container
        fullWidth
        alignItems="flex-end"
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 8, md: 12 }}
      >

        <Grid item xs={12} sm={12} md={3}>
          <FormHelperText id="show_courses_from_filter">
            Show webinars from
          </FormHelperText>
          <Autocomplete
            size="small"
            disablePortal
            limitTags={1}
            id="show_courses_from_filter"
            options={
              filters && filters.show_content_from && Object.keys(filters).length > 0
                ? filters.show_content_from
                : []
            }
            sx={classes.filter}
            loading={isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  // endAdornment: (
                  //   <>
                  //     {isLoading ? (
                  //       <CircularProgress color="inherit" size={20} />
                  //     ) : null}
                  //     {params.InputProps.endAdornment}
                  //   </>
                  // ),
                }}
              />
            )}
            getOptionLabel={(option) => option.value || ""}
            value={selectedFilters["show_content_from"]}
            onChange={(e, value) => {

              if (value && value.value == "Custom") {
                setOpenCustomDialog(true);
              }
              else {
                selectFilter("show_content_from", value === null ? "" : { ...value, value: value.value + "" })
              }


            }
            }
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <FormHelperText id="show_courses_from_filter">
            Category
          </FormHelperText>
          <Autocomplete
            limitTags={1}
            size="small"
            disableCloseOnSelect
            multiple
            id="show_courses_from_filter"
            options={
              filters && Object.keys(filters).length ? filters.category : []
            }
            sx={classes.filter}
            // loading={isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {/* {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null} */}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}

            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={option.category_name}
                  {...getTagProps({ index })}

                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="category" />
          )}

            getOptionLabel={(option) =>
              option.category_name
            }
            value={selectedFilters["category"]}
            onChange={(e, value) => {
              selectFilter("category", value === null ? "" : value);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <FormHelperText id="show_courses_from_filter">Speaker</FormHelperText>
          <Autocomplete
            limitTags={1}
            size="small"
            disablePortal
            multiple
            id="show_courses_from_filter"
            options={
              filters && Object.keys(filters).length ? filters.creator : []
            }
            sx={classes.filter}
            // loading={isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
                placeholder="speaker"
              />
            )}
            getOptionLabel={(option) =>
              option.value || option.category_name || option.speaker_name || ""
            }
            value={selectedFilters["creator"]}
            onChange={(e, value) => {
              selectFilter("creator", value === null ? "" : value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <FormHelperText id="show_courses_from_filter">Species</FormHelperText>
          <Autocomplete
            limitTags={1}
            size="small"
            disablePortal
            multiple
            id="show_courses_from_filter"
            options={
              filters && Object.keys(filters).length ? filters.species : []
            }
            sx={classes.filter}
            // loading={isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
                placeholder="species"
              />
            )}
            getOptionLabel={(option) =>
              option.value || option.category_name || option.speaker_name || ""
            }
            value={selectedFilters["species"]}
            onChange={(e, value) => {
              selectFilter("species", value === null ? "" : value);
            }}
          />
        </Grid>
      </Grid>
      <Dialog
        open={openCustomDialog}
        // onClose={() => setOpenCustomDialog(false)}
        maxWidth={"lg"}
      >
        <DialogTitle
          style={{
            padding: "10px 15px",
            backgroundColor: "#CCCCFF",
          }}
        >
          Select custom date range
          <CloseIcon
            fontSize="small"
            style={{
              cursor: "pointer",
              position: "absolute",
              right: 8,
              top: 8,
            }}
            onClick={() => { setError(false); setOpenCustomDialog(false) }}
          />
        </DialogTitle>
        <DialogContent style={{ padding: 0, width: "550px" }}>
          <DialogContentText
            id="alert_dialog_description"
            style={{ fontWeight: "bold", marginTop: "10px" }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 2, sm: 8, md: 14 }}
            >
              <Grid item xs={12} sm={12} md={5}>
                <span>From</span>
                <FormHelperText id="show_courses_from_filter">
                  Month
                </FormHelperText>
                <Autocomplete

                  size="small"
                  // styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  disablePortal
                  disableClearable
                  id="show_courses_from_filter"
                  options={months}
                  sx={classes.filter}
                  // loading={isLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                      placeholder={custom.start_date.month}
                    />
                  )}
                  // value={selectedFilters["show_content_from"]}
                  onChange={(e, value) => {
                    handleCustomDate("fromMonth", value)
                  }}
                />
                <FormHelperText id="show_courses_from_filter">
                  Year
                </FormHelperText>

                <TextField fullWidth
                  size="small"
                  inputProps={{ maxLength: 4 }}
                  value={custom.start_date.year}
                  onChange={(e, value) => {

                    handleCustomDate("fromYear", e.target.value)
                  }} />
              </Grid>
              <Grid item xs={12} sm={12} md={0.5}>
                <div
                  style={{ borderLeft: "1px solid gray", height: "150px" }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <span>To</span>
                <FormHelperText id="show_courses_from_filter">
                  Month
                </FormHelperText>
                <Autocomplete

                  size="small"
                  disablePortal
                  disableClearable
                  id="show_courses_from_filter"
                  options={months}
                  sx={classes.filter}
                  // loading={isLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                      placeholder={custom.end_date.month}
                    />
                  )}
                  // value={selectedFilters["show_content_from"]}
                  onChange={(e, value) => {
                    handleCustomDate("toMonth", value)
                  }}
                />
                <FormHelperText id="show_courses_from_filter">
                  Year
                </FormHelperText>

                <TextField fullWidth
                  value={custom.end_date.year}
                  inputProps={{ maxLength: 4 }}
                  onChange={(e, value) => {
                    handleCustomDate("toYear", e.target.value)
                  }}
                  size="small" />
              </Grid>
              <Grid container md={14} justifyContent="center">
                {error && <Typography sx={{ color: "red", marginTop: 2 }}>{msg}</Typography>}

              </Grid>
            </Grid>

          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {

              handleSubmit()
            }}
            style={{ textTransform: "none", height: 30 }}
          >
            Submit
          </Button>
          <Button
            variant="outlined"
            style={{ textTransform: "none", height: 30 }}
            onClick={() => { setError(false); setOpenCustomDialog(false) }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  filters: state.WebinarsVetReducer.filters,
  selectedFilters: state.WebinarsVetReducer.selectedFilters,
  courses: state.WebinarsVetReducer.courses,
  pageNumber: state.WebinarsVetReducer.pageNumber,
  isLoading: state.WebinarsVetReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getFilterValues: () => dispatch(getFilterValues()),
  selectFilter: (filterType, filterValue) =>
    dispatch(selectFilter(filterType, filterValue)),
  // getCourses: (filterDetails, pageNumber) =>
  //   dispatch(getCourses(filterDetails, pageNumber)),
  changePage: (pageNumber) => dispatch(changePage(pageNumber)),
  // changeCourseStatus: (courseDetails) =>
  //   dispatch(changeCourseStatus(courseDetails)),
  //   fileDelete: (id) => dispatch(fileDelete(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dropdowns);
