export const FETCH_COURSES_DATA="FETCH_COURSES_DATA"
export const SET_COURSES_DATA="SET_COURSES_DATA"
export const SET_ACTIVE_COURSE_DATA="SET_ACTIVE_COURSE_DATA"
export const CLEAR_ACTIVE_COURSE_DATA="CLEAR_ACTIVE_COURSE_DATA"
export const CLEAR_COURSES_DATA="CLEAR_COURSES_DATA"
export const SET_COURSES_FAILURE_DATA="SET_COURSES_FAILURE_DATA"
export const SET_COURSES_FILTER_DATA="SET_COURSES_FILTER_DATA"
export const SET_USER_COURSE_FILTER_DATA="SET_USER_COURSE_FILTER_DATA"
export const CLEAR_USER_COURSE_FILTER_DATA="CLEAR_USER_COURSE_FILTER_DATA"
export const SET_COURSES_DATA_PAGINATION="SET_COURSES_DATA_PAGINATION"
export const SET_COURSES_SEQUENCE="SET_COURSES_SEQUENCE"
export const TOGGLE_QUIZ_ACTIVATION="TOGGLE_QUIZ_ACTIVATION"
export const FETCH_QUIZ_DATA="FETCH_QUIZ_DATA"
export const SET_QUIZ_DATA="SET_QUIZ_DATA"
export const SET_QUIZ_RESULT="SET_QUIZ_RESULT"
export const CLEAR_QUIZ_DATA="CLEAR_QUIZ_DATA"
export const SET_GRADE_DATA="SET_GRADE_DATA"
export const BOOKMARK_UPDATE_COURSES="BOOKMARK_UPDATE_COURSES"
export const RATING_UPDATE_COURSES="RATING_UPDATE_COURSES"
export const SET_COURSE_CERTIFICATE_PDF="SET_COURSE_CERTIFICATE_PDF"