import axios from "axios";
import { catchError } from "../../ActionCreators/queriesVetActionCreators";
import { SET_LANDING_DATA, 
    SET_GRAPH_DATA, 
    START_LOADING, 
    STOP_LOADING, 
    SET_DATA_FILTER,
    SET_VET_COUNT_FILTER,    
    SET_TOP5_RATED_FILTER,
    SET_NOTIFICATION_DATA,
    SET_NOTIFICATION_SEEN } from "../../ActionTypes/landingPageActionTypes";

const baseUrl = process.env.REACT_APP_API_URL;

// const startLoading = () => dispatch => {
//     dispatch({
//         type: START_LOADING,
//         // payload: true
//     })
// }


const stopLoading = () => dispatch => {
    dispatch({
        type: STOP_LOADING,
        // payload: loading_value
    })
}


export const getLandingDetails = () => async dispatch => {
    //start loading dispatch action
    // startLoading()
    dispatch({
        type: START_LOADING,
        payload:{
            type: "isPageLoading"
        }
    })

    //try catch block
    try{
        // startLoading();
        let url = baseUrl+`/usermanagement/admin/landingdropdown/`;
        let response = await axios.get(url);

        dispatch({
            type: SET_LANDING_DATA,
            payload: response.data
        })
        
        if(response.data){
            let url2 = baseUrl+`/usermanagement/admin/admin_landingpage/`;
        const payloadObj = {
            "country":"India"
        }
        let response2 = await axios.post(url2,payloadObj); 
        dispatch({
            type:SET_GRAPH_DATA,
            payload: response2.data
        })
        
        }
    }
    catch(error){
        dispatch(catchError)
    }
    finally{
        dispatch({
            type: STOP_LOADING,
            payload:{
                type: "isPageLoading"
            }
        })
    }
    

}

export const setFilterValue = (filterValue) => dispatch => {
    dispatch({
        type: SET_DATA_FILTER,
        payload: filterValue
    })
}

export const getGraphData = (filterValue) => async dispatch => {
    dispatch({
        type: START_LOADING,
        payload:{
            type: "isPageLoading"
        }
    })
    dispatch({
        type: SET_DATA_FILTER,
        payload: filterValue
    })
    
    try{
        let url = baseUrl+`/usermanagement/admin/admin_landingpage/`;
        const payloadObj = {
            country:filterValue
        }
        let response = await axios.post(url,payloadObj);
        dispatch({
            type:SET_GRAPH_DATA,
            payload: response.data
        })
        
    }
    catch(error){
        dispatch(catchError)
    }
    finally{
        dispatch({
            type: STOP_LOADING,
            payload:{
                type: "isPageLoading"
            }
        })
    }

}

export const setRegionWiseDropdownValue = (filterValue) => dispatch => {
    dispatch({
        type: SET_VET_COUNT_FILTER,
        payload: filterValue
    })
}

export const setTop5RatedDropdownValue = (filterValue) => dispatch => {
    dispatch({
        type: SET_TOP5_RATED_FILTER,
        payload: filterValue
    })
}

export const setNoficationsData = (currPage=1) => async dispatch => {
    dispatch({
        type: START_LOADING,
        payload:{
            type: "isContentLoading"
        }
    })
    
    try{
        let url = baseUrl+`/usermanagement/admin/notifications/?page=`+currPage;
        let response = await axios.get(url);
        dispatch({
            type:SET_NOTIFICATION_DATA,
            payload: response.data
        })
        
    }
    catch(error){
        dispatch(catchError)
    }
    finally{
        dispatch({
            type: STOP_LOADING,
            payload:{
                type: "isContentLoading"
            }
        })
    }
}

export const setNoficationsSeen = (notificationType) => async dispatch => {
    try{
        let url = baseUrl+`/usermanagement/admin/seen_notifications/`;
        const inputObj = {
            "status":notificationType
        }
        let response = await axios.post(url,inputObj);
        
    }
    catch(error){
        dispatch(catchError)
    }
}