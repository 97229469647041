import {
     CLEAR_ARTICLE_USER_DATA,SET_BOOKMARK_LC, SET_FILTERS_DATA_LEARNING_CONTENT
} from "../ActionTypes/learningContentActionTypes"

import { SET_QUERY_ADDED_ALERT,CLEAR_QUERY_ADDED_ALERT,SET_GENERIC_ERROR_ALERT } from "../ActionTypes/alertsActionTypes"

import axios from "axios"

//Login 
export const getFiltersLC = (moduleId,queryCategory,queryText) => async dispatch => {


    try {
        

        
        let url = process.env.REACT_APP_API_URL+"/learningcontent/vet/dropdown/"



        let result = await axios.get(url)

        if (result.data &&result.data.file_category) {

            


            dispatch({ type: SET_FILTERS_DATA_LEARNING_CONTENT,payload:result.data })


        }
        else {
            dispatch({ type: SET_GENERIC_ERROR_ALERT })
        }

        //dispatch(loadUser())

    }
    catch (err) {
        dispatch({ type: SET_GENERIC_ERROR_ALERT })
    }
}

