import axios from "axios";
import {
  deleteCourseFailure,
  deleteCourseReset,
  deleteCourseSuccess,
  toggleAlert,
} from "../../ActionCreators/coursesAdminActionCreators";

const DELETE_COURSE_URL =
  process.env.REACT_APP_API_URL + "/courses/admin/delete_course/";

export const deleteCourse = (course_id) => (dispatch) => {
  // dispatch(deleteCourseRequest());
  axios
    .delete(DELETE_COURSE_URL + `${course_id}/`)
    .then((response) => {
      if (response.status === 204) {
        dispatch(toggleAlert("success", "Course successfully deleted !", true));
        setTimeout(() => {
          dispatch(deleteCourseSuccess());
          dispatch(deleteCourseReset());
        }, 0); //2000
      } else {
        dispatch(deleteCourseFailure("Something went wrong !"));
        dispatch(toggleAlert("error", "Something went wrong !", true));
      }
    })
    .catch((error) => {
      dispatch(deleteCourseFailure(error.message));
      dispatch(toggleAlert("error", error.message, true));
    });
};
