import axios from "axios";
import {
  createCourseFailure,
  createCourseRequest,
  createCourseSuccess,
  createEditChapterFailure,
  createEditChapterRequest,
  createEditChapterSuccess,
  createEditLessonFailure,
  createEditLessonRequest,
  createEditLessonSuccess,
  createQuizFailure,
  createQuizRequest,
  createQuizSuccess,
  setActiveCourse,
  setCurriculumDetails,
  toggleAlert,
} from "../../ActionCreators/coursesAdminActionCreators";

const CREATE_QUIZ_URL =
  process.env.REACT_APP_API_URL + "/curriculum/admin/create_quiz/";

export const createQuiz = (quizDetails) => (dispatch) => {
  dispatch(createQuizRequest());
  axios
    .post(CREATE_QUIZ_URL, quizDetails)
    .then((response) => {
      if (response.status === 201) {
        dispatch(createQuizSuccess());
        dispatch(toggleAlert("success", "Quiz created successfully !", true));
        dispatch(setCurriculumDetails(response.data));
      } else {
        dispatch(createQuizFailure("Something went wrong !"));
        dispatch(toggleAlert("error", "Something went wrong !", true));
      }
    })
    .catch((error) => {
      dispatch(createQuizFailure(error.message));
      dispatch(toggleAlert("error", error.message, true));
    });
};
